import { ADDRESS_TYPE } from 'Common/constants/addressType';
import { REGEX } from 'Common/constants/addressField';

class AddressModalCtrl {
  constructor(currentUserService, corporateService, timeoutService, $timeout) {
    'ngInject';

    this.currentUserService = currentUserService;
    this.corporateService = corporateService;
    this.timeoutService = timeoutService;
    this.$timeout = $timeout;
  }

  setCustomAddressFlag() {
    this.addressObject.isCustomAddress = !this.addressObject.isCustomAddress;
  }

  setAddressForm() {
    this.$timeout(() => {
      if (this.defaultManual !== null) {
        this.addressObject.isCustomAddress = this.defaultManual;
      }
      if (!this.addressForm) {
        return;
      }
      this.onInputChange({ customForm: this.addressForm });
    }, 1000);
  }

  getAddressType() {
    this.addressTypeName = '';
    const typeId = parseInt(this.addressType, 10);
    switch (typeId) {
      case ADDRESS_TYPE.BUSINESS:
        this.addressTypeName = 'BUSINESS ADDRESS';
        break;
      case ADDRESS_TYPE.HOME:
        this.addressTypeName = this.isOrgAddress
          ? 'BUSINESS ADDRESS'
          : 'HOME ADDRESS';
        break;
      case ADDRESS_TYPE.MAILING:
        this.addressTypeName = 'MAILING ADDRESS';
        break;
      default:
        break;
    }
    this.setAddressForm();
  }

  clearManualFields() {
    if (this.addressObject) {
      this.addressObject.street_address = '';
      this.addressObject.locality = '';
      this.addressObject.postal_code = '';
      this.addressObject.stateShort_Name = '';
      this.addressObject.country = '';
      this.addressObject.formatted_address = '';
    }
  }

  $onChanges(changes) {
    const { saveClicked } = changes;

    if (saveClicked && typeof saveClicked.currentValue !== 'undefined') {
      this.saveClicked = saveClicked.currentValue;
    }
  }

  $onInit() {
    this.postcodeRegex = REGEX.POST_CODE;
    this.addressForm = {};
    this.type = ADDRESS_TYPE;
    this.isCustomAddress = false;
    this.getAddressType();
  }

  $onDestroy() {
    this.timeoutService.cancelTimeouts('AddressModalCtrl');
  }
}

export default AddressModalCtrl;
