import { COLOR } from 'Common/constants/colors';

export const LENDER_MAX_INDEX = 4;

export const ALL_VALUE = '0';

export const WIDGET_TITLE = {
  LENDER_SPLIT: 'Lender Split',
  AVG_LOAN_SIZE: 'Average Loan Size',
  SETTLEMENT_VOLUMES: 'Settlement Volumes',
  ENQUIRY_SOURCE: 'Enquiry Source',
};

export const WIDGET_ID = {
  LODGED: 32,
  APPROVED: 34,
  SETTLED: 42,
  LENDER_SPLIT_SMALL: 51,
  AVG_LOAN_SIZE: 53,
  SETTLEMENT_VOLUMES: 54,
  ENQUIRY_SOURCE: 55,
  REFERRALS_SENT: 56,
  REFERRALS_RECEIVED: 57,
  UPCOMING_FINANCE_DATES: 58,
  UPCOMING_SETTLEMENT_DATES: 59,
  INSURANCE_PIPELINE_STATUS: 60,
  INSURANCE_NOTIFICATIONS: 62,
  ESIGN_USAGE_REPORT: 63,
  APPROVAL_EXPIRY_LARGE: 47,
  CLIENT_REVIEWS_LARGE: 11,
  COMMISSION_RECEIVED_TABLE_LARGE: 6,
  COMMISSION_RECEIVED_CHART_LARGE: 7,
  FIXED_RATE_REVIEWS_LARGE: 1,
  INTEREST_RATE_LARGE: 50,
  INTEREST_UPDATES_LARGE: 17,
  NEW_LEADS_LARGE: 41,
  PIPELINE_STATS_CHART_LARGE: 38,
  PIPELINE_STATS_TABLE_LARGE: 39,
  PROVIDER_UPDATES_LARGE: 16,
  UPCOMING_BIRTHDAYS_LARGE: 4,
  UPCOMING_EVENTS_LARGE: 14,
  APPLICATION_PIPELINE_STATUS_SMALL: 36,
  APPROVAL_EXPIRY_SMALL: 44,
  CLIENT_REVIEWS_SMALL: 10,
  COMMISSION_RECEIVED_SMALL: 5,
  FIXED_RATE_REVIEWS_SMALL: 2,
  UPCOMING_BIRTHDAYS_SMALL: 3,
};

export const NZ_USER_WIDGET_FLAG = [
  {
    widgetType: 'APPROVAL_EXPIRY_LARGE',
    widgetId: WIDGET_ID.APPROVAL_EXPIRY_LARGE,
    showForLoanWriter: false,
    showForRiskWriter: true,
  },
  {
    widgetType: 'CLIENT_REVIEWS_LARGE',
    widgetId: WIDGET_ID.CLIENT_REVIEWS_LARGE,
    showForLoanWriter: true,
    showForRiskWriter: true,
  },
  {
    widgetType: 'COMMISSION_RECEIVED_CHART_LARGE',
    widgetId: WIDGET_ID.COMMISSION_RECEIVED_CHART_LARGE,
    showForLoanWriter: true,
    showForRiskWriter: true,
  },
  {
    widgetType: 'COMMISSION_RECEIVED_TABLE_LARGE',
    widgetId: WIDGET_ID.COMMISSION_RECEIVED_TABLE_LARGE,
    showForLoanWriter: true,
    showForRiskWriter: true,
  },
  {
    widgetType: 'FIXED_RATE_REVIEWS_LARGE',
    widgetId: WIDGET_ID.FIXED_RATE_REVIEWS_LARGE,
    showForLoanWriter: true,
    showForRiskWriter: false,
  },
  {
    widgetType: 'INSURANCE_NOTIFICATIONS_LARGE',
    widgetId: WIDGET_ID.INSURANCE_NOTIFICATIONS,
    showForLoanWriter: false,
    showForRiskWriter: true,
  },
  {
    widgetType: 'INTEREST_RATE_LARGE',
    widgetId: WIDGET_ID.INTEREST_RATE_LARGE,
    showForLoanWriter: true,
    showForRiskWriter: false,
  },
  {
    widgetType: 'INTEREST_UPDATES_LARGE',
    widgetId: WIDGET_ID.INTEREST_UPDATES_LARGE,
    showForLoanWriter: false,
    showForRiskWriter: false,
  },
  {
    widgetType: 'NEW_LEADS_LARGE',
    widgetId: WIDGET_ID.NEW_LEADS_LARGE,
    showForLoanWriter: true,
    showForRiskWriter: false,
  },
  {
    widgetType: 'PIPELINE_STATS_CHART_LARGE',
    widgetId: WIDGET_ID.PIPELINE_STATS_CHART_LARGE,
    showForLoanWriter: true,
    showForRiskWriter: false,
  },
  {
    widgetType: 'PIPELINE_STATS_TABLE_LARGE',
    widgetId: WIDGET_ID.PIPELINE_STATS_TABLE_LARGE,
    showForLoanWriter: true,
    showForRiskWriter: false,
  },
  {
    widgetType: 'PROVIDER_UPDATES_LARGE',
    widgetId: WIDGET_ID.PROVIDER_UPDATES_LARGE,
    showForLoanWriter: false,
    showForRiskWriter: false,
  },
  {
    widgetType: 'SETTLEMENT_VOLUMES_LARGE',
    widgetId: WIDGET_ID.SETTLEMENT_VOLUMES,
    showForLoanWriter: true,
    showForRiskWriter: false,
  },
  {
    widgetType: 'UPCOMING_BIRTHDAYS_LARGE',
    widgetId: WIDGET_ID.UPCOMING_BIRTHDAYS_LARGE,
    showForLoanWriter: true,
    showForRiskWriter: true,
  },
  {
    widgetType: 'UPCOMING_EVENTS_LARGE',
    widgetId: WIDGET_ID.UPCOMING_EVENTS_LARGE,
    showForLoanWriter: false,
    showForRiskWriter: false,
  },
  {
    widgetType: 'UPCOMING_FINANCE_DATES_LARGE',
    widgetId: WIDGET_ID.UPCOMING_FINANCE_DATES,
    showForLoanWriter: true,
    showForRiskWriter: false,
  },
  {
    widgetType: 'UPCOMING_SETTLEMENT_DATES_LARGE',
    widgetId: WIDGET_ID.UPCOMING_SETTLEMENT_DATES,
    showForLoanWriter: true,
    showForRiskWriter: false,
  },
  {
    widgetType: 'APPLICATION_PIPELINE_STATUS_SMALL',
    widgetId: WIDGET_ID.APPLICATION_PIPELINE_STATUS_SMALL,
    showForLoanWriter: true,
    showForRiskWriter: false,
  },
  {
    widgetType: 'APPROVAL_EXPIRY_SMALL',
    widgetId: WIDGET_ID.APPROVAL_EXPIRY_SMALL,
    showForLoanWriter: true,
    showForRiskWriter: false,
  },
  {
    widgetType: 'APPROVED_SMALL',
    widgetId: WIDGET_ID.APPROVED,
    showForLoanWriter: true,
    showForRiskWriter: false,
  },
  {
    widgetType: 'AVERAGE_LOAN_SIZE_SMALL',
    widgetId: WIDGET_ID.AVG_LOAN_SIZE,
    showForLoanWriter: true,
    showForRiskWriter: false,
  },
  {
    widgetType: 'CLIENT_REVIEWS_SMALL',
    widgetId: WIDGET_ID.CLIENT_REVIEWS_SMALL,
    showForLoanWriter: true,
    showForRiskWriter: true,
  },
  {
    widgetType: 'COMMISSION_RECEIVED_SMALL',
    widgetId: WIDGET_ID.COMMISSION_RECEIVED_SMALL,
    showForLoanWriter: true,
    showForRiskWriter: true,
  },
  {
    widgetType: 'ENQUIRY_SOURCE_SMALL',
    widgetId: WIDGET_ID.ENQUIRY_SOURCE,
    showForLoanWriter: true,
    showForRiskWriter: true,
  },
  {
    widgetType: 'FIXED_RATE_REVIEWS_SMALL',
    widgetId: WIDGET_ID.FIXED_RATE_REVIEWS_SMALL,
    showForLoanWriter: true,
    showForRiskWriter: false,
  },
  {
    widgetType: 'INSURANCE_PIPELINE_SMALL',
    widgetId: WIDGET_ID.INSURANCE_PIPELINE_STATUS,
    showForLoanWriter: false,
    showForRiskWriter: true,
  },
  {
    widgetType: 'LENDER_SPLIT_SMALL',
    widgetId: WIDGET_ID.LENDER_SPLIT_SMALL,
    showForLoanWriter: true,
    showForRiskWriter: false,
  },
  {
    widgetType: 'LODGED_SMALL',
    widgetId: WIDGET_ID.LODGED,
    showForLoanWriter: true,
    showForRiskWriter: false,
  },
  {
    widgetType: 'REFERRALS_RECEIVED_SMALL',
    widgetId: WIDGET_ID.REFERRALS_RECEIVED,
    showForLoanWriter: false,
    showForRiskWriter: false,
  },
  {
    widgetType: 'REFERRALS_SENT_SMALL',
    widgetId: WIDGET_ID.REFERRALS_SENT,
    showForLoanWriter: true,
    showForRiskWriter: true,
  },
  {
    widgetType: 'SETTLED_SMALL',
    widgetId: WIDGET_ID.SETTLED,
    showForLoanWriter: true,
    showForRiskWriter: false,
  },
  {
    widgetType: 'UPCOMING_BIRTHDAYS_SMALL',
    widgetId: WIDGET_ID.UPCOMING_BIRTHDAYS_SMALL,
    showForLoanWriter: true,
    showForRiskWriter: true,
  },
];

export const LENDER_SPLIT_SMALL = {
  TIME_PERIOD: '23',
  SPLIT_ON: '24',
  LENDER_CATEGORY: '25',
  ADVISERS: '3',
};

export const OPTION_ALL = {
  ADVISERS: {
    name: 'All Advisers',
    value: ALL_VALUE,
  },
  LENDER_CATEGORY: {
    name: 'All Categories',
    value: ALL_VALUE,
  },
  MEMBERS: {
    name: 'All Team Members',
    value: ALL_VALUE,
  },
};

export const LENDER_COLOR_CODES = [
  COLOR.VICTORIA,
  COLOR.BUTTER_CUP,
  COLOR.TERRACOTTA,
  COLOR.MARINER,
];

export const WIDGET_OPTION_VAL = {
  LENDER_CATEGORY: {
    ALL: '72',
    CUSTOM: '73',
    VAR_NAME: 'lenderCategoryList',
  },
  ADVISER: {
    ALL: '9',
    CUSTOM: '27',
    VAR_NAME: 'advisersDropdown',
  },
};

export const LENDER_SPLIT_ON = {
  COUNT: 70,
  VALUE: 71,
};

export const LENDER_SPLIT_PERIOD = {
  THIS_CY: 65,
  LAST_CY: 86,
  THIS_FY: 66,
  LAST_FY: 67,
  THIS_MONTH: 68,
  LAST_MONTH: 69,
};

export const LOAN_SIZE_PERIOD = {
  THIS_CY: 90,
  LAST_CY: 91,
  THIS_FY: 92,
  LAST_FY: 93,
  THIS_MONTH: 94,
  LAST_MONTH: 95,
};

export const SETTLEMENT_VOLUME_PERIOD = {
  CY: 96,
  FY: 97,
};

export const ENQUIRY_SOURCE_PERIOD = {
  THIS_CY: 98,
  LAST_CY: 99,
  THIS_FY: 100,
  LAST_FY: 101,
  THIS_MONTH: 102,
  LAST_MONTH: 103,
};

export const REFERRALS_SENT_RECEIVED_PERIOD = {
  THIS_WEEK: 104,
  LAST_WEEK: 105,
  THIS_MONTH: 106,
  LAST_MONTH: 107,
  THIS_CY: 108,
  THIS_FY: 109,
  LAST_CY: 110,
  LAST_FY: 111,
};

export const TARGET_BASED_PERIOD = {
  THIS_WEEK: 51,
  THIS_MONTH: 52,
  THIS_CY: 53,
  THIS_FY: 58,
};

export const TARGET_DD_MM = [
  TARGET_BASED_PERIOD.THIS_MONTH,
  TARGET_BASED_PERIOD.THIS_WEEK,
];

export const AVERAGE_LOAN_SIZE = {
  ADVISERS: '3',
  TIME_PERIOD: '26',
  LOAN_STATUS: '27',
};

export const SETTLEMENT_VOLUMES = {
  ADVISERS: '3',
  TIME_PERIOD: '28',
};

export const TARGET_BASED_TILE = {
  ADVISERS: 3,
  TIME_PERIOD: 17,
};

export const LOAN_STATUS_ON = {
  LODGED: 87,
  APPROVED: 88,
  SETTLED: 89,
};

export const LIST_LOAN_STATUS = [
  LOAN_STATUS_ON.LODGED,
  LOAN_STATUS_ON.APPROVED,
  LOAN_STATUS_ON.SETTLED,
];

export const LOAN_WEEKLY_DATA = [
  LOAN_SIZE_PERIOD.THIS_MONTH,
  LOAN_SIZE_PERIOD.LAST_MONTH,
];

export const ENQUIRY_SOURCE = {
  ADVISERS: '3',
  TIME_PERIOD: '29',
};

export const REFERRALS_SENT_RECEIVED = {
  TITLE: {
    [WIDGET_ID.REFERRALS_SENT]: 'Referrals Sent',
    [WIDGET_ID.REFERRALS_RECEIVED]: 'Referrals Received',
  },
  SUBTITLE: {
    [WIDGET_ID.REFERRALS_SENT]: 'Referral types sent to partners',
    [WIDGET_ID.REFERRALS_RECEIVED]: 'Referrals received by organisation',
  },
  ADVISERS: '3',
  TIME_PERIOD: '30',
};

export const CHART_SERIES = {
  LOAN_AMT: ['Target', 'Loan Amount'],
};

export const DATE_INT = {
  WEEK: 52,
  MONTH: 12,
};
