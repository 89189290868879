import orderBy from 'lodash/orderBy';
import { addHelloBookPipelineCategory } from 'Scenes/helloBook/scenes/helloBookPreviewGenerator/util/helloBookPreviewGeneratorCtrl';
import { helloBookFamilyLoanForUI } from 'Common/mappers/helloBook';
import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';

const defaultDeal = {
  loanScenarioId: 0,
  dealName: 'Create New Loan Application',
};

export default class HelloPackDealOptionCtrl {
  constructor(loanOpportunityService, currentUserService, uiService) {
    'ngInject';

    this.loanOpportunityService = loanOpportunityService;
    this.currentUserService = currentUserService;
    this.uiService = uiService;
  }

  $onInit() {
    this.selectedDeal = null;
    this.deals = [];
    this.getLoanList();
    if (this.handler) {
      this.handler.addDeal = (deal) => {
        this.deals = [...this.deals, deal];
        this.selectedDeal = deal;
        this.onDealChange({
          loanApplicationId: this.selectedDeal.loanScenarioId,
          loanApplicationTitle: this.selectedDeal.dealName,
        });
      };
      this.handler.fetchingCallback = () => {
        this.isFetching = false;
      };
    }
  }

  onDealChange({ loanApplicationId, loanApplicationTitle }) {
    this.isFetching = !!loanApplicationId;
    this.onChange({ loanApplicationId, loanApplicationTitle });
  }

  getLoanList() {
    this.loanOpportunityService.getLoanList(this.familyId).then((response) => {
      if (!response) {
        this.handleEmptyDeal();
        return;
      }
      const disableFactFindStatuses = [
        LOAN_STATUS.IN_PROGRESS.LODGED,
        LOAN_STATUS.IN_PROGRESS.PRE_APPROVED,
        LOAN_STATUS.EXISTING.SETTLED,
        LOAN_STATUS.PREVIOUS.NOT_PROCEEDED_WITH,
      ];
      const validLoans = response.filter(
        (loan) =>
          loan.loanScenarioId &&
          !disableFactFindStatuses.includes(loan.loanStatusId),
      );
      const loanList = validLoans.map((loan) => {
        const isMergeLeadAndOpportunities = this.uiService.viewOfAfileTurnedOn;
        const loanWithCategory = addHelloBookPipelineCategory({
          loan,
          isMergeLeadAndOpportunities,
        });
        return helloBookFamilyLoanForUI({
          loan: loanWithCategory,
          isMergeLeadAndOpportunities,
          userCountryCode: this.currentUserService.countryCode,
        });
      });

      this.deals = orderBy(loanList, ['createdDate'], ['desc']) || [];
      this.handleEmptyDeal();
      this.setDefaultLeadLoan();
    });
  }

  handleEmptyDeal() {
    if (this.uiService.viewOfAfileTurnedOn) {
      return;
    }
    this.selectedDeal = defaultDeal;
    this.deals = [defaultDeal, ...this.deals];
    this.setDefaultLeadLoan();
  }

  setDefaultLeadLoan() {
    const [firstLoan] = this.deals;
    const leadLoan = this.deals.find((loan) => loan.isLead) || firstLoan || {};
    this.selectedDeal = leadLoan;
    const isEmptyLoan =
      !this.uiService.viewOfAfileTurnedOn && this.deals.length <= 1;
    this.onChange({
      loanApplicationId: leadLoan.loanScenarioId,
      loanApplicationTitle: leadLoan.dealName,
      isEmptyLoan,
    });
  }
}
