import angular from 'angular';
import _ from 'lodash';
import { DATA_RED, DATA_BLUE } from 'Common/constants/chartColors';
import { BORDER_RADIUS_NORMAL } from 'Common/constants/chartOptions';
import { REPAYMENT_PERIOD } from 'Common/constants/repayment';

export const loadModule = () =>
  angular.module('app').controller(
    'AdditionalRepaymentCalculatorCtrl',
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function AdditionalRepaymentCalculatorCtrl(
      $scope,
      $uibModal,
      loanToolsService,
      utilitiesService,
      $window,
      DEMO_VIDEO,
      formattingService,
      lenderRatesModalService,
      optionsService,
    ) {
      // Open Model For Demo Video
      $scope.openModalForDemoVideo = function (size) {
        $uibModal.open({
          templateUrl: '/assets/views/partials/demo_video_modal.html',
          controller: 'DemoVideoModalCtrl',
          resolve: {
            videoId() {
              return DEMO_VIDEO.ADDITIONAL_REPAYMENT_CALCULATOR.Url;
            },
          },
          size,
          windowClass: 'demo-video-modal-window-class',
        });
      };

      optionsService.getLoanTerms().then((loanTermResponse) => {
        $scope.loanTermList = loanTermResponse;
      });
      // Change Page View On Change Screen Size
      // -------------------------------------------------------------

      $scope.screenSize = $window.innerWidth;

      const viewport = function () {
        let e = $window;
        let a = 'inner';
        if (!('innerWidth' in $window)) {
          a = 'client';
          e = $window.document.documentElement || $window.document.body;
        }
        return {
          width: e[`${a}Width`],
        };
      };

      $scope.getWindowWidth = function () {
        return {
          w: viewport().width,
        };
      };

      $scope.$watch(
        $scope.getWindowWidth,
        (newValue) => {
          $scope.screenSize = newValue.w;
        },
        true,
      );
      $scope.series = ['With Extra Payment', 'No Extra Payment'];
      $scope.colors = [DATA_BLUE, DATA_RED];
      $scope.datasetOverride = $scope.series.map(() => BORDER_RADIUS_NORMAL);

      $scope.options = utilitiesService.getLoanOptions();
      $scope.options = {
        ...$scope.options,
        scales: {
          yAxes: [...utilitiesService.getCustomYaxis()],
        },
      };
      $scope.repayList = {
        repayTable: [],
        repayChart: [],
        repayTit: [],
      };
      $scope.repay = {
        years: 30,
        frequency: 'Monthly',
        loan: '400,000',
        r: 6,
        additionalPayment: '100',
        additionPaymentFrequency: 'Monthly',
        additionPaymentYear: 1,
        lumpSum: 0,
        lumpSumYear: 1,
        redraw: 0,
        redrawAtYear: 1,
        interestOnly: false,
      };
      $scope.resultPanelData = {};

      $scope.timeSaved = '';
      $scope.showBottom = true;
      $scope.changeShowBottom = function (val) {
        $scope.showBottom = val;
      };
      $scope.openLenderRatesModal = (size) => {
        lenderRatesModalService.launchLenderListModal(
          size,
          $scope.lenderList,
          $scope.repay,
        );
      };

      // eslint-disable-next-line unicorn/consistent-function-scoping
      function getParsed(amount) {
        const replaced = amount.replace(/,/g, '');
        return parseFloat(replaced);
      }
      $scope.formatToCurrency = formattingService.calculatorFormatCurrency;
      $scope.formatInterestRate = (amount) => {
        return formattingService.calculatorFormatInterestRate(amount);
      };

      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.loadTable = function () {
        const copiedObj = {};
        $scope.repaymentTtileArr = [];
        angular.copy($scope.repay, copiedObj);
        copiedObj.years = parseInt(copiedObj.years, 10);
        copiedObj.r = parseFloat(copiedObj.r);
        copiedObj.loan = parseFloat(getParsed(copiedObj.loan));
        copiedObj.additionalPayment = parseFloat(
          getParsed(copiedObj.additionalPayment),
        );
        copiedObj.additionPaymentYear = parseInt(
          copiedObj.additionPaymentYear,
          10,
        );
        copiedObj.lumpSumYear = parseInt(copiedObj.lumpSumYear, 10);
        copiedObj.lumpSum = parseFloat(copiedObj.lumpSum)
          ? parseFloat(copiedObj.lumpSum)
          : 0;

        loanToolsService
          .getAdditionalRepaymentCalculations(copiedObj)
          .then((response) => {
            if (!response.data) {
              return;
            }
            $scope.calculationObject = response.data;
            for (
              let i = 8;
              i < $scope.calculationObject.repaymentTitleArray;
              i++
            ) {
              if (
                $scope.calculationObject.repaymentTitleArray[i] ===
                'Repayment Amount (with additional payments and lump sum payments)'
              ) {
                $scope.repaymentTtileArr.push({
                  title1: 'Additional Repayment',
                });
              }
              if (
                $scope.calculationObject.repaymentTitleArray[i] ===
                'Principal Paid (with additional payments and lump sum payments)'
              ) {
                $scope.repaymentTtileArr.push({ title2: 'Principal Paid ' });
              }
              if (
                $scope.calculationObject.repaymentTitleArray[i] ===
                'Interest Paid (with additional payments and lump sum payments)'
              ) {
                $scope.repaymentTtileArr.push({ title3: 'Interest Paid' });
              }
              if (
                $scope.calculationObject.repaymentTitleArray[i] ===
                'Accumulative Principal Paid (with additional payments and lump sum payments)'
              ) {
                $scope.repaymentTtileArr.push({
                  title4: 'Accumulative Principal Paid ',
                });
              }
              if (
                $scope.calculationObject.repaymentTitleArray[i] ===
                'Accumulative Interest Paid (with additional payments and lump sum payments)'
              ) {
                $scope.repaymentTtileArr.push({
                  title5: 'Accumulative Interest Paid ',
                });
              }
              if (
                $scope.calculationObject.repaymentTitleArray[i] ===
                'Principal To Pay (with additional payments and lump sum payments)'
              ) {
                $scope.repaymentTtileArr.push({
                  title6: 'Principal To Pay ',
                });
              }
            }

            $scope.constructDataForGraph();
            $scope.constructDataForXSTable();
            $scope.resultPanelData.additionalRepayment = parseFloat(
              getParsed($scope.repay.additionalPayment),
            );
            $scope.resultPanelData.additionPaymentFrequency =
              $scope.repay.additionPaymentFrequency;
            const year = $scope.calculationObject.YearsSaved;
            let yearSaved = '';
            let monthSaved = '';
            if (year > 1) {
              yearSaved = `${year}Years`;
            } else {
              yearSaved = `${year}Year`;
            }
            const month = $scope.calculationObject.MonthsSaved;
            if (month > 1) {
              monthSaved = `${month}Months`;
            } else {
              monthSaved = `${month}Month`;
            }
            $scope.timeSaved = `${yearSaved} ${monthSaved}`;

            if ($scope.repay.frequency === 'Monthly') {
              $scope.resultPanelData.frequency = 'Monthly';
            } else if ($scope.repay.frequency === 'Weekly') {
              $scope.resultPanelData.frequency = 'Weekly';
            } else {
              $scope.resultPanelData.frequency = 'Fortnightly';
            }
          });
      };

      $scope.constructDataForGraph = function () {
        const arrTotalToPay = [];
        const arrPrincipalToPay = [];
        let data;
        $scope.labels = [];
        $scope.graphData = [];

        for (
          let i = 0;
          i < $scope.calculationObject.RepaymentTableArrayChart.length;
          i++
        ) {
          data = $scope.calculationObject.RepaymentTableArrayChart[i];
          arrTotalToPay.push(_.toInteger(data[7]).toFixed(2));
          arrPrincipalToPay.push(_.toInteger(data[13]).toFixed(2));
          $scope.labels.push(i);
        }

        $scope.data = [arrPrincipalToPay, arrTotalToPay];
        $scope.graphData = $scope.data;
      };

      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.constructDataForXSTable = function () {
        let maxPeriod = 0;
        let yrCount;
        let prdCount;
        let timeSavedSet;
        let row;
        let yearDiff;
        let periodDiff;
        switch ($scope.repay.frequency) {
          case 'Monthly':
            maxPeriod = 12;
            break;
          case 'Weekly':
            maxPeriod = 52;
            break;
          case 'Fortnightly':
            maxPeriod = 26;
            break;
          default:
            break;
        }

        $scope.repaymentTableArrayXs = [];

        yrCount = 1;
        prdCount = 1;
        const splicedArray = [];
        angular.copy(
          $scope.calculationObject.RepaymentTableArray,
          splicedArray,
        );
        splicedArray.splice(0, 1);
        timeSavedSet = false;
        const firstRow = splicedArray[0];
        const prdTextPrefix =
          REPAYMENT_PERIOD[$scope.repay.frequency] || 'Period';
        $scope.totalMonthlyRepayment = firstRow[8];
        $scope.interestSaved = $scope.calculationObject.InterestSaved;
        for (const [i, element] of splicedArray.entries()) {
          if (prdCount > maxPeriod) {
            prdCount = 1;
            yrCount += 1;
          }

          row = element;
          if (parseInt(row[13], 10) === 0 && !timeSavedSet) {
            yearDiff = $scope.repay.years - yrCount - 1;
            periodDiff = maxPeriod - prdCount;
            if (yearDiff <= 0) {
              yearDiff = '';
            } else {
              yearDiff += yearDiff > 1 ? ' Years ' : ' Year ';
            }

            if (periodDiff <= 0) {
              periodDiff = '';
            } else {
              periodDiff = `${periodDiff} ${$scope.repay.frequency.slice(
                0,
                -2,
              )}${periodDiff > 1 ? 's' : ''}`;
            }

            timeSavedSet = true;
          }

          $scope.repaymentTableArrayXs.push({
            id: i,
            selectText: `Year ${yrCount} ${prdTextPrefix} ${prdCount}`,
            yearText: `Year ${yrCount}`,
            periodText: `${prdTextPrefix} ${prdCount}`,
            RepaymentAmount: row[8],
            PrincipalPaid: row[9],
            InterestPaid: row[10],
            AccumulativePrincipalPaid: row[11],
            AccumulativeInterestPaid: row[12],
            PrincipalToPay: row[13],
          });

          prdCount += 1;
        }
        $scope.selectedRepaymentXs = $scope.repaymentTableArrayXs[0];
      };
      $scope.openPdfPreviewModal = function (size) {
        $uibModal.open({
          templateUrl:
            '/assets/views/loanCalculator/partials/add_repay_pdf_preview.html',
          resolve: {
            graphData() {
              return $scope.graphData;
            },
            datasetOverride() {
              return $scope.datasetOverride;
            },
            options() {
              return $scope.options;
            },
            labels() {
              return $scope.labels;
            },
            colors() {
              return $scope.colors;
            },
            loanAmount() {
              return $scope.repay.loan;
            },
            years() {
              return $scope.repay.years;
            },
            interestRate() {
              return $scope.repay.r;
            },
            frequency() {
              return $scope.repay.frequency;
            },
            totalMonthlyRepayment() {
              return $scope.totalMonthlyRepayment;
            },
            resultPanelFreqData() {
              return $scope.resultPanelData.frequency;
            },
            resultPanelAdditionalRepayment() {
              return $scope.resultPanelData.additionalRepayment;
            },
            interestSaved() {
              return $scope.calculationObject.InterestSaved;
            },
            timeSaved() {
              return $scope.timeSaved;
            },
            series() {
              return $scope.series;
            },
          },
          controller: 'AdditionalPdfPreviewModalCtrl',
          size,
        });
      };

      function getLendersList() {
        loanToolsService.getLendersList().then((response) => {
          $scope.lenderList = response.data;
        });
      }

      function init() {
        $scope.loadTable();
        getLendersList();
      }

      init();
    },
  );
