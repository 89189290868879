import { parseToInt10 } from 'Common/utilities/parse';
import { showActionConfirmation } from 'Common/utilities/alert';

export default class InsuranceFileCtrl {
  constructor(insuranceModalApplicationService, insuranceFileService) {
    'ngInject';

    this.insuranceModalApplicationService = insuranceModalApplicationService;
    this.insuranceFileService = insuranceFileService;
  }

  $onInit() {
    this.getInsuranceFiles();
  }

  openInsuranceFile(file) {
    this.insuranceModalApplicationService
      .openInsuranceFile({
        familyId: parseToInt10(this.familyId),
        file,
      })
      .result.then(({ refresh }) => {
        refresh && this.getInsuranceFiles();
      });
  }

  getInsuranceFiles() {
    this.isLoading = true;
    this.insuranceFileService
      .getInsuranceFiles(this.familyId)
      .then((data = []) => {
        this.files = [...data];
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  deleteInsuranceFile(fileId) {
    showActionConfirmation(
      'Are you sure?',
      'This insurance file will be removed from your list',
      () =>
        this.insuranceFileService
          .deleteInsuranceFile(fileId)
          .then(() => this.getInsuranceFiles()),
    );
  }
}
