import { appendEmptySelect } from 'Common/utilities/options';

export default class InsuranceFileDropdownCtrl {
  constructor(insuranceFileService) {
    'ngInject';
    this.insuranceFileService = insuranceFileService;
  }

  $onInit() {
    this.getInsuranceFiles();
    this.fileId = 0;
    this.insuranceFiles = [];
  }

  getInsuranceFiles() {
    this.insuranceFileService
      .getInsuranceFiles(this.familyId)
      .then((data = []) => {
        this.insuranceFiles = appendEmptySelect(data, {
          name: 'Please select insurance file',
          id: 0,
        });
        this.fileId = 0;
      });
  }
}
