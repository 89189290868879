import moment from 'moment';
import { CAPS_DD_MMMM_YYYY } from 'Common/constants/dateConfigs';

export function brokerBuilderForUI(data) {
  return {
    brokerId: parseInt(data.BrokerId, 10),
    fullName: data.FullName,
    lastName: data.LastName,
    firstName: data.FirstName,
    middleName: data.MiddleName,
    preferredName: data.PreferredName,
    BrokerFullName: data.FullName,
    BrokerID: parseInt(data.BrokerId, 10),
  };
}

export const preApprovalCompletionBuilderForMyCRM = (data) => {
  return {
    CurrentEventDate: data.currentEventDate,
    NewEventDate: data.newEventDate,
    LoanId: data.loanId,
    IsCompleted: data.isCompleted,
  };
};

export function templateInsuranceBuilderForUI(data) {
  return {
    ModuleID: data.id + 7,
    ModuleName: data.label,
  };
}

export function sendMailDocBuilderForUI(data) {
  return {
    contentType: data.ContentType,
    documentContent: data.DocumentContent,
    documentExists: data.DocumentExists,
    imageUploadType: data.ImageUploadType,
    isSvg: data.IsSvg,
    name: data.Name,
    numberPages: data.NumberPages,
    size: data.Size,
    type: data.Type,
  };
}

export function postCompletionBuilderForMyCRM(data, type) {
  return {
    CustomerCareType: type,
    SourceTable: data.sourceTable,
    TableRecordID: data.tableRecordID,
    IsCompleted: data.isComplete,
    FamilyID: data.familyID,
  };
}

export function fixedRateExpiryListBuilderForUI(data) {
  return {
    fixedRateExpiryList: data.FixedRateExpiryList.map((obj) => {
      return {
        familyID: obj.FamilyID,
        customer: obj.Customer,
        lenderID: obj.LenderID,
        lender: obj.Lender,
        rateType: obj.RateType,
        country: obj.Country,
        expiryDate: obj.ExpiryDate,
        loanAmount: obj.LoanAmount,
        isComplete: obj.isComplete,
        isChecked: obj.isComplete,
        loanID: obj.LoanID,
        email: obj.Email,
        mobile: obj.Mobile,
        loanStructureID: obj.LoanStructureID,
        communicationSent: obj.CommunicationSent,
        isEntity: obj.IsEntity,
      };
    }),
    totalCount: data.TotalCount,
  };
}

export function sendFixedRateEmailBuilderForUI(data, notiHeader) {
  return {
    FamilyID: data.familyID,
    FamilyFullname: data.customer,
    LoanID: data.loanID,
    EmailAddress: data.email,
    NotificationHeader: notiHeader,
    Attachment: data.attachments,
    CustomEmailSubject: data.subject,
    CustomEmailBody: data.body,
    LoanStructureID: data.loanStructureID,
    AdviserEmailAddress: data.adviserEmailAddress,
    AdviserName: data.adviserName,
  };
}

export function sendMailBuilderForUI(data, notiHeader) {
  if (data.FamilyID) {
    return {
      FamilyID: data.FamilyID,
      FamilyFullname: data.Customer || data.FamilyFullName,
      LoanID: data.LoanID,
      LoanStructureId: data.LoanStructureId,
      EmailAddress: data.Email,
      SourceTable: data.SourceTable,
      TableRecordID: data.TableRecordID,
      NotificationHeader: notiHeader,
    };
  }
  return {
    FamilyID: data.familyID,
    FamilyFullname: data.customer || '',
    LoanID: data.loanID || '',
    LoanStructureId: data.loanStructureId || '',
    LoanStructureID: data.loanStructureID || '',
    EmailAddress: data.email,
    NotificationHeader: notiHeader,
    SourceTable: data.sourceTable || '',
    TableRecordID: data.tableRecordIDCSV || data.tableRecordID,
  };
}

export function sendInsuranceEmailBuilderForUI(data, notiHeader) {
  return {
    FamilyID: data.familyID,
    FamilyFullname: data.customer,
    SourceTable: data.sourceTable,
    TableRecordID: data.tableRecordID,
    EmailAddress: data.email,
    Attachment: data.attachments,
    CustomEmailSubject: data.subject,
    CustomEmailBody: data.body,
    NotificationHeader: notiHeader,
  };
}

export function sendFixedRateSmsBuilderForUI(data, notiHeader, callingCode) {
  return {
    FamilyFullname: data.customer,
    LoanID: data.loanID,
    FamilyID: data.familyID,
    MobileNumber: callingCode + data.mobile,
    NotificationHeader: notiHeader,
  };
}
export function sendFixedRateSmsEmailBuilderForUI(data, callingCode) {
  return {
    EmailAddress: data.email,
    FamilyFullname: data.customer,
    MobileNumber: callingCode + data.mobile,
  };
}

export function sendInsuranceSmsBuilderForUI(data, notiHeader, callingCode) {
  return {
    FamilyFullname: data.customer,
    SourceTable: data.sourceTable,
    TableRecordID: data.tableRecordID,
    HeaderRecordID: data.headerRecordID,
    FamilyID: data.familyID,
    MobileNumber: callingCode + data.mobile,
    NotificationHeader: notiHeader,
  };
}
// eslint-disable-next-line sonarjs/no-identical-functions
export function sendInsuranceSmsEmailBuilderForUI(data, callingCode) {
  return {
    EmailAddress: data.email,
    FamilyFullname: data.customer,
    MobileNumber: callingCode + data.mobile,
  };
}

export function loadingExclusionListBuilderForUI(data) {
  return {
    sourceTable: data.SourceTable,
    tableRecordID: data.TableRecordID,
    familyID: data.FamilyID,
    customer: data.Customer,
    insurer: data.Insurer,
    benefitTypeID: data.BenefitTypeID,
    benefitType: data.BenefitType,
    coverAmount: data.CoverAmount,
    loadingAmount: data.LoadingAmount,
    loadingPercentage: data.LoadingPercentage,
    reviewDate: data.ReviewDate,
    completedDate: data.CompletedDate,
    brokerID: data.BrokerID,
    providerID: data.ProviderID,
    totalCount: data.TotalCount,
    email: data.Email,
    mobile: data.Mobile,
    country: data.Country,
    communicationSent: data.CommunicationSent,
    policyId: data.PolicyID,
  };
}

export function issuedFollowupListBuilderForUI(data) {
  return {
    headerRecordID: data.HeaderRecordID,
    sourceTable: data.SourceTable,
    tableRecordIDCSV: data.TableRecordIDCSV,
    familyID: data.FamilyID,
    customer: data.Customer,
    insurer: data.Insurer,
    benefitTypeIDCSV: data.BenefitTypeIDCSV,
    benefitTypeCSV: data.BenefitTypeCSV,
    policyNumber: data.PolicyNumber,
    commencementDate: data.CommencementDate,
    completedDate: data.CompletedDate,
    brokerID: data.BrokerID,
    providerID: data.ProviderID,
    totalCount: data.TotalCount,
    email: data.Email,
    mobile: data.Mobile,
    country: data.Country,
    communicationSent: data.CommunicationSent,
  };
}

export function dateRangeBuilderForUI(data) {
  return {
    id: data.Id,
    title: data.Title,
    type: data.Type,
    value: data.Value,
  };
}

export function inArrearsListBuilderForUI(data) {
  return {
    sourceTable: data.SourceTable,
    tableRecordID: data.TableRecordID,
    familyID: data.FamilyID,
    customer: data.Customer,
    insurer: data.Insurer,
    policyNumber: data.PolicyNumber,
    commencementDate: data.CommencementDate,
    coverAmount: data.CoverAmount,
    completedDate: data.CompletedDate,
    brokerID: data.BrokerID,
    providerID: data.ProviderID,
    totalCount: data.TotalCount,
    email: data.Email,
    mobile: data.Mobile,
    country: data.Country,
    communicationSent: data.CommunicationSent,
    policyId: data.PolicyID,
  };
}

// eslint-disable-next-line sonarjs/no-identical-functions
export function currentClaimListBuilderForUI(data) {
  return {
    sourceTable: data.SourceTable,
    tableRecordID: data.TableRecordID,
    claimEventId: data.ClaimEventId,
    familyID: data.FamilyID,
    customer: data.Customer,
    insurer: data.Insurer,
    policyNumber: data.PolicyNumber,
    commencementDate: data.CommencementDate,
    coverAmount: data.CoverAmount,
    completedDate: data.CompletedDate,
    brokerID: data.BrokerID,
    providerID: data.ProviderID,
    totalCount: data.TotalCount,
    email: data.Email,
    mobile: data.Mobile,
    country: data.Country,
    communicationSent: data.CommunicationSent,
    policyId: data.PolicyID,
  };
}

export function annualReviewsListBuilderForUI(data) {
  return {
    familyID: data.FamilyID,
    customer: data.Customer,
    reviewMonth: data.ReviewMonth,
    reviewMonthFormatted: moment(data.ReviewMonth, 'M').format('MMM'),
    isCurrentMonth:
      moment(data.ReviewMonth, 'M').format('MMM') === moment().format('MMM'),
    reviewYear: data.ReviewYear,
    brokerID: data.BrokerID,
    email: data.Email,
    mobile: data.Mobile,
    country: data.Country,
    totalCount: data.TotalCount,
    communicationSent: data.CommunicationSent,
  };
}

export function deferralReviewListBuilderForUI(data) {
  return {
    benefitType: data.BenefitType,
    benefitTypeID: data.BenefitTypeID,
    brokerID: data.BrokerID,
    completedDate: data.CompletedDate,
    country: data.Country,
    coverAmount: data.CoverAmount,
    customer: data.Customer,
    email: data.Email,
    familyID: data.FamilyID,
    insurer: data.Insurer,
    mobile: data.Mobile,
    providerID: data.ProviderID,
    reviewDate: data.ReviewDate,
    sourceTable: data.SourceTable,
    tableRecordID: data.TableRecordID,
    totalCount: data.TotalCount,
    communicationSent: data.CommunicationSent,
  };
}

export function settlementFollowUpListBuilderForUI(data) {
  return {
    communicationSent: data.CommunicationSent,
    country: data.Country,
    customer: data.Customer,
    email: data.Email,
    familyID: data.FamilyID,
    lender: data.Lender,
    lenderID: data.LenderID,
    loanAmount: data.LoanAmount,
    loanID: data.LoanID,
    mobile: data.Mobile,
    rateType: data.RateType,
    settlementDate: data.SettlementDate,
    isComplete: data.isComplete,
  };
}

export function approvalExpiresListBuilderForUI(data) {
  return {
    communicationSent: data.CommunicationSent,
    country: data.Country,
    customer: data.Customer,
    email: data.Email,
    expiryDate: data.ExpiryDate,
    familyID: data.FamilyID,
    lender: data.Lender,
    lenderID: data.LenderID,
    loanAmount: data.LoanAmount,
    loanID: data.LoanID,
    mobile: data.Mobile,
    isComplete: data.isComplete,
  };
}

export function birthdaysListBuilderForUI(data) {
  return {
    age: data.Age,
    communicationSent: data.CommunicationSent,
    country: data.Country,
    dateOfBirth: data.DateOfBirth,
    email: data.Email,
    familyFullName: data.FamilyFullName,
    familyID: data.FamilyID,
    fullName: data.FullName,
    customer: data.FullName,
    mobile: data.Mobile,
    smsEnabled: data.SMSEnabled,
    isComplete: data.isComplete,
  };
}

export function annualReviewLoanListBuilderForUI(data) {
  return {
    familyID: data.FamilyID,
    familyFullName: data.FamilyFullName,
    customer: data.FamilyFullName,
    reviewMonth: data.ReviewMonth,
    reviewYear: data.ReviewYear,
    isComplete: data.isComplete,
    email: data.Email,
    mobile: data.Mobile,
    country: data.Country,
    communicationSent: data.CommunicationSent,
  };
}

export function mergeFieldsMapperForUI(data) {
  return (
    data && {
      familyId: data.familyID || data.FamilyID,
      templateContent: data.templateContent,
      templateSubject: data.templateSubject,
      loanStructureId: data.loanStructureID,
      policyId: data.policyId || data.headerRecordID,
      loan: {
        LoanID: data.loanID || data.LoanID,
      },
    }
  );
}

export function interestOnlyExpiryListBuilderForUI(data) {
  return {
    interestOnlyExpiryList: data.InterestOnlyExpiryList.map((obj) => {
      return {
        familyID: obj.FamilyID,
        customer: obj.Customer,
        lenderID: obj.LenderID,
        lender: obj.Lender,
        rateType: obj.RateType,
        country: obj.Country,
        expiryDate: obj.ExpiryDate,
        loanAmount: obj.LoanAmount,
        isComplete: obj.isComplete,
        isChecked: obj.isComplete,
        loanID: obj.LoanID,
        email: obj.Email,
        mobile: obj.Mobile,
        loanStructureID: obj.LoanStructureID,
        communicationSent: obj.CommunicationSent,
        loanType: obj.LoanType,
      };
    }),
    totalCount: data.TotalCount,
  };
}

export function dateRangeOptionBuilderForUI(data = {}) {
  const from = moment().format(CAPS_DD_MMMM_YYYY);
  const to = moment()
    .add(parseInt(data.value, 10), 'days')
    .format(CAPS_DD_MMMM_YYYY);
  return {
    ...data,
    from,
    to,
    text: data.title || '',
    range: [from, to],
  };
}
