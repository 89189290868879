import angular from 'angular';
import BusinessService from './businessService';
import BrokerSupportService from './brokerSupportService';
import CommissionService from './commissionService';
import CommunicateService from './communicateService';
import ConnectService from './connectService';
import ContactInsuranceService from './contactInsuranceService';
import CustomerCareService from './customerCareService';
import CustomerCareInsuranceService from './customerCareInsuranceService';
import DashboardService from './dashboardService';
import FinancialCalculatorService from './financialCalculatorService';
import TasksService from './tasksService';
import FundingCalculatorService from './fundingCalculatorService';
import GeneralService from './generalService';
import InsuranceAppService from './insuranceAppService';
import InsuranceProfilerService from './insuranceProfilerService';
import InsuranceQuoteService from './insuranceQuoteService';
import InsuranceApplicationService from './insuranceApplicationService';
import LendingScenarioService from './lendingScenarioService';
import LoanApplicationServices from './loanApplicationServices';
import LoanProfilerService from './loanProfilerService';
import LoanToolsService from './loanToolsService';
import LoanStructureService from './loanStructureService';
import NextGenLoanAppService from './nextGenLoanAppService';
import NotificationSidebarService from './notificationSidebarService';
import NpsService from './npsService';
import OptionsService from './optionsService';
import RateService from './rateService';
import ReportService from './reportService';
import UserService from './userService';
import AliService from './aliService';
import PepperService from './pepperService';
import SubscriptionService from './subscriptionService';
import ESignService from './eSignService';
import IFinanceAppService from './iFinanceAppService';
import SelectOptionsService from './selectOptionsService';
import PipelineService from './pipelineService';
import CreditChecksService from './creditChecksService';
import CorporateModelService from './corporateModelService';
import JasperService from './jasperService';
import LoanLenderSubmissionService from './loanLenderSubmissionService';
import LoanAppPdfService from './loanAppPdfService';
import LoanOpportunityService from './loanOpportunityService';
import BriServices from './briServices';
import ContactModelService from './contactModelService';
import AirTableModelService from './airTableModelService';
import DependantModelService from './dependantModelService';
import UserAccountModelService from './userAccountModelService';
import LoanSplitModelService from './loanSplitModelService';
import FinancialGoalModelService from './financialGoalModelService';
import LenderModelService from './lenderModelService';
import ServiceabilityService from './serviceabilityService';
import ReferralService from './referralService';
import ReferralModelService from './referralModelService';
import ProviderInformationService from './providerInformationService';
import ReleaseNotesService from './releaseNotesService';
import InsurancePipelineService from './insurancePipelineService';
import LoanScenarioModelService from './loanScenarioModelService';
import RayWhiteService from './rayWhiteService';
import HelloBookService from './helloBookService';
import InsuranceFireAndGeneralService from './insuranceFireAndGeneralService';
import GamePlanService from './gamePlanService';
import BluestoneService from './bluestoneService';
import KiwiService from './kiwiService';
import ProductFinderModelService from './productFinderModelService';
import SegmentExportFilterService from './segmentExportFilterService';
import ContactsNotesService from './contactsNotesService';
import LoanFileService from './loanFileService';
import DocumentCollectionService from './documentCollectionService';
import LoanVariationsService from './loanVariationsService';
import DealViewTaskService from './dealViewTaskService';
import LoanObjectivesService from './loanObjectivesService';
import InsuranceFactFindService from './insuranceFactFindService';
import HandoverPackModelService from './handoverPackModelService';
import AggregatorService from './aggregatorService';
import InsuranceFileService from './insuranceFileService';
import AutomationRuleModelService from './automationRuleModelService';

export default angular
  .module('common.models', [])
  .service('businessService', BusinessService)
  .service('brokerSupportService', BrokerSupportService)
  .service('commissionService', CommissionService)
  .service('communicateService', CommunicateService)
  .service('connectService', ConnectService)
  .service('contactInsuranceService', ContactInsuranceService)
  .service('customerCareService', CustomerCareService)
  .service('customerCareInsuranceService', CustomerCareInsuranceService)
  .service('dashboardService', DashboardService)
  .service('financialCalculatorService', FinancialCalculatorService)
  .service('fundingCalculatorService', FundingCalculatorService)
  .service('tasksService', TasksService)
  .service('generalService', GeneralService)
  .service('insuranceAppService', InsuranceAppService)
  .service('insuranceProfilerService', InsuranceProfilerService)
  .service('insuranceQuoteService', InsuranceQuoteService)
  .service('insuranceApplicationService', InsuranceApplicationService)
  .service('lendingScenarioService', LendingScenarioService)
  .service('loanApplicationServices', LoanApplicationServices)
  .service('loanProfilerService', LoanProfilerService)
  .service('loanStructureService', LoanStructureService)
  .service('loanToolsService', LoanToolsService)
  .service('nextGenLoanAppService', NextGenLoanAppService)
  .service('notificationSidebarService', NotificationSidebarService)
  .service('npsService', NpsService)
  .service('optionsService', OptionsService)
  .service('rateService', RateService)
  .service('reportService', ReportService)
  .service('userService', UserService)
  .service('aliService', AliService)
  .service('pepperService', PepperService)
  .service('subscriptionService', SubscriptionService)
  .service('eSignService', ESignService)
  .service('iFinanceAppService', IFinanceAppService)
  .service('selectOptionsService', SelectOptionsService)
  .service('pipelineService', PipelineService)
  .service('creditChecksService', CreditChecksService)
  .service('corporateModelService', CorporateModelService)
  .service('jasperService', JasperService)
  .service('loanLenderSubmissionService', LoanLenderSubmissionService)
  .service('loanAppPdfService', LoanAppPdfService)
  .service('loanOpportunityService', LoanOpportunityService)
  .service('briServices', BriServices)
  .service('contactModelService', ContactModelService)
  .service('airTableModelService', AirTableModelService)
  .service('dependantModelService', DependantModelService)
  .service('userAccountModelService', UserAccountModelService)
  .service('loanSplitModelService', LoanSplitModelService)
  .service('financialGoalModelService', FinancialGoalModelService)
  .service('lenderModelService', LenderModelService)
  .service('serviceabilityService', ServiceabilityService)
  .service('referralService', ReferralService)
  .service('referralModelService', ReferralModelService)
  .service('providerInformationService', ProviderInformationService)
  .service('releaseNotesService', ReleaseNotesService)
  .service('insurancePipelineService', InsurancePipelineService)
  .service('rayWhiteService', RayWhiteService)
  .service('helloBookService', HelloBookService)
  .service('insuranceFireAndGeneralService', InsuranceFireAndGeneralService)
  .service('gamePlanService', GamePlanService)
  .service('insuranceApplicationService', InsuranceApplicationService)
  .service('loanScenarioModelService', LoanScenarioModelService)
  .service('bluestoneService', BluestoneService)
  .service('kiwiService', KiwiService)
  .service('productFinderModelService', ProductFinderModelService)
  .service('segmentExportFilterService', SegmentExportFilterService)
  .service('loanFileService', LoanFileService)
  .service('contactsNotesService', ContactsNotesService)
  .service('loanVariationsService', LoanVariationsService)
  .service('dealViewTaskService', DealViewTaskService)
  .service('loanObjectivesService', LoanObjectivesService)
  .service('documentCollectionService', DocumentCollectionService)
  .service('handoverPackModelService', HandoverPackModelService)
  .service('aggregatorService', AggregatorService)
  .service('insuranceFactFindService', InsuranceFactFindService)
  .service('automationRuleModelService', AutomationRuleModelService)
  .service('insuranceFileService', InsuranceFileService).name;
