import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import { displayError, toastError } from 'Common/utilities/alert';
import { deleteTemplateTaskUtil } from './util/businessCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('BusinessCtrl', function BusinessCtrl(
      $scope,
      $uibModal,
      toaster,
      $timeout,
      uiService,
      businessService,
      optionsService,
      contactService,
      $controller,
      $state,
      stateService,
      automationRuleModelService,
    ) {
      $controller('TaskTemplateCtrl', { $scope });

      $scope.uiService = uiService;
      $scope.tempObject = {};
      $scope.isShowDataDiv = true;

      const populateActiveUserId = () => {
        contactService.getUserInfo().then((response) => {
          if (!response || !response.data) {
            return;
          }

          $scope.activeUserId = response.data.FamilyId;
        });
      };

      const taskOrderChanged = (event) => {
        if (!event) {
          return;
        }

        const { sortableScope, itemScope } = event.source;
        const data = [];

        sortableScope.modelValue.forEach((task, key) => {
          data.push({
            TaskSettingsId: task.TaskSettingsID,
            Orderby: key,
          });
        });

        businessService.taskOrderSet(itemScope.modelValue.TaskTemplateID, data);
      };
      // eslint-disable-next-line unicorn/consistent-function-scoping
      const taskDragging = (event) => {
        event.source.itemScope.element.addClass(
          'business-template-task-dragging',
        );
      };

      $scope.templateDragListener = {
        accept(sourceItemHandleScope, destSortableScope) {
          return (
            sourceItemHandleScope.itemScope.sortableScope.$id ===
            destSortableScope.$id
          );
        },
        orderChanged(event) {
          taskOrderChanged(event);
        },
        dragStart(event) {
          taskDragging(event);
        },
        clone: false,
        allowDuplicates: false,
        additionalPlaceholderClass: 'task-item',
      };
      // For Open Tab
      $scope.selectTab = (selectedTab) => uiService.selectTab(selectedTab);

      const activeTabOnMount = $state.params.activeTab || 'task-templates';
      $scope.selectTab(activeTabOnMount);
      $scope.selectedTab = activeTabOnMount;
      $scope.subType = $state.params.subType;

      $scope.DueDateOptions = {
        DateCreated: '1',
        RelativeToDate: '2',
        RelativeToAnotherTask: '3',
      };

      $scope.currentIndex = 0;
      $scope.slickConfig = {
        method: {},
        enabled: true,
        autoplay: false,
        draggable: false,
        dots: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 300,
        swipe: true,
        swipeToSlide: true,
        event: {
          afterChange(event, slick, currentSlide) {
            $scope.templateIdInMobile = 0;
            $scope.currentIndex = currentSlide;
            const itemMobile =
              $scope.taskTemplateDetailList[$scope.currentIndex];
            if (itemMobile) {
              $scope.templateIdInMobile = itemMobile.TaskTemplateID;
            }
          },
          init(event, slick) {
            slick.slickGoTo($scope.currentIndex); // slide to correct index when init
          },
        },
      };

      // Tab Selection
      $scope.selectTabUpdate = (tab) => {
        $scope.selectedTab = tab;
        $state.params.activeTab = tab;
        stateService.transition($state.current, $state.params);
      };

      // Filters
      $scope.filterInitial = (...args) => {
        let i;
        let initials = '';
        for (i = 0; i < args.length; i++) {
          if (!_.isNil(args[i]) && _.isString(args[i])) {
            initials = `${initials}${args[i].charAt(0).toUpperCase()}`;
          }
        }
        return initials;
      };

      // Get Template Details
      // --------------------------------------------------------------------------------------

      $scope.dueDateTypes = [];
      optionsService.getDueDateTypes().then((response) => {
        $scope.dueDateTypes = (response && response.data) || '';
      });

      $scope.closeTooltip = (taskTemplate) => {
        taskTemplate.errorMessage = '';
        taskTemplate.isInvalidTemplateName = false;
      };

      $scope.setTaskTemplate = (templateId, taskTemplate) => {
        taskTemplate.isInvalidTemplateName = false;
        if (!taskTemplate.name) {
          $timeout(() => {
            taskTemplate.isInvalidTemplateName = true;
          }, 100);
          return;
        }

        taskTemplate.isEditMode = false;
        const postData = {
          TemplateID: templateId,
          TemplateName: taskTemplate.name,
        };
        businessService.TaskTemplateSet(postData).then((response) => {
          if (response.succeeded) {
            if (parseInt(templateId, 10) === 0) {
              $scope.tempObject.isShowAddTemplateDiv = false;
              $scope.tempObject.name = '';
              toaster.pop('success', 'Added', 'Template added successfully');
            } else {
              toaster.pop(
                'success',
                'Updated',
                'Template updated successfully',
              );
            }

            $scope.getTaskTemplateListFullDetails();
          } else {
            taskTemplate.isInvalidTemplateName = true;
            taskTemplate.errorMessage = response.messages.length
              ? response.messages[0]
              : '';
          }
        });
      };

      $scope.deleteTaskTemplate = (templateId) => {
        $scope.isShowDataDiv = false;
        swal(
          {
            title: 'Are you sure?',
            text: 'This template will be removed from your template list',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: true,
          },
          (confirm) => {
            if (confirm) {
              automationRuleModelService
                .getAutomationRules({ templateId })
                .then((automationRules = []) => {
                  if (uiService.newPipelineTask && automationRules.length) {
                    toastError(
                      'Unable to delete this template because it was linked to an automation rule.',
                    );
                    return;
                  }
                  businessService
                    .TaskTemplateDelete(templateId)
                    .then(() => {
                      swal(
                        'Deleted',
                        'Template delete successfully',
                        'success',
                      );
                      $scope.getTaskTemplateListFullDetails();
                    })
                    .catch(displayError);
                });
            } else {
              $scope.isShowDataDiv = true;
            }
          },
        );
      };

      $scope.deleteTemplateTask = function (params, $event) {
        const dependenciesParams = {
          scope: $scope,
          toaster,
          businessService,
          displayError,
        };
        const utilArgs = {
          dependenciesParams,
          params,
        };
        deleteTemplateTaskUtil(utilArgs, $event);
      };

      $scope.hideMainDiv = () => {
        $scope.isShowDataDiv = false;
      };
      $scope.openModalForAddEditTaskInTemplate = (taskId, templateId) => {
        $scope.taskId = taskId;
        $scope.templateId = templateId;
        $uibModal.open({
          templateUrl:
            'assets/views/profileManagement/Business/modals/add_edit_task.html',
          controller: 'AddEditTaskTemplateCtrl',
          scope: $scope,
          windowClass: 'view-task-window',
        });
      };

      $scope.newZealandOnly = () => {
        return uiService.isCountry('New Zealand');
      };

      const init = () => {
        populateActiveUserId();
      };
      init();
    });
