import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import swal from 'sweetalert';
import { DEFAULT } from 'Common/constants/emailTypes';
import {
  CONTACT_STATUS,
  CONTACT_STATUS_LIST,
} from 'Common/constants/contactStatus';
import { CONTACT_TYPE } from 'Common/constants/contactType';
import { FLOATING_RATE_TYPE } from 'Common/constants/countrySpecific';
import {
  newSegmentFilters,
  exportRecipientsToCSVUtil,
  getAdvisers,
  disableContactRecordType,
  selectedDefaultValues,
  initializeEmailCampaign,
  hideMarketingConsent,
} from './util/newEmailCampaignCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('NewEmailCampaignCtrl', function NewEmailCampaignCtrl(
      $scope,
      $filter,
      $timeout,
      $window,
      communicateService,
      colorService,
      toaster,
      NgTableParams,
      userService,
      corporateService,
      contactService,
      $uibModal,
      $state,
      cfpLoadingBar,
      optionsService,
      referralsService,
      uiService,
      currentUserService,
      segmentExportFilterService,
      mycrmLookupService,
      configService,
      contactStatusService,
    ) {
      initializeEmailCampaign({ $scope, configService });
      $scope.hideMarketingConsent = hideMarketingConsent({ $scope });
      const confirmButtonColor = '#F68F8F';
      const lenderPlace = 0;
      const lastFilterEnquirySource = [];
      $scope.selectedRecipientList = [];
      $scope.CONTACT_STATUS_LIST = contactStatusService.filterStatusList(
        CONTACT_STATUS_LIST,
        uiService.viewOfAfileTurnedOn,
        'id',
      );
      $scope.filterStatus = {
        lending: false,
        insurance: false,
      };
      $scope.disableContactRecordType = disableContactRecordType({ $scope });
      $scope.isShowEmailCampaignReferrers = uiService.showEmailCampaignReferrers();
      newSegmentFilters({ $scope, segmentExportFilterService });
      angular.extend($scope, {
        totalContact: 0,
        isDownloading: false,
        totalPagingCount: 0,
        emailCampaignRepeater: [],
        campaignModel: {
          filterBy: 'client',
        },
        filterRefreshData: [
          {
            location: [],
            postCode: [],
            ageFrom: null,
            ageTo: null,
            gender: [],
            loanStatus: [],
            loanRateType: [],
            lender: [],
            contactStatus: [],
            categories: [],
            ratings: [],
            enquirySource: [],
            adviser: [],
            locationFilter: '',
            postCodeFilter: '',
            contactStatusFilter: '',
          },
        ],
        contactStatus: [
          { ContactStatusId: 1, ContactStatusDescription: 'Lead' },
          { ContactStatusId: 2, ContactStatusDescription: 'Application' },
          { ContactStatusId: 3, ContactStatusDescription: 'Existing Client' },
          {
            ContactStatusId: 4,
            ContactStatusDescription: 'Professional Partner',
          },
          { ContactStatusId: 5, ContactStatusDescription: 'Previous' },
        ],
        selectionStorage: [],
        isAllSelected: { val: true },
        currentPage: 1,
        pageSize: 15,
        allPageSize: 0,
        campaignRecipientList: [],
        selectedRecipientList: [],
        inProgress: false,
        displayECampFilter: false,
        selectedContacts: 0,
        propertyName: 'FamilyFullName',
        reverse: false,
        locationList: [],
        postCodeList: [],
        loanStatusListSelected: [],
        loanRateTypeList: [],
        loanRateTypeListSelected: [],
        lenderListSelected: [],
        categoryListSelected: [],
        ratingListSelected: [],
        enquirySourceList: {},
        enquirySourceListSelected: [],
        adviserList: {},
        insurance: {
          PolicyStatusList: [],
          CatergoriesList: [],
          ProviderList: [],
          selectedPolicyStatusList: [],
          selectedCatergoriesList: [],
          selectedProviderList: [],
          insurerIDs: [],
          insuranceStatusFilters: [],
          insuranceCategoryFilters: [],
        },
      });
      $scope.pagination = { currentPage: 1 };
      $scope.isDisabledAdviser = true;
      optionsService.getMostRecentLeads().then((response) => {
        if (!response || !response.data) {
          return;
        }
        $scope.mostRecentLeads = response.data;
        $scope.campaignModel.mostRecentLead = $scope.mostRecentLeads[0]; // set initial value
      });

      function getRandomBg() {
        return colorService.getRandomColor();
      }

      $scope.$watch(
        () => {
          $scope.windowWidth = $window.innerWidth;
          if ($scope.windowWidth > 992) {
            $scope.isMobileDisplayView = false;
          } else if ($scope.windowWidth <= 992) {
            $scope.isMobileDisplayView = true;
          }
          return $scope.windowWidth;
        },
        () => {
          return $scope.windowWidth;
        },
        true,
      );

      /** ****************************************************************** */
      $scope.downloadCSVFile = (data) => {
        $scope.isDownloading = false;

        const element = $window.document.createElement('a');
        element.setAttribute(
          'href',
          `data:${data.ContentType};base64,${data.DocumentContent}`,
        );
        element.setAttribute('download', data.Name);
        element.setAttribute('id', 'downloadCSVFile');

        element.style.display = 'none';
        $window.document.body.append(element);

        const eventTimeout = $timeout(() => {
          $window.document.querySelector('#downloadCSVFile').click();
          element.remove();
          $timeout.cancel(eventTimeout);
        });
        const loadingBarTimeout = $timeout(() => {
          cfpLoadingBar.complete();
          $timeout.cancel(loadingBarTimeout);
        }, 750);
      };

      const checkIfEmailCampaignIsCorp = (campaignModel) => {
        if (!campaignModel) {
          return 0;
        }
        return campaignModel.isCorporateUser
          ? _.get(
              campaignModel,
              'adviserUnderOrgObj.originalObject.FamilyId',
              0,
            )
          : _.get(
              $scope.placeholderFilter,
              'adviserListSelected[0].familyId',
              0,
            );
      };

      // eslint-disable-next-line unicorn/consistent-function-scoping
      const getStatusId = (campaignModel) => {
        if (!campaignModel) {
          return -1;
        }
        return campaignModel.statusId;
      };

      // eslint-disable-next-line unicorn/consistent-function-scoping
      const getOrgId = (campaignModel, key) => {
        if (
          !campaignModel ||
          !campaignModel.originalObject ||
          !campaignModel.originalObject[key]
        ) {
          return 0;
        }
        return campaignModel.originalObject[key];
      };

      // eslint-disable-next-line unicorn/consistent-function-scoping
      const getMostRecentLeads = (campaignModel, key) => {
        if (!campaignModel || !campaignModel[key]) {
          return 0;
        }
        return campaignModel[key];
      };

      const getEmailCampaignReferrersParams = (campaignModel) => {
        const adviserIdValue = checkIfEmailCampaignIsCorp(campaignModel);
        return {
          userId: 0,
          adviserId: adviserIdValue,
          isActive: campaignModel.contactReferrerStatusSelected
            ? getStatusId(campaignModel.contactReferrerStatusSelected)
            : -1,
          referrerOrgId: campaignModel.referrerOrgObj
            ? getOrgId(campaignModel.referrerOrgObj, 'ReferrerOrgId')
            : 0,
          adviserOrgId: campaignModel.adviserOrgObj
            ? getOrgId(campaignModel.adviserOrgObj, 'AdviserOrganizationId')
            : 0,
          recentLeadInDays: campaignModel.mostRecentLead
            ? getMostRecentLeads(campaignModel.mostRecentLead, 'from')
            : 0,
          recentLeadToInDays: campaignModel.mostRecentLead
            ? getMostRecentLeads(campaignModel.mostRecentLead, 'to')
            : 0,
        };
      };

      const getDownloadFileData = (qry) => {
        if ($scope.campaignModel.filterBy === 'referrers') {
          const emailCampaignParamsforDwnload = getEmailCampaignReferrersParams(
            $scope.campaignModel,
          );
          const {
            userId,
            isActive,
            referrerOrgId,
            adviserOrgId,
            adviserId,
            recentLeadInDays,
            recentLeadToInDays,
          } = emailCampaignParamsforDwnload;
          const totalPageNumber =
            $scope.selectedRecipientList.length !==
            $scope.campaignRecipientList.length
              ? $scope.selectedRecipientList.length
              : 0;
          communicateService
            .getEmailCampaignReferrersExcelExport(
              userId,
              isActive,
              referrerOrgId,
              adviserOrgId,
              adviserId,
              recentLeadInDays,
              recentLeadToInDays,
              $scope.pagination.currentPage,
              totalPageNumber,
            )
            .then(
              (response) => {
                if (!response || !response.data) {
                  return;
                }
                $scope.downloadCSVFile(response.data);
              },
              () => {
                toaster.pop(
                  'error',
                  'Something went wrong',
                  'Please try again',
                );
              },
            );
        } else {
          exportRecipientsToCSVUtil({ $scope, communicateService, query: qry });
        }
      };

      // eslint-disable-next-line unicorn/consistent-function-scoping
      const getKeys = (obj) => {
        return obj && Object.keys(obj);
      };

      // eslint-disable-next-line unicorn/consistent-function-scoping
      const createQry = (obj, keys) => {
        let str = '';
        keys.forEach((k) => {
          if (k === 'contactStatus') {
            // exclude contactStatus property
            return;
          }

          if (typeof obj[k] !== 'number') {
            if (!_.isEmpty(obj[k])) {
              if (str !== '') {
                str = `${str}&${k}=${obj[k]}`;
              } else {
                str = `?${k}=${obj[k]}`;
              }
            }
          } else if (!_.isNil(obj[k])) {
            if (str !== '') {
              str = `${str}&${k}=${obj[k]}`;
            } else {
              str = `?${k}=${obj[k]}`;
            }
          }
        });
        return str;
      };

      $scope.downloadSpreadsheet = () => {
        $scope.isDownloading = true;
        cfpLoadingBar.start();
        cfpLoadingBar.inc();
        const filterObj = _.first($scope.filterRefreshData);
        if (!_.isNil(filterObj.contactStatus)) {
          filterObj.contactStatusFilter = '';
        }
        filterObj.pageNumber = 1;
        if (
          $scope.isAllSelected.val &&
          typeof _.first($scope.campaignRecipientList).TotalCount !==
            'undefined'
        ) {
          filterObj.pageSize = Math.ceil(
            _.first($scope.campaignRecipientList).TotalCount,
          );
        } else {
          filterObj.pageSize = $scope.allPageSize;
        }
        let referralCategoryIds;
        let enquirySourceIds;
        if (filterObj.enquirySource) {
          const enquirySourceObj = Object.assign([], filterObj.enquirySource);
          referralCategoryIds = _.map(enquirySourceObj, 'ReferralCategoryId');
          referralCategoryIds = referralCategoryIds
            ? referralCategoryIds.toString()
            : '';
          enquirySourceIds = _.map(enquirySourceObj, 'EnquirySourceId');
          enquirySourceIds = enquirySourceIds
            ? enquirySourceIds.toString()
            : '';
        }
        const isSelectedAll = $scope.isAllSelected.val;
        const familyIds = $scope.selectedRecipientList.map(
          (item) => item.FamilyID,
        );
        const keys = getKeys(filterObj);
        const index = keys.indexOf('enquirySource');
        if (index > -1) {
          keys.splice(index, 1);
        }
        const qry = createQry(filterObj, keys);
        const qryByIds = familyIds.join(',');
        if ($scope.isAllSelected.val && $scope.selectedContacts > 500) {
          swal(
            {
              title: 'Downloading...',
              text:
                'It takes some time to download all recipients. Are you willing to wait?',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor,
              confirmButtonText: 'Yes!',
              closeOnConfirm: true,
            },
            (isConfirm) => {
              if (isConfirm) {
                getDownloadFileData(
                  `${qry}&familyIds=${qryByIds}&isSelectedAll=${isSelectedAll}&referralCategoryIds=${referralCategoryIds}&enquirySource=${enquirySourceIds}`,
                );
              } else {
                $scope.isDownloading = false;
                $timeout(() => {
                  cfpLoadingBar.complete();
                }, 750);
              }
            },
          );
        } else {
          getDownloadFileData(
            `${qry}&familyIds=${qryByIds}&isSelectedAll=${isSelectedAll}&referralCategoryIds=${referralCategoryIds}&enquirySource=${enquirySourceIds}`,
          );
        }
      };

      $scope.mailChimpFound = false;
      function checkMailChimp() {
        communicateService.getCampaignReports().then((response) => {
          if (response.data && !response.data.isError) {
            $scope.mailChimpFound = true;
          }
        });
      }

      angular.extend($scope, {
        selectTemplate(emailRecipient) {
          if ($scope.mailChimpFound) {
            $uibModal.open({
              templateUrl:
                '/assets/views/modals/mailchimpCampaignTemplate.html',
              controller: 'SelectTemplateModalCtrl',
              resolve: {
                emailRecipient: () => emailRecipient,
              },
            });
          } else {
            swal({
              title: 'No MailChimp Account',
              text:
                "It appears that you don't currently have a Mailchimp account connected - please contact Support to arrange",
              type: 'info',
            });
          }
        },
      });

      /** ****************************************************************** */

      angular.element($window).bind('resize', () => {
        $scope.windowWidth = $window.innerWidth;
      });

      /** ****************************************************************** */
      function showFilterVal() {
        $scope.displayECampFilter = !$scope.displayECampFilter;
      }

      function sortBy(propertyName) {
        $scope.reverse =
          propertyName !== null && $scope.propertyName === propertyName
            ? !$scope.reverse
            : false;
        $scope.propertyName = propertyName;
      }

      /** ****************************************************************** */
      function removeLocationList(item) {
        $scope.locationList.splice(item, 1);
        if (typeof _.first($scope.filterRefreshData) !== 'undefined') {
          _.first($scope.filterRefreshData).location.splice(item, 1);
        }
      }

      function removePostCodeList(item) {
        $scope.postCodeList.splice(item, 1);
      }

      /** ****************************************************************** */

      function pushLocation(value) {
        if (value) {
          $scope.locationList.push(value);
          _.first($scope.filterRefreshData).location.push(value);
          _.first($scope.filterRefreshData).locationFilter = '';
        }
      }

      /** ****************************************************************** */

      function pushPostCode(value) {
        if (!_.isUndefined(value) && value !== '') {
          $scope.postCodeList.push(value);
          _.first($scope.filterRefreshData).postCode.push(value);
          _.first($scope.filterRefreshData).postCodeFilter = '';
        }
      }

      function onSelectAll() {
        _.first($scope.filterRefreshData).loanStatus = [];
        for (let i = 0; i < $scope.loanStatusList.length; i++) {
          if ($scope.loanStatusList[i].LoanStatusID > -1) {
            $scope.loanStatusList[i].ticked = false;
          } else {
            $scope.loanStatusList[i].ticked = true;
          }
        }
      }

      function isOtherTicked(data) {
        let bool = false;
        $scope.loanStatusListSelected = [];
        data.forEach((val) => {
          if (val.LoanStatusID > -1 && val.ticked) {
            bool = true;
            _.first($scope.filterRefreshData).loanStatus.push(val.LoanStatusID);
          }
        });

        return bool;
      }

      function onItemSelect(data) {
        if (parseInt(data.LoanStatusID, 10) === -1) {
          let i;
          for (i = 0; i < $scope.loanStatusList.length; i++) {
            if ($scope.loanStatusList[i].LoanStatusID > -1) {
              $scope.loanStatusList[i].ticked = false;
            } else {
              $scope.loanStatusList[i].ticked = true;
            }
          }
        } else {
          let i;
          for (i = 0; i < $scope.loanStatusList.length; i++) {
            if (
              parseInt($scope.loanStatusList[i].LoanStatusID, 10) === -1 &&
              !isOtherTicked($scope.loanStatusList)
            ) {
              $scope.loanStatusList[i].ticked = true;
              $scope.loanStatusListSelected.push($scope.loanStatusList[i]);
            } else if ($scope.loanStatusList[i].LoanStatusID === -1) {
              $scope.loanStatusList[i].ticked = false;
            }
          }
        }
      }

      function getLoanRateTypes() {
        const floatingTypeName = currentUserService.isAU
          ? FLOATING_RATE_TYPE.AU
          : FLOATING_RATE_TYPE.DEFAULT;
        const otherOption = currentUserService.isNZ
          ? { type: 'Fixed', LoanRateTypeName: 'Fixed Rates' }
          : { type: 'Other', LoanRateTypeName: 'Other' };
        const rateTypes = [
          selectedDefaultValues(
            'All Loan Rate Types',
            'LoanRateTypeName',
            'type',
          ),
          { type: 'Floating', LoanRateTypeName: floatingTypeName },
          { ...otherOption },
        ];
        $scope.loanRateTypeList = rateTypes;
      }

      function onChangeAllSelection() {
        for (let i = 0; i < $scope.enquirySourceList.length; i++) {
          if (
            $scope.enquirySourceList[i].EnquirySourceId === 0 &&
            $scope.enquirySourceList[i].ReferralCategoryId === 0
          ) {
            $scope.enquirySourceList[i].ticked = true;
            _.first($scope.filterRefreshData).enquirySource = [
              $scope.enquirySourceList[i],
            ];
          } else {
            $scope.enquirySourceList[i].ticked = false;
          }
        }
      }

      function onSelectAllEnquirySource() {
        _.first($scope.filterRefreshData).enquirySource = [];
        onChangeAllSelection();
      }

      function isOtherTickedEnquirySource(data) {
        let bool = false;
        _.first($scope.filterRefreshData).enquirySource = [];
        data.forEach((val) => {
          if (val.ReferralCategoryId && val.ticked) {
            bool = true;
            _.first($scope.filterRefreshData).enquirySource.push(val);
          }
        });

        return bool;
      }

      function onItemSelectEnquirySource(data) {
        if (data.EnquirySourceId === 0 && data.ReferralCategoryId === 0) {
          onChangeAllSelection();
        } else {
          let i;
          for (i = 0; i < $scope.enquirySourceList.length; i++) {
            if (
              parseInt($scope.enquirySourceList[i].EnquirySourceId, 10) === 0 &&
              parseInt($scope.enquirySourceList[i].ReferralCategoryId, 10) ===
                0 &&
              !isOtherTickedEnquirySource($scope.enquirySourceList)
            ) {
              $scope.enquirySourceList[i].ticked = true;
              $scope.enquirySourceListSelected.push(
                $scope.enquirySourceList[i],
              );
            } else if (
              $scope.enquirySourceList[i].EnquirySourceId === 0 &&
              $scope.enquirySourceList[i].ReferralCategoryId === 0
            ) {
              $scope.enquirySourceList[i].ticked = false;
            }
          }
        }
      }

      function getUserInfo() {
        $scope.campaignModel.isCorporateUser = false;
        $scope.campaignModel.isClientHidden = false;
        userService.GetUserInfo().then((response) => {
          const userInfo = response.data;
          if (userInfo) {
            $scope.campaignModel.FamilyId = userInfo.FamilyId;
            $scope.campaignModel.isCorporateUser =
              response.data.AccessType === 5;
            if (userInfo.CountryId === 1 && userInfo.AccessType === 5) {
              $scope.campaignModel.filterBy = 'adviser';
              $scope.campaignModel.isClientHidden = true;
            }
          }
        });
      }

      $scope.onItemSelectCorp = (
        data,
        newEmailCampaignFieldName,
        source,
        selection,
      ) => {
        let selectionData = selection;
        _.map(source, (row) => {
          if (data[newEmailCampaignFieldName] === -1) {
            if (row[newEmailCampaignFieldName] === -1) {
              row.ticked = true;
              selectionData = row;
            } else {
              row.ticked = false;
            }
          } else {
            if (row[newEmailCampaignFieldName] === -1) {
              row.ticked = false;
            }
            if (
              selectionData.length === 0 &&
              row[newEmailCampaignFieldName] === -1
            ) {
              row.ticked = true;
            }
          }
        });
      };

      function getContactType() {
        contactService.getContactType().then((response) => {
          const data = _.filter(response.data, (tempData) => {
            return (
              parseInt(tempData.ContactTypeId, 10) !== CONTACT_TYPE.CLIENT &&
              parseInt(tempData.ContactTypeId, 10) !==
                CONTACT_TYPE.REFERRER_ORG &&
              parseInt(tempData.ContactTypeId, 10) !== CONTACT_TYPE.BUSINESS
            );
          }); // Remove Client and Referrer Org from the list
          _.map(data, (row) => {
            row.ticked = false;
          });
          const all = {
            ContactTypeId: -1,
            ContactTypeDesc: 'All Types',
            ticked: true,
          };
          data.unshift(all);
          $scope.campaignModel.contactTypeRaw = angular.copy(data);
          $scope.campaignModel.contactType = data;
          $scope.campaignModel.contactTypeSelected = data[0];
        });
      }

      $scope.resetContactType = () => {
        const data = angular.copy($scope.campaignModel.contactTypeRaw);
        $scope.campaignModel.contactType = data;
        $scope.campaignModel.contactTypeSelected = data[0];
      };

      function operatingCountriesGet() {
        corporateService.operatingCountriesGet().then((response) => {
          const data = response.data.splice(0, 3);
          _.map(data, (row) => {
            row.ticked = false;
          });
          const all = {
            CountryId: -1,
            CountryCode: '',
            CountryName: 'All Countries',
            CallingCode: '',
            ticked: true,
          };
          data.unshift(all);
          $scope.campaignModel.reportingCountryRaw = angular.copy(data);
          $scope.campaignModel.reportingCountry = data;
          $scope.campaignModel.reportingCountrySelected = data[0];
        });
      }

      $scope.resetOperatingCountry = () => {
        const data = angular.copy($scope.campaignModel.reportingCountryRaw);
        $scope.campaignModel.reportingCountry = data;
        $scope.campaignModel.reportingCountrySelected = data[0];
      };

      function reportingState() {
        corporateService.reportingStatesGet().then((response) => {
          const data = response.data;
          _.map(data, (row) => {
            row.ticked = false;
          });
          const all = {
            ReportingStateId: -1,
            ReportingStateName: 'All States',
            ticked: true,
          };
          data.unshift(all);
          $scope.campaignModel.reportingStateRaw = angular.copy(data);
          $scope.campaignModel.reportingState = data;
          $scope.campaignModel.reportingStateSelected = data[0];
        });
      }
      reportingState();

      $scope.resetReportingState = () => {
        const data = angular.copy($scope.campaignModel.reportingStateRaw);
        $scope.campaignModel.reportingState = data;
        $scope.campaignModel.reportingStateSelected = data[0];
      };

      function reportingRegion() {
        corporateService.reportingRegionsGet().then((response) => {
          const data = response.data;
          _.map(data, (row) => {
            row.ticked = false;
          });
          const all = {
            ReportingRegionId: -1,
            ReportingRegionName: 'All Regions',
            ticked: true,
          };
          data.unshift(all);
          $scope.campaignModel.reportingRegionRaw = angular.copy(data);
          $scope.campaignModel.reportingRegion = data;
          $scope.campaignModel.reportingRegionSelected = data[0];
        });
      }

      $scope.resetReportingRegion = () => {
        const data = angular.copy($scope.campaignModel.reportingRegionRaw);
        $scope.campaignModel.reportingRegion = data;
        $scope.campaignModel.reportingRegionSelected = data[0];
      };

      function getBrokerStatusList() {
        corporateService.brokerStatusListGet().then((response) => {
          const data = response.data;
          _.map(data, (row) => {
            row.ticked = false;
          });
          const all = {
            BrokerStatusId: -1,
            BrokerStatusName: 'All Status',
            ticked: true,
          };
          data.unshift(all);
          $scope.campaignModel.contactStatusRaw = angular.copy(data);
          $scope.campaignModel.contactStatusFilter = data;
          $scope.campaignModel.contactStatusSelected = data[0];
        });
      }

      const getContactReferrerStatus = () => {
        let data = [
          {
            statusId: 0,
            statusName: 'Inactive',
            ticked: false,
          },
          {
            statusId: 1,
            statusName: 'Active',
            ticked: false,
          },
        ];
        const all = {
          statusId: -1,
          statusName: 'All Status',
          ticked: true,
        };
        data = [all, ...data];
        $scope.campaignModel.contactReferrerStatus = [...data];
        $scope.campaignModel.contactReferrerStatusSelected = data[0];
      };

      $scope.referrerOrgListCampaign = [];
      $scope.searchAPIReferrerOrg = (searchText) => {
        if (searchText) {
          $scope.$broadcast('angucomplete-alt:clearInput', 'referrer');
          return referralsService
            .getReferrerOrgList(searchText)
            .then((response) => {
              const { data } = response;
              if (!data || !data.length) {
                return [];
              }
              const orgList = _.uniqBy(data, (i) =>
                [i.ReferrerOrgId, i.ReferrerOrgName].join(),
              );
              $scope.referrerOrgListCampaign = orgList.filter((row) => {
                return row.ReferrerOrgName && row.ReferrerOrgId;
              });
              return $scope.referrerOrgListCampaign;
            });
        }
      };

      $scope.campaignModel.adviserId = 0;
      const getAdvisersList = (adviserOrgId) => {
        corporateService
          .advisersUnderOrganizationGet(adviserOrgId)
          .then((response) => {
            if (!response || !response.data) {
              return;
            }
            $scope.adviserUnderOrganisationList = _.filter(
              response.data,
              (data) => {
                return data.FullName !== '';
              },
            );
            $scope.campaignModel.adviserUnderOrgObj = {
              originalObject: $scope.adviserUnderOrganisationList.find(
                (org) => org.FamilyId === $scope.campaignModel.adviserId,
              ),
            };
          });
      };

      $scope.campaignModel.adviserOrg = 0;
      const adviserOrganizationListGet = () => {
        corporateService.adviserOrganizationListGet().then((response) => {
          if (!response || !response.data) {
            return;
          }
          $scope.adviserOrganisationList = _.filter(response.data, (data) => {
            return data.AdviserOrganizatioName !== '';
          });
          $scope.campaignModel.adviserOrgObj = {
            originalObject: $scope.adviserOrganisationList.find(
              (org) =>
                org.AdviserOrganizationId === $scope.campaignModel.adviserOrg,
            ),
          };
        });
      };

      $scope.adviserOrgSelected = (selected) => {
        $scope.campaignModel.adviserOrgObj = 0;
        if (!selected || !selected.originalObject) {
          return;
        }
        $scope.isDisabledAdviser =
          !selected.originalObject.AdviserOrganizationId !== 0;
        $scope.campaignModel.adviserOrgObj = selected;
        getAdvisersList(selected.originalObject.AdviserOrganizationId);
      };

      $scope.resetContactStatus = () => {
        const data = angular.copy($scope.campaignModel.contactStatusRaw);
        $scope.campaignModel.contactStatusFilter = data;
        $scope.campaignModel.contactStatusSelected = data[0];
      };

      function getEnquirySource() {
        communicateService.inquirySourceListGet().then((response) => {
          const data = response.data;
          _.map(data, (row) => {
            row.ticked = false;
          });
          const all = {
            EnquirySourceId: -1,
            EnquirySource: 'All Sources',
            ticked: true,
          };
          data.unshift(all);
          $scope.campaignModel.enquirySourceRaw = angular.copy(data);
          $scope.campaignModel.enquirySource = data;
          $scope.campaignModel.enquirySourceSelected = data[0];
        });
      }

      $scope.resetEnquirySource = () => {
        const data = angular.copy($scope.campaignModel.enquirySourceRaw);
        $scope.campaignModel.enquirySource = data;
        $scope.campaignModel.enquirySourceSelected = data[0];
      };

      function getClientType() {
        corporateService.getClientType().then((response) => {
          const data = response.data;
          _.map(data, (row) => {
            row.ticked = false;
          });
          const all = {
            ClientTypeID: -1,
            ClientTypeName: 'All Types',
            ticked: true,
          };
          data.unshift(all);
          $scope.campaignModel.clientTypeRaw = angular.copy(data);
          $scope.campaignModel.clientType = data;
          $scope.campaignModel.clientTypeSelected = data[0];
        });
      }

      $scope.resetClientType = () => {
        const data = angular.copy($scope.campaignModel.clientTypeRaw);
        $scope.campaignModel.clientType = data;
        $scope.campaignModel.clientTypeSelected = data[0];
      };

      function getCategoryList() {
        communicateService.categoryListGet().then((response) => {
          const data = response.data;
          _.map(data, (row) => {
            row.ticked = false;
          });
          const all = {
            CategoryID: -1,
            CategoryName: 'All Categories',
            ticked: true,
          };
          data.unshift(all);
          $scope.campaignModel.categoryRaw = angular.copy(data);
          $scope.campaignModel.category = data;
          $scope.campaignModel.categorySelected = data[0];
        });
      }
      $scope.resetCategory = () => {
        const data = angular.copy($scope.campaignModel.categoryRaw);
        $scope.campaignModel.category = data;
        $scope.campaignModel.categorySelected = data[0];
      };

      // eslint-disable-next-line unicorn/consistent-function-scoping
      function getMultiSelectObj(types) {
        return {
          selectAll: '<span>Select All</span>',
          selectNone: '<span>Deselect All </span>',
          reset: '<span>Reset</span>',
          search: 'Search',
          nothingSelected: `<div class='buttonLabel padding-left-10'>All ${
            types || 'Types'
          }</div>`, // default-label is deprecated and replaced with this.
        };
      }

      function dataHandler(currentPage, action) {
        switch (action) {
          case 'init':
            toaster.pop('success', 'Success', 'Results Refresh successfully!');
            break;

          case 'toggleAll':
            _.each($scope.campaignRecipientList, (itm) => {
              itm.selected = $scope.isAllSelected.val;
            });
            if ($scope.isAllSelected.val) {
              $scope.selectedRecipientList = $scope.campaignRecipientList;
            } else {
              $scope.selectedRecipientList = [];
            }
            if (
              $scope.campaignRecipientList &&
              $scope.campaignRecipientList.length
            ) {
              $scope.selectedContacts = $scope.isAllSelected.val
                ? _.first($scope.campaignRecipientList).TotalCount
                : 0;
            } else {
              $scope.selectedContacts = 0;
            }
            break;

          case 'pagination':
            $scope.selectedRecipientList.forEach((selected) => {
              $scope.campaignRecipientList = $scope.campaignRecipientList.map(
                (list) => {
                  const selectedID = selected.FamilyID
                    ? selected.FamilyID
                    : selected.ReferrerId;
                  const listId = list.FamilyID
                    ? list.FamilyID
                    : list.ReferrerId;
                  const selectedEmailAddress = selected.EmailAddress
                    ? selected.EmailAddress
                    : selected.Email;
                  const listEmailAddress = list.EmailAddress
                    ? list.EmailAddress
                    : list.Email;
                  return selectedID === listId &&
                    selectedEmailAddress === listEmailAddress
                    ? { ...list, selected: true }
                    : list;
                },
              );
            });

            if ($scope.isAllSelected.val) {
              if (
                $scope.campaignRecipientList &&
                $scope.campaignRecipientList.length
              ) {
                $scope.selectedContacts = _.first(
                  $scope.campaignRecipientList,
                ).TotalCount;
              } else {
                $scope.selectedContacts = 0;
              }
            } else {
              $scope.selectedContacts = _.size($scope.selectedRecipientList);
            }

            break;
          default:
        }

        $scope.emailCampaignRepeater = $scope.campaignRecipientList;
        if (
          $scope.campaignRecipientList &&
          $scope.campaignRecipientList.length
        ) {
          $scope.totalPagingCount = _.first(
            $scope.campaignRecipientList,
          ).TotalCount;
        } else {
          $scope.totalPagingCount = 0;
        }

        if ($scope.campaignModel.filterBy === 'referrers') {
          $scope.tableParams = new NgTableParams(
            {
              page: 1,
              count: $scope.totalPagingCount,
              sorting: {
                ReferrerName: 'asc',
              },
            },
            {
              counts: [],
              total: $scope.totalPagingCount,
              getData(params) {
                let filterData = $scope.emailCampaignRepeater;
                if ($scope.searchText) {
                  const regExp = new RegExp($scope.searchText, 'i');
                  filterData = $scope.emailCampaignRepeater.filter((obj) => {
                    return (
                      obj.ReferrerName.match(regExp) ||
                      obj.Email.match(regExp) ||
                      obj.ReferrerOrgEntityLegalName.match(regExp) ||
                      obj.TotalNumberOfLeads.match(regExp)
                    );
                  });
                }
                const orderedData = params.sorting()
                  ? $filter('orderBy')(filterData, params.orderBy())
                  : filterData;
                return orderedData.slice(
                  (params.page() - 1) * params.count(),
                  params.page() * params.count(),
                );
              },
            },
          );
        }

        $scope.tableParams = new NgTableParams(
          {
            page: 1,
            count: $scope.totalPagingCount,
            sorting: {
              FamilyFullName: 'asc',
            },
          },
          {
            counts: [],
            total: $scope.totalPagingCount,
            getData(params) {
              let filterData = $scope.emailCampaignRepeater;
              if (!_.isEmpty($scope.searchText)) {
                const regExp = new RegExp($scope.searchText, 'i');
                filterData = _.filter($scope.emailCampaignRepeater, (obj) => {
                  return (
                    String(obj.FamilyFullName).match(regExp) ||
                    String(obj.City).match(regExp) ||
                    String(obj.ContactNumber).match(regExp) ||
                    String(obj.ContactType).match(regExp)
                  );
                });
              }
              const orderedData = params.sorting()
                ? $filter('orderBy')(filterData, params.orderBy())
                : filterData;
              return orderedData.slice(
                (params.page() - 1) * params.count(),
                params.page() * params.count(),
              );
            },
          },
        );

        $scope.inProgress = false;
      }

      const adviserContactStatusSelected = (campaignModel) => {
        if (campaignModel && campaignModel.contactStatusSelected.length) {
          return $scope.campaignModel.contactStatusSelected
            .map((elem) => {
              return elem.BrokerStatusId;
            })
            .join(',');
        }

        return campaignModel.contactStatusSelected.BrokerStatusId;
      };

      function refreshResultsMethodAdviser() {
        $scope.inProgress = true;
        const reportingCountry = $scope.campaignModel.reportingCountrySelected
          .map((elem) => {
            return elem.CountryId;
          })
          .join(',');

        const tempReportingState = $scope.campaignModel.reportingStateSelected
          .map((elem) => {
            return elem.ReportingStateId;
          })
          .join(',');

        const tempReportingRegion = $scope.campaignModel.reportingRegionSelected
          .map((elem) => {
            return elem.ReportingRegionId;
          })
          .join(',');

        const contactStatus = adviserContactStatusSelected(
          $scope.campaignModel,
        );

        const categories = $scope.campaignModel.categorySelected
          .map((elem) => {
            return elem.CategoryID;
          })
          .join(',');

        const enquirySource = $scope.campaignModel.enquirySourceSelected
          .map((elem) => {
            return elem.EnquirySourceId;
          })
          .join(',');

        const clientType = $scope.campaignModel.clientTypeSelected
          .map((elem) => {
            return elem.ClientTypeID;
          })
          .join(',');

        const contactType = $scope.campaignModel.contactTypeSelected
          .map((elem) => {
            return elem.ContactTypeId;
          })
          .join(',');

        communicateService
          .getCampaignAdviser(
            parseInt(reportingCountry, 10) === -1 ? '' : reportingCountry,
            parseInt(tempReportingState, 10) === -1 ? '' : reportingState,
            parseInt(tempReportingRegion, 10) === -1 ? '' : reportingRegion,
            parseInt(contactStatus, 10) === -1 ? '' : contactStatus,
            parseInt(categories, 10) === -1 ? '' : categories,
            parseInt(enquirySource, 10) === -1 ? '' : enquirySource,
            parseInt(clientType, 10) === -1 ? '' : clientType,
            parseInt(contactType, 10) === -1 ? '' : contactType,
            $scope.pagination.currentPage,
            $scope.pageSize,
          )
          .then((response) => {
            $scope.campaignRecipientList = response.data;

            const currentPage = 1;
            if ($scope.isAllSelected.val) {
              $scope.campaignRecipientList = response.data;
              _.each($scope.campaignRecipientList, (itm) => {
                itm.selected = $scope.isAllSelected.val;
              });
              dataHandler(currentPage, 'toggleAll');
            }
          });
      }

      function tagSelect(action, obj) {
        _.each(obj, (o) => {
          o.selected = $scope.isAllSelected.val;
        });

        return obj;
      }
      // eslint-disable-next-line unicorn/consistent-function-scoping
      const contactStatusWordToInt = (word) => {
        switch (word) {
          case 'Lead':
            return CONTACT_STATUS.LEAD;
          case 'Application':
            return CONTACT_STATUS.APPLICATION;
          case 'Existing Client':
            return CONTACT_STATUS.EXISTING_CLIENT;
          case 'Professional Partner':
            return CONTACT_STATUS.PROFESSIONAL_PARTNER;
          case 'Previous Client':
            return CONTACT_STATUS.PREVIOUS_CLIENT;
          default:
            return '';
        }
      };
      function getCampaignRecipients(
        location,
        postCode,
        ageFrom,
        ageTo,
        contactStatusFilter,
        paramInsurerIDs,
        paramInsuranceStatusFilters,
        paramInsuranceCategoryFilters,
        enquirySource,
        referralCategoryIds,
        currentPage,
        pageSize,
        action,
        dataObj,
      ) {
        let currentPageValue = currentPage;

        const insurerIDs = paramInsurerIDs === '' ? '' : paramInsurerIDs;
        const insuranceStatusFilters =
          paramInsuranceStatusFilters === '' ? '' : paramInsuranceStatusFilters;
        const insuranceCategoryFilters =
          paramInsuranceCategoryFilters === ''
            ? ''
            : paramInsuranceCategoryFilters;
        const contactStatusFilterParam = contactStatusWordToInt(
          contactStatusFilter,
        );
        const employmentReviewClient =
          ($scope.employmentReviewClient && $scope.employmentReviewClient()) ||
          {};
        switch (action) {
          case 'init':
            if ($scope.tempCurrentPage === 1) {
              currentPageValue = 1;
            }
            $scope.campaignRecipientParam = {
              location,
              postCode,
              ageFrom,
              ageTo,
              contactStatus: contactStatusFilterParam,
              insurerIDs,
              insuranceStatusFilters,
              insuranceCategoryFilters,
              enquirySource,
              referralCategoryIds,
              currentPageValue,
              pageSize,
              ...employmentReviewClient,
            };
            communicateService
              .campaignRecipientGet($scope.campaignRecipientParam)
              .then((response) => {
                if ($scope.isAllSelected.val) {
                  $scope.campaignRecipientList = response.data;
                  if (
                    $scope.campaignRecipientList &&
                    $scope.campaignRecipientList.length
                  ) {
                    $scope.selectedContacts = _.first(
                      $scope.campaignRecipientList,
                    ).TotalCount;
                    $scope.allPageSize = _.first(
                      $scope.campaignRecipientList,
                    ).TotalCount;
                  } else {
                    $scope.selectedContacts = 0;
                  }
                  _.each($scope.campaignRecipientList, (itm) => {
                    itm.selected = $scope.isAllSelected.val;
                  });

                  $scope.selectedRecipientList = $scope.campaignRecipientList;

                  dataHandler(currentPageValue, action);
                }
              });
            break;

          case 'pagination':
            communicateService
              .campaignRecipientGet({
                location,
                postCode,
                ageFrom,
                ageTo,
                contactStatus: contactStatusFilterParam,
                insurerIDs,
                insuranceStatusFilters,
                insuranceCategoryFilters,
                referralSource: enquirySource,
                referralCategoryIds,
                pageNumber: currentPageValue,
                pageSize,
                ...employmentReviewClient,
              })
              .then((response) => {
                $scope.campaignRecipientList = response.data;
                if ($scope.isAllSelected.val) {
                  $scope.campaignRecipientList = tagSelect(
                    'all',
                    $scope.campaignRecipientList,
                  );
                  if (
                    $scope.campaignRecipientList &&
                    $scope.campaignRecipientList.length
                  ) {
                    $scope.selectedContacts = _.first(
                      $scope.campaignRecipientList,
                    ).TotalCount;
                  } else {
                    $scope.selectedContacts = 0;
                  }
                } else {
                  $scope.selectedContacts = _.size(
                    $scope.selectedRecipientList,
                  );
                }

                dataHandler(currentPageValue, action);
              });
            break;
          case 'toggleData':
            $scope.campaignRecipientList = $scope.campaignRecipientList.map(
              (campaignRecipient) => {
                const id = campaignRecipient.FamilyID
                  ? campaignRecipient.FamilyID
                  : campaignRecipient.ReferrerId;
                const objId = dataObj.FamilyID
                  ? dataObj.FamilyID
                  : dataObj.ReferrerId;
                if (id === objId) {
                  const filter = $scope.selectedRecipientList.filter(
                    (selected) => {
                      const selectedId = selected.FamilyID
                        ? selected.FamilyID
                        : selected.ReferrerId;
                      return selectedId === objId;
                    },
                  );
                  if (filter.length === 0) {
                    $scope.selectedRecipientList.push(campaignRecipient);
                  } else {
                    const selected = $scope.selectedRecipientList.filter(
                      (s) => {
                        const sId = s.FamilyID ? s.FamilyID : s.ReferrerId;
                        return sId !== id;
                      },
                    );
                    $scope.selectedRecipientList = selected;
                  }
                }
                return campaignRecipient;
              },
            );

            $scope.selectedContacts = $scope.selectedRecipientList.length;
            dataHandler(currentPageValue, action);
            break;
          default:
            dataHandler(currentPageValue, action);
            break;
        }
      }

      function refreshResultsMethod(action, dataObj) {
        $scope.inProgress = true;

        const filterRefreshData = _.first($scope.filterRefreshData);

        $scope.filterRefreshData[0] = filterRefreshData;
        let referralCategoryIds;
        let enquirySourceIds;
        if (filterRefreshData.enquirySource) {
          const enquirySourceCloneObj = Object.assign(
            [],
            filterRefreshData.enquirySource,
          );
          referralCategoryIds = _.map(
            enquirySourceCloneObj,
            'ReferralCategoryId',
          );
          referralCategoryIds = referralCategoryIds
            ? referralCategoryIds.toString()
            : '';
          enquirySourceIds = _.map(enquirySourceCloneObj, 'EnquirySourceId');
          enquirySourceIds = enquirySourceIds
            ? enquirySourceIds.toString()
            : '';
        }

        getCampaignRecipients(
          $scope.locationList.join(','),
          $scope.postCodeList.join(','),
          filterRefreshData.ageFrom,
          filterRefreshData.ageTo,
          filterRefreshData.contactStatusFilter,
          $scope.insurance.insurerIDs.join(','),
          $scope.insurance.insuranceStatusFilters.join(','),
          $scope.insurance.insuranceCategoryFilters.join(','),
          enquirySourceIds,
          referralCategoryIds,
          $scope.pagination.currentPage,
          $scope.pageSize,
          action,
          dataObj,
        );
      }

      const refreshResultsReferrer = () => {
        $scope.inProgress = true;
        const emailCampaignParams = getEmailCampaignReferrersParams(
          $scope.campaignModel,
        );
        const {
          userId,
          isActive,
          referrerOrgId,
          adviserOrgId,
          adviserId,
          recentLeadInDays,
          recentLeadToInDays,
        } = emailCampaignParams;
        communicateService
          .getEmailCampaignReferrers(
            userId,
            isActive,
            referrerOrgId,
            adviserOrgId,
            adviserId,
            recentLeadInDays,
            recentLeadToInDays,
            $scope.pagination.currentPage,
            $scope.pageSize,
          )
          .then((response) => {
            if (!response && !response.data) {
              return;
            }
            $scope.campaignRecipientList = response.data.Result;
            const currentPage = 1;
            if ($scope.isAllSelected.val) {
              $scope.campaignRecipientList = $scope.campaignRecipientList.map(
                (campaignRecipient) => ({
                  ...campaignRecipient,
                  selected: $scope.isAllSelected.val,
                  TotalCount: response.data.Count,
                }),
              );
              dataHandler(currentPage, 'toggleAll');
            }
          });
      };

      function refreshResults(value) {
        $scope.tempCurrentPage = 0;

        switch ($scope.campaignModel.filterBy) {
          case 'adviser':
            refreshResultsMethodAdviser();
            break;
          case 'referrers':
            refreshResultsReferrer();
            break;
          default:
            if (value === 'refresh' || value === 'init') {
              $scope.isAllSelected.val = true;
              $scope.tempCurrentPage = 1;
              refreshResultsMethod('init');
            } else if (value === 'pagination') {
              $scope.tempCurrentPage = $scope.pagination.currentPage;
              refreshResultsMethod('pagination');
            }
            break;
        }
        $scope.displayEmptyReport = false;
      }

      function toggleAll(value) {
        $scope.isAllSelected.val = !value;
        refreshResultsMethod('toggleAll');
      }

      function optionToggled(data) {
        if (!data.selected) {
          $scope.isAllSelected.val = data.selected;
        }
        refreshResultsMethod('toggleData', data);
      }

      function contactInfo(id) {
        if (!$scope.campaignModel.isClientHidden) {
          if (typeof id !== 'undefined' && id > 0) {
            $state.go('app.contactsSingle', { familyId: id });
          } else {
            toaster.pop(
              'info',
              'Notice',
              'You may have selected a non MyCRM client',
            );
          }
        }
      }

      $scope.$watch(
        () => {
          $scope.sidebarheight = $('#sidebar-1').height();
          return $scope.sidebarheight;
        },
        (value) => {
          $scope.sidebarheight = value + 135;
        },
        true,
      );

      angular.extend($scope, {
        getRandomBg,
        toggleAll,
        optionToggled,
        contactInfo,
        employmentTypeLocalLang: getMultiSelectObj('Employment Types'),
        reviewMonthsLocalLang: getMultiSelectObj('Review Months'),
        clientTypeLocalLang: getMultiSelectObj('Client Types'),
        contactTypeLocalLang: getMultiSelectObj('Types'),
        countryLocalLang: getMultiSelectObj('Countries'),
        stateLocalLang: getMultiSelectObj('States'),
        regionLocalLang: getMultiSelectObj('Regions'),
        statusLocalLang: getMultiSelectObj('Status'),
        sourceLocalLang: getMultiSelectObj('Sources'),
        clientLocalLang: getMultiSelectObj('Clients'),
        catLocalLang: getMultiSelectObj('Categories'),
        genderLocalLang: getMultiSelectObj('Gender'),
        localLang: getMultiSelectObj('Loan Status'),
        lenderLocalLang: getMultiSelectObj('Lender'),
        categoryLocalLang: getMultiSelectObj('Category'),
        enquirySourceLocalLang: getMultiSelectObj('Enquiry Source'),
        adviserLocalLang: $scope.isAutoLoadingDisabled
          ? $scope.adviserEmptyLocalLang
          : getMultiSelectObj('Adviser'),
        loanRateTypesLocalLang: getMultiSelectObj('Loan Rate Type'),
        insurersTypesLocalLang: getMultiSelectObj('Insurers'),
        insurancePolicyTypesLocalLang: getMultiSelectObj('Insurance Policies'),
        insuranceCategoruTypesLocalLang: getMultiSelectObj(
          'Insurance Categories',
        ),
        clientContactTypeLocalLang: getMultiSelectObj('Contact Types'),
        ratingLocalLang: getMultiSelectObj('Rating'),
        showFilterVal,
        sortBy,
        removeLocationList,
        removePostCodeList,
        pushLocation,
        pushPostCode,
        onSelectAll,
        onItemSelect,
        onSelectAllEnquirySource,
        onItemSelectEnquirySource,
        refreshResults,
      });

      const pluckTicked = (filterTypes, id) => {
        if (!$scope.insurance[filterTypes]) {
          return [];
        }
        return $scope.insurance[filterTypes].map((data) => {
          data.ticked = data[id] === 0;
          return data;
        });
      };

      const resetTicked = (filterTypes) => {
        if (!$scope.insurance[filterTypes]) {
          return [];
        }
        return $scope.insurance[filterTypes].map((data) => {
          data.ticked = false;
          return data;
        });
      };

      const unPluckTicked = (filterTypes, id) => {
        if (!$scope.insurance[filterTypes]) {
          return [];
        }
        return $scope.insurance[filterTypes].map((data) => {
          data.ticked = data[id] !== 0 && data.ticked;
          return data;
        });
      };

      const getSelectedIds = (filterTypes, id) => {
        if (!$scope.insurance[filterTypes]) {
          return [];
        }
        return $scope.insurance[filterTypes].map((data) => {
          data.ticked = data[id] !== 0 && data.ticked;
          return data[id];
        });
      };

      const getAllIds = (filterType, ids) => {
        if (!$scope.insurance[filterType]) {
          return [];
        }
        return $scope.insurance[filterType]
          .filter((obj) => {
            return obj[ids] !== 0;
          })
          .map((obj) => {
            return obj[ids];
          });
      };

      $scope.forNZonly = currentUserService.isNZ;

      $scope.onSelectInsurancesFilters = (
        actionType,
        filterType,
        data = {},
      ) => {
        switch (filterType) {
          case 'insurers':
            $scope.insuranceFilterAction(actionType, data, {
              list: 'ProviderList',
              id: 'providerId',
              insurerIDs: 'insurerIDs',
              selectedId: 'selectedProviderList',
            });
            break;
          case 'policy':
            $scope.insuranceFilterAction(actionType, data, {
              list: 'PolicyStatusList',
              id: 'policyNameAsIds',
              insurerIDs: 'insuranceStatusFilters',
              selectedId: 'selectedPolicyStatusList',
            });
            break;
          case 'category':
            $scope.insuranceFilterAction(actionType, data, {
              list: 'CatergoriesList',
              id: 'categoryNameAsIds',
              insurerIDs: 'insuranceCategoryFilters',
              selectedId: 'selectedCatergoriesList',
            });
            break;
          default:
            break;
        }
      };

      $scope.insuranceFilterAction = (actionType, data, filterTypes) => {
        switch (actionType) {
          case 'all':
            $scope.insurance[filterTypes.insurerIDs] = getAllIds(
              filterTypes.list,
              filterTypes.id,
            );
            $scope.insurance[filterTypes.selectedId] = pluckTicked(
              filterTypes.list,
              filterTypes.id,
            );
            $scope.insurance[filterTypes.list] = pluckTicked(
              filterTypes.list,
              filterTypes.id,
            );
            break;
          case 'none':
            $scope.insurance[filterTypes.insurerIDs] = [];
            // eslint-disable-next-line sonarjs/no-extra-arguments
            $scope.insurance[filterTypes.selectedId] = resetTicked(
              filterTypes.list,
              filterTypes.id,
            );
            break;
          // eslint-disable-next-line sonarjs/no-duplicated-branches
          case 'reset':
            $scope.insurance[filterTypes.insurerIDs] = [];
            // eslint-disable-next-line sonarjs/no-extra-arguments
            $scope.insurance[filterTypes.selectedId] = resetTicked(
              filterTypes.list,
              filterTypes.id,
            );
            break;
          default:
            if (data[filterTypes.id] === DEFAULT.ALL) {
              $scope.insurance[filterTypes.insurerIDs] = [DEFAULT.ALL];
              $scope.insurance[filterTypes.insurerIDs] = getAllIds(
                filterTypes.list,
                filterTypes.id,
              );
              $scope.insurance[filterTypes.list] = pluckTicked(
                filterTypes.list,
                filterTypes.id,
              );
            } else {
              $scope.insurance[filterTypes.insurerIDs] = getSelectedIds(
                filterTypes.selectedId,
                filterTypes.id,
              );

              $scope.insurance[filterTypes.list] = unPluckTicked(
                filterTypes.list,
                filterTypes.id,
              );

              $scope.insurance[filterTypes.selectedId] = unPluckTicked(
                filterTypes.list,
                filterTypes.id,
              );

              const isAllToggled =
                $scope.insurance[filterTypes.insurerIDs].find(
                  (ids) => ids === DEFAULT.ALL,
                ) === DEFAULT.ALL;
              if (isAllToggled) {
                const newIds = $scope.insurance[filterTypes.insurerIDs].filter(
                  (i) => i !== DEFAULT.ALL,
                );
                $scope.insurance[filterTypes.id] = [...newIds];
              }
            }

            break;
        }
      };

      // eslint-disable-next-line unicorn/consistent-function-scoping
      const policiesAsAlias = (dataAs) => {
        return {
          policyName: dataAs.policyStatusName,
          policyNameAsIds: dataAs.policyStatusName,
        };
      };
      // eslint-disable-next-line unicorn/consistent-function-scoping
      const categoriesAsAlias = (dataAs) => {
        return {
          categoryName: dataAs.catergoryTypeName,
          categoryNameAsIds: dataAs.catergoryTypeName,
        };
      };

      const insuranceListOfFilters = (requestInsurance) => {
        const { ALL } = DEFAULT;
        requestInsurance.getProvidersList().then((response) => {
          $scope.insurance.ProviderList = [
            { providerName: 'All Insurers', providerId: ALL, ticked: true },
            ...response,
          ];
        });

        requestInsurance.getPolicyStatusList().then((response) => {
          $scope.insurance.PolicyStatusList = [
            { policyName: 'All Policies', policyNameAsIds: ALL, ticked: true },
            ...response.map(policiesAsAlias),
          ];
        });

        requestInsurance.getCategoryList().then((response) => {
          $scope.insurance.CatergoriesList = [
            {
              categoryName: 'All Categories',
              categoryNameAsIds: ALL,
              ticked: true,
            },
            ...response.map(categoriesAsAlias),
          ];
        });
      };

      $scope.getLoanStatusLabel = () =>
        uiService.viewOfAfileTurnedOn ? 'Deal Status' : 'Loan Status';

      function init() {
        _.first($scope.filterRefreshData).contactStatus =
          'Please Select Status';
        getUserInfo();
        getContactType();
        operatingCountriesGet();
        getBrokerStatusList();
        getContactReferrerStatus();
        adviserOrganizationListGet();
        reportingRegion();
        getEnquirySource();
        getClientType();
        getCategoryList();
        getLoanRateTypes();
        checkMailChimp();
        insuranceListOfFilters(communicateService);

        communicateService.categoryListGet().then(({ data = [] }) => {
          const allCategories = selectedDefaultValues(
            'All Category',
            'CategoryName',
            'CategoryID',
          );
          $scope.categoriesList = [allCategories, ...data];
        });

        communicateService.lenderListGet(lenderPlace).then(({ data = [] }) => {
          const allLenders = selectedDefaultValues(
            'All Lender',
            'ProviderName',
            'ProviderId',
          );
          $scope.lenderList = [allLenders, ...data];
        });

        communicateService.ratingListGet().then(({ data = [] }) => {
          const allRatings = selectedDefaultValues(
            'All Rating',
            'RatingName',
            'RatingID',
          );
          $scope.ratingsList = [allRatings, ...data];
        });

        optionsService.getEnquirySourceList().then((response) => {
          const tempEnquirySourceList =
            response.data &&
            response.data.reduce((accum, enquiry) => {
              let newAccum = accum;
              if (
                enquiry.EnquirySourceItem &&
                enquiry.EnquirySourceItem.length
              ) {
                const tempEnquiry = {
                  EnquirySource: `<strong> ${enquiry.ReferralCategoryName} </strong>`,
                  msGroup: true,
                };
                accum.push(tempEnquiry);
                const tempList = _.map(enquiry.EnquirySourceItem, (item) => {
                  return {
                    EnquirySourceId: item.ReferralItemId,
                    EnquirySource: item.ReferralItemName,
                    ReferralCategoryId: enquiry.ReferralCategoryId,
                    ReferralCategoryName: enquiry.ReferralCategoryName,
                  };
                });
                newAccum = accum.concat(tempList);
                newAccum.push({ msGroup: false });
              }
              return newAccum;
            }, []);
          const temp = {
            EnquirySourceId: 0,
            EnquirySource: '<strong> All Enquiry Source </strong>',
            ticked: true,
            ReferralCategoryId: 0,
          };
          tempEnquirySourceList.unshift(temp);
          const tmp = [];
          tempEnquirySourceList &&
            Object.keys(tempEnquirySourceList).forEach((x) => {
              const value = tempEnquirySourceList[x];
              if (lastFilterEnquirySource.includes('All')) {
                value.ticked = parseInt(value.EnquirySourceId, 10) === 0;
              } else if (
                lastFilterEnquirySource.includes(
                  tempEnquirySourceList[x].EnquirySource,
                )
              ) {
                value.ticked = true;
              }
              tmp.push(value);
            });
          $scope.enquirySourceList = tmp;
        });
        getAdvisers({
          $scope,
          mycrmLookupService,
          refreshResults,
        });

        communicateService.loanStatusListGet().then(({ data }) => {
          const allLoanStatus = selectedDefaultValues(
            `All ${$scope.getLoanStatusLabel()}`,
            'LoanStatusName',
            'LoanStatusID',
          );
          $scope.loanStatusList = [allLoanStatus, ...data];
        });
      }

      init();
    });
