import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { CONFIG_EMAIL } from 'Common/config/email';
import { isMobileValid } from 'Common/utilities/mobile';
import { toastError, toastSuccess } from 'Common/utilities/alert';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { VALID_HELLO_BOOK_CREATE_LOCATION } from 'Common/constants/helloBook';
import { CONTACT_STATUS } from 'Common/constants/contactStatus';
import {
  REFERRAL_ITEM,
  CLIENT_REFERRAL,
  ADDITIONAL_REFERRAL,
  REFERRED_BY_ACCESS,
} from 'Common/constants/enquirySource';
import OpenOnlineFactFindForm from 'Components/usersSubscription/openOnlineFactFindForm';
import { ADDRESS_TYPE } from 'Common/constants/addressType';
import { displayField } from 'Common/utilities/displayField';
import { CONTACT_TYPE } from 'Common/constants/contactType';
import { TICKABLE_ITEMS } from 'Common/constants/tickableItems';
import { FACT_FIND_TYPE } from 'Common/constants/factFind';
import {
  stripPhoneFromContactSetPayload,
  saveStandAloneClientContactNumber,
} from 'Common/utilities/contactNumber';
import {
  mobileCountryDropdown,
  assignSelectedCountryValue,
  setCountryCode,
} from 'Assets/js/controllers/contacts/client/util/addClientModal';
import { parseToInt10 } from 'Common/utilities/parse';
import { setAssistantsList } from 'Common/utilities/allocation';
import { isLMBranded } from 'Common/utilities/brandingCategory';
import { getContactStatusLabel } from 'Common/utilities/client';
import {
  validateNZBNfield,
  validateNZBNfieldOnSubmit,
  ifNotValidNZBNChar,
  toggleTrustType,
  onCreateContactOrganisationInfoSet,
  initBusinessAddressSaving,
  initClientAddressSaving,
  getAdvisersList,
  isMobileNumberValid,
  setContactAgeValue,
  restrictEnquirySourceForAdviser,
  showCreateNewFileConfirmation,
  clientContactFormValidation,
  getBypassMobileValidation,
} from './util/createContact';
import {
  initClientAddress,
  checkBusinessAddressValidity,
  isAddressInvalid,
} from './util/clientAddress';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('CreateAccountCtrl', function CreateAccountCtrl(
      $q,
      $scope,
      contactService,
      $uibModal,
      $timeout,
      $state,
      SweetAlert,
      toaster,
      $localStorage,
      $interval,
      $stateParams,
      optionsService,
      corporateService,
      userService,
      generalService,
      $rootScope,
      $window,
      enquirySourceService,
      referencedReferralService,
      contactStatusService,
      contactSharedData,
      clientInfoService,
      loanScenarioService,
      accountService,
      configService,
      onlineFactFindModal,
      currentUserService,
      helloBookPreviewService,
      contactModelService,
      stateService,
      countryCodeService,
      addressLookupService,
      mycrmLookupService,
      uiService,
      viewOfFileModalService,
    ) {
      $scope.referralExistingClient = {};
      $scope.onClientChange = ({ client = {} }) => {
        $scope.referralExistingClient = client;
      };
      initClientAddress({ $scope, configService });
      $scope.isInternationalPhoneNumbers =
        configService.feature.internationalPhoneNumbers;
      $scope.isMobileInputDropdown =
        currentUserService.isNZ &&
        configService.feature.phoneNumberCountryCodeNZ;
      $scope.business = {
        Phone: '',
      };
      $scope.isBypassMobileValidation = false;
      $scope.isInternationalPhoneNumbers = uiService.isInternationalPhoneNumber;
      $scope.isPhoneNumberCountryCodeNZ =
        currentUserService.isNZ &&
        configService.feature.phoneNumberCountryCodeNZ;
      $scope.isInternationalMobileNumber =
        $scope.isInternationalPhoneNumbers || $scope.isPhoneNumberCountryCodeNZ;
      $scope.isAU = currentUserService.isAU;
      $scope.homePhoneCountryCode = countryCodeService.homePhoneCountryCode();
      $scope.countryCode = setCountryCode({
        countryCode: '',
        defaultCountryCode: countryCodeService.setDefaultCountryCode({
          isInternationalPhoneNumbers: $scope.isInternationalMobileNumber,
          defaultCountryCode: $scope.isAU,
        }),
      });
      $scope.getAssistantsList = setAssistantsList({ $scope, optionsService });
      $scope.viewOfAfileTurnedOnAndNotCorp =
        uiService.viewOfAfileTurnedOn && !currentUserService.isCorporateUser;
      $scope.getNewContactModel = function (role) {
        return {
          FamilyId: '0',
          LastName: '',
          FirstName: '',
          MiddleName: '',
          PreferredName: '',
          Title: '',
          Gender: '',
          DOB: '',
          Role: role,
          Employment: [
            {
              Occupation: '',
              OccupationClass: 0,
              Employer: '',
            },
          ],
          SmokerStatus: '',
          Deceased: false,
          DOBReminder: false,
          BestTimeToCall: '',
          IsResident: true,
          Phone: [
            {
              Type: 'Home',
              Number: '',
            },
            {
              Type: 'Work',
              Number: '',
            },
            {
              Type: 'Mobile',
              Number: '',
            },
          ],
          Email: [
            {
              Type: 'Email',
              EmailAddress: '',
            },
          ],
          Notes: '',
          AgeValue: '',
        };
      };
      $scope.setContactAgeValue = setContactAgeValue;
      $scope.contactStatusLabel = getContactStatusLabel(
        uiService.viewOfAfileTurnedOn,
      );
      $scope.contactStatusText = `Contact ${$scope.contactStatusLabel}`;

      const getSubscriptionPopUp = (familyId) => {
        if (!familyId) {
          return;
        }
        const params = {
          brokerId: familyId,
          tickedItemId: TICKABLE_ITEMS.INTERCEPT,
        };
        contactModelService.getSubscriptionPopUp(params).then((data) => {
          const { showPopUp } = data;
          $scope.showPopUp = !!showPopUp;
        });
      };

      toggleTrustType({ $scope, configService, currentUserService });
      mobileCountryDropdown({ $scope, currentUserService, configService });

      $scope.client = {};
      $scope.business = {};
      $scope.allocation = {};
      $scope.accountTypeList = [];
      $scope.personDetails = [$scope.getNewContactModel('Adult')];
      $scope.childDetails = [];
      $scope.homeAutoAddressDetails = [];

      $scope.callValidateNZBNfield = validateNZBNfield({
        $scope,
        configService,
        currentUserService,
      })();

      $scope.isHelloBookEnabled = !!uiService.showHelloBook;
      $scope.contactAddress = {
        MailingAddress: '',
        HomeAddress: '',
        MainEmail: '',
        isMailingAddress: false,
      };

      $scope.currentMobileTab = 1;
      $scope.finalMobileTab = 3;

      if (
        $localStorage.loanApplicationCreateNewContact &&
        $localStorage.loanApplicationSelectedClientName !== undefined
      ) {
        const fullName = $localStorage.loanApplicationSelectedClientName.split(
          ' ',
        );
        $scope.personDetails[0].FirstName = fullName[0];

        if (fullName.length > 1) {
          $scope.personDetails[0].LastName = fullName[fullName.length - 1];
        }
      }
      $scope.allocatedAdviser = {};
      $scope.allocatedAdviser.adviserSelected = {};
      $scope.allocatedAdviser.adviserSelected.description = {};
      $scope.allocatedAdviser.adviserInitialValue = '';
      /* things needed to get the data, need to conenct to corporateService */
      const setLoadingAdviserTheme = (bool) => {
        $scope.isLoadingAdviserTheme = bool;
      };
      $scope.supportEmail = currentUserService.isAU
        ? CONFIG_EMAIL.MARKETING_AU
        : CONFIG_EMAIL.ADVISER_SERVICES_NZ;

      if (typeof $scope.brokersInfo === 'undefined') {
        userService.GetUserInfo().then((response) => {
          if (response && response.data) {
            $scope.loggedInBrokerInfo = response.data;
            const { AccessType: access } = $scope.loggedInBrokerInfo;
            generalService.getBrokerBasicinfo().then((data) => {
              $scope.brokersInfo = data;
              const isAdviser =
                parseInt(access, 10) === ACCESS_TYPE.PRINCIPAL_ADVISER ||
                parseInt(access, 10) === ACCESS_TYPE.ADVISER;
              $scope.getAccountType();
              const { intercept } = configService.feature;
              isAdviser && intercept && getSubscriptionPopUp(data.brokerId);

              if (!$scope.isHelloBookEnabled) {
                $scope.getAdviserBranding();
              } else {
                $scope.isBrandingReady = false;
                setLoadingAdviserTheme(false);
              }
            });
          }
        });
      }

      angular.extend($scope, {
        sampleChange(id) {
          $scope.AssignedAdviserId = id;
          $scope.referredByHelper.loopCount = 0;
          $scope.getAssistantsList(id);
          restrictEnquirySourceForAdviser({ $scope, familyId: id });
        },
        getAccountType() {
          clientInfoService.getAccounTypeNumber().then((res) => {
            $scope.accessType = res.data;
            $scope.getUserInfo();
          });
        },
        getUserInfo() {
          contactService.getUserInfo().then((responseContactCreate) => {
            $scope.userInfo = responseContactCreate.data;
            $scope.currentCountryId = responseContactCreate.data.CountryId || 0;
            $scope.currentUserName = responseContactCreate.data.FullName;
          });
        },
      });

      $scope.isIntlPhoneInput = ({
        inputElementId,
        phoneNumber,
        isOtherFieldInvalid,
        isPhone,
      }) => {
        const messageType = isPhone ? 'Phone' : 'Mobile phone';
        return (
          $scope.isInternationalMobileNumber &&
          countryCodeService.validatePhoneNumber({
            element: angular.element(`#${inputElementId}`),
            phoneNumber,
            isPhoneRequired: true,
            message: `${messageType} is required`,
            isOtherFieldInvalid,
            isLandline: isPhone,
          })
        );
      };

      $scope.isFromPipeline = function () {
        $scope.isPipeline = $stateParams.fromTool;
        if ($scope.isPipeline === 'pipeline') {
          $scope.pipeLineParam = true;
          $scope.headerForPipeline = 'Add New Contact to Card';
          $scope.accountType = 'Client';
        }
      };
      $scope.isFromPipeline();
      $scope.getContactType = function () {
        contactService.getContactType().then((response) => {
          if (!response.data && !response.data.length) {
            return;
          }
          $scope.accountTypeList = response.data.filter(
            (x) =>
              parseInt(x.ContactTypeId, 10) === CONTACT_TYPE.CLIENT ||
              parseInt(x.ContactTypeId, 10) === CONTACT_TYPE.BUSINESS,
          );

          if (
            $localStorage.loanApplicationCreateNewContact ||
            $localStorage.loanProfilerCreateNewContact ||
            $localStorage.fundingCalculatorCreateNewContact
          ) {
            $scope.accountType = 'Client';
          }
        });
      };

      $scope.getContactType();

      $scope.AddPerson = function (role) {
        if (role === 'Adult') {
          if ($scope.personDetails.length < 2) {
            $scope.personDetails.push($scope.getNewContactModel(role));
          } else {
            toaster.pop(
              'error',
              'Maximum Allowed Adults',
              'Only 2 Adults are allowed',
            );
          }
        } else if (role === 'Child') {
          $scope.childDetails.push($scope.getNewContactModel(role));
        }
      };

      $scope.removePersonEntry = function (index) {
        if ($scope.personDetails.length > 1) {
          if (typeof index !== 'undefined') {
            $scope.personDetails.splice(index, 1);
          }
        } else {
          toaster.pop(
            'error',
            "Can't Delete Adult ",
            'You need at least 1 adult to create a new contact',
          );
        }
      };

      $scope.removeChildEntry = function (index) {
        if (typeof index !== 'undefined') {
          $scope.childDetails.splice(index, 1);
        }
      };

      $scope.indexTitle = function (role, index) {
        let foundRole = 0;
        for (let i = 0; i <= index; i++) {
          if ($scope.personDetails[index].Role === role) {
            foundRole++;
          }
        }

        if (role === 'Adult') {
          return `Person ${foundRole}`;
        } else if (role === 'Child') {
          return `Child ${foundRole}`;
        }
      };

      $scope.successStopFun = () => {
        const successStop = $interval(
          () => {
            if ($scope.maxSeconds > 0) {
              $timeout(() => {
                const el = $window.document.querySelectorAll(
                  '.sweet-alert button.confirm',
                );
                angular
                  .element(el)
                  .html(`Redirecting in ${$scope.maxSeconds}s...`);
              }, 0);

              $scope.maxSeconds--;
            } else {
              $interval.cancel(successStop);
              $timeout(() => {
                const el = $window.document.querySelectorAll(
                  '.sweet-alert button.confirm',
                );
                angular.element(el).trigger('click');
              }, 0);
            }
          },
          1000,
          0,
        );
      };

      const redirectToContactPage = () => {
        if ($scope.FamilyId) {
          $state.go('app.contactsSingle', {
            familyId: $scope.FamilyId,
          });
        }
      };

      $scope.showFactFindTick = () => {
        if (!currentUserService) {
          return false;
        }
        const { countryId, brandingCategoryID } = currentUserService;
        return (
          countryId === COUNTRY_TYPE.AUSTRALIA ||
          (countryId === COUNTRY_TYPE.NEW_ZEALAND &&
            isLMBranded(brandingCategoryID))
        );
      };

      const openHelloBook = () => {
        const { urlLocation } = $state.params;
        const additionalConditionHelloBook =
          !$scope.pipeLineParam &&
          VALID_HELLO_BOOK_CREATE_LOCATION.includes(urlLocation);
        helloBookPreviewService.launchOnClientCreateSuccess(
          $scope,
          $scope.FamilyId,
          additionalConditionHelloBook,
          contactModelService,
          helloBookPreviewService,
          uiService.newHelloModalEnabled,
        );
      };

      const openFactFindModal = (loanAppId, type = FACT_FIND_TYPE.GENERAL) => {
        if (!loanAppId) {
          return;
        }
        const { splitOnlineFactFind } = configService.feature;
        if (
          $scope.showPopUp &&
          !$scope.openFactFind &&
          (type === FACT_FIND_TYPE.GENERAL || type === FACT_FIND_TYPE.INVITE)
        ) {
          $scope.openInterceptModal(loanAppId, type);
          return;
        }
        if (splitOnlineFactFind) {
          if (uiService.newHelloModalEnabled) {
            helloBookPreviewService
              .open({
                familyId: $scope.FamilyId,
                isForLoanWriters: null,
                isNewModal: true,
                loanApplicationId: loanAppId,
                selectedService: '',
                onlyFactFind: true,
              })
              .then(() => redirectToContactPage());
          } else {
            onlineFactFindModal
              .open({
                loanId: loanAppId,
                type,
                isShowReviewButton: false,
              })
              .then((response) => {
                if (response && response.factFindType) {
                  openFactFindModal(loanAppId, response.factFindType);
                  return;
                }
                redirectToContactPage();
              });
          }
        } else {
          $uibModal
            .open({
              templateUrl:
                'assets/views/profileManagement/Users/openOnlineFactFindForm.html',
              controller: OpenOnlineFactFindForm,
              controllerAs: 'vm',
              resolve: {
                loanId: () => loanAppId,
              },
              size: 'lg',
              windowClass: 'online-fact-find-form',
            })
            .result.then(() => {
              redirectToContactPage();
            });
        }
      };

      $scope.openInterceptModal = (loanAppId, type) => {
        const { brokerId: familyId } = $scope.brokersInfo;
        const props = {
          familyId,
        };
        const modalInstance = $uibModal.open({
          template: `<intercept-bank-connect
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId"
                  >
                </intercept-bank-connect>`,
          size: 'lg',
          backdrop: 'static',
          windowClass: 'intercept-bank-connect',
          controller: 'CommonModalPlaceholderCtrl',
          controllerAs: 'vm',
          resolve: {
            props,
          },
        });
        modalInstance.result.then((response) => {
          if (!response) {
            return;
          }
          $scope.openFactFind = response;
          type && openFactFindModal(loanAppId, type);
          // eslint-disable-next-line sonarjs/no-extra-arguments
          !type && openHelloBook(response);
        });
      };

      const loanScenarioSet = (setAdviser) => {
        if (!$scope.FamilyId && !setAdviser) {
          return;
        }
        const familyId = $scope.FamilyId.toString();
        const { allocatedAdviserId } = setAdviser;
        const adviserId = parseInt(allocatedAdviserId, 10);
        const dummyscenario = {
          LoanScenarioId: '0',
          AdviserId: adviserId,
          Title: `Loan Application ${Math.random().toString(36).slice(20)}`,
          StatusName: 'ongoing',
          FamilyId: familyId,
          AssignedLenderId: '0',
          IsFactFindTriggered: true,
        };
        loanScenarioService
          .scenarioSet(dummyscenario)
          .then((scenarioSetResponse) => {
            if (!scenarioSetResponse || !scenarioSetResponse.data) {
              return;
            }
            const { data: loanAppId } = scenarioSetResponse;
            openFactFindModal(loanAppId);
          });
      };

      $scope.saveContactAddress = function (setAdviser) {
        $scope.familyDetails.Addresses = [];

        $scope.familyDetails.ReferralCategoryId =
          $scope.familyDetails.ReferralCategoryId || 0;
        $scope.familyDetails.ReferralItemId =
          $scope.familyDetails.ReferralItemId || 0;
        if (
          displayField($scope.familyDetails.ReferralItemId, CLIENT_REFERRAL)
        ) {
          $scope.familyDetails = {
            ...$scope.familyDetails,
            ...$scope.referralExistingClient,
          };
        }

        if (
          $scope.familyDetails.ReferralCategoryId !== REFERRAL_ITEM.REFERRAL &&
          $scope.familyDetails.ReferralItemId !== REFERRAL_ITEM.REFERRAL
        ) {
          $scope.familyDetails.ReferrerAgreementTypeId = 0;
          $scope.familyDetails.ReferrerID = 0;
          $scope.familyDetails.ReferrerName = '';
          $scope.familyDetails.ReferrerNameLabel = '';
          $scope.familyDetails.ReferrerOrgID = 0;
          $scope.familyDetails.ReferrerOrgName = '';
        }

        if (
          !$scope.isAddressOverhaulEnabled &&
          $scope.contactAddress.HomeAddress
        ) {
          $scope.familyDetails.Addresses.push({
            Type: 'Home',
            geoCoded: true,
            formatted_address: $scope.contactAddress.HomeAddress,
            IsMailing: false,
          });
          if (
            (!$scope.contactAddress.isMailingAddress &&
              $scope.contactAddress.MailingAddress) ||
            $scope.contactAddress.isMailingAddress
          ) {
            $scope.familyDetails.Addresses.push({
              Type: 'Postal',
              TypeId: ADDRESS_TYPE.POSTAL,
              geoCoded: true,
              formatted_address: $scope.contactAddress.isMailingAddress
                ? $scope.contactAddress.HomeAddress
                : $scope.contactAddress.MailingAddress,
              IsMailing: true,
            });
          }
        }

        if ($scope.isAddressOverhaulEnabled) {
          $scope.familyDetails.Addresses = setAdviser.Addresses;
        }

        contactService
          .contactFamilyInfoSet($scope.familyDetails)
          .then((response) => {
            if (
              !_.isUndefined(setAdviser.FamilyId) &&
              !_.isUndefined(setAdviser.allocatedAdviserId)
            ) {
              // Update Allocation API
              if ($rootScope.isCorporateUser) {
                corporateService
                  .assignClientToAdviserSet(
                    setAdviser.allocatedAdviserId,
                    setAdviser.FamilyId,
                    0,
                  )
                  .then((setResponse) => {
                    if (setResponse.status === 200) {
                      toastSuccess('Assigned Adviser to Client');
                    }
                  });
              } else {
                contactService
                  .putAssignAdviser(
                    setAdviser.FamilyId,
                    setAdviser.allocatedAdviserId,
                  )
                  .then(() => {
                    toaster.pop(
                      'success',
                      'Successfully',
                      'Assigned Adviser to Client',
                    );
                  });
              }
            }

            if ($scope.pipeLineParam === true) {
              let familyName = '';
              if ($scope.personDetails.length > 0) {
                familyName = `${$scope.personDetails[0].FirstName} ${$scope.personDetails[0].LastName}`;
              }

              const statusName = $localStorage.pipelineStatus;
              $localStorage.pipelineCardSatusName = $scope.ContactStatus.Name;
              let AdviserName = $scope.familyDetails.AdviserFullName;
              if (AdviserName.slice(-1) === 's') {
                AdviserName += "'";
              } else {
                AdviserName += "'s";
              }
              SweetAlert.swal(
                {
                  title: 'Saved to New Card!',
                  text: `Successfully added ${familyName} as a ${statusName}`,
                  type: 'success',
                  showConfirmButton: true,
                  showCancelButton: false,
                  confirmButtonColor: '#30C1C4',
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  confirmButtonText: 'Redirecting in s...',
                  html: true,
                },
                () => {
                  $state.go($localStorage.previousState, {
                    createdContact: response.data,
                    ...$localStorage.previousStateParams,
                  });
                },
              );
              $scope.maxSeconds = 3;
              $scope.successStopFun();
            } else if (
              $localStorage.loanApplicationCreateNewContact ||
              $localStorage.loanProfilerCreateNewContact ||
              $localStorage.fundingCalculatorCreateNewContact
            ) {
              let AdviserName = $scope.familyDetails.AdviserFullName;
              if (AdviserName.slice(-1) === 's') {
                AdviserName += "'";
              } else {
                AdviserName += "'s";
              }

              if ($localStorage.loanApplicationCreateNewContact) {
                // Set familyid for local storage
                $localStorage.loanApplicationSelectedFamilyId =
                  $scope.familyDetails.FamilyId;
                SweetAlert.swal(
                  {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    title: 'Saved to Related Parties!',
                    text: `Successfully added <b>${$localStorage.loanApplicationSelectedClientName}</b> to ${AdviserName} Loan Application`,
                    type: 'success',
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonColor: '#30C1C4',
                    confirmButtonText: 'Redirecting in s...',
                    html: true,
                  },
                  () => {
                    $state.go(
                      $localStorage.previousState,
                      $localStorage.previousStateParams,
                    );
                  },
                );
              }
              if ($localStorage.loanProfilerCreateNewContact) {
                $localStorage.loanProfilerFamilyId = response.data;
                $localStorage.loanProfilerContactFirstName =
                  $scope.personDetails[0].FirstName;
                $localStorage.loanProfilerContactLastName =
                  $scope.personDetails[0].LastName;
                SweetAlert.swal(
                  {
                    title: 'Saved to Related Parties!',
                    text: `Successfully added to ${AdviserName} Loan Calculator`,
                    type: 'success',
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonColor: '#30C1C4',
                    confirmButtonText: 'Redirecting in s...',
                    html: true,
                  },
                  // eslint-disable-next-line sonarjs/no-identical-functions
                  () => {
                    $state.go(
                      $localStorage.previousState,
                      $localStorage.previousStateParams,
                    );
                  },
                );
              }
              if ($localStorage.fundingCalculatorCreateNewContact) {
                $localStorage.fundingCalculatorFamilyId = response.data;
                $localStorage.fundingCalculatorContactFirstName =
                  $scope.personDetails[0].FirstName;
                $localStorage.fundingCalculatorContactLastName =
                  $scope.personDetails[0].LastName;
                SweetAlert.swal(
                  {
                    title: 'Saved to Related Parties!',
                    text: `Successfully added to ${AdviserName} Funding Calculator`,
                    type: 'success',
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonColor: '#30C1C4',
                    confirmButtonText: 'Redirecting in s...',
                    html: true,
                  },
                  // eslint-disable-next-line sonarjs/no-identical-functions
                  () => {
                    $state.go(
                      $localStorage.previousState,
                      $localStorage.previousStateParams,
                    );
                  },
                );
              }

              $scope.maxSeconds = 3;
              $scope.successStopFun();
            } else if ($scope.sendFactFind) {
              loanScenarioSet(setAdviser);
            } else {
              const createNewContactTimer = $timeout(() => {
                redirectToContactPage();
                showCreateNewFileConfirmation({
                  contactService,
                  uiService,
                  viewOfFileModalService,
                  familyId: response.data,
                  allocatedAdviserId: setAdviser.allocatedAdviserId,
                });
              }, 3000);
              $scope.$on('$destroy', () => {
                $timeout.cancel(createNewContactTimer);
              });
            }
          });
      };

      $scope.contactFamilyInfoGet = function (setAdviser) {
        return contactService
          .contactFamilyInfoGet($scope.FamilyId)
          .then((response) => {
            $scope.familyDetails = response.data;
            $scope.familyDetails.FamilyId = $scope.FamilyId;
            const { ReferralItemId } =
              $scope.contactInfoSetValue ||
              $scope.contactInfoSetValue.EnquirySource;
            const isSaveClient = ReferralItemId
              ? $scope.displayField(ReferralItemId, $scope.clientEnquiryList)
              : false;
            const isSaveDetails = ReferralItemId
              ? $scope.displayField(
                  ReferralItemId,
                  $scope.additionalEnquiryList,
                )
              : false;
            if (
              isSaveClient &&
              $scope.client.selected &&
              $scope.client.selected.originalObject
            ) {
              $scope.familyDetails.ClientReferrerFamilyId =
                $scope.client.selected.originalObject.FamilyID || 0;
            }
            if (isSaveDetails) {
              $scope.familyDetails.OtherContactReferrerName =
                $scope.client.additionalDetails;
            }
            $scope.familyDetails.isMailingAddress =
              $scope.contactAddress.isMailingAddress;
            $scope.familyDetails.MainEmail = $scope.contactAddress.MainEmail;
            $scope.familyDetails.ContactStatusId = angular.copy(
              $scope.ContactStatus.ContactStatusId,
            );
            $scope.familyDetails = accountService.copyReferral(
              $scope.familyDetails,
              $scope.contactInfoSetValue,
            );
            initClientAddressSaving({
              $scope,
              generalService,
              addressLookupService,
              setAdviser,
            });
            return $scope.familyDetails;
          });
      };

      $scope.ContactStatus = {};
      $scope.updateContactStatus = (status) => {
        clientInfoService.updateContactStatus($scope.ContactStatus, status);
      };

      const checkReferralRequirements = () => {
        if (
          !$scope.contactInfoSetValue ||
          !$scope.contactInfoSetValue.EnquirySource ||
          !$scope.contactInfoSetValue.EnquirySource.ReferralCategoryId
        ) {
          return true;
        }
        const selectedEnquiry =
          $scope.contactInfoSetValue.EnquirySource.ReferralCategoryId;
        const isValid =
          !!$scope.contactInfoSetValue.ReferrerNameLabel &&
          !!$scope.contactInfoSetValue.ReferrerID;
        if (
          parseInt(selectedEnquiry, 10) !== parseInt(REFERRAL_ITEM.REFERRAL, 10)
        ) {
          return true;
        }
        $scope.showReferralAlert = !isValid;
        $scope.canSave = !isValid;
        return isValid;
      };

      $scope.canSave = true;
      $scope.sendFactFind = false;
      $scope.sendFactFindCheck = function (value) {
        $scope.sendFactFind = value;
      };
      $scope.isInvalidBusinessName = false;
      $scope.saveAccount = function (form) {
        $scope.canSave = false;
        if (!checkReferralRequirements()) {
          return;
        }
        $scope.isInvalidForm = true;
        angular.forEach($scope.personDetails, (data) => {
          const DOB = moment(data.DOB, ['DD-MM-YYYY']);
          if (data.DOB !== '' && !DOB.isValid()) {
            toaster.pop(
              'warning',
              'Invalid Date of Birth',
              'Please fill in a valid date',
            );
            $scope.isInvalidForm = false;
            $scope.canSave = true;
            return;
          }

          const dobSplit = data.DOB.split('/');
          if (dobSplit[2] < 1700) {
            toaster.pop(
              'warning',
              'Invalid Date of Birth',
              'Year must not be below 1700',
            );
            $scope.isInvalidForm = false;
            $scope.canSave = true;
          }
        });

        if ($scope.isInvalidForm) {
          $scope.saveAccountForPipeline(form);
        }
      };
      $scope.saveAccountForPipeline = (form) => {
        if (!checkReferralRequirements()) {
          return;
        }
        if ($scope.accountType === 'Client') {
          $scope.saveClientAccount(form);
        }
        if ($scope.accountType === 'Business') {
          $scope.validateBusinessAccount(form);
        }
      };
      $scope.cancel = function () {
        if (
          $localStorage.loanApplicationCreateNewContact ||
          $localStorage.loanProfilerCreateNewContact
        ) {
          $state.go(
            $localStorage.previousState,
            $localStorage.previousStateParams,
          );
        } else {
          stateService.routeToContactList();
        }
      };

      $scope.validateMobile = isMobileValid;

      $scope.saveClientAccount = function (form) {
        const formAccessKeys = {
          modelEmail: 'MainEmail',
          modelMobileNumber: 'MobileNumber',
          formMobileNumber: 'formMobileNumber',
          formFirstName: 'formDataFirstName.personFirstName',
          formLastName: 'formDataLastName.personLastName',
        };
        const contactModel = $scope.personDetails[0];
        const isFormValid = clientContactFormValidation({
          form,
          $scope,
          contactModel,
          formAccessKeys,
          inputElementId: 'mobileNumber-dc',
          countryCodeService,
          isBypassMobileValidation: $scope.isBypassMobileValidation,
          angular,
        });

        if (!isFormValid) {
          $scope.prevStep();
          $scope.canSave = true;
          return;
        }

        $scope.canSave = false;
        const allContacts = [];
        const personMobiles =
          form?.$error?.ngIntlTelInput && getBypassMobileValidation(form);

        if (personMobiles?.length) {
          $scope.personDetails[0].MobileNumber = personMobiles[0];
          if (personMobiles.length > 1) {
            $scope.personDetails[1].MobileNumber = personMobiles[1];
          }
        }

        /* assigning adviserid to the contact */
        $scope.allocatedAdviser.adviserSelected.description.FamilyId =
          $scope.AssignedAdviserId;
        $scope.personDetails.forEach((obj) => {
          obj.AllocatedAdviserID = $scope.AssignedAdviserId || 0;
          obj.AllocatedAssistantID = $scope.allocation.assistantId || 0;

          $scope.selectedCountry = assignSelectedCountryValue({
            inputElement: angular.element('#mobileNumber-dc'),
            flagCondition: $scope.isInternationalMobileNumber,
            currentUserService,
          });

          obj.Phone = [
            {
              Type: 'Mobile',
              Number: obj.MobileNumber,
              CountryCode: $scope.selectedCountry.iso2,
            },
          ];
          obj.Email = [{ Type: 'Email', EmailAddress: obj.MainEmail }];
          allContacts.push(obj);
        });

        $scope.childDetails.forEach((obj) => {
          allContacts.push(obj);
        });

        if (allContacts.length > 0) {
          clientInfoService
            .dateFormattingList(angular.copy(allContacts))
            .then((res) => {
              const tempAllContacts = res.data;
              const contactPayload = $scope.isInternationalMobileNumber
                ? stripPhoneFromContactSetPayload(tempAllContacts)
                : tempAllContacts;
              $scope.clientDetails = tempAllContacts;
              contactService
                .addContact(contactPayload)
                .then((response) => {
                  // @TODO : Get the newly created family ID
                  // Use /contacts/ContactFamilyInfoGet one we will have the family ID

                  $scope.FamilyId = response.data;
                  const successAddContactCallback = () => {
                    $scope.contactFamilyInfoGet({
                      FamilyId: $scope.FamilyId,
                      allocatedAdviserId:
                        $scope.allocatedAdviser.adviserSelected.description
                          .FamilyId,
                    });

                    if (!$localStorage.loanApplicationCreateNewContact) {
                      if (uiService.viewOfAfileTurnedOn) {
                        return;
                      }
                      toastSuccess('Contact has been Added Successfully.');

                      const { urlLocation } = $state.params;
                      $scope.hasHelloBookAccess =
                        $scope.isHelloBookEnabled &&
                        !$scope.pipeLineParam &&
                        VALID_HELLO_BOOK_CREATE_LOCATION.includes(urlLocation);
                      if ($scope.showPopUp && !$scope.openFactFind) {
                        $scope.openInterceptModal();
                        return;
                      }
                      openHelloBook();
                    }
                  };

                  $scope.isInternationalMobileNumber &&
                    saveStandAloneClientContactNumber({
                      familyId: $scope.FamilyId,
                      contacts: tempAllContacts,
                      contactService,
                      contactModelService,
                      successAddContactCallback,
                    });

                  !$scope.isInternationalMobileNumber &&
                    successAddContactCallback();
                })
                .catch((error) => {
                  $scope.canSave = true;
                  toastError(error.data.Message);
                });
            });
        }
      };

      $scope.setBypassMobileValidation = (bool) => {
        $scope.isBypassMobileValidation = bool;
      };

      $scope.isMobileNumberValid = isMobileNumberValid;

      $scope.validateBusinessAccount = (form) => {
        $scope.isInvalidBusinessName =
          form.businessName && form.businessName.$invalid;
        const isBusinessNameInvalid =
          form.businessName.$invalid ||
          (form.businessName.$invalid && form.businessName.$dirty);
        const isFormValid = $scope.isInternationalPhoneNumbers
          ? form.$invalid ||
            !$scope.isMobileNumberValid({
              countryCodeService,
              mobileNumber: $scope.business.Phone,
              isPhone: true,
              isInternationalPhoneEnabled: $scope.isInternationalMobileNumber,
              isInternationalPhoneNumbers:
                !$scope.isInternationalPhoneNumbers &&
                $scope.isPhoneNumberCountryCodeNZ,
              angular,
            })
          : isBusinessNameInvalid;
        if (isAddressInvalid($scope, checkBusinessAddressValidity($scope))) {
          return;
        }
        if (isFormValid) {
          const isMobileInputValid =
            $scope.isInternationalPhoneNumbers &&
            !$scope.isIntlPhoneInput({
              inputElementId: 'phoneNumberBusiness',
              phoneNumber: $scope.business.Phone,
              isOtherFieldInvalid: isBusinessNameInvalid,
              isPhone: true,
            });
          if (isMobileInputValid) {
            $scope.canSave = true;
            return false;
          }
          $scope.prevStep();
          toastError('Invalid Form - Please fill up the form correctly');
          $scope.canSave = true;
          return false;
        }

        const isValidNZBNChar = validateNZBNfieldOnSubmit(
          { ...configService, ...currentUserService },
          { $scope },
        );

        if (isValidNZBNChar) {
          ifNotValidNZBNChar({ $scope })();
          return;
        }
        initBusinessAddressSaving({
          $scope,
          generalService,
          addressLookupService,
        });
      };
      $scope.saveBusinessAccount = (address) => {
        $scope.selectedCountryCode = countryCodeService.getAssignedCountryCode({
          isInternationalPhoneNumbers: $scope.isInternationalPhoneNumbers,
          selectorElement: angular.element('#phoneNumberBusiness'),
        });

        const org = {
          OrganisationId: '0',
          Name: $scope.business.Name,
          Description: $scope.business.Description,
          OrganisationTaxNumber: $scope.business.OrganisationTaxNumber,
          OrganisationCompanyNumber: $scope.business.OrganisationCompanyNumber,
          FamilyId: '0',
          Phone: [
            {
              Type: 'Work',
              Number: $scope.business.Phone,
              CountryCode: $scope.selectedCountryCode.iso2,
            },
          ],
          Address: address,
          Email: [
            {
              Type: 'Email',
              EmailAddress: $scope.business.OfficeEmail,
            },
          ],
          NZBN:
            ($scope.business.employmentNZBN &&
              $scope.business.employmentNZBN.toString()) ||
            '',
          Notes: $scope.business.Notes,
          PersonId: '0',
        };

        const myCRMconfigs = {
          ...configService,
          ...currentUserService,
        };
        onCreateContactOrganisationInfoSet({
          $scope,
          $state,
          contactService,
          contactModelService,
          myCRMconfigs,
          org,
          viewOfFileModalService,
          uiService,
        });
      };

      $scope.homeAutoAddresss = function () {
        $timeout.cancel($scope.searchTimeout);
        $scope.searchTimeout = $timeout(() => {
          generalService
            .placeSearch($scope.contactAddress.HomeAddress)
            .then((respond) => {
              $scope.homeAutoAddressDetails = respond.data;
            });
        }, 500);
      };
      $scope.isGeocodeHome = false;
      $scope.isGeocodeMail = false;
      $scope.isClickedHome = false;
      $scope.isClickedMail = false;
      $scope.selectHomeAddress = function (homeAddress) {
        $scope.contactAddress.HomeAddress = homeAddress;
        $scope.isGeocodeHome = true;
        $scope.closeAutoHomeAddress();
      };
      $scope.$watch('contactAddress.HomeAddress', () => {
        if ($scope.isClickedHome) {
          $scope.isClickedHome = false;
        } else if ($scope.isGeocodeHome) {
          $scope.isGeocodeHome = false;
        }
      });

      $scope.geoCodedAddress = false;
      $scope.selectBusinesAddress = function (homeAddress) {
        $scope.business.Address = homeAddress;
        $scope.geoCodedAddress = true;
        $scope.closeOfficeAddress();
      };

      $scope.businessAutoAddresss = function () {
        $timeout.cancel($scope.searchTimeout);
        $scope.searchTimeout = $timeout(() => {
          generalService
            .placeSearch($scope.business.Address)
            .then((respond) => {
              $scope.officeAutoAddressDetails = respond.data;
            });
        }, 500);
      };

      $scope.closeAutoHomeAddress = function () {
        $scope.homeAutoAddressDetails = [];
      };
      $scope.closeOfficeAddress = function () {
        $scope.officeAutoAddressDetails = [];
      };

      $scope.mailAutoAddresss = function () {
        $timeout.cancel($scope.searchTimeout);
        $scope.searchTimeout = $timeout(() => {
          generalService
            .placeSearch($scope.contactAddress.MailingAddress)
            .then((respond) => {
              $scope.mailAutoAddressDetails = respond.data;
            });
        }, 500);
      };

      $scope.selectMailAddress = function (mailAddress) {
        $scope.contactAddress.MailingAddress = mailAddress;
        $scope.isGeocodeMail = true;
        $scope.closeAutoMailAddress();
      };
      $scope.$watch('contactAddress.MailingAddress', () => {
        if ($scope.isClickedMail) {
          $scope.isClickedMail = false;
        } else if ($scope.isGeocodeMail) {
          $scope.isGeocodeMail = false;
        }
      });

      $scope.closeAutoMailAddress = function () {
        $scope.mailAutoAddressDetails = [];
      };

      // app behavior
      $scope.getNumberArray = function (num) {
        return new Array(num);
      };
      $scope.nextStep = function (form) {
        if ($scope.currentMobileTab !== $scope.finalMobileTab) {
          $scope.currentMobileTab++;
        } else {
          $scope.saveAccount(form);
        }
      };
      $scope.prevStep = function () {
        if ($scope.currentMobileTab > 1) {
          $scope.currentMobileTab--;
        }
      };

      $scope.contactInfoSetValue = {};
      enquirySourceService.getEnquirySourceList($scope);

      // Enquiry is changed
      $scope.enquiryChanged = () => {
        clientInfoService.enquiryChanged($scope.contactInfoSetValue);
      };

      $scope.referredByHelper = {
        showClientList: false,
        loopCount: 0,
        getReferralOrgList() {
          if (
            $scope.referredByHelper &&
            $scope.referredByHelper.loopCount < 1 &&
            $scope.AssignedAdviserId > 0
          ) {
            referencedReferralService.getReferencedReferralList(
              $scope.AssignedAdviserId,
              $scope,
            );
          }
        },
        searchClient() {
          $scope.clientNotFound = false;
          $scope.referredByHelper.showClientList = true;
          $scope.referredByHelper.getReferralOrgList();
        },
      };
      $scope.referredByHelper.selectClient = (client) => {
        clientInfoService.selectClient(
          $scope.contactInfoSetValue,
          $scope.referredByHelper,
          client,
        );
      };
      $scope.toggleChildrenDiv = function (referralCategory) {
        const { referralCategoryList } = $scope;
        contactSharedData
          .toggleChildrenDiv(referralCategory, referralCategoryList)
          .then((response) => {
            $scope.contactInfoSetValue = response.contactInfoSetValue;
            $scope.selectedReferralCategory = response.selectedReferralCategory;
          });
      };

      $scope.openReferralModal = function (size) {
        const {
          getReferralCategoryList: getReferralCategory,
          contactFamilyInfoGet,
          contactInfoSetValue,
          selectedReferralCategory,
          referralCategoryList,
        } = $scope;
        const modalInstance = contactSharedData.openReferralModal(
          size,
          getReferralCategory,
          contactFamilyInfoGet,
          contactInfoSetValue,
          selectedReferralCategory,
          referralCategoryList,
        );
        modalInstance.result.then(
          () => {
            enquirySourceService.getEnquirySourceList($scope);
          },
          () => {
            enquirySourceService.getEnquirySourceList($scope);
          },
        );
      };

      optionsService.ContactStatusGet().then(({ data }) => {
        const viewOfAFileContactStatus = [
          CONTACT_STATUS.EXISTING_CLIENT,
          CONTACT_STATUS.PROFESSIONAL_PARTNER,
          CONTACT_STATUS.PREVIOUS_CLIENT,
          CONTACT_STATUS.NEW_CLIENT,
        ];
        const filteredList = contactStatusService.filterStatusList(
          data,
          uiService.viewOfAfileTurnedOn,
        );
        $scope.contactStatusList = contactStatusService.populateStatusWithColor(
          filteredList,
        );
        if ($scope.viewOfAfileTurnedOnAndNotCorp) {
          const filterContact = (status = {}) =>
            viewOfAFileContactStatus.includes(parseToInt10(status.Value));
          $scope.contactStatusList = $scope.contactStatusList.filter(
            filterContact,
          );
        }
      });

      $scope.clientEnquiryList = CLIENT_REFERRAL;
      $scope.additionalEnquiryList = ADDITIONAL_REFERRAL;
      $scope.referredByAccessList = REFERRED_BY_ACCESS;
      $scope.displayField = displayField;
      $scope.clientSearchApi = (userInputString) => {
        const defer = $q.defer();
        defer.resolve([]);
        if (!userInputString || !$scope.AssignedAdviserId) {
          return defer.promise;
        }
        return contactService.searchFamilyContactType(
          userInputString,
          'Client',
          $scope.AssignedAdviserId,
        );
      };

      $scope.getAdviserBranding = () => {
        setLoadingAdviserTheme(true);
        contactModelService
          .getFamilyAdviserTheme($scope.brokersInfo.brokerId)
          .then((data) => {
            setLoadingAdviserTheme(false);
            if (!data) {
              return;
            }
            $scope.isBrandingReady =
              !data.isBYOB || !!(data && data.logoDocId && data.tradingName);
          })
          .catch(() => setLoadingAdviserTheme(false));
      };
      getAdvisersList({ $scope, mycrmLookupService, currentUserService });
    });
