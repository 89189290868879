import {
  IS_LIMITS,
  IS_AU_LIMITS,
  IS_NEXT_PROP,
  IS_LOANS,
  IS_INTERESTS,
} from 'Common/constants/liabilityType';

class loanProfilerDetailService {
  liabilityIsLimit(liabilityTypeID = 0, nzNewServiceability) {
    const isLimits = nzNewServiceability
      ? IS_LIMITS.concat(IS_LOANS, IS_INTERESTS)
      : IS_AU_LIMITS;
    return isLimits.includes(+liabilityTypeID);
  }

  liabilityIsNextProp(liabilityTypeID = 0) {
    return IS_NEXT_PROP.includes(liabilityTypeID);
  }

  $onInit() {
    this.isTooltipShown = false;
  }

  hideTooltip() {
    this.isTooltipShown = false;
  }

  showTooltip() {
    this.isTooltipShown = true;
  }
}

export default loanProfilerDetailService;
