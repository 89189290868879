import set from 'lodash/set';
import get from 'lodash/get';
import {
  groupedArraysToObjectGroup,
  assignArrayGroupByColumn,
} from 'Common/utilities/array';
import { formatDate } from 'Common/utilities/date';
import { toastError } from 'Common/utilities/alert';
import {
  ALT_DATE_INPUT_FORMATS,
  DD_MMM_YYYY_FORMAT,
  TO_MYCRM_DATE_FORMAT,
} from 'Common/constants/dateConfigs';
import { NOT_FOUND } from 'Common/constants/httpStatusCodes';

const TEMPLATE_COLUMN = 2;
const TEMPLATE_MIN_ROW = 3;

export default class HelloPackFileInviteCtrl {
  constructor(documentCollectionService, helloPackService) {
    'ngInject';

    this.documentCollectionService = documentCollectionService;
    this.helloPackService = helloPackService;
  }

  $onInit() {
    const fileInvite = get(this.model, 'fileInvite', {
      reminders: {},
      templates: [],
      dueDate: '',
    });

    this.fileInvite = { ...fileInvite };
    this.dateSettings = {
      format: DD_MMM_YYYY_FORMAT,
      isOpen: false,
      dateOptions: {
        formatYear: 'yy',
        showWeeks: false,
      },
      altInputFormats: ALT_DATE_INPUT_FORMATS,
      openDate: (obj) => {
        obj.isOpen = !obj.isOpen;
      },
    };
    this.getReminders();
    this.getOverdueReminders();
    const isDocumentRequestToggled = get(
      this,
      'model.options.documentRequest.value',
      false,
    );
    isDocumentRequestToggled && this.getTemplates();
    this.helloPackService.handler.onToggleFileInvite = () => {
      this.getTemplates();
    };
  }

  getReminders() {
    return this.documentCollectionService.getReminders().then(({ data }) => {
      const defaultValue = [];
      this.reminders = data ? [...data] : defaultValue;
    });
  }

  getOverdueReminders() {
    return this.documentCollectionService
      .getReminders({ isOverdue: true })
      .then(({ data }) => {
        const defaultValue = [];
        this.overdueReminders = data ? [...data] : defaultValue;
      });
  }

  getTemplates() {
    if (this.isTemplateRequested) {
      return;
    }
    return this.documentCollectionService
      .getTemplates()
      .then(({ data, status }) => {
        this.isTemplateRequested = true;
        if (status === NOT_FOUND) {
          this.templates = [];
          this.groupedTemplates = {};
          return toastError(data, 'Fetching Template Error');
        }
        const fileInviteTemplates = get(this.model, 'fileInvite.templates', []);
        const templates = data
          ? data.map((template) => {
              template.selected = fileInviteTemplates.includes(template.id);
              return template;
            })
          : [];
        const defaultSelected = templates.reduce(
          (selectedTemplates, template) => {
            if (template.selected) {
              return [...selectedTemplates, template.id];
            }
            return selectedTemplates;
          },
          [],
        );
        set(this.model, 'fileInvite.templates', defaultSelected);
        this.templates = assignArrayGroupByColumn(
          templates,
          TEMPLATE_COLUMN,
          TEMPLATE_MIN_ROW,
        );
        this.groupedTemplates = groupedArraysToObjectGroup(this.templates);
        set(this, 'model.fileInvite.availableTemplates', this.templates);
      });
  }

  onChangeDueDate(dueDate) {
    set(
      this,
      'model.fileInvite.dueDate',
      formatDate(TO_MYCRM_DATE_FORMAT)(dueDate),
    );
  }

  onChangeTemplate({ value, id }) {
    const templatesModel = 'model.fileInvite.templates';
    const selectedIds = get(this, templatesModel, []);
    if (!value) {
      const newSelectedIds = selectedIds.filter(
        (selectedId) => selectedId !== id,
      );
      set(this, templatesModel, newSelectedIds);
    } else {
      set(this, templatesModel, [...selectedIds, id]);
    }
  }
}
