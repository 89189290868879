import { OPPORTUNITY_SERVICEABILITY_INCOME_TYPES } from 'Common/constants/incomeTypes';
import { CALCULATION_TYPE } from 'Common/constants/calculations';
import { OPPORTUNITY } from 'Common/constants/paramTypes';
import { isEmpty, isValidObjectValue } from 'Common/utilities/objectValidation';
import { toastSaveSuccess } from 'Common/utilities/alert';
import { getLenderLogoImg } from 'Common/utilities/image';
import { parseToInt10 } from 'Common/utilities/parse';
import {
  formatPersonParty,
  getLatestCalculation,
  getLiabilityModel,
  isValidFinancialModel,
  onModelUpdate,
} from 'Components/opportunity/opportunityMain/opportunityDetails/util/opportunityCalculation';
import {
  getLiabilityInfo,
  mapOtherLiability,
  mapCreditCard,
  mapIncome,
} from 'Components/opportunity/opportunityMain/opportunityDetails/opportunityServiceability/utils';

const CALCULATE_BUTTON_LABEL = {
  DEFAULT: 'Calculate',
  INPROG: 'Calculating',
  COMPLETED: 'Calculated',
};
const SERVICEABILITY_PART = {
  ASSESSMENT: 'Assessment',
  INCOME: 'Income',
  LIABILITY: 'Liability',
};

export default class OpportunityServiceabilityCtrl {
  constructor(
    $scope,
    $state,
    $timeout,
    $stateParams,
    optionsService,
    contactService,
    currentUserService,
    loanProfilerService,
    loanOpportunityService,
    opportunityCalculationService,
    $q,
    uiService,
    loanAppSharedData,
    loanAppDetailsService,
    loanCalculatorLinking,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.$q = $q;
    this.$timeout = $timeout;
    this.$stateParams = $stateParams;
    this.optionsService = optionsService;
    this.contactService = contactService;
    this.currentUserService = currentUserService;
    this.loanProfilerService = loanProfilerService;
    this.loanOpportunityService = loanOpportunityService;
    this.opportunityCalculationService = opportunityCalculationService;
    this.uiService = uiService;
    this.loanAppSharedData = loanAppSharedData;
    this.loanAppDetailsService = loanAppDetailsService;
    this.loanCalculatorLinking = loanCalculatorLinking;

    this.CALCULATION_TYPE = CALCULATION_TYPE;
  }

  $onChanges(changes) {
    if (changes.involvedParties && !changes.loanId) {
      this.$onInit(true);
    }
  }

  $onInit(isInvolvedPartyChanged) {
    this.currentFamilyId = null;

    this.timeoutList = {
      creditCardLiabilityTimeout: {
        resetTimeout: null,
        savingTimeout: null,
      },
      otherLiabilityTimeout: {
        resetTimeout: null,
        savingTimeout: null,
      },
    };
    this.serviceabilityCalculationItem = {};
    this.frequencyTypeInitialList = [];
    this.frequencyTypeWordList = [];
    this.familyFinancialList = [];
    this.involvedByFamily = [];
    this.isFinancialSaving = false;
    this.isAddCalculationClicked = false;

    this.model = {
      familyId: null,
      incomeList: [],
      creditCard: getLiabilityModel(),
      otherLiabilities: getLiabilityModel(),
    };

    this.crmCurrency = this.currentUserService.brokerRegionalization.currencySign;

    this.calculateBtnLabel = CALCULATE_BUTTON_LABEL.DEFAULT;
    this.isCalculating = false;

    this.populateLoanFrequency();
    this.getOptionLiabilityTypes()
      .then(() => this.getFamilyFinancialList())
      .then(() =>
        this.groupPartyInvolvedByFamily(
          this.involvedParties.InvolvedPartyPerson,
        ),
      )
      .then(() => this.filterServiceabilityCalculation());

    this.loanOpportunityService
      .getLoanOpportunityGeneral(this.opportunityId)
      .then((details) => {
        this.deal = details;
      });

    if (this.uiService.viewOfAfileTurnedOn) {
      this.getServiceabilityForDeal();
    }
  }

  getServiceabilityForDeal() {
    this.loadingSkeletonCount = 3;
    this.loanOpportunityService
      .getLoanOpportunityCalculation(this.opportunityId)
      .then((data = []) => {
        this.serviceabilityList = data.filter(
          (item) => item.typeOfCalculation === CALCULATION_TYPE.SERVICEABILITY,
        );
      })
      .finally(() => {
        this.loadingSkeletonCount = 0;
      });
  }

  onDeleteServiceability($event, item) {
    const removeServiceabilityItem = (serviceability) => () => {
      this.serviceabilityList = this.serviceabilityList.filter(
        (serviceabilityItem) =>
          serviceabilityItem.brokerEventId !== serviceability.brokerEventId,
      );
    };
    this.opportunityCalculationService.removeCalculation(
      $event,
      item,
      removeServiceabilityItem(item),
    );
  }

  // deprecated
  async triggerFinancialUpdate() {
    return this.involvedByFamily.forEach((party) => {
      if (this.hasFamilyServiceability(party.familyId)) {
        this.setCurrentFamily(party.familyId);
        this.syncServiceabilityInfo();
      }
    });
  }

  hasFamilyServiceability(familyId) {
    return this.serviceabilityCalculationList.some(
      (calculationItem) =>
        parseToInt10(calculationItem.familyId) === parseToInt10(familyId),
    );
  }

  filterServiceabilityCalculation() {
    this.serviceabilityCalculationList =
      this.opportunityCalculationService.calculations.filter(
        (calculationItem) =>
          calculationItem.typeOfCalculation === CALCULATION_TYPE.SERVICEABILITY,
      ) || [];
  }

  getOptionLiabilityTypes() {
    return this.optionsService.LiabilityTypeGet().then(({ data }) => {
      if (!data) {
        return;
      }
      const creditCardType = data.find(
        (liability) => liability.Name === 'Credit Card',
      );
      this.creditCardType = creditCardType;

      const otherType = data.find((liability) => liability.Name === 'Other');
      this.otherLiabilitiesType = otherType;
    });
  }

  getFamilyFinancialList() {
    return this.loanOpportunityService
      .getFinancials(this.opportunityId)
      .then((response) => {
        if (!response) {
          return;
        }
        this.familyFinancialList = response;
        return this.familyFinancialList;
      });
  }

  populateLoanFrequency() {
    // Frequency Type List Data
    this.optionsService.FinancialFrequency().then(({ data }) => {
      if (!data) {
        return;
      }
      this.frequencyTypeInitialList = data.map((freq) => {
        return {
          label: freq.Name.slice(0, 1),
          value: parseToInt10(freq.Value),
        };
      });
      this.frequencyTypeWordList = data.map((freq) => {
        return {
          label: `per ${freq.Name.toLowerCase()}`,
          value: parseToInt10(freq.Value),
        };
      });
    });
  }

  groupPartyInvolvedByFamily(personList) {
    const liabilityTypes = {
      creditCardId: parseToInt10(this.creditCardType.Value),
      otherId: parseToInt10(this.otherLiabilitiesType.Value),
    };

    return Promise.all([
      formatPersonParty(personList, this.familyFinancialList, liabilityTypes),
    ]).then((response) => {
      const [personResponse] = response;
      this.involvedByFamily = [...personResponse];

      return this.involvedByFamily;
    });
  }

  // deprecated
  setDefaultFamily() {
    if (!isEmpty(this.involvedByFamily)) {
      this.currentFamilyId = this.involvedByFamily[0].familyId;
      this.setCurrentFamily(this.currentFamilyId);
    }
  }

  mappingServiceability() {
    const incomeInfo = this.contactService.getIncomeList(
      this.$state.params.sourceFamilyId,
    );
    const liabilityInfo = this.contactService.getLiabilityList(
      this.$state.params.sourceFamilyId,
    );
    this.$q.all({ incomeInfo, liabilityInfo }).then((res) => {
      const liabilityFinancialInfo = getLiabilityInfo(res);
      this.model.creditCard = mapCreditCard(
        liabilityFinancialInfo,
        this.model.creditCard,
      );
      this.model.otherLiabilities = mapOtherLiability(
        liabilityFinancialInfo,
        this.model.otherLiabilities,
      );

      const { data, showError } = mapIncome(
        res.incomeInfo,
        this.model.incomeList,
      );
      this.model.incomeList = data;
      this.model.showError = showError;
      if (!this.hasValidServiceability) {
        return;
      }

      this.syncServiceabilityInfo(null, true);
      this.calculate();
    });
  }

  setCurrentFamily(currentFamilyId) {
    this.familyObj =
      this.involvedByFamily &&
      this.involvedByFamily.find(
        (family) => family.familyId === currentFamilyId,
      );
    this.involvedFamilyPersons =
      (this.familyObj && this.familyObj.clients) || [];

    this.model = {
      ...this.model,
      familyId: currentFamilyId,
      creditCard: this.familyObj.liabilities.creditCard,
      otherLiabilities: this.familyObj.liabilities.otherLiabilities,
      incomeList: this.familyObj.clients,
    };
    this.setFamilyServiceability();
    this.mappingServiceability();
  }

  // deprecated
  setFamilyServiceability() {
    const familyServiceabilityList =
      this.serviceabilityCalculationList &&
      this.serviceabilityCalculationList.filter(
        (calculationItem) =>
          parseToInt10(calculationItem.familyId) ===
          parseToInt10(this.model.familyId),
      );

    this.serviceabilityCalculationItem =
      getLatestCalculation(familyServiceabilityList) || {};
    this.isValidateServiceability();
    this.setCalculationLenderLogo();
  }

  isValidateServiceability() {
    this.hasValidServiceability = isValidObjectValue(
      () =>
        this.serviceabilityCalculationItem.serviceability.highestServiceability,
    );

    return this.hasValidServiceability;
  }

  onUpdateFrequency(model, key, value) {
    model[key] = value;
    this.saveIncome(model).then((response) => {
      if (!response) {
        return;
      }
      toastSaveSuccess();
      if (!model.id) {
        model.id = response.id;
      }
    });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  onIncomeInputChange(...params) {
    // eslint-disable-next-line unicorn/no-unreadable-array-destructuring
    const [, , incomeModel] = params;
    const data = onModelUpdate(...params);
    const fieldTimeoutName = `incomeTimeout${incomeModel.clientId}`;
    const fieldTimeout = this.getFieldTimeout(fieldTimeoutName);

    data.isEditing = true;
    this.isFinancialSaving = true;
    fieldTimeout.savingTimeout &&
      this.$timeout.cancel(fieldTimeout.savingTimeout);
    fieldTimeout.resetTimeout &&
      this.$timeout.cancel(fieldTimeout.resetTimeout);

    if (!isValidFinancialModel(...params)) {
      return;
    }

    fieldTimeout.resetTimeout = this.$timeout(() => {
      data.isEditing = false;
    }, 1000);

    const syncIncomeServiceabilityFinancial = () => {
      this.syncServiceabilityInfo(SERVICEABILITY_PART.INCOME).then(
        (response) => {
          if (response.incomeList) {
            const updatedIncome = response.incomeList.find(
              (income) => income.id === data.id,
            );

            if (updatedIncome && !data.serviceabilityIncomeId) {
              data.serviceabilityIncomeId =
                updatedIncome.serviceabilityIncomeId;
            }
          }
        },
      );
    };

    fieldTimeout.savingTimeout = this.$timeout(() => {
      if (!data.isEditing && !data.isSaving) {
        data.isSaving = true;
        this.saveIncome(data)
          .then((response) => {
            if (!response) {
              return;
            }
            toastSaveSuccess();

            data.isEditing = false;
            data.isSaving = false;
            this.isFinancialSaving = false;
            if (!data.id) {
              data.id = response.id;
            }
          })
          .then(() => {
            this.hasFamilyServiceability(this.currentFamilyId) &&
              syncIncomeServiceabilityFinancial();
            this.redirectToServiceability();
          });
      }
    }, 2000);
  }

  saveIncome(data) {
    if (!data) {
      return;
    }
    const isExistingIncome = data && data.id;
    if (isExistingIncome) {
      return this.loanOpportunityService.updateClientIncome(
        this.opportunityId,
        data.id,
        data,
      );
    }

    return this.loanOpportunityService.addClientIncome(
      this.opportunityId,
      this.currentFamilyId,
      data,
    );
  }

  getFieldTimeout(fieldTimeoutName) {
    const fieldTimeout =
      this.timeoutList &&
      fieldTimeoutName &&
      this.timeoutList[fieldTimeoutName];
    if (!fieldTimeout) {
      const newFieldTimeout = {
        savingTimeout: null,
        resetTimeout: null,
      };
      this.timeoutList[fieldTimeoutName] = newFieldTimeout;
      return newFieldTimeout;
    }
    return fieldTimeout;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  onLiabilityInputChange(...params) {
    // eslint-disable-next-line unicorn/no-unreadable-array-destructuring
    const [, , liabilityModel] = params;
    const data = onModelUpdate(...params);
    const fieldTimeoutName = `liabilityTimeout${liabilityModel.liabilityTypeId}`;
    const fieldTimeout = this.getFieldTimeout(fieldTimeoutName);

    data.isEditing = true;
    this.isFinancialSaving = true;
    fieldTimeout.savingTimeout &&
      this.$timeout.cancel(fieldTimeout.savingTimeout);
    fieldTimeout.resetTimeout &&
      this.$timeout.cancel(fieldTimeout.resetTimeout);

    if (!isValidFinancialModel(...params)) {
      return;
    }

    fieldTimeout.resetTimeout = this.$timeout(() => {
      data.isEditing = false;
    }, 1000);

    const syncLiabilityServiceabilityFinancial = () => {
      this.syncServiceabilityInfo(SERVICEABILITY_PART.LIABILITY).then(
        (response) => {
          if (response.liabilityList) {
            const updatedLiability = response.liabilityList.find(
              (liability) => liability.id === data.id,
            );

            if (updatedLiability && !data.serviceabilityLiabilityId) {
              data.serviceabilityLiabilityId =
                updatedLiability.serviceabilityLiabilityId;
            }
          }
        },
      );
    };

    fieldTimeout.savingTimeout = this.$timeout(() => {
      if (!data.isEditing && !data.isSaving) {
        data.isSaving = true;
        this.saveLiability(data)
          // eslint-disable-next-line sonarjs/no-identical-functions
          .then((response) => {
            if (!response) {
              return;
            }
            toastSaveSuccess();

            data.isEditing = false;
            data.isSaving = false;
            this.isFinancialSaving = false;
            if (!data.id) {
              data.id = response.id;
            }
          })
          .then(() => {
            this.hasFamilyServiceability(this.currentFamilyId) &&
              syncLiabilityServiceabilityFinancial();
            this.redirectToServiceability();
          });
      }
    }, 2000);
  }

  saveLiability(data) {
    if (!data) {
      return;
    }
    const isExistingLiability = data && data.id;
    if (isExistingLiability) {
      return this.loanOpportunityService.updateFamilyLiability(
        this.opportunityId,
        data.id,
        data,
      );
    }

    return this.loanOpportunityService.addFamilyLiability(
      this.opportunityId,
      this.currentFamilyId,
      data,
    );
  }

  getMappedData(part, override) {
    const assessmentDetail =
      isValidObjectValue(
        () =>
          this.serviceabilityCalculationItem.serviceability.assessmentDetail,
      ) && this.serviceabilityCalculationItem.serviceability.assessmentDetail;

    const overrideAssessmentDetails = {
      ...assessmentDetail,
      brokerEventId:
        this.serviceabilityCalculationItem &&
        this.serviceabilityCalculationItem.brokerEventId,
      noOfSpouse: this.familyObj.clients.length,
      noOfDependents: this.getFamilyDependencyCount(this.familyObj.familyId),
      securityAmount: this.summary.SecurityAmount,
      loanAmount: this.summary.ProposedLoanAmount,
      lVR: this.summary.lvr,
      loanScenarioId: this.opportunityId,
      loanTerm: (assessmentDetail && assessmentDetail.loanTerm) || 30,
    };

    const data = {};
    switch (part) {
      case SERVICEABILITY_PART.ASSESSMENT:
        data.assessmentDetail = overrideAssessmentDetails;
        break;
      case SERVICEABILITY_PART.INCOME:
        data.incomeList = this.getFamilyIncome();
        break;
      case SERVICEABILITY_PART.LIABILITY:
        data.liabilityList = this.getFamilyLiability();
        break;
      default:
        data.assessmentDetail = overrideAssessmentDetails;
        data.incomeList = this.getFamilyIncome(override);
        data.liabilityList = this.getFamilyLiability();
    }

    return data;
  }

  getFamilyDependencyCount(familyId) {
    const familyDependency = this.involvedParties.Dependent.filter(
      (dependent) => parseToInt10(dependent.FamilyId) === familyId,
    );
    return familyDependency.length;
  }

  getFamilyIncome(override = false) {
    return this.model.incomeList
      .filter((clientIncome) => override || clientIncome.income.id)
      .map((clientIncome, index) => {
        return {
          ...clientIncome.income,
          brokerEventId:
            this.serviceabilityCalculationItem &&
            this.serviceabilityCalculationItem.brokerEventId,
          noOfClient: index + 1,
          incomeTypeId: this.currentUserService.isNZ
            ? OPPORTUNITY_SERVICEABILITY_INCOME_TYPES.SALARY
            : OPPORTUNITY_SERVICEABILITY_INCOME_TYPES.BASE_INCOME,
          percentage: 0,
        };
      });
  }

  getFamilyLiability() {
    const familyLiability = [];

    if (this.model.creditCard.id) {
      const creditCard = {
        ...this.model.creditCard,
        brokerEventId: this.serviceabilityCalculationItem.brokerEventId,
      };
      familyLiability.push(creditCard);
    }

    if (this.model.otherLiabilities.id) {
      const otherLiabilities = {
        ...this.model.otherLiabilities,
        brokerEventId: this.serviceabilityCalculationItem.brokerEventId,
      };
      familyLiability.push(otherLiabilities);
    }

    return familyLiability;
  }

  addCalculation() {
    if (this.isFinancialSaving) {
      this.isAddCalculationClicked = true;
      return;
    }

    const data = this.getMappedData();
    this.opportunityCalculationService.setServiceabilityMappedData(data);
    this.opportunityCalculationService.addCalculation(
      CALCULATION_TYPE.SERVICEABILITY,
      this.currentFamilyId,
    );
  }

  redirectToServiceability() {
    if (this.isAddCalculationClicked) {
      this.redirectServiceabilityTimeout = this.$timeout(() => {
        this.isAddCalculationClicked = false;
      }, 2000);
      this.addCalculation();
    }
  }

  calculate() {
    this.isCalculating = true;
    this.calculateBtnLabel = CALCULATE_BUTTON_LABEL.INPROG;

    return this.opportunityCalculationService
      .calculateServiceability(this.serviceabilityCalculationItem)
      .then((response) => {
        this.calculateBtnLabel = CALCULATE_BUTTON_LABEL.COMPLETED;
        this.isCalculating = false;
        this.calculateLabelTimeout = this.$timeout(() => {
          this.calculateBtnLabel = CALCULATE_BUTTON_LABEL.DEFAULT;
        }, 3000);

        this.serviceabilityCalculationItem.serviceability.highestServiceability = response;
        this.serviceabilityCalculationItem.createdBy = this.currentUserService.fullName;
        this.serviceabilityCalculationItem.dateOfCalculation = new Date();
        this.setCalculationLenderLogo();
      });
  }

  removeCalculation($event, calculation) {
    const onSuccessRemove = () => {
      this.filterServiceabilityCalculation();
      this.setFamilyServiceability();
    };
    this.opportunityCalculationService.removeCalculation(
      $event,
      calculation,
      onSuccessRemove,
    );
  }

  setCalculationLenderLogo() {
    const isValidServiceability = isValidObjectValue(
      () =>
        this.serviceabilityCalculationItem.serviceability.highestServiceability,
    );
    if (!isValidServiceability) {
      return;
    }
    const LOGO_HEIGHT = '40';
    const LOGO_WIDTH = '85';
    this.serviceabilityCalculationItem.logo = getLenderLogoImg(
      this.serviceabilityCalculationItem.serviceability.highestServiceability,
      LOGO_HEIGHT,
      LOGO_WIDTH,
    );
  }

  getFamilyData() {
    const familyId = this.$state.params.sourceFamilyId;
    const familyList = this.involvedParties.PersonBorrowers.filter(
      (person) => person.FamilyId === familyId,
    );
    const clientIds = familyList.map((person) => person.PersonId).join(',');
    const [firstPersonInFamily = {}] = familyList;
    const familyFullName = firstPersonInFamily.FamilyFullName;

    return {
      familyId,
      clientIds,
      familyFullName,
    };
  }

  routeToServiceability() {
    const { familyId, clientIds, familyFullName } = this.getFamilyData();
    const {
      ProposedLoanAmount: proposedLoanAmount,
      SecurityAmount: securityAmount,
      PrimaryPurpose: primaryPurpose,
    } = this.summary ?? {};

    const args = {
      tabName: 'serviceability',
      eventId: 0,
      id: this.opportunityId,
      linkerId: this.loanCalculatorLinking.LINKERS.OPPORTUNITY,
      title: this.deal.title,
      isEdit: false,
      isOpportunityOrLoanapp: true,
      previousRouteStateParams: {
        ...this.$stateParams,
        tabName: 'summary',
        lendingCategory: this.summary?.LendingCategoryId ?? 0,
        sourceFamilyName: familyFullName,
      },
      proposedLoanAmount,
      securityAmount,
      primaryPurposeId:
        primaryPurpose?.answerInt.length && primaryPurpose.answerInt[0],
      familyId,
      paramType: OPPORTUNITY,
      loanApplicationId: this.opportunityId,
      clientIds,
    };

    this.loanCalculatorLinking.redirectToLoanProfiler(args);
  }

  // deprecated
  $onDestroy() {
    const timeoutKeys = Object.keys(this.timeoutList);
    timeoutKeys.forEach((fieldTimeoutName) => {
      const fieldTimeout = this.timeoutList[fieldTimeoutName];
      this.$timeout.cancel(fieldTimeout.savingTimeout);
      this.$timeout.cancel(fieldTimeout.resetTimeout);
    });
    this.$timeout.cancel(this.calculateLabelTimeout);
    this.$timeout.cancel(this.redirectServiceabilityTimeout);
  }
}
