import angular from 'angular';
import { setupLogger, LogLevel } from '@loanmarket/logger-core';
import { CloudWatchLogger } from '@loanmarket/logger-cloud-watch';
import { cloudWatchKeyFunctions } from '@loanmarket/logger-cloud-watch/lib/utils/browser-fingerprint';

import { getConfigFromEnv } from 'Common/utilities/config';
import { setupRollout, getRolloutFeatureFlags } from 'Common/utilities/rollout';

import packet from './packet';
import {
  APP_MEDIAQUERY,
  JS_REQUIRES,
  DEMO_VIDEO,
  LEGACY_ANGULAR_HOST,
  TECHNOLOGY,
} from './config/config.constant';
import './config/config.calendar.js';
import ngIntlTelInput from './config/config.ngIntlTelInput';
import configIdleWatcher from './config/config.idlewatcher';
import configRouter from './config/config.router';
import configLoadingBar from './config/config.loadingbar';
import configBreadcrumb from './config/config.breadcrumb';
import configStorage from './config/config.storage';
import configBootstrapCalendar from './config/config.bootstrapcalendar';
import configLocationRoute from './config/config.locationRoute';
import configHttpProvider from './config/config.httpProvider';
import configNgSrc from './config/config.ng-src';
import configDocumentTitle from './config/config.documentTitle';
import configUibModal from './config/config.uibModal';

import main from './main';
import { runTheme, bootstrapOktaAuthentication } from './bootstrap';

import '../../../sass/styles.scss';
import '../../../sass/plugins.scss';
import '../../../sass/themes/main.scss';
import common from '../../src/common';
import components from '../../src/components';
import scenes from '../../src/scenes';
import services from '../../src/services';
import controllers from '../../src/controllers';

import { loadModules } from './load-angular-modules';

export const runApp = () => {
  /* eslint-disable angular/file-name */
  angular
    .module('app', [packet, common, components, scenes, services, controllers])
    .constant(...APP_MEDIAQUERY)
    .constant(...JS_REQUIRES)
    .constant(...DEMO_VIDEO)
    .constant(...LEGACY_ANGULAR_HOST)
    .constant(...TECHNOLOGY)
    .config(configIdleWatcher)
    .config(ngIntlTelInput)
    .config(configRouter)
    .config(configLoadingBar)
    .config(configBreadcrumb)
    .config(configStorage)
    .config(configLocationRoute)
    .config(configUibModal)
    .config(configHttpProvider)
    .config(configNgSrc)
    .run(configBootstrapCalendar)
    .run(configDocumentTitle)
    .run(main);

  /* eslint-disable angular/window-service, angular/document-service */
  window.app = angular.module('app');
  angular.element(async () => {
    loadModules();
    runTheme();
    const data = getConfigFromEnv();
    if (data.useCloudWatch === 'true') {
      setupLogger({
        minLogLevel: LogLevel.Info,
        logger: CloudWatchLogger,
        customLoggerArgs: {
          group: '/aws/frontend/mycrm-frontend',
          userAgent: window.navigator.userAgent,
          cloudWatchConfiguration: {
            accessKeyId: data.cloudWatchLogsAccessKeyId,
            secretAccessKey: data.cloudWatchLogsSecretAccessKey,
            region: data.cloudWatchLogsRegion,
          },
          maxRetries: 3,
          keyFunctions: cloudWatchKeyFunctions,
          newLogStreamPerRequest: true,
        },
      });
    } else {
      setupLogger({
        minLogLevel: LogLevel.Trace,
      });
    }

    await setupRollout(data);

    const features = getRolloutFeatureFlags();

    if (features.inMaintenance) {
      window.location.assign(data.oktaLogin);
      return;
    }
    await bootstrapOktaAuthentication();
  });
};
