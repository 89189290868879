import moment from 'moment';
import { D_MMM_YYYY_FORMAT } from 'Common/constants/dateConfigs';

const ACCREDITED = 2;
const CANCELLED = 3;

export const accreditationTypesForUI = (provider) => {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const forStatusLabeled = (statuses = {}) => {
    return {
      statusId: statuses.StatusId || 0,
      statusName: statuses.StatusName || '',
      isAccredited: statuses.StatusId === ACCREDITED,
      isCancelled: statuses.StatusId === CANCELLED,
    };
  };

  const { SignatureDetails = {} } = provider;

  return {
    accreditationTypeId: provider.AccreditationTypeId,
    providerId: provider.ProviderId,
    providerName: provider.ProviderName,
    familyId: provider.FamilyID,
    lenderIsActive: provider.Lender_IsActive,
    issueCodes: provider.IssueCodes,
    transferCodes: provider.TransferCodes,
    type: provider.Type,
    status: provider.Status,
    dateReceived: provider.DateReceived,
    dateSubmitted: provider.DateSubmitted
      ? new Date(provider.DateSubmitted)
      : null,
    watch: provider.Watch,
    comments: provider.Comments,
    adviserId: provider.AdviserId,
    aggregatorId: provider.AggregatorId,
    dateAccredited: provider.DateAccredited
      ? new Date(provider.DateAccredited)
      : null,
    dateAccreditedStatus: provider.DateAccredited
      ? moment(provider.DateAccredited).format(D_MMM_YYYY_FORMAT)
      : null,
    dateReceivedStatus: provider.DateReceived
      ? moment(provider.DateReceived).format(D_MMM_YYYY_FORMAT)
      : null,
    cancelledDate: new Date(provider.CancelledDate),
    cancelledDateStatus: moment(provider.CancelledDate).format(
      D_MMM_YYYY_FORMAT,
    ),
    statusId: provider.StatusId || null,
    accreditationStatus: provider.AccreditationStatus.map(forStatusLabeled),
    statusLabel: forStatusLabeled(
      provider.AccreditationStatus.find(
        (status) => status.StatusId === provider.StatusId,
      ),
    ),
    signatureDetails: {
      brokerName: SignatureDetails.BrokerName,
      contactType: SignatureDetails.ContactType,
      mobilePhone: SignatureDetails.MobilePhone,
      email: SignatureDetails.Email,
    },
  };
};

export const accreditationTypesForAPI = (data) => {
  return {
    ProviderId: data.providerId,
    ProviderName: data.providerName,
    FamilyID: data.familyId,
    Status: data.status,
    StatusId: data.statusId,
    DateAccredited: data.dateAccredited,
    DateSubmitted: data.dateSubmitted,
    Watch: data.watch,
    Comments: data.comments,
    AdviserId: data.adviserId,
    AggregatorId: data.aggregatorId,
    IssueCodes: data.issueCodes,
    AccreditationTypeId: data.accreditationTypeId,
  };
};

export const bulkAccreditationTypesForAPI = (providers) => {
  return {
    AccreditationList: providers.map(accreditationTypesForAPI),
  };
};

export const accreditationEmailForAPI = (data) => {
  return {
    EmailSubject: data.subject,
    EmailBody: data.content,
  };
};
