import { PIPELINE_CATEGORIES } from 'Common/constants/pipelineType';
import { moveLoanOpportunityItem } from 'Common/utilities/kanbanHelper';
import { notProceededModalContentMapper } from 'Common/utilities/loanFileHelper';

class LoanStatusConversionService {
  constructor(modalRenderService, loanOpportunityService, pipelineService) {
    'ngInject';

    this.modalRenderService = modalRenderService;
    this.loanOpportunityService = loanOpportunityService;
    this.pipelineService = pipelineService;
  }

  initializeConversion({ status, previousStatus = {} }) {
    const contentTitle = status.isNotProceeded
      ? 'Not Proceeded'
      : status.PipelineStatus;
    const modalContent = {
      content: `This record will be moved to ${contentTitle}`,
    };
    if (!status.isNotProceeded) {
      return this.showConfirmation(modalContent);
    }
    status.isApplication = !!previousStatus.isApplication;
    return this.getReasons(status).then(({ data }) => {
      return this.showConfirmation(
        notProceededModalContentMapper(modalContent, data),
      );
    });
  }

  getReasons(status) {
    if (status.isApplication) {
      return this.pipelineService.getPipelineCategoryReasons(
        PIPELINE_CATEGORIES.APPLICATION,
      );
    }
    return this.loanOpportunityService.getNotProceededReasons();
  }

  showConfirmation(modalContent) {
    const modalInstance = this.modalRenderService.renderPipelineStatusMoveConfirmationModal(
      modalContent,
    );
    return modalInstance.result;
  }

  convertStatus({ card, result = {} }) {
    const params = {
      card,
      isConversion: true,
      loanOpportunityService: this.loanOpportunityService,
    };
    return moveLoanOpportunityItem(params).then(({ data }) => {
      if (!data) {
        return { isOk: false };
      }
      return result;
    });
  }
}

export default LoanStatusConversionService;
