/* eslint-disable angular/window-service */
import { feeCardTypesBuilderForUI } from 'Common/mappers/feeCardTypes';
import {
  featureBuilderForUI,
  corporateTeamBuilderForUI,
  brokerBasicInfoForUI,
  tickableItemsBuilderForUI,
  lenderSubmissionBuilderForUI,
  advisersLookupBuilderForUI,
} from 'Common/mappers/general';
import { cacheObjects } from 'Assets/js/cache-objects';

class GeneralService {
  constructor(httpClient, $q) {
    'ngInject';

    this.httpClient = httpClient;
    this.$q = $q;
  }

  clientEntityListGet(searchString) {
    return this.httpClient.get(`ClientEntityListGet`, { searchString });
  }

  getBrokerAll(familyId) {
    return this.httpClient.get(`GetBrokerAll`, { familyId });
  }

  documentGet(params) {
    const data = typeof params === 'object' ? params : { documentID: params };
    return this.httpClient.get(`DocumentGet`, data, false, true, false);
  }

  sendPasswordResetLinkEmailSet(familyId, clientId) {
    return this.httpClient.post(`SendPasswordResetLinkEmailSet`, {
      familyId,
      clientId,
    });
  }

  getCheckIfEmailExists(
    email,
    familyId,
    checkOrg = 0,
    contactTypeId = '',
    excludeWorkEmail,
  ) {
    return this.httpClient.post(
      `CheckIfEmailExistsGet`,
      {},
      { email, familyId, checkOrg, contactTypeId, excludeWorkEmail },
    );
  }

  setWalkThrough(data) {
    return this.httpClient.post('WalkThroughSet', data);
  }

  getWalkThrough() {
    return this.httpClient.get('WalkThroughGet');
  }

  eDRSchemesGet() {
    return this.httpClient.get('EDRSchemesGet');
  }

  SMSSubscriptionStatusSet(familyId, subscriptionId, smsSendingStatus) {
    return this.httpClient.post(
      `SMSSubscriptionStatusSet`,
      {},
      { familyId, subscriptionId, smsSendingStatus },
    );
  }

  SMSSubscriptionStatusGet(familyId) {
    return this.httpClient.get(`SMSSubscriptionStatusGet`, { familyId });
  }

  getEmailSignature(familyId) {
    return this.httpClient.get(`EmailSignatureGet`, { familyId });
  }

  setEmailSignature(data) {
    return this.httpClient.post('EmailSignatureSet', data);
  }

  placeSearch(address) {
    if (!address) {
      return this.$q.resolve({ data: {} });
    }
    return this.httpClient.get(`PlaceSearch`, { address });
  }

  postCodeSearch(postCode) {
    return this.httpClient.get(`PostCodeSearch`, { postCode });
  }

  geocodeSearch(address) {
    return this.httpClient.get(`GeocodeSearch`, { address });
  }

  addressDetailsGet(addressId) {
    return this.httpClient.get(`AddressDetailsGet`, { addressId });
  }

  addressDetailsSet(address) {
    return this.httpClient.post('AddressDetailsSet', address);
  }

  adviserPipelineCategoryGet() {
    return this.httpClient.get(`AdviserPipelineCategoryGet`);
  }

  documentThumbnail(params) {
    return this.httpClient.get(`DocumentThumbnail`, params);
  }

  currencyCodeGet() {
    return this.httpClient.get(`CurrencyCodeGet`);
  }

  userTypes() {
    return this.httpClient.get(`UserTypes`);
  }

  brokerFees() {
    return this.httpClient.get(`BrokerFees`);
  }

  isClientNotCorpLockedOut(clientFamilyId) {
    return this.httpClient.get('IsClientNotCorpLockedOut', { clientFamilyId });
  }

  brokerpediaInfoSet() {
    return this.httpClient.get(`BrokerpediaInfoSet`);
  }

  brokerpediaLoginURLGet() {
    return this.httpClient.get(`BrokerpediaLoginURLGet`);
  }

  gotItTickedSet() {
    return this.httpClient.post('GotItTicked');
  }

  getFeatures() {
    return this.httpClient
      .get('features')
      .then((response) => featureBuilderForUI(response.data));
  }

  getFeaturesNoBuilder() {
    return this.httpClient.get('features').then((response) => response.data);
  }

  getCorporateTeams() {
    return this.httpClient
      .get(`corporate-teams`)
      .then(
        (response) =>
          response.data && response.data.map(corporateTeamBuilderForUI),
      );
  }

  getFeeCardTypes() {
    return this.httpClient
      .get('fee-card-types')
      .then((response) => response.data.map(feeCardTypesBuilderForUI));
  }

  getImportantDocumentFlags() {
    return this.httpClient.get(`important-document-flags`);
  }

  getBrokerBasicinfo() {
    // eslint-disable-next-line angular/window-service
    const getBrokerBasicInfoSession = cacheObjects?.getBrokerBasicInfo;
    if (getBrokerBasicInfoSession) {
      var response = getBrokerBasicInfoSession;
      return Promise.resolve(
        response.data && brokerBasicInfoForUI(response.data),
      );
    }

    return this.httpClient.get(`GetBrokerBasicinfo`).then((response) => {
      cacheObjects.getBrokerBasicInfo = response;
      response.data && brokerBasicInfoForUI(response.data);
    });
  }

  getTickableItems() {
    return this.httpClient
      .get(`tickable-item`)
      .then((response) => response.data.map(tickableItemsBuilderForUI));
  }

  postTickableItems(params) {
    return this.httpClient.post(`tickable-item`, {}, params);
  }

  getLenderSubmission() {
    return this.httpClient
      .get(`lender-submission-email`)
      .then(
        ({ data }) => (data && data.map(lenderSubmissionBuilderForUI)) || [],
      );
  }

  getAdviserList(params) {
    return this.httpClient
      .get(`adviser-list`, params)
      .then(({ data }) => data && data.map(advisersLookupBuilderForUI));
  }

  getDynamicUrl(keyName = '') {
    return this.httpClient.get(`dynamic-url`, { dynamicUrlKey: keyName });
  }
}

export default GeneralService;
