import { cloneDeep } from 'lodash';
import { SEND_TO_TABS, GAME_PLAN_SECTIONS } from 'Common/constants/gamePlan';
import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import {
  toastError,
  toastSaveSuccess,
  showCustomConfirmation,
} from 'Common/utilities/alert';
import { objectLength } from 'Common/utilities/objectValidation';
import { setupNewPostURL } from 'Common/utilities/handleObsoleteURL';
import { getClientEmailMobilePayload } from 'Common/utilities/contact';
import {
  onUpdateEmailValue,
  updateApplicantEmailModel,
  updateApplicantFromList,
  filterApplicantByType,
  flattenPersonList,
  extendApplicantModel,
  getSectionName,
} from '../../util/gamePlan';

const SEE_APPLICANT_LIMIT = 2;
export default class GamePlanSendingMomentSidebar {
  constructor(
    $timeout,
    contactService,
    gamePlanGeneratorService,
    gamePlanSharedDataService,
    crmConfirmation,
    currentUserService,
    helloBookService,
  ) {
    'ngInject';

    this.timeout = $timeout;
    this.currentUserService = currentUserService;
    this.contactService = contactService;
    this.helloBookService = helloBookService;
    this.gamePlanGeneratorService = gamePlanGeneratorService;
    this.gamePlanSharedDataService = gamePlanSharedDataService;
    this.showCustomConfirmation = showCustomConfirmation;
    this.crmConfirmation = crmConfirmation;
  }

  $onInit() {
    this.gamePlanSections =
      GAME_PLAN_SECTIONS[this.currentUserService.countryCode];
    this.isSeeMore = false;
    this.moreCount = null;
    this.isDownloading = false;
    this.activeFamilyList = [];
    this.allClientFamilyList = [];
    this.gamePlanSharedDataService.oldMergedNames = ``;
    this.sectionList = [];
    this.activeTab = SEND_TO_TABS.APPLICANTS;
    this.SEND_TO_TABS = SEND_TO_TABS;
    this.onUpdateEmailValue = onUpdateEmailValue;
    this.sentEmailText = {};
    this.isSendingGameplanOn = this.gamePlanSharedDataService.isSendingGamePlanOn;
    this.isEsignEnabled = false;
    this.getSectionName = getSectionName;

    this.populateApplicantList();
    this.processApplicantData();
    this.setFilteredApplicants();
    this.setApplicantListCount();
    this.processGamePlanSectionsData();

    this.updateSharedService();
    this.setSections();
    this.processTimeOut = this.timeout(() => {
      this.getDefaults();
      this.gamePlanSharedDataService.isSectionOnSaveDefaultEdited = true;
      this.gamePlanSharedDataService.isSavingSideBar = false;
      this.saveSectionButtonText = this.gamePlanSharedDataService
        .isSectionSettingsDefault
        ? this.gamePlanSharedDataService.saveButtonText.isSaved
        : this.gamePlanSharedDataService.saveButtonText.isReset;
    }, 500);
    this.getSubscriptionInfo();
  }

  $onChanges(changes) {
    if (changes.applicants) {
      this.populateApplicantList();
    }
  }

  $onDestroy() {
    this.timeout.cancel(this.processTimeOut);
  }

  getSubscriptionInfo() {
    this.contactService
      .subscriptionInfo(this.loanAdviserId)
      .then((response) => {
        this.isEsignEnabled =
          response != null &&
          response.length &&
          response.some(
            (item) =>
              item.subscriptionProductId === SUBSCRIPTIONTYPE.E_SIGN &&
              item.isActive,
          );
      });
  }

  editEmailMessage() {
    this.gamePlanSharedDataService.isEditEmailMessage = true;
    this.gamePlanSharedDataService.updateEmailName();
  }

  processGamePlanSectionsData() {
    this.sectionList = this.gamePlanSections.map((section) => {
      return {
        ...section,
        isActive: false,
        isIncluded: true,
      };
    });

    const activeSection =
      this.sectionList.find((section) => section.isDefaultActive) || {};
    activeSection.isActive = true;
  }

  populateApplicantList() {
    this.borrowers = [];
    this.guarantors = [];
    this.personList = flattenPersonList(this.applicants.InvolvedPartyPerson);

    const personBorrowers = filterApplicantByType({
      partyList: this.personList,
      isApplicant: true,
      isGuarantor: false,
    });
    const entityBorrowers = filterApplicantByType({
      partyList: this.applicants.InvolvedPartyEntity,
      isApplicant: true,
      isGuarantor: false,
    });
    const personGuarantors = filterApplicantByType({
      partyList: this.personList,
      isApplicant: false,
      isGuarantor: true,
    });

    this.borrowers = [...personBorrowers, ...entityBorrowers];
    this.guarantors = [...personGuarantors];
    this.gamePlanSharedDataService.borrowers = this.borrowers;
    this.gamePlanSharedDataService.guarantors = this.guarantors;
  }

  checkIfEmailIsSent() {
    return (
      this.sentEmailText.hasSentEmailToApplicant &&
      !this.guarantors.length &&
      this.isSendingGameplanOn
    );
  }

  processApplicantData() {
    this.borrowers = this.borrowers.map((applicant) =>
      extendApplicantModel(applicant),
    );
    this.guarantors = this.guarantors.map((applicant) =>
      extendApplicantModel(applicant),
    );
  }

  isApplicantActive() {
    return this.activeTab === SEND_TO_TABS.APPLICANTS;
  }

  isGuarantorActive() {
    return this.activeTab === SEND_TO_TABS.GUARANTORS;
  }

  updateSharedService() {
    const selectedApplicants = this.allClientFamilyList.filter(
      (applicant) => applicant.isSelected,
    );
    this.gamePlanSharedDataService.setSelectedApplicants(
      selectedApplicants,
      this.isApplicantActive(),
    );
    this.gamePlanSharedDataService.isApplicantActive = this.isApplicantActive();
  }

  setApplicantListCount() {
    if (!this.isSeeMore) {
      if (
        this.isApplicantActive() &&
        this.borrowers.length > SEE_APPLICANT_LIMIT
      ) {
        this.moreCount = this.borrowers.length - SEE_APPLICANT_LIMIT;
      } else if (
        this.isGuarantorActive() &&
        this.guarantors.length > SEE_APPLICANT_LIMIT
      ) {
        this.moreCount = this.guarantors.length - SEE_APPLICANT_LIMIT;
      }
    }
  }

  toggleSeeMore(showMore) {
    this.isSeeMore = showMore;
    this.setFilteredApplicants();
    this.updateSharedService();
  }

  setFilteredApplicants() {
    const applicantList = this.isApplicantActive()
      ? cloneDeep(this.borrowers)
      : cloneDeep(this.guarantors);

    const allApplicantList = cloneDeep(applicantList);

    const filteredApplicants = this.isSeeMore
      ? applicantList
      : applicantList.splice(0, SEE_APPLICANT_LIMIT);

    this.activeFamilyList = filteredApplicants;
    this.allClientFamilyList = allApplicantList;
    this.gamePlanSharedDataService.oldMergedNames = this.gamePlanSharedDataService.getApplicantNames();
  }

  onClickTab(activeTab) {
    this.activeTab = activeTab;
    this.isSeeMore = false;
    this.moreCount = null;
    this.setFilteredApplicants();
    this.setApplicantListCount();
    this.updateSharedService();

    if (this.cancelCallback) {
      this.cancelCallback();
    }
  }

  onUpdateFamilySelected(applicant) {
    this.gamePlanSharedDataService.oldMergedNames = this.gamePlanSharedDataService.getApplicantNames();
    const selectedApplicant = this.isApplicantActive()
      ? this.borrowers.find((borrower) => borrower.id === applicant.id)
      : this.guarantors.find((guarantor) => guarantor.id === applicant.id);
    selectedApplicant.isSelected = applicant.isSelected;

    this.allClientFamilyList = this.allClientFamilyList.map((item) => {
      if (item.id === applicant.id) {
        item.isSelected = applicant.isSelected;
      }
      return item;
    });
    this.updateSharedService();
  }

  onSaveContact(applicant) {
    if (!applicant || !applicant.updatedEmail) {
      return;
    }
    updateApplicantEmailModel({ applicant });
    if (applicant.PersonId) {
      return this.saveClient(applicant);
    }

    return this.saveOrganisation(applicant);
  }

  saveClient(applicant) {
    const payload = getClientEmailMobilePayload(applicant);
    if (!payload) {
      return;
    }

    return this.helloBookService
      .setClient(payload)
      .then(() => {
        toastSaveSuccess();
        this.updateApplicantFromOriginList(applicant);
        this.gamePlanGeneratorService.reloadLoanAppApplicantsCallback();
      })
      .catch(toastError);
  }

  saveOrganisation(applicant) {
    return this.contactService
      .organisationInfoGet(applicant.FamilyId)
      .then((response) => {
        const payload = updateApplicantEmailModel({
          applicant: response.data,
          newEmail: applicant.updatedEmail,
        });

        this.contactService
          .organisationInfoSet(setupNewPostURL(payload, 'organisation'))
          // eslint-disable-next-line sonarjs/no-identical-functions
          .then(() => {
            toastSaveSuccess();
            this.updateApplicantFromOriginList(applicant);
            this.gamePlanGeneratorService.reloadLoanAppApplicantsCallback();
          });
      })
      .catch(toastError);
  }

  updateApplicantFromOriginList(applicant) {
    updateApplicantFromList({
      applicant,
      list: this.isApplicantActive() ? this.borrowers : this.guarantors,
    });
  }

  onUpdateEmail(params) {
    this.onUpdateEmailValue(params);

    const { cancelCallback } = params;
    this.cancelCallback = cancelCallback;
  }

  onSendEmail() {
    this.gamePlanSharedDataService.updateEmailName();
    this.isEmailSending = true;
    const {
      brokerMessage,
      emailMessage,
      isEditEmailMessage,
    } = this.gamePlanSharedDataService;

    const emailMessageContent = isEditEmailMessage
      ? emailMessage
      : brokerMessage;

    const payload = this.gamePlanSharedDataService.getPayload({
      isApplicant: this.isApplicantActive(),
      sections: this.sectionList,
      emailMessage: emailMessageContent,
    });

    this.gamePlanGeneratorService
      .send({
        loanAppId: this.loanAppId,
        payload,
        crmConfirmation: this.crmConfirmation,
      })
      .then(() => {
        this.getDefaults();
        this.isEmailSending = false;

        this.updateSharedService();
      })
      .catch(() => {
        this.isEmailSending = false;
      });
  }

  onSendEsign() {
    if (this.gamePlanSharedDataService.isBrokerMessageCountMax) {
      this.gamePlanSharedDataService.displayWarningCount();
      return;
    }
    const props = {
      isSendCpa: true,
      isSendCqp: true,
      loanId: this.loanAppId,
      showEsignApplicantOnly: this.isApplicantActive(),
    };

    this.gamePlanSharedDataService.onSendEsign({ props }).result.then(
      (result) => {
        if (result.contactUpdated) {
          this.setFilteredApplicants();
        }
      },
      () => {},
    );
  }

  setSections() {
    this.gamePlanSharedDataService.setSections(this.sectionList);
  }

  selectSection() {
    if (this.gamePlanSharedDataService.isEditEmailMessage) {
      this.gamePlanSharedDataService.updateEmailName();
    }
    this.gamePlanSharedDataService.isEditEmailMessage = false;
    this.setSections();
    this.gamePlanSharedDataService.isSectionOnSaveDefaultEdited = false;
    this.saveSectionButtonText = this.gamePlanSharedDataService.saveButtonText.isReset;
  }

  onDownloadPreview() {
    if (this.gamePlanSharedDataService.isBrokerMessageCountMax) {
      this.gamePlanSharedDataService.displayWarningCount();
      return;
    }
    this.gamePlanSharedDataService.updateEmailName();

    const mergedNames = this.activeFamilyList.reduce((accum, applicant) => {
      return `${accum}${applicant.name}`;
    }, '');
    this.isDownloading = true;

    const payload = this.gamePlanSharedDataService.getPayload({
      isApplicant: this.isApplicantActive(),
      sections: this.sectionList.map((section) => ({
        ...section,
        name: getSectionName(section.name, this.isApplicantActive()),
      })),
    });

    this.gamePlanGeneratorService
      .download({
        loanAppId: this.loanAppId,
        fileName: mergedNames,
        payload,
      })
      .then(() => {
        this.isDownloading = false;
      })
      .catch(() => {
        this.isDownloading = false;
      });
  }

  getDefaultSections(sections) {
    const editableSections = sections;

    this.sectionList = this.sectionList.map((value) => {
      const section = editableSections.find(
        (data) => data.apiKey === value.apiKey,
      );
      if (section) {
        return Object.assign(
          {},
          { ...value },
          { isIncluded: section.isIncluded },
        );
      }

      return value;
    });
  }

  setDefaultSections(data) {
    return [
      {
        apiKey: this.gamePlanSections[1].apiKey,
        isIncluded: data.BrokerMessage,
      },
      {
        apiKey: this.gamePlanSections[5].apiKey,
        isIncluded: data.Concierge,
      },
      {
        apiKey: this.gamePlanSections[0].apiKey,
        isIncluded: data.CoverPage,
      },
    ];
  }

  initializeServiceSections({ data, defaultSections }) {
    const { Message: message } = data;

    this.gamePlanSharedDataService.setBrokerMessage(message);
    this.getDefaultSections(defaultSections);
    this.setSections();
  }

  setSentEmailText(data) {
    this.sentEmailText = {
      hasSentEmailToApplicant: data.HasSentEmailToApplicant,
      hasSentEmailToGuarantor: data.HasSentEmailToGuarantor,
    };
  }

  getDefaults() {
    const loanAdviserId = this.loanAdviserId;

    this.gamePlanGeneratorService
      .getDefaults({
        loanAdviserId,
        params: { loanApplicationId: this.loanAppId },
      })
      .then(({ data }) => {
        if (data) {
          if (objectLength(this.sentEmailText)) {
            this.setSentEmailText(data);
            return;
          }
          this.setSentEmailText(data);

          const defaultSections = this.setDefaultSections(data);

          this.initializeServiceSections({ data, defaultSections });

          const currentSettings = {
            message: data.Message,
            sections: defaultSections,
          };

          this.gamePlanSharedDataService.setDefaults(currentSettings);
          this.gamePlanSharedDataService.checkMessageIfDefault(currentSettings);
          this.gamePlanSharedDataService.isSectionSettingsOnDefault = this.gamePlanSharedDataService.isSectionSettingsDefault;
          this.gamePlanSharedDataService.updateEmailName();
          return;
        }

        this.gamePlanSharedDataService.setDefaultMessage();
      });
  }

  saveDefaultSections(response) {
    const isSidebar = true;
    this.gamePlanSharedDataService.isSavingSideBar = true;
    this.saveSectionButtonText = 'Saving';
    this.gamePlanSharedDataService
      .saveDefaults(this.loanAdviserId, isSidebar, true)
      .then(() => {
        this.gamePlanSharedDataService.setLoaders(response, isSidebar);
        this.saveSectionButtonText = this.gamePlanSharedDataService.setSaveButtonTextOnReset(
          response,
        );
      })
      // eslint-disable-next-line sonarjs/no-identical-functions
      .catch(() => {
        this.gamePlanSharedDataService.setLoaders(response, isSidebar);
        this.saveSectionButtonText = this.gamePlanSharedDataService.setSaveButtonTextOnReset(
          response,
        );
      });
  }

  resetOnDefault() {
    this.isReverting = true;

    this.showCustomConfirmation(
      'Warning',
      'Are you sure you want to reset to default settings?',
      'Confirm',
      'Cancel',
      'reset-confirmation',
      (confirm) => {
        if (confirm) {
          this.gamePlanSharedDataService.setDefaultDataSections();

          const sections = this.gamePlanSharedDataService.sectionsDefaults;

          this.getDefaultSections(sections);
          this.saveDefaultSections({ isReset: false });
          this.isReverting = false;
        } else {
          this.isReverting = false;
        }
      },
    );
  }
}
