export const VALID_EXTENSIONS = [
  'pdf',
  'docx',
  'doc',
  'odt',
  'xls',
  'xlsx',
  'ods',
  'odp',
  'ppt',
  'pptx',
  'jpg',
  'jpeg',
  'png',
  'rtf',
  'odt',
  'txt',
  'tif',
  'bmp',
  'gif',
  'html',
];

export const HELLOBOOK_VALID_EXTENSIONS = [
  'pdf',
  'docx',
  'doc',
  'odt',
  'xls',
  'xlsx',
  'ods',
  'odp',
  'ppt',
  'pptx',
  'jpg',
  'jpeg',
  'png',
];

export const INVALID_FILE_INFO = {
  title: 'Invalid',
  description: 'Invalid file NOT added',
};

export const DOC_TYPE_ID = {
  MARRIAGE_CERTIFICATE: 29,
  MEDICARE_CARD: 30,
  DRIVERS_LICENCE_AUSTRALIA: 12,
  DRIVERS_LICENCE_INTERNATIONAL: 13,
  AU_CITIZENSHIP_CERTIFICATE: 1,
  BIRTH_CERTIFICATE: 6,
  BIRTH_CERTIFICATE_AU: 7,
  BIRTH_CERTIFICATE_INTL: 8,
};

export const SUPPORTING_DOCS_NZ = {
  SIGNED_DECLARATION_FORM: 21,
};

export const AWS_S3_ROOT = {
  BYOB: 1,
  ADVISER: 2,
  HELLOBOOK: 3,
  INSURANCE_APP: 4,
};

export const DOCUMENT_UPLOAD_URL = 'contacts/DocumentUpload';
export const DOCUMENT_LOGO_KEY = {
  DEFAULT: 'companyLogoDocumentId',
  PNG: 'companyLogoDocumentIdPNG',
};

export const DOCUMENT_BROKEN_ERROR_MSG =
  'Error with this document - please delete and re-upload';
