import { showCustomConfirmation } from 'Common/utilities/alert';
import { getWordCountFromHtml } from 'Common/utilities/string';

class GamePlanSendingMomentMainCtrl {
  constructor(
    gamePlanSharedDataService,
    gamePlanGeneratorService,
    $timeout,
    currentUserService,
  ) {
    'ngInject';

    this.gamePlanSharedDataService = gamePlanSharedDataService;
    this.gamePlanGeneratorService = gamePlanGeneratorService;
    this.timeout = $timeout;
    this.currentUserService = currentUserService;
    this.showCustomConfirmation = showCustomConfirmation;
  }

  $onInit() {
    this.textCounter = 0;
    this.isTextCountMax = false;
    this.userTerm = this.currentUserService.userTerm;
    this.userTerm = this.currentUserService.userTerm;
    this.gamePlanSharedDataService.initialize(this.loanAdviserId).then(() => {
      this.adviserTheme = this.gamePlanSharedDataService.adviserTheme;
      this.setIconThemeColor();
    });

    this.bannerTitle = `This is a preview of the Game Plan you are creating`;
    this.bannerTitleEmail = `This is a preview of the email you are about to send`;

    this.isReverting = false;

    this.processTimeout = this.timeout(() => {
      this.gamePlanSharedDataService.isSavingMainContent = false;
      this.gamePlanSharedDataService.isOnSaveDefaultEdited = true;
      this.gamePlanSharedDataService.isSettingsOnDefault = this.gamePlanSharedDataService.isSettingsDefault;
      this.brokerViewSaveButtonText = this.setBrokerSaveButtonTextDefaultMessage(
        this.gamePlanSharedDataService.isSettingsOnDefault,
      );
      this.updateWordCount();
    }, 2000);
  }

  updateWordCount() {
    this.textCounter = getWordCountFromHtml(
      this.gamePlanSharedDataService.brokerMessage,
    );
    this.gamePlanSharedDataService.brokerMessageCount = this.textCounter;
    this.isTextCountMax =
      this.textCounter >= this.gamePlanSharedDataService.brokerMessageCountMax;
    this.gamePlanSharedDataService.isBrokerMessageCountMax = this.isTextCountMax;
    this.gamePlanSharedDataService.displayWarningCount();
  }

  setBrokerSaveButtonTextDefaultMessage(isSettingsDefault) {
    return isSettingsDefault
      ? this.gamePlanSharedDataService.saveButtonText.isSaved
      : this.gamePlanSharedDataService.saveButtonText.isReset;
  }

  $onDestroy() {
    this.timeout.cancel(this.processTimeout);
  }

  onUpdateEmailMessage({ message }) {
    this.gamePlanSharedDataService.emailMessage = message;
    if (this.gamePlanSharedDataService.isApplicantActive) {
      this.gamePlanSharedDataService.emailMessageApplicant = message;
    }
    if (!this.gamePlanSharedDataService.isApplicantActive) {
      this.gamePlanSharedDataService.emailMessageGuarantor = message;
    }
  }

  onUpdateMessage(response) {
    if (!response || !response.message) {
      return;
    }

    const { message } = response;
    this.gamePlanSharedDataService.isOnSaveDefaultEdited = false;
    this.gamePlanSharedDataService.brokerMessage = message;
    this.brokerViewSaveButtonText = this.gamePlanSharedDataService.saveButtonText.isReset;
    this.updateWordCount();
  }

  setIconThemeColor() {
    // eslint-disable-next-line no-undef
    const iconElem = $(
      '.GamePlanSendingMomentMain__root .game-plan-broker-message svg #email-icon-stroke',
    );

    if (iconElem) {
      iconElem.css('stroke', this.adviserTheme.sidebarBgColor);
    }
  }

  saveBrokerMessageAsDefault(response) {
    if (this.isTextCountMax) {
      this.updateWordCount();
      return;
    }
    const loanAdviserId = this.loanAdviserId;
    const isSidebar = false;

    this.gamePlanSharedDataService.isSavingMainContent = true;
    this.brokerViewSaveButtonText = 'Saving';

    this.gamePlanSharedDataService
      .saveDefaults(loanAdviserId, isSidebar, response.isReset)
      .then(() => {
        this.gamePlanSharedDataService.setLoaders(response, isSidebar);
        this.brokerViewSaveButtonText = this.gamePlanSharedDataService.setSaveButtonTextOnReset(
          response,
        );
      })
      // eslint-disable-next-line sonarjs/no-identical-functions
      .catch(() => {
        this.gamePlanSharedDataService.setLoaders(response, isSidebar);
        this.brokerViewSaveButtonText = this.gamePlanSharedDataService.setSaveButtonTextOnReset(
          response,
        );
      });
  }

  resetOnDefault() {
    this.showCustomConfirmation(
      'Warning',
      'Are you sure you want to reset to default settings?',
      'Confirm',
      'Cancel',
      'reset-confirmation',
      (confirm) => {
        if (confirm) {
          this.gamePlanSharedDataService.setDefaultMessage();
          this.saveBrokerMessageAsDefault({ isReset: false });
          this.isReverting = false;
        } else {
          this.isReverting = false;
        }
      },
    );
  }
}

export default GamePlanSendingMomentMainCtrl;
