import template from './insuranceFileDropdown.html';
import controller from './insuranceFileDropdownCtrl.js';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    onSelect: '&',
    familyId: '<',
    className: '<',
  },
};
