import cloneDeep from 'lodash/cloneDeep';
import {
  ALL_VALUE,
  REFERRALS_SENT_RECEIVED,
  WIDGET_OPTION_VAL,
  REFERRALS_SENT_RECEIVED_PERIOD,
  WIDGET_ID,
} from 'Common/constants/dashboardWidget';
import {
  getValueOption,
  getCurrentCarouselData,
  parseMultiSelectTicks,
  mapOptionAll,
  mapSelectedItem,
  getCustomOptionValues,
  getIndexPeriod,
  getChartColor,
  getAdvisersParams,
  getFormattedAdvisers,
} from 'Common/utilities/dashboardWidget';
import { STACKED_CHART_CONFIG, COMMON_CHART_COLORS } from 'Common/config/chart';
import { parseToInt10 } from 'Common/utilities/parse';

export default class ReferralsTileCtrl {
  constructor(
    $q,
    commonWidgetService,
    currentUserService,
    overviewDashboardService,
    dashboardService,
    mycrmLookupService,
  ) {
    'ngInject';

    this.$q = $q;
    this.commonWidgetService = commonWidgetService;
    this.currentUserService = currentUserService;
    this.overviewDashboardService = overviewDashboardService;
    this.dashboardService = dashboardService;
    this.mycrmLookupService = mycrmLookupService;
  }

  $onInit() {
    const widgetId = parseToInt10(this.myParam.WidgetId);
    this.isReferralsReceived = WIDGET_ID.REFERRALS_RECEIVED === widgetId;
    this.widgetTitle = REFERRALS_SENT_RECEIVED.TITLE[widgetId];
    this.widgetSubTitle = REFERRALS_SENT_RECEIVED.SUBTITLE[widgetId];
    this.showWidgetSettings = true;
    this.timePeriodSelected = {};
    this.timePeriodList = [];
    this.advisersDropdown = [];

    this.chartOptions = cloneDeep(STACKED_CHART_CONFIG);

    this.getWidgetOptionDropdown();
  }

  goToReport() {
    const params = {
      widgetId: this.myParam.WidgetId,
      widgetUserId: this.myParam.Widget_UserID,
    };
    this.commonWidgetService.goToReport(params);
  }

  setView(isSettings) {
    this.isSettings = isSettings;
  }

  getWidgetOptionDropdown() {
    const { isAssistant, isAdminAssistant } = this.currentUserService;
    const isLoanWriterOnly = isAssistant || isAdminAssistant;

    const promises = [
      this.commonWidgetService.getWidgetOptions(
        REFERRALS_SENT_RECEIVED.TIME_PERIOD,
      ),
      this.mycrmLookupService.getAdvisers(getAdvisersParams(isLoanWriterOnly)),
    ];

    this.$q.all(promises).then((responses) => {
      if (!responses || !responses.length) {
        return;
      }
      const [timePeriodList, advisersDropdown] = responses;

      this.timePeriodList = timePeriodList;
      this.advisersDropdown = getFormattedAdvisers(advisersDropdown);

      this.getWidgetUserOptions(true);
    });
  }

  getWidgetUserOptions(isLoad) {
    this.commonWidgetService
      .getWidgetUserOptions(this.myParam.Widget_UserID)
      .then(({ data }) => {
        if (!data || !data.length) {
          return;
        }

        const timePeriod = getValueOption(
          REFERRALS_SENT_RECEIVED.TIME_PERIOD,
          data,
        );
        this.timePeriodSelected = timePeriod;
        const advisers = getValueOption(REFERRALS_SENT_RECEIVED.ADVISERS, data);

        this.advisersDropdown = parseMultiSelectTicks(
          advisers,
          this.advisersDropdown,
        );
        isLoad && this.getReferralsData();
      });
  }

  setTimePeriod(isNext, currentPeriod, list) {
    const indexPeriod = getIndexPeriod(list, currentPeriod);
    this.timePeriodSelected = getCurrentCarouselData(isNext, indexPeriod, list);

    this.setWidgetOptions({
      widgetOptionId: REFERRALS_SENT_RECEIVED.TIME_PERIOD,
      value:
        this.timePeriodSelected.Widget_OptionValue ||
        this.timePeriodSelected.valueId,
    });
  }

  setWidgetOptions(params) {
    const { widgetOptionId, value, selectedUser, needUpdate } = params;
    const payload = {
      WidgetOptionId: widgetOptionId,
      Widget_UserID: this.myParam.Widget_UserID,
      Widget_OptionValue: value || 0,
      SelectedUser: selectedUser || 0,
    };
    this.overviewDashboardService.WidgetOptionSet(payload).then(() => {
      if (needUpdate) {
        this.getWidgetUserOptions(true);
        return;
      }
      this.getReferralsData();
    });
  }

  getReferralsData() {
    const { Widget_OptionValue: widgetVal, valueId } = this.timePeriodSelected;
    const nonStackedData = [
      REFERRALS_SENT_RECEIVED_PERIOD.THIS_MONTH,
      REFERRALS_SENT_RECEIVED_PERIOD.LAST_MONTH,
    ];
    const isStackedData = !(
      nonStackedData.includes(parseInt(widgetVal, 10)) ||
      nonStackedData.includes(parseInt(valueId, 10))
    );
    const { scales } = this.chartOptions;

    scales.xAxes[0].stacked = isStackedData;
    scales.yAxes[0].stacked = isStackedData;

    const endpointToUse = this.isReferralsReceived
      ? 'getReferralsReceived'
      : 'getReferralsSent';

    this.dashboardService[endpointToUse]({
      userWidgetId: this.myParam.Widget_UserID,
    }).then((data) => {
      this.chartData = [];
      this.chartSeries = [];
      this.chartLegend = [];
      this.chartLabels = [];
      if (!data.length) {
        return;
      }
      this.datasetOverride = [];

      data.forEach((item, index) => {
        this.chartSeries = [...this.chartSeries, item.category];
        const color = getChartColor({ index, colors: COMMON_CHART_COLORS });
        const chartDataTmp = item.data.map((obj) => obj.count);

        this.chartLegend = [
          ...this.chartLegend,
          { label: item.category, color },
        ];
        this.chartLabels = item.data.map((obj) => obj.label);
        this.chartData = [...this.chartData, chartDataTmp];
        this.datasetOverride = [
          ...this.datasetOverride,
          {
            backgroundColor: color,
            borderColor: color,
          },
        ];
      });
      this.chartLegend = this.chartLegend.slice(0, 3);
    });
  }

  setCustomWidgetOption(variableName, isAll) {
    const optionValues = getCustomOptionValues(this[variableName]);
    const { ADVISER } = WIDGET_OPTION_VAL;
    const widgetOptionId = REFERRALS_SENT_RECEIVED.ADVISERS;
    const value = isAll ? ADVISER.ALL : ADVISER.CUSTOM;
    const needUpdate = false;

    this.setWidgetOptions({
      widgetOptionId,
      value,
      selectedUser: optionValues,
      needUpdate,
    });
  }

  selectAll(varName, inputData) {
    this[varName] = mapOptionAll(inputData);
    this.setCustomWidgetOption(varName, true);
  }

  onItemSelect(itemClicked, variableName, inputData, selection) {
    if (itemClicked.value === ALL_VALUE || !selection.length) {
      this.selectAll(variableName, inputData);
      return;
    }

    this[variableName] = mapSelectedItem(inputData);
    this.setCustomWidgetOption(variableName);
  }
}
