import { getSpecificBrokerNotes } from 'Common/utilities/loanApplication';
import { BROKER_NOTES_ID } from 'Common/constants/loanAppBrokerNotes';
import {
  INSURANCE_LOAN_APP,
  INSURANCE_LOAN_APP_NZ,
} from 'Common/constants/insuranceOptions';

export const assignInsuranceBrokerNotes = (scope, brokerNotesResponse) => {
  if (!scope || !brokerNotesResponse) {
    return;
  }
  scope.assess = {
    InsuranceOverview: {},
  };
  scope.assess.InsuranceOverview.brokerNotes = getSpecificBrokerNotes(
    brokerNotesResponse,
    BROKER_NOTES_ID.REFERRAL_PARTNERS,
  );
};

export const getInsuranceTitleByCountryName = (
  isAU,
  isLoanAppOnOpportunity = false,
) => {
  return (
    (isAU &&
      (isLoanAppOnOpportunity
        ? 'Future Goals and Referrals'
        : 'Referral Partners')) ||
    'Insurance'
  );
};

export const validateYesNoWithToggleAndText = ({
  getValue,
  isValueDefined,
}) => ({
  defaultTrueCondition,
  yesSubQuestionOptions,
  noSubQuestionOptions,
  mainQuestionId,
  textQuestionId,
}) => {
  if (defaultTrueCondition) {
    return true;
  }
  const isValidAnswerYes =
    isValueDefined(mainQuestionId) &&
    getValue(mainQuestionId) === true &&
    yesSubQuestionOptions.some((option) => isValueDefined(option));

  const isValidAnswerNo =
    isValueDefined(mainQuestionId) &&
    getValue(mainQuestionId) === false &&
    noSubQuestionOptions.some((option) => isValueDefined(option));

  return !!(
    isValueDefined(mainQuestionId) &&
    isValueDefined(textQuestionId) &&
    (isValidAnswerYes || isValidAnswerNo)
  );
};

export const validateRiskInsurance = ({
  currentUserService,
  isValueDefined,
  getValue,
}) => () => {
  return validateYesNoWithToggleAndText({ getValue, isValueDefined })({
    defaultTrueCondition: !currentUserService.isNZ,
    mainQuestionId: INSURANCE_LOAN_APP.QUESTIONS.RISK_INSURANCE,
    textQuestionId:
      INSURANCE_LOAN_APP_NZ.SUBQUESTIONS.RISK_INSURANCE.MORE_DETAILS,
    yesSubQuestionOptions: [
      INSURANCE_LOAN_APP_NZ.SUBQUESTIONS.RISK_INSURANCE.OBLIGATION_FREE,
      INSURANCE_LOAN_APP_NZ.SUBQUESTIONS.RISK_INSURANCE.PASS_ON_DETAILS,
    ],
    noSubQuestionOptions: [
      INSURANCE_LOAN_APP_NZ.SUBQUESTIONS.RISK_INSURANCE.DECLINE_OPTION,
    ],
  });
};

export const validateDiscussLifeStyle = ({
  currentUserService,
  isValueDefined,
  getValue,
}) => () => {
  return validateYesNoWithToggleAndText({ getValue, isValueDefined })({
    defaultTrueCondition: !currentUserService.isAU,
    mainQuestionId: INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE,
    textQuestionId:
      INSURANCE_LOAN_APP.SUBQUESTIONS.DISCUSS_LIFESTYLE.MORE_DETAILS,
    yesSubQuestionOptions: [
      INSURANCE_LOAN_APP.SUBQUESTIONS.DISCUSS_LIFESTYLE.OBLIGATION_FREE,
      INSURANCE_LOAN_APP.SUBQUESTIONS.DISCUSS_LIFESTYLE.LOAN_PROTECTION_PLAN,
      INSURANCE_LOAN_APP.SUBQUESTIONS.DISCUSS_LIFESTYLE.PROTECTION_OPTIONS,
    ],
    noSubQuestionOptions: [
      INSURANCE_LOAN_APP.SUBQUESTIONS.DISCUSS_LIFESTYLE.DECLINE_PROTECTION,
    ],
  });
};
