export const SECTION_STRING = {
  INVOLVED_PARTY_PERSON: 'PersonBorrowers',
  INVOLVED_PARTY_ENTITY: 'InvolvedPartyEntity',
  RELATED_PARTY: 'RelatedParty',
};

export const PRIMARY_PURPOSE = {
  QUESTION_ID: 5,
  DISPLAY_OPTION_ID: 6,
};

export const TAB_TEXT_ID = {
  SUMMARY: 'SUMMARY',
  FACT_FIND: 'FACT_FIND',
  CALCULATIONS: 'CALCULATIONS',
  COMMUNICATIONS: 'COMMUNICATIONS',
  NOTES: 'NOTES',
  DOCUMENTS: 'DOCUMENTS',
  TASKS: 'TASKS',
};

export const TAB_ORDER = {
  SUMMARY: 1,
  FACT_FIND: 2,
  CALCULATIONS: 3,
  COMMUNICATIONS: 4,
  NOTES: 5,
  TASKS: 6,
  DOCUMENTS: 7,
};

export const FILE_TAB_ORDER = {
  SUMMARY: 1,
  CALCULATIONS: 2,
  NOTES: 3,
  TASKS: 4,
  COMMUNICATIONS: 5,
  DOCUMENTS: 6,
  FACT_FIND: 7,
};
