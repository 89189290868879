import swal from 'sweetalert';
import { get } from 'lodash';
import {
  toastError,
  displayError,
  toastSaveSuccess,
  toastSaveDelete,
} from 'Common/utilities/alert';
import { openNotesModal, getEmailObject } from 'Common/utilities/notes';
import { stripHtmlSpaces } from 'Common/utilities/string';
import {
  NOTES_TABLE_SIZE,
  DEFAULT_NOTES_SIZE,
} from 'Common/constants/pageTable';
import { notesBuilderForContent } from 'Common/mappers/loanOpportunity';

export default class OpportunityNotesCtrl {
  constructor(
    $uibModal,
    $timeout,
    $q,
    $filter,
    $stateParams,
    $window,
    loanOpportunityService,
    referralModelService,
    contactService,
    toaster,
    configService,
    opportunityLoanAppService,
    uiService,
    contactsNotesService,
    corporateService,
  ) {
    'ngInject';

    this.uibModal = $uibModal;
    this.$timeout = $timeout;
    this.$q = $q;
    this.$filter = $filter;
    this.$stateParams = $stateParams;
    this.$window = $window;
    this.loanOpportunityService = loanOpportunityService;
    this.referralModelService = referralModelService;
    this.contactService = contactService;
    this.toaster = toaster;
    this.configService = configService;
    this.opportunityLoanAppService = opportunityLoanAppService;
    this.uiService = uiService;
    this.contactsNotesService = contactsNotesService;
    this.corporateService = corporateService;
    this.onPageChange = this.onPageChange.bind(this);
  }

  $onInit() {
    this.opportunityId = this.$stateParams.opportunityId || 0;
    this.isViewOfAFileTurnedOn = this.uiService.viewOfAfileTurnedOn;
    this.initializeNotes();
    this.disableOpenModal = false;
    this.emailObj = {};
    getEmailObject({ vm: this });

    const reloadNotes = this.getNotes.bind(this);
    this.opportunityLoanAppService.setCallback(
      'refreshOpportunityNotes',
      reloadNotes,
    );
  }

  $onChanges({ adviserId }) {
    if (adviserId.currentValue !== adviserId.previousValue) {
      this.corporateService
        .corporateContactGet(0, this.adviserId)
        .then(({ data }) => {
          if (!data) {
            return;
          }
          const fullName = `${data.PreferredName || data.FirstName} ${
            data.LastName
          }`;
          this.adviserInfo = { ...data, fullName };
        });
    }
  }

  initializeNotes() {
    this.selectedNoteType = 0;
    return this.contactsNotesService.getDisplayCount().then(({ data }) => {
      this.defaultNotesCount = data || DEFAULT_NOTES_SIZE;
      this.tableSizes = NOTES_TABLE_SIZE;
      this.currentPage = 1;
      this.getNotes();
    });
  }

  onPageChange({ pageNumber, pageSize }) {
    this.currentPage = pageNumber || 1;
    this.defaultNotesCount = pageSize || this.defaultNotesCount;
    this.getNotes();
    if (!pageSize) {
      return;
    }
    this.contactsNotesService.setDisplayCount({ displayCount: pageSize });
  }

  onSearchNotes({ query }) {
    if (!query) {
      this.notesList = [...this.notesListRaw];
      this.noteListCount = this.noteListCountRaw;
      return;
    }
    this.notesList = this.$filter('filter')(this.notesListRaw, query);
    this.noteListCount = this.notesList.length;
  }

  reloadNotes() {
    const isReloadedNotes =
      this.isViewOfAFileTurnedOn && this.noteListCount > this.defaultNotesCount;
    isReloadedNotes && this.getNotes();
    return isReloadedNotes;
  }

  openNoteFromTable({ note }) {
    this.openAddEditNotesModal(note);
  }

  deleteNoteFromTable({ note, $event }) {
    $event.stopPropagation();
    this.deleteNote(note);
  }

  onNoteTypeChange({ noteType = {} }) {
    if (!noteType.id) {
      this.notesList = [...(this.notesListRaw || [])];
      this.noteListCount = this.noteListCountRaw || 0;
      return;
    }
    this.notesList =
      this.notesListRaw.filter((note) => note.noteTypeId === noteType.id) || [];
    this.noteListCount = this.notesList.length;
  }

  getNotes() {
    const params = {
      count: this.isViewOfAFileTurnedOn ? this.defaultNotesCount : 0,
      pageNumber: this.currentPage,
    };
    this.loanOpportunityService
      .getLoanOpportunityNotes(this.loanId, params)
      .then((notesList) => {
        if (!notesList) {
          return;
        }
        const formattedList = notesList.map((note) => ({
          ...note,
          htmlStrippedActivityDetails: stripHtmlSpaces(
            this.$window,
            note.activityDetails,
            ' ',
          ),
        }));
        this.notesListRaw = this.isViewOfAFileTurnedOn
          ? formattedList.map(notesBuilderForContent)
          : formattedList;
        this.notesList = [...this.notesListRaw];
        this.noteListCountRaw = get(this, 'notesList.0.noteCount', 0);
        this.noteListCount = this.noteListCountRaw;
      });
  }

  openAddEditNotesModal(note) {
    openNotesModal({ vm: this, note });
  }

  callOpenModal(props) {
    const modalSize = this.configService.feature.noteType ? 'wide' : '';
    this.uibModal
      .open({
        template: `<opportunity-notes-modal
                  modal-instance="vm.modalInstance"
                  note-obj="vm.props.noteObj"
                  is-add="vm.props.isAdd"
                  referral-id="vm.props.referralId"
                  is-sms-enabled="vm.props.isSmsEnabled">
                </opportunity-notes-modal>`,
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        windowClass: `opportunity-notes-modal ${modalSize}`,
        size: modalSize,
        keyboard: false,
        resolve: {
          props: () => props,
        },
      })
      .result.then(
        () => {
          this.disableOpenModal = false;
          toastSaveSuccess(this.toaster);
          this.getNotes();
        },
        () => {
          this.disableOpenModal = false;
          this.getNotes();
        },
      );
  }

  deleteNote(note) {
    swal(
      {
        title: 'Delete Note Confirmation',
        text: `Are you sure want to delete this note?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, Delete Note',
        closeOnConfirm: true,
      },
      (response) => {
        if (!response) {
          return;
        }
        const { communicationId } = note;
        this.loanOpportunityService
          .deleteLoanOpportunityNote(communicationId)
          .then((res) => {
            const { data: result } = res;
            if (result) {
              toastSaveDelete(this.toaster);
              if (this.reloadNotes()) {
                return;
              }
              const idx = this.notesList.indexOf(note);
              this.notesList.splice(idx, 1);
            } else {
              toastError();
            }
          })
          .catch((error) => {
            displayError(error);
          });
      },
    );
  }
}
