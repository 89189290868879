export const BROKER_NOTES_ID = {
  INVOLVED_PARTIES: 1,
  RELATED_PARTIES: 2,
  EMPLOYMENT: 3,
  ADDRESS_HISTORY: 4,
  IDENTIFICATION: 5,
  DEPOSIT: 6,
  BORROWERS: 19,
  GUARANTORS: 20,
  DEPENDANTS: 21,
  TRADE_PERSONAL_REFERENCES: 22,
  BROKER_DECLARATION: 23,
  REFERRAL_PARTNERS: 24,
};

export const BROKER_NOTES_MAX_LENGTH = 16008;
