import ProfilerToContactCtrl from 'Components/saveProfilerToContact/profilerToContactCtrl';

export default class InsuranceModalApplicationService {
  constructor($uibModal) {
    'ngInject';

    this.createInsuranceAppModal = $uibModal;
  }

  onCreate(params) {
    return this.createInsuranceAppModal.open(this.modalOption(params));
  }

  modalTemplate() {
    this.componentTemplate = `<insurance-create-application
                                modal-instance="vm.modalInstance"
                                modal-data="vm.props.params">
                              </insurance-create-application>`;
    return this.componentTemplate;
  }

  modalPropertyData(param = {}) {
    const params = {
      title: 'Create New Insurance Application',
      buttonLabel: 'Next Step',
      searchPlaceHolder: 'Search Client Name',
      ...param,
    };
    this.props = { params };
    return this.props;
  }

  modalOption(params) {
    this.modalOptions = {
      animation: true,
      template: this.modalTemplate(),
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      resolve: {
        props: () => this.modalPropertyData(params),
      },
    };
    return this.modalOptions;
  }

  openWorkbenchModal(quoteId, familyId) {
    return this.createInsuranceAppModal.open({
      animation: true,
      template: `
        <insurance-app-workbench-modal
          modal-instance="vm.modalInstance"
          quote-id="vm.props.quoteId"
          family-id="vm.props.familyId">
        </insurance-app-workbench-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'insurance-app-workbench',
      size: 'lg',
      resolve: {
        props: () => {
          return {
            quoteId,
            familyId,
          };
        },
      },
    });
  }

  openAddBenefitModal(data = {}) {
    return this.createInsuranceAppModal.open({
      templateUrl:
        '/assets/views/contacts/partials/insurance-modal/insurance_existing.html',
      controller: 'InsuranceExistingModalCtrl',
      windowClass: 'insurance-existing-modal is-larger',
      backdrop: false,
      keyboard: false,
      size: 'lg',
      resolve: {
        items: () => {
          return { data };
        },
      },
    });
  }

  addNewClientProfiler(profilerDataInfo) {
    return this.createInsuranceAppModal.open({
      templateUrl:
        '/src/components/saveProfilerToContact/profilerToContact.html',
      controller: ProfilerToContactCtrl,
      controllerAs: 'vm',
      size: 'md',
      backdrop: 'static',
      windowClass: 'ipSaveTocontact-modal-v2',
      resolve: {
        profilerData: profilerDataInfo,
        type: () => 'save',
        items: () => {
          return { profilerDataInfo };
        },
      },
    });
  }

  openInsuranceFile({ familyId, file }) {
    return this.createInsuranceAppModal.open({
      animation: true,
      template: `
        <create-new-insurance-file-modal
          modal-instance="vm.modalInstance"
          family-id="vm.props.familyId"
          file="vm.props.file"
        ></create-new-insurance-file-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      keyboard: false,
      windowClass: `mycrm-modal new-file-modal`,
      size: 'md',
      resolve: {
        props: () => {
          return {
            familyId,
            file,
          };
        },
      },
    });
  }
}
