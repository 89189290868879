export function parseIntValue(list) {
  if (!list) {
    return [];
  }
  return list.map((option) => {
    option.Value = parseInt(option.Value, 10);
    return option;
  });
}

export function parseToInt10(value) {
  if (!value) {
    return 0;
  }
  return parseInt(value, 10);
}
