import { LOAN_STATUS_CATEGORY } from 'Common/constants/loanStatusCategory';

class LoansSummaryCtrl {
  constructor(uiService) {
    'ngInject';

    this.uiService = uiService;
  }

  isEmptySummary() {
    return !this.loansList || !this.loansList.length;
  }

  isExisting() {
    return this.loansSummaryType === LOAN_STATUS_CATEGORY.EXISTING;
  }

  hasLender(loanId) {
    const loan =
      this.loansList && this.loansList.find((item) => item.LoanID === loanId);
    return loan && loan.Lender && parseInt(loan.Lender.LenderId, 10);
  }

  hasMoreItems() {
    return (
      !this.isEmptySummary() && this.loansList.length > this.initialMaxItemCount
    );
  }

  getExcessItemsCount() {
    return !this.isEmptySummary()
      ? this.loansList.length - this.initialMaxItemCount
      : 0;
  }

  getInterestRateType(split) {
    if (!split) {
      return '';
    }
    return this.isVariable(split) ? 'Variable' : split.InterestRateType;
  }

  getRateTerm(split) {
    if (!split) {
      return '';
    }
    if (this.isVariable(split) || this.isFloating(split)) {
      return '';
    }
    if (!split.InterestRateTerm) {
      return '';
    }

    const isAYearOrMore =
      split.InterestRateTerm > 11 && split.InterestRateTerm !== 18;
    if (isAYearOrMore) {
      const loanTermYears = Math.round(split.InterestRateTerm / 12);
      const yearText = loanTermYears > 1 ? 'years' : 'year';
      return `${loanTermYears} ${yearText}`;
    }
    return `${split.InterestRateTerm} months`;
  }

  isVariable(split) {
    if (!split) {
      return;
    }
    return (
      split.InterestRateType === 'Floating' &&
      this.uiService.isCountry('Australia')
    );
  }

  isFloating(split) {
    if (!split) {
      return;
    }
    return (
      split.InterestRateType === 'Floating' &&
      this.uiService.isCountry('New Zealand')
    );
  }

  viewMoreItems() {
    if (this.isEmptySummary()) {
      return;
    }
    this.initialMaxItemCount = this.loansList.length;
  }
}

export default LoansSummaryCtrl;
