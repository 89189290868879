export const INSURANCE_OPTIONS = {
  HEALTH: {
    SPECIALIST: 1,
    GPOPTION: 2,
    DENTALOPTICAL: 3,
  },
  OCCUPATION_TYPE: {
    FULLTIME: 1,
    PARTIME: 2,
    NON_EARNER: 3,
  },
  DEVICE_WIDTH: {
    MOBILE: 991,
  },
  CLIENT_TYPE: {
    NEW: 1,
    EXISTING: 2,
  },
  BENEFIT_TYPE: {
    HEALTH_COVER: 1,
    LIFE_COVER: 2,
    FAMILY_PROTECTION: 3,
    FAMILY_PROTECTION2: 20,
    TRAUMA_COVER: 4,
    TPD: 5,
    INCOME_PROTECTION: 6,
    MORTAGE_REPAYMENT: 7,
    REDUNDANCY: 8,
    WAIVER_OF_PREMIUM: 9,
    INCOME_PROTECTION_NON_TAXABLE: 10,
  },
};

export const OLD_BENEFIT_TYPE = {
  LIFE_COVER: 1,
  TRAUMA_COVER: 2,
  HEALTH_COVER: 3,
  MORTGAGE_REPAYMENT: 7,
  MORTGAGE_LIFE_COVER: 8,
  TPD: 9,
  INCOME_PROTECTION: 10,
  REDUNDANCY_COVER: 11,
  MORTGAGE_INTERRUPTION: 12,
  FAMILY_PROTECTION: 20,
  SOUTHERN_CROSS: 10,
  WAIVE_PREMIUMS: 51,
};

export const ZERO_PREMIUM = 0;

export const INSURANCE_LOAN_APP = {
  QUESTIONS: {
    DISCUSS_LIFESTYLE: 102,
    DONT_DISCUSS_LIFESTYLE: 103,
    WEALTH_MARKET_FINANCIAL_ADVISER: 104,
    ALI_GROUP: 105,
    EXISTING_ADVISER: 106,
    MORE_DETAILS: 107,
    CONCIERGE: 25,
    COMMENTS: 99,
    GENERAL_INSURANCE: 24,
    HOMENOW: 182,
    HOMENOW_TEXT: 317,
    RISK_INSURANCE: 72,
  },
  SUBQUESTIONS: {
    DISCUSS_LIFESTYLE: {
      DECLINE_PROTECTION: 103,
      OBLIGATION_FREE: 104,
      LOAN_PROTECTION_PLAN: 105,
      PROTECTION_OPTIONS: 106,
      MORE_DETAILS: 107,
    },
  },
  FIELD: {
    RADIOBOX_ON_YES: 12,
    RADIOBOX_ON_NO: 13,
  },
  DISPLAY_ID: {
    CHECKBOX: 8,
    TEXTBOX_ON_YES: 3,
  },
};

export const INSURANCE_LOAN_APP_NZ = {
  QUESTIONS: {
    GENERAL_INSURANCE: 73,
  },
  SUBQUESTIONS: {
    RISK_INSURANCE: {
      OBLIGATION_FREE: 126,
      PASS_ON_DETAILS: 127,
      DECLINE_OPTION: 129,
      MORE_DETAILS: 130,
    },
  },
};

export const INSURANCE_DATA = {
  QUOTE: 1,
  PROFILER: 2,
};

export const GENDER = {
  MALE: 1,
  FEMALE: 2,
};

export const WAIT_PERIOD = {
  TWO_WEEKS: 2,
  FOUR_WEEKS: 4,
  EIGHT_WEEKS: 8,
  TWENTY_SIX_WEEKS: 26,
  FIFTY_TWO_WEEKS: 52,
  ONEHUNDRED_FOUR_WEEKS: 104,
};

export const CALC_PERIOD = {
  NONE: 0,
  YEARLY: 1,
  LEVEL_10: 10,
  LEVEL_65: 65,
  LEVEL_70: 70,
  LEVEL_80: 80,
  LEVEL_85: 85,
  LEVEL_90: 90,
  LEVEL_100: 100,
};

export const BENEFIT_PERIOD = {
  Years_2: 2,
  Years_5: 5,
  TO_AGE_65: 65,
  TO_AGE_70: 70,
};

export const EMPLOYMENT_STATUS = {
  EMPLOYED: 1,
};

export const CLIENT_AGE = {
  TYPE_CHILD: 'child',
  CHILD: 22,
  ADULT: {
    FROM: 18,
    TO: 101,
  },
};

export const INSURANCE_QUOTE_REPORT_SECTION = {
  PREMIUM_COMPARISON_GRAPH: 1,
  INSURER_RECOMMENDATION: 2,
  WOP_BENEFITS: 3,
  HEALTH_INSURANCE_EXCESS: 4,
  ONLINE_APP_LINK: 5,
};

export const INSURANCE_POLICY_FEE_GRAPH_COLOR = '#5B1F23';

export const PRODUCTS = {
  ASTERON: 4,
  PARTNERS: 6,
};

export const INSURANCE_TYPES = {
  PROFILER: 1,
  QUICK_QUOTE: 2,
  FIDELITY_LIFE: 3,
  FIDELITY_HEALTH: 5,
  FIDELITY_NIB: 99,
  TRAUMA_COVERS: [PRODUCTS.ASTERON, PRODUCTS.PARTNERS],
};

export const INCOME_PROTECTION_BENEFITS = [
  INSURANCE_OPTIONS.BENEFIT_TYPE.INCOME_PROTECTION,
  INSURANCE_OPTIONS.BENEFIT_TYPE.INCOME_PROTECTION_NON_TAXABLE,
];

export const INSURANCE_PIPELINE_STATUS = {
  IN_FORCE: 6,
  NOT_PROCEEDED: 7,
};

export const COVER_TYPES = [
  {
    key: 'Accelerated',
    value: 'Accelerated',
  },
  {
    key: 'Standalone',
    value: 'Standalone',
  },
];

export const BENEFIT_INDICES = {
  HEALTH_COVER: 0,
  LIFE_COVER_1: 1,
  LIFE_COVER_2: 2,
  FAMILY_PROTECTION: 3,
  TRAUMA_COVER_1: 4,
  TRAUMA_COVER_2: 5,
  TPD_1: 6,
  TPD_2: 7,
  INCOME_PROTECTION: 8,
  MORTGAGE_REPAYMENT: 9,
  REDUNDANCY: 10,
  WAIVER_OF_PREMIUM: 11,
};

export const IS_LOADING_EXCLUSION = 1;
export const CLIENTS_OCCURENCE = {
  PRIMARY: true,
  SECONDARY: false,
};

export const BENEFIT_ICONS = {
  'Health Cover':
    '/assets/images/insuranceTools/icons-insurance/small/health-cover-icon-small.svg',
  'Life Cover':
    '/assets/images/insuranceTools/icons-insurance/small/life-cover-icon-small.svg',
  'Family Protection':
    '/assets/images/insuranceTools/icons-insurance/small/family-protection-icon-small.svg',
  'Trauma Cover':
    '/assets/images/insuranceTools/icons-insurance/small/trauma-icon-small.svg',
  'Total and Permanent Disability':
    '/assets/images/insuranceTools/icons-insurance/small/total-permanent-large-small.svg',
  'Income Protection':
    '/assets/images/insuranceTools/icons-insurance/small/income-protection-icon-small.svg',
  'Mortgage Repayment Cover':
    '/assets/images/insuranceTools/icons-insurance/small/mortgage-protection-icon-small.svg',
  'Redundancy Cover':
    '/assets/images/insuranceTools/icons-insurance/small/redunduncy-cover-icon-small.svg',
  'Waiver of Premium':
    '/assets/images/insuranceTools/icons-insurance/small/premium-waiver-icon-small.svg',
};

export const DEFAULT_BROCHURE = {
  fileName: 'Insurance Report',
};

export const POLICY_FILE_TYPES = {
  BROCHURE: 'Policy Brochure',
  DOCUMENT: 'Policy Document',
};
