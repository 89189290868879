import angular from 'angular';
import FastClick from 'fastclick';
import { getConfigData, getConfigFromEnv } from 'Common/utilities/config';
import { setConfigServiceInstance } from 'Common/utilities/rollout';
import { setToken } from 'Common/utilities/token';
import { getAccessToken, setListeners } from 'Common/utilities/oktaAuth';

const scriptMemo = {};
const addScriptOnDOM = (src, doc) => {
  if (src in scriptMemo) {
    return scriptMemo[src];
  }

  const s = doc.createElement('script');
  s.type = 'text/javascript';
  s.async = true;
  s.src = src;
  const x = doc.querySelectorAll('script')[0];
  x.parentNode.insertBefore(s, x);

  scriptMemo[src] = s;
  return s;
};

const addUserSnap = (apiKey, doc, $window) => {
  $window.onUsersnapCXLoad = function (api) {
    api.init({
      button: {
        isHidden: true,
      },
    });
    $window.UsersnapCX = api;
  };
  const userSnapSrc = `//api.usersnap.com/load/${apiKey}.js?onload=onUsersnapCXLoad`;
  addScriptOnDOM(userSnapSrc, doc);
};

const addFreshChat = ({
  currentUserService,
  doc,
  $window,
  $interval,
  freshChatToken,
}) => {
  const freshChatSrc = 'https://wchat.au.freshchat.com/js/widget.js';
  addScriptOnDOM(freshChatSrc, doc);

  const fcWidgetInterval = $interval(() => {
    if (!$window.fcWidget) {
      return;
    }
    $window.fcWidget.init({
      config: {
        headerProperty: {
          hideChatButton: true,
        },
      },
      token: freshChatToken,
      host: 'https://wchat.au.freshchat.com',
      siteId: '',
    });
    $window.fcWidget.setExternalId(currentUserService.familyId);
    $window.fcWidget.user.setFirstName(currentUserService.firstName);
    $window.fcWidget.user.setEmail(currentUserService.email);
    $interval.cancel(fcWidgetInterval);
  }, 1000);
};
const addUserBack = (currentUserService, doc, $window) => {
  const userBackSrc = 'https://static.userback.io/widget/v1.js';
  const digits = currentUserService.isAU ? '21827' : '21828';
  $window.Userback = {
    access_token: `10699|${digits}|SmsafXyzKvSu0Un748OhWB82C`,
    email: currentUserService.email,
    name: currentUserService.fullName,
  };
  addScriptOnDOM(userBackSrc, doc);
};

const googleMapScript = (googleApiKey, doc) => {
  const baseGoogleMapSrc = `//maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places&callback=initMap`;
  // eslint-disable-next-line angular/window-service
  window.initMap = () => true;
  addScriptOnDOM(baseGoogleMapSrc, doc);
};

const olarkScript = (
  currentUserService,
  doc,
  $window,
  $timeout,
  legacyAngularHost,
) => {
  if (!currentUserService.isAU) {
    return;
  }
  const olarkUrl = `${legacyAngularHost}/assets/js/olark.js`;
  addScriptOnDOM(olarkUrl, doc);

  const configSetting = $timeout(() => {
    $window.olark('api.visitor.updateEmailAddress', {
      emailAddress: `${currentUserService.email}`,
    });
    $window.olark('api.visitor.updateFullName', {
      fullName: currentUserService.fullName,
    });
    $window.olark('api.box.hide');
    $window.olark.configure('box.inline', true);
    $window.olark('api.box.onShrink', () => {
      $window.olark('api.box.hide');
    });
    $window.olark.identify('2114-893-10-3425w');
    $timeout.cancel(configSetting);
  }, 1000);
};

const addCKEditor = (doc) => {
  const ckEditorSrc = `https://cdn.ckeditor.com/4.5.6/standard/ckeditor.js`;
  addScriptOnDOM(ckEditorSrc, doc);
};

const toAddFreshChat = ({
  configService,
  currentUserService,
  $document,
  $window,
  $interval,
  freshChatToken,
}) => {
  configService.feature.freshChatAU &&
    addFreshChat({
      currentUserService,
      doc: $document[0],
      $window,
      $interval,
      freshChatToken,
    });
};
const toAddUserBack = (
  configService,
  currentUserService,
  $document,
  $window,
) => {
  configService.feature.userbackHelpMenu &&
    addUserBack(currentUserService, $document[0], $window);
};

let setupComplete = false;

function main(
  $http,
  $rootScope,
  $state,
  $stateParams,
  $timeout,
  $window,
  $document,
  configService,
  currentUserService,
  legacyAngularHost,
  httpService,
  $interval,
) {
  if (!setupComplete) {
    setConfigServiceInstance(configService);
  }

  getAccessToken().then((accessToken) => {
    httpService.setHttpCommonHeader(accessToken);
  });

  const onTokenRenew = (newToken) => {
    const formatted = `Bearer ${newToken.value}`;
    setToken(formatted);
    configService.token = formatted;
    httpService.setHttpCommonHeader(newToken);
  };

  setListeners(onTokenRenew);

  if (!setupComplete) {
    // Attach Fastclick for eliminating the 300ms delay between a physical tap and the firing of a click event on mobile browsers
    try {
      FastClick.attach($document.body);
    } catch (error) {
      // Continue regardless of error
    }
  }

  // Set some reference to access them from any scope
  $rootScope.$state = $state;
  $rootScope.$stateParams = $stateParams;

  // GLOBAL APP SCOPE
  // set below basic information
  $rootScope.app = {
    name: 'myCRM', // name of your project
    author: 'Loan Market Group', // author's name or company name
    description: '', // brief description
    version: '2.0', // current version
    year: new Date().getFullYear(), // automatic current year (for copyright information)
    isMobile: (() => {
      // true if the browser is a mobile device
      let check = false;
      if (
        /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          $window.navigator.userAgent,
        )
      ) {
        check = true;
      }
      return check;
    })(),
    defaultLayout: {
      isNavbarFixed: true, // true if you want to initialize the template with fixed header
      isSidebarFixed: true, // true if you want to initialize the template with fixed sidebar
      // eslint-disable-next-line angular/window-service
      isSidebarClosed: window.technology === 'react', // true if you want to initialize the template with closed sidebar
      isFooterFixed: false, // true if you want to initialize the template with fixed footer
      isBoxedPage: false, // true if you want to initialize the template with boxed layout
      theme: 'main', // indicate the theme chosen for your project
      logo: 'assets/images/defaultBrandingLogo/branding.svg', // relative path of the project logo
      logoCollapsed: 'assets/images/square-logo.png', // relative path of the collapsed logo
    },
    layout: '',
  };

  // update author if loanmarket
  if (configService.isLoanMarket) {
    $rootScope.app.author = 'Loan Market Group';
  }
  $rootScope.app.layout = angular.copy($rootScope.app.defaultLayout);

  // this was added because there was an issue of google tag instances being duplicated
  $rootScope.$on('$stateChangeStart', () => {
    angular.element('#gtmScriptTag').remove();
  });

  if (!setupComplete) {
    const config = getConfigData();
    const { freshChatToken } = getConfigFromEnv();
    toAddUserBack(configService, currentUserService, $document, $window);
    toAddFreshChat({
      configService,
      currentUserService,
      $document,
      $window,
      $interval,
      freshChatToken,
    });
    // Usersnap Plugin
    if ($window.technology === 'angular') {
      if (currentUserService.isCorporate) {
        // If Access Type is Corporate
        addUserSnap(config.userSnapKeyCorporate, $document[0], $window);
      } else {
        addUserSnap(config.userSnapKey, $document[0], $window);
      }
    }

    googleMapScript(config.googleApiKey, $document[0]);
    olarkScript(
      currentUserService,
      $document[0],
      $window,
      $timeout,
      legacyAngularHost?.value || '.',
    );
    addCKEditor($document[0]);
  }

  setupComplete = true;
}

main.$inject = [
  '$http',
  '$rootScope',
  '$state',
  '$stateParams',
  '$timeout',
  '$window',
  '$document',
  'configService',
  'currentUserService',
  'LEGACY_ANGULAR_HOST',
  'httpService',
  '$interval',
];

export default main;
