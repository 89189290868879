import swal from 'sweetalert';
import * as Sentry from '@sentry/browser';
import get from 'lodash/get';
import { responseBuilderForUI } from 'Common/mappers/response';

const errorCallback = function (
  e,
  customErrorCallback,
  enableModalError = true,
  url = '',
) {
  const errorStatus = [404, 400, 500];
  const showErrorModal =
    errorStatus.indexOf(e.status) !== -1 && enableModalError;
  if (e.status === 504) {
    Sentry.captureException(
      new Error(
        `504 Status code on API Endpoint ${url} on page ${document.URL}`,
      ),
    );
  }
  if (showErrorModal) {
    const message = get(e, 'data.Message') || 'Please contact support.';
    swal('Yikes! Something is wrong', message, 'error');
  }
  customErrorCallback(e);
};

export const handleError = (url, customErrorCallback, errorModal) => {
  return (e) => {
    const response = responseBuilderForUI(e);
    errorCallback(
      response,
      () => {
        customErrorCallback(response);
      },
      errorModal,
      url,
    );
  };
};
