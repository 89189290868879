import template from './helloPackModal.html';
import controller from './helloPackModalCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    familyId: '<',
    isForLoanWriters: '<',
    selectedService: '<',
    onlyFactFind: '<?',
    loanApplicationId: '<?',
    insuranceProfilerId: '<?',
    inviteInsuranceOnlineFactFind: '<?',
    hideDealOption: '<?',
  },
};
