import angular from 'angular';
import { get, isFunction } from 'lodash';
import { parseToInt10 } from 'Common/utilities/parse';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import {
  NOTES_QUESTION_ID,
  SECTION_ID,
} from 'Common/constants/loanappSectionIds';
import { isValidEmailAddress } from 'Common/utilities/string';
import { toastError, displayError } from 'Common/utilities/alert';
import {
  QUESTION_ID,
  REQUIRED_DECLARATION_QUESTION_ID,
  DECLARATION_SUB_QUESTION_ID,
  INTERVIEWED_CLIENT_VIA_IDS,
  CONFIRM_DECLARATION_IDS,
  QUESTION_ID_AU,
  QUESTION_ID_NZ,
} from 'Common/constants/questionId';
import {
  INTERVIEW_OPTION,
  CONFIRM_DECLARATION_AU,
  CONFIRM_DECLARATION_NZ,
  DECLARATION_CONFIRM_WARNING,
} from 'Common/constants/declaration.js';
import { triggerGA } from 'Common/utilities/googleAnalytics';
import { LENDER_SUBMISSION_EMAIL_TYPES } from 'Common/constants/loanAppSubmission';
import {
  mapLenderContentRecipient,
  mapClientContentRecipient,
} from './loanEmailTemplate';
import { isValidDocument } from './loanAppValidation';

export const isShowOverwriteUtil = ({
  isApplicationSubmitted,
  loanAppSharedData,
  isSubmitOnlineDisabled,
}) => {
  return (
    isApplicationSubmitted &&
    !loanAppSharedData.isAssetFinanceLoan &&
    !isSubmitOnlineDisabled
  );
};
export const showSupportingDocsWarning = (data) => {
  const hasDocuments = data && data.length;
  const warningMsg = `There are currently no supporting documents attached to this loan application.
    Please ensure all supporting documents are uploaded in MyCRM for compliance and audit purposes`;
  return hasDocuments ? false : warningMsg;
};

export const checkOptionalAdviserNotes = ({ key, isNZ }) => {
  return isNZ && parseToInt10(key) === NOTES_QUESTION_ID.ADDITIONAL_INFO;
};

export const checkInvalidRecipient = ($scope) => {
  const {
    AdviserEmailAddress,
    CopyToAdviser,
    ClientEmailAddress,
    CopyToClient,
    LenderEmailAddress,
    SubmissionMethod,
  } = $scope.LenderSubmissionSet;
  if (SubmissionMethod !== 'email') {
    return false;
  }
  const emails = [
    LenderEmailAddress,
    ...((CopyToClient && [ClientEmailAddress]) || []),
    ...((CopyToAdviser && [AdviserEmailAddress]) || []),
  ];

  const emailList = $scope.loanAppEmailTemplateNZ
    ? [
        LenderEmailAddress,
        ...mapClientContentRecipient($scope),
        ...mapLenderContentRecipient($scope),
      ]
    : emails;
  return emailList.some((email) => !isValidEmailAddress(email));
};
export const checkForBlueStoneConfigs = ({
  $scope = {},
  blueStoneSubmissionService,
  currentUserService,
  PROVIDER_TYPE,
}) => {
  const { isAU } = currentUserService;
  blueStoneSubmissionService.loanScope = $scope;
  $scope.isBlueStone = blueStoneSubmissionService.isBlueStoneFeatureOn();
  $scope.toggle = blueStoneSubmissionService.isFeatureOnAndOff();
  $scope.providersType = PROVIDER_TYPE;
  $scope.onChangeProviderToBlueStone = () => {
    const providerId = $scope.loanAppLenderDetailObject.LenderId;
    $scope.isProviderBlueStone = blueStoneSubmissionService.isProviderBlueStone(
      providerId,
    );
  };

  $scope.switchCaseProviders = (method) => {
    let option = $scope.LenderSubmissionSet.SubmissionMethod;
    const isAllowedEmail = $scope.uiControlSubmission.isAcceptingEmail(
      $scope.isAcceptingEmailSubmission,
    );
    switch (method) {
      case 'email':
        if (isAllowedEmail) {
          option = 'email';
        }
        break;
      case 'print':
        if (!isAU || $scope.loanAppSharedData.isAssetFinanceLoan) {
          option = 'print';
        }
        break;
      case 'bluestone':
        option = 'bluestone';
        break;
      default:
        if ($scope.crmCountry === 'Australia') {
          option = 'nextgen';
        } else if ($scope.crmCountry === 'New Zealand') {
          option = 'simpology';
        }
        break;
    }
    const isEmail = $scope.isLodged && option === 'email';
    $scope.isEmailBluestone = $scope.isBlueStone && isEmail;

    $scope.LenderSubmissionSet.SubmissionMethod = option;
    $scope.invalidRecipient = checkInvalidRecipient($scope);
  };
};

export const checkIsDifferentEmailAddress = ({ $scope = {}, invalidEmail }) => {
  const LENDER_EMAIL = 125;
  const defaultEmailAddress = $scope.loanAppLender.DefaultLenderEmailAddress;
  const { answerString = [] } =
    $scope.significantLoanDataHolder.find(
      (email) => email.QuestionId === LENDER_EMAIL,
    ) || {};
  const [newEmail] = answerString;
  const isSubmitToEmail =
    $scope.LenderSubmissionSet.SubmissionMethod === 'email';
  $scope.confirmationParams = {
    newEmail: $scope.isLenderSubmissionEmail
      ? $scope.lenderEmailObj.newEmail
      : newEmail,
    defaultEmailAddress,
    ...$scope.loanAppLenderDetailObject,
  };
  return (
    $scope.currentUserService.isNZ &&
    !$scope.noNeedToCheck &&
    isSubmitToEmail &&
    invalidEmail
  );
};

export const extendLoandSubmit = ({
  $scope,
  dontCheckEmployment,
  blueStoneSubmissionService,
  popupBlockerService,
}) => {
  $scope.isLoadingLoanSubmission = true;
  if ($scope.currentUserService.isNZ) {
    blueStoneSubmissionService.getLoanAppLixiValidation(
      $scope,
      dontCheckEmployment,
    );
  } else {
    const callback = () => {
      $scope.formSubmissionLender(dontCheckEmployment);
    };
    const errorcallback = () => {
      $scope.isLoadingLoanSubmission = false;
    };
    popupBlockerService.run(callback, errorcallback);
  }
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const checkExistingEmail = ($scope, { lenderEmail, applicantEmail }) => {
  const { significantLoanDataHolder } = $scope;
  if (!significantLoanDataHolder || !significantLoanDataHolder.length) {
    return false;
  }
  const questionBodyId =
    (lenderEmail && QUESTION_ID.LENDER_EMAIL_CONTENT) ||
    (applicantEmail && QUESTION_ID.APPLICANT_EMAIL_CONTENT);
  const questionSubjectId =
    (lenderEmail && QUESTION_ID.LENDER_EMAIL_SUBJECT) ||
    (applicantEmail && QUESTION_ID.APPLICANT_EMAIL_SUBJECT);
  const emailContent = significantLoanDataHolder.reduce((initial, answer) => {
    if (get(answer, 'QuestionId') === questionSubjectId) {
      initial.subject = get(answer, 'answerString[0]');
    }
    if (get(answer, 'QuestionId') === questionBodyId) {
      initial.body = get(answer, 'answerString[0]');
    }
    return initial;
  }, {});
  const { subject, body } = emailContent;
  const hasValidEmailContent = !!(subject && body);
  if (!hasValidEmailContent) {
    return hasValidEmailContent;
  }
  if (lenderEmail) {
    $scope.LenderSubmissionSet = {
      ...$scope.LenderSubmissionSet,
      CustomEmailSubject: subject,
      CustomEmailContent: body,
    };
    $scope.LenderSubmission = {
      ...$scope.LenderSubmission,
      CustomEmailSubject: subject,
      CustomEmailContent: body,
    };
    $scope.initLenderEmailContent();
  }
  if (applicantEmail) {
    $scope.loanSubmissionClientEmail = {
      ...$scope.loanSubmissionClientEmail,
      Subject: subject,
      Body: body,
    };
    $scope.LenderSubmissionSet = {
      ...$scope.LenderSubmissionSet,
      ApplicantEmailSubject: subject,
      ApplicantEmailBody: body,
    };
  }
  return hasValidEmailContent;
};

export const lenderEmailConfirmation = ({
  $scope,
  crmConfirmation,
  dontCheckEmployment,
}) => {
  const modalInstance = crmConfirmation.open({
    type: 'warning',
    title: 'Are these the right details?',
    description: `<email-lender-check lender-info-data="vm.cCommonObject" ></email-lender-check>`,
    buttonText: 'Edit',
    modalSize: 'md',
    renderAsComponent: true,
    isButtonMaxWidth: true,
    showCustomButton: true,
    customButtonClass: 'colored',
    customButtonText: `Looks good`,
    commonObject: $scope.confirmationParams,
  });

  modalInstance.result.then(({ isCustom }) => {
    let gaEvent = {
      event: 'ga-event',
      category: 'Loan Application',
      action: 'Submit to Lender',
      label: 'Lender Email - Continue to Send',
    };
    if (!isCustom) {
      gaEvent = {
        ...gaEvent,
        label: 'Lender Email - Cancel',
      };
      triggerGA(gaEvent);
      return;
    }
    triggerGA(gaEvent);
    $scope.noNeedToCheck = true;
    $scope.submitLenderSubmission(dontCheckEmployment);
  });
};

export const setupLenderSubmissionEmail = ({
  $scope,
  configService,
  loanAppSharedData,
  currentUserService,
  loanScenarioService,
}) => {
  const [
    myCRMDefaultType,
    adviserDefaultType,
    otherType,
  ] = LENDER_SUBMISSION_EMAIL_TYPES;
  $scope.isLenderSubmissionEmail = configService.feature.lenderSubmissionEmail;
  $scope.lenderEmailTypeQuestionId = QUESTION_ID.SUBMISSION_EMAIL_TYPE;
  $scope.hasAdviserSaveDefaultEmailAccess = currentUserService.hasAdviserAccess;
  $scope.isInitialLenderLoad = true;

  $scope.setDefaultLenderEmailType = () => {
    const selectedLenderId = get(loanAppSharedData, 'lenderId');
    const isDefaultTypeIncludedInOptions = (typeId) => {
      return !!$scope.submissionEmailTypes.find(
        (emailType) => emailType.typeId === typeId,
      );
    };
    if (!selectedLenderId) {
      return;
    }
    if (
      $scope.lenderEmailObj.lenderEmailType &&
      ($scope.lenderHasDefaultEmail || $scope.adviserHasDefaultEmail) &&
      isDefaultTypeIncludedInOptions($scope.lenderEmailObj.lenderEmailType)
    ) {
      return $scope.lenderEmailObj.lenderEmailType;
    } else if (
      $scope.lenderHasDefaultEmail &&
      isDefaultTypeIncludedInOptions(myCRMDefaultType.typeId)
    ) {
      return myCRMDefaultType.typeId;
    } else if (
      $scope.adviserHasDefaultEmail &&
      isDefaultTypeIncludedInOptions(adviserDefaultType.typeId)
    ) {
      return adviserDefaultType.typeId;
    }
    return otherType.typeId;
  };

  $scope.setSubmissionEmailTypesDropdown = () => {
    const selectedLenderId = get(loanAppSharedData, 'lenderId');
    const hasLenderDefault = selectedLenderId && $scope.lenderHasDefaultEmail;
    if ($scope.hasAdviserSaveDefaultEmailAccess) {
      return hasLenderDefault
        ? LENDER_SUBMISSION_EMAIL_TYPES
        : [adviserDefaultType, otherType];
    }

    const noEditAccessTypes = [];
    if ($scope.lenderHasDefaultEmail) {
      noEditAccessTypes.push(myCRMDefaultType);
    }
    if ($scope.adviserHasDefaultEmail) {
      noEditAccessTypes.push(adviserDefaultType);
    }
    noEditAccessTypes.push(otherType);
    return noEditAccessTypes;
  };

  $scope.setEmailTypes = () => {
    $scope.submissionEmailTypes = $scope.setSubmissionEmailTypesDropdown();
    $scope.lenderEmailObj.lenderEmailType = $scope.setDefaultLenderEmailType();
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const hasDefaultEmailForType = (data, type) => {
    return data.find((email) => email.typeId === type.typeId);
  };

  $scope.forceResetOnOtherOption = () => {
    $scope.saveSubmissionEmailUsed(false, true);
    $scope.updateLenderEmailAddress('', false);
    $scope.forceResetOther = false;
  };

  $scope.isResetOtherTypeValue = () => {
    return (
      $scope.forceResetOther &&
      $scope.lenderEmailObj.lenderEmailType === otherType.typeId &&
      !$scope.isInitialLenderLoad &&
      !$scope.lenderEmailObj[otherType.typeId]
    );
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  $scope.getLenderSubmissionEmails = (loanScenarioId, selectedLenderId) => {
    if (!loanScenarioId) {
      return;
    }
    return loanScenarioService
      .getLenderSubmissionEmails(loanScenarioId)
      .then(({ data }) => {
        $scope.isLenderEmailEmptyState =
          !selectedLenderId || !data || !data.length;
        if (!data || !data.length) {
          return;
        }

        $scope.lenderHasDefaultEmail = !!hasDefaultEmailForType(
          data,
          myCRMDefaultType,
        );
        $scope.adviserHasDefaultEmail = !!hasDefaultEmailForType(
          data,
          adviserDefaultType,
        );

        const mappedData = data.reduce((accum, email) => {
          accum[email.typeId] = email.email;

          const otherHasSameValueWithAdviser =
            email.typeId === otherType.typeId &&
            $scope.adviserHasDefaultEmail &&
            hasDefaultEmailForType(data, adviserDefaultType).email ===
              email.email;

          const isDoForceOtherReset =
            $scope.forceResetOther &&
            email.typeId === otherType.typeId &&
            !$scope.isInitialLenderLoad;

          if (otherHasSameValueWithAdviser || isDoForceOtherReset) {
            accum[email.typeId] = '';
          }
          return accum;
        }, {});
        $scope.lenderEmailObj = { ...$scope.lenderEmailObj, ...mappedData };
      })
      .then(() => {
        if ($scope.isResetOtherTypeValue()) {
          $scope.forceResetOnOtherOption();
        }

        $scope.setEmailTypes();
        $scope.setDefaultLenderEmailValue(
          $scope.lenderEmailObj.lenderEmailType,
        );

        if ($scope.isInitialLenderLoad) {
          $scope.onLenderEmailTypeChange($scope.lenderEmailObj.lenderEmailType);
        }
        $scope.isInitialLenderLoad = false;

        if (
          $scope.lenderEmailObj.lenderEmailType === otherType.typeId &&
          !$scope.lenderEmailObj.newEmail
        ) {
          $scope.editLenderEmail();
          $scope.forceResetOnOtherOption();
        }
      })
      .catch(displayError);
  };

  $scope.validateLenderEmailAddress = () => {
    if ($scope.LenderSubmissionSet) {
      $scope.LenderSubmissionSet.LenderEmailAddress =
        $scope.lenderEmailObj.newEmail;
      $scope.invalidRecipient = checkInvalidRecipient($scope);
    }
  };

  $scope.setDefaultLenderEmailValue = (lenderEmailTypeId) => {
    const typeId = lenderEmailTypeId || $scope.lenderEmailObj.lenderEmailType;
    const selectedLenderTypeEmail = $scope.lenderEmailObj[typeId] || '';
    $scope.lenderEmailObj.newEmail = selectedLenderTypeEmail;
    $scope.hasInitialLenderEmail = !!$scope.lenderEmailObj.newEmail;

    $scope.validateLenderEmailAddress();
  };

  $scope.setEditLenderEmailState = (isEditLenderSubmissionEmail) => {
    $scope.isEditLenderSubmissionEmail = isEditLenderSubmissionEmail;
  };

  $scope.editLenderEmail = () => $scope.setEditLenderEmailState(true);

  $scope.resetEditLenderEmail = () => {
    $scope.setDefaultLenderEmailValue();
    $scope.setEditLenderEmailState(false);
  };

  $scope.onLenderEmailTypeChange = (lenderEmailTypeId) => {
    if (!lenderEmailTypeId) {
      return;
    }
    $scope.lenderEmailObj.lenderEmailType = lenderEmailTypeId;
    $scope.setDefaultLenderEmailValue(lenderEmailTypeId);
    $scope.hideConfirmSaveAsDefault();

    if ($scope.lenderEmailObj.newEmail) {
      $scope.resetEditLenderEmail();
      $scope.saveSubmissionEmailUsed();
    } else {
      $scope.editLenderEmail();
    }

    if ($scope.isResetOtherTypeValue()) {
      $scope.forceResetOnOtherOption();
    }
  };

  $scope.saveEditedLenderEmail = () => {
    if ($scope.lenderEmailObj.lenderEmailType === otherType.typeId) {
      $scope.showConfirmSaveAsDefault();
      return;
    }

    $scope.onConfirmSaveAsDefault(true);
  };

  $scope.showConfirmSaveAsDefault = () => {
    if ($scope.hasAdviserSaveDefaultEmailAccess) {
      $scope.showSaveAsDefaultPopover = true;
    } else {
      $scope.onConfirmSaveAsDefault(false);
    }
  };

  $scope.hideConfirmSaveAsDefault = () => {
    $scope.showSaveAsDefaultPopover = false;
  };

  $scope.checkIfSelectedLender = () => {
    const selectedLenderId = get(loanAppSharedData, 'lenderId');

    if (!selectedLenderId) {
      toastError('Please select a lender');
    }
  };

  $scope.onConfirmSaveAsDefault = (saveAsDefault) => {
    if (!isValidEmailAddress($scope.lenderEmailObj.newEmail)) {
      toastError('Email address is invalid');
      return;
    }

    if (saveAsDefault) {
      $scope.lenderEmailObj.lenderEmailType = adviserDefaultType.typeId;
      $scope.lenderEmailObj[otherType.typeId] = '';
    }

    $scope.hideConfirmSaveAsDefault();
    $scope.saveSubmissionEmailUsed(saveAsDefault);
    $scope.updateLenderEmailAddress(
      $scope.lenderEmailObj.newEmail,
      saveAsDefault,
    );

    if ($scope.isLenderEmailEmptyState) {
      $scope.resetLenderSubmissionEmail();
    }
  };

  $scope.saveSubmissionEmailUsed = (saveAsDefault = false, force = false) => {
    if (!isValidEmailAddress($scope.lenderEmailObj.newEmail) && !force) {
      return;
    }
    const selectedLenderId = get(loanAppSharedData, 'lenderId');
    const lenderEmailValue =
      $scope.isLenderEmailEmptyState && !saveAsDefault
        ? otherType.typeId
        : $scope.lenderEmailObj.lenderEmailType;
    const dropdownDisplayId = 5;
    const payload = {
      LoanScenarioId: $scope.loanAppId,
      FamilyId: $scope.familyId,
      QuestionId: $scope.lenderEmailTypeQuestionId,
      DisplayOptionId: dropdownDisplayId,
      answerint: [lenderEmailValue],
      ProviderId: parseToInt10(selectedLenderId),
    };
    return loanScenarioService
      .lenderSubmissionSet(payload)
      .then(() => {
        $scope.lenderEmailObj[$scope.lenderEmailObj.lenderEmailType] =
          $scope.lenderEmailObj.newEmail;
      })
      .catch(displayError);
  };

  $scope.resetLenderSubmissionEmail = (forceResetOther = false) => {
    const loanScenarioId = get(
      loanAppSharedData,
      'LoanApplicationDetails.LoanScenarioId',
    );
    const selectedLenderId = get(loanAppSharedData, 'lenderId');
    $scope.forceResetOther = forceResetOther;

    $scope.submissionEmailTypes = selectedLenderId
      ? LENDER_SUBMISSION_EMAIL_TYPES
      : [adviserDefaultType, otherType];

    $scope.lenderEmailObj = {
      newEmail: '',
      [myCRMDefaultType.typeId]: '',
      [adviserDefaultType.typeId]: '',
      [otherType.typeId]: '',
    };
    $scope.isLenderEmailEmptyState = !selectedLenderId;

    $scope.hideConfirmSaveAsDefault();
    $scope.resetEditLenderEmail();
    $scope.getLenderSubmissionEmails(loanScenarioId, selectedLenderId);
  };

  const selectedLenderId = get(loanAppSharedData, 'lenderId');
  !selectedLenderId &&
    $scope.isLenderSubmissionEmail &&
    $scope.resetLenderSubmissionEmail();
};

export const callbackValidationTrigger = ({ $scope, isValid }) => {
  isFunction($scope.validationOnlyFn) && $scope.validationOnlyFn({ isValid });
  $scope.shouldValidateOnly = false;
};

export const getAnsweredStatus = (question) => {
  const { QuestionId, answer, SubQuestion, isCustomContent } = question;
  const withSubquestion = get(SubQuestion, 'length', 0);
  let answered = typeof answer !== 'undefined' && typeof answer !== 'object';
  if (!withSubquestion) {
    return answered;
  }
  const subQuestionAnswer = get(SubQuestion, '[0].answer', '');
  if (INTERVIEWED_CLIENT_VIA_IDS.includes(QuestionId)) {
    answered =
      answer &&
      (answer !== INTERVIEW_OPTION.OTHER ||
        (answer === INTERVIEW_OPTION.OTHER && subQuestionAnswer));
  }
  if (isCustomContent) {
    answered = answer || (!answer && subQuestionAnswer);
  }
  return answered;
};

export const getDeclarationsAnswer = (
  question,
  questions,
  questionOptionService,
) => {
  if (!question) {
    return null;
  }
  const { QuestionId } = question;
  const answerKey = questionOptionService.getAnswerType(QuestionId, questions);
  return typeof question[answerKey] !== 'undefined'
    ? question[answerKey][0]
    : null;
};

export const getDeclarationsSubquestion = (
  subQuestion = [],
  $scope,
  questionOptionService,
) =>
  subQuestion.map((question) => {
    const answerObject = $scope.declarationAnswer[question.QuestionId];
    return {
      ...question,
      answer: getDeclarationsAnswer(
        answerObject,
        $scope.loanSubmissionDeclarations,
        questionOptionService,
      ),
    };
  });

export const bidMapAnswerToOjbect = (questions) => {
  if (!get(questions, 'length')) {
    return {};
  }
  return questions.reduce((initial, question) => {
    const { answer, QuestionId, SubQuestion } = question;
    return {
      ...initial,
      [QuestionId]: answer,
      ...(get(SubQuestion, 'length') && bidMapAnswerToOjbect(SubQuestion)),
    };
  }, {});
};

export const getCustomContent = (questionId, countryId) => {
  const isCustomContent = CONFIRM_DECLARATION_IDS.includes(questionId);
  return {
    isCustomContent,
    ...(isCustomContent && {
      customContent:
        countryId === COUNTRY_TYPE.AUSTRALIA
          ? CONFIRM_DECLARATION_AU
          : CONFIRM_DECLARATION_NZ,
    }),
  };
};

export const bidDeclarationsInit = ({ $scope, questionOptionService }) => {
  $scope.declarationList = $scope.loanSubmissionDeclarations.reduce(
    (inital, question) => {
      const { QuestionId, SubQuestion, CountryId } = question;
      const answerObject = $scope.declarationAnswer[QuestionId];
      const validToPush =
        (answerObject && !DECLARATION_SUB_QUESTION_ID.includes(QuestionId)) ||
        REQUIRED_DECLARATION_QUESTION_ID.includes(QuestionId);
      if (validToPush) {
        return [
          ...inital,
          {
            ...question,
            SubQuestion: getDeclarationsSubquestion(
              SubQuestion,
              $scope,
              questionOptionService,
            ),
            answer: getDeclarationsAnswer(
              answerObject,
              $scope.loanSubmissionDeclarations,
              questionOptionService,
            ),
            ...getCustomContent(QuestionId, CountryId),
          },
        ];
      }
      return inital;
    },
    [],
  );
  $scope.declarationsValueSet = bidMapAnswerToOjbect($scope.declarationList);
  return $scope.validateDeclarations();
};

export const bidValidateDeclaration = ({
  $scope,
  setCompletionStatus,
  $timeout,
  loanAppSharedData,
}) => {
  $scope.isValidDeclarations = $scope.declarationList.every(getAnsweredStatus);
  setCompletionStatus({
    $scope,
    $timeout,
    sectionId: SECTION_ID.DECLARATIONS,
    loanAppSharedData,
    isValidSection: $scope.isValidDeclarations,
  });
  $scope.countValids($scope.isValidDeclarations, 'declarations');
};

export const showBidSubquestion = (question) => {
  if (!question) {
    return false;
  }
  const { QuestionId, answer, isCustomContent } = question;
  const showInterviewSubquestion =
    INTERVIEWED_CLIENT_VIA_IDS.includes(QuestionId) &&
    answer === INTERVIEW_OPTION.OTHER;
  const showPleaseConfirmSubquestion = isCustomContent && answer === false;
  return !!(showInterviewSubquestion || showPleaseConfirmSubquestion);
};

export const checkValidConfirmDetailsDeclaration = ({
  $scope,
  isAU,
  displayWarning,
}) => {
  const confirmDetailsId = isAU
    ? QUESTION_ID_AU.CONFIRM_DECLARATION
    : QUESTION_ID_NZ.CONFIRM_DECLARATION;
  const confirmContent = $scope.declarationList.find(
    (question) => question.QuestionId === confirmDetailsId,
  );
  if (!confirmContent) {
    return;
  }
  const { answer, SubQuestion } = confirmContent;
  const validConfirmAnswer =
    answer || (answer === false && get(SubQuestion, '[0].answer'));
  if (validConfirmAnswer) {
    return;
  }
  displayWarning(
    DECLARATION_CONFIRM_WARNING.body,
    DECLARATION_CONFIRM_WARNING.title,
    false,
    DECLARATION_CONFIRM_WARNING.customClass,
  );
};

export const showDocumentsValidationMessage = ({
  $scope,
  loanAppSharedData,
  showMissedFieldMessage = true,
  focusBackToSection,
}) => {
  if (!$scope || !loanAppSharedData) {
    return;
  }
  $scope.isValidDocumentSection = false;
  loanAppSharedData.hasInvalidSupportingDocs = true;
  $scope.showValidationMessage(showMissedFieldMessage, !focusBackToSection);
  if (focusBackToSection) {
    angular.element('html,body,.html,.body').animate({ scrollTop: 600 }, 1400);
    angular.element('.supporting-doc-section .level-2-heading').click();
  }
};

export const validateExistingSupportingDocs = ({
  $scope,
  loanAppSharedData,
  uiService,
  files,
}) => {
  if (!$scope.validation) {
    $scope.validation = {};
  }
  $scope.validation.documents = null;
  const hasInvalidFiles =
    files && files.length && !files.every(isValidDocument);
  if (!hasInvalidFiles) {
    return;
  }

  if (uiService.viewOfAfileTurnedOn) {
    $scope.validation.documents = {
      goToError: $scope.openDocumentsTab,
    };
  }
  showDocumentsValidationMessage({
    $scope,
    loanAppSharedData,
    showMissedFieldMessage: !uiService.viewOfAfileTurnedOn,
    focusBackToSection: !uiService.viewOfAfileTurnedOn,
  });
};

export const validateDocumentOnLoanSubmission = ({
  $scope,
  uiService,
  configService,
  loanAppSharedData,
}) => {
  if (!$scope || !uiService || !configService || !loanAppSharedData) {
    return;
  }

  const isDocumentRequired = configService.feature.bidLoanAppChangesValidation;
  $scope.isValidDocumentSection = true;
  const focusBackToSection = !uiService.viewOfAfileTurnedOn;

  return $scope.showNoSupportingDocsWarning().then((data) => {
    const { showWarning: isNoDocumentAdded, files } = data;
    if (isDocumentRequired && !!isNoDocumentAdded) {
      showDocumentsValidationMessage({
        $scope,
        loanAppSharedData,
        focusBackToSection,
      });
    } else if (!isNoDocumentAdded) {
      validateExistingSupportingDocs({
        $scope,
        loanAppSharedData,
        uiService,
        files,
      });
    }
  });
};

export const proceedToSubmissionEndpoint = ($scope) => {
  if (!$scope) {
    return;
  }

  switch ($scope.LenderSubmissionSet.SubmissionMethod) {
    case 'nextgen':
      $scope.isNextGenSubmitted = true;
      $scope.submitNextGenLoanSubmission();
      break;
    case 'simpology':
      $scope.submitSimpologySubmission();
      break;
    case 'email':
      $scope.emailLoanAppToLenderNZ();
      break;
    case 'print':
      $scope.submitPrintedLoanAppNZ();
      break;
    default:
      break;
  }
};
