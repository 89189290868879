import get from 'lodash/get';
import { convertTextToKey } from 'Common/utilities/string';
import {
  FACT_FIND_HEADINGS_VALUES,
  FACT_FIND_GROUPING,
} from 'Common/constants/factFindHeadings';

export default class HelloPackFactFindCtrl {
  constructor(loanApplicationServices, helloPackService) {
    'ngInject';

    this.loanApplicationServices = loanApplicationServices;
    this.helloPackService = helloPackService;
  }

  $onInit() {
    if (this.handler) {
      this.handler.onToggle = (value) => {
        this.onTurnOnToggle(value);
      };
    }
  }

  $onChanges({ factFindDefaultSetting }) {
    if (factFindDefaultSetting.currentValue) {
      this.getFactFindCheckList();
    }
  }

  getFactFindCheckList() {
    return this.loanApplicationServices
      .getFactFindHeadings()
      .then((data = []) => {
        const { privacypolicy } = this.factFindDefaultSetting;
        const policyObj = {
          id: FACT_FIND_HEADINGS_VALUES.PRIVACY_POLICY,
          name: 'Privacy Policy',
          group: FACT_FIND_GROUPING.GROUP_1,
          viewOnly: true,
        };
        const headings = privacypolicy ? [policyObj].concat(data) : data;
        this.factFindCheckList = headings.reduce((accum, section) => {
          if (section.id === FACT_FIND_HEADINGS_VALUES.SUPPORTING_DOCUMENTS) {
            return accum;
          }
          const sectionKey = convertTextToKey(section.name).toLowerCase();
          const newSection = {
            ...section,
            selected: get(this, `factFindDefaultSetting[${sectionKey}]`, true),
            sectionKey,
          };
          if (!accum[section.group]) {
            accum[section.group] = [newSection];
            return accum;
          }
          accum[section.group] = [...accum[section.group], newSection];
          return accum;
        }, {});
        this.onTurnOnToggle(this.toggleValue, false);
        this.onProcessItems();
      });
  }

  onProcessItems() {
    const factFindItems = Object.keys(this.factFindCheckList).reduce(
      (accum, key) => {
        return [...accum, ...this.factFindCheckList[key]];
      },
      [],
    );
    const selectedItems = factFindItems.filter((item) => item.selected) || [];
    const isFullFactFind = selectedItems.length === factFindItems.length;
    const factFindSections = factFindItems.reduce((accum, factFind) => {
      accum[convertTextToKey(factFind.name)] = factFind.selected;
      return accum;
    }, {});
    const props = {
      isFullFactFind,
      selectedItems,
      factFindSections,
      isFactFindValid: !!selectedItems.length,
    };
    this.onChange && this.onChange({ props });
  }

  getSelectedItems() {
    const factFindItems = Object.keys(this.factFindCheckList).reduce(
      (accum, key) => {
        return [...accum, ...this.factFindCheckList[key]];
      },
      [],
    );
    return factFindItems.filter((item) => item.selected) || [];
  }

  onTurnOnToggle(value, isProcessItem = true) {
    if (!value) {
      return;
    }
    const selectedItems = this.getSelectedItems();
    if (selectedItems.length) {
      return;
    }
    Object.keys(this.factFindCheckList).map((key) => {
      get(this, `factFindCheckList[${key}]`).map((factFind) => {
        factFind.selected = true;
        return factFind;
      });
    });
    isProcessItem && this.onProcessItems();
  }

  onCheckItem(value, item) {
    item.selected = value;
    this.onProcessItems();
  }
}
