import { parseToInt10 } from 'Common/utilities/parse';
import { CONSTRUCTION_TYPE_ORDER_NZ } from 'Common/constants/securityValues';

export function adviserAssistantsBuilderForUI(assistant) {
  return {
    assistantId: parseInt(assistant.Value, 10),
    assistantName: assistant.Name,
  };
}
export function valuationBasisBuilderForUI(result) {
  return {
    name: result.Description,
    value: parseInt(result.ValuationBasisId, 10),
  };
}

export function interviewQuestionBuilderForUI(data) {
  return {
    name: data.Name,
    value: data.Value,
  };
}

export function lenderListBuilderForUI(result) {
  return {
    providerName: result.ProviderName,
    category: result.Category,
    providerId: parseInt(result.ProviderId, 10),
  };
}
export function lendingCategoriesBuilderForUI(category) {
  return {
    categoryName: category.Name,
    categoryId: parseInt(category.Value, 10),
  };
}
export function businessTypesBuilderForUI(type) {
  return {
    name: type.Name,
    value: parseInt(type.Value, 10),
  };
}
export function lenderCategoryBuilderForUI(category) {
  return (
    category && {
      order: category.Order,
      name: category.Name,
      value: parseInt(category.Value, 10),
      helpText: category.HelpText,
      ticked: false,
    }
  );
}
export function frequencyBuilderForUI(option) {
  return {
    name: option.Name,
    value: option.Value,
    helpText: option.HelpText,
  };
}

export function separationLetterGenerateStatusesBuilderForUI(status) {
  return (
    status && {
      id: parseInt(status.Name, 10),
      value: status.Value,
      helpText: status.HelpText,
    }
  );
}

export const partyCompanyTypeBuilderForUI = (companyType) => {
  return (
    companyType && {
      value: parseInt(companyType.Value, 10),
      label: companyType.Name,
    }
  );
};

export function separationLetterContentsBuilderForUI(content) {
  return (
    content && {
      id: parseInt(content.Name, 10),
      value: content.Value,
      helpText: content.HelpText,
    }
  );
}

export function employmentNatureForUI(option) {
  return {
    name: option.Name,
    value: option.Value,
  };
}

export function countryListBuilderForUI(country) {
  return (
    (country && {
      name: country.Name,
      region: country.Region,
      countryId: country.CountryID,
      countryCode: country.CountryCode,
      callingCode: country.CallingCode,
    }) ||
    []
  );
}

export const subscriptionBillingEntityOptionBuilderForUI = (option) => {
  return {
    billingEntityName: option.BillToEntityName,
    billingEntity: option.BillingEntity,
    billingEntityType: option.BillingEntityTypeId,
    name: `${option.BillingEntity} (${option.BillToEntityName})`,
  };
};

export const constructionTypeBuilderForUI = (type, isAU) => {
  const value = parseToInt10(type.Value);
  return (
    type && {
      groupName: type.GroupName,
      groupId: type.GroupId,
      name: type.Name,
      value,
      orderNo: isAU ? value : CONSTRUCTION_TYPE_ORDER_NZ[value],
    }
  );
};

export const fapLicenseBuilderForUI = (type) => {
  return (
    type && {
      name: type.Name,
      value: parseInt(type.Value, 10),
      fapLicenseNo: type.FAPLicenseNo,
    }
  );
};

export const eSignAvailableBrokersBuilderForUI = (data) => {
  return (
    data && {
      brokerId: data.BrokerId,
      fullName: data.FullName,
      reportingStateId: data.ReportingStateId,
      sourceFamilyID: data.SourceFamilyID,
    }
  );
};

export function equifaxAccountTypesBuilderForUI(data) {
  return (
    data && {
      code: data.AccountTypeCode,
      description: data.TypeDescription,
      category: data.TypeCategory,
    }
  );
}

export const purposeTypeBuilderForUI = (data) => ({
  purposeTypeId: data.ServiceabilityPurposeTypeId,
  purposeTypeName: data.ServiceabilityPurposeTypeName,
  nextGenMapping: data.NextGenMapping,
});

export const loanStatusBuilderForUI = (data) => ({
  LoanStatusGroupName: data.GroupName,
  LoanStatusName: data.Name,
  LoanStatusID: String(data.Value),
  OrgPipelineStatusID: data.OrgPipelineStatusID,
});
