import template from './lenderLogo.html';
import LenderLogoCtrl from './lenderLogoCtrl';

export default {
  template,
  controller: ['LEGACY_ANGULAR_HOST', LenderLogoCtrl],
  controllerAs: 'vm',
  bindings: {
    lenderName: '<',
    lenderId: '<',
    countryCode: '<',
    shownOnError: '<',
    width: '<',
    height: '<',
    isLarge: '<',
    isBordered: '<',
    isRetina: '<',
  },
};
