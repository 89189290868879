import angular from 'angular';
import swal from 'sweetalert';
import { displayError } from 'Common/utilities/alert';
import { filterContactCategory } from '../../util/category';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('CategoryModalCtrl', function CategoryModalCtrl(
      $scope,
      $uibModalInstance,
      contactService,
      SweetAlert,
      toaster,
    ) {
      $scope.categoryModalType = 'Select';
      $scope.isNewCategoryVisible = false;
      $scope.newCategoryList = [];
      $scope.existingCategoryNameList = [];

      function getCategory() {
        contactService.categoryListsGet($scope.familyId).then(({ data }) => {
          if (!data) {
            return;
          }
          filterContactCategory({
            $scope,
            data,
          });
        });
      }

      $scope.setCorporate = function (
        categoryId,
        isExclusiveForCorpUser,
        positionIndex,
      ) {
        $scope.filteredItems[
          positionIndex
        ].isExclusiveForCorpUser = !(typeof isExclusiveForCorpUser ===
        'undefined'
          ? false
          : isExclusiveForCorpUser);
        contactService
          .setContactCategoryCorpUser(
            categoryId,
            !(typeof isExclusiveForCorpUser === 'undefined'
              ? false
              : isExclusiveForCorpUser),
          )
          .then(() => {});
      };

      $scope.isCategoryPresent = function (category) {
        return $scope.getContactFamilyInfo.Categories.includes(category);
      };

      $scope.categoryCheckedAdd = function (categoryName, checked) {
        if (checked) {
          $scope.contactInfoSetValue.Categories.push(categoryName);
        } else {
          const categoryIndex = $scope.contactInfoSetValue.Categories.indexOf(
            categoryName,
          );
          $scope.contactInfoSetValue.Categories.splice(categoryIndex, 1);
        }
      };

      $scope.addCategory = function () {
        $scope.isNewCategoryVisible = true;
        const newCategory = {
          CategoryID: 0,
          CategoryName: '',
          IsChecked: true,
        };
        $scope.newCategoryList.push(newCategory);
      };

      $scope.deleteCategory = function (categoryID) {
        swal(
          {
            title: 'Are you sure?',
            text: 'This record will be removed from your category list',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: true,
          },
          (confirm) => {
            if (confirm) {
              contactService
                .deleteCategory(categoryID)
                .then(() => {
                  getCategory();
                  toaster.pop(
                    'success',
                    'Deleted',
                    'Category has been successfully deleted',
                  );
                })
                .catch(displayError);
            }
          },
        );
      };

      $scope.saveCategory = function (category) {
        if (category.CategoryName === '') {
          swal('Empty Name', 'Category Name cannot be empty.', 'error');
          return;
        }

        contactService
          .categorySet(
            $scope.familyId,
            category.CategoryID,
            category.CategoryName,
          )
          .then(() => {
            const index = $scope.newCategoryList.indexOf(category);
            if (index > -1) {
              $scope.newCategoryList.splice(index, 1);
            }
            getCategory();
            toaster.pop(
              'success',
              'Added',
              'Category has been successfully added',
            );
          })
          .catch(displayError);
      };

      $scope.hideEditButton = function (category) {
        category.IsEditMode = true;
      };

      $scope.updateCategory = function (currentlyEditedCategory) {
        if (currentlyEditedCategory.CategoryName === '') {
          swal('Empty Name', 'Category Name cannot be empty.', 'error');
          return;
        }
        currentlyEditedCategory.IsEditMode = false;

        contactService
          .updateCategory(
            currentlyEditedCategory.CategoryID,
            currentlyEditedCategory.CategoryName,
          )
          .then(() => {
            toaster.pop(
              'success',
              'Updated',
              'Category has been successfully updated',
            );
          })
          .catch(displayError);
      };

      function reorder(category, orderBy) {
        contactService.updateCategoryOrder(category, orderBy).then(() => {
          getCategory();
        });
      }

      $scope.reorderUp = function (category, index) {
        if (index > 0) {
          const itemAbove =
            $scope.filteredItems[$scope.filteredItems.indexOf(category) - 1];
          reorder(category.CategoryID, itemAbove.OrderBy);
        }
      };

      $scope.reorderDown = function (category, index) {
        if (index + 1 < $scope.existingCategoryNameList.length) {
          const itemBelow =
            $scope.filteredItems[$scope.filteredItems.indexOf(category) + 1];
          reorder(category.CategoryID, itemBelow.OrderBy);
        }
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.done = function () {
        $scope.referralChecker('Categories');
        contactService
          .contactFamilyInfoSet(angular.toJson($scope.contactInfoSetValue))
          .then(() => {
            $scope.contactFamilyInfoGet();
            toaster.pop(
              'success',
              'Updated',
              'Category has been successfully updated',
            );
          });
        $uibModalInstance.dismiss('cancel');
      };

      function init() {
        getCategory();
      }

      init();
    });
