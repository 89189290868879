import set from 'lodash/set';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import * as Sentry from '@sentry/browser';
import FileUploadCtrl from 'Components/fileUpload/fileUploadCtrl';
import {
  AWS_S3_ROOT,
  HELLOBOOK_VALID_EXTENSIONS,
} from 'Common/constants/document';
import {
  COMMON_PLACEHOLDER,
  SEND_HELLO_BOOK_TYPE,
  FACT_FIND_TYPE,
  DOCUMENT_REQUEST_METHOD_ID,
  DOCUMENT_REQUEST_METHODS,
  HELLO_PACK_KEY,
  HELLO_PACK_OPTIONS_TOGGLE,
  SERVICE_OPTION,
} from 'Common/constants/helloBook';
import {
  PERSON_PARTY_TYPES,
  ENTITY_PARTY_TYPES,
  CONTACT_CLIENT_TYPE_ID,
} from 'Common/constants/partyTypes';
import { CLIENT_ROLE } from 'Common/constants/contactType';
import {
  helloBookDefaultSettingsForMyCRM,
  helloPackSendBuilderForMyCRM,
  insuranceProfilerSendBuilderForMyCRM,
  inviteIOFFSendBuilderForMyCRM,
} from 'Common/mappers/helloBook';
import { getTemporaryUniqueId } from 'Common/utilities/math';
import { parseToInt10 } from 'Common/utilities/parse';
import { toastSuccess, toastError } from 'Common/utilities/alert';
import { formatInvolvedParties } from 'Common/utilities/involvedParties';
import {
  splitByCapitalLetters,
  grammaticallySeparateByComma,
} from 'Common/utilities/string';
import { triggerGA } from 'Common/utilities/googleAnalytics';
import { updateClientDescription } from 'Assets/js/controllers/loanApp/util/application.js';
import {
  commonProps,
  mycrmConfirmationDefault,
  viewOfAFileTheme,
} from 'Common/default/modal';
import { FACT_FIND_HEADINGS_VALUES } from 'Common/constants/factFindHeadings';
import { ERROR_OKTA } from 'Common/constants/errorMessages';
import { getLogger } from '@loanmarket/logger-core';
import { isEnvironment } from 'Common/utilities/env';

const WORKFLOW_FACTFIND = {
  FULL: 'shared',
  PARTIAL: 'shared_partial',
};
const clientMapper = (client) => ({
  firstname: client.firstName,
  lastname: client.lastName,
  email: get(client, 'email[0].email', client.defaultEmail),
  clientId: parseToInt10(client.personId),
});
const FACT_FIND_KEY = 'factFind.factFindSections';
const ONLINE_FF_SENT = 'Online fact find sent!';

export default class HelloPackService {
  constructor(
    $q,
    $uibModal,
    $timeout,
    $interval,
    $window,
    toaster,
    uiService,
    FileUploader,
    configService,
    contactService,
    documentService,
    helloBookService,
    clientInfoService,
    loanScenarioService,
    contactSharedDataService,
    documentCollectionService,
    loanScenarioModelService,
    crmConfirmation,
    elementService,
    insuranceProfilerService,
    insuranceFactFindService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$uibModal = $uibModal;
    this.$timeout = $timeout;
    this.$interval = $interval;
    this.$window = $window;
    this.toaster = toaster;
    this.uiService = uiService;
    this.fileUploader = FileUploader;
    this.configService = configService;
    this.contactService = contactService;
    this.documentService = documentService;
    this.helloBookService = helloBookService;
    this.clientInfoService = clientInfoService;
    this.loanScenarioService = loanScenarioService;
    this.contactSharedDataService = contactSharedDataService;
    this.documentCollectionService = documentCollectionService;
    this.loanScenarioModelService = loanScenarioModelService;
    this.crmConfirmation = crmConfirmation;
    this.elementService = elementService;
    this.insuranceProfilerService = insuranceProfilerService;
    this.insuranceFactFindService = insuranceFactFindService;
    this.form = {};
    this.formError = {};
    this.closeModal = true;
    this.handler = {
      getClientExcludedInDeal: () => {},
      setHelloPackLoanId: () => {},
      skipDealView: () => {},
      setDealHelloPackDisabled: () => {},
      onCheckInvolvedPartiesValidation: () => {},
      onToggleFileInvite: () => {},
    };
    this.logger = getLogger('HelloPackService');
  }

  $onInit() {
    this.onlyFactFind = false;
    this.helloPackModalInstance = null;
  }

  resetHelloPackForm() {
    this.form = {};
    this.formError = {};
  }

  launchConfirmation(props = mycrmConfirmationDefault) {
    return this.$uibModal.open({
      template: `<mycrm-confirmation
                    modal-instance="vm.modalInstance"
                    data-theme="{{vm.props.theme}}"
                    modal-title="{{vm.props.modalTitle}}"
                    message="{{vm.props.message}}"
                    confirmation-title="{{vm.props.confirmationTitle}}"
                    discard-title="{{vm.props.discardTitle}}"
                    on-confirm="vm.props.onConfirm">
                </mycrm-confirmation>`,
      ...commonProps,
      windowClass: 'mycrm-modal mycrm-confirmation-modal',
      resolve: {
        props: () => ({ theme: viewOfAFileTheme, ...props }),
      },
    }).result;
  }

  launchFileInviteRequest({ data }) {
    const PORTAL_VIEW = isEnvironment('prod')
      ? 'https://mycrm.fileinvite.com/invite/overview'
      : 'https://mycrm.sandbox.fileinvite.com/invite/overview';
    if (!data.id) {
      return;
    }
    const props = {
      modalTitle: this.onlyFactFind ? ONLINE_FF_SENT : 'Hello pack sent!',
      message: `You selected File Invite as the method for collecting documents. You can make more changes to this request from within File Invite.`,
      confirmationTitle: 'Open the File Invite request',
      discardTitle: 'Close',
    };
    return this.launchConfirmation(props).then(({ isCancelled }) => {
      if (isCancelled) {
        return;
      }
      this.$window.open(`${PORTAL_VIEW}/${data.id}`, '_blank');
    });
  }

  launchHelloPackModal(props = {}) {
    this.onlyFactFind = !!props.onlyFactFind;
    this.helloPackModalInstance = this.$uibModal.open({
      template: `<hello-pack-modal
        modal-instance="vm.modalInstance"
        family-id="vm.props.familyId"
        selected-service="vm.props.selectedService"
        is-for-loan-writers="!!vm.props.isForLoanWriters"
        loan-application-id="vm.props.loanApplicationId"
        only-fact-find="!!vm.props.onlyFactFind"
        selected-service="vm.props.selectedService"
        insurance-profiler-id="vm.props.insuranceProfilerId"
        invite-insurance-online-fact-find="vm.props.inviteInsuranceOnlineFactFind"
        hide-deal-option="vm.props.hideDealOption"
      ></hello-pack-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      keyboard: false,
      backdrop: 'static',
      size: 'md',
      resolve: {
        props: () => props,
      },
      windowClass: 'mycrm-modal hello-pack-modal',
    });
    return this.helloPackModalInstance;
  }

  initFileUploader(
    familyId,
    isPresignedUrl = false,
    uploadType = AWS_S3_ROOT.HELLOBOOK,
  ) {
    if (!this.fileUploader) {
      return;
    }
    this.fileUploadCtrl = new FileUploadCtrl(
      this.uiService,
      this.fileUploader,
      this.configService,
      this.documentService,
      this.crmConfirmation,
      this.$timeout,
      this.toaster,
      this.$window,
    );
    this.fileUploadCtrl.isPresignedUrl = isPresignedUrl;
    this.fileUploadCtrl.$onInit();
    this.fileUploadCtrl.familyId = familyId;
    this.fileUploadCtrl.uploadType = uploadType;
    this.fileUploadCtrl.type = 'file';
    this.fileUploadCtrl.extensions = HELLOBOOK_VALID_EXTENSIONS;
  }

  onUploadFiles({ attachedFiles = [], callback }) {
    const files = attachedFiles.reduce(
      (accum, file) => {
        const isForUpload = !file.isQueued && !file.documentKey;
        if (isForUpload) {
          accum.forUpload.push(file);
        } else {
          accum.uploaded.push(file);
        }
        return accum;
      },
      {
        uploaded: [],
        forUpload: [],
      },
    );
    if (!files.forUpload.length) {
      const uploadedFiles = [...files.uploaded];
      callback && callback(uploadedFiles);
    }

    const completeCallback = this.fileUploadCtrl.uploader.onCompleteAll;
    this.fileUploadCtrl.uploader.onCompleteAll = () => {
      completeCallback();
      const documents = get(
        this,
        'fileUploadCtrl.uploader.uploadedItems',
        [],
      ).map((file, index) => ({
        id: getTemporaryUniqueId(index),
        documentId: file.DocumentId,
        documentKey: file.DocumentKey,
        name: file.Name,
        showConfirmDeletePopover: false,
        index,
        file,
      }));
      const allFiles = [...files.uploaded, ...documents];
      callback && callback(allFiles);
    };

    files.forUpload.forEach((item) => {
      item.isQueued = true;
      this.fileUploadCtrl.uploader.addToQueue(item.file);
    });
    if (!this.fileUploadCtrl.isPresignedUrl) {
      this.fileUploadCtrl.uploader.uploadAll();
    }
  }

  saveDefaultSetting(props = {}) {
    const {
      content,
      adviserId,
      factFind = {},
      attachedFiles,
      documentRequestMethod,
      fileInvite,
      options,
    } = props;
    const message = get(content, 'message', '').replace(
      content.clientDisplayName,
      COMMON_PLACEHOLDER,
    );
    const sections = Object.keys(options).reduce((accum, key) => {
      if (key === 'documentRequest') {
        set(
          factFind,
          'factFindSections.SupportingDocuments',
          options[key].value,
        );
        factFind.isFullFactFind = !!(
          factFind.isFullFactFind && options[key].value
        );
        return accum;
      }
      const apiKey = options[key].apiKey;
      if (apiKey && options[key].isVisible) {
        accum[apiKey] = options[key].value;
      }
      return accum;
    }, {});
    const settings = {
      ...sections,
      adviserId,
      message,
      documentRequestMethod,
      fileInvite,
      factFindType: factFind.isFullFactFind
        ? FACT_FIND_TYPE.FULL
        : FACT_FIND_TYPE.PARTIAL,
      factFindSections: factFind.factFindSections,
      documents: attachedFiles.map((file) => ({
        documentKey: file.documentKey,
        documentId: file.documentId,
        name: file.name,
      })),
      isUploadedAttachments: get(options, 'uploadedAttachments.value', true),
    };
    const payload = helloBookDefaultSettingsForMyCRM(settings);
    return this.helloBookService
      .setDefaultSettings(adviserId, payload)
      .then(() => {
        toastSuccess('Defaults Saved Successfully!');
      })
      .catch(toastError);
  }

  saveDefault(props = {}, processCallback) {
    const callback = (files = []) => {
      props.attachedFiles = [...files];
      this.saveDefaultSetting(props).finally(processCallback);
    };
    if (!get(props, 'options.uploadedAttachments.value')) {
      return callback();
    }
    this.onUploadFiles({ attachedFiles: props.attachedFiles, callback });
  }

  addInvolvedParties(loanApplicationId, clients = []) {
    if (!clients.length) {
      return Promise.resolve();
    }
    const requests = clients.reduce((accum, client) => {
      const partyType = client.isEntity
        ? ENTITY_PARTY_TYPES.COMPANY
        : PERSON_PARTY_TYPES.APPLICANT;
      const selectedClient = {
        IsClient: true,
        AssignedLoanScenarioId: loanApplicationId,
        ...updateClientDescription(client, partyType),
        FamilyID: client.familyId,
        ClientEntityId: client.personId,
        ClientTypeId: client.isEntity
          ? CONTACT_CLIENT_TYPE_ID.ENTITY.toString()
          : CONTACT_CLIENT_TYPE_ID.PERSON.toString(),
        FirstName: client.firstName,
        LastName: client.lastName,
        PreferedName: client.preferredName,
      };
      return accum.concat(
        this.loanScenarioService.scenarioApplicantSet(selectedClient),
      );
    }, []);
    return Promise.all(requests);
  }

  send(familyId, props = {}, processCallback) {
    const callback = (files = []) => {
      props.attachedFiles = [...files];
      this.sendHelloPack(familyId, props).then(() => {
        processCallback(this.closeModal);
      });
    };
    this.addInvolvedParties(
      props.loanApplicationId,
      props.excludedClients,
    ).then(() => {
      this.onUploadFiles({ attachedFiles: props.attachedFiles, callback });
    });
  }

  sendSuccessfulToast() {
    if (this.onlyFactFind) {
      this.crmConfirmation.notify({
        title: ONLINE_FF_SENT,
        allowClose: true,
        disableBackdrop: true,
      });
    } else {
      this.crmConfirmation.notify({
        title: 'Hello pack sent!',
        allowClose: true,
        disableBackdrop: true,
      });
    }
  }

  sendInsuranceProfile(familyId, props = {}, processCallback) {
    const callback = (files = []) => {
      props.attachedFiles = [...files];
      this.sendInsuranceProfileProceed(familyId, props).finally(
        processCallback,
      );
    };
    this.onUploadFiles({ attachedFiles: props.attachedFiles, callback });
  }

  sendInviteIOFF(familyId, props = {}, processCallback) {
    const form = {
      familyId,
      clients: props.clients,
      message: props.content.message,
    };
    const payload = inviteIOFFSendBuilderForMyCRM(form);
    return this.insuranceFactFindService
      .shareWithClient(payload)
      .then((success) => {
        if (!success) {
          throw Error;
        }
        this.crmConfirmation.notify({
          title: ONLINE_FF_SENT,
          allowClose: true,
          disableBackdrop: true,
        });
      })
      .finally(processCallback)
      .catch(toastError);
  }

  helloPackSection(helloPack) {
    const isHelloPackWithCreditGuideDisplayed = get(
      helloPack,
      'options.helloBookWithCreditGuide.isVisible',
      false,
    );

    return Object.keys(helloPack.options).reduce((accum, key) => {
      if (key === 'documentRequest') {
        const supportingDocumentValue = get(
          helloPack,
          `options[${key}].value`,
          false,
        );
        const factFindKey = 'factFind.isFullFactFind';
        const isFullFactFind = get(helloPack, factFindKey, false);
        set(
          helloPack,
          'factFind.factFindSections.SupportingDocuments',
          supportingDocumentValue,
        );
        set(helloPack, factFindKey, isFullFactFind && supportingDocumentValue);
        return accum;
      }
      if (key === 'onlineFactFind') {
        const onlineFactFind = get(helloPack, 'options.onlineFactFind', {});
        const documentRequest = get(helloPack, 'options.documentRequest', {});
        accum[onlineFactFind.defaultSettingKey] =
          helloPack.selectedService === SERVICE_OPTION.INSURANCE
            ? onlineFactFind.value
            : onlineFactFind.value || documentRequest.value;
        return accum;
      }

      const defaultSettingKey = helloPack.options[key].defaultSettingKey;
      if (defaultSettingKey) {
        const toggleOption = helloPack.options[key];

        if (
          key === HELLO_PACK_KEY.WIH_CREDIT_GUIDE &&
          isHelloPackWithCreditGuideDisplayed
        ) {
          accum[HELLO_PACK_OPTIONS_TOGGLE.CREDIT_GUIDE] = toggleOption.value;
          accum[defaultSettingKey] = toggleOption.value;
        }

        const skipIfInvalidHelloPackCreditGuideKey =
          (isHelloPackWithCreditGuideDisplayed &&
            (key === HELLO_PACK_KEY.DEFAULT ||
              key === HELLO_PACK_KEY.CREDIT_GUIDE_ONLY)) ||
          (!isHelloPackWithCreditGuideDisplayed &&
            key === HELLO_PACK_KEY.WIH_CREDIT_GUIDE);

        if (!skipIfInvalidHelloPackCreditGuideKey) {
          accum[defaultSettingKey] = toggleOption.value;
        }
      }
      return accum;
    }, {});
  }

  setFactFindSections(helloPack) {
    const isFactFindToggled = get(
      helloPack,
      'options.onlineFactFind.value',
      false,
    );
    const factFindSections = get(helloPack, FACT_FIND_KEY, {});
    if (isFactFindToggled) {
      return factFindSections;
    }
    return Object.keys(factFindSections).reduce((accum, key) => {
      accum[key] = false;
      return accum;
    }, {});
  }

  pushHelloPackDetailsToGTM(helloPack) {
    const {
      options = {},
      factFind = {},
      documentRequestMethod,
      fileInvite = {},
    } = helloPack;

    const newModalToggles = Object.keys(options).reduce((acc, cur) => {
      const option = options[cur];
      const key = splitByCapitalLetters(cur, '_').toLowerCase();
      return option.isVisible ? { ...acc, [key]: option.value } : acc;
    }, {});

    const fiSelectedTemplates = get(fileInvite, 'templates', []);
    const fiAvailableTemplates = get(fileInvite, 'availableTemplates', []);
    const selectedTemplates = fiSelectedTemplates.map((templateId) => {
      const template = fiAvailableTemplates.find(
        (temp) => temp.id === templateId,
      );
      const { id, name } = template || {};
      return { id, name };
    });

    const documentRequestType = DOCUMENT_REQUEST_METHODS.find(
      (method) => documentRequestMethod === method.value,
    );

    const helloPackGTMData = {
      event: 'new_hello_modal',
      modal_toggles: newModalToggles,
      fact_find_sections: factFind.factFindSections,
      document_request: {
        type: get(documentRequestType, 'methodName', ''),
        templates: selectedTemplates,
      },
    };

    triggerGA(helloPackGTMData);
  }

  getSelectedHeadingIds(factFind) {
    const isSupportingDocuments = get(
      factFind,
      'factFindSections.SupportingDocuments',
      false,
    )
      ? [{ id: FACT_FIND_HEADINGS_VALUES.SUPPORTING_DOCUMENTS }]
      : [];
    const selectedHeadings = get(factFind, 'selectedItems', []).concat(
      isSupportingDocuments,
    );
    if (!selectedHeadings || !selectedHeadings.length) {
      return {};
    }
    const SelectedHeadingIds = selectedHeadings.reduce((initial, current) => {
      const { id } = current;
      return [...initial, id];
    }, []);
    return { SelectedHeadingIds };
  }

  sendHelloPack(familyId, helloPack) {
    this.closeModal = true;
    set(helloPack, FACT_FIND_KEY, this.setFactFindSections(helloPack));
    const sections = this.helloPackSection(helloPack);
    const [client] = helloPack.clients || [];
    const isFactFindFull = get(helloPack, 'factFind.isFullFactFind', false);
    const workFlow = isFactFindFull
      ? WORKFLOW_FACTFIND.FULL
      : WORKFLOW_FACTFIND.PARTIAL;
    const customerIoWorkflow = this.onlyFactFind ? workFlow : '';
    const form = {
      ...sections,
      sendType: SEND_HELLO_BOOK_TYPE.NOW,
      clients: helloPack.clients,
      client,
      factFindSections: get(helloPack, FACT_FIND_KEY, {}),
      selectedHeadingIds: this.getSelectedHeadingIds(helloPack.factFind),
      factFindType: isFactFindFull
        ? FACT_FIND_TYPE.FULL
        : FACT_FIND_TYPE.PARTIAL,
      customerIoWorkflow,
      adviserId: get(helloPack, 'adviserInfo.familyId', 0),
      editorMessage: helloPack.content.message,
      communicationAppendMessage: helloPack.communicationAppendMessage,
      selectedApplicationId: sections.isFactFindEnabled
        ? helloPack.loanApplicationId
        : 0,
      documents: helloPack.attachedFiles || [],
      isForLoanWriters: helloPack.isForLoanWriters,
      loanId: helloPack.loanId,
      isNZ: helloPack.isNZ || false,
    };
    const payload = helloPackSendBuilderForMyCRM(form);
    const isFileInviteToggleOn = get(
      helloPack,
      'options.documentRequest.value',
      false,
    );

    const isSendFileInvite =
      isFileInviteToggleOn &&
      this.uiService.fileInviteEnabled &&
      helloPack.documentRequestMethod ===
        DOCUMENT_REQUEST_METHOD_ID.FILE_INVITE;
    this.logger.info(`Send Hello pack payload: ${JSON.stringify(payload)}`);
    if (isSendFileInvite) {
      return this.sendFileInvite(helloPack)
        .then((response) => {
          return this.helloBookService.send(familyId, payload).then(() => {
            this.pushHelloPackDetailsToGTM(helloPack);
            this.launchFileInviteRequest(response);
          });
        })
        .catch((error) => {
          this.closeModal = false;
          const message =
            get(error, 'message.message') ?? get(error, 'message.error');
          toastError(message);
        });
    }

    return this.helloBookService
      .send(familyId, payload)
      .then(({ Succeeded, Messages }) => {
        if (typeof Succeeded !== 'undefined' && !Succeeded) {
          return this.errorHandler(Messages);
        }
        this.pushHelloPackDetailsToGTM(helloPack);
        this.contactSharedDataService.loadContactCommunication();
        return this.sendSuccessfulToast();
      });
  }

  errorHandler(messages) {
    const [isOktaError, oktaMessage] = messages;
    const isOkta = isOktaError === ERROR_OKTA;
    const message = isOkta
      ? JSON.parse(oktaMessage)
      : grammaticallySeparateByComma(messages);
    const { errorId, errorSummary } = message;
    const descriptions = isOkta ? errorSummary : message;
    this.logger.error(descriptions);
    return this.crmConfirmation.open({
      type: 'warning',
      title: 'Oops!',
      description: `<fatal-errors fatal-errors-data="vm.cCommonObject" ></fatal-errors>`,
      buttonText: `Got it`,
      showCloseButton: true,
      showCancelButton: false,
      modalSize: 'md',
      cancelButtonClass: 'colored',
      renderAsComponent: true,
      commonObject: {
        codes: errorId,
        descriptions,
      },
      isButtonMaxWidth: true,
    });
  }

  sendInsuranceProfileProceed(familyId, props) {
    const sections = this.helloPackSection(props);
    const form = {
      ...sections,
      familyId,
      clients: props.clients,
      adviserId: get(props, 'adviserInfo.familyId', 0),
      adviserEmail: get(props, 'adviserInfo.email', null),
      message: props.content.message,
      profilerId: props.insuranceProfilerId,
      documents: props.attachedFiles || [],
      insuranceFileId: props.insuranceFileId,
    };
    const payload = insuranceProfilerSendBuilderForMyCRM(form);
    return this.insuranceProfilerService
      .sendInsuranceProfile(payload)
      .then((success) => {
        if (!success) {
          throw Error;
        }
        this.crmConfirmation.notify({
          title: 'Insurance profile sent!',
          allowClose: true,
          disableBackdrop: true,
        });
      })
      .catch(toastError);
  }

  pollFileInviteStatus(data, loanId, poller, defer) {
    if (data.data) {
      this.$interval.cancel(poller);
      const sent = get(data, 'data.sent', false);
      if (sent) {
        defer.resolve(data);
      } else {
        Sentry.captureMessage(`Create Invite not sent - ${loanId}`);
        defer.reject(data);
      }
    } else if (data.message) {
      this.$interval.cancel(poller);
      defer.reject(data);
    }
  }

  sendFileInvite(helloPack) {
    const { fileInvite, clients = [], loanId = 0, adviserId } = helloPack;
    const contacts = clients.map(clientMapper);
    const defer = this.$q.defer();
    this.documentCollectionService
      .createFileInvite({
        ...fileInvite,
        contacts,
        loanId,
        adviserId,
      })
      .then(({ data }) => {
        if (data.value) {
          const POLL_INTERVAL = 1000;
          const POLL_LIMIT = 60;

          let pollCount = 0;
          const poller = this.$interval(() => {
            pollCount++;

            if (pollCount >= POLL_LIMIT) {
              Sentry.captureMessage(
                `Create Invite timed out - ${loanId} - ${data.value}`,
              );
              this.$interval.cancel(poller);
              defer.reject(data);
            }

            this.documentCollectionService
              .pollFileInviteCreation(data.value)
              .then(({ data }) =>
                this.pollFileInviteStatus(data, loanId, poller, defer),
              )
              .catch((error) => {
                this.$interval.cancel(poller);
                defer.reject(error);
              });
          }, POLL_INTERVAL);
        } else {
          defer.reject(data);
        }
      });
    return defer.promise;
  }

  getHelloPackApplicants(loanScenarioId, filters = { IsApplicant: true }) {
    const filterParties = (client) => {
      return Object.keys(filters).find((key) => filters[key] === client[key]);
    };
    return this.loanScenarioModelService
      .getApplicants({
        loanScenarioId,
      })
      .then(({ data }) => {
        const { InvolvedPartyPerson = [] } = data;
        const persons = Object.keys(InvolvedPartyPerson).reduce(
          (accum, key) => {
            const partyPersons = InvolvedPartyPerson[key] || [];
            return accum.concat([...partyPersons]);
          },
          [],
        );
        const involvedParties = formatInvolvedParties({
          InvolvedPartyPerson: [...persons],
        });
        return involvedParties.filter(filterParties);
      });
  }

  getHelloPackInvolvedParties({ loanApplicationId, familyId, filters }) {
    if (loanApplicationId) {
      return this.getHelloPackApplicants(loanApplicationId, filters);
    }
    return this.contactService
      .clientInformGet(familyId, 0, true)
      .then(({ data }) => {
        if (!data) {
          return [];
        }
        return data.filter((client) => client.Role === CLIENT_ROLE.ADULT);
      });
  }

  onSetInvolvedPartiesValidity(error = '') {
    const invalidEmail = get(this, 'form.$error.emailError', false);
    const invalidMobile = get(this, 'form.$error.mobileError', false);
    const invalidInvolvedParties = invalidEmail || invalidMobile;
    const errorMessage =
      error || this.formError.emailError || this.formError.mobileError;
    this.form.$setValidity('involvedParties', !invalidInvolvedParties);
    this.formError.involvedParties = !invalidInvolvedParties
      ? ''
      : errorMessage;
    this.handler.onCheckInvolvedPartiesValidation(!error);
  }

  onCheckClientDuplicateEmail(clients = []) {
    const uniqueByEmail = uniqBy(clients, 'defaultEmail');
    if (uniqueByEmail.length < clients.length) {
      this.form.$setValidity('emailError', false);
      this.formError.emailError = 'At least one client must be enabled';
      this.onSetInvolvedPartiesValidity(this.formError.emailError);
      this.elementService.scrollToElement('#involved-party-header');
      return false;
    }
    this.formError.emailError = '';
    this.form.$setValidity('emailError', true);
    this.onSetInvolvedPartiesValidity();
    return true;
  }

  onSetValidityFactFind(isValid) {
    this.form.$setValidity('onlineFactFind', isValid);
    this.formError.onlineFactFind = isValid
      ? ''
      : `At least one fact find section must be selected. Alternatively, disable 'Online fact find'`;
    if (!isValid) {
      this.elementService.scrollToElement('.onlineFactFind');
    }
  }
}
