import { get } from 'lodash';
import { parseToInt10 } from 'Common/utilities/parse';
import { isEnvironment } from 'Common/utilities/env';

export const initializeRelease = (vm, currentUserService, configService) => {
  vm.newReleases = currentUserService.newReleasesCount;
  const { releasePageAlert } = configService.feature;
  vm.displayReleaseMenu = releasePageAlert;
  vm.displayHelpAndSupportReleaseSubMenu = !releasePageAlert;
};

export const onNavigate = (vm) => (stateName, params = {}) => {
  vm.menuToggleInMobile();
  vm.stateGo(stateName, params);
};

export const redirectBorrower = ({ $state, familyId, isClient }) => {
  const isClientBool = isClient === 'true';
  const familyIdInt = parseToInt10(familyId);
  const stateName = `app.${
    isClientBool ? 'contactsSingle' : 'BusinessAccount'
  }`;
  $state.go(stateName, {
    familyId: familyIdInt,
    activeTab: isClientBool ? 'lending' : 'loans',
  });
};

export const getOpportunityParams = ({
  firstFamilyId,
  loanScenarioId,
  loanId,
}) => {
  const params = {
    opportunityId: loanScenarioId,
    loanId,
    sourceFamilyId: firstFamilyId || 0,
    sourceFamilyName: '',
    isContactOpener: false,
    tabName: 'details',
  };
  return params;
};

export const setFeatureFlagReferences = ({
  vm,
  configService,
  currentUserService,
}) => () => {
  const { feature, activeSubscription } = configService;
  const {
    isAU,
    isNZ,
    isCorporate,
    isPrincipalAdviser,
    isAdminAssistant,
    isEnableComplianceTools,
  } = currentUserService;

  vm.isEsignSubscribed = !!(
    activeSubscription && activeSubscription.includes(`eSign`)
  );

  const getFlagValue = (flagName = '') => get(feature, flagName, false);

  vm.hideReportSupport = !isAU && !getFlagValue('cannyIONZ');
  vm.insurancePipelineEnabled = getFlagValue('insuranceApplicationsPipeline');
  vm.isInternationalPhoneNumber = getFlagValue('internationalPhoneNumbers');
  vm.showUserProfileManagement = getFlagValue('userProfileManagement');
  vm.showLMICalculator = getFlagValue('lmiCalculator');
  vm.showCreditCheck = getFlagValue('creditCheck');
  vm.showHEMCalculator = getFlagValue('hemCalculator');
  vm.showIncomeCalculator = getFlagValue('incomeCalculator');
  vm.showOpportunity = getFlagValue('opportunity') && vm.showLendingTools;
  vm.showInsurancePipeline =
    isNZ && getFlagValue('insuranceApplicationsPipeline');
  vm.showProviderInformation = getFlagValue('providerInformation');
  vm.showMaxLVR = getFlagValue('maxlvr');
  vm.showSubmitEnhancements =
    (isNZ && getFlagValue('cannyIONZ')) || (isAU && getFlagValue('cannyIOAU'));
  vm.showHelloBookCorp = getFlagValue('helloBookCorp');
  vm.showHelloBook =
    getFlagValue('helloBook') && (isNZ ? isEnableComplianceTools : true);
  vm.showGenerateHelloBook = isNZ && vm.showHelloBook;
  vm.showCommissionEnhancementsV3 =
    isNZ && getFlagValue('commissionEnhancementsV3');
  vm.showFinancialsButtonLayout = getFlagValue('financialsButtonLayout');
  vm.forNZUserQuoteProfiler =
    isNZ && getFlagValue('insuranceQuotesPipelineReport');
  vm.forNZusersHideLender = isNZ && getFlagValue('providerInformationNZ');
  vm.viewOfAfileTurnedOn = getFlagValue('bidViewOfAFile');
  vm.newTaskDealEnabled = vm.viewOfAfileTurnedOn && true; // TODO: will remove this on Jan 2021
  vm.newDealFeature = vm.viewOfAfileTurnedOn && !isCorporate;
  vm.newHelloModalEnabled = getFlagValue('newHelloModal');
  vm.fileInviteEnabled = vm.newHelloModalEnabled && getFlagValue('fileInvite');
  vm.showTopUpLoanVariation = getFlagValue('topUpOrLoanVariations');
  vm.showDisclosureGuide =
    isNZ && getFlagValue('nzComplianceHiddenFields') && isEnableComplianceTools;
  vm.loanAppSplitFundingAndStructure = getFlagValue(
    'loanAppSplitFundingAndStructure',
  );
  vm.showGamePlanInterestAndCommission = getFlagValue(
    'gamePlanInterestAndCommission',
  );
  vm.resourcesConciergeEnabled = getFlagValue('resourcesConcierge');
  vm.isSetToFromEmailEnabled =
    (getFlagValue('setToFromEmail') && isNZ) ||
    (getFlagValue('setToFromEmailAU') && isAU);
  vm.showIOFF = getFlagValue('insuranceOnlineFactFind');
  vm.undisclosedGenderEnabled = getFlagValue('undisclosedGender');
  vm.ckeditorTaskEnabled = getFlagValue('ckeditorTask');
  vm.bidLoanAppChangesValidation = getFlagValue('bidLoanAppChangesValidation');
  vm.nzBidLoanAppChangesValidation =
    getFlagValue('bidLoanAppChangesValidation') && isNZ;
  vm.onboardingOptimisation =
    getFlagValue('onboardingOptimisation') &&
    (isCorporate || isPrincipalAdviser || isAdminAssistant);
  vm.newPipeline = getFlagValue('newPipeline');
  vm.commissionSplittingEnabled = getFlagValue('commissionSplitting');
  vm.newCustomerCareEvents = getFlagValue('newCustomerCareEvents');
  vm.businessSettingsEmailMailTemplates = getFlagValue(
    'businessSettingsEmailMailTemplates',
  );
  vm.insuranceFile = getFlagValue('insuranceFile');
  vm.newPipelineTasks = getFlagValue('newPipelineTasks');
};

export const setOneSpanAccess = ({ vm, currentUserService }) => {
  vm.showOneSpan = currentUserService.isAU;
  vm.oneSpanLink = isEnvironment('prod')
    ? 'https://apps.esignlive.com.au'
    : 'https://sandbox.esignlive.com';
};

export const lmConcierge = {
  name: 'Concierge',
  url: 'https://www.loanmarket.com.au/concierge',
};
export const byobConcierge = {
  name: 'Concierge',
  url: 'https://conciergeinsurance.com.au',
};

export const getResourcesConcierge = ({
  resourcesConciergeEnabled,
  currentUserService,
}) => {
  if (!resourcesConciergeEnabled) {
    return [];
  }
  if (currentUserService.isCorporate) {
    return [
      { ...lmConcierge, name: `${lmConcierge.name} - LM` },
      { ...byobConcierge, name: `${byobConcierge.name} - BYOB` },
    ];
  } else if (currentUserService.isLM) {
    return [lmConcierge];
  } else if (currentUserService.isBYOB) {
    return [byobConcierge];
  }
  return [];
};
