import { viewOfAFileTheme } from 'Common/default/modal';
import { toastError } from 'Common/utilities/alert';

class CreateNewInsuranceFileModalCtrl {
  constructor($state, insuranceFileService) {
    'ngInject';

    this.$state = $state;
    this.insuranceFileService = insuranceFileService;
  }

  $onInit() {
    this.defaultTheme = viewOfAFileTheme;
    this.modalTitle = this.file?.id
      ? 'Edit insurance file'
      : 'Create new insurance file';
    this.newFile = {
      InsuranceFileId: this.file?.id || 0,
      FamilyId: this.familyId || 0,
      FileName: this.file?.name || null,
    };
  }

  onCancel() {
    this.modalInstance.close();
  }

  onSave() {
    this.isSaving = true;
    return this.insuranceFileService[
      this.newFile.InsuranceFileId ? 'putInsuranceFile' : 'postInsuranceFile'
    ](this.newFile)
      .then((id) => {
        if (!id) {
          return toastError();
        }
        this.modalInstance.close({ refresh: true });
      })
      .finally(() => {
        this.isSaving = false;
      });
  }
}

export default CreateNewInsuranceFileModalCtrl;
