import angular from 'angular';
import { APPROVAL_EXPIRIES_ID } from 'Common/constants/customerCareModules';
import _ from 'lodash';
import moment from 'moment';
import {
  initEmailRecipients,
  setEmailObject,
} from './util/customerCareEmailUtil';

export const loadModule = () =>
  angular.module('app').controller(
    'OpenPreApprovalSendEmailModalCtrl',
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function OpenPreApprovalSendEmailModalCtrl(
      $scope,
      $rootScope,
      $uibModal,
      $uibModalInstance,
      $state,
      $controller,
      source,
      toaster,
      preApprovalObj,
      preApprovalListTable,
      contactService,
      corporateService,
      customerCareService,
      loadPreApprovalTable,
      formErrorHandlerServices,
      currentUserService,
      customerCareTemplateService,
    ) {
      const vm = this;
      angular.extend(
        vm,
        $controller('CustomerCareSendEmailModalBaseCtrl', { $scope }),
      );

      $scope.recipients = {};
      $scope.isComplete = !currentUserService.isNZ;
      $scope.mergeFieldsParams = preApprovalObj;
      if (source === 'single') {
        if (preApprovalObj.Email !== '') {
          const expDate = moment(preApprovalObj.ExpiryDate).format('D MMM');
          const notiHeader = `Approval Expires (${expDate}) Completed via Email`;
          $scope.recipients.email = initEmailRecipients({
            ...preApprovalObj,
            NotificationHeader: notiHeader,
          });
        }
        $scope.recipients.CustomerCareType = 'FIXED_RATE_EXPIRY';
      }

      $scope.preApprovalListTable = preApprovalListTable;
      $scope.currentModule = APPROVAL_EXPIRIES_ID;

      if (source === 'all') {
        $scope.all = true;
        if ($scope.preApprovalListTable.length > 0) {
          $scope.selectEmailList = [];

          for (let i = 0; i < $scope.preApprovalListTable.length; i++) {
            $scope.emailAdd = {};
            if ($scope.preApprovalListTable[i].Email !== '') {
              $scope.emailAdd.FamilyID =
                $scope.preApprovalListTable[i].FamilyID;
              $scope.emailAdd.FamilyFullname =
                $scope.preApprovalListTable[i].Customer;
              $scope.emailAdd.LoanID = $scope.preApprovalListTable[i].LoanID;

              $scope.emailAdd.EmailAddress =
                $scope.preApprovalListTable[i].Email;
              $scope.selectEmailList.push($scope.emailAdd);
            }
          }
          if ($scope.selectEmailList.length > 0) {
            $scope.recipients.email = $scope.selectEmailList;
            $scope.noOfPeople = $scope.selectEmailList.length;
          }
        }
      }
      $scope.getRecipientAutoSuggestion = function (userInput) {
        contactService
          .getRecipientAutoSuggestion(userInput)
          .then((response) => {
            $scope.selectEmailList = [];
            $scope.withEmail = response.data;
            _.forEach($scope.withEmail, (obj) => {
              if (
                typeof obj.EmailAddress !== 'undefined' &&
                obj.EmailAddress !== ''
              ) {
                $scope.selectEmail = {};
                $scope.selectEmail.FamilyFullname = obj.Name;
                $scope.selectEmail.EmailAddress = obj.EmailAddress;
                $scope.selectEmailList.push($scope.selectEmail);
              }
            });
          });
      };

      // EmailTemplates
      function getMergeFields() {
        $scope.preferredName = '';
        if (typeof preApprovalObj.FamilyID !== 'undefined') {
          $scope.familyId = preApprovalObj.FamilyID;
        } else {
          $scope.familyId = 0;
        }
        if (typeof preApprovalObj.LoanID !== 'undefined') {
          $scope.loanId = preApprovalObj.LoanID;
        } else {
          $scope.loanId = 0;
        }
        $scope.clientId = 0;
        corporateService
          .MergeFieldsGet($scope.familyId, $scope.clientId, $scope.loanId)
          .then((response) => {
            $scope.emailMergeFieldList = response.data;
            $scope.mergeFieldsObj = response.data[0];
            $scope.mergeFields = [];
            $scope.mergeFields = $scope.mergeFieldsObj.MergeFields;
            for (let k = 0; k < $scope.mergeFields.length; k++) {
              if ($scope.mergeFields[k].Name === 'Full Name') {
                $scope.preferredName = $scope.mergeFields[k].Value;
              }
            }
            $scope.getEmailTemplates($scope.preferredName);
            $scope.getDefaultTemplate();
          });
      }

      getMergeFields();

      $rootScope.getEmailTemplatesFromEmailPage = function () {
        $scope.getEmailTemplates();
      };
      $scope.selectedTemplate = {
        UserTemplateID: 0,
        UserTemplateName: 'Blank',
        UserTemplateEmailSubject: '',
      };

      $scope.refreshResults = function (select) {
        const search = select.search;
        $scope.getRecipientAutoSuggestion(search);
      };

      $scope.sendEmail = function (form) {
        const customerCareFormValid = formErrorHandlerServices.sendEmailFormValidation(
          form,
        );
        if (customerCareFormValid) {
          setEmailObject($scope);
          $scope.email.CustomerCareType = 'PRE_APPROVAL_EXPIRY';
          $scope.email.IsComplete = $scope.isComplete;

          customerCareService
            .CustomerCareEmailSendv2($scope.email)
            .then((preApprovalRes) => {
              if (preApprovalRes.data && preApprovalRes.data.length) {
                const preApprovalEmailValid = customerCareTemplateService.customerCareResponseMsg(
                  preApprovalRes.data,
                  'EMAIL',
                );
                if (preApprovalEmailValid) {
                  $scope.email.EmailSubject = '';
                  $scope.email.EmailBody = '';
                  form.$setPristine(true);
                  loadPreApprovalTable();
                }
                $scope.cancel();
              }
            });
        }
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
    },
  );
