export function getLenderLogoPath(lender) {
  const { countryCode, lenderId, isLarge, isRetina } = lender;
  const imgDir = `assets/images`;
  const defaultImgSrc = `${imgDir}/lenderLogos/default.png`;
  const isLenderLogo = lenderId && lenderId > 0 && countryCode;

  if (!isLenderLogo) {
    return defaultImgSrc;
  }

  const lenderLogoDir = isLarge ? 'lenderLogoLg' : 'lenderLogos';
  const screenType = isRetina ? '@2x' : '';
  const lenderLogo = [imgDir, lenderLogoDir, countryCode, lenderId].join('/');
  return `${lenderLogo}${screenType}.png`;
}

export function getLenderLogoImg(lender, height = 21, width = 38) {
  if (!lender) {
    return '';
  }
  const { lenderName } = lender;
  const lenderLogoPath = getLenderLogoPath(lender);
  return lenderLogoPath
    ? `<img height="${height}"
      width="${width}"
      title="${lenderName || 'Lender Logo'}"
      alt="${lenderName || 'Lender Logo'}"
      src="${lenderLogoPath}"/>`
    : '';
}
