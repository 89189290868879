import template from './countrySelectorInput.html';
import CountrySelectorInputCtrl from './countrySelectorInputCtrl';

export default {
  template,
  controller: CountrySelectorInputCtrl,
  bindings: {
    countrySelectorModel: '=',
    countrySelectorId: '=',
    countrySelectorName: '=',
    phoneSelectedCountry: '=',
    phoneCountryCode: '=',
    isRequired: '=',
    isValid: '=',
    isError: '=',
    isSuccess: '=',
    positionRight: '<',
    positionBottom: '<',
    checkPosition: '<',
    countrySelectorContainerRight: '<',
    countrySelectorContainerPosition: '<',
    isLandline: '<',
    selectorForm: '<?',
    useManualValidation: '<?',
    getCurrentNumber: '&?',
    onBlur: '&?',
    onChange: '&?',
    inputClass: '@',
    onYes: '&?',
    onNo: '&?',
    allowBypassValidation: '<',
  },
};
