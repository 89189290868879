import Rox from 'rox-browser';

// Override the feature management properties
export const feature = {
  maxlvr: 'maxLVR',
  newLoanApplicationv2: 'newLoanApplicationV2',
  lmiCalculator: 'lMICalculator',
  fundingCalculatorEnhancement: 'fundingCalculatorQuickWinEnhancements',
  intercept: 'interceptBankConnectExpenseAnalysis',
  betterEntity: 'betterEntityManagement',
  pipelineV2P1: 'pipelineV2-Phase1',
};

export const featureMapperForUI = (features, user) => {
  const props = {};
  if (user.isAU) {
    props.helloBook = features.helloBookAU;
    props.requirementsAndObjectives = features.requirementsAndObjectives;
  } else if (user.isNZ) {
    props.helloBook = !!(features.helloBookNZ && user.isEnableComplianceTools);
    props.requirementsAndObjectives = features.requirementsAndObjectivesNZ;
  }
  return props;
};

export const rolloutFeatureFlags = {
  nodifiPhase2: new Rox.Flag(),
  jagoNPS: new Rox.Flag(),
  nodifi: new Rox.Flag(),
  goalSetterFactFindMerge: new Rox.Flag(),
  dependentUplift: new Rox.Flag(),
  dependentsWithOneClientAsParent: new Rox.Flag(),
  livingExpenseV2: new Rox.Flag(),
  bidLoanAppChangesRationale: new Rox.Flag(),
  cannyIONZ: new Rox.Flag(),
  corpAccreditationTable: new Rox.Flag(),
  productDiscountRate: new Rox.Flag(),
  nzCorpHelloBook: new Rox.Flag(),
  CPACQPGeneration: new Rox.Flag(),
  generateGamePlan: new Rox.Flag(),
  goalSetter: new Rox.Flag(),
  productFinderEditEnhancements: new Rox.Flag(),
  loanAppLikeGamePlan: new Rox.Flag(),
  helloBookAttachments: new Rox.Flag(),
  marketingAutomationClientSubscribe: new Rox.Flag(),
  aolServicingCalc: new Rox.Flag(),
  lmiFromAirtable: new Rox.Flag(),
  helloBookCorp: new Rox.Flag(),
  bccEmailSync: new Rox.Flag(),
  loanAppReferrersNZ: new Rox.Flag(),
  loanAppEmailTemplateNZ: new Rox.Flag(),
  constructionLoanApps: new Rox.Flag(),
  redesignedExpandedNotesDisplay: new Rox.Flag(),
  profileInformationBrokerImages: new Rox.Flag(),
  clientDetailsPreviewMobile: new Rox.Flag(),
  sendEmailToAssistantContact: new Rox.Flag(),
  sendingGamePlan: new Rox.Flag(),
  addressHistoryRefresh: new Rox.Flag(),
  eSign2FA: new Rox.Flag(),
  nzNewServiceability: new Rox.Flag(),
  employmentHistoryRefresh: new Rox.Flag(),
  commissionEnhancementsV3: new Rox.Flag(),
  loanSplitRepaymentHoliday: new Rox.Flag(),
  bidLoanAppChangesValidation: new Rox.Flag(),
  sendToLenderCheckbox: new Rox.Flag(),
  logicalSortingRelease: new Rox.Flag(),
  lenderSubmissionEmail: new Rox.Flag(),
  lendersByAccreditation: new Rox.Flag(),
  abnValidation: new Rox.Flag(),
  segmentAndExportAutoLoadingDisable: new Rox.Flag(),
  educationCPDEnhancements: new Rox.Flag(),
  bidLoanAppChangesDeclarations: new Rox.Flag(),
  assetLookUp: new Rox.Flag(),
  studentLoanDropdown: new Rox.Flag(),
  bidViewOfAFile: new Rox.Flag(),
  documentsRedesign: new Rox.Flag(),
  financialsButtonLayout: new Rox.Flag(),
  bidLoanAppChangesProducts: new Rox.Flag(),
  productFinderLastUpdated: new Rox.Flag(),
  gmailSignatureAdditionalLinks: new Rox.Flag(),
  loanSubmissionRequireDocument: new Rox.Flag(),
  nzLendingTools: new Rox.Flag(),
  inMaintenance: new Rox.Flag(),
  accreditationTable: new Rox.Flag(),
  newHelloModal: new Rox.Flag(),
  fileInvite: new Rox.Flag(),
  unlockingCustomerCare: new Rox.Flag(),
  setToFromEmail: new Rox.Flag(),
  turnOffThumbnails: new Rox.Flag(),
  tasksRedesign: new Rox.Flag(),
  oneTouchPrivacy: new Rox.Flag(),
  profilerDocCOC: new Rox.Flag(),
  loanAppSplitFundingAndStructure: new Rox.Flag(),
  showCreditGuideWithHelloPack: new Rox.Flag(),
  topUpOrLoanVariations: new Rox.Flag(),
  unequalSharesFinancialsExpense: new Rox.Flag(),
  nzComplianceHiddenFields: new Rox.Flag(),
  resourcesConcierge: new Rox.Flag(),
  newPipeline: new Rox.Flag(),
  supportingDocumentsSorting: new Rox.Flag(),
  financialGoalSummary: new Rox.Flag(),
  setToFromEmailAU: new Rox.Flag(),
  gamePlanInterestAndCommission: new Rox.Flag(),
  insuranceOnlineFactFind: new Rox.Flag(),
  creditCheckEquifaxDetails: new Rox.Flag(),
  multiLoanCpa: new Rox.Flag(),
  percentLVRFundingCalc: new Rox.Flag(),
  undisclosedGender: new Rox.Flag(),
  statementOfAdvice: new Rox.Flag(),
  ckeditorTask: new Rox.Flag(),
  userbackHelpMenu: new Rox.Flag(),
  onboardingOptimisation: new Rox.Flag(),
  preApprovalSITCampaign: new Rox.Flag(),
  customAggregator: new Rox.Flag(),
  freshChatAU: new Rox.Flag(),
  kbHelpMenuAU: new Rox.Flag(),
  newPipelineTasks: new Rox.Flag(),
  commissionSplitting: new Rox.Flag(),
  newCustomerCareEvents: new Rox.Flag(),
  businessSettingsEmailMailTemplates: new Rox.Flag(),
  useNavInAngular: new Rox.Flag(),
  insuranceFile: new Rox.Flag(),
  hideReportingAndSegmentExport: new Rox.Flag(),
};
