import _ from 'lodash';
import swal from 'sweetalert';
import { REPORT_TYPE } from 'Common/constants/reportType';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import {
  JASPER_FILTERS,
  PIPELINE_STATUS_FILTER,
  LOAN_DATE_TYPES,
} from 'Common/constants/jasperReport';
import { getMonthList } from 'Common/utilities/month';
import { REFERRAL_TYPE } from 'Common/constants/referralType';
import { typeIntBuilderForUI } from 'Common/mappers/sharedMappers';
import {
  financialDefaultMonth,
  referrerOrgDefault,
  loanStructureType,
  referrerDefault,
  reportingState,
  pipelineStatus,
  lenderCategory,
  businessUnit,
  referralType,
  loanStatus,
  dateRange,
  assistant,
  lender,
  loanPurpose,
} from 'Common/default/jasperReport';

import {
  getValueOption,
  parseMultiSelectTicks,
  getCorrespondingReport,
} from 'Common/utilities/dashboardWidget';
import {
  LENDER_SPLIT_SMALL,
  WIDGET_ID,
  LENDER_SPLIT_ON,
  AVERAGE_LOAN_SIZE,
  LIST_LOAN_STATUS,
  SETTLEMENT_VOLUMES,
  ENQUIRY_SOURCE,
  REFERRALS_SENT_RECEIVED,
} from 'Common/constants/dashboardWidget';

class JasperReportCtrl {
  constructor(
    $window,
    $filter,
    $scope,
    $state,
    $q,
    $stateParams,
    pipelineStatusService,
    jasperReportService,
    sendReferralService,
    currentUserService,
    communicateService,
    commissionService,
    corporateService,
    optionsService,
    jasperService,
    configService,
    commonWidgetService,
    reportService,
    contactInsuranceService,
    mycrmLookupService,
    uiService,
  ) {
    'ngInject';

    this.$window = $window;
    this.$filter = $filter;
    this.$scope = $scope;
    this.$state = $state;
    this.$q = $q;
    this.$stateParams = $stateParams;
    this.selectReferrerOrg = this.selectReferrerOrg.bind(this);
    this.selectReferrer = this.selectReferrer.bind(this);
    this.pipelineStatusService = pipelineStatusService;
    this.jasperReportService = jasperReportService;
    this.sendReferralService = sendReferralService;
    this.currentUserService = currentUserService;
    this.communicateService = communicateService;
    this.commissionService = commissionService;
    this.corporateService = corporateService;
    this.optionsService = optionsService;
    this.jasperService = jasperService;
    this.configService = configService;
    this.commonWidgetService = commonWidgetService;
    this.reportService = reportService;
    this.contactInsuranceService = contactInsuranceService;
    this.mycrmLookupService = mycrmLookupService;
    this.selectReferrerOrgFamily = this.selectReferrerOrgFamily.bind(this);
    this.isIncludedInBirthdayReminder = false;
    this.uiService = uiService;
  }

  getReportTypeSettings() {
    this.jasperReportService.getReportTypeSetting().then((types) => {
      this.reportTypes = types.map((type) => {
        type.otherSettings = type.otherSettings
          ? JSON.parse(type.otherSettings)
          : '';
        return type;
      });
      if (!this.uiService.showInsuranceTools) {
        this.reportTypes = this.reportTypes.filter(
          (status) => status.reportTypeId !== REPORT_TYPE.INSURANCE_REPORTS,
        );
      }
    });
  }

  getReferrerOrganizations() {
    this.jasperService.getReferrerOrganizations().then((data) => {
      if (!data || !data.length) {
        return;
      }
      const referrerOrg = _.uniqBy(data, (org) =>
        [org.referrerOrgId, org.referrerOrgName].join(),
      );
      this.referrerOrgList = this.isCorporateUser
        ? referrerOrg
        : [referrerOrgDefault, ...referrerOrg];
      this.referrerOrgId = 0;
      this.referrerId = 0;
    });
  }

  getReferrers(referrerOrg) {
    this.referrerId = 0;
    if (!referrerOrg) {
      return;
    }
    const params = {
      referrerOrg,
    };
    this.jasperService.getReferrers(params).then((data) => {
      if (!data || !data.length) {
        return;
      }
      const referrer = _.uniqBy(data, (ref) =>
        [ref.referrerId, ref.referrerName].join(),
      );
      this.referrerList = this.isCorporateUser
        ? referrer
        : [referrerDefault, ...referrer];
    });
  }

  getAdviserAssistants() {
    const familyId = this.currentUserService.isCorporate
      ? 0
      : this.currentUserService.familyId;
    this.optionsService.adviserAssistantsGet(familyId).then(({ data = [] }) => {
      const assistants = data && data.map(typeIntBuilderForUI);
      this.assistantRaw = [assistant, ...assistants];
      this.assistantList = [...this.assistantRaw];
      this.assistantSelected = [assistant];
    });
  }

  getUnconditionalApprovedDate() {
    this.date.start = new Date(this.date.year, this.date.month - 6, 1);
    this.date.end = new Date(this.date.year, this.date.month, 0);
  }

  getCurrentMonthDate() {
    this.date.start = new Date(this.date.year, this.date.month, 1);
    this.date.end = new Date(this.date.year, this.date.month + 1, 0);
  }

  getLocalLang() {
    this.adviserLocalLang = {
      ...this.jasperReportService.getLocalLang('Advisers'),
    };
    this.assistantLocalLang = {
      ...this.jasperReportService.getLocalLang('Assistants'),
    };
    this.reportingStateLocalLang = {
      ...this.jasperReportService.getLocalLang('States'),
    };
    this.lenderLocalLang = {
      ...this.jasperReportService.getLocalLang('Lenders'),
    };
    this.businessUnitLocalLang = {
      ...this.jasperReportService.getLocalLang('Business Units'),
    };
    this.pipelineStatusLocalLang = {
      ...this.jasperReportService.getLocalLang('Pipeline Status'),
    };
    this.loanStatusLocalLang = {
      ...this.jasperReportService.getLocalLang('Loan Status'),
    };
    this.lenderCategoryLocalLang = {
      ...this.jasperReportService.getLocalLang('Categories'),
    };
    this.referralTypeLocalLang = {
      ...this.jasperReportService.getLocalLang('Referral Types'),
    };
    this.loanStructureTypeLocalLang = {
      ...this.jasperReportService.getLocalLang('Interest Types'),
    };
    this.insurersLocalLang = {
      ...this.jasperReportService.getLocalLang('Insurance Providers'),
    };
    this.loanPurposeLocalLang = {
      ...this.jasperReportService.getLocalLang('Loan Purpose'),
    };
  }

  getCommissionBroker() {
    if (this.adviserListForCommission && this.adviserListForCommission.length) {
      this.parseAdviserMultiSelect(this.adviserListForCommission);
      return;
    }
    this.commissionService.getAllBrokers().then((response) => {
      const { data } = response;
      if (!data || !data.length) {
        return;
      }
      const hasOnlyOneAdviser = data.length === 2;
      const adviserList = hasOnlyOneAdviser ? data.slice(1) : data;
      this.adviserListForCommission =
        adviserList &&
        adviserList
          .map((adviser, index) => {
            const { BrokerID: value, BrokerFullName: name } = adviser;
            const ticked = index === 0;
            return { ticked, value, name };
          })
          .sort((left, right) => {
            return left.value < right.value ? -1 : 1;
          });
      this.parseAdviserMultiSelect(this.adviserListForCommission);
    });
  }

  getLoanStructureType() {
    this.optionsService.getLoanStructureType().then((data) => {
      this.parseLoanStructureType(data);
    });
  }

  getCalendaOption() {
    this.optionsService.getCalendaOption().then((data) => {
      this.dateRangeOptionV2 = data || [];
    });
  }

  getLenderCategory() {
    this.optionsService.getCategoryLendersList().then((data) => {
      this.parseLenderCategory(data);
    });
  }

  formatMultiSelectGroupItem(item, isHeader) {
    return {
      name: isHeader ? `<strong>${item.GroupName}</strong>` : item.Name,
      value: isHeader ? undefined : item.Value,
      msGroup: isHeader || undefined,
    };
  }

  formatLoanPurposeList(list) {
    const formattedList = [];
    let currentGroup = '';

    list.forEach((item) => {
      const firstGroupItem = item.GroupName && currentGroup !== item.GroupName;
      if (firstGroupItem) {
        if (currentGroup) {
          formattedList.push({ msGroup: false });
        }

        currentGroup = item.GroupName;
        const formattedItem = this.formatMultiSelectGroupItem(item, true);
        formattedList.push(formattedItem);
      }

      const formattedItem = this.formatMultiSelectGroupItem(item, false);
      formattedList.push(formattedItem);
    });
    return formattedList;
  }

  getLoanPurpose() {
    this.optionsService.loanpurpose().then(({ data }) => {
      if (!data || !data.length) {
        return;
      }

      const formattedList = this.formatLoanPurposeList(data);
      this.loanPurposeList = [loanPurpose, ...formattedList];
      this.loanPurposeRaw = [loanPurpose, ...formattedList];
      this.loanPurposeSelected = [loanPurpose];
    });
  }

  getFinancialYear() {
    this.reportService.getCountryFiscalYear().then(({ data }) => {
      const [financialYear] = data;
      const defaultStart = new Date(this.date.year, financialDefaultMonth, 1);
      const defaultEnd = new Date(
        defaultStart.getFullYear() + 1,
        defaultStart.getMonth(),
        0,
      );
      const { FiscalYearStart, FiscalYearEnd } = financialYear || {};
      this.date.financialYearStart = FiscalYearStart
        ? new Date(FiscalYearStart)
        : defaultStart;
      this.date.financialYearEnd = FiscalYearEnd
        ? new Date(FiscalYearEnd)
        : defaultEnd;
    });
  }

  parseLenderCategory(data) {
    if (!data || !data.length) {
      return;
    }
    this.lenderCategoryList = [lenderCategory, ...data];
    this.lenderCategoryRaw = [lenderCategory, ...data];
    this.lenderCategorySelected = [lenderCategory];
  }

  parseAdviserMultiSelect(data) {
    if (!data || !data.length) {
      return;
    }
    this.adviserList = [...data];
    this.adviserRaw = [...data];
    this.adviserSelected = [data[0]];
  }

  parseAdviseSingleSelect(data) {
    if (!data || !data.length) {
      return;
    }
    this.adviserListForSingleSelect = [...data];
  }

  parseLoanStructureType(data) {
    if (!data || !data.length) {
      return;
    }
    this.loanStructureTypeList = [loanStructureType, ...data];
    this.loanStructureTypeRaw = [loanStructureType, ...data];
    this.loanStructureTypeSelected = [loanStructureType];
  }

  parseReportingState(response) {
    const { data } = response;
    if (!data || !data.length) {
      return;
    }
    const reportingList =
      data &&
      data.map((row) => {
        row.ticked = false;
        return row;
      });
    this.reportingStateRaw = [reportingState, ...reportingList];
    this.reportingStateList = [reportingState, ...reportingList];
    this.reportingStateSelected = [reportingState];
  }

  parseLender(response) {
    const { data } = response;
    if (!data || !data.length) {
      return;
    }
    const lenderList =
      data &&
      data.map((row) => {
        row.ticked = false;
        return row;
      });
    this.lenderRaw = [lender, ...lenderList];
    this.lenderList = [lender, ...lenderList];
    this.lenderSelected = [lender];
  }

  parseBusinessUnit(response) {
    const { data } = response;
    if (!data || !data.length) {
      return;
    }
    const businessUnitList =
      data &&
      data.map((row) => {
        row.ticked = false;
        return row;
      });
    this.businessUnitRaw = [businessUnit, ...businessUnitList];
    this.businessUnitList = [businessUnit, ...businessUnitList];
    this.businessUnitSelected = [businessUnit];
  }

  parsePipelineStatus(data) {
    this.pipelineStatusPartialList = this.pipelineStatusService.getPipelineStatus();
    this.pipelineStatusFullList = [pipelineStatus, ...data];
    this.assignPipelineStatus();
  }

  parseDateRangeOption(data) {
    if (!data || !data.length) {
      return;
    }
    this.dataRangeList = [dateRange, ...data];
  }

  parseDateOption() {
    this.monthList = getMonthList();
    this.monthSelected = this.date.month + 1;
  }

  parseAdviserForSingleSelect(data) {
    this.advisers = data && data.length ? data.filter((x) => x.fullName) : [];
    this.adviserId =
      this.advisers && this.advisers.length
        ? this.advisers[0].familyId
        : this.currentUserService.familyId || 0;
  }

  parseLoanStatus(data) {
    if (!data || !data.length) {
      return;
    }
    this.loanStatusRaw = [loanStatus, ...data];
    this.loanStatusList = [loanStatus, ...data];
    this.loanStatusSelected = [loanStatus];
  }

  parseReferralType(response) {
    const { data } = response;
    if (!data || !data.length) {
      return;
    }
    this.types = data;
    if (this.currentUserService.isAU) {
      const { RAY_WHITE, WEALTH_MARKET, OTHER, LOAN_MARKET } = REFERRAL_TYPE;
      const typesForAu = [RAY_WHITE, WEALTH_MARKET, OTHER, LOAN_MARKET];
      this.types = data.filter((type) => {
        return typesForAu.includes(type.ReferralTypeID);
      });
    }
    this.referralTypeRaw = [referralType, ...this.types];
    this.referralTypeList = [referralType, ...this.types];
    this.referralTypeSelected = [referralType];
  }

  assignPipelineStatus() {
    if (!this.reportType) {
      return;
    }
    const isFullList =
      this.reportType &&
      this.reportType.filters &&
      this.reportType.filters.length &&
      this.reportType.filters.includes(JASPER_FILTERS.PIPELINE_STATUS_FULL);
    const data = isFullList
      ? this.pipelineStatusFullList
      : this.pipelineStatusPartialList;
    if (!data || !data.length) {
      return;
    }
    this.pipelineStatusRaw = [...data];
    this.pipelineStatusList = [...data];
    this.pipelineStatusSelected = [data[0]];
  }

  openDate(dateField, isDisabled = false) {
    if (isDisabled) {
      return;
    }
    this.date[dateField] = !this.date[dateField];
  }

  selectReferrerOrg(referrerOrg) {
    if (!referrerOrg || !referrerOrg.originalObject) {
      this.$scope.$broadcast('angucomplete-alt:clearInput', 'referrer');
      this.referrerOrgId = 0;
      return;
    }
    const { referrerOrgId } = referrerOrg.originalObject;
    this.referrerOrgId = referrerOrgId;
    this.getReferrers(referrerOrgId);
  }

  selectReferrerOrgFamily(referrerOrgFamily) {
    if (!referrerOrgFamily || !referrerOrgFamily.originalObject) {
      this.referrerOrgFamilyId = 0;
      return;
    }
    const { referrerOrgId } = referrerOrgFamily.originalObject;
    this.referrerOrgFamilyId = referrerOrgId || 0;
  }

  selectReferrer(referrer) {
    if (!referrer || !referrer.originalObject) {
      this.referrerId = 0;
      return;
    }
    const { referrerId } = referrer.originalObject;
    this.referrerId = referrerId;
  }

  selectReportType(reportType) {
    if (
      reportType &&
      reportType.reportTypeId === REPORT_TYPE.AVERAGE_UNCONDITIONAL_APPROVED
    ) {
      this.getUnconditionalApprovedDate();
    } else {
      this.getCurrentMonthDate();
    }
    if (
      reportType &&
      reportType.reportTypeId === REPORT_TYPE.COMMISSION_REPORT
    ) {
      this.nonCommissionAdvisers = [...this.adviserList];
      this.getCommissionBroker();
    } else if (this.nonCommissionAdvisers) {
      this.parseAdviserMultiSelect(this.nonCommissionAdvisers);
    }
    this.dateFilteredOnLoan = 0;
    this.dateFilteredOnOpportunity = 0;
    this.assignPipelineStatus();
    this.dateRangeOption = 0;
    this.processDefaultValues(reportType.otherSettings);
  }

  displayAdviserSingle() {
    return (
      this.currentUserService.accessType !== ACCESS_TYPE.ADVISER &&
      this.displayFilters(this.jasperFilters.ADVISER_SINGLE)
    );
  }

  displayCustomDate() {
    return (
      !this.dateRangeOption &&
      (this.displayFilters(this.jasperFilters.CUSTOM_DATE) ||
        this.displayFilters(this.jasperFilters.RELATIVE_DATE))
    );
  }

  displayReferrerDetails() {
    return (
      this.configService.feature.jasperReportReferrerDetails &&
      this.displayFilters(this.jasperFilters.REFERRER_DETAILS)
    );
  }

  displayLender() {
    return (
      this.displayFilters(this.jasperFilters.LENDER) ||
      this.displayFilters(this.jasperFilters.LENDER_ID)
    );
  }

  resetReportDefault() {
    this.report.exportToPdf = null;
    this.report.exportToExcel = null;
    this.report.isCancelled = false;
  }

  generateReport() {
    this.resetReportDefault();
    this.getJasperParameter();
    this.getJasperReportToken().then((data) => {
      if (!data || data.status === 'inactive') {
        return;
      }
      this.isReportGenerated = true;
      this.report.isLoading = true;
      this.report = { ...this.report, ...data };
      this.report.jasperUrl = this.jasperReportService.getJasperReportUrl(
        this.reportType,
      );
      this.jasperReportService.loadJasperReport(this.report);
    });
  }

  displayReportContent() {
    const isActive = this.report.status !== 'inactive';
    const isNotCancelled = !this.report.isCancelled;
    return this.isReportGenerated && isActive && isNotCancelled;
  }

  disableGenerateButton() {
    return (
      !this.reportType ||
      this.report.isLoading ||
      this.report.status === 'inactive'
    );
  }

  displaySelectionMessage() {
    const isInactive = this.report.status !== 'inactive';
    return isInactive && !this.isReportGenerated;
  }

  initializeDashboardDefault() {
    this.reportType = this.reportTypes.find(
      (item) => item.reportTypeId === REPORT_TYPE.LOAN_DETAILS_REPORT,
    );
    if (!this.reportType) {
      return;
    }
    this.selectReportType(this.reportType);
    const status = this.$stateParams.loanStatus || 'lodged';
    this.dateFilteredOnLoanV3 =
      LOAN_DATE_TYPES[status.toUpperCase()] || LOAN_DATE_TYPES.LODGED;
  }

  setDashboardWidgetFilter() {
    if (!this.$state.params.adviser || !this.$state.params.dateFilter) {
      return;
    }
    this.initializeDashboardDefault();
    this.adviserList.map((obj) => {
      obj.ticked = false;
      const selectedAdviser = Array.isArray(this.$state.params.adviser)
        ? this.$state.params.adviser.find(
            (item) => obj.value === parseInt(item, 10),
          )
        : obj.value === parseInt(this.$state.params.adviser, 10);
      if (
        selectedAdviser ||
        (this.$state.params.adviser === '0' && obj.value === -1)
      ) {
        obj.ticked = true;
      }
      return obj;
    });

    switch (this.$state.params.dateFilter) {
      case 'This Week':
        this.weekStart = this.fullDate.getDate() - this.fullDate.getDay() + 1;
        this.weekEnd = this.weekStart + 6;
        this.date.start = new Date(
          this.date.year,
          this.date.month,
          this.weekStart,
        );
        this.date.end = new Date(this.date.year, this.date.month, this.weekEnd);
        break;
      case 'This Calendar Year':
        this.date.start = new Date(this.date.year, 0, 1);
        this.date.end = new Date(this.date.year + 1, 0, 0);
        break;
      case 'This Financial Year':
        this.date.start = this.date.financialYearStart;
        this.date.end = this.date.financialYearEnd;
        break;
      case 'This Month':
        this.date.start = new Date(this.date.year, this.date.month, 1);
        this.date.end = new Date(this.date.year, this.date.month + 1, 0);
        break;
      default:
        break;
    }
  }

  preselectReferralsTile(data) {
    const timePeriod = getValueOption(
      REFERRALS_SENT_RECEIVED.TIME_PERIOD,
      data,
    );
    const advisers = getValueOption(REFERRALS_SENT_RECEIVED.ADVISERS, data);

    this.adviserList = parseMultiSelectTicks(advisers, this.adviserList);
    this.date = {
      ...this.date,
      ...this.jasperReportService.preselectTimePeriod(timePeriod),
    };
  }

  preselectEnquiry(data) {
    const timePeriod = getValueOption(ENQUIRY_SOURCE.TIME_PERIOD, data);
    const advisers = getValueOption(ENQUIRY_SOURCE.ADVISERS, data);

    this.adviserList = parseMultiSelectTicks(advisers, this.adviserList);
    this.date = {
      ...this.date,
      ...this.jasperReportService.preselectTimePeriod(timePeriod),
    };
    this.pipelineStatusList.map((item) => {
      item.ticked = item.statusId === PIPELINE_STATUS_FILTER.NEW_LEADS;
      return item;
    });
  }

  preselectSettlementVolume(data) {
    const timePeriod = getValueOption(SETTLEMENT_VOLUMES.TIME_PERIOD, data);
    const advisers = getValueOption(SETTLEMENT_VOLUMES.ADVISERS, data);
    this.dateFilteredOnLoan = this.loanFilteredOn.indexOf(
      'Loan Settlement Date',
    );
    this.adviserList = parseMultiSelectTicks(advisers, this.adviserList);
    this.date = {
      ...this.date,
      ...this.jasperReportService.preselectTimePeriod(timePeriod),
    };
  }

  preselectLoanSize(data) {
    const timePeriod = getValueOption(AVERAGE_LOAN_SIZE.TIME_PERIOD, data);
    const advisers = getValueOption(AVERAGE_LOAN_SIZE.ADVISERS, data);

    this.adviserList = parseMultiSelectTicks(advisers, this.adviserList);

    const loanStatusObj = getValueOption(AVERAGE_LOAN_SIZE.LOAN_STATUS, data);
    const loanStatusSelected =
      loanStatusObj && loanStatusObj.Widget_OptionValue;
    this.dateFilteredOnLoan = LIST_LOAN_STATUS.indexOf(
      parseInt(loanStatusSelected, 10),
    );

    this.date = {
      ...this.date,
      ...this.jasperReportService.preselectTimePeriod(timePeriod),
    };
  }

  preselectLenderSplit(data) {
    const splitOn = getValueOption(LENDER_SPLIT_SMALL.SPLIT_ON, data);
    const timePeriod = getValueOption(LENDER_SPLIT_SMALL.TIME_PERIOD, data);
    const advisers = getValueOption(LENDER_SPLIT_SMALL.ADVISERS, data);
    const lenderCategoryVal = getValueOption(
      LENDER_SPLIT_SMALL.LENDER_CATEGORY,
      data,
    );
    const { isNZ, isID } = this.currentUserService;

    this.loansSplitBySelected =
      parseInt(splitOn.Widget_OptionValue, 10) === LENDER_SPLIT_ON.COUNT
        ? 1
        : 0;

    this.adviserList = parseMultiSelectTicks(advisers, this.adviserList);
    const hasLenderCategory = !(isNZ || isID);
    this.lenderCategoryList = hasLenderCategory
      ? parseMultiSelectTicks(lenderCategoryVal, this.lenderCategoryList)
      : this.lenderCategoryList;

    this.date = {
      ...this.date,
      ...this.jasperReportService.preselectTimePeriod(timePeriod),
    };
  }

  preselectFiltersFromWidget(widgetId, data) {
    const intWidgetId = parseInt(widgetId, 10);
    switch (intWidgetId) {
      case WIDGET_ID.LENDER_SPLIT_SMALL:
        this.reportType = this.reportTypes.find(
          (item) => item.reportTypeId === REPORT_TYPE.LENDER_SPLIT,
        );
        if (this.reportType) {
          this.selectReportType(this.reportType);
          this.preselectLenderSplit(data);
        }
        break;

      case WIDGET_ID.AVG_LOAN_SIZE:
        this.reportType =
          this.reportTypes &&
          this.reportTypes.find(
            (item) => item.reportTypeId === REPORT_TYPE.LOAN_SIZE_WIDGET,
          );
        if (this.reportType) {
          this.selectReportType(this.reportType);
          this.preselectLoanSize(data);
        }
        break;

      case WIDGET_ID.SETTLEMENT_VOLUMES:
        this.reportType =
          this.reportTypes &&
          this.reportTypes.find(
            (item) => item.reportTypeId === REPORT_TYPE.SETTLEMENT_VOLUMES,
          );
        if (this.reportType) {
          this.selectReportType(this.reportType);
          this.preselectSettlementVolume(data);
        }
        break;

      case WIDGET_ID.ENQUIRY_SOURCE:
        this.reportType =
          this.reportTypes &&
          this.reportTypes.find(
            (item) => item.reportTypeId === REPORT_TYPE.LEAD_STATUS_REPORT,
          );
        if (this.reportType) {
          this.selectReportType(this.reportType);
          this.preselectEnquiry(data);
        }
        break;

      case WIDGET_ID.REFERRALS_RECEIVED:
      case WIDGET_ID.REFERRALS_SENT:
        this.reportType =
          this.reportTypes &&
          this.reportTypes.find(
            (item) => item.reportTypeId === getCorrespondingReport(intWidgetId),
          );
        if (this.reportType) {
          this.selectReportType(this.reportType);
          this.preselectReferralsTile(data);
        }
        break;
      default:
    }
  }

  getWidgetUserOptions(widgetId, widgetUserId) {
    this.commonWidgetService
      .getWidgetUserOptions(widgetUserId)
      .then(({ data }) => {
        if (!data || !data.length) {
          return;
        }

        this.preselectFiltersFromWidget(widgetId, data);
      });
  }

  processDefaultValues({ defaults }) {
    this.disabledFields = [];
    if (this.previousSelectionSetting) {
      this.assignUserDefaultValues(this.previousSelectionSetting, true);
    }
    if (defaults) {
      this.assignUserDefaultValues(defaults);
    }
  }

  assignUserDefaultValues(defaults = {}, revertPreviousSetting = false) {
    this.previousSelectionSetting = revertPreviousSetting ? null : defaults;
    const defaultFields = Object.keys(defaults);
    if (!defaultFields.length) {
      return;
    }
    defaultFields.map((field) => {
      if (defaults[field].isMultiSelect) {
        this.assignMultiSelectDefaultValues(
          defaults[field],
          revertPreviousSetting,
        );
        return field;
      }
      if (defaults[field].isDate) {
        this.assignUserDefaultDates(
          field,
          defaults[field],
          revertPreviousSetting,
        );
        return field;
      }
      const revertValue = defaults[field].revertValue;
      const fieldRevertValue =
        typeof revertValue !== 'undefined' ? revertValue : 0;
      this[field] = revertPreviousSetting
        ? fieldRevertValue
        : defaults[field].value;
      return field;
    });
    if (revertPreviousSetting) {
      return;
    }
    this.disabledFields = defaultFields.filter(
      (field) => defaults[field].disabled,
    );
  }

  assignMultiSelectDefaultValues(fieldObj, revertPreviousSetting) {
    const { id, base, value, revertValue } = fieldObj;
    const rawList = this[`${base}Raw`];
    this[`${base}List`] =
      rawList &&
      rawList.map((list) => {
        const fieldRevertValue = revertValue || -1;
        list.ticked = revertPreviousSetting
          ? list[id] === fieldRevertValue
          : list[id] === value;
        if (list.ticked) {
          this[`${base}Selected`] = [list];
        }
        return list;
      });
  }

  assignUserDefaultDates(field, fieldObj, revertPreviousSetting) {
    if (revertPreviousSetting) {
      return;
    }
    const { month = 0, year = 0 } = fieldObj;
    const isEndDate = field === 'end';
    const defaultYear = this.date.year + year;
    const defaultMonth = this.date.month + month + (isEndDate ? 1 : 0);
    const defaultDay = isEndDate ? 0 : 1;
    this.date[field] = new Date(defaultYear, defaultMonth, defaultDay);
  }

  getInsuranceProviders() {
    this.contactInsuranceService
      .insuranceQuoteProviderGet(0, 0, true)
      .then(({ data }) => {
        this.insuranceProvidersList = data.map((provider) => {
          return {
            providerId: provider.ProviderId,
            name: provider.ProviderName,
          };
        });
        const allInsurersObj = {
          providerId: -1,
          name: 'All Insurers',
          ticked: true,
        };
        this.insuranceProvidersList = [
          allInsurersObj,
          ...this.insuranceProvidersList,
        ];
        this.insuranceProvidersRaw = [...this.insuranceProvidersList];
      });
  }

  getInsuranceBenefitStatuses() {
    this.contactInsuranceService.insuranceStatusGet().then(({ data }) => {
      this.insuranceStatusList = data.map((status) => {
        return {
          statusId: status.StatusID,
          name: status.StatusName,
        };
      });
      const allStatusObj = {
        statusId: -1,
        name: 'All Insurance Status',
        ticked: true,
      };
      this.insuranceStatusList = [allStatusObj, ...this.insuranceStatusList];
      this.insuranceStatusRaw = [...this.insuranceStatusList];
    });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  $onInit() {
    this.fullDate = new Date();
    this.date = {
      endIsOpen: false,
      startIsOpen: false,
      year: this.fullDate.getFullYear(),
      month: this.fullDate.getMonth(),
    };
    this.report = {};
    this.getReferrerOrganizations();
    this.getLocalLang();
    this.getReportTypeSettings();
    this.parseDateOption();
    this.getCurrentMonthDate();
    this.getLoanStructureType();
    this.getCalendaOption();
    this.getAdviserAssistants();
    this.getInsuranceProviders();
    this.getInsuranceBenefitStatuses();
    this.isCorporateUser =
      this.currentUserService.accessType === ACCESS_TYPE.CORPORATE;
    this.onItemSelect = this.jasperReportService.onItemSelect;
    this.onItemReset = this.jasperReportService.onItemReset;
    this.displayFilters = this.jasperReportService.displayFilters;
    this.getJasperReportToken = this.jasperReportService.getJasperReportToken;
    this.getJasperParameter = this.jasperReportService.getJasperParameter;
    this.getJasperReportParameterArray = this.jasperReportService.getJasperReportParameterArray;
    this.opportunityFilteredOn = ['Date Created', 'Date Updated'];
    this.insuranceFilteredOn = ['Commenced', 'Created', 'Last Updated'];
    this.loanFilteredOn = [
      'Loan Lodged Date',
      'Loan Approved Date',
      'Loan Settlement Date',
    ];
    this.dateFilteredOnList = this.opportunityFilteredOn;
    this.dateFilteredOnLabelFor = 'opportunity';
    this.dateFilteredOnLoan = 0;
    this.dateFilteredOnOpportunity = 0;
    this.dateFilteredOnInsurance = 0;
    this.dateFilteredOnLoanV3 = 0;
    this.dateRangeOption = 0;
    this.referrerList = this.isCorporateUser ? [] : [referrerDefault];
    this.jasperFilters = JASPER_FILTERS;
    this.loansSplitByList = ['Loan Amount', 'Number of Loans'];
    this.loansSplitBySelected = 0;
    this.isReportGenerated = false;
    this.dataToShowList = ['Chart Only', 'Table Only', 'Chart and Table'];
    this.rateTypeList = ['Fixed', 'Variable'];
    this.previousAggregatorOptions = ['All', 'No', 'Yes'];
    this.previousAggregator = 0;
    this.dataToShow = 0;
    this.getLenderCategory();
    this.getLoanPurpose();
    this.getFinancialYear();
    const promises = [
      this.jasperReportService.getAdviserForMultiSelect(),
      this.corporateService.reportingStatesGet(),
      this.communicateService.lenderListGet(0),
      this.corporateService.businessUnitGet(),
      this.jasperService.getPipelineStatus(),
      this.jasperService.getDateRanges(),
      this.mycrmLookupService.getAdvisers(),
      this.jasperService.getLoanStatus(),
      this.sendReferralService.referralReferralTypeGet(),
      this.jasperService.getTypesOfDate(),
      this.jasperService.getReferrerOrgFamily(),
    ];
    this.report.cancel = () => {
      this.report.isLoading = false;
      this.report.isCancelled = true;
    };
    this.$q
      .all(promises)
      .then((responses) => {
        if (!responses || !responses.length) {
          return;
        }
        const [
          adviserMultiSelectResponse,
          reportingStateResponse,
          lenderResponse,
          businessUnitResponse,
          pipelineStatusResponse,
          dateRangeOptionResponse,
          adviserForSingleSelectResponse,
          loanStatusResponse,
          referralTypeResponse,
          typesOfDate,
          referrerOrgFamily,
        ] = responses;
        if (adviserMultiSelectResponse) {
          this.parseAdviserMultiSelect(adviserMultiSelectResponse);
        }
        if (reportingStateResponse) {
          this.parseReportingState(reportingStateResponse);
        }
        if (lenderResponse) {
          this.parseLender(lenderResponse);
        }
        if (businessUnitResponse) {
          this.parseBusinessUnit(businessUnitResponse);
        }
        if (pipelineStatusResponse) {
          this.parsePipelineStatus(pipelineStatusResponse);
        }
        if (dateRangeOptionResponse) {
          this.parseDateRangeOption(dateRangeOptionResponse);
        }
        if (adviserForSingleSelectResponse) {
          this.parseAdviserForSingleSelect(adviserForSingleSelectResponse);
        }
        if (loanStatusResponse) {
          this.parseLoanStatus(loanStatusResponse);
        }
        if (referralTypeResponse) {
          this.parseReferralType(referralTypeResponse);
        }
        if (typesOfDate) {
          this.typesOfDate = typesOfDate;
        }
        if (referrerOrgFamily) {
          this.referrerOrgFamilyList = referrerOrgFamily;
        }
        this.getJasperParameter();
        this.getJasperReportToken().then((data) => {
          this.report = {
            ...this.report,
            ...data,
          };
          const isInactive = !data || data.status === 'inactive';
          if (isInactive) {
            return;
          }
          return this.jasperReportService.loadVisualizeJs(this.report);
        });
        if (this.$state.params.adviser && this.$state.params.dateFilter) {
          this.setDashboardWidgetFilter();
        }
        const { params } = this.$state;
        const fromDashboardWidget = params.widgetId && params.widgetUserId;
        fromDashboardWidget &&
          this.getWidgetUserOptions(params.widgetId, params.widgetUserId);
      })
      .catch(() => {
        swal('Server Error', `Unable to load lookups for reports.`, 'error');
      });
  }
}

export default JasperReportCtrl;
