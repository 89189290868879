import { isLMBranded } from 'Common/utilities/brandingCategory';

export const getApiUrl = ({ brandingCategoryID, tradingName, loanAppId }) => {
  const apiUrl = `/goal/#/broker-login/${loanAppId}`;

  return isLMBranded(brandingCategoryID) ? apiUrl : `/${tradingName}${apiUrl}`;
};

export const getGoalSetterUrl = ({
  url,
  brandingCategoryID,
  tradingName,
  loanAppId,
}) => {
  if (!url) {
    return;
  }
  const params = { brandingCategoryID, tradingName, loanAppId };
  return `${url}${getApiUrl(params)}`;
};
