import angular from 'angular';
import lenderSubmission from './lenderSubmission';
import submitOnlineButton from './submitOnlineButton';
import modalProviders from './modalProviders';
import warningBanner from './warningBanner';
import customWarning from './customWarning';
import multipleErrorBanner from './multipleErrorBanner';
import fatalErrors from './fatalErrors';
import internalErrors from './internalErrors';

export default angular
  .module('blueStoneSubmission.scenes.components', [])
  .component('submitOnlineButton', submitOnlineButton)
  .component('modalProviders', modalProviders)
  .component('warningBanner', warningBanner)
  .component('customWarning', customWarning)
  .component('multipleErrorBanner', multipleErrorBanner)
  .component('fatalErrors', fatalErrors)
  .component('internalErrors', internalErrors)
  .component('lenderSubmission', lenderSubmission).name;
