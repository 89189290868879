import { BILLING_TYPE } from 'Common/constants/subscriptionBilling';

export function subscriptionDetailsBuilderForUI(item) {
  return {
    id: item.SubDetailId,
    name: item.Name,
    description: item.Description,
  };
}

export function subscriptionLinksBuilderForUI(link) {
  return {
    linkText: link.LinkText,
    linkUrl: link.LinkURL,
  };
}

export function subscriptionAdditionalInfoBuilderForUI(data) {
  return {
    notes: data.Notes,
  };
}

export const subscriptionBillingBuilderForMyCRM = (data) => {
  return {
    AdviserId: data.adviserId,
    SubscriptionId: data.subscriptionId,
    BillingEntityTypeId: data.billingEntityType,
    BillingEntityName: data.billingEntityName,
  };
};

export const subscriptionBillingPutBuilderForMyCRM = (data) => {
  return {
    AdviserId: data.adviserId,
    SubscriptionId: data.subscriptionId,
    BillingEntityTypeId: data.billingEntityType,
    BillingEntityName: data.billingEntityName,
    SubscriptionBillingEntityId: data.billingEntityId,
  };
};

export const subscriptionBillingEntityBuilderForUI = (data) => {
  return {
    billingEntityName: data.BillingEntityName,
    billingEntityType: data.BillingEntityTypeId,
    billingEntityId: data.SubscriptionBillingEntityId,
    subscriptionId: data.SubscriptionId,
    billingName: `${
      data.BillingEntityTypeId === BILLING_TYPE.FRANCHISEE
        ? 'Franchisee'
        : 'Contractor'
    } ${(data.BillingEntityName && `(${data.BillingEntityName})`) || ''}`,
    organisationId: data.OrganisationId,
  };
};

export function subscriptionInfoBuilderForUI(subscription) {
  return {
    brokerId: subscription.BrokerID,
    description: subscription.Description,
    franchiseBillingDate: subscription.FranchiseBillingDate,
    isActive: subscription.IsActive,
    isCancelled: subscription.IsCancelled,
    isTrial: subscription.IsTrial,
    monthlyRecurringFee: subscription.MonthlyRecurringFee,
    nextRenewDate: subscription.NextRenewDate,
    orderedBy: subscription.OrderedBy,
    orderedById: subscription.OrderedById,
    subscriptionId: subscription.SubscriptionId,
    subscriptionProductId: subscription.SubscriptionProductId,
    subscriptionStartDate: subscription.SubscriptionStartDate,
    title: subscription.Title,
    termsAndConditionsLink: subscription.TermsAndConditionsLink,
    subDetailIDs: subscription.SubDetailIDs,
    subscriptionEndDate: subscription.SubscriptionEndDate,
    isImmediately: subscription.IsImmediately,
    isPromoted: subscription.IsPromoted,
    hasTrial: subscription.SubscriptionHasTrial,
    costUponSubscription: subscription.CostUponSubscription,
    productSubDetails:
      (subscription.SubscriptionProductSubDetails &&
        subscription.SubscriptionProductSubDetails.map(
          subscriptionDetailsBuilderForUI,
        )) ||
      [],
    links:
      (subscription.Links &&
        subscription.Links.map(subscriptionLinksBuilderForUI)) ||
      [],
    additionalInfo:
      (subscription.AdditionalInfo &&
        subscription.AdditionalInfo.map(
          subscriptionAdditionalInfoBuilderForUI,
        )) ||
      [],
    defaultPrice: subscription.DefaultPrice,
    cancelMessage: subscription.CancelMessage,
    isDefaultPricePresent: typeof subscription.DefaultPrice !== 'undefined',
    additionalCostDescription: subscription.AdditionalCostDescription,
    billingEntityType: subscription.BillingEntityTypeId,
    billingEntityId: subscription.SubscriptionBillingEntityId,
    billingEntityName: subscription.billingEntityName,
    previousSubscriptionId: subscription.PreviousSubscriptionId,
  };
}

export function subscriptionBuilderForMyCRM(subscription) {
  return {
    BrokerID: subscription.brokerId,
    Description: subscription.description,
    FranchiseBillingDate: subscription.franchiseBillingDate,
    IsActive: subscription.isActive,
    IsCancelled: subscription.isCancelled,
    IsTrial: subscription.isTrial,
    MonthlyRecurringFee: subscription.monthlyRecurringFee,
    NextRenewDate: subscription.nextRenewDate,
    OrderedBy: subscription.orderedBy,
    OrderedById: subscription.orderedById,
    SubscriptionId: subscription.subscriptionId,
    SubscriptionProductId: subscription.subscriptionProductId,
    SubscriptionStartDate: subscription.subscriptionStartDate,
    Title: subscription.title,
    TermsAndConditionsLink: subscription.termsAndConditionsLink,
    SubDetailIDs: subscription.subDetailIDs,
    SubscriptionEndDate: subscription.subscriptionEndDate,
    IsImmediately: subscription.isImmediately,
    SubscriptionHasTrial: subscription.hasTrial,
    DefaultPrice: subscription.defaultPrice,
    AdditionalCostDescription: subscription.additionalCostDescription,
  };
}

export function subscriptionPricingBuilderForUI(pricing) {
  return {
    adviserOrgName: pricing.AdviserOrgName,
    brokerBrandingID: pricing.BrokerBrandingID,
    AdviserOrgName: pricing.AdviserOrgName,
    hasPreviousTrial: pricing.HasPreviousTrial,
    subscribedUsers: pricing.SubscribedUsers,
    subscriptionPrice: pricing.SubscriptionPrice,
    subscriptionProductID: pricing.SubscriptionProductID,
  };
}

export function marketingAutomationBuilderForUI(data) {
  return {
    subscribe_FixedRateExpiry: data.Subscribe_FixedRateExpiry,
    subscribe_HappyBirthday: data.Subscribe_HappyBirthday,
    subscribe_Nurture: data.Subscribe_Nurture,
    subscribe_InterestOnly: data.Subscribe_InterestOnly,
    subscribe_PreApprovalExpiry: data.Subscribe_PreApprovalExpiry,
    subscribe_StayInTouchAnnualReview: data.Subscribe_StayInTouchAnnualReview,
    subscribe_StayInTouchSettlement: data.Subscribe_StayInTouchSettlement,
    organization_FixedRateExpiry: data.Organization_FixedRateExpiry,
    organization_HappyBirthday: data.Organization_HappyBirthday,
    organization_Nurture: data.Organization_Nurture,
    organization_InterestOnly: data.Organization_InterestOnly,
    organization_PreApprovalExpiry: data.Organization_PreApprovalExpiry,
    organization_StayInTouchAnnualReview:
      data.Organization_StayInTouchAnnualReview,
    organization_StayInTouchSettlement: data.Organization_StayInTouchSettlement,
  };
}
