import { cloneDeep, get } from 'lodash';
import { removeFromArray } from 'Common/utilities/array';
import {
  DEFAULT_INCOME_TYPES,
  RENTAL_SUB_INCOME_TYPES,
} from 'Common/constants/incomeTypes';
import { OPPORTUNITY } from 'Common/constants/paramTypes';
import { catchError } from 'Common/utilities/promise';
import { parseToInt10 } from 'Common/utilities/parse';
import {
  LIABILITY_TYPE,
  IS_LOANS,
  IS_INTERESTS,
  IS_LIMITS,
} from 'Common/constants/liabilityType';
import {
  INVESTMENT_PURPOSE_TYPES,
  BUSINESS_PURPOSE_TYPES,
  OWNER_OCCUPIED_PURPOSE_TYPES,
  PRIMARY_PURPOSE_TYPE,
} from 'Common/constants/primaryPurpose';
import { formatDate, computeDays } from 'Common/utilities/date';
import { DATE_TEXT_FORMAT } from 'Common/constants/dateConfigs';
import { pluralize } from 'Common/utilities/string';
import { NZ_LENDER } from 'Common/constants/commonLenderIds';
import { toastInfo, toastError } from 'Common/utilities/alert';
const ADULT_LIMIT = 2;
const MAX_ADULT = 5;

const mapOpportunityCalculationValue = (obj) => {
  return (
    (obj && {
      NoOfSpouse: obj.assessmentDetail && obj.assessmentDetail.noOfSpouse,
      NoOfDependents:
        obj.assessmentDetail && obj.assessmentDetail.noOfDependents,
      LoanAmount: obj.assessmentDetail && obj.assessmentDetail.loanAmount,
      SecurityAmount:
        obj.assessmentDetail && obj.assessmentDetail.securityAmount,
      LoanTerm: (obj.assessmentDetail && obj.assessmentDetail.loanTerm) || 30,
      LVR: obj.assessmentDetail && obj.assessmentDetail.lVR,
    }) ||
    {}
  );
};

export const getIncomeType = ({
  $scope,
  loanScenarioService,
  includeSubTypes = false,
}) => {
  loanScenarioService.IncomeTypeGet(includeSubTypes).then(({ data }) => {
    const incomeTypeList = includeSubTypes
      ? mapRentalSubTypeNameAsIncomeNameForIncomeTypeList(data)
      : data;

    $scope.incomeTypeList = incomeTypeList;
  });
};

export const isIncomeRentalSubType = (value) => {
  if (!value) {
    return false;
  }

  const strValue = String(value);
  if (!strValue.includes('-')) {
    return false;
  }

  const splitValue = strValue.split('-');
  const [Value, RentalTypeId] = splitValue;
  return (
    parseToInt10(Value) === DEFAULT_INCOME_TYPES.RENTAL &&
    RENTAL_SUB_INCOME_TYPES.includes(parseToInt10(RentalTypeId))
  );
};

export const mapRentalSubTypeNameAsIncomeNameForIncomeTypeList = (
  incomeTypeList,
) => {
  if (!incomeTypeList) {
    return [];
  }

  return (
    incomeTypeList.map((incomeType) => {
      if (
        parseToInt10(incomeType.Value) === DEFAULT_INCOME_TYPES.RENTAL &&
        !String(incomeType.Value).includes('-')
      ) {
        incomeType.OriginalValue = incomeType.Value;
        incomeType.Value = `${incomeType.Value}-${incomeType.RentalTypeId}`;

        incomeType.OriginalIncomeName = incomeType.Name;
        incomeType.Name = incomeType.RentalTypeName;
      } else {
        incomeType.Value = String(incomeType.Value);
      }
      return incomeType;
    }) || []
  );
};

export const mapRentalSubTypeNameAsIncomeNameForIncomeList = (income) => {
  if (!income) {
    return {};
  }
  const { IncomeTypeId } = income;
  if (parseToInt10(IncomeTypeId) === DEFAULT_INCOME_TYPES.RENTAL) {
    income.OriginalIncomeType = IncomeTypeId;
    income.IncomeTypeId = `${income.OriginalIncomeType}-${income.RentalTypeId}`;
    income.OriginalIncomeName = income.TypeName;
    income.TypeName = income.RentalTypeName;
  }
  return income;
};

export const setIncomeTypeModel = ($scope) => (newIncome) => {
  if (!newIncome || !newIncome.IncomeOwner) {
    return;
  }

  const {
    IncomeType,
    IncomeOwner: { ClientNo, ClientID = null },
  } = newIncome;

  const extendToIncome = {
    ClientNo,
    ClientID,
  };

  const isNzIncomeRentalSubtype =
    $scope.nzNewServiceability && isIncomeRentalSubType(IncomeType);
  if (isNzIncomeRentalSubtype) {
    const strValue = String(IncomeType);
    const splitValue = strValue.split('-');
    const [Value, RentalTypeId] = splitValue;
    extendToIncome.RentalTypeId = parseToInt10(RentalTypeId);
    extendToIncome.OriginalIncomeType = parseToInt10(Value);
  }

  const IncomeTypeValue = isNzIncomeRentalSubtype
    ? extendToIncome.OriginalIncomeType
    : IncomeType;
  extendToIncome.IncomeTypeId = `${IncomeTypeValue},${ClientNo}`;

  Object.assign(newIncome, extendToIncome);
};

export const getLiabilityOwner = ({ clientList, owner }) => {
  const clientListLength = get(clientList, 'length', 0);
  const numofclient = get(owner, 'ClientNo');
  if (!numofclient || !clientListLength) {
    return [];
  }
  return [
    {
      numofclient,
      percentage: 100,
    },
  ];
};

export const getLiabilityForNzServiceabilityDates = ({
  liability,
  nzNewServiceability,
}) => {
  if (!liability || !nzNewServiceability) {
    return { ...liability };
  }
  const {
    LiabilityTypeID,
    DocumentedLoanTermMonth,
    DocumentedLoanTermYear,
    LoanStartDate,
  } = liability;
  const showLiabilityDates = !!(
    IS_LOANS.includes(LiabilityTypeID) &&
    LoanStartDate &&
    (DocumentedLoanTermYear || DocumentedLoanTermMonth)
  );
  const startDateLabel = formatDate(DATE_TEXT_FORMAT)(LoanStartDate);
  const count = DocumentedLoanTermMonth + DocumentedLoanTermYear * 12;
  const loanEndDateLabel = formatDate(DATE_TEXT_FORMAT)(
    computeDays('add', 'months')(LoanStartDate, count),
  );
  const termLabel = [
    ...((!!DocumentedLoanTermYear && [
      pluralize(DocumentedLoanTermYear, 'Year'),
    ]) ||
      []),
    ...((!!DocumentedLoanTermMonth && [
      pluralize(DocumentedLoanTermMonth, 'Month'),
    ]) ||
      []),
  ].join(' and ');
  return {
    ...liability,
    LoanStartDate: LoanStartDate ? new Date(LoanStartDate) : null,
    showLiabilityDates,
    startDateLabel,
    termLabel,
    loanEndDateLabel,
  };
};

export const getLiabilityForStudentLoan = ({
  liability,
  studentLoanDropdown,
  clientList,
}) => {
  const clientListLength = get(clientList, 'length', 0);
  if (!liability || !studentLoanDropdown || !clientListLength) {
    return { ...liability };
  }
  const { Ownership, LiabilityTypeID } = liability;
  const ownerNumber = get(Ownership, '[0].numofclient');
  if (!ownerNumber && LiabilityTypeID !== LIABILITY_TYPE.STUDENT_LOAN) {
    return { ...liability };
  }
  return {
    ...liability,
    showStudentLoanDropDown: true,
    Owner: clientList.find((client) => client.ClientNo === ownerNumber),
  };
};

const filterIDsFromOpportunityCalculationData = (data) => {
  return (
    (data && {
      ...data,
      incomeList:
        data.incomeList &&
        data.incomeList.map((obj) => ({
          ...obj,
          serviceabilityIncomeId: 0,
        })),
      liabilityList:
        data.liabilityList &&
        data.liabilityList.map((obj) => ({
          ...obj,
          serviceabilityLiabilityId: 0,
        })),
    }) ||
    {}
  );
};

export const setLoanAppOnOpportunity = ({ $scope, $state, uiService }) => {
  $scope.isServiceabilityFromLoanAppOnOpportunity =
    $state.params.paramType === OPPORTUNITY && uiService.viewOfAfileTurnedOn;
  return $scope.isServiceabilityFromLoanAppOnOpportunity;
};

export const mapDataFromOpportunity = ({ $scope, $state, uiService }) => ({
  opportunityCalculationData,
  loanOpportunityService,
}) => {
  if (
    $state.params.paramType !== OPPORTUNITY ||
    !uiService.viewOfAfileTurnedOn
  ) {
    return false;
  }
  const data = filterIDsFromOpportunityCalculationData(
    opportunityCalculationData,
  );
  $scope.familyMembersData = {
    ...$scope.familyMembersData,
    ...mapOpportunityCalculationValue(opportunityCalculationData),
  };
  const hasContentData = Object.keys(data).length;
  if (!hasContentData) {
    return;
  }
  return loanOpportunityService
    .saveServiceabilityFinancials(
      $state.params.paramId,
      $scope.brokerEventId,
      data,
    )
    .then(() => {
      $scope.getIncomeDetails();
    });
};

export function deleteIncome(income, newIncomeList, loanProfilerService) {
  return new Promise((resolve) => {
    if (Math.floor(income.IncomeID) === 0) {
      removeFromArray(income, newIncomeList);
      resolve(true);
    } else {
      loanProfilerService
        .deleteServiceabilityIncome(income.IncomeId)
        .then((res) => {
          resolve(res);
        });
    }
  });
}

export const getHeadingTooltip = (column) => {
  switch (column) {
    case 'UMI':
      return 'Uncommitted Monthly Income';
    case 'Income':
      return 'Total Net Monthly Income';
    case 'Expenses':
      return 'Total Monthly Living Expenses';
    default:
      return '';
  }
};

export const getColumnCount = (showAdditionFieldAndNzServiceablity) => {
  const AU_COL_COUNT = 9;
  const DEFAULT_COL_COUNT = 6;
  return showAdditionFieldAndNzServiceablity ? AU_COL_COUNT : DEFAULT_COL_COUNT;
};

export const getProviderIdByLenderId = (providerList, lenderId) => {
  const provider = providerList.find(
    (prov) => parseToInt10(prov.lenderId) === parseToInt10(lenderId),
  );
  return (provider && provider.providerId) || null;
};

export const redirectToLenderInfo = ($state, $scope) => (lenderId) => {
  if (!$state || !$scope || !$scope.providerList || !lenderId) {
    return;
  }
  const providerId = getProviderIdByLenderId($scope.providerList, lenderId);

  if (!providerId) {
    return;
  }

  const currentStateParams = cloneDeep($state.params);
  currentStateParams.origin = $scope.origin;
  currentStateParams.isFromLoanProfiler = true;
  $scope.loanProfilerSharedData.setLoanProfilerState(currentStateParams);
  $state.go('app.loanProviderInformation', { providerId });
};

export const loadProviderInfo = ({
  $scope,
  $state,
  providerInformationService,
}) => {
  const currentTab =
    $scope.origin && $scope.origin.replace(' ', '').toLowerCase();
  if (($state.params.tab && $state.params.tab.toLowerCase()) !== currentTab) {
    return;
  }

  providerInformationService
    .getProvidersInformation()
    .then((response) => {
      if (!response || !response.length) {
        return;
      }

      $scope.providerList = response;
      $scope.redirectToLenderInfo = redirectToLenderInfo($state, $scope);
    })
    .catch(catchError);
};

export const initShowLiabilityInputs = ({ $scope, nzNewServiceability }) => {
  $scope.LIABILITY_TYPE = LIABILITY_TYPE;
  $scope.showRepayment = (liabilityTypeID) => {
    return nzNewServiceability
      ? !IS_LIMITS.includes(liabilityTypeID)
      : liabilityTypeID !== LIABILITY_TYPE.CREDIT_CARD;
  };
  $scope.showBalance = (liability) => {
    const { LiabilityTypeID, isLimit } = liability;
    return nzNewServiceability
      ? !IS_LIMITS.includes(LiabilityTypeID)
      : !isLimit;
  };
  $scope.showDates = (liabilityTypeID) => {
    const isDates = nzNewServiceability ? IS_LOANS : [];
    return isDates.includes(liabilityTypeID);
  };
  $scope.showInterestRate = (liabilityTypeID) => {
    const isInterestRates = nzNewServiceability
      ? IS_LOANS.concat(IS_LOANS, IS_INTERESTS)
      : [];
    return isInterestRates.includes(liabilityTypeID);
  };

  $scope.isRepaymentRequired = (liabilityTypeID) =>
    nzNewServiceability ? !IS_INTERESTS.includes(liabilityTypeID) : true;
  $scope.isBalanceRequired = (liabilityTypeID) =>
    nzNewServiceability
      ? !IS_LIMITS.concat(IS_INTERESTS).includes(liabilityTypeID)
      : true;
  $scope.isLimitRequired = (liabilityTypeID) =>
    nzNewServiceability
      ? IS_LIMITS.concat(IS_INTERESTS).includes(liabilityTypeID)
      : true;
};

export const showNzNewServiceabilityWarnings = ({
  $scope,
  nzNewServiceability,
}) => {
  $scope.expensesWarning = (lender) => {
    const { LenderName } = lender;
    return `Oops! We can't calculate accurate household expenses as ${LenderName} uses expense calculations for families up to 2 adults only`;
  };
  $scope.showExpensesWarning = (lender) => {
    const { LenderID } = lender;
    const lendersToShowWarning = [NZ_LENDER.BNZ, NZ_LENDER.COOP];
    const adultCount = get($scope, 'familyMembersData.NoOfSpouse', 0);
    return (
      nzNewServiceability &&
      ADULT_LIMIT < adultCount &&
      lendersToShowWarning.includes(LenderID)
    );
  };
  $scope.workSheetWarning = (lender) => {
    const { LenderName } = lender;
    return `${LenderName}'s lender worksheets only allow for the income of 2 adults, so only the first 2 adults will appear on the generated worksheet`;
  };
  $scope.showWorkSheetWarning = (lender) => {
    const { LenderID } = lender;
    const lendersToShowWarning = [NZ_LENDER.ANZ, NZ_LENDER.WESTPAC];
    const adultCount = get($scope, 'familyMembersData.NoOfSpouse', 0);
    return (
      nzNewServiceability &&
      ADULT_LIMIT < adultCount &&
      lendersToShowWarning.includes(LenderID)
    );
  };
  $scope.onClientNumberChange = (isMobile = false) => {
    if (!$scope.familyMembersData) {
      return toastError();
    }
    const { NoOfSpouse } = $scope.familyMembersData;
    if (NoOfSpouse >= $scope.maxAdultCount) {
      $scope.familyMembersData.NoOfSpouse = MAX_ADULT - 1;
      const title = 'Maximum Allowed Clients';
      const message = `Only 4 Clients are Allowed`;
      return toastInfo(message, title);
    }
    return !isMobile && $scope.updateFamilyMembers();
  };
  $scope.maxAdultCount = nzNewServiceability ? MAX_ADULT : 10;
};

export const getCalculation = ({
  $scope,
  loanProfilerService,
  accreditedOnly,
  isSavedCalc,
  clientIds,
}) => {
  $scope.lenderSelected = false;
  const params = {
    brokerEventID: $scope.brokerEventId,
    loanScenarioId: $scope.loanScenarioId,
    accreditedOnly,
    clientIds,
  };
  loanProfilerService
    .getCalculation(params)
    .then((data) => {
      $scope.getLendersList(data, isSavedCalc);
    })
    .catch(catchError);
};

export const setupLenderRedirect = ({
  origin = 'Product Finder',
  $scope,
  $state,
  currentUserService,
  loanProfilerSharedData,
  providerInformationService,
}) => {
  $scope.origin = origin;
  $scope.currentUserService = currentUserService;
  $scope.loanProfilerSharedData = loanProfilerSharedData;
  loadProviderInfo({ $scope, $state, providerInformationService });
};

export const updateDefaultForDeal = ({
  $scope,
  familyMembersData,
  proposedLoanAmount,
  securityAmount,
}) => {
  if (!$scope.isServiceabilityFromLoanAppOnOpportunity) {
    return;
  }
  $scope.familyMembersData.LoanAmount =
    $scope.familyMembersData.LoanAmount ||
    parseToInt10(proposedLoanAmount) ||
    0;
  $scope.familyMembersData.SecurityAmount =
    $scope.familyMembersData.SecurityAmount ||
    parseToInt10(securityAmount) ||
    0;

  const isLoanAmountChanged =
    $scope.familyMembersData.LoanAmount !== familyMembersData.LoanAmount;
  const isSecurityAmountChanged =
    $scope.familyMembersData.SecurityAmount !==
    familyMembersData.SecurityAmount;
  const hasChanges = isLoanAmountChanged || isSecurityAmountChanged;
  hasChanges && $scope.updateFamilyMembers();
};

export const getLoanPurposeInServiceability = ({ $scope, optionsService }) => {
  optionsService.getServiceabilityPurposeTypes().then(({ data }) => {
    $scope.LoanPurposeList = data;
  });
};

export const getPrimaryPurposeTypeOfPurpose = (purposeId) => {
  if (OWNER_OCCUPIED_PURPOSE_TYPES.includes(purposeId)) {
    return PRIMARY_PURPOSE_TYPE.OWNER_OCCUPIED;
  } else if (INVESTMENT_PURPOSE_TYPES.includes(purposeId)) {
    return PRIMARY_PURPOSE_TYPE.INVESTMENT;
  } else if (BUSINESS_PURPOSE_TYPES.includes(purposeId)) {
    return PRIMARY_PURPOSE_TYPE.BUSINESS;
  } else {
    return '';
  }
};
