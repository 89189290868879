import angular from 'angular';
import $ from 'jquery';
import { RESIZE_SUMMARY_TABS } from 'Common/constants/broadcast';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .directive('summaryTabs', function summaryTabs(
      $window,
      $timeout,
      $document,
    ) {
      $.fn.reverse = [].reverse;
      // eslint-disable-next-line sonarjs/cognitive-complexity
      const sumTab = (scope, element) => {
        angular.element($window.document).ready(() => {
          const tabsContainer = element.length >= 1 ? element[0] : null;
          let currentParentWidth = 0;
          scope.narrowNav = false;
          const toggleOverflowTabs = () => {
            scope.narrowNav = $('#hiddentablist li').length < 1;
          };
          const checkTabs = () => {
            const screenWidth = $(window).width();
            const existingTab = [];
            const domParentTab = $('.nav-tabs > li.contact-parent-tab');
            const parentTab = domParentTab.filter((index, value) => {
              const skipCurrentElement = true;
              const elementHasId = value && value.id;
              const isExistingTab = elementHasId
                ? existingTab.includes(value.id)
                : skipCurrentElement;
              if (!isExistingTab) {
                existingTab.push(value.id);
              }
              return !isExistingTab;
            });
            if (screenWidth >= 975) {
              currentParentWidth = tabsContainer.clientWidth;
              let totalWidth = 0;
              parentTab.each(function () {
                totalWidth += parseInt($(this).innerWidth(), 10);
              });
              totalWidth += 5;
              if (currentParentWidth <= totalWidth) {
                let widthDifference = totalWidth - currentParentWidth;
                const reverseParentTab = parentTab.reverse().get();
                reverseParentTab.forEach((item) => {
                  const lastItem = $(item);
                  const currentItemWidth = lastItem.innerWidth();
                  if (widthDifference < 1) {
                    return;
                  }
                  widthDifference -= currentItemWidth;
                  lastItem.attr('data-width', $(item).outerWidth(true));
                  lastItem.prependTo($('.nav-tabs .more ul'));
                });
              } else {
                const elementList = $('.nav-tabs li.more li').get();
                let currentTotalWidth = totalWidth;
                elementList.forEach((item) => {
                  // eslint-disable-next-line unicorn/prefer-query-selector
                  const elem = $document[0].getElementById(item.id);
                  currentTotalWidth += parseInt(
                    elem.getAttribute('data-width'),
                    10,
                  );
                  if (currentTotalWidth >= currentParentWidth) {
                    return;
                  }
                  $(item).insertBefore($('.nav-tabs .more'));
                });
              }
              toggleOverflowTabs();
            }
          };
          const onResize = () => {
            $timeout(() => {
              checkTabs();
            }, 300);
          };
          const cleanUp = () => {
            angular.element($window).off('resize', onResize);
          };
          $timeout(() => {
            angular.element($window).trigger('resize');
          }, 1000);
          checkTabs();
          angular.element($window).on('resize', onResize);
          scope.$on('$destroy', cleanUp);
          scope.$on(RESIZE_SUMMARY_TABS, onResize);
        });
      };
      return {
        restrict: 'A',
        link: sumTab,
      };
    });
