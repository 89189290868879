import { TAB_TEXT_ID, FILE_TAB_ORDER } from 'Common/constants/opportunity';
import { getTabOrder } from 'Common/utilities/loanOpportunity';
import {
  DISPLAY_FACT_FIND,
  DISPLAY_DOCUMENTS,
} from 'Components/opportunity/util/constants';

class OpportunityMainCtrl {
  constructor(leadOpportunitiesService, uiService, $scope) {
    'ngInject';

    this.leadOpportunitiesService = leadOpportunitiesService;
    this.uiService = uiService;
    this.$scope = $scope;
    this.factFindEventCleanUp = null;
  }

  $onInit() {
    this.TAB_TEXT_ID = TAB_TEXT_ID;

    const leadsOppTitle = this.uiService.viewOfAfileTurnedOn
      ? 'Summary'
      : 'Opportunity';
    this.opportunityTabHeaderTitle = this.loanAppOnOpportunities
      ? leadsOppTitle
      : 'DETAILS';
    this.applicationTabHeaderTitle = this.uiService.viewOfAfileTurnedOn
      ? 'Application'
      : 'Fact Find';
    this.tabOrder = {
      summary: this.getTabOrder(TAB_TEXT_ID.SUMMARY),
      factFind: this.getTabOrder(TAB_TEXT_ID.FACT_FIND),
      calculations: this.getTabOrder(TAB_TEXT_ID.SUMMARY),
      communications: this.getTabOrder(TAB_TEXT_ID.COMMUNICATIONS),
      notes: this.getTabOrder(TAB_TEXT_ID.NOTES),
      documents: this.getTabOrder(TAB_TEXT_ID.DOCUMENTS),
      tasks: this.getTabOrder(TAB_TEXT_ID.TASKS),
    };

    this.factFindEventCleanUp = this.$scope.$on(DISPLAY_FACT_FIND, () => {
      this.activeTab = FILE_TAB_ORDER.FACT_FIND;
    });
    this.documentsEventCleanUp = this.$scope.$on(DISPLAY_DOCUMENTS, () => {
      this.activeTab = FILE_TAB_ORDER.DOCUMENTS;
    });
  }

  getTabOrder(tabTextId) {
    const order = getTabOrder(this.uiService.viewOfAfileTurnedOn, tabTextId);
    return { order };
  }

  $onDestroy() {
    this.factFindEventCleanUp && this.factFindEventCleanUp();
    this.documentsEventCleanUp && this.documentsEventCleanUp();
  }
}

export default OpportunityMainCtrl;
