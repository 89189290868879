export const FINANCIAL_FREQUENCY = {
  YEARLY: 1,
  HALF_YEARLY: 2,
  QUARTERLY: 3,
  MONTHLY: 4,
  FORTNIGHTLY: 5,
  WEEKLY: 6,
};

export const FINANCIAL_FREQUENCY_TEXT = {
  1: 'Yearly',
  3: 'Quarterly',
  4: 'Monthly',
  5: 'Fortnightly',
  6: 'Weekly',
};

export const FINANCIAL_REPAYMENT_FREQUENCY = {
  [FINANCIAL_FREQUENCY.YEARLY]: 1,
  [FINANCIAL_FREQUENCY.QUARTERLY]: 4,
  [FINANCIAL_FREQUENCY.MONTHLY]: 12,
  [FINANCIAL_FREQUENCY.FORTNIGHTLY]: 26,
  [FINANCIAL_FREQUENCY.WEEKLY]: 52,
};

export const FINANCIAL_TYPE = {
  ASSET: 'Asset',
  EXPENSE: 'Expense',
  INCOME: 'Income',
  LIABILITY: 'Liability',
  REAL_ESTATE_ASSET: 'Real Estate',
};

export const EXPENSE_TYPE = {
  RENT: '40',
};
