import CONTACT_REQUESTER from 'Common/constants/addNewContactRequesters';
import {
  ENTITY_PARTY_TYPES,
  PERSON_PARTY_TYPES,
} from 'Common/constants/partyTypes';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { objectLength } from 'Common/utilities/objectValidation';
import { toastError } from 'Common/utilities/alert';

export default class HelperFunctions {
  constructor($q, $uibModal, contactService, utilitiesService) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.contactService = contactService;
    this.utilitiesService = utilitiesService;
    this.$q = $q;

    this.declareHelperFunctions();

    this.openApplicantModal = this.openApplicantModal.bind(this);
    this.openCompanyModal = this.openCompanyModal.bind(this);
  }

  openApplicantModal(
    person,
    type,
    listApplicants = {},
    assignedAdviserIdFromRequester,
  ) {
    return this.$uibModal.open({
      templateUrl:
        '/assets/views/loanApplication/clientAssess/modal/addApplicants_v2.html',
      controller: 'ApplicantModalCtrl',
      size: 'lg',
      resolve: {
        selectedPartyType: () =>
          type ? `${type}` : `${PERSON_PARTY_TYPES.APPLICANT}`,
        type: () => (type ? `${type}` : `${PERSON_PARTY_TYPES.APPLICANT}`),
        person: () => person,
        requester: () => CONTACT_REQUESTER.REQUESTER.NEW_LOAN_APP_MODAL,
        listApplicants: () => listApplicants,
        assignedAdviserIdFromRequester: () => +assignedAdviserIdFromRequester,
        listSoleTraders: () => [],
      },
      backdrop: 'static',
      keyboard: false,
    });
  }

  openCompanyModal(entity = [], partyType = 0) {
    const finalEntity = entity.length ? entity[0] : {};
    return this.$uibModal.open({
      templateUrl:
        '/assets/views/loanApplication/clientAssess/modal/personCompany.add.html',
      controller: 'AddCompanyRelatedModalCtrl',
      size: 'md',
      resolve: {
        selectedPartyType: () =>
          partyType ? `${partyType}` : `${ENTITY_PARTY_TYPES.COMPANY}`,
        person: () => finalEntity,
        requester: () => CONTACT_REQUESTER.REQUESTER.NEW_LOAN_APP_MODAL,
        guardianList: () => [],
      },
      backdrop: 'static',
      keyboard: false,
    });
  }

  declareHelperFunctions() {
    // Helper functions with no services declaration
    this.isAdviser = (currentUser = {}) => {
      if (!objectLength(currentUser)) {
        return 0;
      }
      return (
        currentUser.accessType === ACCESS_TYPE.ADVISER ||
        currentUser.accessType === ACCESS_TYPE.PRINCIPAL_ADVISER
      );
    };

    this.getDefaultAdviser = (currentUser = {}) => {
      if (!objectLength(currentUser)) {
        return 0;
      }
      if (this.isAdviser(currentUser)) {
        return currentUser.familyId;
      }
      return 0;
    };

    this.addFamily = (familiesList, family) => {
      familiesList.concat(family);
    };

    this.getIncludedFamily = (excludedId, family, prop) => {
      return family.filter(
        (o) => o[prop] !== excludedId[0] && o.Role === 'Adult',
      );
    };

    this.personModel = (member, familyId) => {
      return {
        ...member,
        FamilyId: familyId,
        initials: this.utilitiesService.filterInitial(
          member.FirstName,
          member.LastName,
        ),
        ClientEntityId: member.PersonId,
        AddRelatedChildren: false,
        ClientName: member.FullName,
        IsClient: true,
      };
    };

    this.entityModel = (entity, familyId) => {
      return {
        ...entity,
        FamilyId: familyId,
        initials: this.utilitiesService.filterInitialOneString(entity.Name),
        ClientEntityId: entity.OrganisationId,
        AddRelatedChildren: false,
        ClientName: entity.Name,
        IsClient: false,
      };
    };

    this.adviserModel = (adviser) => {
      const adviserName = adviser.fullName || adviser.FullName;
      return {
        ...adviser,
        adviserName,
        AdviserFamilyId:
          adviser.BrokerId || adviser.FamilyId || adviser.familyId,
        familyId: adviser.BrokerId || adviser.FamilyId || adviser.familyId,
      };
    };

    this.validateData = (formData, adviser) => {
      const defer = this.$q.defer();
      const { families, entities } = formData.borrowers;
      const isValidBorrowers = !!(families.length || entities.length);
      const isValidAdviser = !!(objectLength(adviser) && adviser.familyId);

      if (!isValidBorrowers) {
        toastError('Please put at least 1 borrower');
      }
      if (!isValidAdviser) {
        toastError('Please assign an adviser');
      }

      defer.resolve(isValidBorrowers && isValidAdviser);
      return defer.promise;
    };

    this.convertFormDataToAPIData = (listObject) => {
      if (!listObject) {
        return false;
      }
      const arrayObject = [];
      listObject.forEach((family) => {
        family.forEach((member) => {
          const {
            ClientEntityId,
            AddRelatedChildren,
            IsClient,
            FamilyId,
            ClientName: DisplayName,
            IsFirstHomeBuyer,
          } = member;
          arrayObject.push({
            ClientEntityId,
            AddRelatedChildren,
            IsClient,
            FamilyId,
            DisplayName,
            IsFirstHomeBuyer,
          });
        });
      });
      return arrayObject;
    };
  }
}
