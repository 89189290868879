import { getGoalSetterUrl } from 'Common/utilities/goalSetter';

class GoalSetterSidebarCtrl {
  constructor($window, onlineFactFindModal, contactModelService) {
    'ngInject';

    this.window = $window;
    this.onlineFactFindModal = onlineFactFindModal;
    this.contactModelService = contactModelService;
  }

  $onInit() {
    this.goalSetterUrl = '';
    this.isLoading = false;
  }

  launchGoalSetterUrlOnNewTab(url) {
    if (url) {
      this.window.open(url, '_blank', 'noopener, noreferrer');
    }
  }

  launchGoalSetter() {
    if (!this.goalSetterUrl) {
      this.isLoading = true;
      this.contactModelService
        .getFamilyAdviserInfo(this.familyId)
        .then((response) => {
          this.isLoading = false;
          if (response.consumerUrl) {
            const params = {
              url: response.consumerUrl,
              brandingCategoryID: response.brandingCategoryID,
              tradingName: response.tradingName,
              loanAppId: this.loanAppId,
            };
            this.goalSetterUrl = getGoalSetterUrl(params);
          }
          this.launchGoalSetterUrlOnNewTab(this.goalSetterUrl);
        });
      return;
    }

    this.launchGoalSetterUrlOnNewTab(this.goalSetterUrl);
  }
}

export default GoalSetterSidebarCtrl;
