export const SEND_TO_TABS = {
  APPLICANTS: 'APPLICANTS',
  GUARANTORS: 'GUARANTORS',
};

export const AU_GAME_PLAN_SECTIONS = [
  {
    id: 1,
    name: 'Cover page',
    apiKey: 'CoverPage',
    isHidden: false,
    isToggleable: true,
  },
  {
    id: 2,
    name: 'Broker message',
    apiKey: 'BrokerMessage',
    isEditable: true,
    isDefaultActive: true,
    isHidden: false,
    isToggleable: true,
  },
  {
    id: 3,
    name: 'Your goals',
    apiKey: 'Goals',
    isHidden: true,
    isToggleable: false,
  },
  {
    id: 10,
    name: 'Selected product',
    apiKey: 'SelectedProduct',
    isHidden: true,
    isToggleable: false,
  },
  {
    id: 11,
    name: 'Rates, fees and commissions',
    apiKey: 'RateFeesCommission',
    isHidden: true,
    isToggleable: false,
  },
  {
    id: 7,
    name: 'Your funds position',
    apiKey: 'FundsPosition',
    isHidden: true,
    isToggleable: false,
  },
  {
    id: 6,
    name: 'Concierge Moving Solutions',
    apiKey: 'Concierge',
    isHidden: false,
    isToggleable: true,
  },
  {
    id: 9,
    name: 'Product comparison',
    apiKey: 'ProductComparison',
    isHidden: true,
    isToggleable: false,
  },
  {
    id: 5,
    name: 'Client details',
    apiKey: 'ClientDetails',
    isHidden: true,
    isToggleable: false,
  },
  {
    id: 13,
    name: 'Terms, disclaimers, acknowledgements',
    apiKey: 'Appendix',
    isHidden: true,
    isToggleable: false,
  },
  {
    id: 12,
    name: 'Time to sign',
    apiKey: 'TimeToSign',
    isHidden: true,
    isToggleable: false,
  },
];

export const NZ_GAME_PLAN_SECTIONS = [
  {
    id: 1,
    name: 'Cover page',
    apiKey: 'CoverPage',
    isHidden: false,
    isToggleable: true,
  },
  {
    id: 2,
    name: 'Adviser message',
    apiKey: 'BrokerMessage',
    isEditable: true,
    isDefaultActive: true,
    isHidden: false,
    isToggleable: true,
  },
  {
    id: 3,
    name: ['Your goals', 'Applicant goals'],
    apiKey: 'Goals',
    isHidden: true,
    isToggleable: false,
  },
  {
    id: 10,
    name: 'Selected product',
    apiKey: 'SelectedProduct',
    isHidden: true,
    isToggleable: false,
  },
  {
    id: 11,
    name: 'Rates, fees and commissions',
    apiKey: 'RateFeesCommission',
    isHidden: true,
    isToggleable: false,
  },
  {
    id: 7,
    name: 'Your funds position',
    apiKey: 'FundsPosition',
    isHidden: true,
    isToggleable: false,
  },
  {
    id: 6,
    name: 'Other considerations',
    apiKey: 'Concierge',
    isHidden: true,
    isToggleable: false,
  },
  {
    id: 9,
    name: 'Product comparison',
    apiKey: 'ProductComparison',
    isHidden: false,
    isToggleable: true,
  },
  {
    id: 5,
    name: ['Client details', 'Guarantor details'],
    apiKey: 'ClientDetails',
    isHidden: true,
    isToggleable: false,
  },
  {
    id: 13,
    name: 'Terms, disclaimers, acknowledgements',
    apiKey: 'Appendix',
    isHidden: true,
    isToggleable: false,
  },
  {
    id: 12,
    name: 'Time to sign',
    apiKey: 'TimeToSign',
    isHidden: true,
    isToggleable: false,
  },
];

export const GAME_PLAN_SECTIONS = {
  AU: AU_GAME_PLAN_SECTIONS,
  NZ: NZ_GAME_PLAN_SECTIONS,
};
