import {
  insuranceFileBuilderForUI,
  insuranceFileBuilderForAPI,
} from 'Common/mappers/insuranceFile';

class InsuranceFileService {
  constructor($q, httpClient) {
    'ngInject';

    this.$q = $q;
    this.httpClient = httpClient;
    this.apiBaseUrl = 'insurance-file';
  }

  postInsuranceFile(data) {
    return this.httpClient
      .post(this.apiBaseUrl, insuranceFileBuilderForAPI(data))
      .then(({ data }) => data);
  }

  getInsuranceFiles(familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/list?familyId=${familyId}`)
      .then(({ data = [] }) => data?.map?.(insuranceFileBuilderForUI));
  }

  deleteInsuranceFile(id) {
    return this.httpClient.delete(`${this.apiBaseUrl}?insuranceFileId=${id}`);
  }

  putInsuranceFile(data) {
    return this.httpClient
      .put(this.apiBaseUrl, insuranceFileBuilderForAPI(data))
      .then(({ data }) => data);
  }

  deleteInsuranceFileDocument(id, documentId) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/${id}/document?documentId=${documentId}`,
    );
  }
}

export default InsuranceFileService;
