import angular from 'angular';
import _ from 'lodash';
import { MERGE_FIELDS } from 'Common/constants/mergeFields';
import { ADDRESS_TYPE } from 'Common/constants/addressModules';
import { MERGE_FIELD_CATEGORY } from 'Common/constants/mergeFieldCategory';
import { shortenAmount } from 'Common/utilities/currency';
import { characterToHTMLEntity } from 'Common/utilities/characterToHTMLEntity';
import { escapeCharactersToHTMLTag } from 'Common/utilities/escapeCharactersToHTMLTag';
import moment from 'moment';
import {
  replaceDataPlaceholder,
  translateLoanMergeFields,
} from 'Assets/js/services/util/mailTemplateServiceUtil';
class MailTemplateService {
  constructor(
    $q,
    $filter,
    corporateService,
    contactService,
    uiService,
    templateService,
  ) {
    this.$q = $q;
    this.$filter = $filter;
    this.corporateService = corporateService;
    this.contactService = contactService;
    this.uiService = uiService;
    this.templateService = templateService;
    this.defaultTemplateName = 'New Mail Template';
  }

  translateMailTemplate(mergeFieldData, actualData = null) {
    const defer = this.$q.defer();
    if (mergeFieldData && mergeFieldData.familyId) {
      this.translateMailTemplateMergeFields(mergeFieldData, defer, actualData);
    } else {
      defer.reject({ data: 'Invalid arguments' });
    }
    return defer.promise;
  }

  translateMailTemplateMergeFields(mergeFieldData, defer, actualData = null) {
    const {
      familyId,
      client,
      loan,
      loanStructureId,
      policyId,
      isSMS,
    } = mergeFieldData;
    const clientId = (client && client.PersonId) || client || 0;
    const loanId = loan ? loan.LoanID : 0;

    if (actualData) {
      return replaceDataPlaceholder(mergeFieldData, defer, actualData, this);
    }
    this.corporateService
      .MergeFieldsGet(
        familyId,
        clientId,
        loanId,
        policyId,
        isSMS,
        loanStructureId,
      )
      .then(
        (response) => {
          return replaceDataPlaceholder(
            mergeFieldData,
            defer,
            response.data,
            this,
          );
        },
        (error) => {
          return defer.reject(error);
        },
      );
  }

  getMergeFieldsValues(mergeFields, category) {
    const mergeFieldsGroup = _.find(
      mergeFields,
      (mergeFieldsGroup) => mergeFieldsGroup.Category === category,
    );
    return mergeFieldsGroup ? mergeFieldsGroup.MergeFields : null;
  }

  translateContactsMergeFields(contact, mergeFieldsValue, templateContent) {
    if (mergeFieldsValue && this.isTemplateContentValid(templateContent)) {
      templateContent = this.applyMergeFieldsValueToTemplate(
        mergeFieldsValue,
        templateContent,
      );
      const isContactAddrValid =
        contact && contact.Address && contact.Address.length;
      if (isContactAddrValid) {
        this.translateHomeAddrMergeFields(
          mergeFieldsValue,
          templateContent,
          contact.Address,
        );
        this.translateMailingAddrMergeFields(
          mergeFieldsValue,
          templateContent,
          contact.Address,
        );
      }
    }
    return templateContent;
  }

  translateSubjectMergeFields(templateSubject, mergeFields, client) {
    if (!templateSubject) return templateSubject;
    let translatedSubject = templateSubject.trim();
    const contactMergeFieldValues = this.getMergeFieldsValues(
      mergeFields,
      MERGE_FIELD_CATEGORY.CONTACT,
    );
    const otherMergeFieldValues = this.getMergeFieldsValues(
      mergeFields,
      MERGE_FIELD_CATEGORY.OTHER,
    );
    translatedSubject = this.translateContactsMergeFields(
      client,
      contactMergeFieldValues,
      translatedSubject,
    );
    translatedSubject = this.translateOtherMergeFields(
      otherMergeFieldValues,
      translatedSubject,
    );
    return translatedSubject;
  }

  translateHomeAddrMergeFields(mergeFieldsValue, content, contactAddr) {
    const homeAddrFieldTranslated = this.mergeFieldHasValue(
      mergeFieldsValue,
      MERGE_FIELDS.FULL_ADDR_BLOCK_HOME,
    );
    if (homeAddrFieldTranslated) return;

    const homeAddr = _.find(
      contactAddr,
      (addr) => parseInt(addr.TypeId, 10) === ADDRESS_TYPE.CURRENT_ADDRESS,
    );
    if (!homeAddr) return;

    const translatedContent = this.translateTemplateContentByField(
      content,
      MERGE_FIELDS.FULL_ADDR_BLOCK_HOME,
      homeAddr.formatted_address,
    );
    return translatedContent;
  }

  translateMailingAddrMergeFields(mergeFieldsValue, content, contactAddr) {
    const mailingAddrTranslated = this.mergeFieldHasValue(
      mergeFieldsValue,
      MERGE_FIELDS.FULL_ADDR_BLOCK_MAILING,
    );
    if (!mailingAddrTranslated) return;

    const mailingAddr = _.find(contactAddr, (addr) => addr.isMailing);
    if (!mailingAddr) return;

    const translatedContent = this.translateTemplateContentByField(
      content,
      MERGE_FIELDS.FULL_ADDR_BLOCK_MAILING,
      mailingAddr.formatted_address,
    );
    return translatedContent;
  }

  translateLoanMergeFields(loan, mergeFieldsValue, templateContent) {
    return translateLoanMergeFields(
      loan,
      mergeFieldsValue,
      templateContent,
      this,
    );
  }

  translateTemplateContentByField(content, mergeField) {
    const oldFieldPlaceholder = `[${mergeField.Name}]`;
    const newFieldPlaceholder = `{${mergeField.Name}}`;
    const hasOldFieldPlaceholder = content.indexOf(oldFieldPlaceholder) !== -1;
    const hasNewFieldPlaceholder = content.indexOf(newFieldPlaceholder) !== -1;
    let translatedContent = content;

    if (hasOldFieldPlaceholder && mergeField.Value) {
      translatedContent = content
        .split(oldFieldPlaceholder)
        .join(mergeField.Value);
    }
    if (hasNewFieldPlaceholder && mergeField.Value) {
      translatedContent = content
        .split(newFieldPlaceholder)
        .join(mergeField.Value);
    }
    return translatedContent;
  }

  translateOtherMergeFields(mergeFieldsValue, templateContent) {
    if (mergeFieldsValue && this.isTemplateContentValid(templateContent)) {
      templateContent = this.applyMergeFieldsValueToTemplate(
        mergeFieldsValue,
        templateContent,
      );
    }
    return templateContent;
  }
  translateInsuranceMergeFields(mergeFieldsValue, templateContent) {
    if (mergeFieldsValue && this.isTemplateContentValid(templateContent)) {
      templateContent = this.applyMergeFieldsValueToTemplate(
        mergeFieldsValue,
        templateContent,
      );
    }
    return templateContent;
  }
  applyMergeFieldsValueToTemplate(mergeFieldsValue, templateContent) {
    _.each(mergeFieldsValue, (field) => {
      field.Name = characterToHTMLEntity(field.Name);
      field.Value = escapeCharactersToHTMLTag(field.Value);
      templateContent = templateContent
        .split(`{${field.Name}}`)
        .join(field.Value);
      templateContent = templateContent
        .split(`[${field.Name}]`)
        .join(field.Value);
    });
    return templateContent;
  }

  mergeFieldHasValue(mergeFields, fieldName) {
    return _.some(
      mergeFields,
      (field) => field.Name === fieldName && field.Value && field.Value.trim(),
    );
  }

  templateHasLoanMergeFields(mergeFields, template) {
    if (mergeFields && template) {
      return _.some(mergeFields, (field) => {
        const mergeFieldPlaceholder = `{${field.Name}}`;
        const oldPlaceholder = `[${field.Name}]`;
        return (
          template.includes(mergeFieldPlaceholder) ||
          template.includes(oldPlaceholder)
        );
      });
    }
    return false;
  }

  isTemplateContentValid(templateContent) {
    return templateContent && templateContent.length;
  }

  isLoanForMailContentValid(templateContent, loan) {
    const defer = this.$q.defer();
    if (this.mergeFields) {
      defer.resolve(
        this.isMailContentValid(templateContent, loan, this.mergeFields),
      );
    } else {
      this.corporateService.getMergeFields().then(
        (response) => {
          this.mergeFields = response.data;
          defer.resolve(
            this.isMailContentValid(templateContent, loan, response.data),
          );
        },
        () => {
          defer.resolve(templateContent && templateContent.trim());
        },
      );
    }
    return defer.promise;
  }

  isMailContentValid(templateContent, loan, mergeFields) {
    const loanMergeFields = this.getMergeFieldsValues(
      mergeFields,
      MERGE_FIELD_CATEGORY.LOANS,
    );
    return (
      !this.templateHasLoanMergeFields(loanMergeFields, templateContent) || loan
    );
  }

  isTemplateValid(template) {
    return (
      template &&
      template.UserTemplateName &&
      template.UserTemplateName.trim() &&
      template.UserTemplateContent &&
      template.UserTemplateContent.trim()
    );
  }

  getDefaultTemplateFor(folder, defaultContent) {
    return folder
      ? {
          UserTemplateName: this.defaultTemplateName,
          UserTemplateFolderId: folder.UserTemplateID,
          UserTemplateFolderName: folder.UserTemplateName,
          UserTemplateContent: defaultContent || null,
          IsExclusiveForCorpUser: this.uiService.isCorporateUser,
          isSharedWithAdviser: false,
          IsTemplateFolder: false,
          IsSystemTemplate: this.templateService.isBlankFolderOrTemplate(
            folder,
          ),
        }
      : null;
  }

  getPrimaryClientFrom(clientInformation) {
    return _.find(clientInformation, (client) => client.isPrimary);
  }

  getMergeFieldLoansSelection(familyId) {
    const defer = this.$q.defer();
    if (familyId) {
      this.contactService.loanListGet(familyId).then((response) => {
        if (response.data) {
          const clientLoansList = response.data.map((loan) => {
            const lender = loan.Lender ? loan.Lender.LenderName : 'TBA';
            const amount = this.getFormattedLoanAmount(loan);
            const statusName =
              loan.LoanStatus && loan.LoanStatus.LoanStatusName
                ? loan.LoanStatus.LoanStatusName
                : '';
            const settlementDate = this.getFormattedSettlementDate(loan);
            loan.LoanName = `${lender} (${amount}) ${statusName} ${settlementDate}`;
            return loan;
          });
          defer.resolve(clientLoansList);
        }
      });
    }
    return defer.promise;
  }

  getFormattedLoanAmount(loan) {
    return loan ? `$${shortenAmount(loan.LoanAmount, 2)}` : '';
  }

  getFormattedSettlementDate(loan) {
    return loan && loan.SettlementDate
      ? moment(loan.SettlementDate).format('MMM DD, YYYY')
      : '';
  }

  constructClientFullName(clientInformation) {
    let clientHeaderName = '';
    _.each(clientInformation, (client) => {
      if (client.Role && client.Role === 'Adult') {
        if (clientHeaderName && clientHeaderName.trim()) {
          clientHeaderName = `${clientHeaderName} & ${client.FirstName} ${client.LastName}`;
        } else if (clientInformation.length > 1) {
          clientHeaderName = `${clientHeaderName} ${client.FirstName}`;
        } else {
          clientHeaderName = `${client.FirstName} ${client.LastName}`;
        }
      }
    });
    return clientHeaderName;
  }

  constructNewTemplateFolder(folderName) {
    return {
      UserTemplateName: folderName,
      IsTemplateFolder: true,
      DocRtfSMSTemplateList: [],
    };
  }

  setTemplatesOrder(templates) {
    _.forEach(templates, (value, index) => {
      value.OrderBy = index + 1;
    });
    return templates;
  }
}

export const loadModule = () =>
  angular
    .module('app')
    .factory('mailTemplateService', [
      '$q',
      '$filter',
      'corporateService',
      'contactService',
      'uiService',
      'templateService',
      (
        $q,
        $filter,
        corporateService,
        contactService,
        uiService,
        templateService,
      ) =>
        new MailTemplateService(
          $q,
          $filter,
          corporateService,
          contactService,
          uiService,
          templateService,
        ),
    ]);
