import template from './fileHeader.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    fileGeneralDetails: '<',
    fileDetails: '<',
    applicants: '<',
    statusList: '<',
    fileStatus: '<',
    loanId: '<',
    onTitleEdit: '&',
    onAdviserSelect: '&',
    onAssistantSelect: '&',
    onStatusChange: '&',
    isUseUibDropdown: '<?',
  },
};
