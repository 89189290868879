import angular from 'angular';
import _ from 'lodash';
import moment from 'angular-moment';
import {
  countryCodeInputElement,
  dynamicCountryCodeInputIdNameSetter,
} from 'Common/utilities/countryCodeUtility';
import { toastError } from 'Common/utilities/alert';
import {
  updatePartyApplicantsOnEdit,
  formatPhoneListSubmission,
  assignContactSetPhone,
  isPhoneExisting,
  validatePhoneList,
  contactEmailValidation,
  initializeCountryCodePhoneVariables,
  addPhone,
  isInvalidWarning,
  initializeApplicantContactsetOnEdit,
  initializeContactsetOnCreate,
  submissionErrorAction,
  removeApplicantAction,
  validatePhoneOnAddSelectContact,
  isInternationalPhoneType,
} from 'Common/utilities/applicantModalUtilities';
import { emailValidation } from 'Common/utilities/emailUtilities';
import { formatExistingMobile } from 'Common/utilities/mobile';
import { REQUESTER } from 'Common/constants/addNewContactRequesters';
import { PERSON_PARTY_TYPES } from 'Common/constants/partyTypes';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import {
  setAssistantsList,
  onAdviserAutocompleteSelect,
  onAdviserSelect,
} from 'Common/utilities/allocation';
import { setLoanAppAssistant } from 'Common/utilities/loanApplication';
import {
  mobileCountryDropdown,
  assignSelectedCountryValue,
} from 'Assets/js/controllers/contacts/client/util/addClientModal';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('AddPersonModalCtrl', function AddPersonModalCtrl(
      $scope,
      $uibModalInstance,
      utilitiesService,
      toaster,
      contactService,
      selectedPartyType,
      person,
      loanScenarioService,
      $state,
      $rootScope,
      broadcastDataService,
      applicationSharedService,
      requester,
      allocatedAdviserList,
      assistantAdvisersList,
      AssignedAdviserId,
      requesterLabel,
      genderService,
      $q,
      contactSharedDataService,
      loanAppDetailsService,
      userFamilyId,
      lendingCategoryId,
      currentUserService,
      configService,
      optionsService,
      corporateService,
      countryCodeService,
    ) {
      $scope.countryCodeService = countryCodeService;
      initializeCountryCodePhoneVariables({
        dynamicCountryCodeInputIdNameSetter,
        $scope,
        currentUserService,
        configService,
        countryCodeService,
        mobileCountryDropdown,
      });

      $scope.addPhone = () => {
        addPhone({
          $scope,
          dynamicCountryCodeInputIdNameSetter,
          countryCodeService,
        });
      };

      $scope.isInternationalPhones = isInternationalPhoneType;

      // Controller code is based on loan app (with editing feature supported)
      $scope.allocatedAdviserList = allocatedAdviserList;
      $scope.assistantAdvisersList = assistantAdvisersList;
      $scope.AssignedAdviserId = AssignedAdviserId;
      $scope.applicantLabel = requesterLabel;

      $scope.ACCESS_TYPE = ACCESS_TYPE;
      $scope.assistantsList = [];
      $scope.allocation = {};
      $scope.getAssistantsList = setAssistantsList({ $scope, optionsService });
      $scope.onAdviserAutocompleteSelect = onAdviserAutocompleteSelect({
        $scope,
      });

      $scope.showAllocatedAdviser = () => {
        const vm = $scope;
        return (
          vm.getAdviserIfEditLoaded ||
          (vm.advisersList && vm.advisersList.length > 0) ||
          (vm.brokersList && vm.brokersList.length > 0) ||
          (vm.assistantAdvisersList && vm.assistantAdvisersList.length > 0) ||
          parseInt(vm.accessType, 10) === ACCESS_TYPE.CORPORATE
        );
      };
      $scope.getAssistantsList(AssignedAdviserId);

      // models
      angular.extend($scope, {
        partyApplicants: [],
        Settings: {
          MaritalStatusAdded: false,
        },
      });

      $scope.ContactSet = {};
      const countryCode = applicationSharedService.countryCodeAndBirthday(
        $rootScope.crmCountry,
      );
      const countryBirth = applicationSharedService.countryCodeAndBirthday(
        $rootScope.crmCountry,
      );
      // Default
      $scope.saveText = requesterLabel;
      $scope.Settings.SelectedParty = 'Applicants';
      if (person) {
        // We need to know which of the family members are included in the loan app
        // Task 1: We need to know what family object to use
        $scope.Settings.isEdit = true;
        let loanAppFamilyCon = [];
        _.forEach($scope.listApplicants, (family) => {
          if (_.isEqual(_.first(family).FamilyId, person.FamilyId)) {
            // take a copy of the family object
            loanAppFamilyCon = angular.copy(family);
          }
        });

        contactService.clientInformGet(person.FamilyId).then((family) => {
          let ApplicantNumber = 1;
          _.forEach(family.data, (member) => {
            if (member.Role === 'Adult') {
              contactService
                .clientInformGet(person.FamilyId, member.PersonId)
                .then((response) => {
                  const ContactSet = $scope.getNewContactModel('Adult');
                  if (
                    ContactSet.customerConsentOptions.ConsentMarketing !== true
                  ) {
                    ContactSet.customerConsentOptions.ConsentMarketing = false;
                  }
                  angular.extend(ContactSet, response.data[0]);
                  if (_.size(ContactSet.Email) > 0) {
                    ContactSet.EmailAddress = ContactSet.Email[0].EmailAddress;
                  }
                  if (_.isUndefined(ContactSet.IsResident)) {
                    ContactSet.IsResident = false;
                  }
                  // find country codes
                  let CitizenshipObj = _.find($scope.countryList, (o) => {
                    return (
                      parseInt(o.CountryID, 10) ===
                      parseInt(ContactSet.CitizenshipId, 10)
                    );
                  });
                  let CountryOfResidencyObj = _.find(
                    $scope.countryList,
                    (o) => {
                      return (
                        parseInt(o.CountryID, 10) ===
                        parseInt(ContactSet.CountryOfResidencyId, 10)
                      );
                    },
                  );
                  let CountryOfBirthObj = _.find($scope.countryList, (o) => {
                    return (
                      parseInt(o.CountryID, 10) ===
                      parseInt(ContactSet.CountryOfBirthId, 10)
                    );
                  });
                  if (!CitizenshipObj) {
                    CitizenshipObj = {
                      CountryCode: '',
                    };
                  }
                  if (!CountryOfResidencyObj) {
                    CountryOfResidencyObj = {
                      CountryCode: '',
                    };
                  }
                  if (!CountryOfBirthObj) {
                    CountryOfBirthObj = {
                      CountryCode: '',
                    };
                  }
                  // Task 2: If the member is included in the loanAppFamilyCon put isIncluded property = true, else false
                  const familyMember = _.find(loanAppFamilyCon, (mem) => {
                    return mem.PersonId === ContactSet.PersonId;
                  });
                  angular.extend(ContactSet, {
                    FamilyId: person.FamilyId,
                    CountryCode: CitizenshipObj.CountryCode,
                    ResidencyCountryCode: CountryOfResidencyObj.CountryCode,
                    CountryOfBirth: CountryOfBirthObj.CountryCode,
                    ResidencyId: ContactSet.ResidencyId.toString(),
                    MaritalStatusId: ContactSet.MaritalStatusId.toString(),
                    DOBGet: moment(ContactSet.DOB).format('DD/MM/YYYY'),
                    // DOB: utilitiesService.formatDate(ContactSet.DOB, true),
                    PartyType: selectedPartyType,
                    ApplicantNumber: ApplicantNumber++,
                    PreferredContactMethodId: ContactSet.PreferredContactMethodId.toString(),
                    isIncluded: !!familyMember, // to be used on tickbox
                    isExisting: !!familyMember, // to be used on knowing if it's currently existing
                  });
                  ContactSet.Phone = formatPhoneListSubmission({
                    phones: ContactSet.Phone,
                    formatExistingMobile,
                    $scope,
                    countryCodeService,
                  });
                  // Push it
                  initializeApplicantContactsetOnEdit({
                    ContactSet,
                    $scope,
                    countryCodeService,
                    dynamicCountryCodeInputIdNameSetter,
                    member,
                    person,
                  });
                });
            }
          });
        });
      } else {
        $scope.Settings.isEdit = false;
        $scope.ContactSet = contactSharedDataService.getContactDataModel(
          'Adult',
          countryCode,
          countryBirth,
        );
        $scope.ContactSet.PartyType = selectedPartyType;
        $scope.ContactSet.ApplicantNumber = 1;
        $scope.ContactSet.customerConsentOptions = { ConsentMarketing: true };
        initializeContactsetOnCreate($scope);
        $scope.partyApplicants.push($scope.ContactSet);
      }

      $scope.titles = [
        { Value: 'Mr', Name: 'Mr' },
        { Value: 'Mrs', Name: 'Mrs' },
        { Value: 'Ms', Name: 'Ms' },
        { Value: 'Miss', Name: 'Miss' },
        { Value: 'Master', Name: 'Master' },
        { Value: 'Doctor', Name: 'Doctor' },
      ];

      $scope.$watch('ContactSet.Title', (newVal) => {
        genderService.changeGender(newVal, $scope.ContactSet.Gender);
      });

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
      $scope.datePopUp = {
        DOB: false,
      };
      // Open Date Popup
      $scope.openDate = function (dateField) {
        $scope.datePopUp[dateField] = !$scope.datePopUp[dateField];
      };

      $scope.format = 'dd MMM yyyy';
      $scope.altInputFormats = ['M!/d!/yyyy'];
      $scope.dateOptions = {
        formatYear: 'yy',
        showWeeks: false,
      };

      // methods
      angular.extend($scope, {
        setMaritalStatus(bool) {
          $scope.Settings.MaritalStatusAdded = bool;
        },
        showApplicantPopover(bool) {
          $scope.Settings.isShowApplicantPopover = bool;
          if (!bool) {
            $scope.Settings.isShowApplicantDeletePopover = true;
          }
        },
        showApplicantDeletePopover(bool) {
          $scope.Settings.isShowApplicantDeletePopover = bool;
        },
        removeContact() {
          /* Remove Party Applicant if $scope.ContactSet.MaritalStatusId === 2 && partyApplicant > 1 */
          if (_.size($scope.partyApplicants) > 1) {
            $scope.partyApplicants.pop();
          }
        },
        addContact(type, isMarried) {
          const isIntlPhoneNumber = validatePhoneOnAddSelectContact($scope);
          const isAddContactPhoneValid = validatePhoneList({
            $scope,
            countryCodeInputElement,
            emptyNumberMessage: $scope.emptyNumberMessage,
            angular,
            countryCodeService,
            value: $scope.ContactSet,
          }).includes(false);
          if (
            isIntlPhoneNumber &&
            isInvalidWarning({
              isInvalid: isAddContactPhoneValid,
              toastError,
              errorMessage: $scope.errorMessage,
            })
          ) {
            return false;
          }
          assignContactSetPhone({
            $scope,
            countryCodeService,
          });
          updatePartyApplicantsOnEdit({ $scope });
          // eslint-disable-next-line sonarjs/no-small-switch
          switch (type) {
            case 'Applicant':
              if (_.size($scope.partyApplicants) < 2) {
                if (!isMarried) {
                  $scope.ContactSet = contactSharedDataService.getContactDataModel(
                    'Adult',
                    countryCode,
                    countryBirth,
                  );
                  $scope.ContactSet.PartyType = '1';
                  $scope.ContactSet.ApplicantNumber =
                    _.size($scope.partyApplicants) + 1;
                  $scope.ContactSet.customerConsentOptions = {
                    ConsentMarketing: true,
                  };
                  if ($scope.Settings.isEdit) {
                    $scope.ContactSet.isIncluded = true;
                  }

                  $scope.partyApplicants.push($scope.ContactSet);
                } else {
                  const ContactSet = $scope.getNewContactModel('Adult');
                  ContactSet.PartyType = '1';
                  ContactSet.ApplicantNumber =
                    _.size($scope.partyApplicants) + 1;
                  ContactSet.MaritalStatusId = '2';
                  ContactSet.customerConsentOptions = {
                    ConsentMarketing: true,
                  };

                  if ($scope.Settings.isEdit) {
                    ContactSet.isIncluded = true;
                  }

                  $scope.partyApplicants.push(ContactSet);
                  $scope.setMaritalStatus(true);
                }
                $scope.Settings.SelectedParty = 'Applicants';
              }
              break;
            default:
              break;
          }
          initializeContactsetOnCreate($scope);
        },
        selectContact(party) {
          $scope.searchContactText = party.FamilyFullName;
          $scope.selectedFamily = party;
          $scope.showContactList = false;
          const isSelectContactPhoneIntl = validatePhoneOnAddSelectContact(
            $scope,
          );
          if (isSelectContactPhoneIntl) {
            const isSelectContactPhoneValid = validatePhoneList({
              $scope,
              countryCodeInputElement,
              emptyNumberMessage: $scope.emptyNumberMessage,
              angular,
              countryCodeService,
              value: $scope.ContactSet,
            }).includes(false);
            if (
              isInvalidWarning({
                isInvalid: isSelectContactPhoneValid,
                toastError,
                errorMessage: $scope.errorMessage,
              })
            ) {
              return false;
            }
          }

          assignContactSetPhone({
            $scope,
            countryCodeService,
          });
          updatePartyApplicantsOnEdit({ $scope });

          if (party.FirstName === '' || !party.FirstName) {
            party.errFirstName = true;
          } else {
            party.errFirstName = false;
          }
          if (party.LastName === '' || !party.LastName) {
            party.errLastName = true;
          } else {
            party.errLastName = false;
          }
          const tempAccessType = parseInt($scope.accessType, 10);
          const isAdviserNotEmpty = _.isEmpty(
            $scope.allocatedAdviser.adviserSelected.FullName.description,
          );
          if (tempAccessType === 5) {
            if (isAdviserNotEmpty) {
              party.errAdviser = true;
            } else {
              party.errAdviser = false;
            }
          }
          // eslint-disable-next-line sonarjs/no-small-switch
          switch (party.PartyType) {
            case '1':
              $scope.setMaritalStatus(false);
              $scope.Settings.SelectedParty = 'Applicants';
              $scope.ContactSet = party;
              break;
            default:
              break;
          }
        },
        sampleChange(id) {
          $scope.AssignedAdviserId = id;
          onAdviserSelect({ $scope })(id);
        },
        removeSecondApplicant() {
          removeApplicantAction($scope);
        },
        checkMaritalStatus() {
          // NOTE: Married status = 3; make sure the number of applicants is still = 1
          if (
            parseInt($scope.ContactSet.MaritalStatusId, 10) === 2 &&
            _.size($scope.partyApplicants) === 1
          ) {
            $scope.setMaritalStatus(true);
            $scope.isMarried = true;
          } else {
            $scope.setMaritalStatus(false);
          }
          if (
            !_.isUndefined($scope.isMarried) &&
            $scope.isMarried === true &&
            parseInt($scope.ContactSet.MaritalStatusId, 10) !== 2 &&
            _.size($scope.partyApplicants) > 1
          ) {
            $scope.removeContact('Applicant');
            $scope.removeSecondApplicant();
          }
        },
        saveClientAccount() {
          // save applicants
          const partyApplicants = angular.copy($scope.partyApplicants);
          const {
            excludedApplicants,
            excludedIds,
          } = applicationSharedService.getExcludedApplicants(partyApplicants);

          // trap ids, we cannot exclude 2 members (this means no adult is selected)
          if (_.size(excludedApplicants) > 1) {
            toaster.pop('error', 'Error Saving', 'Cannot save empty members');
            return false;
          }
          if (
            excludedApplicants &&
            excludedApplicants.length &&
            excludedApplicants[0].isExisting
          ) {
            $scope.deleteClient(
              {
                PersonId: excludedApplicants[0].PersonId,
                IsApplicant: true,
                Role: '1',
              },
              true,
            );
          }

          $scope.selectedCountry = assignSelectedCountryValue({
            inputElement: angular.element('#mobileNumber-dc'),
            flagCondition: $scope.isMobileInputDropdown,
            currentUserService,
          });

          // Map the included parties
          _.map(partyApplicants, (party) => {
            party.Email = [{ Type: 'Email', EmailAddress: party.EmailAddress }];
            party.DOB = utilitiesService.formatDate(party.DOBGet, true);

            if (parseInt(party.CountryCode, 10) === -1) {
              party.CountryOfBirth = party.CitizenshipCountryManual;
            } else {
              party.CountryOfBirth = party.CountryCode;
            }

            let CitizenshipObj = _.find(
              $scope.countryList,
              (o) => o.CountryCode === party.CountryCode,
            );
            let CountryOfResidencyObj = _.find(
              $scope.countryList,
              (o) => o.CountryCode === party.ResidencyCountryCode,
            );
            let CountryOfBirthObj = _.find(
              $scope.countryList,
              (o) => o.CountryCode === party.CountryOfBirth,
            );
            if (!CitizenshipObj) {
              CitizenshipObj = {
                CountryID: 0,
              };
            }
            if (!CountryOfResidencyObj) {
              CountryOfResidencyObj = {
                CountryID: 0,
              };
            }
            if (!CountryOfBirthObj) {
              CountryOfBirthObj = {
                CountryID: 0,
              };
            }
            let adviserId = $scope.AssignedAdviserId;
            if (parseInt($scope.accessType, 10) === ACCESS_TYPE.CORPORATE) {
              const allocatedAdviser =
                $scope.allocatedAdviser.adviserSelected.FullName;
              if (allocatedAdviser && allocatedAdviser.description) {
                adviserId = allocatedAdviser.description.FamilyId;
              }
            }
            party.AllocatedAdviserID = adviserId;
            party.AllocatedAssistantID =
              $scope.accessType === ACCESS_TYPE.CORPORATE
                ? _.get(
                    $scope.allocation,
                    'selectedAssistant.originalObject.Value',
                  ) || 0
                : $scope.allocation.assistantId;

            angular.extend(party, {
              CountryOfResidencyId: parseInt(
                CountryOfResidencyObj.CountryID,
                10,
              ),
              CitizenshipId: parseInt(CitizenshipObj.CountryID, 10),
              CountryOfBirthId: parseInt(CountryOfBirthObj.CountryID, 10),
              ResidencyId: parseInt(party.ResidencyId, 10),
            });
          });

          contactService
            .addContact(partyApplicants)
            .then((response) => {
              const FamilyId = response.data;

              if (requester === REQUESTER.NEW_LOAN_APP_MODAL) {
                $uibModalInstance.close({ FamilyId });
              } else {
                $uibModalInstance.close({
                  FamilyId,
                  PartyType: '1',
                  excludedIds,
                });
                if (parseInt($scope.accessType, 10) === ACCESS_TYPE.CORPORATE) {
                  $scope.AssignedAdviserId =
                    $scope.allocatedAdviser.adviserSelected.FullName.description.FamilyId;
                }

                return FamilyId;
              }
            })
            .then((FamilyId) => {
              contactService.putAssignAdviser(
                FamilyId,
                $scope.AssignedAdviserId,
              );

              return FamilyId;
            })
            .then((FamilyId) => {
              const defer = $q.defer();
              const postData = {
                LoanScenarioId: 0,
                FamilyId,
                AdviserId: $scope.AssignedAdviserId,
                LendingCategoryId: lendingCategoryId,
              };

              loanScenarioService.scenarioSet(postData).then(
                (res) => {
                  defer.resolve({
                    FamilyId,
                    loanAppId: res.data,
                    AdviserId: $scope.AssignedAdviserId,
                  });
                },
                () => {
                  defer.reject({});
                },
              );

              return defer.promise;
            })
            .then(({ FamilyId, loanAppId, AdviserId }) => {
              let adviserFamilyId = userFamilyId;
              const adviserAccessList = [
                ACCESS_TYPE.ADVISER,
                ACCESS_TYPE.PRINCIPAL_ADVISER,
              ];
              if (!adviserAccessList.includes($scope.accessType)) {
                adviserFamilyId = $scope.AssignedAdviserId;
              }
              setLoanAppAssistant({
                $state,
                corporateService,
                loanScenarioService,
                dummyScenario: { FamilyId, AdviserId },
              })(loanAppId);
              loanAppDetailsService.setInsuranceDefault(
                loanAppId,
                FamilyId,
                adviserFamilyId,
              );
              $state.go('app.loanApplicationDetailsV3', {
                familyId: FamilyId,
                loanAppId,
              });
              $rootScope.$on('$stateChangeSuccess', () => {
                broadcastDataService.InlinePreloader = {
                  preLoaderText: '',
                  preLoaderSize: '',
                  showPreLoader: false,
                };
              });
              toaster.pop(
                'success',
                'Successful!',
                'Contact has been assigned to an adviser.',
              );
              toaster.pop(
                'success',
                'Added!',
                'Contact has been Added Successfully.',
              );
              $scope.dismissModal();
            })
            .catch((error) => {
              $scope.submitClicked = false;
              toastError(error.data.Message);
            });
        },

        saveAccount(form) {
          let errKey = 0;
          $scope.submitClicked = true;
          assignContactSetPhone({
            $scope,
            countryCodeService,
          });
          updatePartyApplicantsOnEdit({ $scope });
          _.forEach($scope.partyApplicants, (value, key) => {
            if (
              value.isIncluded &&
              (!value.FirstName ||
                value.FirstName === '' ||
                !value.LastName ||
                value.LastName === '')
            ) {
              form.$invalid = true;
              errKey = key;
            }

            const isEmailValid = contactEmailValidation({
              value,
              emailValidation,
              $scope,
            });

            const isInvalid = isInvalidWarning({
              isInvalid: isEmailValid.isInvalid,
              toastError,
              errorMessage: isEmailValid.message,
            });

            if (isInvalid) {
              errKey = key;
              form.$invalid = true;
            }

            if (
              isInvalidWarning({
                isInvalid: isPhoneExisting({ value, $scope }),
                toastError,
                errorMessage: $scope.emptyNumberMessage,
              })
            ) {
              errKey = key;
              form.$invalid = true;
            }

            if ($scope.isInternationalNumberAUNZ) {
              const isPhoneListValidNumber = validatePhoneList({
                $scope,
                countryCodeInputElement,
                emptyNumberMessage: $scope.emptyNumberMessage,
                angular,
                countryCodeService,
                value,
              }).includes(false);
              if (
                isInvalidWarning({
                  isInvalid: isPhoneListValidNumber,
                  toastError,
                  errorMessage: $scope.errorMessage,
                })
              ) {
                errKey = key;
                form.$invalid = true;
              }
            }
            if (
              parseInt($scope.accessType, 10) ===
                PERSON_PARTY_TYPES.SOLICITOR &&
              _.isEmpty(
                $scope.allocatedAdviser.adviserSelected.FullName.description,
              )
            ) {
              form.$invalid = true;
              errKey = key;
            }
          });

          if (form.$invalid) {
            submissionErrorAction({ $scope, errKey });
            $scope.submitClicked = false;
          } else {
            $scope.saveClientAccount();
          }
        },
      });
    });
