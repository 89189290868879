export const initEmailRecipients = (customerCareObj) => {
  const emails = customerCareObj.Email || customerCareObj.email;
  if (!emails) {
    return [customerCareObj];
  }
  const splittedEmails = emails.split(',');
  return splittedEmails.map((EmailAddress) => {
    return {
      ...customerCareObj,
      EmailAddress,
    };
  });
};

export const processClientList = ({
  email,
  clients = [],
  attachments = [],
}) => {
  return clients.reduce((accum, value) => {
    const client = {
      ...value,
      Attachment: attachments,
      CustomEmailSubject: email.EmailSubject,
      CustomEmailBody: email.EmailBody,
    };
    return accum.concat(client);
  }, []);
};

export const setEmailObject = ($scope) => {
  $scope.email.IsCustomBulk = true;
  $scope.email.ClientList = processClientList({
    email: $scope.email,
    clients: $scope.recipients.email,
    attachments: $scope.customerCareEmail.attachments,
  });
};

export const setTemplateSelection = ({ $scope, templateService }) => (
  template = {},
  index = -1,
) => {
  $scope.selectedTemplate = { ...template };
  $scope.iconChange = !$scope.iconChange;
  $scope.showTick = true;
  $scope.customerCareEmail.attachments = [];
  $scope.isLoadingAttachment = !!template.AttachmentDocumentIds;

  if (!$scope.email) {
    $scope.email = {};
  }
  $scope.translateTemplate(
    template.UserTemplateContent || '',
    template.UserTemplateEmailSubject || '',
  );
  $scope.index = index;
  return templateService
    .getTemplateAttachments(template.AttachmentDocumentIds)
    .then((attachments) => {
      $scope.isLoadingAttachment = false;
      $scope.customerCareEmail.attachments = [...attachments];
    })
    .catch(() => {
      $scope.isLoadingAttachment = false;
    });
};

export const openAttachment = ({
  $scope,
  commonFnService,
  currentUserService,
}) => () => {
  const modalInstance = commonFnService.attachFileMgmt(
    currentUserService.familyId,
  );
  return modalInstance.result.then((data) => {
    $scope.customerCareEmail.attachments = [
      ...$scope.customerCareEmail.attachments,
      ...data,
    ];
  });
};

export const initializeSendEmail = ({
  $scope,
  templateService,
  commonFnService,
  currentUserService,
}) => {
  $scope.customerCareEmail = {
    attachments: [],
  };
  $scope.setTemplateSelection = setTemplateSelection({
    $scope,
    templateService,
  });
  $scope.openAttachment = openAttachment({
    $scope,
    commonFnService,
    currentUserService,
  });
};
