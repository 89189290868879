import angular from 'angular';
import { cloneDeep, orderBy, flow, get } from 'lodash';
import { getLogger } from '@loanmarket/logger-core';
import { EDITOR_OPTIONS_NO_TABLE } from 'Common/config/wysiwigEditor';
import { CONFIG_EMAIL } from 'Common/config/email';
import { COLOR } from 'Common/constants/colors';
import {
  AWS_S3_ROOT,
  HELLOBOOK_VALID_EXTENSIONS,
} from 'Common/constants/document';
import { KEY_CODE } from 'Common/constants/keyCode';
import { EMAIL_CTYPES } from 'Common/constants/emailTypes';
import { CLIENT_ROLE } from 'Common/constants/contactType';
import { PHONE_TYPE } from 'Common/constants/phoneMobileFormat';
import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';
import { HELLO_BOOK } from 'Common/constants/promptMessages';
import { FACT_FIND_GROUPING } from 'Common/constants/factFindHeadings';
import {
  INSURANCE_PACK_TYPE,
  PACK_MODE,
  SERVICE_OPTION,
} from 'Common/constants/helloBook';
import {
  helloBookClientBuilderForUI,
  helloBookClientBuilderForMyCRM,
  helloBookSendBuilderForMyCRM,
  helloBookDefaultSettingsForMyCRM,
  helloBookFamilyLoanForUI,
} from 'Common/mappers/helloBook';
import { isValidObjectValue } from 'Common/utilities/objectValidation';
import { parseToInt10 } from 'Common/utilities/parse';
import {
  toastError,
  toastSaveSuccess,
  toastSuccess,
} from 'Common/utilities/alert';
import {
  setMappedContactEmailByType,
  setMappedContactPhoneByType,
} from 'Common/utilities/contact';
import { convertTextToKey, isValidEmailAddress } from 'Common/utilities/string';
import { isMobileNumberValid } from 'Common/utilities/mobile';
import { removeElementOnIndex } from 'Common/utilities/array';
import { extractFileExtension } from 'Common/utilities/downloadHelper';
import FileUploadCtrl from 'Components/fileUpload/fileUploadCtrl';
import {
  isLMBranded,
  isWMBranded,
  isIMBranded,
  isOwnBrand,
} from 'Common/utilities/brandingCategory';
import {
  setupDynamicRenderHtmlContent,
  addHelloBookPipelineCategory,
  getMobileCountryCode,
} from './util/helloBookPreviewGeneratorCtrl';
import { setupHelloPackInsurance } from './util/helloPackInsurance';
import { getSelectedHeadings } from 'Common/utilities/factFind';
import { performanceTimingData } from 'Common/utilities/dataLayer';
import {
  HELLO_PACK_SEND_INSURANCE,
  HELLO_PACK_SEND_LENDING,
} from 'Common/constants/performance';

const FACT_FIND_TYPE = {
  PARTIAL: 'partial',
  FULL: 'full',
};
const SEND_HELLO_BOOK_TYPE = {
  NOW: 'now',
  PREVIEW: 'preview',
  SAVE: 'save',
};
const SEND_PREVIEW_LABEL = {
  DEFAULT: 'Send Preview',
  SENT: 'Sent',
};
const TOGGLE_SECTIONS = {
  ONLINE_FACT_FIND: 'onlineFactFind',
};
const HELLO_PACK_CHAR_LIMIT = 5000;
const FILE_ATTACHMENT_LIMIT = 3;

const DEFAULT_LM_LOGO_PATH = './assets/images/helloBook/defaultLogo.svg';
const DEFAULT_INSURANCE_LOGO_PATH =
  './assets/images/defaultBrandingLogo/insurance-market-logo.svg';
export default class HelloBookPreviewGenerator {
  constructor(
    $q,
    $state,
    toaster,
    $window,
    $timeout,
    uiService,
    FileUploader,
    configService,
    generalService,
    crmConfirmation,
    contactService,
    helloBookService,
    currentUserService,
    contactModelService,
    insuranceSharedData,
    loanScenarioService,
    actionValidatorService,
    loanOpportunityService,
    loanApplicationServices,
    insurancePipelineService,
    insuranceApplicationService,
    countryCodeService,
    contactSharedDataService,
    userAccountModelService,
    documentService,
  ) {
    'ngInject';

    this.factFindType = FACT_FIND_TYPE;
    this.packMode = PACK_MODE;
    this.packType = INSURANCE_PACK_TYPE;
    this.sendType = SEND_HELLO_BOOK_TYPE;
    this.editorOptions = EDITOR_OPTIONS_NO_TABLE;
    this.promptMessages = HELLO_BOOK;
    this.sendPreviewButtonLabel = SEND_PREVIEW_LABEL.DEFAULT;

    this.$q = $q;
    this.$state = $state;
    this.toaster = toaster;
    this.$window = $window;
    this.$timeout = $timeout;
    this.uiService = uiService;
    this.fileUploader = FileUploader;
    this.configService = configService;
    this.generalService = generalService;
    this.contactService = contactService;
    this.crmConfirmation = crmConfirmation;
    this.helloBookService = helloBookService;
    this.currentUserService = currentUserService;
    this.contactModelService = contactModelService;
    this.insuranceSharedData = insuranceSharedData;
    this.loanScenarioService = loanScenarioService;
    this.loanOpportunityService = loanOpportunityService;
    this.actionValidatorService = actionValidatorService;
    this.loanApplicationServices = loanApplicationServices;
    this.insurancePipelineService = insurancePipelineService;
    this.insuranceApplicationService = insuranceApplicationService;
    this.documentService = documentService;
    this.contactSharedDataService = contactSharedDataService;
    this.countryCodeService = countryCodeService;
    this.isMobileNumberValid = isMobileNumberValid;
    this.uiService = uiService;
    this.angular = angular;
    this.userAccountModelService = userAccountModelService;
  }

  $onInit() {
    this.logger = getLogger('HelloBook');

    if (!this.activePackMode) {
      this.activePackMode = PACK_MODE.HELLO_PACK;
    }
    if (!this.familyId) {
      this.familyId = this.$state.params.familyId;
    }
    this.helloBookCreditMessage = '';
    this.clientId = null;
    this.client = {};
    this.isShowWarningPhone = true;
    this.oktaEmailActive = false;
    this.mobileNumber = {};
    this.email = {};
    this.selectedApplicationId = 0;
    this.factFindSections = [];
    this.familyLoans = [];
    this.onboardingTeam = CONFIG_EMAIL.ONBOARDING_AU;
    this.itSupportEmail = CONFIG_EMAIL.MYCRM_SUPPORT;
    this.factFindEmail = this.currentUserService.isNZ
      ? CONFIG_EMAIL.ADVISER_SERVICES_NZ
      : CONFIG_EMAIL.MARKETING_AU;
    this.model = {
      email: null,
      phone: null,
    };
    this.isBYOB = false;
    this.adviserTheme = null;
    this.isEmailEdit = false;
    this.isPhoneEdit = false;
    this.isSaveLoading = false;
    this.isMessageEdited = false;
    this.isBrandingReady = false;
    this.isSendNowLoading = false;
    this.isSendPreviewLoading = false;
    this.editorMessage = '';
    this.editorCharacterLimit = HELLO_PACK_CHAR_LIMIT;
    this.FACT_FIND_GROUPING = FACT_FIND_GROUPING;
    this.isInternationalPhoneNumbers = this.uiService.isInternationalPhoneNumber;
    this.phoneCountryCode = this.countryCodeService.homePhoneCountryCode();
    this.selectedPhoneCountryCode = this.phoneCountryCode;
    this.workPhoneInputName = 'workPhoneInput';
    // FILE UPLOADS
    this.fileUploadCtrl = null;
    this.attachedFiles = [];
    this.documentsUploaded = [];
    this.validFileExtensions = `.${HELLOBOOK_VALID_EXTENSIONS.join(',.')}`;
    this.hasInvalidFileExtension = false;
    this.clientSelectedHasCalled = false;
    this.isEmailRequired = true;
    this.isPhoneRequired = false;
    this.activateClientIndex = 0;
    this.isContactUpdated = false;
    const { helloBookSendToBothClients } = this.configService.feature;
    this.helloBookSendToBothClients = helloBookSendToBothClients;
    this.isOktaCheckEmailLoading = true;
    this.messageSalutation = `Hi`;
    this.commonNamePlaceholder = `{First Name}`;

    this.loanLinkageLabel = this.uiService.viewOfAfileTurnedOn
      ? 'Link to File'
      : 'Loan Application';

    if (this.activePackMode === PACK_MODE.INSURANCE_APP) {
      this.initFileUploader({
        uploadType: AWS_S3_ROOT.INSURANCE_APP,
        isPresignedUrl: true,
      });
      setupHelloPackInsurance({
        vm: this,
        insurancePipelineService: this.insurancePipelineService,
        insuranceApplicationService: this.insuranceApplicationService,
      });
      this.initInsuranceApp();
    } else {
      this.initFileUploader({ uploadType: AWS_S3_ROOT.HELLOBOOK });
      this.initFactFind();
      this.setCalendly();
      this.setCreditGuide();
    }
    this.setDefaults();

    this.setupFamily();
    setupDynamicRenderHtmlContent({ vm: this });
    this.isValid();
  }

  initFileUploader({ uploadType, isPresignedUrl = false }) {
    if (!this.fileUploader) {
      return;
    }
    this.fileUploadCtrl = new FileUploadCtrl(
      this.uiService,
      this.fileUploader,
      this.configService,
      this.documentService,
      this.crmConfirmation,
      this.$timeout,
      this.toaster,
      this.$window,
    );
    this.fileUploadCtrl.isPresignedUrl = isPresignedUrl;
    this.fileUploadCtrl.$onInit();
    this.fileUploadCtrl.familyId = this.familyId;
    this.fileUploadCtrl.uploadType = uploadType;
    this.fileUploadCtrl.type = 'file';
    this.fileUploadCtrl.extensions = HELLOBOOK_VALID_EXTENSIONS;
  }

  setupFamily() {
    this.getFamilyClients().then(() => {
      this.clientId =
        (this.clients.length && this.clients[0] && this.clients[0].personId) ||
        null;
      if (!this.clientId) {
        return;
      }

      this.clientSelected();
      if (this.helloBookSendToBothClients) {
        this.clientChecked();
      }
      this.activePackMode === PACK_MODE.HELLO_PACK && this.populateLoanList();
    });

    const defer = this.$q.defer();
    this.$q
      .all([this.getFamilyAdviserInfo(), this.getFactFindHeadings()])
      .then(() => {
        this.getDefaultSettings();
        return defer.promise;
      });
  }

  setDefaults() {
    this.DEFAULT_PLACEHOLDER = {
      [INSURANCE_PACK_TYPE.APPLICATION]: {
        packTitle: 'Invite client to complete their application',
        bannerMessage: 'We have a few questions for you to answer...',
        defaultEditorMessage: `Hi ${this.client.firstName}, <br><br>
          <p>
            Before I can submit your application to your chosen insurance provider, 
            you just need to answer a few questions about your current situation.
          </p>
          <p>
            You can provide this information online by clicking the link below. The links will be valid for 24 hours.
          </p>
          <p>`,
      },
      [INSURANCE_PACK_TYPE.QUOTE]: {
        packTitle: 'Email Quote Report',
        bannerMessage: 'Your tailored insurance quote.',
        defaultEditorMessage: `Hi ${this.client.firstName}, <br><br>
          ${
            this.activePackType === INSURANCE_PACK_TYPE.QUOTE
              ? this.setDefaultQuoteCustomPlaceholder()
              : ''
          }
        `,
      },
      [PACK_MODE.HELLO_PACK]: {
        packTitle: 'Send Hello Pack',
        bannerMessage: 'Hello.',
        defaultEditorMessage: `Hi ${this.client.firstName}, <br><br>
          <p>It was great to chat and learn about what you're looking to do.</p>
        `,
      },
    };
    const insuranceKey =
      this.activePackType === INSURANCE_PACK_TYPE.APPLICATION
        ? INSURANCE_PACK_TYPE.APPLICATION
        : INSURANCE_PACK_TYPE.QUOTE;
    const key =
      this.activePackMode === PACK_MODE.INSURANCE_APP
        ? insuranceKey
        : PACK_MODE.HELLO_PACK;
    const defaultText = this.DEFAULT_PLACEHOLDER[key];

    Object.assign(this, {
      ...defaultText,
    });
  }

  initFactFind() {
    // fact find specific models
    this.model = {
      ...this.model,
      calendlyLink: null,
      factFindType: this.factFindType.FULL,
      sections: {
        onlineFactFind: false,
        helloBook: true,
        isViewQuote: true,
        creditGuide: false,
        calendly: false,
      },
    };

    this.disableFactFindStatusList = [
      LOAN_STATUS.IN_PROGRESS.LODGED,
      LOAN_STATUS.IN_PROGRESS.PRE_APPROVED,
      LOAN_STATUS.EXISTING.SETTLED,
      LOAN_STATUS.PREVIOUS.NOT_PROCEEDED_WITH,
    ];
  }

  getFamilyClients() {
    this.isOktaCheckEmailLoading = !this.isContactUpdated;
    return this.contactService
      .clientInformGet(this.familyId, 0, true)
      .then(({ data }) => {
        if (!data) {
          return;
        }
        const oldClientData = this.clients || [];
        this.clients = data
          .map(helloBookClientBuilderForUI)
          .filter((client) => client.role === CLIENT_ROLE.ADULT);

        this.clientFormatContact(oldClientData);
        if (oldClientData.length) {
          this.isValid();
        }
      });
  }

  getFamilyAdviserInfo() {
    return this.contactModelService
      .getFamilyAdviserInfo(this.familyId)
      .then((response) => {
        this.adviserInfo = response;
        this.isBrandingReady = isLMBranded(this.adviserInfo.brandingCategoryID);
        this.getFamilyAdviserTheme();
      });
  }

  isUseDefaultBranding() {
    const isLMBrand =
      this.activePackMode === PACK_MODE.HELLO_PACK &&
      (isLMBranded(this.adviserInfo.brandingCategoryID) ||
        isWMBranded(this.adviserInfo.brandingCategoryID));

    const isInsuranceBrand = isIMBranded(this.adviserInfo.brandingCategoryID);

    return isLMBrand || isInsuranceBrand;
  }

  isEditorMessageAboveThreshold() {
    return this.editorCharacterLimit < this.editorMessage.length;
  }

  getFamilyAdviserTheme() {
    this.contactModelService
      .getFamilyAdviserTheme(this.familyId)
      .then((response) => {
        this.adviserTheme = response;

        const isUseDefaultBranding = this.isUseDefaultBranding();

        this.isBYOB = isOwnBrand(this.adviserInfo.brandingCategoryID);
        this.isBrandingReady =
          !this.isBYOB ||
          !!(this.adviserInfo.tradingName && this.adviserTheme.logoDocId);

        if (this.isBYOB && !this.adviserTheme.logoDocId) {
          this.logoPath = '';
          return;
        }

        if (isUseDefaultBranding) {
          this.setDefaultBranding();
          return;
        }

        if (!this.adviserTheme.logoDocId) {
          return;
        }
        this.generalService
          .documentGet(this.adviserTheme.logoDocId)
          .then(({ data }) => {
            if (!data || !data.ContentType || !data.DocumentContent) {
              return;
            }
            this.logoPath = data
              ? `data:${data.ContentType};base64,${data.DocumentContent}`
              : '';
          });
      });
  }

  setDefaultBranding() {
    if (
      isIMBranded(this.adviserInfo.brandingCategoryID) ||
      this.selectedService === SERVICE_OPTION.INSURANCE
    ) {
      this.logoPath = DEFAULT_INSURANCE_LOGO_PATH;
      this.adviserTheme.sidebarBgColor = COLOR.SUSHI;
    } else {
      this.logoPath = DEFAULT_LM_LOGO_PATH;
    }
  }

  getFactFindHeadings() {
    if (this.activePackMode === INSURANCE_PACK_TYPE.APPLICATION) {
      return;
    }
    return this.loanApplicationServices
      .getFactFindHeadings()
      .then((response) => {
        this.factFindSections =
          response &&
          response.map((section) => {
            const selected = false;
            return {
              selected,
              sectionKey: convertTextToKey(section.name).toLowerCase(),
              ...section,
            };
          });
      });
  }

  parseFileInviteData(data) {
    if (!data) {
      return {
        reminders: {},
        templates: [],
        dueDate: '',
      };
    }
    const dueDate = data.dueDate ? new Date(data.dueDate) : '';
    return {
      ...data,
      dueDate,
    };
  }

  getDefaultSettings() {
    const adviserId = this.adviserInfo.familyId;
    this.helloBookService.getDefaultSettings(adviserId).then((data) => {
      const {
        message,
        calendlyLink,
        factFindType,
        isCreditEnabled,
        isFactFindEnabled,
        isHelloBookEnabled,
        isCalendlyInviteEnabled,
        isDisclosureEnabled,
        factFindSections: factFindSectionsDefault,
        attachments,
        documentRequestMethod,
        fileInvite,
      } = data;
      const defaults = {
        calendlyLink,
        factFindType,
        documentRequestMethod,
        fileInvite: this.parseFileInviteData(fileInvite),
        sections: {
          onlineFactFind: !!isFactFindEnabled,
          helloBook: !!isHelloBookEnabled,
          creditGuide: !!isCreditEnabled,
          calendly: !!isCalendlyInviteEnabled,
          disclosureGuide: !!isDisclosureEnabled,
        },
      };

      this.attachedFiles = attachments;
      if (message) {
        this.editorMessage = message;
        this.isMessageEdited = true;
        this.setDefaultEditorMessage();
      }

      if (this.activePackMode !== PACK_MODE.HELLO_PACK) {
        return;
      }

      this.model = {
        ...this.model,
        ...defaults,
      };

      if (this.factFindSections) {
        this.factFindSections = this.factFindSections.map((section) => {
          return {
            ...section,
            selected: factFindSectionsDefault[section.sectionKey],
          };
        });
      }

      this.setCalendly();
      this.setCreditGuide();
    });
  }

  setCalendly() {
    this.showCalendly = this.currentUserService.isAU;

    if (this.currentUserService.isNZ || !this.model.calendlyLink) {
      this.model.sections.calendly = false;
    }
  }

  setCreditGuide() {
    if (this.currentUserService.isNZ) {
      this.showCreditGuide = false;
      this.showDisclosureGuide = this.uiService.showDisclosureGuide;
      this.model.sections.disclosureGuide = this.uiService.showDisclosureGuide;
      this.model.sections.creditGuide = false;
    } else {
      this.showCreditGuide = true;
      this.showDisclosureGuide = false;
      this.model.sections.disclosureGuide = false;
    }
  }

  setOnlineFacFind() {
    if (
      isOwnBrand(this.adviserInfo.brandingCategoryID) &&
      !this.isBrandingReady
    ) {
      this.model.sections.onlineFactFind = false;
    }
  }

  setDefaultEditorMessage() {
    this.theClientName = this.helloBookSendToBothClients
      ? this.client.firstNameForBoth
      : this.client.firstName;

    const isSalutationPresent = this.editorMessage.indexOf(`Hi`);

    if (isSalutationPresent > -1) {
      const CHARCOUNT = 2;
      // eslint-disable-next-line unicorn/prefer-string-slice
      const currentName = this.editorMessage.substring(
        isSalutationPresent + CHARCOUNT,
        this.editorMessage.indexOf(`</p>`),
      );
      this.editorMessage = this.editorMessage.replace(
        currentName,
        ` ${this.theClientName},`,
      );
    } else if (this.editorMessage) {
      this.editorMessage = `<p>Hi ${this.theClientName},</p>${this.editorMessage}`;
    } else {
      this.editorMessage = this.defaultEditorMessage;
    }
    this.initialEditorMessage = this.editorMessage;
  }

  editMessage() {
    if (this.initialEditorMessage !== this.editorMessage) {
      this.isMessageEdited = true;
    }
    this.isValid();
  }

  clientChecked() {
    const checkedCount = this.clients.filter((item) => {
      return item.isSelected;
    }).length;

    this.clients.map((item) => {
      if (checkedCount > 1) {
        item.isDisabled = false;
      }
      if (checkedCount === 1 && item.isSelected) {
        item.isDisabled = true;
      }
      return item;
    });

    let newName = '';
    this.clients.map((item, itemIndex) => {
      if (item.isSelected) {
        if (
          newName &&
          this.clients.length > 1 &&
          itemIndex + 1 < this.clients.length
        ) {
          newName = `${newName}, ${item.firstName}`;
        }
        if (
          newName &&
          this.clients.length > 1 &&
          itemIndex + 1 === this.clients.length
        ) {
          newName = `${newName} and ${item.firstName}`;
        }
        if (!newName) {
          newName = item.firstName;
        }
      }
      return item;
    });

    this.client.firstNameForBoth = newName;
    this.isMessageEdited = true;
    this.setDefaultEditorMessage();
    this.model.currentFirstName = newName;
    this.isValid();
  }

  getEmailAddressFromList(index) {
    let email =
      this.clients[index].email.length &&
      this.clients[index].email.find(
        (item) => item.type === EMAIL_CTYPES.CRM_EMAIL,
      );

    if (!email) {
      email = this.clients[index].email[0];
    }
    return email && email.email;
  }

  getMobileNumberFromList(index) {
    const theClient = this.clients[index];
    let mobileNumber = { number: '' };
    if (isValidObjectValue(() => theClient.phone.length)) {
      const mobileNumberIndex = theClient.phone.findIndex(
        (phone) => phone.type === PHONE_TYPE.MOBILE,
      );
      if (mobileNumberIndex > -1) {
        mobileNumber = theClient.phone[mobileNumberIndex];
      }
    }
    return this.countryCodeService.formatExistingMobileAuNzNumber({
      isInterNationalPhoneNumbers: this.isInternationalPhoneNumbers,
      phoneNumber: mobileNumber.number,
    });
  }

  activateClient(index) {
    this.activateClientIndex = index;
    if (!this.isEmailEdit && !this.isPhoneEdit) {
      this.clientId = this.clients[index].personId;
      this.clientSelected();
    }
  }

  clientFormatContact(oldClientData = []) {
    this.clients = this.clients.map((client, index) => {
      client.formattedEmail = this.getEmailAddressFromList(index);
      client.formattedPhone = this.getMobileNumberFromList(index);
      client.countryCode =
        getMobileCountryCode(client) || this.phoneCountryCode;
      client.isSelected =
        oldClientData.length && oldClientData[index]
          ? oldClientData[index].isSelected
          : true;
      client.isDisabled =
        oldClientData.length && oldClientData[index]
          ? oldClientData[index].isDisabled
          : false;
      return client;
    });
    if (this.jointContact) {
      this.clients = this.clients.filter((item) => item.isPrimary);
    }
    this.setIsClientLoanBorrower();
    !this.isContactUpdated && this.checkEmailExistInOkta();
  }

  checkEmailExistInOkta() {
    const promises = this.clients.reduce((initial, client) => {
      const { formattedEmail } = client;
      return formattedEmail
        ? [
            ...initial,
            this.userAccountModelService.getEmailExist(formattedEmail),
          ]
        : initial;
    }, []);
    this.$q.all(promises).then((responses) => {
      this.isOktaCheckEmailLoading = false;
      if (!get(responses, 'length')) {
        return;
      }
      responses.map((response) => this.updateOktaEmailExistProp(response));
    });
  }

  clientSelected() {
    !this.helloBookSendToBothClients && this.setIsClientLoanBorrower();
    this.selectedClient = this.clients.find(
      (client) => client.personId === this.clientId,
    );
    const bothName = this.client.firstNameForBoth;
    this.client = cloneDeep(this.selectedClient);
    this.client.firstNameForBoth = bothName;
    if (!this.client) {
      return;
    }
    this.setDefaults();
    this.setDefaultEditorMessage();
    this.model.currentFirstName =
      this.helloBookSendToBothClients && this.clientSelectedHasCalled
        ? this.client.firstNameForBoth
        : this.client.firstName;
    this.model.email = this.getEmailAddress();
    this.model.phone = this.getMobileNumber();
    this.model.countryCode =
      getMobileCountryCode(this.client) || this.phoneCountryCode;
    this.clientSelectedHasCalled = true;
    this.isValid();
  }

  validateEmail() {
    return isValidObjectValue(() => this.client.email.length);
  }

  getEmailAddress() {
    this.setEmailAddress();
    return this.email.email;
  }

  setEmailAddress() {
    const hasEmail = this.validateEmail();

    if (!hasEmail) {
      this.email = { email: '' };
      return;
    }

    this.email =
      hasEmail &&
      this.client.email.find((email) => email.type === EMAIL_CTYPES.CRM_EMAIL);

    if (!this.email) {
      this.email = this.client.email[0];
    }
  }

  checkPopoverVisibility(fieldName) {
    switch (fieldName) {
      case 'email':
        this.phoneCancelCallback && this.phoneCancelCallback();
        break;
      case 'phone':
        this.emailCancelCallback && this.emailCancelCallback();
        break;
      default:
    }
  }

  onUpdateEmailValue(key, value, isEdit, cancelCallback) {
    this.isValidEmail = isValidEmailAddress(value);
    this.isEmailEdit = isEdit;
    this.emailCancelCallback = cancelCallback;
    this.model.email = value;

    this.isValid();
    if (!this.isValidEmail) {
      return;
    }

    if (this.validateEmail()) {
      this.email.email = value;
    } else {
      const newEmail = {
        email: value,
        type: EMAIL_CTYPES.CRM_EMAIL,
      };
      this.client.email = [newEmail];
      this.email = this.client.email[0];
    }
  }

  validateMobile() {
    return isValidObjectValue(() => this.client.phone.length);
  }

  getMobileNumber() {
    this.mobileNumber = { number: '' };
    if (this.validateMobile()) {
      const mobileNumberIndex = this.client.phone.findIndex(
        (phone) => phone.type === PHONE_TYPE.MOBILE,
      );
      if (mobileNumberIndex > -1) {
        this.mobileNumber = this.client.phone[mobileNumberIndex];
      }
    }
    return this.countryCodeService.formatExistingMobileAuNzNumber({
      isInterNationalPhoneNumbers: this.isInternationalPhoneNumbers,
      phoneNumber: this.mobileNumber.number,
    });
  }

  setMobileValidation(value) {
    const isInternationalMobileEnabled = this.isInternationalPhoneNumbers;

    if (isInternationalMobileEnabled) {
      const selectedCountryCode = this.countryCodeService.getSelectedCountryCode(
        `${this.workPhoneInputName}Id`,
      );
      return value
        ? this.countryCodeService.isNumberMobile({
            mobileNumber: value,
            countryCode: selectedCountryCode,
          })
        : false;
    }

    return this.isMobileNumberValid(value);
  }

  onUpdatePhoneValue(key, value, isEdit, cancelCallback) {
    this.isValidMobile = this.setMobileValidation(value);
    this.isPhoneEdit = isEdit;
    this.phoneCancelCallback = cancelCallback;

    this.model.phone = value;
    this.isValid();
    if (!this.isValidMobile) {
      return;
    }

    const countryCode = this.countryCodeService.getAssignedCountryCode({
      isInternationalPhoneNumbers: this.isInternationalPhoneNumbers,
      selectorElement: this.angular.element(`#${this.workPhoneInputName}Id`),
    }).iso2;

    const newMobile = {
      number: value,
      type: PHONE_TYPE.MOBILE,
      countryCode,
    };

    if (this.validateMobile()) {
      const mobilePhone = this.client.phone.find(
        (phone) => phone.type === PHONE_TYPE.MOBILE,
      );

      this.mobileNumber = mobilePhone || newMobile;
      this.mobileNumber.number = value;
      this.mobileNumber.countryCode = countryCode;
    } else {
      this.client.phone = [newMobile];
      this.mobileNumber = newMobile;
    }
  }

  onSaveClientPhoneParam({ phone, id }) {
    const currentEditingUser = this.clients.find(
      (item) => id === item.personId,
    );
    const countryCode = this.countryCodeService.getAssignedCountryCode({
      isInternationalPhoneNumbers: this.isInternationalPhoneNumbers,
      selectorElement: this.angular.element(`#${this.workPhoneInputName}Id`),
    }).iso2;
    const newUserPhoneValue = setMappedContactPhoneByType({
      contact: currentEditingUser,
      countryCode,
      phone,
      phoneType: PHONE_TYPE.MOBILE,
    });
    return this.onSaveClient(newUserPhoneValue);
  }

  onSaveClientEmailParam({ email, id }) {
    const currentEditingUser = this.clients.find(
      (item) => id === item.personId,
    );
    const newUserEmailValue = setMappedContactEmailByType({
      contact: currentEditingUser,
      email,
      emailType: EMAIL_CTYPES.CRM_EMAIL,
    });
    return this.onSaveClient(newUserEmailValue);
  }

  onSaveClient(client = null) {
    this.isEmailEdit = false;
    this.isPhoneEdit = false;

    const param = client || this.client;

    return this.validateEmailInOkta(param);
  }

  validateEmailInOkta(param) {
    this.isOktaCheckEmailLoading = true;
    const { email: emailObj } = param;
    const formattedEmail = get(emailObj, '[0].email');
    if (!formattedEmail) {
      return;
    }
    return this.userAccountModelService
      .getEmailExist(formattedEmail)
      .then(({ isOktaEmailExist, email }) => {
        this.oktaEmailActive = false;
        this.updateOktaEmailExistProp({ isOktaEmailExist, email });
        return !isOktaEmailExist && this.saveClient(param);
      })
      .catch(toastError);
  }

  updateOktaEmailExistProp({ isOktaEmailExist, email }) {
    if (!email) {
      return;
    }
    if (!this.oktaEmailActive) {
      this.oktaEmailActive = isOktaEmailExist;
    }
    this.clients = this.clients.map((client) => {
      const clientEmail = get(client, 'email.[0].email', '');
      if (clientEmail === email) {
        client.isOktaEmailExist = isOktaEmailExist;
      }
      return client;
    });
  }

  saveClient(param) {
    this.saveClientDetail(param).then(() => {
      this.isContactUpdated = true;
      this.isOktaCheckEmailLoading = false;
      toastSaveSuccess();
      this.getFamilyClients();
    });
  }

  saveClientDetail(param) {
    if (this.uiService.helloPackNewClientUpdateEnabled) {
      this.helloBookService.setClient(param).then((response) => response);
    }
    const request = helloBookClientBuilderForMyCRM(param);
    return this.contactService.addContact([request]);
  }

  toggleSection(key, disabled = false) {
    if (disabled) {
      return;
    }
    const { sections } = this.model;
    sections[key] = !sections[key];

    this.specificToggleSectionHandler(key);
    this.isValid();
  }

  toggleIncludeQuote() {
    this.model.sections.isViewQuote = !this.model.sections.isViewQuote;
  }

  specificToggleSectionHandler(key) {
    if (key === TOGGLE_SECTIONS.ONLINE_FACT_FIND) {
      const isOnlineFactFind = this.model.sections.onlineFactFind;
      isOnlineFactFind &&
        this.selectedApplicationId &&
        this.addFamilyClientIsLoanBorrower();

      !isOnlineFactFind && this.resetSelectClient();
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  isValid() {
    const isFactFindExists =
      this.isBrandingReady && this.model.sections.onlineFactFind;
    const isMobilePhoneValid =
      (!isFactFindExists && !this.model.phone) ||
      this.setMobileValidation(this.model.phone);

    const isPhoneValid = isFactFindExists
      ? isMobilePhoneValid && !this.isPhoneEdit
      : true;

    this.isPhoneRequired = isFactFindExists;
    this.isShowWarningPhone = isFactFindExists;

    this.isValidForm =
      isPhoneValid &&
      isValidEmailAddress(this.model.email) &&
      !this.isEmailEdit;

    if (this.helloBookSendToBothClients) {
      this.isValidForm = true;
      this.clients &&
        this.clients.length &&
        this.clients.map((item) => {
          if (
            item.isSelected &&
            (!item.formattedEmail ||
              (this.isPhoneRequired && !item.formattedPhone))
          ) {
            this.isValidForm = false;
          }
          return item;
        });
    }

    if (
      this.model &&
      this.model.sections &&
      this.model.sections.onlineFactFind &&
      this.model.factFindType === this.factFindType.PARTIAL
    ) {
      this.isValidForm = this.isFactFindSectionSelected() && this.isValidForm;
    }

    const isClientNotLoanBorrower =
      !this.helloBookSendToBothClients &&
      this.model.sections.onlineFactFind &&
      !this.client.isLoanBorrower;

    if (isClientNotLoanBorrower) {
      this.isValidForm = false;
    }
  }

  isFactFindSectionSelected() {
    return this.factFindSections.some((section) => section.selected);
  }

  send(sendType) {
    this.isValid();
    if (!this.isValidForm) {
      toastError('Form invalid. Please fill out correctly.');
      return;
    }
    if (this.oktaEmailActive || this.isOktaCheckEmailLoading) {
      return;
    }
    this.setButtonLoading(sendType, true);

    this.setCalendly();
    this.setCreditGuide();
    this.setOnlineFacFind();

    const uploadThenSend = () => this.startUploadThenSend(sendType);
    const isSendApplicationFactFind =
      this.model.sections.onlineFactFind &&
      this.activeFactFindScenarioIds.length &&
      !this.activeFactFindScenarioIds.includes(this.selectedApplicationId) &&
      SEND_HELLO_BOOK_TYPE.NOW === sendType &&
      this.selectedService === SERVICE_OPTION.LENDING;

    if (isSendApplicationFactFind) {
      const personName =
        (this.selectedClient && this.selectedClient.fullName) || '';

      this.actionValidatorService.confirmSendFactFind(
        this.activeFactFindLoans,
        personName,
        uploadThenSend,
      );
      return;
    }

    uploadThenSend();
  }

  startUploadThenSend(sendType) {
    const doSend = () => this.sendHelloPack(sendType);
    this.attachedFiles.length && this.onUploadFiles(doSend);
    !this.attachedFiles.length && doSend();
  }

  setHelloBookLoanId(loanApplicationId) {
    if (!loanApplicationId) {
      this.loanId = 0;
      return;
    }
    this.loanScenarioService
      .scenarioDetailsGet(loanApplicationId)
      .then(({ data }) => {
        this.loanId = data.LoanId || 0;
      });
  }

  sendHelloPack(sendType) {
    this.setButtonLoading(sendType, true);
    const filteredClients = this.clients.filter((item) => item.isSelected);
    const form = {
      clients: this.helloBookSendToBothClients ? filteredClients : [],
      client: this.client,
      factFindSections: this.factFindSections.reduce((accum, section) => {
        accum[section.sectionKey.toLowerCase()] = section.selected;
        return accum;
      }, {}),
      model: this.model,
      adviser: this.adviserInfo,
      editorMessage: this.editorMessage,
      communicationAppendMessage: this.helloBookCreditMessage,
      sendType,
      selectedApplicationId: this.selectedApplicationId,
      documents: this.mergeUploadedDocuments(),
      isForLoanWriters: this.isForLoanWriters,
      loanId: this.loanId,
      ...getSelectedHeadings(this.factFindSections),
    };
    const payload = helloBookSendBuilderForMyCRM(form);
    this.helloBookService
      .send(this.familyId, payload)
      .then((response) => {
        if (!response) {
          return;
        }
        this.setButtonLoading(sendType, false);
        this.contactSharedDataService.loadContactCommunication();
        toastSuccess('Hello Book Sent Sucessfully!');

        performanceTimingData({
          event: `hello-book-send`,
          action: this.isForLoanWriters
            ? HELLO_PACK_SEND_LENDING
            : HELLO_PACK_SEND_INSURANCE,
        });

        if (sendType === SEND_HELLO_BOOK_TYPE.PREVIEW) {
          this.sendPreviewButtonLabel = SEND_PREVIEW_LABEL.SENT;
          const sendPreviewTimeout = this.$timeout(() => {
            this.sendPreviewButtonLabel = SEND_PREVIEW_LABEL.DEFAULT;
            this.$onDestroy = () => this.$timeout.cancel(sendPreviewTimeout);
          }, 3000);
        }
        if (sendType !== SEND_HELLO_BOOK_TYPE.PREVIEW && this.modalInstance) {
          this.modalInstance.close({
            sent: true,
            onlineFactFind: this.model.sections.onlineFactFind,
            isContactUpdated: this.isContactUpdated,
          });
        }
      })
      .catch(() => {
        toastError();
        this.setButtonLoading(sendType, false);
      });
  }

  setButtonLoading(sendType, status) {
    switch (sendType) {
      case SEND_HELLO_BOOK_TYPE.NOW:
        this.isSendNowLoading = status;
        break;
      case SEND_HELLO_BOOK_TYPE.PREVIEW:
        this.isSendPreviewLoading = status;
        break;
      case SEND_HELLO_BOOK_TYPE.SAVE:
        this.isSaveLoading = status;
        break;
      default:
    }
  }

  initSaveDefault() {
    this.setButtonLoading(SEND_HELLO_BOOK_TYPE.SAVE, true);
    const executeSaveDefault = () => this.saveDefault();
    this.onUploadFiles(executeSaveDefault);
  }

  mergeUploadedDocuments() {
    if (!this.documentsUploaded && !this.attachedFiles) {
      return [];
    }

    const newAttachedFiles = this.attachedFiles.filter(
      (file) => file.documentKey,
    );
    return [...newAttachedFiles, ...this.documentsUploaded];
  }

  saveDefault() {
    const isNameFound = this.editorMessage.includes(this.theClientName);
    const replaceMessage = this.editorMessage.replace(
      this.messageSalutation,
      `${this.messageSalutation} ${this.commonNamePlaceholder}`,
    );
    const dynamicMessage = isNameFound
      ? this.editorMessage.replace(
          this.theClientName,
          this.commonNamePlaceholder,
        )
      : replaceMessage;

    const adviserId = this.adviserInfo.familyId;
    const settings = {
      adviserId,
      message: dynamicMessage,
      factFindType: this.model.factFindType,
      ...this.model.sections,
      factFindSections: this.factFindSections.reduce((accum, section) => {
        accum[convertTextToKey(section.name)] = section.selected;
        return accum;
      }, {}),
      documents: this.mergeUploadedDocuments(),
    };

    const payload = helloBookDefaultSettingsForMyCRM(settings);

    this.helloBookService
      .setDefaultSettings(adviserId, payload)
      .then(() => {
        this.setButtonLoading(SEND_HELLO_BOOK_TYPE.SAVE, false);
        toastSuccess('Defaults Saved Sucessfully!');
      })
      .catch(toastError);
  }

  populateLoanList() {
    this.loanOpportunityService.getLoanList(this.familyId).then((response) => {
      if (!response) {
        return;
      }
      const mapLoanProps = (loans) =>
        loans.map((loan) => {
          const isMergeLeadAndOpportunities = this.uiService
            .viewOfAfileTurnedOn;
          const newLoan = addHelloBookPipelineCategory({
            loan,
            isMergeLeadAndOpportunities,
          });
          return helloBookFamilyLoanForUI({
            loan: newLoan,
            isMergeLeadAndOpportunities,
            userCountryCode: this.currentUserService.countryCode,
          });
        });

      const filterLoans = (loans) =>
        loans.filter(
          (loan) =>
            loan.loanScenarioId &&
            !this.disableFactFindStatusList.includes(loan.loanStatusId),
        );

      const pipe = flow([mapLoanProps, filterLoans]);
      this.familyLoans =
        orderBy(pipe(response), ['createdDate'], ['desc']) || [];

      if (!this.familyLoans.length) {
        this.familyLoans.splice(0, 0, {
          loanScenarioId: 0,
          loanDisplayLabel: 'New Loan Application',
        });
      }

      this.setDefaultLeadLoan();
      this.getLoanApplicationList();
      this.setIsClientLoanBorrower();
    });
  }

  setIsClientLoanBorrower(isFromDropdown) {
    !this.selectedApplicationId && this.resetSelectClient();
    this.selectedApplicationId &&
      this.addFamilyClientIsLoanBorrower(isFromDropdown);
    isFromDropdown && this.setHelloBookLoanId(this.selectedApplicationId);
  }

  setDefaultLeadLoan() {
    const [firstLoan] = this.familyLoans;
    this.isLeadLoanOnly = this.familyLoans.length === 1 && firstLoan.isLead;

    const leadLoan = this.familyLoans.find((loan) => loan.isLead);

    this.selectedApplicationId =
      (leadLoan && leadLoan.loanScenarioId) ||
      (firstLoan && firstLoan.loanScenarioId) ||
      0;
    this.setHelloBookLoanId(this.selectedApplicationId);
  }

  getLoanBorrowers() {
    if (!this.selectedApplicationId) {
      return;
    }

    const selectedLoanApp = this.familyLoans.find(
      (loan) =>
        parseToInt10(loan.loanScenarioId) ===
        parseToInt10(this.selectedApplicationId),
    );

    if (!selectedLoanApp) {
      this.logger.error(
        'Hello Book getLoanBorrower: selected loan not found in family loans',
      );
      return;
    }

    return selectedLoanApp.borrowers.map((borrower) => {
      return borrower.personId || borrower.entityId;
    });
  }

  addFamilyClientIsLoanBorrower(isFromDropdown) {
    const borrowerListIds = this.getLoanBorrowers();
    if (!borrowerListIds) {
      return;
    }
    const mappedClients = this.clients.map((client) => {
      if (borrowerListIds.includes(parseToInt10(client.personId))) {
        return {
          ...client,
          isSelected: true,
          isDisabled: false,
          isLoanBorrower: true,
        };
      }

      return {
        ...client,
        isSelected: false,
        isDisabled: true,
        isLoanBorrower: false,
      };
    });

    this.clients = mappedClients;

    const isForceUpdateClient =
      !this.helloBookSendToBothClients && isFromDropdown && this.client;
    if (isForceUpdateClient) {
      this.client = this.clients.find(
        (client) =>
          parseToInt10(client.personId) === parseToInt10(this.client.personId),
      );
    }
  }

  resetSelectClient() {
    this.clients = this.clients.map((client) => {
      return {
        ...client,
        isSelected: true,
        isDisabled: false,
        isLoanBorrower: true,
      };
    });
  }

  getLoanApplicationList() {
    this.loanScenarioService
      .scenarioListGet(this.familyId, '')
      .then(({ data }) => {
        if (!data) {
          return;
        }

        this.activeFactFindScenarioIds =
          data
            .filter((loan) => loan.IsLockable && !loan.IsLocked)
            .reduce((accum, current) => {
              accum.push(parseInt(current.LoanScenarioId, 10));
              return accum;
            }, []) || [];

        this.activeFactFindLoans = this.familyLoans.filter((loan) => {
          const id = parseInt(loan.loanScenarioId, 10);
          return this.activeFactFindScenarioIds.includes(id);
        });
      });
  }

  onSelectFileAttachments(event) {
    const attachedFileList = event.target.files;
    const isFileLimit =
      attachedFileList.length > FILE_ATTACHMENT_LIMIT ||
      attachedFileList.length + this.attachedFiles.length >
        FILE_ATTACHMENT_LIMIT;

    if (
      this.showFileExtensionError(attachedFileList) ||
      this.showFileLimitError(isFileLimit)
    ) {
      return;
    }

    this.hasInvalidFileExtension = false;

    // eslint-disable-next-line unicorn/no-for-loop
    for (let i = 0; i < attachedFileList.length; i++) {
      const file = attachedFileList[i];
      this.attachedFiles.push({
        name: file.name,
        index: i,
        showConfirmDeletePopover: false,
        file,
      });
    }
  }

  showFileExtensionError(fileList) {
    for (const file of fileList) {
      const ext = extractFileExtension(file.name);
      if (!HELLOBOOK_VALID_EXTENSIONS.includes(ext)) {
        this.hasInvalidFileExtension = true;
        break;
      }
    }

    if (fileList.length && this.hasInvalidFileExtension) {
      toastError(HELLO_BOOK.INVALID_FILE_EXT);
      return true;
    }

    return false;
  }

  showFileLimitError(condition) {
    if (
      (this.attachedFiles &&
        this.attachedFiles.length > FILE_ATTACHMENT_LIMIT) ||
      condition
    ) {
      toastError(HELLO_BOOK.FILE_LIMIT);
      return true;
    }

    return false;
  }

  onUploadFiles(callback) {
    const files = this.attachedFiles.filter(
      (item) => !item.isQueued && !item.documentKey,
    );
    if (!files.length) {
      callback && callback();
      return;
    }

    // override default complete all handler
    const originalCompleteAllCallback = this.fileUploadCtrl.uploader
      .onCompleteAll;
    this.fileUploadCtrl.uploader.onCompleteAll = () => {
      originalCompleteAllCallback();
      this.documentsUploaded = this.fileUploadCtrl.uploader.uploadedItems.map(
        (item) => {
          return {
            DocumentId: item.DocumentId,
            DocumentKey: item.DocumentKey,
            Name: item.Name,
          };
        },
      );
      callback && callback();
    };

    files.forEach((item) => {
      item.isQueued = true;
      this.fileUploadCtrl.uploader.addToQueue(item.file);
    });

    if (!this.fileUploadCtrl.isPresignedUrl) {
      this.fileUploadCtrl.uploader.uploadAll();
    }
  }

  onConfirmPopoverKeydown(event, isCancelled = true) {
    if (event.keyCode === KEY_CODE.ESC) {
      this.onResetDeletePopover();
    } else if (
      !isCancelled &&
      [KEY_CODE.SPACE, KEY_CODE.WIN_ENT].includes(event.keyCode)
    ) {
      this.onRemoveSelectedFileAttachment();
    }
  }

  onResetDeletePopover() {
    // reset showConfirmDelete to hide other popover
    this.attachedFiles.forEach((file) => {
      file.showConfirmDeletePopover = false;
    });
  }

  onShowConfirmDelete(index, file) {
    this.onResetDeletePopover();

    file.showConfirmDeletePopover = true;
    this.itemForDeleteIndex = index;
  }

  onRemoveSelectedFileAttachment() {
    if (
      typeof this.itemForDeleteIndex === 'undefined' ||
      this.itemForDeleteIndex === null
    ) {
      return;
    }

    this.attachedFiles = removeElementOnIndex(
      this.itemForDeleteIndex,
      this.attachedFiles,
    );
  }

  onCancel() {
    this.modalInstance.close({
      isCancelled: true,
      isContactUpdated: this.isContactUpdated,
    });
  }

  showNotLoanBorrowerWarning(client) {
    return !!(this.model.sections.onlineFactFind && !client.isLoanBorrower);
  }
}
