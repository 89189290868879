import angular from 'angular';
import { COUNTRY_SHORTNAME } from 'Common/constants/countryType';
import { phoneType, VALID_MOBILE_TYPES } from 'Common/constants/phoneType';
import { intlTelInputErrorIndex } from 'Common/constants/intlTelInputErrorIndex';
import { toastError } from 'Common/utilities/alert';
import { formatExistingMobile } from 'Common/utilities/mobile';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';

class CountryCodeService {
  constructor(currentUserService, configService) {
    'ngInject';

    this.defaultCode = '';
    this.currentUserService = currentUserService;
    this.countryShorthandName = COUNTRY_SHORTNAME;
    this.errorMap = [
      'Invalid number',
      'Invalid country code',
      'Too short',
      'Too long',
      'Invalid',
    ];
    this.angular = angular;
    this.configService = configService;
    this.toastError = toastError;
    this.toasterFormTitle = 'Invalid Form';
    this.intlTelInputErrorIndex = intlTelInputErrorIndex;
    this.formatExistingMobile = formatExistingMobile;
    this.parsePhoneNumberFromString = parsePhoneNumberFromString;
  }

  isIntlTelPhone() {
    return this.configService.feature.internationalPhoneNumbers;
  }

  formatExistingMobileAuNzNumber({ isInternationalPhoneNumbers, phoneNumber }) {
    if (isInternationalPhoneNumbers) {
      return this.formatExistingMobile(phoneNumber);
    }

    return phoneNumber;
  }

  isMobileInputDropdown() {
    return (
      this.currentUserService.isNZ &&
      this.configService.feature.phoneNumberCountryCodeNZ
    );
  }

  getAssignedCountryCode({ isInternationalPhoneNumbers, selectorElement }) {
    const country = this.setDefaultCountryCode(isInternationalPhoneNumbers);
    const isCountryCodeEmpty = Object.keys(
      selectorElement.intlTelInput('getSelectedCountryData'),
    ).length;
    const isCountryCodeValid =
      isInternationalPhoneNumbers &&
      selectorElement.length &&
      isCountryCodeEmpty;

    return isCountryCodeValid
      ? selectorElement.intlTelInput('getSelectedCountryData')
      : { iso2: country };
  }

  initializeCountryCode({ details, isInternationalPhoneNumbers }) {
    const countryCode = this.setDefaultCountryCode({
      isInternationalPhoneNumbers,
      defaultCountryCode: this.currentUserService.isAU,
    });
    if (details.Phone.length && details.Phone[0].CountryCode) {
      return details.Phone[0].CountryCode.toLowerCase();
    }
    return countryCode;
  }

  setDefaultCountryCode({ isInternationalPhoneNumbers, defaultCountryCode }) {
    if (isInternationalPhoneNumbers && defaultCountryCode) {
      return this.getShortHandNamedCountryCode(defaultCountryCode);
    }

    return this.getShortHandNamedCountryCode(this.currentUserService.isAU);
  }

  getShortHandNamedCountryCode(isCountryAU) {
    return isCountryAU
      ? this.countryShorthandName.AUSTRALIA.toLowerCase()
      : this.countryShorthandName.NEW_ZEALAND.toLowerCase();
  }

  setMessageForEmptyInput({ index, message }) {
    const errorMessage = message || 'Phone number is required';
    return index === this.intlTelInputErrorIndex.inputEmpty
      ? `${this.errorMap[index]} - ${errorMessage}`
      : this.errorMap[index];
  }

  isNumberMobile({ mobileNumber, countryCode }) {
    if (!mobileNumber) {
      return true;
    }

    const selectedCountryCode =
      (countryCode.iso2 && countryCode.iso2.toUpperCase()) || '';

    if (!selectedCountryCode) {
      return true;
    }

    const phoneNumber = this.parsePhoneNumberFromString(
      `${mobileNumber}`,
      selectedCountryCode,
    );

    return phoneNumber && VALID_MOBILE_TYPES.includes(phoneNumber.getType());
  }

  countryInputSelectorValidation({
    element,
    isPhoneRequired,
    message,
    phoneNumber,
    isLandline,
  }) {
    const isInvalid = element.intlTelInput('getValidationError');
    const isValid = element.intlTelInput('isValidNumber');
    const countryCode = element.intlTelInput('getSelectedCountryData');
    const validMessage = 'Phone number valid.';
    const isNumberMobile =
      isLandline ||
      this.isNumberMobile({
        mobileNumber: phoneNumber,
        countryCode,
      });

    if (isValid) {
      return !isNumberMobile
        ? {
            isValid: isNumberMobile,
            message: 'Error - Landline numbers are not allowed',
          }
        : { isValid, message: validMessage };
    } else if (
      !isPhoneRequired &&
      isInvalid === this.intlTelInputErrorIndex.inputEmpty
    ) {
      return { isValid: true, message: validMessage };
    }
    return {
      isValid,
      message: this.setMessageForEmptyInput({ index: isInvalid, message }),
    };
  }

  validatePhoneNumber({
    element,
    phoneNumber,
    isPhoneRequired,
    message,
    isOtherFieldInvalid,
    showToaster = true,
    isLandline = false,
  }) {
    const validation = this.countryInputSelectorValidation({
      element,
      isPhoneRequired,
      message,
      phoneNumber,
      isLandline,
    });
    if (validation.isValid || (isOtherFieldInvalid && !phoneNumber)) {
      return true;
    }

    if (showToaster) {
      this.toastError(validation.message);
    }

    return false;
  }

  validatePhoneNumberFormat({
    element,
    isPhoneRequired,
    message,
    phoneNumber,
    isLandline = false,
  }) {
    const validation = this.countryInputSelectorValidation({
      element,
      isPhoneRequired,
      message,
      phoneNumber,
      isLandline,
    });

    if (!validation.isValid) {
      this.toastError(validation.message);
      return false;
    }
    return true;
  }

  homePhoneCountryCode() {
    return this.setDefaultCountryCode({
      isInternationalPhoneNumbers:
        this.isIntlTelPhone || this.isMobileInputDropdown,
      defaultCountryCode: this.currentUserService.isAU,
    });
  }

  getSelectedCountryCode(inputId) {
    return this.angular
      .element(`#${inputId}`)
      .intlTelInput('getSelectedCountryData');
  }

  getInputElement(inputId) {
    return this.angular.element(`#${inputId}`)[0];
  }

  isInternationalPhoneCountryCode(inputType) {
    if (
      this.currentUserService.isNZ &&
      this.configService.feature.phoneNumberCountryCodeNZ &&
      !this.configService.feature.internationalPhoneNumbers
    ) {
      return inputType.toLowerCase() === phoneType.mobile.toLowerCase()
        ? this.configService.feature.phoneNumberCountryCodeNZ
        : false;
    }
    return this.configService.feature.internationalPhoneNumbers;
  }
}

export default CountryCodeService;
