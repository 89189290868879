import angular from 'angular';
import _ from 'lodash';

import {
  showLoanStructureEdit,
  borrowersDetailsGet,
  loanStructureDetailsMapper,
  inlineFormFunctions,
  getLoanTermMonthly,
  updateImportantDates,
  getRateTypeByCountry,
} from 'Common/utilities/loanStructure';
import { REPAYMENTS, RATE_TYPE } from 'Common/constants/loan';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('LoanStructureCtrl', function LoanStructureCtrl(
      $scope,
      contactService,
      SweetAlert,
      $timeout,
      $rootScope,
      $window,
      toaster,
      contactSharedData,
      optionsService,
      loanInformationService,
      configService,
      currentUserService,
      broadcastDataService,
      loanProfilerService,
      loanStructureService,
    ) {
      /*
       * MODELS
       */ // ///////
      const {
        productDiscountRate,
        loanSplitRepaymentHoliday,
      } = configService.feature;
      const { countryId, isNZ } = currentUserService;
      $scope.productDiscountRate = productDiscountRate;
      $scope.loanSplitRepaymentHoliday = loanSplitRepaymentHoliday;
      $scope.numberToMonthYearText = contactSharedData.numberToMonthYearText;
      angular.extend($scope, {
        isLoanSplit:
          $scope.loanModalTab === 'loan-splits' && productDiscountRate,
        userCountryId: countryId,
        format: 'dd MMM yyyy',
        momentFormat: 'DD MMM YYYY',
        altInputFormats: ['d!/M!/yyyy', 'd!M!yyyy'],
        dateOptions: { formatYear: 'yy', showWeeks: false },
        LoanStructureListGet: [],
        updateRefixDetails: [],
        CurrentFixedRateGet: [],
        LoanDetails: {},
        selectLoanType: [
          'Principal and Interest',
          'Interest Only',
          RATE_TYPE.REVOLVING_CREDIT,
        ],
        selectTerms: [0.25, 0.5, 0.75, 1.5], // Loan Type select options
        selectRateType: getRateTypeByCountry(isNZ),
        selectRepaymentFreq: REPAYMENTS, // Repayment Frequency select option
        RateExpireInMonths: ['Year', 'Month'], // Rate expiry months/year
      });
      if ($scope.isLoanSplit) {
        inlineFormFunctions({
          $scope,
          broadcastDataService,
          contactService,
          loanProfilerService,
        });
        borrowersDetailsGet({ $scope, contactService });
        getLoanTermMonthly({ $scope, loanStructureService });
        $scope.updateImportantDates = updateImportantDates({ $scope });
      }

      // Open Date Popup
      function openDateStructures(dateField, structureId) {
        if (_.isUndefined($scope.datePopUp[dateField])) {
          $scope.datePopUp[dateField] = [];
        }
        $scope.datePopUp[dateField][structureId] = !$scope.datePopUp[dateField][
          structureId
        ];
      }
      function dataInitializer() {
        for (let i = 1; i < 31; i++) {
          $scope.selectTerms.push(i);
        }
        optionsService.getLoanTerms(true).then((loanTermResponse) => {
          $scope.loanTermsList = [
            { value: 0, name: 'None' },
            ...loanTermResponse,
          ];
        });
      }
      /*
       * METHODS
       */ angular.extend($scope, {
        // eslint-disable-next-line sonarjs/no-use-of-empty-return-value
        openDateStructures: openDateStructures(),
      }); // /////// // Terms select options

      /*
       * INITIALIZER
       */ $scope.checkValidTerm = function (termParam) {
        let term = termParam;
        const validTerm = $scope.selectTerms.filter((termList) => {
          return termList === term;
        });

        if (_.isUndefined(validTerm[0])) {
          term = $scope.selectTerms[0];
        }
      };

      $scope.toggleExpireInMonths = function () {
        $timeout(() => {
          $scope.showAllExpireInMonths = !$scope.showAllExpireInMonths;
        }, 100);
      };

      $scope.assignBorrowerTag = function (listBorrowerValues) {
        const borrowerIdList = [];
        _.each(listBorrowerValues, (value) => {
          // let borrowerInputTag = [];
          if (!borrowerIdList.includes(value.BorrowerID)) {
            borrowerIdList.push(value.BorrowerID);
          }
        });

        return borrowerIdList;
      };

      $scope.assignBorrowerTagNames = function (listBorrowerValues) {
        const borrowerIdList = [];
        let borrowerInputTagNames = '';

        _.each(listBorrowerValues, (value) => {
          if (value.IsInclude) {
            let tagName = '';
            // let borrowerInputTag = [];
            if (!borrowerIdList.includes(value.BorrowerID)) {
              borrowerIdList.push(value.BorrowerID);
              if (value.FirstName) {
                tagName += `${value.FirstName} `;
              }
              if (value.LastName) {
                tagName += value.LastName;
              }

              borrowerInputTagNames += `${
                tagName.trim() === '' ? 'No Entity Name' : tagName
              }, `;
            }
          }
        });

        borrowerInputTagNames = borrowerInputTagNames.slice(0, -2);
        return borrowerInputTagNames;
      };
      $scope.loanStructureDataField = () => {
        $scope.loanSplitLoader = true; /* set this variable to true first so it will display the loader */
        if (!$scope.params.loanId) {
          $scope.loanSplitLoader = true;
          return;
        }
        contactService
          .loanDetailsGet($scope.params.loanId)
          .then((response) => {
            const loanDetails = response.data;
            $scope.LoanDetails = loanDetails;
            $scope.loanSplitLoader = false;
          })
          .then(() => {
            loanInformationService.loanStructureList = [];
            _.each(
              $scope.LoanDetails.loan.LoanStructureList,
              (objLoanStructure) => {
                $scope.loanSplitLoader = true; // objLoanStructure has object so show loader
                contactService
                  .loanStructureDetailsGet(
                    $scope.params.loanId,
                    objLoanStructure.LoanStructureId,
                  )
                  .then(({ data }) => {
                    const loanStructureDetails = loanStructureDetailsMapper({
                      $scope,
                      data,
                    });
                    loanInformationService.loanStructureList.push(
                      loanStructureDetails,
                    );
                    loanStructureDetails.InterestRateTerm =
                      objLoanStructure.InterestRateTerm;
                    loanStructureDetails.LoanStructureId =
                      objLoanStructure.LoanStructureId;
                    loanStructureDetails.borrowerInputTag = $scope.assignBorrowerTag(
                      loanStructureDetails.Borrowers,
                    );
                    loanStructureDetails.borrowerInputTagNames = $scope.assignBorrowerTagNames(
                      loanStructureDetails.Borrowers,
                    );
                    loanStructureDetails.IsModifying = false;
                    loanStructureDetails.IsSavingLoader = false;
                    loanStructureDetails.IsDetailsTab = true;
                    loanStructureDetails.Lender =
                      $scope.LoanDetails.loan.Lender;
                    loanStructureDetails.MinimumRepaymentAmount = (
                      objLoanStructure.MinimumRepaymentAmount || 0
                    ).toFixed(2);
                    $scope.loanStructureDetailsGet(loanStructureDetails, 'all');
                  });
              },
            );
          });
      };

      // $scope.loanStructureDataField();/* to double check if the list has a content or none */

      $rootScope.$on('reloadLoanSplitToLoanStructure', () => {
        $scope.LoanDetails = [];
        $scope.LoanStructureListGet = [];
        $scope.loanStructureDataField();
      });

      $scope.loanStructureDetailsGet = (loanStructureDetails, action) => {
        loanStructureDetails.CurrentFixedRateGet = {
          RateId: 0,
          LoanStructureId: loanStructureDetails.LoanStructureId,
          RateBegin: new Date(),
          RateEnd: new Date(),
          InterestPeriod: 1,
          PeriodType: $scope.RateExpireInMonths[0],
          InterestRate: 0.1,
          IsRefix: true,
        };
        contactService
          .loanStructureChangeLog(
            $scope.params.loanId,
            loanStructureDetails.LoanStructureId,
          )
          .then((response) => {
            const tempChangeLog = response.data;
            const uniqueDateObject = _.uniqBy(tempChangeLog, 'DateChanged');
            const dateArray = [];
            _.forEach(uniqueDateObject, (object) => {
              dateArray.push(object.DateChanged);
            });
            const dateObject = [];
            _.forEach(dateArray, (value) => {
              dateObject.push({ DateChanged: value, DescriptionList: [] });
            });

            let dateObjectCounter = 0;
            _.forEach(tempChangeLog, (object) => {
              if (
                object.DateChanged === dateObject[dateObjectCounter].DateChanged
              ) {
                dateObject[dateObjectCounter].DescriptionList.push(
                  object.Description,
                );
              } else {
                dateObjectCounter++;
                dateObject[dateObjectCounter].DescriptionList.push(
                  object.Description,
                );
              }
            });
            loanStructureDetails.ChangeLog = dateObject;
          });

        contactService
          .CurrentFixedRateGet(loanStructureDetails.LoanStructureId)
          .then((response) => {
            const data = response.data;
            if (
              _.isUndefined(data.RateBegin) &&
              _.isUndefined(data.RateEnd) &&
              _.isUndefined(data.InterestPeriod)
            ) {
              loanStructureDetails.CurrentFixedRateGet.IsRefix = false;
            }

            if (!_.isUndefined(data.RateBegin)) {
              data.RateBegin = new Date(data.RateBegin);
            } else {
              data.RateBegin = new Date();
            }

            if (!_.isUndefined(data.RateEnd)) {
              data.RateEnd = new Date(data.RateEnd);
            }

            if (!_.isUndefined(data.InterestPeriod)) {
              loanStructureDetails.CurrentFixedRateGet.InterestPeriod =
                data.InterestPeriod;
            }

            if (data.InterestRate !== 0) {
              loanStructureDetails.CurrentFixedRateGet.InterestRate =
                data.InterestRate;
            }

            loanStructureDetails.CurrentFixedRateGet.RateBegin = data.RateBegin;
            loanStructureDetails.CurrentFixedRateGet.RateEnd = data.RateEnd;
            loanStructureDetails.CurrentFixedRateGet.PeriodType =
              data.PeriodType;

            if (
              loanStructureDetails.LoanStructureType ===
                RATE_TYPE.REVOLVING_CREDIT &&
              isNZ
            ) {
              loanStructureDetails.InterestRateType =
                RATE_TYPE.REVOLVING_CREDIT;
            }

            // added for update dates in refix
            $scope.updateRefixDetails.push(
              loanStructureDetails.CurrentFixedRateGet,
            );

            contactService
              .FixedRateHistoryGet(loanStructureDetails.LoanStructureId)
              .then((fixedRateHistoryGetResponse) => {
                showLoanStructureEdit({ $scope, loanStructureDetails });

                loanStructureDetails.FixedRateHistory =
                  fixedRateHistoryGetResponse.data;
                if (action === 'all') {
                  $scope.LoanStructureListGet.push(loanStructureDetails);
                  $scope.LoanStructureListGet.sort(
                    (a, b) => a.LoanStructureId - b.LoanStructureId,
                  );
                }
                $scope.loanSplitLoader = false; // hide loader
              });
          });

        if (loanStructureDetails.RateTerm < 1) {
          loanStructureDetails.TempRateTerm =
            loanStructureDetails.RateTerm * 12;
          loanStructureDetails.TempPeriodType = 'Months';
        } else {
          loanStructureDetails.TempRateTerm = loanStructureDetails.RateTerm;
          loanStructureDetails.TempPeriodType = 'Year(s)';
        }
      };

      $scope.saveFixRate = function (modifyObj) {
        modifyObj.CurrentFixedRateGet.InterestRate /= 100;
        modifyObj.IsSavingLoader = true;
        $scope.IsSavingLoader = true;

        contactService.FixedRatesSet(modifyObj.CurrentFixedRateGet).then(() => {
          if (modifyObj.CurrentFixedRateGet.IsRefix) {
            toaster.pop(
              'success',
              'Added',
              'Successfully added new interest rate',
            );
          } else {
            toaster.pop(
              'success',
              'Updated',
              'Successfully updated interest rate',
            );
          }
          modifyObj.IsSavingLoader = false;
          $scope.IsSavingLoader = false;
          modifyObj.IsModifying = false;
          modifyObj.IsDetailsTab = false;
          $scope.loanStructureDetailsGet(modifyObj, 'edit');
        });
      };

      /*
       * Get Loan Info only if action is not to create
       * */
      if ($scope.params.loanInformationAction !== 'create') {
        $scope.loanStructureDataField();
      }

      $scope.deleteLoanStructure = function (loanStructureId) {
        SweetAlert.swal(
          {
            title: 'Are you sure?',
            text: 'This record will be removed from your loan structure list',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: true,
          },
          (confirm) => {
            if (confirm) {
              contactService
                .loanStructureDelete($scope.params.loanId, loanStructureId)
                .then(() => {
                  $timeout(() => {
                    $scope.loanDataField();
                    $rootScope.$emit('reloadLoanSplitToLoanStructure');
                    toaster.pop(
                      'success',
                      'Success',
                      'Loan Structure has been been successfully deleted.',
                    );
                  }, 100);
                });
            }
          },
        );
      };

      $scope.editLoanSplit = false;
      $scope.addLoanVar = true;
      $scope.toggleSplitLoader = () => {
        $scope.loanSplitLoader = !$scope.loanSplitLoader;
      };
      $scope.editLoanStructure = () => {
        $scope.editLoanSplit = !$scope.editLoanSplit;
      };
      $scope.addLoanStructure = () => {
        $scope.addLoanVar = !$scope.addLoanVar;

        if (!$scope.addLoanVar) {
          $scope.scrollTimeout = $timeout(() => {
            function scrollTo(element, to, duration) {
              if (duration <= 0) {
                return;
              }
              const difference = to - element.scrollTop;
              const perTick = (difference / duration) * 10;

              $window.requestAnimationFrame(() => {
                element.scrollTop += perTick;
                if (element.scrollTop === to) {
                  return;
                }
                scrollTo(element, to, duration - 10);
              }, 10);
            }

            const targetContextInt = $window.document.querySelector(
              '#loan_structure_add_edit_form',
            ).offsetTop;
            const scrollable = $window.document.querySelector(
              '#loan-split-tab-scrollable',
            );

            scrollTo(scrollable, targetContextInt, 300);
          }, 1000);
        }
      };

      $scope.$on('$destroy', () => {
        if ($scope.scrollTimeout) {
          $timeout.cancel($scope.scrollTimeout);
        }
      });

      $scope.loanStructureModal = function (action, loanstructure) {
        if (action === 'edit') {
          $scope.editLoanStructure();
        }
        if (action === 'create') {
          $scope.addLoanStructure();
        }
        $scope.params.loanStructureAction = action;
        if (loanstructure) {
          $scope.params.loanstructureDataBorrowersList =
            loanstructure.Borrowers;
          $scope.params.structureId = loanstructure.LoanStructureId;
          $scope.params.Lender = loanstructure.Lender;
          $scope.params.CurrentFixedRateGet = loanstructure.CurrentFixedRateGet;
          const notExistingProductID =
            $scope.params &&
            $scope.params.LoanSplitsProductList &&
            !_.find($scope.params.LoanSplitsProductList, {
              ProductID: loanstructure.ProductID,
            });
          if (notExistingProductID) {
            $scope.params.LoanSplitsProductList.push({
              ProductID: loanstructure.ProductID,
              ProductName: loanstructure.Product,
            });
          }

          if ($scope.loanDetails) {
            $scope.params.providerID = $scope.loanDetails.loan.Lender.LenderId;
          }
        } else {
          $scope.params.structureId = 0;
        }
      };

      $scope.autoAddExpiry = function (value, valueIndex) {
        $scope.LoanStructureListGet[
          valueIndex
        ].CurrentFixedRateGet.RateEnd = new Date(
          $scope.LoanStructureListGet[valueIndex].CurrentFixedRateGet.RateBegin,
        );

        if (
          $scope.LoanStructureListGet[valueIndex].CurrentFixedRateGet
            .PeriodType === 'Month'
        ) {
          $scope.LoanStructureListGet[
            valueIndex
          ].CurrentFixedRateGet.RateEnd.setMonth(
            $scope.LoanStructureListGet[
              valueIndex
            ].CurrentFixedRateGet.RateBegin.getMonth() +
              parseInt(
                $scope.LoanStructureListGet[valueIndex].CurrentFixedRateGet
                  .InterestPeriod,
                10,
              ),
          );
        } else if (
          $scope.LoanStructureListGet[valueIndex].CurrentFixedRateGet
            .PeriodType === 'Year'
        ) {
          $scope.LoanStructureListGet[
            valueIndex
          ].CurrentFixedRateGet.RateEnd.setYear(
            $scope.LoanStructureListGet[
              valueIndex
            ].CurrentFixedRateGet.RateBegin.getFullYear() +
              parseInt(
                $scope.LoanStructureListGet[valueIndex].CurrentFixedRateGet
                  .InterestPeriod,
                10,
              ),
          );
        }
      }; // ///////
      /*
       * METHODS
       */ angular.extend($scope, {
        // eslint-disable-next-line sonarjs/no-use-of-empty-return-value
        openDateStructures: openDateStructures(),
      });

      function init() {
        dataInitializer();
      } // ////////

      /*
       * RUN INITIALIZERS
       */ init();

      $scope.detailTabChange = (value, value2) => {
        $scope[`rateIndexTab_${value}`] = value2;
      };

      $scope.detailTabCheck = (value, value2) => {
        return $scope[`rateIndexTab_${value}`] === value2;
      };

      $scope.getLoanAmtTotal = (data) => {
        const value = parseFloat(data.Value || 0);
        return data.IsCapitaliseLMI ? value + parseFloat(data.LMI || 0) : value;
      };
    });
