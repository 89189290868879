import Rox from 'rox-browser';
import { rolloutFeatureFlags as flags } from 'Common/config/feature';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { BROKER_STATUS } from 'Common/constants/brokerStatus';
import { getCountryProperty } from 'Common/utilities/countrySpecific';
import { getUserInfoData } from 'Common/utilities/user';

let configService = {};
let uiService = {};
export const currentUserInPilotRolloutFeatureFlags = {};
export const inPilotRolloutFeatureFlags = {};

export const mappedRolloutProperties = () => {
  const user = getUserInfoData();
  return {
    'Adviser Branding': user.BrandingCategory,
    'Adviser Org ID': user.AdviserOrgID,
    'Adviser Org Name': user.AdviserOrgName || '',
    'User Country': getCountryProperty(user.CountryId),
    'User ID': user.FamilyId,
    'User is Principal Adviser':
      user.AccessType === ACCESS_TYPE.PRINCIPAL_ADVISER,
    'User is Adviser': [
      ACCESS_TYPE.PRINCIPAL_ADVISER,
      ACCESS_TYPE.ADVISER,
    ].includes(user.AccessType),
    'User is Admin Assistant': user.AccessType === ACCESS_TYPE.ADMIN_ASSISTANT,
    'User is Assistant': [
      ACCESS_TYPE.ADMIN_ASSISTANT,
      ACCESS_TYPE.ASSISTANT,
    ].includes(user.AccessType),
    'User is Corporate': user.AccessType === ACCESS_TYPE.CORPORATE,
    'User Name': user.Email,
    'Broker Status ID': user.BrokerStatusID || '',
    'Broker Status': BROKER_STATUS[user.BrokerStatusID] || '',
    'User State': user.ReportingStateName || '',
  };
};

const setCustomProperty = (key, value) => {
  switch (typeof value) {
    case 'boolean':
      Rox.setCustomBooleanProperty(key, value);
      break;
    case 'string':
      Rox.setCustomStringProperty(key, value === '' ? 'NULL' : value);
      break;
    case 'number':
      if (!value) {
        return;
      }
      Rox.setCustomNumberProperty(key, value);
      break;
    default:
      break;
  }
};

const pushRolloutProperties = () => {
  const props = mappedRolloutProperties();

  Object.keys(props).forEach(
    (key) => key && setCustomProperty(key, props[key]),
  );
};

// called in main ctrl
export const setConfigServiceInstance = (instance) => {
  configService = instance;
};

// called in uiservice
export const setUIServiceInstance = (instance) => {
  uiService = instance;
};

export const setOnRolloutFeatureFlagsUpdate = (featureFlags) => {
  const newFeatureList = { ...configService.feature, ...featureFlags };
  configService &&
    configService.feature &&
    (configService.feature = newFeatureList);

  uiService && uiService.setFeatures();
};

export function getRolloutFeatureFlags() {
  const mappedFlags = {};
  pushRolloutProperties();
  Object.keys(flags).forEach((key) => {
    const isFlagEnabled = flags[key].isEnabled();
    const isPilotFlagEnabledForUser =
      isFlagEnabled && inPilotRolloutFeatureFlags[key];

    mappedFlags[key] = isFlagEnabled;

    if (isPilotFlagEnabledForUser) {
      currentUserInPilotRolloutFeatureFlags[key] = true;
    }
  });

  return mappedFlags;
}

let alreadySetup = false;

export async function setupRollout({ rolloutKey }) {
  if (!rolloutKey || alreadySetup) {
    return;
  }

  alreadySetup = true;
  Rox.register('', flags);
  await Rox.setup(rolloutKey, {
    freeze: 'none',
    impressionHandler: (reporting, experiment) => {
      const isFeatureFlagInPilot =
        experiment &&
        !experiment.isArchived &&
        !inPilotRolloutFeatureFlags[experiment.name];

      if (isFeatureFlagInPilot) {
        inPilotRolloutFeatureFlags[experiment.name] = true;
      }
    },
    configurationFetchedHandler: (fetcherResults) => {
      if (fetcherResults.hasChanges) {
        setOnRolloutFeatureFlagsUpdate(getRolloutFeatureFlags());
      }
    },
  });
}
