import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import swal from 'sweetalert';
import $ from 'jquery';
import { CONTACT_TYPE } from 'Common/constants/contactType';
import { ADVISER_STATUS } from 'Common/constants/adviserStatus';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import {
  adviserDetailsBuilderForUI,
  adviserLeadsBuilderForAPI,
  adviserBrokerSupportBuilderForAPI,
} from 'Common/mappers/corporate';
import { advisersLookupBuilderForUI } from 'Common/mappers/general';

import { ADDRESS_TYPE } from 'Common/constants/addressType';
import {
  REFERRAL_ITEM,
  CLIENT_REFERRAL,
  ADDITIONAL_REFERRAL,
  REFERRED_BY_ACCESS,
  HAS_LEADBILLING_CATEGORY,
} from 'Common/constants/enquirySource';
import { NO_BRANDING_CATEGORY_WARNING } from 'Common/constants/promptMessages';
import { displayField } from 'Common/utilities/displayField';
import { displayWarning } from 'Common/utilities/alert';
import { ASSISTANT_STATUS_LIST } from 'Common/constants/assistantConstant';
import { UNIX_MULTIPLIER } from 'Common/constants/dateConfigs';
import { setupNewPostURL } from 'Common/utilities/handleObsoleteURL';
import { setAssistantsList } from 'Common/utilities/allocation';
import { isLMBranded, isOwnBrand } from 'Common/utilities/brandingCategory';
import { getContactStatusLabel } from 'Common/utilities/client';
import {
  isReferrerOrgFieldsValid,
  isBusinessTypeOthers,
  getDefaultLeadBillingId,
  getCurrentScheme,
  getAccountOwnerLabel,
  getHasRole,
  hasValue,
  onInit,
  isEMocaUnit,
  validateAdviserBusinessAddress,
  initBusinessAddressSaving,
  initClientAddressSaving,
  onAdviserAutocompleteSelect,
  checkBusinessFormValidity,
  saveClientContact,
} from './util/contactsNewCtrl';
import {
  initClientAddress,
  checkAddressValidity,
  checkBusinessAddressValidity,
} from './util/clientAddress';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('ContactsNewCtrl', function ContactsNewCtrl(
      $scope,
      contactService,
      $uibModal,
      $timeout,
      corporateService,
      $state,
      SweetAlert,
      $filter,
      toaster,
      generalService,
      mapService,
      optionsService,
      $q,
      $stateParams,
      $localStorage,
      broadcastDataService,
      dashboardService,
      uiService,
      referencedReferralService,
      contactStatusService,
      enquirySourceService,
      loadAllocationModalService,
      clientInfoService,
      accountService,
      contactSharedData,
      corporateModelService,
      commonFnService,
      currentUserService,
      stateService,
      configService,
      addressLookupService,
      mycrmLookupService,
      dataService,
      contactsNotesService,
    ) {
      $scope.referralExistingClient = {};
      $scope.onClientChange = ({ client = {} }) => {
        $scope.referralExistingClient = client;
      };
      initClientAddress({ $scope, configService });
      $scope.showHelloBookCorp = uiService.showHelloBookCorp;
      $scope.accountOwnerLabel = getAccountOwnerLabel(currentUserService.isNZ);
      $scope.contactType = {};
      $scope.entityTypeList = [];
      $scope.operatingCountry = [];
      $scope.businessUnit = [];
      $scope.status = [];
      $scope.assistantsList = [];
      $scope.operatingCountrySelected = {};
      $scope.businessUnitSelected = {};
      $scope.statusSelected = {};
      $scope.isAccountOwnerSelected = false;
      $scope.businessDetails = {};
      $scope.contactInfo = {};
      $scope.agreements = {};
      $scope.marketing = {};
      $scope.isBrandedShop = 'No';
      $scope.hasOfficeWeb = 'No';
      $scope.milestoneList = [
        { id: 1, value: '$250m' },
        { id: 2, value: '$500m' },
      ];
      $scope.adviserBroker = {};
      $scope.adviserBroker.brokerSupportOfficer = {};
      $scope.dateModel = {};
      $scope.franchisePrincipalList = [];
      $scope.contacts = {};
      $scope.contacts.isSaving = false;
      $scope.showOnboardingActive = false;
      $scope.brandingCategory = {
        adviser: [],
        adviserOrg: [],
      };
      $scope.adviserOrgModel = {};
      onInit({ $scope, uiService, dataService });
      $scope.getAssistantsList = setAssistantsList({ $scope, optionsService });
      $scope.onAdviserAutocompleteSelect = onAdviserAutocompleteSelect({
        $scope,
      });

      $scope.address = {};

      const newAddressModel = {
        formatted_address: '',
        geoCoded: false,
      };

      /** Adviser Address Init */
      _.assign($scope.address.businessAddressModelObj, newAddressModel);
      _.assign($scope.address.homeAddressModelObj, newAddressModel);
      _.assign($scope.address.postalAddressModelObj, newAddressModel);

      /** Assistant Address Init */
      _.assign(
        $scope.address.assistantBusinessAddressModelObj,
        newAddressModel,
      );
      _.assign($scope.address.assistantHomeAddressModelObj, newAddressModel);
      _.assign($scope.address.assistantPostalAddressModelObj, newAddressModel);

      /** Adviser Organisation Address Init */
      _.assign($scope.address.adviserOrgAddressModelObj, newAddressModel);

      /** Referrer Organisation Address Init */
      _.assign($scope.address.referrerOrgAddressModelObj, newAddressModel);

      $scope.adviserForm = {};
      $scope.adviserOrgForm = {};
      $scope.assistantForm = {};
      $scope.referrerForm = {};
      $scope.business = {};
      $scope.formatList = ['dd-MM-yyyy', 'dd/MM/yyyy'];
      $scope.dateOptions = {
        showWeeks: false,
        showButtonBar: false,
      };

      $scope.feesPaidBy = [
        { name: 'Head Broker', id: 0 },
        { name: 'Loan Writer', id: 1 },
        { name: 'Others', id: 2 },
      ];
      $scope.contactStatusLabel = getContactStatusLabel(
        uiService.viewOfAfileTurnedOn,
      );
      $scope.contactStatusText = `Contact ${$scope.contactStatusLabel}`;

      // functions
      function getBrandingCategoryList(countryId, key) {
        corporateService.getBrandingCategoryList(countryId).then((response) => {
          $scope.brandingCategory[key] = response.data;
        });
      }

      function getBusinessUnit() {
        corporateService.businessUnitGet().then((response) => {
          $scope.businessUnit = response.data;
        });
      }

      function getReportingRegion(countryId) {
        contactService.getReportingRegion(countryId).then((response) => {
          const region = response.data;
          if (region) {
            const temp = {
              ReportingRegionId: 0,
              ReportingRegionName: '[No Reporting Region]',
            };
            $scope.reportingRegionList = [temp, ...region];
          }
        });
      }
      function getEDRSchemes() {
        generalService.eDRSchemesGet().then((response) => {
          $scope.eDRSchemes = response.data;
        });
      }
      function getAdviserAchievements() {
        corporateService.adviserAchievementsGet(2016).then((response) => {
          $scope.adviserAchievementList = response.data;
        });
      }
      function getEnquirySources() {
        contactService.getEnquirySources().then((response) => {
          $scope.enquirySourceList = response.data;
        });
      }
      function getGeneralInterest() {
        contactService.getGeneralInterest().then((response) => {
          $scope.generalInterestList = response.data;
        });
      }
      function getSportsTeam() {
        contactService.getSportsTeam().then((response) => {
          $scope.sportsTeamList = response.data;
        });
      }
      function getEmploymentType() {
        contactService.getEmploymentType().then((response) => {
          $scope.employmentTypeList = response.data;
        });
      }

      function setCurrentScheme(countryCode) {
        $scope.edrScheme.current = getCurrentScheme(
          countryCode,
          $scope.edrScheme,
        );
      }
      $scope.userCallingCode = '00';
      $scope.userCountryCode = '';
      const getUserCodes = () => {
        if ($scope.operatingCountry && $scope.operatingCountry.length) {
          $scope.operatingCountry.forEach((country) => {
            if (
              parseInt(country.CountryId, 10) ===
              parseInt($scope.userInfo.CountryId, 10)
            ) {
              $scope.userCallingCode = country.CallingCode;
              $scope.userCountryCode = country.CountryCode;
            }
          });
        }
      };
      function getOperatingCountry() {
        contactService.getOperatingCountry().then((response) => {
          $scope.operatingCountry = response.data.splice(0, 3);
          if ($scope.operatingCountry.length > 0) {
            _.map($scope.operatingCountry, (item) => {
              item.CountryCode = _.isUndefined(item.CountryCode)
                ? ''
                : item.CountryCode.toLowerCase();
              if (item.CountryId === $scope.currentCountryId) {
                $scope.operatingCountrySelected = item;
                $scope.adviserDetails.reportingCountrySelected = item;
                $scope.assistant.reportingCountrySelected = item;
                setCurrentScheme(item.CountryCode);
              }
            });
            // eslint-disable-next-line sonarjs/no-extra-arguments
            getUserCodes($scope.currentCountryId);
          }
        });
      }
      function getOrganizationStatus() {
        contactService.getOrganizationStatus().then((response) => {
          $scope.status = response.data;
        });
      }
      function getCommissionTiers(userId) {
        corporateModelService.getCommissionTiers(userId).then((response) => {
          $scope.commissionTiersList = response.data;
        });
      }
      function getAdminUser() {
        contactService.getAdminUser().then((response) => {
          $scope.adminUsers = response.data;
          response.data.forEach((data) => {
            if (data.FullName === $scope.currentUserName) {
              $scope.accountOwner.originalObject = data;
              $scope.adviserDetails.salesManager.originalObject = data;
              $scope.adviserDetails.recruitedBy.originalObject = data;
              $scope.adviserBroker.brokerSupportOfficer.originalObject = data;
            }
          });
        });
      }

      function checkEmailValidity(email, familyId, checkOrg = 0) {
        const query = $q.defer();
        generalService
          .getCheckIfEmailExists(email, familyId, checkOrg)
          .then((response) => {
            query.resolve(response.data > 0);
          });
        return query.promise;
      }
      // allocation
      const getAdvisersDropdown = () => {
        mycrmLookupService.getAdvisers().then((data) => {
          $scope.allocatedAdviser = data || [];
        });
      };

      function getUserInfo() {
        contactService.getUserInfo().then((response) => {
          $scope.userInfo = response.data;
          if ($scope.userInfo) {
            $scope.isProfileAdmin = $scope.userInfo.CorpAccessTypeID === 1;
            $scope.currentCountryId = $scope.userInfo.CountryId || 0;
            $scope.showForAu =
              $scope.currentCountryId === COUNTRY_TYPE.AUSTRALIA;
            $scope.showForNz =
              $scope.currentCountryId === COUNTRY_TYPE.NEW_ZEALAND;
            $scope.userId = $scope.userInfo.FamilyId || 0;
            $scope.currentUserName = $scope.userInfo.FullName;
            $scope.mobileMask = broadcastDataService.getMobileFormat(
              $scope.currentCountryId,
            );
            $scope.phoneMask = broadcastDataService.getPhoneFormat(
              $scope.currentCountryId,
            );
            $scope.phoneMaskAdviserOrg = commonFnService.getAdviserOrgMobileFormat(
              $scope.currentCountryId,
            );
            getCommissionTiers($scope.userId);
            getReportingRegion($scope.currentCountryId);
            getBrandingCategoryList($scope.currentCountryId, 'adviser');
            getBrandingCategoryList($scope.currentCountryId, 'adviserOrg');
          }
          getOperatingCountry();
          getBusinessUnit();
          getAdminUser();
          getAdvisersDropdown();
        });
      }

      function onAddressChangeBusiness(place) {
        if (place) {
          $scope.address.businessAddress = place.formatted_address;
        }
      }
      function onAddressChangeHome(place) {
        if (place) {
          $scope.address.homeAddress = place.formatted_address;
        }
      }
      function onAddressChangePostal(place) {
        if (place) {
          $scope.address.postalAddress = place.formatted_address;
        }
      }

      function onAddressChangeAdviserOrg(place) {
        if (place) {
          $scope.address.adviserOrgAddress = place.formatted_address;
        }
      }

      function onAddressChangeBusinessAssistant(place) {
        if (place) {
          $scope.address.assistantBusinessAddress = place.formatted_address;
        }
      }
      function onAddressChangeHomeAssistant(place) {
        if (place) {
          $scope.address.assistantHomeAddress = place.formatted_address;
        }
      }
      function onAddressChangePostalAssistant(place) {
        if (place) {
          $scope.address.assistantPostalAddress = place.formatted_address;
        }
      }
      function onAddressChangeReferrerOrg(place) {
        if (place) {
          $scope.address.referrerOrgAddress = place.formatted_address;
        }
      }

      function getContactType() {
        contactService
          .getContactType()
          .then((response) => {
            $scope.contactTypeList = response.data;

            if (response.data.length > 0) {
              if (
                typeof $stateParams.fromTool !== 'undefined' &&
                $stateParams.fromTool === 'pipeline'
              ) {
                $scope.contactType = response.data[0];
              } else if (
                typeof $localStorage.currentContactTypeId !== 'undefined'
              ) {
                $scope.contactType = _.first(
                  _.filter(response.data, (data) => {
                    return (
                      data.ContactTypeId === $localStorage.currentContactTypeId
                    );
                  }),
                );
                $localStorage.currentContactTypeId = undefined;
              } else {
                $scope.contactType = _.first(
                  _.filter(response.data, (data) => {
                    return data.ContactTypeId === '1';
                  }),
                );
              }
              $scope.setContactType($scope.contactType);
            }
          })
          .then(() => {
            mapService.mapAutoCompletePlace(
              'googleLookupBusiness',
              onAddressChangeBusiness,
            );
            mapService.mapAutoCompletePlace(
              'googleLookupHome',
              onAddressChangeHome,
            );
            mapService.mapAutoCompletePlace(
              'googleLookupPostal',
              onAddressChangePostal,
            );
            mapService.mapAutoCompletePlace(
              'googleLookupAdviserOrg',
              onAddressChangeAdviserOrg,
            );
            mapService.mapAutoCompletePlace(
              'googleLookupBusinessAssistant',
              onAddressChangeBusinessAssistant,
            );
            mapService.mapAutoCompletePlace(
              'googleLookupHomeAssistant',
              onAddressChangeHomeAssistant,
            );
            mapService.mapAutoCompletePlace(
              'googleLookupPostalAssistant',
              onAddressChangePostalAssistant,
            );
            mapService.mapAutoCompletePlace(
              'googleLookupReferrerOrgAddress',
              onAddressChangeReferrerOrg,
            );
          });
      }

      function getEntityType() {
        contactService.getEntityType().then((response) => {
          if (response.data.length > 0) {
            $scope.entityTypeList = response.data;
          }
        });
      }

      function getOrgType() {
        corporateService.adviserOrgTypesGet().then((response) => {
          if (response.data.length > 0) {
            $scope.adviserOrgTypesList = response.data;
          }
        });
      }

      function getAdviserUnderOrganization(orgId) {
        contactService.getAdviserUnderOrganization(orgId).then((response) => {
          $scope.adviserList = response.data;
        });
      }

      function getAdviserOrganizationList() {
        contactService.getAdviserOrganizationList().then((response) => {
          $scope.adviserOrganizationList = _.filter(response.data, (data) => {
            return data.AdviserOrganizatioName !== '';
          });

          const temp = {
            AdviserOrganizatioName: 'No Organisation',
            AdviserOrganizationId: 0,
            Fax: '',
            Phone: '',
          };
          $scope.adviserOrganizationList.unshift(temp);
          const tmp = [];
          $scope.adviserOrganizationList &&
            Object.keys($scope.adviserOrganizationList).forEach((x) => {
              const value = $scope.adviserOrganizationList[x];
              value.icon = '<i class="fa fa-cog violet-color"></i>';
              value.ticked = parseInt(value.AdviserOrganizationId, 10) === 0;
              tmp.push(value);
            });
          $scope.adviser_org = tmp;
          $scope.selected_adviser_org = [
            {
              AdviserOrganizatioName: 'Default',
              AdviserOrganizationId: 0,
              Fax: '',
              Phone: '',
              ticked: true,
            },
          ];
        });
      }

      function saveOrgAddress(id, addressObj) {
        if (_.size(addressObj) > 0 && addressObj.formatted_address) {
          dashboardService.addressDetailsSet(addressObj).then((response) => {
            if (response.data > 0) {
              contactService
                .setOrganizationAddress(0, id, response.data, true)
                .then(() => {});
              contactService
                .setOrganizationAddress(0, id, response.data, false)
                .then(() => {});
            }
          });
        }
      }

      // eslint-disable-next-line sonarjs/cognitive-complexity
      function saveAdviserOrganization() {
        const data = {
          AdviserOrganizationId: 0,
          ComplianceId: 0,
          AdviserOrganizationName: $scope.businessDetails.accountName || '',
          EntityTypeId:
            typeof $scope.entityTypeSelected === 'undefined'
              ? 0
              : $scope.entityTypeSelected.EntityTypeID,
          AccountOwner_FamilyId:
            $scope.accountOwner !== null &&
            typeof $scope.accountOwner.originalObject !== 'undefined'
              ? $scope.accountOwner.originalObject.FamilyId
              : $scope.userInfo.FamilyId,
          AdviserOrgTypeID:
            typeof $scope.adviserOrgTypeSelected === 'undefined'
              ? 0
              : $scope.adviserOrgTypeSelected.AdviserOrgTypeID,
          CountryId:
            typeof $scope.operatingCountrySelected === 'undefined'
              ? 0
              : $scope.operatingCountrySelected.CountryId,
          Status: $scope.statusSelected.BrokerStatusId || null,
          FSPRNumber: $scope.businessDetails.fsprNumber || '',
          LeadAdviser_FamilyId:
            typeof $scope.selectedAdviser === 'undefined'
              ? 0
              : $scope.selectedAdviser.originalObject.FamilyId,
          Phone: $scope.contactInfo.phone ? $scope.contactInfo.phone : '',
          Fax: $scope.contactInfo.fax || '',
          CompanyEmail: $scope.contactInfo.officeEmail || '',
          EmailForCommissions: $scope.contactInfo.emailForCommission || '',
          ABN: $scope.agreements.abn || '',
          ACN: $scope.agreements.acn || '',
          RegisteredBusinessName:
            $scope.agreements.registeredBusinessName || '',
          AgreementNotes: $scope.agreements.notes || '',
          AgreementExecDate:
            $filter('date')(
              $scope.dateModel.adviserOrgAgreementExecutionDate,
              // eslint-disable-next-line sonarjs/no-duplicate-string
              'yyyy-MM-dd',
            ) || '',
          ReviewMonth:
            typeof $scope.currentReviewMonth === 'undefined'
              ? 0
              : $scope.currentReviewMonth.id,
          MileStone:
            typeof $scope.milestoneSelected === 'undefined'
              ? ''
              : $scope.milestoneSelected.value,
          ASCId: $scope.agreements.ascId || '',
          TradingName: $scope.marketing.tradingName || '',
          BrandedShopFront: $scope.isBrandedShop === 'Yes',
          HasOfficeWebsite: $scope.hasOfficeWeb === 'Yes',
          OfficeDisplayName: $scope.marketing.officeName || '',
          Website: $scope.marketing.website || '',
          WebDisplayState:
            typeof $scope.webDisplayStateSelected === 'undefined'
              ? ''
              : $scope.webDisplayStateSelected.name,
          BrandingCategoryId:
            typeof $scope.adviserOrg.brandingCategorySelected === 'undefined'
              ? 0
              : $scope.adviserOrg.brandingCategorySelected.BrandingCategoryId,
          BrandingCategory:
            typeof $scope.adviserOrg.brandingCategorySelected === 'undefined'
              ? ''
              : $scope.adviserOrg.brandingCategorySelected.BrandingCategory,
          F1Code: $scope.agreements.f1Code || '',
          RegBusNameExpDate:
            $filter('date')(
              $scope.dateModel.adviserOrgAgreementExpiryDate,
              'yyyy-MM-dd',
            ) || '',
          CessationDate:
            $filter('date')(
              $scope.dateModel.adviserOrgAgreementCessationDate,
              'yyyy-MM-dd',
            ) || '',
          CessationReasonID:
            typeof $scope.agreements.cessationReason === 'undefined'
              ? 0
              : $scope.agreements.cessationReason.CessationReasonID,
          SpecialCondition: $scope.agreements.specialCondition || '',
          IsComplianceTools: true,
        };
        contactService.setAdviserOrganization(0, data).then((response) => {
          if (response.data > 0) {
            saveOrgAddress(
              response.data,
              $scope.address.adviserOrgAddressModelObj,
            );
            const saveAdviserOrgTimer = $timeout(() => {
              swal(
                {
                  title: 'Success!',
                  text: 'Successfully saved the Adviser Organization!',
                  type: 'success',
                  showCancelButton: true,
                  // confirmButtonColor: "#DD6B55",
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  confirmButtonText: 'View Contact',
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  cancelButtonText: 'Add New Contact',
                  closeOnConfirm: true,
                  closeOnCancel: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#3085d6',
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  confirmButtonClass: 'btn btn-success',
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  cancelButtonClass: 'btn btn-info',
                },
                (isConfirm) => {
                  if (isConfirm) {
                    corporateService
                      .adviserOrganizationGet(response.data, 0)
                      .then((advResponse) => {
                        if (advResponse.data.length > 0) {
                          const obj = advResponse.data[0];
                          $state.go('app.contactsAdviserOrg', {
                            adviserOrgId: obj.AdviserOrganizationId,
                            adviserComplianceId:
                              obj.AdviserOrganizationComplianceId,
                          });
                        }
                      });
                  } else {
                    $localStorage.currentContactTypeId =
                      $scope.contactType.ContactTypeId;
                    $state.reload();
                  }
                },
              );
            });
            $scope.$on('$destroy', () => {
              $timeout.cancel(saveAdviserOrgTimer);
            });
          } else {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            swal('Oops...', 'Something went wrong!', 'error');
            $scope.contacts.isSaving = false;
          }
        });
      }

      function adviserValidation() {
        $scope.hasBusinessUnit =
          _.size($scope.adviserDetails.businessUnitSelected) > 0 &&
          $scope.adviserDetails.businessUnitSelected.BusinessUnitID > 0;
        $scope.hasFirstName = hasValue($scope.adviserDetails.firstName);
        $scope.hasLastName = hasValue($scope.adviserDetails.lastName);
        $scope.hasMobile = hasValue($scope.adviserDetails.mobile);
        $scope.hasRole = getHasRole($scope.franchisee.role);
        const brokerStatusId =
          typeof $scope.adviserDetails.brokerStatusListSelected === 'undefined'
            ? -1
            : $scope.adviserDetails.brokerStatusListSelected.BrokerStatusId;
        const adviserOrgId =
          typeof $scope.selected_adviser_org !== 'undefined' &&
          $scope.selected_adviser_org.length > 0
            ? $scope.selected_adviser_org[0].AdviserOrganizationId
            : 0;
        $scope.isOrgRequired =
          brokerStatusId === 1 ? adviserOrgId === 0 : false;

        $scope.isValidating = true;

        return (
          validateAdviserBusinessAddress({ $scope }) &&
          hasValue($scope.adviserDetails.firstName) &&
          hasValue($scope.adviserDetails.lastName) &&
          !$scope.isOrgRequired &&
          hasValue($scope.adviserDetails.mobile) &&
          $scope.contactModel.isEmailValid &&
          $scope.hasRole &&
          $scope.hasBusinessUnit
        );
      }

      const saveOnlineOnboardingActive = (clientId) => {
        const onboardingOnlineToggle =
          $scope.adviserDetails &&
          $scope.adviserDetails.setOnlineOnboardingActive;
        corporateModelService.setOnlineOnboardingActive(
          0,
          clientId,
          onboardingOnlineToggle,
        );
      };

      // eslint-disable-next-line sonarjs/cognitive-complexity
      function saveRecruitment(clientId) {
        const data = {
          RecruitmentDetailsID: 0,
          FamilyId: 0,
          PerceivedQualityId:
            typeof $scope.recruitment.perceiveQualityListSelected ===
            'undefined'
              ? 0
              : $scope.recruitment.perceiveQualityListSelected
                  .PerceivedQualityID,
          ProspectiveRatingId:
            typeof $scope.recruitment.prospectiveRatingListSelected ===
            'undefined'
              ? 0
              : $scope.recruitment.prospectiveRatingListSelected
                  .ProspectiveRatingID,
          RecruitmentStatusId:
            typeof $scope.recruitment.recruitmentStatusListSelected ===
            'undefined'
              ? 0
              : $scope.recruitment.recruitmentStatusListSelected
                  .RecruitmentStatusID,
          NotProceedingReasonsId:
            typeof $scope.recruitment.reasonsListSelected === 'undefined'
              ? 0
              : $scope.recruitment.reasonsListSelected
                  .RecruitmentNotProceedingReasonsID,
          ReasonForNotProceeding:
            typeof $scope.recruitment.reasonsListSelected === 'undefined'
              ? ''
              : $scope.recruitment.reasonsListSelected
                  .RecruitmentNotProceedingReasons,
          OnboardingStatusId: 0,
          ExpectedJoinDate:
            $filter('date')(
              $scope.dateModel.adviserExpectedJoinDate,
              'yyyy-MM-dd',
            ) || '',
          SpecialConditions: $scope.recruitment.specialCondition || '',
          SpecialConditionsApproved:
            $scope.recruitment.specialConditionApproved === 'Yes' ? 1 : 0,
          SpecialConditionsApprovedBy:
            $scope.recruitment.specialConditionApprovedBy || '',
          FranchiseeAwareOfLoanWriter:
            $scope.recruitment.loanWriter === 'Yes' ? 1 : 0,
          PreviousAggregator: $scope.recruitment.employer || '',
          JobTitle: $scope.recruitment.jobTitle || '',
          Description: $scope.recruitment.summary || '',
          EnquirySourceId:
            typeof $scope.enquirySourceListSelected === 'undefined'
              ? 0
              : $scope.enquirySourceListSelected.EnquirySourceId,
          RecruitedBy_FamilyId:
            $scope.adviserDetails.recruitedBy &&
            typeof $scope.adviserDetails.recruitedBy.originalObject !==
              'undefined'
              ? $scope.adviserDetails.recruitedBy.originalObject.FamilyId
              : $scope.userInfo.FamilyId,
          CommissionTierId:
            typeof $scope.commissionTiersListSelected === 'undefined'
              ? 0
              : $scope.commissionTiersListSelected.CommissionTierId,
          ReceiveRecruitNewsLetter:
            $scope.recruitment.newsLetter === 'Yes' ? 1 : 0,
          MentorName: $scope.adviserAdditional.nameOfMentor || '',
          MentorContactNumber: $scope.adviserAdditional.contactOfMentor || '',
          MentorNatureOfRelationship: '',
          HaveTwoYrsIndustryExp: 0,
          YearsMortgageAdviser: $scope.adviserAdditional.mortgageAdviser || 0,
          MothersMaidenName: '',
          NumberFollowUpAppointments: 0,
          NumberFollowUpCalls: 0,
          PipelineCategoryID:
            typeof $scope.recruitment.pipelineCategorySelected === 'undefined'
              ? 0
              : $scope.recruitment.pipelineCategorySelected.APipelineCategoryID,
        };

        corporateService
          .setFamilyRecruitmentDetails(0, clientId, data)
          .then(() => {
            saveOnlineOnboardingActive(clientId);
          });
      }

      const getDefaultReferToValue = () => {
        const isBrokerInactive =
          !$scope.adviserDetails ||
          !$scope.adviserDetails.brokerStatusListSelected ||
          $scope.adviserDetails.brokerStatusListSelected.BrokerStatusId !==
            ADVISER_STATUS.ACTIVE;
        if (isBrokerInactive) {
          return false;
        }
        const { reportingCountrySelected } = $scope.adviserDetails;
        const defaultValue =
          (reportingCountrySelected &&
            reportingCountrySelected.CountryId === COUNTRY_TYPE.AUSTRALIA) ||
          false;
        if (isLMBranded($scope.brandingCategoryId)) {
          return true;
        }
        if (isOwnBrand($scope.brandingCategoryId)) {
          return false;
        }
        return defaultValue;
      };

      /**
       * Description : Contact Adddress Set
       * @param {number} id - Contact clientId
       * @param {boolean} sameAsOrg - true/false if address same as organisation
       * @param {number} orgId - Organisation ID and set to zero if no adviser org
       * @param {object} addressParamObj - object that handle address. Object values businessAddressObj, mailingAddressObj, homeAddressObj,
       * @param {boolean} sameAsMailing - true/false if business if same as postal
       * @param {boolean} sameAsHome - true/false if business if same as home
       */
      // eslint-disable-next-line sonarjs/cognitive-complexity
      function saveCorpAddress(
        id,
        sameAsOrg,
        orgId,
        addressParamObj,
        sameAsMailing,
        sameAsHome,
      ) {
        if (sameAsOrg && orgId > 0) {
          contactService.getAdviserOrgAddress(orgId).then((response) => {
            if (_.size(response.data) > 0) {
              const addressId = Number(response.data[0].AddressID) || 0;
              if (addressId > 0) {
                /**
                 * 1st Set : for Business Address
                 * 2nd Set : for Mailing Address
                 * 3rd Set : for Home Address
                 */
                corporateService
                  .familyAddressSet(0, id, addressId, 0, 1, true)
                  .then(() => {});
                corporateService
                  .familyAddressSet(0, id, addressId, 1, 0, true)
                  .then(() => {});
                corporateService
                  .familyAddressSet(0, id, addressId, 0, 0, true)
                  .then(() => {});
              }
            }
          });
          // eslint-disable-next-line sonarjs/no-collapsible-if
        } else if (!sameAsOrg) {
          if (_.size(addressParamObj) > 0) {
            if (
              _.size(addressParamObj.businessAddressObj) > 0 &&
              addressParamObj.businessAddressObj.formatted_address
            ) {
              dashboardService
                .addressDetailsSet(addressParamObj.businessAddressObj)
                .then((response) => {
                  if (response.data > 0) {
                    corporateService
                      .familyAddressSet(0, id, response.data, 0, 1, true)
                      .then(() => {});
                    if (sameAsMailing) {
                      corporateService
                        .familyAddressSet(0, id, response.data, 1, 0, true)
                        .then(() => {});
                    }
                    if (sameAsHome) {
                      corporateService
                        .familyAddressSet(0, id, response.data, 0, 0, true)
                        .then(() => {});
                    }
                  }
                });
            }

            if (
              _.size(addressParamObj.mailingAddressObj) > 0 &&
              addressParamObj.mailingAddressObj.formatted_address
            ) {
              dashboardService
                .addressDetailsSet(addressParamObj.mailingAddressObj)
                .then((response) => {
                  if (response.data > 0) {
                    corporateService
                      .familyAddressSet(0, id, response.data, 1, 0, true)
                      .then(() => {});
                  }
                });
            }

            if (
              _.size(addressParamObj.homeAddressObj) > 0 &&
              addressParamObj.homeAddressObj.formatted_address
            ) {
              dashboardService
                .addressDetailsSet(addressParamObj.homeAddressObj)
                .then((response) => {
                  if (response.data > 0) {
                    corporateService
                      .familyAddressSet(0, id, response.data, 0, 0, true)
                      .then(() => {});
                  }
                });
            }
          }
        }
      }

      $scope.updateSwithOnlineOnboardingActive = (value) => {
        $scope.adviserDetails.setOnlineOnboardingActive = value || false;
      };

      // eslint-disable-next-line unicorn/consistent-function-scoping
      const setDefaultValueIfStatusActive = (adviserDetails) => {
        if (!adviserDetails || !adviserDetails.brokerStatusListSelected) {
          return;
        }
        const { brokerStatusId } = adviserDetailsBuilderForUI(
          adviserDetails.brokerStatusListSelected,
        );
        return brokerStatusId === ADVISER_STATUS.ACTIVE || false;
      };

      const setDefaultValueIfLoanMarket = (data) => {
        if (!data || !data.length) {
          return;
        }
        return commonFnService
          .getAdviserOrgBrandingCategoryId(data[0].AdviserOrganizationId)
          .then((brandingCategoryId) => {
            return isLMBranded(brandingCategoryId);
          });
      };

      const saveLeadDefaultValueToTrue = (id, data, adviserDetails) => {
        setDefaultValueIfLoanMarket(data).then((res) => {
          const isSetToTrue =
            res && setDefaultValueIfStatusActive(adviserDetails);
          const leadData = adviserLeadsBuilderForAPI({
            sendNotificationBroker: isSetToTrue,
            sendNotificationClient: isSetToTrue,
            wMReferralAlloc: false,
          });
          corporateService.leadsProfileSet(0, id, leadData);
        });
      };

      const saveBrokerDefaultValueToTrue = (id, data, adviserDetails) => {
        setDefaultValueIfLoanMarket(data).then((res) => {
          const isSetToTrue =
            res && setDefaultValueIfStatusActive(adviserDetails);
          corporateService.familyBrokerSupportSet(
            0,
            id,
            adviserBrokerSupportBuilderForAPI({ nPSEnabled: isSetToTrue }),
          );
        });
      };

      // eslint-disable-next-line sonarjs/cognitive-complexity
      function saveAdviser(isSaveWithAction) {
        $scope.isValidating = false;

        const brokerStatus =
          $scope.adviserDetails.brokerStatusListSelected &&
          $scope.adviserDetails.brokerStatusListSelected.BrokerStatusId;
        const adviserOrgId =
          $scope.selected_adviser_org.length &&
          $scope.selected_adviser_org[0].AdviserOrganizationId;
        const brandingCatId =
          $scope.adviserMarketing.brandingCategorySelected &&
          $scope.adviserMarketing.brandingCategorySelected.BrandingCategoryId;
        const invalidBrandingCategories =
          adviserOrgId &&
          brokerStatus === ADVISER_STATUS.ACTIVE &&
          !brandingCatId;
        if (invalidBrandingCategories) {
          const warningMsg =
            NO_BRANDING_CATEGORY_WARNING.ADVISER_ORG_OF_ADVISER;
          displayWarning(warningMsg, 'Warning', false);
          $scope.contacts.isSaving = false;
          return;
        }

        if (adviserValidation()) {
          const data = {
            Title:
              typeof $scope.adviserDetails.titleSelected === 'undefined'
                ? ''
                : $scope.adviserDetails.titleSelected.value,
            ClientID: 0,
            FamilyId: 0,
            FranchiseeDetailsId: 0,
            ClientTypeIds: _.map(
              $scope.clientType.selected,
              'ClientTypeID',
            ).join(','),
            FirstName: $scope.adviserDetails.firstName || '',
            MiddleName: $scope.adviserDetails.middleName || '',
            LastName: $scope.adviserDetails.lastName || '',
            PreferredName: $scope.adviserDetails.preferredName || '',
            AdviserOrganizationId: $scope.selected_adviser_org
              ? $scope.selected_adviser_org[0].AdviserOrganizationId
              : 0,
            WorkNumber:
              typeof $scope.adviserDetails.phone !== 'undefined'
                ? $scope.adviserDetails.phone
                : '',
            Mobile: $scope.adviserDetails.mobile || '', // Adding 0 to the numbers for default format.
            Fax: $scope.adviserDetails.fax || '',
            Email: $scope.adviserDetails.email || '',
            BrokerStatusId:
              typeof $scope.adviserDetails.brokerStatusListSelected ===
              'undefined'
                ? -1
                : $scope.adviserDetails.brokerStatusListSelected.BrokerStatusId,
            Description: $scope.adviserDetails.description || '',
            RecruitedBy_FamilyId:
              $scope.adviserDetails.recruitedBy &&
              typeof $scope.adviserDetails.recruitedBy.originalObject !==
                'undefined'
                ? $scope.adviserDetails.recruitedBy.originalObject.FamilyId
                : $scope.userInfo.FamilyId,
            ContactType: $scope.contactType.ContactTypeDesc,
            AccountOwner_FamilyId:
              $scope.adviserDetails.salesManager &&
              typeof $scope.adviserDetails.salesManager.originalObject !==
                'undefined'
                ? $scope.adviserDetails.salesManager.originalObject.FamilyId
                : $scope.userInfo.FamilyId,
            ReportingCountryId:
              typeof $scope.adviserDetails.reportingCountrySelected ===
              'undefined'
                ? 0
                : $scope.adviserDetails.reportingCountrySelected.CountryId,
            ReportingStateId:
              typeof $scope.reportingStateListSelected === 'undefined'
                ? 0
                : $scope.reportingStateListSelected.ReportingStateId,
            ReportingRegionId:
              typeof $scope.reportingRegionListSelected === 'undefined'
                ? 0
                : $scope.reportingRegionListSelected.ReportingRegionId,
            StartDate:
              $filter('date')(
                $scope.dateModel.adviserStartDate,
                'yyyy-MM-dd',
              ) || '',
            DOB:
              $filter('date')($scope.dateModel.adviserBirthday, 'yyyy-MM-dd') ||
              '',
            Gender:
              typeof $scope.genderListSelected === 'undefined'
                ? 0
                : $scope.genderListSelected.id,
            SecondaryEmail: $scope.adviserAdditional.email || '',
            HomeNumber: $scope.adviserAdditional.phone || '',
            LanguagesSpoken:
              typeof $scope.languageSpokenListSelected === 'undefined'
                ? 0
                : $scope.languageSpokenListSelected.name,
            GeneralInterests:
              typeof $scope.generalInterestListSelected === 'undefined'
                ? ''
                : $scope.generalInterestListSelected
                    .map((elem) => {
                      return elem.GeneralInterestName;
                    })
                    .join(','),
            SportsTeam:
              typeof $scope.sportsTeamListSelected === 'undefined'
                ? ''
                : $scope.sportsTeamListSelected
                    .map((elem) => {
                      return elem.Team;
                    })
                    .join(','),
            ReceiveRecruitNewsLetter:
              $scope.adviserAdditional.newsletter === 'Yes' ? 1 : 0,
            CommissionTierId:
              typeof $scope.commissionTiersListSelected === 'undefined'
                ? 0
                : $scope.commissionTiersListSelected.CommissionTierId,
            AchievementId:
              typeof $scope.achievementFY16 === 'undefined'
                ? 0
                : $scope.achievementFY16.AdviserAchievementId,
            IndividualAchievementId:
              typeof $scope.achievementFY15 === 'undefined'
                ? 0
                : $scope.achievementFY15.AdviserAchievementId,
            JobTitle: $scope.recruitment.jobTitle || '',
            PreviousAggregator: $scope.recruitment.employer || '',
            BrokerReferredBy: $scope.adviserAdditional.referredBy || '',
            EnquirySourceId:
              typeof $scope.enquirySourceListSelected === 'undefined'
                ? 0
                : $scope.enquirySourceListSelected.EnquirySourceId,
            AppointmentEntity: $scope.franchisee.appointmentEntity || '',
            RoleId:
              typeof $scope.franchisee.role === 'undefined'
                ? '0'
                : $scope.franchisee.role
                    .map((elem) => {
                      return elem.ContactTypeRoleId;
                    })
                    .join(','),
            EmploymentType:
              typeof $scope.employmentTypeListSelected === 'undefined'
                ? ''
                : $scope.employmentTypeListSelected.EmploymentTypeName,
            EntityTypeId:
              typeof $scope.franchisee.entityType === 'undefined'
                ? 0
                : $scope.franchisee.entityType.EntityTypeID,
            AppointmentEntityShareholder: $scope.franchisee.appointment || '',
            ABN: $scope.franchisee.abn || '',
            ACN: $scope.franchisee.acn || '',
            CountryOfBirth: $scope.adviserCompliance.countryBirth || '',
            PlaceOfBirth: $scope.adviserCompliance.placeOfBirth || '',
            DisplayTitle: '',
            Location: '',
            EmploymentTypeId:
              typeof $scope.employmentTypeListSelected === 'undefined'
                ? 0
                : $scope.employmentTypeListSelected.EmploymentTypeId,
            YearsMortgageAdviser: $scope.adviserAdditional.mortgageAdviser || 0,
            YearsMortgageLender: $scope.adviserAdditional.mortgageLender || 0,
            MentorName: $scope.adviserAdditional.nameOfMentor || '',
            MentorContactNumber: $scope.adviserAdditional.contactOfMentor || '',
            GSTRegistered: $scope.adviserAdditional.isGstRegistered,
            IRDGSTNumber: $scope.adviserAdditional.gstNumber || '',
            BrandingCategoryId:
              typeof $scope.adviserMarketing.brandingCategorySelected ===
              'undefined'
                ? 0
                : $scope.adviserMarketing.brandingCategorySelected
                    .BrandingCategoryId,
            BrandingCategory:
              typeof $scope.adviserMarketing.brandingCategorySelected ===
              'undefined'
                ? ''
                : $scope.adviserMarketing.brandingCategorySelected
                    .BrandingCategory,
            MarketingDetails: {
              DisplayTitle: $scope.adviserMarketing.title || '',
              TradingDisplayName: $scope.adviserMarketing.tradingName || '',
              BrandedShopFront:
                $scope.adviserMarketing.isBrandedShop === 'Yes' ? 1 : 0,
              BrandedCar:
                $scope.adviserMarketing.haveBrandedCar === 'Yes' ? 1 : 0,
              TFPAccount: $scope.adviserMarketing.tpfAccount || '',
              PrintForceAccount:
                $scope.adviserMarketing.printForceAccount || '',
              SubscribeENewsLetter:
                $scope.adviserMarketing.newsletter === 'Yes' ? 1 : 0,
              PoweredByLoanMarket:
                $scope.adviserMarketing.poweredLoanMarket === 'Yes' ? 1 : 0,
              RulesForPoweredByBranding:
                $scope.adviserMarketing.poweredBy || '',
              Skype: '',
              Facebook: $scope.adviserMarketing.facebook || '',
              LinkedIn: $scope.adviserMarketing.linkedin || '',
              Twitter: $scope.adviserMarketing.twitter || '',
              GooglePlus: $scope.adviserMarketing.googlePlus || '',
              AvatarDocumentID: 0,
              YouTubeChannel: $scope.adviserMarketing.youtubeChannel || '',
              YoutubeFeatured: $scope.adviserMarketing.youtubeFeatured || '',
              BrandingCategoryId:
                typeof $scope.adviserMarketing.brandingCategorySelected ===
                'undefined'
                  ? 0
                  : $scope.adviserMarketing.brandingCategorySelected
                      .BrandingCategoryId,
              BrandingCategory:
                typeof $scope.adviserMarketing.brandingCategorySelected ===
                'undefined'
                  ? ''
                  : $scope.adviserMarketing.brandingCategorySelected
                      .BrandingCategory,
            },
            FamilyCompliance: {
              FamilyComplianceId: 0,
              FamilyId: 0,
              EDRScheme:
                typeof $scope.eDRSchemesSelected === 'undefined'
                  ? 0
                  : $scope.eDRSchemesSelected.EDRSchemeDesc,
              EDRSchemeId: (
                $scope.adviserCompliance?.scheme || { EDRSchemeID: 0 }
              ).EDRSchemeID,
              EDRNumber: $scope.adviserCompliance.edrNumber || '',
              EDRStartDate:
                $filter('date')(
                  $scope.dateModel.adviserErdStartDate,
                  'yyyy-MM-dd',
                ) || '',
              EDRExpiryDate:
                $filter('date')(
                  $scope.dateModel.adviserErdExpiry,
                  'yyyy-MM-dd',
                ) || '',
              SendEDRExpiryNotification:
                $scope.adviserCompliance.edrExpiryNotify === 'Yes' ? 1 : 0,
              IndividualASICID: $scope.adviserCompliance.individualAsic || '',
              CompanyASICID: $scope.adviserCompliance.companyAsic || '',
              AppointmentType:
                typeof $scope.adviserCompliance.appointment === 'undefined'
                  ? ''
                  : $scope.adviserCompliance.appointment.value,
              DiplomaProvider: $scope.adviserCompliance.diplomaProvider || '',
              DiplomaCompletedDate:
                $filter('date')(
                  $scope.dateModel.adviserDiplomaComplete,
                  'yyyy-MM-dd',
                ) || '',
              DriversLicenseNo: $scope.adviserCompliance.driversLicense || '',
              DriversLicenseExpiry:
                $filter('date')(
                  $scope.dateModel.adviserLicenseExpiry,
                  'yyyy-MM-dd',
                ) || '',
              HistoricalComplianceAudit:
                $scope.adviserCompliance.complianceAudit || '',
              HistoricalComplianceComments:
                $scope.adviserCompliance.complianceComment || '',
              PIPolicyHolderName: $scope.adviserCompliance.piHolderName || '',
              PIInsurer: $scope.adviserCompliance.piInsurer || '',
              PIPolicyNumber: $scope.adviserCompliance.piPolicyNumber || '',
              PICoverAmount: $scope.adviserCompliance.piCoverAmount || '',
              PIExpiry:
                $filter('date')(
                  $scope.dateModel.adviserPiExpiry,
                  'yyyy-MM-dd',
                ) || '',
              SendPIExpirationNotification:
                $scope.adviserCompliance.piExpiryNotify === 'Yes' ? 1 : 0,
              ProfessionalSchemeName:
                typeof $scope.adviserCompliance.schemeName === 'undefined'
                  ? ''
                  : $scope.adviserCompliance.schemeName.name,
              ProfessionalSchemeMembershipNo:
                $scope.adviserCompliance.membershipNumber || '',
              ProfessionalSchemeRenewalDate:
                $filter('date')(
                  $scope.dateModel.adviserRenewal,
                  'yyyy-MM-dd',
                ) || '',
              SendProfSchemeRenewalNotif:
                $scope.adviserCompliance.schemeRenewalNotify === 'Yes' ? 1 : 0,
              CessationDate:
                $filter('date')(
                  $scope.dateModel.adviserCessationDate,
                  'yyyy-MM-dd',
                ) || '',
              CountryOfBirth: $scope.adviserCompliance.countryBirth || '',
              PlaceOfBirth: $scope.adviserCompliance.placeOfBirth || '',
              CommissionClawbackFee:
                $scope.adviserCompliance.commissionClawbackFee === 'Yes'
                  ? 1
                  : 0,
              ReliabilityHistory:
                $scope.adviserCompliance.reliabilityHistory || '',
              ConflictOfInterests:
                $scope.adviserCompliance.conflictOfInterests || '',
            },
            FamilyWebManagement: {
              WebManagementId: 0,
              WebsiteType:
                typeof $scope.adviserWeb.websiteTypeSelected === 'undefined'
                  ? ''
                  : $scope.adviserWeb.websiteTypeSelected.value,
              Website: $scope.adviserWeb.website || '',
              ProfilePage: $scope.adviserWeb.personalPage || '',
              LastUpdateToWebsite:
                $filter('date')(
                  $scope.dateModel.adviserLastUpdate,
                  'yyyy-MM-dd',
                ) || '',
              DoNotDisplayOnOfficeSite:
                $scope.adviserWeb.doNotDisplay === 'Yes' ? 1 : 0,
              IsPublic: $scope.adviserWeb.isPublic === 'Yes' ? 1 : 0,
              GeoCodeNextRefresh:
                $filter('date')(
                  $scope.dateModel.adviserGeoNextRefresh,
                  'yyyy-MM-dd',
                ) || '',
              GeocodeLatitude: $scope.adviserWeb.geo1 || '',
              GeocodeLongitude: $scope.adviserWeb.geo2 || '',
              BrokerProfileImageURL: $scope.adviserWeb.brokerProfile || '',
              ProfilePageCompleteURL: $scope.adviserWeb.profilePageUrl || '',
            },
            FamilyBrokerSupport: {
              Offshoring:
                $scope.adviserBroker.manilaOffshoring === 'Yes' ? 1 : 0,
              CommencementDate:
                $filter('date')(
                  $scope.dateModel.adviserCommencementDate,
                  'yyyy-MM-dd',
                ) || '',
              AgreementSigned:
                $scope.adviserBroker.supportAgreementSigned === 'Yes' ? 1 : 0,
              PreferredDocLabeling:
                $scope.adviserBroker.documentLabelling || '',
              CustomerContact:
                $scope.adviserBroker.customerContact === 'Yes' ? 1 : 0,
              DataEntryNotes: $scope.adviserBroker.entryNotes || '',
              ServiceModel:
                typeof $scope.adviserBroker.manilaBrokerListSelected ===
                'undefined'
                  ? ''
                  : $scope.adviserBroker.manilaBrokerListSelected.value.toLowerCase(),
              OfficerFamilyID:
                $scope.adviserBroker.brokerSupportOfficer === null ||
                typeof $scope.adviserBroker.brokerSupportOfficer
                  .originalObject === 'undefined'
                  ? $scope.userInfo.FamilyId
                  : $scope.adviserBroker.brokerSupportOfficer.originalObject
                      .FamilyId,
              AgreementSupportDate:
                $filter('date')(
                  $scope.dateModel.adviserAgreementReturnDate,
                  'yyyy-MM-dd',
                ) || '',
              PreferredContactMethod:
                typeof $scope.adviserBroker.contactMethodListSelected ===
                'undefined'
                  ? ''
                  : $scope.adviserBroker.contactMethodListSelected.value,
              SupportingDocPreference:
                typeof $scope.adviserBroker.supportingDocumentListSelected ===
                'undefined'
                  ? ''
                  : $scope.adviserBroker.supportingDocumentListSelected.value,
              FeesPaidById: $scope.adviserBroker.paidBy.id || 0,
              FeesPaidByOther: $scope.adviserBroker.paidByOther || '',
              AutoReferralWM: getDefaultReferToValue(),
              AutoReferralConcierge: getDefaultReferToValue(),
              AutoReferralHome: getDefaultReferToValue(),
            },
            ...(currentUserService.isNZ
              ? { AccessTypeID: $scope.adviserDetails.accessTypeId || 0 }
              : {}),
            BusinessUnitID: $scope.adviserDetails.businessUnitSelected
              ? $scope.adviserDetails.businessUnitSelected.BusinessUnitID
              : 0,
            FeeCardId: $scope.feeCardId,
          };
          contactService.setAdviser(0, data).then((response) => {
            if (response.data > 0) {
              saveRecruitment(response.data);
              const addressParamObj = {
                businessAddressObj: $scope.address.businessAddressModelObj,
                mailingAddressObj: $scope.address.postalAddressModelObj,
                homeAddressObj: $scope.address.homeAddressModelObj,
              };
              saveCorpAddress(
                response.data,
                $scope.isOrgAddress,
                data.AdviserOrganizationId,
                addressParamObj,
                $scope.isBusinessAsPostal,
                $scope.isBusinessAsHome,
              );

              saveLeadDefaultValueToTrue(
                response.data,
                $scope.selected_adviser_org,
                $scope.adviserDetails,
              );
              saveBrokerDefaultValueToTrue(
                response.data,
                $scope.selected_adviser_org,
                $scope.adviserDetails,
              );
              if (isSaveWithAction) {
                const saveAdviserTimer = $timeout(() => {
                  swal(
                    {
                      title: 'Success!',
                      text: 'Successfully saved the Adviser!',
                      type: 'success',
                      showCancelButton: true,
                      confirmButtonText: 'View Contact',
                      cancelButtonText: 'Add New Contact',
                      closeOnConfirm: true,
                      closeOnCancel: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#3085d6',
                      confirmButtonClass: 'btn btn-success',
                      cancelButtonClass: 'btn btn-info',
                    },
                    (isConfirm) => {
                      if (isConfirm) {
                        corporateService
                          .corporateContactGet(response.data, 0)
                          .then((corpResponse) => {
                            const obj = corpResponse.data;
                            $state.go('app.contactsAdviser', {
                              clientId: obj.ClientID,
                              familyId: obj.FamilyId,
                            });
                          });
                      } else {
                        $localStorage.currentContactTypeId =
                          $scope.contactType.ContactTypeId;
                        $state.reload();
                      }
                    },
                  );
                });
                $scope.$on('$destroy', () => {
                  $timeout.cancel(saveAdviserTimer);
                });
              }
            } else {
              swal('Oops...', 'Something went wrong!', 'error');
              $scope.contacts.isSaving = false;
            }
          });
        } else {
          if (
            !$scope.contactModel.isEmailValid &&
            $scope.adviserDetails.email !== undefined &&
            $scope.adviserDetails.email !== ''
          ) {
            swal(
              'Woah there partner!',
              'That email address is already in MyCRM for another user.  Please specify a new email address to continue.',
              'error',
            );
          } else {
            swal(
              'Oops...',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              'Some required fields need to be filled out. ',
              'error',
            );
          }
          $scope.contacts.isSaving = false;
        }
      }

      // eslint-disable-next-line sonarjs/cognitive-complexity
      function saveAssistant() {
        const data = {
          ClientID: 0,
          FamilyId: 0,
          FranchiseeDetailsId: 0,
          FirstName: $scope.assistant.firstName || '',
          MiddleName: '',
          LastName: $scope.assistant.lastName || '',
          PreferredName: $scope.assistant.preferredName || '',
          AdviserOrganizationId:
            typeof $scope.assistant.parentAccount !== 'undefined' &&
            $scope.assistant.parentAccount
              ? $scope.assistant.parentAccount.description.AdviserOrganizationId
              : 0,
          WorkNumber: $scope.assistant.phone || '',
          Mobile: $scope.assistant.mobile || '',
          Fax: $scope.assistant.fax,
          Email: $scope.assistant.email || '',
          BrokerStatusId:
            $scope.assistant.brokerStatusListSelected.BrokerStatusId,
          Description: '',
          RecruitedBy_FamilyId: 0,
          ContactType: $scope.contactType.ContactTypeDesc,
          AccountOwner_FamilyId: 0,
          ReportingCountryId: $scope.assistant.reportingCountrySelected
            ? $scope.assistant.reportingCountrySelected.CountryId
            : 0,
          ReportingStateId: $scope.assistant.reportingStateListSelected
            ? $scope.assistant.reportingStateListSelected.ReportingStateId
            : 0,
          ReportingRegionId: $scope.assistant.reportingRegionListSelected
            ? $scope.assistant.reportingRegionListSelected.ReportingRegionId
            : 0,
          StartDate:
            $filter('date')(
              $scope.dateModel.assistantEmployeeStartDate,
              'yyyy-MM-dd',
            ) || '',
          EndDate:
            $filter('date')(
              $scope.dateModel.assistantEmployeeEndDate,
              'yyyy-MM-dd',
            ) || '',
          DOB:
            $filter('date')($scope.dateModel.assistantDOB, 'yyyy-MM-dd') || '',
          Gender: $scope.assistant.gender === 'Male' ? 1 : 2,
          HomeNumber: '',
          LanguagesSpoken: '',
          GeneralInterests: '',
          SportsTeam: '',
          ReceiveRecruitNewsLetter: 0,
          CommissionTierId: 0,
          AchievementId: 0,
          BusinessUnitID: $scope.assistant.businessUnitId || 0,
          IndividualAchievementId: 0,
          JobTitle: '',
          PreviousAggregator: '',
          BrokerReferredBy: '',
          EnquirySourceId: 0,
          AppointmentEntity: '',
          RoleId:
            typeof $scope.assistant.role === 'undefined'
              ? '0'
              : $scope.assistant.role,
          EmploymentType:
            typeof $scope.assistant.employmentType === 'undefined'
              ? ''
              : $scope.assistant.employmentType.EmploymentTypeName,
          EntityTypeId: '',
          AppointmentEntityShareholder: '',
          ABN: '',
          ACN: '',
          DisplayTitle: $scope.assistant.title,
          Location:
            typeof $scope.assistant.location === 'undefined'
              ? ''
              : $scope.assistant.location,
          EmploymentTypeId:
            typeof $scope.assistant.employmentType === 'undefined'
              ? 0
              : $scope.assistant.employmentType.EmploymentTypeId,
          ...(currentUserService.isNZ
            ? { AccessTypeID: $scope.assistant.accessTypeId || 0 }
            : {}),
        };
        contactService.setAdviser(0, data).then((response) => {
          if (response.data > 0) {
            const addressParamObj = {
              businessAddressObj:
                $scope.address.assistantBusinessAddressModelObj,
              mailingAddressObj: $scope.address.assistantPostalAddressModelObj,
              homeAddressObj: $scope.address.assistantHomeAddressModelObj,
            };
            saveCorpAddress(
              response.data,
              $scope.assistant.isOrgAddress,
              data.AdviserOrganizationId,
              addressParamObj,
              $scope.assistant.isBusinessAsPostal,
              $scope.assistant.isBusinessAsHome,
            );

            const saveAssistantTimer = $timeout(() => {
              swal(
                {
                  title: 'Success!',
                  text: 'Successfully saved the Assistant!',
                  type: 'success',
                  showCancelButton: true,
                  confirmButtonText: 'View Contact',
                  cancelButtonText: 'Add New Contact',
                  closeOnConfirm: true,
                  closeOnCancel: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#3085d6',
                  confirmButtonClass: 'btn btn-success',
                  cancelButtonClass: 'btn btn-info',
                },
                (isConfirm) => {
                  if (isConfirm) {
                    corporateService
                      .corporateContactGet(response.data, 0)
                      .then((corpResponse) => {
                        const obj = corpResponse.data;
                        $state.go('app.contactsAssistant', {
                          clientId: obj.ClientID,
                          familyId: obj.FamilyId,
                        });
                      });
                  } else {
                    $localStorage.currentContactTypeId =
                      $scope.contactType.ContactTypeId;
                    $state.reload();
                  }
                },
              );
            });
            $scope.$on('$destroy', () => {
              $timeout.cancel(saveAssistantTimer);
            });
          } else {
            swal('Oops...', 'Something went wrong!', 'error');
            $scope.contacts.isSaving = false;
          }
        });
      }

      // eslint-disable-next-line sonarjs/cognitive-complexity
      function saveReferrerOrg() {
        if (isReferrerOrgFieldsValid($scope.referrer)) {
          const data = {
            ReferrerOrgComplianceId: 0,
            ReferrerOrgId: 0,
            ComplianceId: 0,
            ReferrerOrgName: $scope.referrer.legalName || '',
            TradingName: $scope.referrer.tradingName || '',
            ABN: $scope.referrer.abn || '',
            ACN: $scope.referrer.acn || '',
            BusinessTypeID: $scope.referrer.businessTypeId,
            BusinessActivitiesDesc: $scope.referrer.businessActivities || '',
            OrganisationTypeId:
              typeof $scope.referrer.organizationType === 'undefined'
                ? 0
                : $scope.referrer.organizationType.OrganizationTypeId,
            OrganisationType:
              typeof $scope.referrer.organizationType === 'undefined'
                ? ''
                : $scope.referrer.organizationType.OrganizationTypeName,
            CreatedByFamilyId: 0,
            CreatedByName: '',
            DateCreated: '',
            LastModifiedByFamilyId: 0,
            LastModifiedByName: '',
            DateModified: '',
            Directors: $scope.referrer.directorList,
          };
          contactService.setReferrerOrg(data).then((response) => {
            if (response.data > 0) {
              saveOrgAddress(
                response.data,
                $scope.address.referrerOrgAddressModelObj,
              );
              const saveReferrerOrgTimer = $timeout(() => {
                swal(
                  {
                    title: 'Success!',
                    text: 'Successfully saved the Referrer Organisation!',
                    type: 'success',
                    showCancelButton: true,
                    confirmButtonText: 'View Contact',
                    cancelButtonText: 'Add New Contact',
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#3085d6',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonClass: 'btn btn-info',
                  },
                  (isConfirm) => {
                    if (isConfirm) {
                      corporateService
                        .referrerOrgGet(response.data, 0)
                        .then((refResponse) => {
                          const obj = refResponse.data;
                          $state.go('app.contactsReferrerOrg', {
                            referrerComplianceId: obj.ReferrerOrgComplianceId,
                            referrerOrgId: obj.ReferrerOrgId,
                          });
                        });
                    } else {
                      $localStorage.currentContactTypeId =
                        $scope.contactType.ContactTypeId;
                      $state.reload();
                    }
                  },
                );
              });
              $scope.$on('$destroy', () => {
                $timeout.cancel(saveReferrerOrgTimer);
              });
            } else {
              swal('Oops...', 'Something went wrong!', 'error');
              $scope.contacts.isSaving = false;
            }
          });
        } else {
          swal(
            // eslint-disable-next-line sonarjs/no-duplicate-string
            'Required Fields',
            // eslint-disable-next-line sonarjs/no-duplicate-string
            'Please fill-out some required field/s',
            'error',
          );
          $scope.contacts.isSaving = false;
        }
      }

      // Referrer
      $scope.referrers = {};

      // eslint-disable-next-line sonarjs/cognitive-complexity
      function saveReferrer() {
        if (
          typeof $scope.referrers.firstName !== 'undefined' &&
          $scope.referrers.firstName.trim() !== '' &&
          typeof $scope.referrers.lastName !== 'undefined' &&
          $scope.referrers.lastName.trim() !== ''
        ) {
          const data = {
            ClientID: 0,
            FamilyId: 0,
            FranchiseeDetailsId: 0,
            Title: $scope.referrers.titleSelected
              ? $scope.referrers.titleSelected.value
              : '',
            FirstName: $scope.referrers.firstName || '',
            MiddleName: '',
            LastName: $scope.referrers.lastName || '',
            PreferredName: '',
            AdviserOrganizationId: 0,
            WorkNumber: $scope.referrers.phone || '',
            Mobile: $scope.referrers.mobile || '',
            Fax: '',
            Email: $scope.referrers.email || '',
            BrokerStatusId: 0,
            Description: '',
            RecruitedBy_FamilyId: 0,
            ContactType: 'referrer',
            AccountOwner_FamilyId: 0,
            ReportingCountryId: $scope.currentCountryId,
            ReportingStateId: 0,
            ReportingRegionId: 0,
            StartDate: '',
            DOB: '',
            Gender: 3,
            SecondaryEmail: '',
            HomeNumber: '',
            LanguagesSpoken: '',
            GeneralInterests: '',
            SportsTeam: '',
            ReceiveRecruitNewsLetter: 0,
            CommissionTierId: 0,
            AchievementId: 0,
            IndividualAchievementId: 0,
            JobTitle: '',
            PreviousAggregator: '',
            BrokerReferredBy: '',
            EnquirySourceId: 0,
            AppointmentEntity: '',
            RoleId: '',
            EmploymentType: '',
            EntityTypeId: '',
            AppointmentEntityShareholder: '',
            ABN: '',
            ACN: '',
            DisplayTitle: '',
            Location: '',
            EmploymentTypeId: 0,
          };
          contactService.setAdviser(0, data).then((response) => {
            if (response.data > 0) {
              swal(
                {
                  title: 'Success!',
                  text: 'Successfully saved the Referrer!',
                  type: 'success',
                  showCancelButton: true,
                  confirmButtonText: 'View Contact',
                  cancelButtonText: 'Add New Contact',
                  closeOnConfirm: true,
                  closeOnCancel: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#3085d6',
                  confirmButtonClass: 'btn btn-success',
                  cancelButtonClass: 'btn btn-info',
                },
                (isConfirm) => {
                  if (isConfirm) {
                    corporateService
                      .corporateContactGet(response.data, 0)
                      .then((corpResponse) => {
                        const obj = corpResponse.data;
                        $state.go('app.contactsReferrer', {
                          clientId: obj.ClientID,
                          familyId: obj.FamilyId,
                        });
                      });
                  } else {
                    $localStorage.currentContactTypeId =
                      $scope.contactType.ContactTypeId;
                    $state.reload();
                  }
                },
              );
            } else {
              swal('Oops...', 'Something went wrong!', 'error');
              $scope.contacts.isSaving = false;
            }
          });
        } else {
          swal(
            'Required Fields',
            'Please fill-out some required field/s',
            'error',
          );
          $scope.contacts.isSaving = false;
        }
      }

      $scope.currentMobileTab = 1;
      $scope.finalMobileTab = 10;
      getUserInfo();
      getContactType();
      getEntityType();
      getAdminUser();
      getOrgType();

      getOrganizationStatus();
      getAdviserUnderOrganization(0);
      getAdviserOrganizationList();
      $scope.contactTypeList = [];
      $scope.adviserDetails = {};
      $scope.adviserAdditional = {};
      $scope.assistant = {};
      $scope.adviserMarketing = {};
      $scope.adviserCompliance = {};
      $scope.adviserAdditional.newsletter = 'No';
      $scope.adviserOrg = {};
      $scope.localLang = {
        reset: "<i class='fa fa-plus'></i>",
        search: 'Search',
        nothingSelected: "<i class='fa fa-cog violet-color'></i>",
      };
      $scope.adviser_org = [];
      optionsService.getTitleList().then((response) => {
        $scope.titleList = response;
      });

      $scope.accountOwner = {};
      $scope.adviserDetails.salesManager = {};
      $scope.adviserDetails.recruitedBy = {};

      $scope.selected_adviser_org = [];

      $scope.selectContentType = function (data) {
        $scope.contactType = data;
      };
      $scope.getNumberArray = function (num) {
        return new Array(num);
      };

      $scope.selectStatus = function (item) {
        $scope.statusSelected = item;
      };
      $scope.saveAccountForPipeline = function (form) {
        if (
          parseInt($scope.contactType.ContactTypeId, 10) ===
            CONTACT_TYPE.CLIENT &&
          !$scope.isInvalidForm
        ) {
          $scope.contacts.isSaving = true;
          $scope.saveClientAccount(form);
        }
      };
      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.saveAccount = function (form) {
        $scope.contacts.isSaving = true;

        $scope.isInvalidForm = true;
        angular.forEach($scope.personDetails, (data) => {
          const DOB = moment(data.DOB, ['DD-MM-YYYY']);
          if (data.DOB !== '' && !DOB.isValid()) {
            toaster.pop(
              'warning',
              'Invalid Date of Birth',
              'Please fill in a valid date',
            );
            $scope.contacts.isSaving = false;
            $scope.isInvalidForm = false;
            return;
          }

          const dobSplit = data.DOB.split('/');
          if (dobSplit[2] < 1700) {
            toaster.pop(
              'warning',
              'Invalid Date of Birth',
              'Year must not be below 1700',
            );
            $scope.contacts.isSaving = false;
            $scope.isInvalidForm = false;
          }
        });

        if (
          Number($scope.contactType.ContactTypeId) === CONTACT_TYPE.ADVISER_ORG
        ) {
          // Adviser Organization
          if ($scope.adviserOrgForm.$invalid) {
            if (
              $scope.adviserOrgForm.accountName.$invalid ||
              $scope.adviserOrgForm.adviserOrgBrandingCategory.$invalid
            ) {
              swal(
                'Required Fields',
                'Please fill-out some required field/s',
                'error',
              );
            } else if ($scope.adviserOrgForm.officeEmail.$invalid) {
              // eslint-disable-next-line sonarjs/no-duplicate-string
              swal('Invalid Field', 'Invalid Office Email', 'error');
            } else if ($scope.adviserOrgForm.$error.pattern) {
              swal(
                'Invalid Field',
                'Invalid phone format, it must not be maximum of 25 characters.',
                'error',
              );
            } else {
              swal(
                // eslint-disable-next-line sonarjs/no-duplicate-string
                'Invalid Dates',
                // eslint-disable-next-line sonarjs/no-duplicate-string
                'There are invalid dates in the form. Please check and save again. Valid format should look like this dd-mm-yyyy or dd/mm/yyyy',
                'error',
              );
            }
            $scope.contacts.isSaving = false;
          } else {
            checkEmailValidity($scope.contactInfo.officeEmail, 0, 1).then(
              (response) => {
                if (response) {
                  swal(
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    'Email Validation',
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    'Email is already used by other user.',
                    'error',
                  );
                  $scope.contacts.isSaving = false;
                } else {
                  saveAdviserOrganization();
                }
              },
            );
          }
        } else if (
          parseInt($scope.contactType.ContactTypeId, 10) ===
          CONTACT_TYPE.ADVISER
        ) {
          // Adviser
          const isSaveWithAction = true;
          if ($scope.adviserForm.$invalid) {
            swal(
              'Invalid Dates',
              'There are invalid dates in the form. Please check and save again. Valid format should look like this dd-mm-yyyy or dd/mm/yyyy',
              'error',
            );
            $scope.contacts.isSaving = false;
          } else if ($scope.adviserDetails.email) {
            const defaultFamilyId = 0;
            generalService
              .getCheckIfEmailExists(
                $scope.adviserDetails.email,
                defaultFamilyId,
                0,
                $scope.contactType.ContactTypeId,
              )
              .then((response) => {
                $scope.contactModel.isEmailValid = response && !response.data;
                saveAdviser(isSaveWithAction);
              });
          } else {
            $scope.contactModel.isEmailValid = false;
            saveAdviser(isSaveWithAction);
          }
        } else if (
          parseInt($scope.contactType.ContactTypeId, 10) ===
          CONTACT_TYPE.ASSISTANT
        ) {
          if ($scope.assistantForm.$invalid) {
            const isRequiredInvalid =
              $scope.assistantForm.assistantFirstName.$invalid ||
              $scope.assistantForm.assistantLastName.$invalid ||
              $scope.assistantForm.assistantBusinessUnit.$invalid ||
              $scope.assistantForm.assistantRole.$invalid ||
              $scope.assistantForm.assistantAdviserOrg.$invalid ||
              $scope.assistantForm.assistantEmail.$invalid;
            if (isRequiredInvalid) {
              swal(
                'Required Fields',
                'Please fill-out some required field/s',
                'error',
              );
            } else if ($scope.assistantForm.assistantEmail.$invalid) {
              swal('Invalid Field', 'Invalid Assistant Email Address', 'error');
            } else {
              swal(
                'Invalid Dates',
                'There are invalid dates in the form. Please check and save again. Valid format should look like this dd-mm-yyyy or dd/mm/yyyy',
                'error',
              );
            }
            $scope.contacts.isSaving = false;
          } else {
            checkEmailValidity($scope.assistant.email, 0).then((prom) => {
              if (prom) {
                swal(
                  'Email Validation',
                  'Email is already used by other user.',
                  'error',
                );
                $scope.contacts.isSaving = false;
              } else {
                saveAssistant();
              }
            });
          }
        } else if (
          Number($scope.contactType.ContactTypeId) === CONTACT_TYPE.REFERRER_ORG
        ) {
          saveReferrerOrg();
        } else if (
          Number($scope.contactType.ContactTypeId) ===
          CONTACT_TYPE.ADMINISTRATOR
        ) {
          if ($scope.referrerForm.referrerEmail.$invalid) {
            swal('Invalid Field', 'Invalid Referrer Email Address', 'error');
            $scope.contacts.isSaving = false;
          } else {
            checkEmailValidity($scope.referrers.email, 0).then((prom) => {
              if (prom) {
                swal(
                  'Email Validation',
                  'Email is already used by other user.',
                  'error',
                );
                $scope.contacts.isSaving = false;
              } else {
                saveReferrer();
              }
            });
          }
        } else if (
          Number($scope.contactType.ContactTypeId) === CONTACT_TYPE.CLIENT
        ) {
          if ($scope.isInvalidForm) {
            $scope.saveClientAccount(form);
          }
        } else if (
          Number($scope.contactType.ContactTypeId) === CONTACT_TYPE.BUSINESS &&
          $scope.isInvalidForm
        ) {
          $scope.validateBusinessAccount();
        }
      };

      $scope.adviserGender = 'Male';
      $scope.setAdviserGender = function (value) {
        $scope.adviserGender = value;
      };
      $scope.setContactType = function (item) {
        $scope.client = {
          adviserSelected: {},
          adviserInitialValue: '',
        };
        $scope.contactType = item;
        if (parseInt(item.ContactTypeId, 10) === CONTACT_TYPE.ADVISER) {
          $scope.finalMobileTab = 9;
        } else if (
          parseInt(item.ContactTypeId, 10) === CONTACT_TYPE.ADVISER_ORG ||
          parseInt(item.ContactTypeId, 10) === CONTACT_TYPE.CLIENT
        ) {
          $scope.finalMobileTab = 5;
        } else if (
          parseInt(item.ContactTypeId, 10) === CONTACT_TYPE.ASSISTANT
        ) {
          $scope.finalMobileTab = 4;
        } else if (
          parseInt(item.ContactTypeId, 10) === CONTACT_TYPE.ADMINISTRATOR ||
          parseInt(item.ContactTypeId, 10) === CONTACT_TYPE.REFERRER_ORG
        ) {
          $scope.finalMobileTab = 2;
        } else if (parseInt(item.ContactTypeId, 10) === CONTACT_TYPE.BUSINESS) {
          $scope.finalMobileTab = 3;
        } else {
          $scope.finalMobileTab = 4;
        }
      };

      $scope.isFromPipeline = function () {
        $scope.isPipeline = $stateParams.fromTool;
        if ($scope.isPipeline === 'pipeline') {
          $scope.pipeLineParam = true;
          $scope.headerForPipeline = 'Add New Contact to Card';
        }
      };
      $scope.isFromPipeline();
      $scope.setEntityType = function (item) {
        $scope.entityTypeSelected = item;
      };

      $scope.setOrgType = function (item) {
        $scope.adviserOrgTypeSelected = item;
      };

      $scope.setOperatingCountry = function (item) {
        $scope.operatingCountrySelected = item;
        $scope.mobileMask = broadcastDataService.getMobileFormat(
          item.CountryId,
        );
        $scope.phoneMask = broadcastDataService.getPhoneFormat(item.CountryId);
        $scope.phoneMaskAdviserOrg = commonFnService.getAdviserOrgMobileFormat(
          item.CountryId,
        );
        $scope.showForAu = item.CountryId === 2;
        $scope.showForNz = item.CountryId === 1;
        getBrandingCategoryList(item.CountryId, 'adviserOrg');
      };

      $scope.adviserList = [];

      $scope.adviserOrganizationListSelected = [];
      $scope.setAdviserOrganizationList = function (item) {
        $scope.adviserOrganizationListSelected = item;
      };

      $scope.org = {};
      $scope.org.filteredOrganisation = [];
      $scope.searchOrganisation = function (data) {
        $scope.org.filteredOrganisation = data.result;
      };
      $scope.setReviewMonth = function (item) {
        $scope.currentReviewMonth = item;
      };
      $scope.setBrandedShop = function () {
        $scope.isBrandedShop = $scope.isBrandedShop === 'Yes' ? 'No' : 'Yes';
      };
      $scope.setOfficeWeb = function () {
        $scope.hasOfficeWeb = $scope.hasOfficeWeb === 'Yes' ? 'No' : 'Yes';
      };
      $scope.setMilestone = function (item) {
        $scope.milestoneSelected = item;
      };
      $scope.nextStep = function () {
        if ($scope.currentMobileTab !== $scope.finalMobileTab) {
          $scope.currentMobileTab++;
        } else {
          $scope.saveAccount();
        }
      };
      $scope.prevStep = function () {
        if ($scope.currentMobileTab > 1) {
          $scope.currentMobileTab--;
        }
      };
      $scope.setWebDisplayState = function (item) {
        $scope.webDisplayStateSelected = item;
      };
      $scope.selectBrokerStatus = function (value) {
        $scope.brokerStatusSelected = value;
      };
      $scope.selectContactType = function (value) {
        $scope.contactTypeSelected = value;
      };
      $scope.selectAdviserReportingCountry = function (data) {
        $scope.adviserDetails.reportingCountrySelected = data;
      };
      $scope.selectReportingState = function (value) {
        $scope.reportingStateSelected = value;
      };
      $scope.selectReportingRegion = function (value) {
        $scope.reportingRegionSelected = value;
      };
      $scope.selectLanguageSpoken = function (value) {
        $scope.languageSpokenSelected = value;
      };
      $scope.setNewsLetter = function () {
        $scope.adviserAdditional.newsletter =
          $scope.adviserAdditional.newsletter === 'Yes' ? 'No' : 'Yes';
      };

      $scope.adviserAdditional.isGstRegistered = true;
      $scope.setGstRegistered = function () {
        $scope.adviserAdditional.isGstRegistered = !$scope.adviserAdditional
          .isGstRegistered;
      };

      // Broker Status
      $scope.setBrokerStatusValue = (item) => {
        $scope.showOnboardingActive =
          item.BrokerStatusId === ADVISER_STATUS.ONBOARDING;
        $scope.adviserDetails.setOnlineOnboardingActive = false;
      };

      $scope.setBrokerStatusList = function (item) {
        $scope.brokerStatusListSelected = item;
      };
      $scope.brokerStatusList = [];
      function getBrokerStatusGet() {
        contactService.getBrokerStatusList().then((response) => {
          $scope.brokerStatusList = response.data;
          if (response.data && response.data.length) {
            $scope.brokerStatusListSelected = response.data[3];
            $scope.adviserDetails.brokerStatusListSelected = response.data[3];
            $scope.assistant.brokerStatusListSelected =
              response.data[ASSISTANT_STATUS_LIST.PROSPECT];
          }
          const brokerStatSelected =
            $scope.adviserDetails &&
            $scope.adviserDetails.brokerStatusListSelected;
          $scope.showOnboardingActive =
            brokerStatSelected.BrokerStatusId === ADVISER_STATUS.ONBOARDING;
          $scope.adviserDetails.setOnlineOnboardingActive =
            brokerStatSelected.BrokerStatusId === ADVISER_STATUS.ONBOARDING;
        });
      }
      getBrokerStatusGet();

      const getOrganisationStatus = () => {
        corporateService.adviserOrganizationStatusesGet().then((response) => {
          if (response.data && response.data.length) {
            $scope.adviserOrgModel.statusList = response.data.map((data) => {
              data.BrokerStatusId = data.StatusCode;
              data.BrokerStatusName = data.Status;
              return data;
            });
            $scope.statusSelected = response.data[1];
          }
        });
      };
      getOrganisationStatus();

      $scope.contactTypeRolesList = [];
      $scope.setContactTypeRole = function (item) {
        $scope.contactTypeRolesListSelected = item;
      };
      function getContactTypeRoles() {
        contactService.getContactTypeRoles().then((response) => {
          $scope.contactTypeRolesList = response.data;
          if (response.data.length > 0) {
            $scope.contactTypeRolesListSelected = response.data[0];
          }
        });
      }
      // Contact Type Roles
      getContactTypeRoles();

      // Reporting Courtry
      $scope.setReportingCountry = function (item) {
        $scope.adviserDetails.reportingCountrySelected = item;
        $scope.mobileMask = broadcastDataService.getMobileFormat(
          item.CountryId,
        );
        $scope.phoneMask = broadcastDataService.getPhoneFormat(item.CountryId);
        $scope.phoneMaskAdviserOrg = commonFnService.getAdviserOrgMobileFormat(
          item.CountryId,
        );
        setCurrentScheme(item.CountryCode);
        $scope.showForAu = item.CountryId === 2;
        $scope.showForNz = item.CountryId === 1;
        getCommissionTiers($scope.userId);
        getReportingRegion(item.CountryId);
        getBrandingCategoryList(item.CountryId, 'adviser');
      };

      $scope.setBusinessUnit = function (item) {
        $scope.adviserDetails.businessUnitSelected = item;
      };

      $scope.reportingStateList = [];
      $scope.setReportingState = function (item) {
        $scope.reportingStateListSelected = item;
      };
      function getReportingStates() {
        contactService.getReportingStates().then((response) => {
          $scope.reportingStateList = response.data;
        });
      }
      // Reporting State
      getReportingStates();

      // Reporting Region
      $scope.reportingRegionList = [];
      $scope.setReportingRegion = function (item) {
        if (item.ReportingRegionId === 0) {
          $scope.reportingRegionListSelected = {};
        } else {
          $scope.reportingRegionListSelected = item;
        }
      };

      $scope.eDRSchemes = [];
      $scope.setEDRSchemes = function (item) {
        $scope.eDRSchemesSelected = item;
      };

      getEDRSchemes();

      // Spoken Languages
      $scope.languageSpokenList = uiService.spokenLanguage;
      $scope.setLanguageSpoken = function (item) {
        $scope.languageSpokenListSelected = item;
      };

      // Commission Tier
      $scope.commissionTiersList = [];
      $scope.setCommissionTier = function (item) {
        $scope.commissionTiersListSelected = item;
      };

      // Achievements
      $scope.adviserAchievementList = [];
      $scope.setAchievementFY16 = function (item) {
        $scope.achievementFY16 = item;
      };
      $scope.setAchievementFY15 = function (item) {
        $scope.achievementFY15 = item;
      };

      getAdviserAchievements();

      // Enquiry Source
      $scope.enquirySourceList = [];
      $scope.setEnquirySource = function (item) {
        $scope.enquirySourceListSelected = item;
      };

      getEnquirySources();

      // General Interest
      $scope.generalInterestListSelected = [];
      $scope.onSelectGeneral = function (item) {
        $scope.generalInterestListSelected.push(item);
      };
      $scope.onRemoveGeneral = function (item) {
        const i = $scope.generalInterestListSelected.indexOf(item);
        $scope.generalInterestListSelected.splice(i, 1);
      };
      $scope.isGeneralChecked = function (data) {
        return $scope.generalInterestListSelected.includes(data);
      };

      getGeneralInterest();
      $scope.generalTransform = function (newTag) {
        return {
          id: 0,
          GeneralInterestName: newTag,
        };
      };
      // Sports Team
      $scope.sportsTeamListSelected = [];
      $scope.onSelectSports = function (item) {
        $scope.sportsTeamListSelected.push(item);
      };
      $scope.onRemoveSports = function (item) {
        const i = $scope.generalInterestListSelected.indexOf(item);
        $scope.sportsTeamListSelected.splice(i, 1);
      };
      $scope.isSportsChecked = function (data) {
        return $scope.sportsTeamListSelected.includes(data);
      };

      getSportsTeam();
      $scope.sportTransform = function (newTag) {
        return {
          id: 0,
          Team: newTag,
        };
      };
      // Francisee
      $scope.franchisee = {};
      $scope.franchisee.role = [];
      $scope.onSelectRole = function () {};
      $scope.onRemoveRole = function () {};

      // Employment Type
      $scope.employmentTypeList = [];
      $scope.setEmployment = function (item) {
        $scope.employmentTypeListSelected = item;
      };

      getEmploymentType();

      // Entity Type
      $scope.setFranchiseeEntity = function (item) {
        $scope.franchisee.entityType = item;
      };

      // Adviser Address
      $scope.addressDetails = {};
      $scope.isBusinessAsHome = true;
      $scope.isBusinessAsPostal = true;

      $scope.openGeneralInterestModal = function () {
        $uibModal.open({
          templateUrl: 'generalInterestList.html',
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          scope: $scope,
          controller($uibModalInstance) {
            $scope.closeGeneralInterestModal = function () {
              $uibModalInstance.dismiss('cancel');
            };
            $scope.selectGenerelInterestInModal = function (data) {
              const i = $scope.generalInterestListSelected.indexOf(data);
              if (i === -1) {
                $timeout(() => {
                  $scope.generalInterestListSelected.push(data);
                });
              } else {
                $timeout(() => {
                  $scope.generalInterestListSelected.splice(i, 1);
                });
              }
            };
            $scope.selectGeneralAll = function () {
              if (
                $scope.generalInterestListSelected.length !==
                $scope.generalInterestList.length
              ) {
                $scope.generalInterestListSelected = angular.copy(
                  $scope.generalInterestList,
                );
              } else {
                $scope.generalInterestListSelected = [];
              }
            };
          },
        });
      };

      $scope.openSportsTeamModal = function () {
        $uibModal.open({
          templateUrl: 'sportsTeamModal.html',
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          scope: $scope,
          controller($uibModalInstance) {
            $scope.closeSportsTeamModal = function () {
              $uibModalInstance.dismiss('cancel');
            };
            $scope.selectSportstTeamInModal = function (data) {
              const i = $scope.sportsTeamListSelected.indexOf(data);
              if (i === -1) {
                $timeout(() => {
                  $scope.sportsTeamListSelected.push(data);
                });
              } else {
                $timeout(() => {
                  $scope.sportsTeamListSelected.splice(i, 1);
                });
              }
            };
            $scope.selectSportsAll = function () {
              if (
                $scope.sportsTeamListSelected.length !==
                $scope.sportsTeamList.length
              ) {
                $scope.sportsTeamListSelected = angular.copy(
                  $scope.sportsTeamList,
                );
              } else {
                $scope.sportsTeamListSelected = [];
              }
            };
          },
        });
      };

      // Assistant Tab
      $scope.assistant.gender = 'Male';
      $scope.setAssistantGender = function (value) {
        $scope.assistant.gender = value;
      };
      $scope.assistant.roleList = [
        {
          ContactTypeRoleId: 8,
          ContactTypeRoleDesc: 'Internal or Office Manager',
          ContactTypeRoleName: 'Assistant',
        },
        {
          ContactTypeRoleId: 10,
          ContactTypeRoleDesc: 'Outsourced or External',
          ContactTypeRoleName: 'Assistant',
        },
      ];
      $scope.assistant.locationList = ['Office based', 'Remote'];
      $scope.setAssistantRole = function (item) {
        $scope.assistant.role = item;
      };
      $scope.setAssistantState = function (item) {
        $scope.assistant.state = item;
      };
      $scope.setAssistantLocation = function (item) {
        $scope.assistant.location = item;
      };
      $scope.setAssistantEmployment = function (item) {
        $scope.assistant.employmentType = item;
      };
      $scope.setReportingCountryAssistant = function (item) {
        $scope.assistant.reportingCountrySelected = item;
        $scope.mobileMask = broadcastDataService.getMobileFormat(
          item.CountryId,
        );
        $scope.phoneMask = broadcastDataService.getPhoneFormat(item.CountryId);
        $scope.phoneMaskAdviserOrg = commonFnService.getAdviserOrgMobileFormat(
          item.CountryId,
        );
        getReportingRegion(item.CountryId);
      };
      $scope.setReportingStateAssistant = function (item) {
        $scope.assistant.reportingStateListSelected = item;
      };
      $scope.setReportingRegionAssistant = function (item) {
        $scope.assistant.reportingRegionListSelected = item;
      };
      $scope.assistant.isOrgAddress = true;
      $scope.assistant.isBusinessAsPostal = true;
      $scope.assistant.isBusinessAsHome = true;

      // Adviser Marketing
      $scope.adviserMarketing.isBrandedShop = 'No';
      $scope.setBrandedShopAdviserMarketing = function () {
        $scope.adviserMarketing.isBrandedShop =
          $scope.adviserMarketing.isBrandedShop === 'Yes' ? 'No' : 'Yes';
      };
      $scope.adviserMarketing.haveBrandedCar = 'No';
      $scope.setBrandedCarAdviserMarketing = function () {
        $scope.adviserMarketing.haveBrandedCar =
          $scope.adviserMarketing.haveBrandedCar === 'Yes' ? 'No' : 'Yes';
      };
      $scope.adviserMarketing.newsletter = 'No';
      $scope.setNewsletterAdviserMarketing = function () {
        $scope.adviserMarketing.newsletter =
          $scope.adviserMarketing.newsletter === 'Yes' ? 'No' : 'Yes';
      };
      $scope.adviserMarketing.poweredLoanMarket = 'No';
      $scope.setPoweredLoanMarketAdviserMarketing = function () {
        $scope.adviserMarketing.poweredLoanMarket =
          $scope.adviserMarketing.poweredLoanMarket === 'Yes' ? 'No' : 'Yes';
      };

      // Adviser Compliance
      $scope.edrScheme = {};
      $scope.edrScheme.au = [
        { id: 1, value: 'COSL' },
        { id: 2, value: 'FOS' },
      ];
      $scope.edrScheme.nz = [
        { id: 1, value: 'FSCL' },
        { id: 2, value: 'IFSO' },
        { id: 3, value: 'FDR' },
      ];
      $scope.professionalSchemeNames = [
        { id: 1, name: 'MFAA' },
        { id: 2, name: 'FBAA' },
      ];
      $scope.edrScheme.current = [];

      $scope.adviserCompliance.edrExpiryNotify = 'No';
      $scope.setEDRExpiryNotify = function () {
        $scope.adviserCompliance.edrExpiryNotify =
          $scope.adviserCompliance.edrExpiryNotify === 'Yes' ? 'No' : 'Yes';
      };
      $scope.adviserCompliance.commissionClawbackFee = 'No';
      $scope.setCommissionClawbackFee = function () {
        $scope.adviserCompliance.commissionClawbackFee =
          $scope.adviserCompliance.commissionClawbackFee === 'Yes'
            ? 'No'
            : 'Yes';
      };

      $scope.appointmentTypeList = [
        { id: 1, value: 'CRN' },
        { id: 2, value: 'ACL' },
      ];

      $scope.adviserCompliance.piExpiryNotify = 'No';
      $scope.setPIExpiryNotify = function () {
        $scope.adviserCompliance.piExpiryNotify =
          $scope.adviserCompliance.piExpiryNotify === 'Yes' ? 'No' : 'Yes';
      };
      $scope.adviserCompliance.schemeRenewalNotify = 'No';
      $scope.setSchemeRenewalNotify = function () {
        $scope.adviserCompliance.schemeRenewalNotify =
          $scope.adviserCompliance.schemeRenewalNotify === 'Yes' ? 'No' : 'Yes';
      };

      $scope.$watch('adviserDetails.reportingCountrySelected', () => {
        if (
          typeof $scope.adviserDetails.reportingCountrySelected !==
            'undefined' &&
          $scope.adviserDetails.reportingCountrySelected.CountryCode === 'NZ'
        ) {
          $scope.adviserCompliance.companyAsic = '';
          $scope.adviserCompliance.individualAsic = '';
        }
      });
      $scope.selectedOrganizationAddress = '';
      $scope.$watch('adviserOrganizationListSelected.originalObject', () => {
        $scope.adviserCompliance.companyAsic = '';
        if ($scope.adviserOrganizationListSelected.originalObject) {
          contactService
            .getAdviserOrgAddress(
              $scope.adviserOrganizationListSelected.originalObject
                .AdviserOrganizationId,
            )
            .then((response) => {
              $scope.selectedOrganizationAddress =
                response.data.Formatted_address;
            });
        }
        if (
          typeof $scope.adviserDetails.reportingCountrySelected !==
            'undefined' &&
          $scope.adviserDetails.reportingCountrySelected.CountryCode === 'AU' &&
          $scope.adviserOrganizationListSelected !== null
        ) {
          corporateService
            .adviserOrganizationGet(
              0,
              $scope.adviserOrganizationListSelected.originalObject
                .AdviserOrganizationId,
            )
            .then((response) => {
              $scope.adviserCompliance.companyAsic = response.data[0].ASCId;
            });
        }
      });

      // Web Management
      $scope.adviserWeb = {};
      $scope.adviserWeb.websiteTypes = [
        { id: 1, value: 'Profile Page' },
        { id: 2, value: 'Website' },
      ];
      $scope.adviserWeb.doNotDisplay = 'No';
      $scope.setDoNotDisplay = function () {
        $scope.adviserWeb.doNotDisplay =
          $scope.adviserWeb.doNotDisplay === 'Yes' ? 'No' : 'Yes';
      };
      $scope.adviserWeb.isPublic = 'No';
      $scope.setIsPublic = function () {
        $scope.adviserWeb.isPublic =
          $scope.adviserWeb.isPublic === 'Yes' ? 'No' : 'Yes';
      };

      // Broker Support
      $scope.adviserBroker.manilaOffshoring = 'No';
      $scope.adviserBroker.paidBy = $scope.feesPaidBy[0];
      $scope.setAdviserBroker = function () {
        $scope.adviserBroker.manilaOffshoring =
          $scope.adviserBroker.manilaOffshoring === 'Yes' ? 'No' : 'Yes';
      };

      $scope.adviserBroker.supportAgreementSigned = 'No';
      $scope.setSupportAgreementSigned = function () {
        $scope.adviserBroker.supportAgreementSigned =
          $scope.adviserBroker.supportAgreementSigned === 'Yes' ? 'No' : 'Yes';
      };
      $scope.adviserBroker.customerContact = 'No';
      $scope.setCustomerContact = function () {
        $scope.adviserBroker.customerContact =
          $scope.adviserBroker.customerContact === 'Yes' ? 'No' : 'Yes';
      };
      $scope.adviserBroker.manilaBrokerList = [
        { id: 1, value: 'Standard' },
        { id: 2, value: 'Discount' },
        { id: 3, value: 'Waivered' },
      ];

      $scope.adviserBroker.contactMethodList = [
        { id: 1, value: 'Email' },
        { id: 2, value: 'Phone' },
        { id: 3, value: 'Any' },
      ];

      $scope.adviserBroker.supportingDocumentList = [
        { id: 1, value: 'Email' },
        { id: 2, value: 'Apply Online' },
        { id: 3, value: 'Lender Portal' },
      ];

      // Temp Variable
      $scope.monthTitle = [
        { id: 1, name: 'January' },
        { id: 2, name: 'February' },
        { id: 3, name: 'March' },
        { id: 4, name: 'April' },
        { id: 5, name: 'May' },
        { id: 6, name: 'June' },
        { id: 7, name: 'July' },
        { id: 8, name: 'August' },
        { id: 9, name: 'September' },
        { id: 10, name: 'October' },
        { id: 11, name: 'November' },
        { id: 12, name: 'December' },
      ];
      $scope.webDisplayState = [
        { id: 1, name: 'ACT' },
        { id: 1, name: 'NSW' },
        { id: 2, name: 'NT' },
        { id: 3, name: 'QLD' },
        { id: 4, name: 'SA' },
        { id: 5, name: 'VIC' },
        { id: 6, name: 'TAS' },
        { id: 7, name: 'WA' },
      ];
      $scope.languageSpoken = ['English', 'Cantonese', 'Arabic'];
      $scope.languageSpokenSelected = $scope.languageSpoken[0];
      $scope.genderList = [
        { id: 1, value: 'Male' },
        { id: 2, value: 'Female' },
        { id: 3, value: 'Unknown' },
      ];
      $scope.roleList = [
        'Franchisee',
        'Loan Writer',
        'Corporate',
        'Director',
        'Guarantor',
        'Principal',
      ];
      $scope.setGender = function (item) {
        $scope.genderListSelected = item;
      };
      $scope.multipleDemo = {};
      $scope.availableColors = [
        'Red',
        'Green',
        'Blue',
        'Yellow',
        'Magenta',
        'Maroon',
        'Umbra',
        'Turquoise',
      ];
      $scope.multipleDemo.colors = ['Blue', 'Red'];

      $scope.newAdviserOrg = function () {
        const filteredType = $scope.contactTypeList.filter((type) => {
          return parseInt(type.ContactTypeId, 10) === CONTACT_TYPE.ADVISER_ORG;
        });
        const adviserOrgObj =
          filteredType && filteredType.length ? filteredType[0] : {};
        swal(
          {
            title: 'Save this Adviser?',
            text: 'Do you want to create this adviser?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Create it!',
            cancelButtonText: 'No, Just Continue!',
            closeOnConfirm: false,
            closeOnCancel: false,
          },
          (isConfirm) => {
            if (isConfirm) {
              generalService
                .getCheckIfEmailExists($scope.adviserDetails.email, 0)
                .then((response) => {
                  $scope.contactModel.isEmailValid = response && !response.data;
                  const isAdviserValid = adviserValidation();
                  if (isAdviserValid && $scope.adviserDetails.email) {
                    saveAdviser();
                    swal('Created!', 'New adviser created!', 'success');
                    $scope.contactType = adviserOrgObj;
                  } else {
                    swal(
                      'Oops...',
                      'Some required fields need to be filled out.',
                      'error',
                    );
                  }
                });
            } else {
              swal(
                'New Adviser Organization',
                'Continue to create new Adviser Organization',
                'success',
              );
              $scope.contactType = adviserOrgObj;
            }
          },
        );
      };

      // Referrer Org Tab
      $scope.referrer = {};
      $scope.isAddNewDirector = false;
      $scope.referrer.directorList = [];
      $scope.currentIndex = -1;
      $scope.addNewDirectory = function () {
        $scope.isAddNewDirector = true;
      };
      $scope.saveDirector = function () {
        const data = {
          DirectorId: 0,
          Name: $scope.directorName,
          Contact: $scope.directorContact,
          Email: $scope.directorEmail,
        };
        if ($scope.currentIndex === -1) {
          $scope.referrer.directorList.push(data);
        } else {
          $scope.referrer.directorList[$scope.currentIndex] = data;
        }
        $scope.cancelDirector();
      };
      $scope.cancelDirector = function () {
        $scope.directorName = '';
        $scope.directorContact = '';
        $scope.directorEmail = '';
        $scope.currentIndex = -1;
        $scope.isAddNewDirector = false;
      };
      $scope.editDirector = function (data, index) {
        $scope.directorName = data.Name;
        $scope.directorContact = data.Contact;
        $scope.directorEmail = data.Email;
        $scope.isAddNewDirector = true;
        $scope.currentIndex = index;
      };
      $scope.deleteDirector = function (index) {
        $scope.referrer.directorList.splice(index, 1);
      };

      function getOrganizationType() {
        contactService.getOrganizationType().then((response) => {
          $scope.referrer.organizationTypeList = response.data;
        });
      }
      getOrganizationType();

      $scope.cancelCreation = function () {
        stateService.routeToContactList();
      };
      $scope.openModal = function () {
        $uibModal.open({
          templateUrl: 'assets/views/contacts/referrer/referrer_member.html',
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          controller: 'ReferrerAsMemberNewCtrl',
        });
      };
      $scope.openModalAgreement = function () {
        $uibModal.open({
          templateUrl: 'assets/views/contacts/referrer/agreement_new.html',
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          controller: 'AgreementNewCtrl',
        });
      };

      // Recruitment Tab
      $scope.recruitment = {};
      $scope.recruitment.newsLetter = 'No';
      $scope.setRecruitmentNewsLetter = function () {
        $scope.recruitment.newsLetter =
          $scope.recruitment.newsLetter === 'Yes' ? 'No' : 'Yes';
      };
      $scope.recruitment.specialConditionApproved = 'No';
      $scope.setSpecialConditionApproved = function () {
        $scope.recruitment.specialConditionApproved =
          $scope.recruitment.specialConditionApproved === 'Yes' ? 'No' : 'Yes';
      };
      $scope.recruitment.loanWriter = 'No';
      $scope.setLoanWriter = function () {
        $scope.recruitment.loanWriter =
          $scope.recruitment.loanWriter === 'Yes' ? 'No' : 'Yes';
      };

      function getPerceivedQualityList() {
        $scope.recruitment.perceiveQualityList = [];
        corporateService.getPerceivedQualityList().then((response) => {
          $scope.recruitment.perceiveQualityList = response.data;
        });
      }
      getPerceivedQualityList();
      function pipelineCategory() {
        generalService.adviserPipelineCategoryGet().then((response) => {
          $scope.recruitment.pipelineCategoryList = response.data;
        });
      }
      pipelineCategory();

      function getProspectiveRatingList() {
        $scope.recruitment.prospectiveRatingList = [];
        corporateService.getProspectiveRatingList().then((response) => {
          $scope.recruitment.prospectiveRatingList = response.data;
        });
      }
      getProspectiveRatingList();

      function getRecruitmentStatusList() {
        $scope.recruitment.recruitmentStatusList = [];
        corporateService.getRecruitmentStatusList().then((response) => {
          $scope.recruitment.recruitmentStatusList = response.data;
        });
      }
      getRecruitmentStatusList();

      function getRecruitmentNotProceedingReasonList() {
        $scope.recruitment.reasonsList = [];
        corporateService
          .getRecruitmentNotProceedingReasonList()
          .then((response) => {
            $scope.recruitment.reasonsList = response.data;
          });
      }
      getRecruitmentNotProceedingReasonList();

      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.onAdviserOrgChange = (adviserOrgArr) => {
        if (isEMocaUnit($scope.adviserDetails)) {
          return;
        }
        if (adviserOrgArr && adviserOrgArr.length) {
          const adviserOrgId = adviserOrgArr[0].AdviserOrganizationId || 0;
          if (adviserOrgId) {
            corporateService
              .adviserOrganizationGet(0, adviserOrgId)
              .then((response) => {
                const data = response.data;
                if (data && data.length) {
                  $scope.brandingCategoryId = data[0].BrandingCategoryId || 0;
                  const filteredList = $scope.brandingCategory.adviser.filter(
                    (x) => {
                      return x.BrandingCategoryId === $scope.brandingCategoryId;
                    },
                  );
                  $scope.adviserMarketing.brandingCategorySelected =
                    filteredList && filteredList.length ? filteredList[0] : {};
                }
              });
          }
        }
      };

      $scope.address.suggestionList = [];
      $scope.address.show = [];
      function searchAddress(obj, key, searchString) {
        $timeout.cancel($scope.searchTimeoutPromise);
        $scope.searchTimeoutPromise = $timeout(() => {
          if (searchString) {
            generalService.placeSearch(searchString).then((respond) => {
              $scope.address.suggestionList = respond.data;
              $scope.address.show[key] = true;
            });
          }
        }, 500);
      }
      function selectAddress(obj, key, addressObj) {
        obj[key] = addressObj.formatted_address;
        obj[`${key}Obj`] = addressObj;
        $scope.address.show[key] = false;
      }

      function closeAddress(key) {
        $scope.address.show[key] = false;
      }

      $scope.searchAddress = searchAddress;
      $scope.selectAddress = selectAddress;
      $scope.closeAddress = closeAddress;

      //= ======================== Client Account ====================================================

      // eslint-disable-next-line unicorn/consistent-function-scoping
      function getNewContactModel(role) {
        return {
          FamilyId: '0',
          LastName: '',
          FirstName: '',
          MiddleName: '',
          PreferredName: '',
          Title: '',
          Gender: '',
          DOB: '',
          Role: role,
          Employment: [
            {
              Occupation: '',
              OccupationClass: 0,
              Employer: '',
            },
          ],
          SmokerStatus: '',
          Deceased: false,
          DOBReminder: false,
          BestTimeToCall: '',
          Phone: [
            {
              Type: 'Home',
              Number: '',
            },
            {
              Type: 'Work',
              Number: '',
            },
            {
              Type: 'Mobile',
              Number: '',
            },
          ],
          Email: [
            {
              Type: 'Email',
              EmailAddress: '',
            },
          ],
          Notes: '',
          AgeValue: '',
          mobileId: `mobile-id-${(
            new Date().getTime() / UNIX_MULTIPLIER
          ).toFixed(0)}`,
          mobileName: `mobile-name-${(
            new Date().getTime() / UNIX_MULTIPLIER
          ).toFixed(0)}`,
        };
      }
      $scope.personDetails = [getNewContactModel('Adult')];
      $scope.childDetails = [];
      $scope.homeAutoAddressDetails = [];
      $scope.contactAddress = {};

      $scope.AddPerson = function (role) {
        if (role === 'Adult') {
          if ($scope.personDetails.length < 2) {
            $scope.personDetails.push(getNewContactModel(role));
          } else {
            swal(
              'Maximum Allowed Adults',
              'Only 2 Adults are allowed',
              'error',
            );
          }
        } else if (role === 'Child') {
          $scope.childDetails.push(getNewContactModel(role));
        }
      };

      $scope.removePersonEntry = function (index) {
        const validToRemove = index > 0;
        if (validToRemove) {
          $scope.personDetails.splice(index, 1);
        } else {
          swal(
            'Action Not Allowed',
            'You need at least 1 person when creating a client',
            'error',
          );
        }
      };

      $scope.removeChildEntry = function (index) {
        $scope.childDetails.splice(index, 1);
      };

      // eslint-disable-next-line unicorn/consistent-function-scoping
      function isAnguCompleteNotEmpty(data) {
        return data !== null && typeof data.originalObject !== 'undefined';
      }
      const isEnquiryNotValid = () => {
        if (
          !$scope.contactInfoSetValue.EnquirySource ||
          !$scope.contactInfoSetValue.EnquirySource.ReferralCategoryId ||
          !$scope.contactInfoSetValue.EnquirySource.ReferralItemId
        ) {
          return true;
        }
        const isReferral = $scope.displayField(
          $scope.contactInfoSetValue.EnquirySource.ReferralItemId,
          $scope.referredByAccessList,
        );
        if (!isReferral) {
          return false;
        }
        const referrer =
          $scope.contactInfoSetValue.ReferrerID &&
          $scope.contactInfoSetValue.ReferrerName;
        const referrerOrg =
          $scope.contactInfoSetValue.ReferrerOrgID &&
          $scope.contactInfoSetValue.ReferrerOrgName;
        return !(
          referrerOrg &&
          referrer &&
          $scope.contactInfoSetValue.ReferrerNameLabel
        );
      };

      function saveUtmCampaign(familyId, clientId, model) {
        corporateService
          .clientUTMDetailsSet(familyId, clientId, model)
          .then(() => {});
      }

      $scope.form = {};
      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.saveClientAccount = function () {
        const isAddressValid = checkAddressValidity($scope);
        const form = $scope.form.client;
        let firstError = null;
        if (form.$invalid || !isAddressValid) {
          $scope.prevStep();

          firstError = null;
          form &&
            Object.keys(form).forEach((field) => {
              if (field[0] !== '$') {
                if (firstError === null && !form[field].$valid) {
                  firstError = form[field].$name;
                }

                if (form[field].$pristine) {
                  form[field].$dirty = true;
                }
              }
            });

          angular.element(`.ng-invalid[name=${firstError}]`).focus();
          swal(
            // eslint-disable-next-line sonarjs/no-duplicate-string
            'Invalid Form',
            'Some required fields need to be filled out.',
            'error',
          );
          $scope.contacts.isSaving = false;
        } else if (!isAnguCompleteNotEmpty($scope.client.adviserSelected)) {
          swal(
            'Invalid Form',
            'Some required fields need to be filled out.',
            'error',
          );
          $scope.contacts.isSaving = false;
          // eslint-disable-next-line sonarjs/no-duplicated-branches
        } else if (isEnquiryNotValid()) {
          swal(
            'Invalid Form',
            'Some required fields need to be filled out.',
            'error',
          );
          $scope.contacts.isSaving = false;
        } else {
          const allContacts = [];

          $scope.personDetails.forEach((obj) => {
            obj.Phone = [
              {
                Type: 'Mobile',
                Number: obj.MobileNumber,
                CountryCode: obj.countryCode || '',
              },
            ];
            obj.Email = [{ Type: 'Email', EmailAddress: obj.MainEmail }];
            obj.ContactTypeID = $scope.contactType.ContactTypeId;
            obj.AllocatedAdviserID = isAnguCompleteNotEmpty(
              $scope.client.adviserSelected,
            )
              ? $scope.client.adviserSelected.originalObject.familyId
              : 0;
            const allocatedAssistantId = _.get(
              $scope.client,
              'selectedAssistant.originalObject.Value',
            );
            obj.AllocatedAssistantID = allocatedAssistantId || 0;
            obj.ReferralCategoryID = _.isUndefined(
              $scope.contactInfoSetValue.EnquirySource,
            )
              ? 0
              : $scope.contactInfoSetValue.EnquirySource.ReferralCategoryId;

            allContacts.push(obj);
          });

          $scope.childDetails.forEach((obj) => {
            allContacts.push(obj);
          });

          if (allContacts.length > 0) {
            clientInfoService
              .dateFormattingList(angular.copy(allContacts))
              .then((res) => {
                const tempAllContacts = res.data;

                saveClientContact({
                  $scope,
                  contactService,
                  saveUtmCampaign,
                  contacts: tempAllContacts,
                  contactsNotesService,
                });
              });
          }
        }
      };

      function allocateAdviser(clientFamilyId) {
        if (typeof $scope.client.adviserSelected.description !== 'undefined') {
          corporateService
            .assignClientToAdviserSet(
              $scope.client.adviserSelected.description.familyId,
              clientFamilyId,
              0,
            )
            .then((response) => {
              if (response.status === 200) {
                toaster.pop(
                  'success',
                  'Successfully',
                  'Assigned Adviser to Client',
                );
              }
            });
        } else if ($scope.client.adviserSelected.familyId) {
          corporateService
            .assignClientToAdviserSet(
              $scope.client.adviserSelected.familyId,
              clientFamilyId,
              0,
            )
            // eslint-disable-next-line sonarjs/no-identical-functions
            .then((response) => {
              if (response.status === 200) {
                toaster.pop(
                  'success',
                  'Successfully',
                  'Assigned Adviser to Client',
                );
              }
            });
        }
      }

      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.saveContactAddress = function (familyDetails, familyId) {
        if (displayField(familyDetails.ReferralItemId, CLIENT_REFERRAL)) {
          familyDetails.ReferrerClientId =
            $scope.referralExistingClient.ReferrerClientId || null;
          familyDetails.ClientReferrerFamilyId =
            $scope.referralExistingClient.ClientReferrerFamilyId || null;
        }
        if (
          familyDetails.ReferralCategoryId !== REFERRAL_ITEM.REFERRAL &&
          familyDetails.ReferralItemId !== REFERRAL_ITEM.REFERRAL
        ) {
          familyDetails.ReferrerAgreementTypeId = 0;
          familyDetails.ReferrerID = 0;
          familyDetails.ReferrerName = '';
          familyDetails.ReferrerNameLabel = '';
          familyDetails.ReferrerOrgID = 0;
          familyDetails.ReferrerOrgName = '';
        }
        if (
          !$scope.isAddressOverhaulEnabled &&
          $scope.contactAddress.HomeAddress
        ) {
          familyDetails.Addresses.push({
            Type: 'Home',
            geoCoded: $scope.isGeocodeHome,
            formatted_address: $scope.contactAddress.HomeAddress,
            IsMailing: false,
          });
          if (
            (!$scope.contactAddress.isMailingAddress &&
              $scope.contactAddress.MailingAddress) ||
            $scope.contactAddress.isMailingAddress
          ) {
            familyDetails.Addresses.push({
              Type: 'Postal',
              TypeId: ADDRESS_TYPE.POSTAL,
              geoCoded: $scope.contactAddress.isMailingAddress
                ? $scope.isGeocodeHome
                : $scope.isGeocodeMail,
              formatted_address: $scope.contactAddress.isMailingAddress
                ? $scope.contactAddress.HomeAddress
                : $scope.contactAddress.MailingAddress,
              IsMailing: true,
            });
          }
        }

        contactService.contactFamilyInfoSet(familyDetails).then((response) => {
          allocateAdviser(familyId);
          $timeout(() => {
            if ($scope.pipeLineParam) {
              let AdviserName = $scope.familyList.AdviserFullName;
              if (AdviserName.slice(-1) === 's') {
                AdviserName += "'";
              } else {
                AdviserName += "'s";
              }
              SweetAlert.swal(
                {
                  title: 'Success!',
                  text: 'Successfully saved the Client to New Card!',
                  type: 'success',
                  showCancelButton: false,
                  confirmButtonColor: '#DD6B55',
                  confirmButtonText: 'View Pipeline',
                  closeOnConfirm: true,
                },
                () => {
                  $state.go($localStorage.previousState, {
                    createdContact: response.data,
                    pipelineType: 'leads',
                  });
                },
              );
            } else if (response.data > 0) {
              $timeout(() => {
                SweetAlert.swal(
                  {
                    title: 'Success!',
                    text: 'Successfully saved the Client!',
                    type: 'success',
                    showCancelButton: true,
                    confirmButtonText: 'View Contact',
                    cancelButtonText: 'Add New Contact',
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#3085d6',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonClass: 'btn btn-info',
                  },
                  (isConfirm) => {
                    if (isConfirm) {
                      $state.go('app.contactsSingle', {
                        familyId: response.data,
                      });
                    } else {
                      $localStorage.currentContactTypeId =
                        $scope.contactType.ContactTypeId;
                      $state.reload();
                    }
                  },
                );
              });
            } else {
              swal('Oops...', 'Something went wrong!', 'error');
            }
            $scope.contacts.isSaving = false;
          });
        });
      };
      $scope.familyList = [];
      $scope.contactFamilyInfoGet = function (familyId) {
        contactService.contactFamilyInfoGet(familyId).then((response) => {
          $scope.familyList = response.data;
          const familyDetails = response.data;
          familyDetails.FamilyId = familyId;
          familyDetails.isMailingAddress =
            $scope.contactAddress.isMailingAddress;
          familyDetails.MainEmail = $scope.contactAddress.MainEmail;
          familyDetails.ContactStatusId = angular.copy(
            $scope.ContactStatus.ContactStatusId,
          );
          const isSaveClient =
            $scope.displayField(
              $scope.contactInfoSetValue.EnquirySource.ReferralItemId,
              $scope.clientEnquiryList,
            ) &&
            $scope.client.selectedClient &&
            $scope.client.selectedClient.originalObject;
          const isSaveDetails = $scope.displayField(
            $scope.contactInfoSetValue.EnquirySource.ReferralItemId,
            $scope.additionalEnquiryList,
          );
          familyDetails.ClientReferrerFamilyId = isSaveClient
            ? $scope.client.selectedClient.originalObject.FamilyID
            : 0;
          familyDetails.OtherContactReferrerName = isSaveDetails
            ? $scope.client.enquiryAdditionalDetails
            : '';
          const familyInfo = accountService.copyReferral(
            familyDetails,
            $scope.contactInfoSetValue,
          );
          initClientAddressSaving({
            $scope,
            generalService,
            addressLookupService,
            familyInfo,
            familyId,
          });
        });
      };

      $scope.ContactStatus = {};
      $scope.updateContactStatus = (status) => {
        clientInfoService.updateContactStatus($scope.ContactStatus, status);
      };
      $scope.homeAutoAddresss = function () {
        $timeout.cancel($scope.searchTimeout);
        $scope.searchTimeout = $timeout(() => {
          generalService
            .placeSearch($scope.contactAddress.HomeAddress)
            .then((respond) => {
              $scope.homeAutoAddressDetails = respond.data;
            });
        }, 500);
      };
      $scope.isGeocodeHome = false;
      $scope.isGeocodeMail = false;
      $scope.isClickedHome = false;
      $scope.isClickedMail = false;

      $scope.selectHomeAddress = function (homeAddress) {
        $scope.isClickedHome = true;
        $scope.contactAddress.HomeAddress = homeAddress;
        $scope.isGeocodeHome = true;
        $scope.closeAutoHomeAddress();
      };
      $scope.$watch('contactAddress.HomeAddress', () => {
        if ($scope.isClickedHome) {
          $scope.isClickedHome = false;
        } else if ($scope.isGeocodeHome) {
          $scope.isGeocodeHome = false;
        }
      });

      $scope.closeAutoHomeAddress = function () {
        $scope.homeAutoAddressDetails = [];
      };

      $scope.mailAutoAddresss = function () {
        $timeout.cancel($scope.searchTimeout);
        $scope.searchTimeout = $timeout(() => {
          generalService
            .placeSearch($scope.contactAddress.MailingAddress)
            .then((respond) => {
              $scope.mailAutoAddressDetails = respond.data;
            });
        }, 500);
      };

      $scope.selectMailAddress = function (mailAddress) {
        $scope.isClickedMail = true;
        $scope.contactAddress.MailingAddress = mailAddress;
        $scope.isGeocodeMail = true;
        $scope.closeAutoMailAddress();
      };
      $scope.$watch('contactAddress.MailingAddress', () => {
        if ($scope.isClickedMail) {
          $scope.isClickedMail = false;
        } else if ($scope.isGeocodeMail) {
          $scope.isGeocodeMail = false;
        }
      });

      $scope.closeAutoMailAddress = function () {
        $scope.mailAutoAddressDetails = [];
      };

      $scope.utmModel = {
        PostingCode: '',
        UTMSource: '',
        UTMMedium: '',
        UTMTerm: '',
        UTMContent: '',
        UTMCampaign: '',
      };

      $scope.client = {};
      $scope.client.adviserSelected = {};

      //= =========================== End of Client Account =============================
      $('.select-role').click(function () {
        const vm = this;
        $(vm).find('input[type="text"]').focus();
      });

      // Client Type
      $scope.clientType = {};
      $scope.clientType.selected = [];
      $scope.getClientType = function () {
        corporateService.getClientType().then((response) => {
          $scope.clientType.list = response.data;
        });
      };
      $scope.getClientType();

      // Validation
      $scope.contactModel = {};
      $scope.contactModel.isEmailValid = false;
      $scope.contactModel.isVerified = false;
      $scope.contactModel.isChecking = false;
      function checkIfEmailExist(email) {
        $scope.contactModel.isChecking = true;
        generalService.getCheckIfEmailExists(email, 0).then((response) => {
          $scope.contactModel.isEmailValid = false;
          if (response.data === 0) {
            $scope.contactModel.isEmailValid = true;
          }
          $scope.contactModel.isChecking = false;
        });
      }

      $scope.validateEmail = function () {
        $scope.contactModel.isVerified = false;
        if ($scope.adviserDetails.email) {
          checkIfEmailExist($scope.adviserDetails.email);
        } else {
          $scope.contactModel.isEmailValid = false;
        }
      };

      $scope.hasFirstName = false;
      $scope.hasLastName = false;
      $scope.hasMobile = false;
      $scope.hasRole = false;
      $scope.isValidating = false;

      $scope.mapModel = broadcastDataService;

      $scope.mobileMask = '';
      $scope.client.adviserInitialValue = '';
      $scope.allocation = {};
      $scope.allocation.isAllocationShow = true;
      $scope.openModalAdviserMapNearby = () => {
        const showAllocationButton = true;
        const modalInstance = loadAllocationModalService.launchBrokerAllocationModal(
          showAllocationButton,
          $scope.utmModel.PostingCode,
        );
        $scope.allocation.isAllocationShow = false;
        modalInstance.result.then(
          (adviserInfo) => {
            if (adviserInfo) {
              const mappedAdviser = advisersLookupBuilderForUI(adviserInfo);
              $scope.client.adviserSelected = mappedAdviser;
              $scope.client.adviserSelected.originalObject = mappedAdviser;
              $scope.client.adviserInitialValue = adviserInfo.FullName || '';
              $scope.referredByHelper.loopCount = 0;
              $scope.allocation.isAllocationShow = true;
            }
          },
          () => {
            if ($scope.allocation) {
              $scope.allocation.isAllocationShow = true;
            }
          },
        );
      };

      $scope.contactInfoSetValue = {};
      enquirySourceService.getEnquirySourceList($scope);

      // Enquiry is changed
      $scope.enquiryChanged = () => {
        $scope.contactInfoSetValue.LeadBillingID = getDefaultLeadBillingId(
          $scope.contactInfoSetValue.EnquirySource.ReferralCategoryId,
        );
        clientInfoService.enquiryChanged($scope.contactInfoSetValue);
      };
      $scope.referredByHelper = {
        showClientList: false,
        loopCount: 0,
        getReferralOrgList() {
          if (
            $scope.referredByHelper &&
            $scope.referredByHelper.loopCount < 1
          ) {
            const selectedAdviser =
              $scope.client.adviserSelected.description.familyId || 0;
            const adviserId =
              ($scope.client.adviserSelected &&
                $scope.client.adviserSelected.familyId) ||
              selectedAdviser;
            if (adviserId > 0) {
              referencedReferralService.getReferencedReferralList(
                adviserId,
                $scope,
              );
            }
          }
        },
        searchClient() {
          $scope.clientNotFound = false;
          $scope.referredByHelper.showClientList = true;
          $scope.referredByHelper.getReferralOrgList();
        },
      };

      $scope.referredByHelper.selectClient = (client) => {
        clientInfoService.selectClient(
          $scope.contactInfoSetValue,
          $scope.referredByHelper,
          client,
        );
      };
      $scope.$watch('client.adviserSelected', () => {
        $scope.referredByHelper.loopCount = 0;
      });

      $scope.toggleChildrenDiv = function (referralCategory) {
        if (referralCategory.IsEditMode) {
          return false;
        }

        $scope.selectedReferralCategory = {};
        _.map($scope.referralCategoryList, (o) => {
          o.isVisible = false;
          o.hasChildrenData = false;
          return o;
        });

        referralCategory.isVisible = !referralCategory.isVisible;
        $scope.contactInfoSetValue.ReferralCategory =
          referralCategory.CategoryName;

        if (referralCategory.isVisible && !referralCategory.hasChildrenData) {
          contactService
            .getReferralChildList(referralCategory.ReferralCategoryID)
            .then((response) => {
              referralCategory.Children = response.data;
              referralCategory.hasChildrenData = true;

              $scope.selectedReferralCategory = referralCategory;
            });
        }
      };

      $scope.openReferralModal = function (size) {
        const {
          getReferralCategoryList,
          contactFamilyInfoGet,
          contactInfoSetValue,
          selectedReferralCategory,
          referralCategoryList,
        } = $scope;
        const modalInstance = contactSharedData.openReferralModal(
          size,
          getReferralCategoryList,
          contactFamilyInfoGet,
          contactInfoSetValue,
          selectedReferralCategory,
          referralCategoryList,
        );
        modalInstance.result.then(
          () => {
            enquirySourceService.getEnquirySourceList($scope);
          },
          () => {
            enquirySourceService.getEnquirySourceList($scope);
          },
        );
      };

      optionsService.ContactStatusGet().then(({ data }) => {
        const filteredList = contactStatusService.filterStatusList(
          data,
          uiService.viewOfAfileTurnedOn,
        );
        $scope.contactStatusList = contactStatusService.populateStatusWithColor(
          filteredList,
        );
      });

      corporateService.cessationReasonGet().then((response) => {
        $scope.cessationReasons = response.data;
        if (response.data.length > 0) {
          $scope.agreements.cessationReason = response.data[0];
        }
      });

      corporateService.advisersUnderOrganizationGet(0).then((response) => {
        $scope.franchisePrincipalList = response.data;
      });
      $scope.geoCodedAddress = false;

      $scope.businessAutoAddresss = function () {
        $timeout.cancel($scope.searchTimeout);
        $scope.searchTimeout = $timeout(() => {
          generalService
            .placeSearch($scope.business.Address)
            .then((respond) => {
              $scope.officeAutoAddressDetails = respond.data;
            });
        }, 500);
      };

      $scope.closeOfficeAddress = function () {
        $scope.officeAutoAddressDetails = [];
      };

      $scope.selectBusinesAddress = function (homeAddress) {
        $scope.business.Address = homeAddress;
        $scope.geoCodedAddress = true;
        $scope.closeOfficeAddress();
      };

      $scope.validateBusinessAccount = () => {
        const isAdviser = isAnguCompleteNotEmpty($scope.client.adviserSelected);
        const isInvalid = !checkBusinessFormValidity({ $scope, isAdviser });
        const isAddressValid = checkBusinessAddressValidity($scope);
        if (isInvalid || !isAddressValid) {
          $scope.prevStep();
          swal(
            'Required Fields',
            'Please fill-out some required field/s',
            'error',
          );
          $scope.contacts.isSaving = false;
          return;
        }
        initBusinessAddressSaving({
          $scope,
          generalService,
          addressLookupService,
          isAdviser,
        });
      };

      $scope.saveBusinessAccount = (address, isAdviser) => {
        const orgSet = {
          OrganisationId: '0',
          Name: $scope.business.Name,
          Description: $scope.business.Description,
          OrganisationTaxNumber: $scope.business.OrganisationTaxNumber,
          OrganisationCompanyNumber: $scope.business.OrganisationCompanyNumber,
          FamilyId: '0',
          Phone: [
            {
              Type: 'Work',
              Number: $scope.business.Phone,
              CountryCode: $scope.businessEntity.countryCode || '',
            },
          ],
          Address: address,
          Email: [
            {
              Type: 'Email',
              EmailAddress: $scope.business.OfficeEmail,
            },
          ],
          Notes: $scope.business.Notes,
          PersonId: '0',
          PostingCode: $scope.business.PostingCode,
          AllocatedAdviserID: isAdviser
            ? $scope.client.adviserSelected.originalObject.familyId
            : 0,
          AllocatedAdviserName: isAdviser
            ? $scope.client.adviserSelected.originalObject.fullName
            : '',
        };
        contactService
          .organisationInfoSet(setupNewPostURL(orgSet, 'organisation'))
          .then(({ data: familyId }) => {
            if (!familyId) {
              swal('Oops...', 'Something went wrong!', 'error');
              $scope.contacts.isSaving = false;
              return;
            }
            const businessTimer = $timeout(() => {
              swal(
                {
                  title: 'Success!',
                  text: 'Successfully saved the Business!',
                  type: 'success',
                  showCancelButton: true,
                  confirmButtonText: 'View Contact',
                  cancelButtonText: 'Add New Contact',
                  closeOnConfirm: true,
                  closeOnCancel: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#3085d6',
                  confirmButtonClass: 'btn btn-success',
                  cancelButtonClass: 'btn btn-info',
                },
                (isConfirm) => {
                  if (isConfirm) {
                    $state.go('app.BusinessAccount', {
                      familyId,
                    });
                  } else {
                    $localStorage.currentContactTypeId =
                      $scope.contactType.ContactTypeId;
                    $state.reload();
                  }
                },
              );
            });
            $scope.$on('$destroy', () => {
              $timeout.cancel(businessTimer);
            });
          });
      };

      $scope.clientEnquiryList = CLIENT_REFERRAL;
      $scope.additionalEnquiryList = ADDITIONAL_REFERRAL;
      $scope.referredByAccessList = REFERRED_BY_ACCESS;
      $scope.LEAD_BILLING_ACCESS = HAS_LEADBILLING_CATEGORY;
      $scope.displayField = displayField;
      $scope.clientSearchApi = (userInputString) => {
        const defer = $q.defer();
        defer.resolve([]);
        const adviser = $scope.client.adviserSelected;
        const allocatedAdviserId =
          adviser && adviser.description ? adviser.description.FamilyId : 0;
        const adviserId =
          adviser && adviser.FamilyId ? adviser.FamilyId : allocatedAdviserId;
        if (!userInputString || !adviserId) {
          return defer.promise;
        }
        return contactService.searchFamilyContactType(
          userInputString,
          'Client',
          adviserId,
        );
      };
      const getFeeCardTypes = () => {
        generalService.getFeeCardTypes().then((data) => {
          if (!data || !data.length) {
            return;
          }
          $scope.feeCardValues = data;
          $scope.feeCardId = data[0].feeCardId;
        });
      };
      getFeeCardTypes();

      const getBusinessTypes = () => {
        optionsService.getBusinessTypes().then((data) => {
          if (!data || !data.length) {
            return;
          }
          $scope.referrer.businessTypeList = data;
        });
      };
      getBusinessTypes();

      $scope.showHideBusinessTypeDescription = () => {
        $scope.showBusinessTypeDescription = isBusinessTypeOthers(
          $scope.referrer.businessTypeId,
        );
      };

      const getLeadBillingList = () => {
        optionsService.getLeadBillingList().then((response) => {
          $scope.leadBillingList = response || [];
        });
      };
      getLeadBillingList();
    });
