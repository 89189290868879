import template from './questionTextAndIcon.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    titleValue: '<',
    inputValue: '<',
    iconText: '@',
    placeHolder: '@',
    onChange: '&',
  },
};
