import {
  getPhoneNumberByType,
  getEmailByType,
  getFirstAddressId,
} from 'Common/utilities/contact.js';
import { COLLAPSE } from 'Common/constants/livingExpense';
import { FINANCIAL_FREQUENCY } from 'Common/constants/financialFrequency';
import { getParsed } from 'Common/utilities/math';
import {
  getOwnerName,
  getLastSavedDescription,
  setBorrowerId,
} from 'Common/utilities/livingExpense';
import { SOURCE_PLATFORM } from 'Common/constants/sourcePlatform';
import { RATE_PERIOD } from 'Common/constants/loanAppDiscount';
import { loanScenarioSimplifiedListLabelFormatter } from 'Common/utilities/loanApplication';

export function manualIFinanceBuilderForUI(manualIFinance) {
  return (
    manualIFinance && {
      lenderName: manualIFinance.Lender,
      assetVariant: manualIFinance.ProductName,
      establishmentFee: manualIFinance.EstablishmentFee,
      accountKeepingFee: manualIFinance.AccountKeepingFee,
      repaymentAmount: manualIFinance.Repayment,
      productRepaymentFrequency: manualIFinance.ManualProdRepaymentFrequency,
      interestRate: manualIFinance.InterestRate,
      brokerageAmount: manualIFinance.BrokerageAmount,
      brokeragePercent: manualIFinance.BrokeragePercent,
      repaymentFrequency: manualIFinance.RepaymentFrequency,
    }
  );
}

export function manualIFinanceBuilderForMyCRM(manualIFinance) {
  return (
    manualIFinance && {
      Lender: manualIFinance.lenderName,
      ProductName: manualIFinance.assetVariant,
      EstablishmentFee: manualIFinance.establishmentFee,
      AccountKeepingFee: manualIFinance.accountKeepingFee,
      Repayment: manualIFinance.repaymentAmount,
      ManualProdRepaymentFrequency: manualIFinance.productRepaymentFrequency,
      InterestRate: manualIFinance.interestRate,
      BrokerageAmount: manualIFinance.brokerageAmount,
      BrokeragePercent: manualIFinance.brokeragePercent,
      RepaymentFrequency: manualIFinance.repaymentFrequency,
    }
  );
}

export function assetSupplierBuilderForMyCRM(assetSupplier) {
  return (
    assetSupplier && {
      AssetSupplierId: assetSupplier.assetSupplierId,
      FirstName: assetSupplier.firstname,
      LastName: assetSupplier.lastname,
      Title: assetSupplier.title,
      Gender: assetSupplier.gender,
      ACN: assetSupplier.abnacn,
      CompanyName: assetSupplier.companyName,
      DealerNumber: assetSupplier.dealerNumber,
      Phone: [
        {
          Type: 'Fax',
          Number: assetSupplier.fax,
        },
        {
          Type: 'Work',
          Number: assetSupplier.officePhone,
        },
        {
          Type: 'Mobile',
          Number: assetSupplier.mobile,
        },
      ],
      AddressId: assetSupplier.addressId,
      Email: [
        {
          Type: 'Email',
          EmailAddress: assetSupplier.email,
        },
      ],
    }
  );
}

export function assetSupplierBuilderForUI(assetSupplier) {
  return (
    assetSupplier && {
      assetSupplierId: assetSupplier.AssetSupplierId,
      firstname: assetSupplier.FirstName,
      lastname: assetSupplier.LastName,
      title: assetSupplier.Title,
      gender: assetSupplier.Gender,
      abnacn: assetSupplier.ACN,
      companyName: assetSupplier.CompanyName,
      dealerNumber: assetSupplier.DealerNumber,
      officePhone: getPhoneNumberByType(assetSupplier.Phone, 'Work'),
      mobile: getPhoneNumberByType(assetSupplier.Phone, 'Mobile'),
      fax: getPhoneNumberByType(assetSupplier.Phone, 'Fax'),
      addressId: assetSupplier.AddressId,
      email: getEmailByType(assetSupplier.Email, 'Email'),
    }
  );
}

export function possibleAdverseChangesBuilderForUI(possibleAdverseChanges) {
  return {
    clientId: possibleAdverseChanges.ClientId,
    clientFullName: possibleAdverseChanges.ClientFullName,
    familyId: possibleAdverseChanges.FamilyId,
    loanScenarioId: possibleAdverseChanges.LoanScenarioId,
    questionId: possibleAdverseChanges.QuestionId,
    answer: possibleAdverseChanges.Answer,
    natureOfChange: possibleAdverseChanges.NatureOfChange,
    periodOfImpact: possibleAdverseChanges.PeriodOfImpact,
    financialImpact: possibleAdverseChanges.FinancialImpact,
  };
}

export function possibleAnticipatedBuilderForUI(possibleAnticipated) {
  return {
    clientId: possibleAnticipated.ClientId,
    natureOfChangeId: possibleAnticipated.NatureOfChangeId,
    loanScenarioId: possibleAnticipated.LoanScenarioId,
    natureOfChange: possibleAnticipated.NatureOfChange,
    periodOfImpactDuration: possibleAnticipated.PeriodOfImpactDuration,
    startDate: new Date(possibleAnticipated.StartDate),
    periodOfImpactUnit: possibleAnticipated.PeriodOfImpactUnit,
    financialImpactOperator: possibleAnticipated.FinancialImpactOperator,
    financialImpactAmount: possibleAnticipated.FinancialImpactAmount,
    objectiveListName: possibleAnticipated.ObjectiveListName,
    other: possibleAnticipated.Other,
  };
}

export function possiblePlanOrAnticipateBuilderForUI(possiblePlanOrAnticipate) {
  return {
    planOrAnticipateId: possiblePlanOrAnticipate.PlanOrAnticipateId,
    clientId: possiblePlanOrAnticipate.ClientId,
    objectiveListValue: possiblePlanOrAnticipate.ObjectiveListValue,
    optionName: possiblePlanOrAnticipate.OptionName,
    details: possiblePlanOrAnticipate.Details,
  };
}
export function assetSupplierContactBuilderForUI(assetSupplier) {
  return {
    assetSupplierId: assetSupplier.PersonId,
    firstname: assetSupplier.FirstName,
    lastname: assetSupplier.LastName,
    title: assetSupplier.Title,
    gender: assetSupplier.Gender,
    abnacn: assetSupplier.ACN,
    companyName: assetSupplier.CompanyName,
    dealerNumber: assetSupplier.DealerNumber,
    officePhone: getPhoneNumberByType(assetSupplier.Phone, 'Work'),
    mobile: getPhoneNumberByType(assetSupplier.Phone, 'Mobile'),
    fax: getPhoneNumberByType(assetSupplier.Phone, 'Fax'),
    addressId: getFirstAddressId(assetSupplier.Address),
    email: getEmailByType(assetSupplier.Email, 'Email'),
  };
}

export function cqpDynamicFeeBuilderForUI(data) {
  return (
    data && {
      feeId: data.FeeId,
      loanApplicationID: data.LoanApplicationID,
      description: data.Description,
      amount: data.Amount,
      originalAmount: data.OriginalAmount,
      payableWhen: data.PayableWhen,
      isChecked: data.IsCheck,
      loanStructureId: data.LoanStructureId,
    }
  );
}

export function rationaleQuestionBuilderForUI(data) {
  return (
    data && {
      loanApplicationId: data.LoanApplicationId,
      question: data.Question,
      questionId: data.QuestionId,
      questionTypeId: data.QuestionTypeId,
      answerString:
        data.AnswerString && data.AnswerString.replace(/<br>/g, '\n'),
      displayOptionId: data.DisplayOptionId,
      questionSectionId: data.QuestionSectionId,
      placeholder: data.Placeholder,
    }
  );
}

export const rationaleQuestionBuilderForMyCRM = (data) => {
  return (
    data && {
      LoanApplicationId: data.loanApplicationId,
      QuestionId: data.questionId,
      AnswerString: data.answerString,
    }
  );
};

export const brokerEventIdBuilderForUI = (data) => {
  return (
    data && {
      brokerEventId: data.BrokerEventId,
      productLoanStructureId: data.ProductLoanStructureId,
    }
  );
};

export function cqpDynamicFeeBuilderForMyCRM(data) {
  return (
    data && {
      FeeId: data.feeId,
      LoanApplicationID: data.loanApplicationID,
      Description: data.description,
      Amount: data.amount,
      PayableWhen: data.payableWhen,
      LoanStructureId: data.loanStructureId,
    }
  );
}

export function documentZipBuilderForUI(data) {
  return (
    data && {
      name: data.Name,
      size: data.Size,
      contentType: data.ContentType,
      documentContent: data.DocumentContent,
      documentExists: data.DocumentExists,
      isSvg: data.IsSvg,
      imageUploadType: data.ImageUploadType,
    }
  );
}

export function loanScenarioFactFindBuilderForUI(data) {
  return (
    data && {
      IsLockable: data.IsLockable,
      IsLocked: data.IsLocked,
      SharedTo: data.SharedTo,
    }
  );
}

export function applicationStatusBuilderForUI(status) {
  return (
    status && {
      category: status.LoanStatusCategory,
      id: status.LoanStatusID,
      name: status.LoanStatusName,
    }
  );
}

export function lenderBuilderForUI(lender) {
  return (
    lender && {
      countryCode: lender.CountryCode,
      id: lender.LenderId,
      name: lender.LenderName,
    }
  );
}

export function loanBuilderForUI(data) {
  return (
    data && {
      applicants: data.Applictants && data.Applictants.trim(),
      borrower: data.Borrower,
      clientName: data.ClientName,
      referrerName: data.ClientReferrerName,
      createdDate: data.CreatedDate,
      familyList: data.FamilyList,
      fixedExpiryDescription: data.FixedExpiryDescription,
      hasBeenSentToNextGen: data.HasBeenSentToNextGen,
      isCurrent: data.IsCurrent,
      isOpportunity: data.IsOpportunity,
      isPipelineLinked: data.IsPipelineLinked,
      isReadOnly: data.IsReadOnly,
      lender: data.Lender && lenderBuilderForUI(data.Lender),
      loanAmount: data.LoanAmount,
      loanId: data.LoanID,
      loanScenarioId: data.LoanScenarioID,
      loanScenarioTitle: data.LoanScenarioTitle,
      status: data.LoanStatus && applicationStatusBuilderForUI(data.LoanStatus),
      loanStructureList: data.LoanStructureList,
      pipelineCardsId: data.PipelineCardsID,
      pipelineStatus: data.PipelineStatus,
      pipelineStatusId: data.PipelineStatusID,
      referenceNumber: data.ReferenceNumber,
    }
  );
}

export function lenderInvoiceBuilderForUI(data) {
  return (
    data && {
      id: data.ProviderId,
      label: data.LenderName,
      countryCode: data.CountryCode,
      email: data.Email,
      isLender: true,
    }
  );
}

export function taxInvoiceRecipientsBuilderForUI(data) {
  return (
    data && {
      id: data.FamilyId,
      label: data.RecipientName,
    }
  );
}

export function taxInvoiceBuilderForUI(data) {
  return (
    data && {
      brokerageFee: data.BrokerageFee,
      documentFee: data.DocumentFee,
      lender: (data.Lender && lenderInvoiceBuilderForUI(data.Lender)) || {},
      recipients:
        data.Recipients &&
        data.Recipients.map((recipient) =>
          taxInvoiceRecipientsBuilderForUI(recipient),
        ),
    }
  );
}

export function taxInvoicePDFBuilderForUI(data) {
  return (
    data && {
      contentType: data.ContentType,
      documentContent: data.DocumentContent,
    }
  );
}

export function taxInvoiceEmailBuilderForUI(data) {
  return (
    data && {
      emailAddress: data.EmailAddress,
      remarks: data.EmailRemarks,
    }
  );
}

export const expenseChildTypeBuilderForUI = (data, index) => {
  return (
    data && {
      financialId: data.FinancialId || 0,
      id: data.Id || 0,
      borrowers: data.Borrowers,
      type: data.Type,
      typeId: data.TypeId,
      value: data.Value,
      frequencyId: data.FrequencyId || FINANCIAL_FREQUENCY.MONTHLY,
      ...COLLAPSE.TYPE,
      owner: getOwnerName(data.Borrowers),
      isContinuingAfterSettlement: data.IsContinuingAfterSettlement,
      borrowerId: setBorrowerId(data && data.Borrowers),
      isMainExpense: !!data.IsMainExpense,
      description: data.Description,
      ...(data.Description && { otherIndex: index }),
    }
  );
};

export const borrowerBuilderForMyCRM = (data) => {
  return (
    data && {
      BorrowerID: data.BorrowerID,
      IsEntity: data.IsEntity,
    }
  );
};

export const expenseChildTypeBuilderForMyCRM = (data) => {
  return (
    data && {
      FinancialId: data.financialId || 0,
      Id: data.id || 0,
      Borrowers:
        (data.borrowers && data.borrowers.map(borrowerBuilderForMyCRM)) || [],
      Type: data.type,
      TypeId: data.typeId,
      Value: getParsed(data.value),
      FrequencyId: data.frequencyId || FINANCIAL_FREQUENCY.MONTHLY,
      IsContinuingAfterSettlement: data.isContinuingAfterSettlement,
      IsMainExpense: data.isMainExpense,
      IsDeleted: data.isDeleted,
      Description: data.description,
    }
  );
};

export const expenseTypeBuilderMapperForUI = (data) => {
  return (
    data && {
      category: data.Category,
      categoryId: data.CategoryId,
      groupTotalAmount: data.GroupTotalAmount,
      description: data.Description || '',
      groupTotalAmountPerMonth: data.GroupTotalAmountPerMonth,
      lastSavedDescription: getLastSavedDescription(data),
      lastModifiedDate: data.LastModifiedDate,
      modifiedBy: data.ModifiedBy,
      types: data.Types && data.Types.map(expenseChildTypeBuilderForUI),
      ...COLLAPSE.CATEGORY,
      hasOther: data.HasOther,
    }
  );
};
export const expenseTypeBuilderForUI = (data) => {
  return (
    data && {
      financialType: data.FinancialType,
      totalAmount: data.TotalAmount,
      financialInfo:
        data.FinancialInfo &&
        data.FinancialInfo.map(expenseTypeBuilderMapperForUI),
    }
  );
};

export const expenseTypeBuilderForMyCRM = (data) => {
  return (
    data && {
      Category: data.category,
      CategoryId: data.categoryId,
      FamilyId: data.familyId,
      GroupTotalAmount: data.groupTotalAmount,
      Description: data.description || '',
      SourcePlatform: SOURCE_PLATFORM.MY_CRM.ID,
      Types:
        (data.types && data.types.map(expenseChildTypeBuilderForMyCRM)) || [],
    }
  );
};
export function tradeReferenceBuilderForUI(data) {
  return (
    data && {
      tradeReferenceId: data.TradeReferenceId,
      companyName: data.CompanyName,
      firstName: data.FirstName,
      lastName: data.LastName,
      phoneNumber: data.PhoneNumber,
    }
  );
}

export const productReferenceRatesBuilderForUI = (data, rateState) => {
  const { ProductName, OngoingRate, InitialRate } = data;
  const rateLabel =
    rateState === RATE_PERIOD.INITIAL.VALUE
      ? {
          label: `${ProductName} (${InitialRate}%)`,
          rate: InitialRate,
          ratePeriod: RATE_PERIOD.INITIAL.VALUE,
        }
      : {
          label: `${ProductName} (${OngoingRate}%)`,
          rate: OngoingRate,
          ratePeriod: RATE_PERIOD.ONGOING.VALUE,
        };
  return (
    data && {
      initialRate: InitialRate,
      ongoingRate: OngoingRate,
      productName: ProductName,
      referenceProductId: data.ProductId,
      ...rateLabel,
    }
  );
};
export const referenceRatesBuilderForUI = (data) => {
  return (
    data && {
      ongoingReferenceRateProducts:
        (data.OngoingReferenceRateProducts &&
          data.OngoingReferenceRateProducts.map((rate) =>
            productReferenceRatesBuilderForUI(rate, RATE_PERIOD.ONGOING.VALUE),
          )) ||
        [],
      initialReferenceRateProducts:
        (data.InitialReferenceRateProducts &&
          data.InitialReferenceRateProducts.map((rate) =>
            productReferenceRatesBuilderForUI(rate, RATE_PERIOD.INITIAL.VALUE),
          )) ||
        [],
    }
  );
};

export function loansForPipelineLinkingBuilderForUI(data) {
  return (
    data && {
      countryCode: data.CountryCode,
      isLockable: data.IsLockable,
      isLocked: data.IsLocked,
      lenderId: data.LenderID,
      lenderName: data.LenderName,
      loanAmount: data.LoanAmount,
      loanAppId: data.LoanAppID,
      loanPurpose: data.LoanPurpose,
      dateTimeCreated: data.DateTimeCreated,
      loanId: data.LoanID,
      pipelineStatusId: data.PipelineStatusID,
    }
  );
}

export const brokerNotesBuilderForUI = (brokerNotes) => {
  return {
    brokerNotes: brokerNotes.BrokerNotes,
    subsectionId: brokerNotes.SubsectionId,
  };
};

export function natureOfChangeBuilderForUI(item) {
  return {
    clientId: item.ClientId,
    details: item.Details,
    financialImpactAmount: item.FinancialImpactAmount,
    financialImpactOperator: item.FinancialImpactOperator,
    loanScenarioId: item.LoanScenarioId,
    natureOfChange: item.NatureOfChange,
    natureOfChangeId: item.NatureOfChangeId,
    objectiveListId: item.ObjectiveListId,
    objectiveListName: item.ObjectiveListName,
    objectiveListValue: item.ObjectiveListValue,
    optionId: item.OptionId,
    optionName: item.OptionName,
    periodOfImpactDuration: item.PeriodOfImpactDuration,
    periodOfImpactUnit: item.PeriodOfImpactUnit,
    planOrAnticipateId: item.PlanOrAnticipateId,
    startDate: new Date(item.StartDate),
    other: item.Other,
  };
}

export const otherFundsReqBuilderForUI = (item) => {
  return {
    assetTypeId: item.AssetTypeId,
    assetTypeName: item.AssetTypeName,
    condition: item.Condition,
    description: item.Description,
    isReadOnly: item.IsReadOnly,
    loanApplicationId: item.LoanApplicationId,
    otherFundingRequirementId: item.OtherFundingRequirementId,
    ownershipId: item.OwnershipId,
    price: item.Price,
  };
};

export const lenderSubmissionEmailBuilderForUI = (data) => {
  return (
    data && {
      providerId: data.ProviderId,
      email: data.Email,
      typeId: data.DefaultTypeId,
      type: data.DefaultType,
    }
  );
};

export const loanScenarioSimplifiedListBuilderForUI = ($filter) => (data) => {
  return (
    data && {
      loanApplicationId: data.LoanApplicationId,
      loanId: data.LoanId,
      loanScenarioTitle: data.LoanScenarioTitle,
      loanStatusName: data.LoanStatusName,
      loanAmount: data.LoanAmount,
      lenderName: data.LenderName,
      loanCategory: data.LoanCategory,
      lastModifiedDate: data.LastModifiedDate,
      label: loanScenarioSimplifiedListLabelFormatter({ $filter, data }),
    }
  );
};
