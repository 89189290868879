import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { toastError } from 'Common/utilities/alert';
import { checkIfInvestment } from 'Common/utilities/security';
import {
  populatePropertyType,
  setDefaultValues,
  onSelectAll,
  getOwnersListOnItemSelect,
  isMortgageOwnersUtil,
  addAsset,
  getNewRentalIncomeModel,
  getNewMortgageLiabilityModel,
  setOptionsList,
  getLocalLang,
  searchCreditorInstitution,
  pickedCreditorLookup,
  checkCreditorsList,
  initializeAddressLookup,
} from 'Common/utilities/assetsModal';
import { KEY_CODE } from 'Common/constants/keyCode';
import { parseToInt10 } from 'Common/utilities/parse';
import { initSelectedOwnersUtil } from 'Common/utilities/assets';
import { initializeAssetsDependents } from './util/loanAppAssetModalCtrl';
import { setUpNZNewServiceability } from 'Common/utilities/financialLiabilities';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('LoanappAssetModalCtrl', function LoanappAssetModalCtrl(
      $scope,
      $uibModalInstance,
      modalType,
      ownersList,
      isRealEstate,
      $timeout,
      SweetAlert,
      loanScenarioService,
      optionsService,
      $rootScope,
      toaster,
      generalService,
      loanAppSharedData,
      financialsSharedService,
      financeService,
      currentUserService,
      dashboardService,
      loanApplicantAssetInfoService,
      contactService,
      uiService,
      familyId,
      loanAppId,
      assetsSet,
      optionsObj,
      addAssetCallback,
      timeoutService,
      blueStoneSubmissionService,
      listLender,
      configService,
      addressLookupService,
      loanStructureService,
    ) {
      initializeAssetsDependents($scope, currentUserService);
      $scope.populatePropertyTypeTemplate = (zoningId) => {
        $scope.populatePropertyTypeCall(optionsService, zoningId);
      };
      $scope.loanAppSharedData = loanAppSharedData;
      $scope.uiService = uiService;
      $scope.financialsSharedService = financialsSharedService;
      $scope.formSubmittedValidation = false;
      const {
        loanTermRemainingMappingToAOL,
        nzNewServiceability,
      } = configService.feature;
      $scope.loanTermRemainingMappingToAOL = loanTermRemainingMappingToAOL;

      $scope.nzNewServiceability = nzNewServiceability;
      nzNewServiceability &&
        setUpNZNewServiceability({
          $scope,
          loanStructureService,
          optionsService,
          isAssetLiability: true,
        });
      // Create a loan term list
      $scope.loanTermList = [];
      $scope.rentalVerificationTypeList = [];
      $scope.ListPropertyType = [];
      $scope.subAssetTypes = [];

      const defaultMortgageValuesAU = financeService.getDefaultMortgageAU();
      const setDefaultValuesUtil = setDefaultValues({
        $scope,
        optionsService,
        financeService,
        isNZ: currentUserService.isNZ,
      });
      const addAssetUtil = addAsset({
        $scope,
        currentUserService,
        loanScenarioService,
        dashboardService,
        loanAppSharedData,
        addressLookupService,
      });
      const getNewRentalIncomeModelUtil = getNewRentalIncomeModel({
        $scope,
        currentUserService,
      });
      const getNewMortgageLiabilityModelUtil = getNewMortgageLiabilityModel({
        $scope,
        currentUserService,
        defaultMortgageValuesAU,
      });
      $scope.listLender = listLender;
      $scope.familyId = familyId;
      $scope.loanAppId = loanAppId;
      $scope.AssetsSet = assetsSet;
      $scope.populatePropertyTypeCall = populatePropertyType({
        $scope,
        financeService,
        currentUserService,
      });
      $scope.onSelectAll = onSelectAll({ $scope });
      $scope.localLang = getLocalLang();
      $scope.searchCreditorInstitution = searchCreditorInstitution({ $scope });
      $scope.pickedCreditorLookup = pickedCreditorLookup({ $scope });
      $scope.setDefaultValuesMethod = (fieldName) => {
        setDefaultValuesUtil(fieldName);
      };
      $scope.getSubAssetType = () => {
        financeService.getSubAssetType($scope);
      };

      optionsService.getLoanTerms().then((loanTermResponse) => {
        $scope.loanTermList = loanTermResponse;
      });

      $scope.checkIfInvestment = () => {
        $scope.isInvestment = checkIfInvestment(
          $scope.AssetsSet.PrimaryPurposeId,
        );
      };
      const initSelectedOwners = () => {
        const selectedFamilyId = parseToInt10(
          _.get($scope.financialsSharedService, 'selectedFamily.FamilyId', 0),
        );
        if (modalType === 'new') {
          initSelectedOwnersUtil({
            ownersList: $scope.ownersList,
            selectedFamilyId,
          });
        } else {
          $scope.ownersList = ownersList;
        }
      };

      const init = () => {
        setOptionsList({ $scope, optionsObj });
        checkCreditorsList({ $scope, contactService });
        if (modalType === 'new') {
          loanApplicantAssetInfoService.updateIds(
            $scope.AssetsSet,
            $scope.ValueBasisList,
          );
          $scope.setDefaultValuesMethod('all');
        }
        $scope.getSubAssetType();
        $scope.checkIfInvestment();
        $scope.AssetsSet.CurrencyCode =
          currentUserService.brokerRegionalization.code;
        initSelectedOwners();
      };
      init();

      const getAddressList = () => {
        $scope.propertyList = loanAppSharedData.addressList.filter(
          (obj) => obj,
        );
      };
      // Set type for real estate since there are no dropdown
      if (isRealEstate) {
        getAddressList();
        $scope.AssetsSet.Type = _.find($scope.assetTypes, (o) => {
          return o.Desc === 'Real Estate';
        });
      }

      $scope.frequencyTypes = [];
      optionsService.FinancialFrequency().then((response) => {
        _.map(response.data, (o) => {
          o.Desc = o.Name;
          o.Value = parseInt(o.Value, 10);

          return o;
        });

        $scope.frequencyTypes = response.data;
      });

      if ($rootScope.crmCountry === 'New Zealand') {
        optionsService.RentalVerificationTypeGet().then((response) => {
          $scope.rentalVerificationTypeList = response.data;
        });
      }

      // Models
      angular.extend($scope, {
        Title: modalType === 'new' ? 'Add' : 'Edit',
        SaveTitle: modalType === 'new' ? 'Add' : 'Edit',
        Years: [],
      });
      for (let i = moment().year(); i >= 1930; i--) {
        $scope.Years.push(i);
      }

      // Methods
      angular.extend($scope, {
        cancel() {
          $uibModalInstance.dismiss('cancel');
        },

        fullAutoAddress(event) {
          // setter
          $timeout.cancel($scope.searchTimeout);
          if (event.which === KEY_CODE.ESC) {
            return;
          }
          $scope.searchTimeout = $timeout(() => {
            if (_.isUndefined($scope.AssetsSet.FullAddress)) {
              $scope.AssetsSet.FullAddress = {};
            }

            generalService
              .placeSearch($scope.AssetsSet.FullAddress.formatted_address)
              .then((respond) => {
                $scope.fullAutoAddressDetails = respond.data;
              })
              .catch(toastError);
          }, 500);
        },
        selectFullAddress(fullAddress) {
          $scope.AssetsSet.FullAddress = fullAddress;
          $scope.closeAutoFullAddress();
        },
        closeAutoFullAddress() {
          $scope.fullAutoAddressDetails = [];
        },
        saveBreakdown(form) {
          if (form) {
            let assetType = _.find($scope.assetTypes, (o) => {
              return o.Desc === $scope.AssetsSet.Type.Desc;
            });
            if (!assetType) {
              assetType = {
                Group: '',
              };
            }
            const validateAssetValue =
              _.isUndefined($scope.AssetsSet.Value) ||
              _.isNull($scope.AssetsSet.Value) ||
              $scope.AssetsSet.Value === 0;
            if (assetType.Group === 'Vehicle' && validateAssetValue) {
              toaster.pop('warning', 'Warning', 'Amount is required');
              return false;
            }
            if (
              assetType.Group === 'Real Estate' &&
              (_.isUndefined($scope.AssetsSet.FullAddress) ||
                _.size($scope.AssetsSet.FullAddress) < 1)
            ) {
              toaster.pop('warning', 'Warning', 'Address is required');
              return false;
            }
            $scope.formSubmittedValidation = true;
            $scope.addAsset(true);
            $scope.cancel();
            blueStoneSubmissionService.validateCountries();
          }
        },
        addRentalIncome() {
          const rentalIncome = $scope.AssetsSet.RentalIncome || [];
          const index = rentalIncome.length + 1;
          const newRentalIncome = getNewRentalIncomeModelUtil(index);
          $scope.AssetsSet.RentalIncome = rentalIncome.concat(newRentalIncome);
        },
        removeRentalIncome(income) {
          if (income === 0) {
            $scope.AssetsSet.RentalIncome = [];
          } else if (income.Id === 0) {
            _.remove($scope.AssetsSet.RentalIncome, (o) => {
              return _.isEqual(o, income);
            });

            _.forEach($scope.AssetsSet.RentalIncome, (o, key) => {
              o.index = key + 1; // putting index 1 to n
            });
          } else {
            SweetAlert.swal(
              {
                title: 'Are you sure?',
                text: 'This record will be removed from your income list',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F68F8F',
                confirmButtonText: 'Yes, remove it!',
                closeOnConfirm: false,
              },
              (confirm) => {
                if (confirm) {
                  loanScenarioService
                    .IncomeInfoDelete($scope.loanAppId, income.Id)
                    .then(() => {
                      $timeout(() => {
                        financialsSharedService.IncomeInfoGet();

                        _.remove($scope.AssetsSet.RentalIncome, (o) => {
                          return _.isEqual(o, income);
                        });

                        _.forEach($scope.AssetsSet.RentalIncome, (o, key) => {
                          o.index = key + 1; // putting index 1 to n
                        });

                        SweetAlert.swal({
                          title: 'Success',
                          text: 'Income has been been successfully deleted.',
                          type: 'success',
                          timer: 2000,
                          showConfirmButton: false,
                        });
                      }, 100);
                    })
                    .catch(toastError);
                }
              },
            );
          }
        },
        addMortgageLiability() {
          const mortgage = $scope.AssetsSet.MortgageLiability || [];
          const index = mortgage.length + 1;
          const newMortgage = getNewMortgageLiabilityModelUtil(index);
          $scope.AssetsSet.MortgageLiability = mortgage.concat(newMortgage);
        },
        removeMortgageLiability(mortgage) {
          if (mortgage === 0) {
            $scope.AssetsSet.MortgageLiability = [];
          } else {
            mortgage.removeLiability = () => {
              _.remove($scope.AssetsSet.MortgageLiability, (o) => {
                return _.isEqual(o, mortgage);
              });

              _.forEach($scope.AssetsSet.MortgageLiability, (o, key) => {
                o.index = key + 1; // putting index 1 to n
              });
            };

            if (!mortgage.Id) {
              mortgage.removeLiability();
            } else {
              SweetAlert.swal(
                {
                  title: 'Are you sure?',
                  text: 'This record will be removed from your mortgage list',
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#F68F8F',
                  confirmButtonText: 'Yes, remove it!',
                  closeOnConfirm: false,
                },
                (confirm) => {
                  if (confirm) {
                    loanScenarioService
                      .LiabilityInfoDelete($scope.loanAppId, mortgage.Id)
                      .then(() => {
                        $timeout(() => {
                          financialsSharedService.LiabilityInfoGet();

                          mortgage.removeLiability();

                          SweetAlert.swal({
                            title: 'Success',
                            text:
                              'Liability has been been successfully deleted.',
                            type: 'success',
                            timer: 2000,
                            showConfirmButton: false,
                          });
                        }, 100);
                      })
                      .catch(toastError);
                  }
                },
              );
            }
          }
        },
        hideAssetForm() {
          $scope.displayAssetsForm = false;
        },
      });

      $scope.$watch('AssetsSet.RentalIncome', () => {
        if (_.size($scope.AssetsSet.RentalIncome) > 0) {
          $scope.AssetsSet.IsRentalIncome = true;
        } else {
          $scope.AssetsSet.IsRentalIncome = false;
        }
      });

      $scope.$watch('AssetsSet.MortgageLiability', () => {
        if (_.size($scope.AssetsSet.MortgageLiability) > 0) {
          $scope.AssetsSet.IsExistingMortgages = true;
        } else {
          $scope.AssetsSet.IsExistingMortgages = false;
        }
      });
      $scope.populatePropertyTypeCall(
        optionsService,
        $scope.AssetsSet.ZoningId,
      );

      $scope.addAsset = (isModal) => {
        addAssetUtil(isModal, modalType, addAssetCallback);
      };

      $scope.onItemSelect = (data, paramOwnerList, mortgageIndex) => {
        const evaluatedOwnersList = getOwnersListOnItemSelect({
          ownersList: paramOwnerList,
        })(data);
        const isMortgageOwners = isMortgageOwnersUtil(
          $scope.AssetsSet,
          mortgageIndex,
        );
        if (isMortgageOwners) {
          isMortgageOwners.dropdownOwnersList = evaluatedOwnersList;
          isMortgageOwners.Borrowers = evaluatedOwnersList;
        } else {
          $scope.ownersList = evaluatedOwnersList;
        }
      };

      $scope.toggleSearchCreditor = (toShow) => {
        $scope.showCreditorResults = toShow;
      };

      $scope.$on('$destroy', () => {
        timeoutService.cancelTimeouts('LoanappAssetModalCtrl');
      });
      initializeAddressLookup({ $scope, configService });
    });
