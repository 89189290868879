import template from './modalProviders.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    lenderId: '<',
    modalInstance: '<',
  },
};
