import _ from 'lodash';
import LoanSubmissionClientEmailContent from 'Common/services/loanSubmissionClientEmailContent';

class LoanSubmissionEmailContentService {
  constructor(
    $q,
    loanScenarioService,
    contactService,
    corporateService,
    loanAppSharedData,
    loanSubmissionUIService,
    currentUserService,
  ) {
    this.$q = $q;
    this.loanScenarioService = loanScenarioService;
    this.contactService = contactService;
    this.corporateService = corporateService;
    this.loanAppSharedData = loanAppSharedData;
    this.loanSubmissionUIService = loanSubmissionUIService;
    this.currentUserService = currentUserService;
  }

  getDefaultEmail(loanAppId, familyId, refreshApplicants) {
    const deferred = this.$q.defer();
    if (loanAppId) {
      this.loanAppSharedData
        .getLoanAppApplicants(loanAppId, refreshApplicants)
        .then((response) => {
          if (!response.data || !response.data.InvolvedPartyPerson) {
            return deferred.reject({
              data: 'No involved party persons found.',
            });
          }

          const applicants = response.data.InvolvedPartyPerson;
          const loanScenarioDetails = this.loanAppSharedData
            .LoanApplicationDetails;
          this.getSubmitterInfo(familyId).then(() => {
            const isSubmitterValid =
              this.submitter &&
              this.submitter.MergeFields &&
              this.submitter.MergeFields.length;
            if (!isSubmitterValid) return;
            return this.initDefaultEmail(
              applicants,
              loanScenarioDetails,
              familyId,
              deferred,
            );
          });
        });
    } else {
      deferred.reject({ data: 'Invalid arguments.' });
    }
    return deferred.promise;
  }

  getSubmitterInfo(familyId) {
    const loanId = _.get(
      this.loanAppSharedData,
      'LoanApplicationDetails.LoanId',
    );
    if (!familyId || !loanId) return;
    const deferred = this.$q.defer();
    this.corporateService
      .MergeFieldsGet(familyId, 0, loanId)
      .then((response) => {
        if (!response || !response.data) return;
        this.submitter = response.data.find((obj) => obj.Category === 'Other');
        return deferred.resolve(this.submitter);
      });
    return deferred.promise;
  }

  getClientEmail(familyId, loanId, lenderSubmission = {}) {
    const clientEmail = new LoanSubmissionClientEmailContent(
      familyId,
      loanId,
      lenderSubmission,
      this.corporateService,
      this.currentUserService,
    );
    return clientEmail;
  }

  initDefaultEmail(applicants, loanScenarioDetails, familyId, promise) {
    this.initEmailSubject(applicants, familyId).then((defaultSubject) => {
      const defaultEmail = {};
      defaultEmail.subject = defaultSubject;
      this.initEmailBody(loanScenarioDetails, applicants).then((content) => {
        defaultEmail.content = content;
        return promise.resolve(defaultEmail);
      });
    });
    return promise;
  }

  initEmailSubject(applicants, familyId) {
    const deferred = this.$q.defer();
    if (applicants && familyId) {
      if (!this.submitter) {
        this.getSubmitterInfo(familyId).then(() => {
          const customEmailSubject = this.getEmailSubject(applicants);
          deferred.resolve(customEmailSubject);
        });
      } else {
        const customEmailSubject = this.getEmailSubject(applicants);
        deferred.resolve(customEmailSubject);
      }
    } else {
      deferred.resolve('');
    }
    return deferred.promise;
  }

  getEmailSubject(applicants) {
    const applicantsName = this.getApplicantsLastFirstName(applicants);
    let customEmailSubject = `Loan application for ${applicantsName}`;
    const submitterName = this.getMergeFieldsValue(
      this.submitter.MergeFields,
      'Adviser Name',
    );
    if (submitterName) {
      customEmailSubject = `${customEmailSubject} from ${submitterName}`;
    }

    const submitterOrg = this.getMergeFieldsValue(
      this.submitter.MergeFields,
      'Business Name',
    );
    if (submitterOrg) {
      customEmailSubject = `${customEmailSubject} of ${submitterOrg}`;
    }

    const lvrText = this.getLVRText();
    if (lvrText) {
      customEmailSubject = `${customEmailSubject} with ${lvrText}`;
    }

    return customEmailSubject;
  }

  getLVRText() {
    const invalidLVR =
      !this.loanAppSharedData.fundingSummary ||
      !this.loanAppSharedData.fundingSummary.lvr;
    if (invalidLVR) return;
    const lvr = this.loanAppSharedData.fundingSummary.lvr;
    if (lvr > 80) {
      return '> 80% LVR';
    } else if (lvr < 80) {
      return '< 80% LVR';
    } else {
      return '80% LVR';
    }
  }

  initEmailBody(loanScenarioDetails, applicants) {
    const deferred = this.$q.defer();
    if (loanScenarioDetails && loanScenarioDetails.LoanId) {
      this.contactService
        .loanDetailsGet(loanScenarioDetails.LoanId)
        .then((response) => {
          if (response.data && response.data.loan) {
            const loanDetails = response.data.loan;
            let customEmailContent = this.initializeEmailOpening(applicants);
            customEmailContent = `${customEmailContent}${this.initializeEmailMessage()}`;
            customEmailContent = `${customEmailContent}${this.initializeEmailFooter()}`;
            deferred.resolve(customEmailContent);
          } else {
            deferred.resolve('');
          }
        });
    } else {
      deferred.resolve('');
    }
    return deferred.promise;
  }

  initializeEmailOpening(applicants) {
    let opening =
      '<p><strong>IMPORTANT - Please do not reply to this email as this address is unmonitored. Please contact the sender of this email using their email address as listed below.</strong></p>';

    const applicantsName = this.getApplicantsFirstLastName(applicants);
    opening = `${opening}<p>New Loan Application for ${applicantsName}</p>`;

    return opening;
  }

  initializeEmailMessage() {
    return this.loanSubmissionUIService.getEmailMessage();
  }

  initializeEmailFooter() {
    if (!this.submitter) return '';
    const submitter = this.submitter.MergeFields;
    const submitterName = this.getMergeFieldsValue(submitter, 'Adviser Name');
    let customEmailContent = `<p>Submitted by: <br/> ${submitterName || ''}`;

    const submitterOrg = this.getMergeFieldsValue(submitter, 'Business Name');
    if (submitterOrg) {
      customEmailContent = `${customEmailContent}<br/>${submitterOrg}`;
    }

    const submitterPhoneNumber = this.getMergeFieldsValue(
      submitter,
      'Adviser Mobile Phone number',
    );
    if (submitterPhoneNumber) {
      customEmailContent = `${customEmailContent}<br/>${submitterPhoneNumber}`;
    }

    const submitterEmailAddr = this.getMergeFieldsValue(
      submitter,
      'Adviser Email address',
    );
    if (submitterEmailAddr) {
      customEmailContent = `${customEmailContent}<br/> ${submitterEmailAddr}`;
    }
    customEmailContent = `${customEmailContent}</p>`;

    customEmailContent = `${customEmailContent}<hr/><small>This email may contain information which is confidential and/or subject to legal privilege. If you are not the intended recipient, please immediately notify the sender and delete the email.</small><hr/>`;

    return customEmailContent;
  }

  getApplicantsFirstLastName(applicants) {
    let applicantsName = '';
    if (applicants) {
      _.forOwn(applicants, (applicant) => {
        _.each(applicant, (person) => {
          if (person.IsApplicant) {
            applicantsName = `${applicantsName}${person.FirstName} ${person.LastName} & `;
          }
        });
      });

      applicantsName = applicantsName.slice(0, -2).trim();
    }
    return applicantsName;
  }

  getApplicantsLastFirstName(applicants) {
    if (!applicants) return '';
    let applicantsName = '';
    _.forOwn(applicants, (value) => {
      if (_.isArray(value)) {
        const familyFullName = value.reduce((accu, person) => {
          return `${accu}${person.LastName} (${person.FirstName}) & `;
        }, '');
        applicantsName = `${applicantsName}${familyFullName}`;
      } else if (value.FamilyFullName) {
        applicantsName = `${applicantsName}${value.FamilyFullName} & `;
      }
    });
    const applicantsNameSlice = applicantsName.slice(0, -2);
    if (applicantsNameSlice) {
      applicantsName = applicantsNameSlice.trim();
    }

    return applicantsName;
  }

  getMergeFieldsValue(mergeFields, name) {
    const field = _.find(mergeFields, (field) => {
      return field.Name === name;
    });
    return field ? field.Value : null;
  }

  isValidLenderSubmissionObj(LenderSubmissionObj) {
    return (
      LenderSubmissionObj &&
      LenderSubmissionObj.loanAppId &&
      LenderSubmissionObj.familyId &&
      this.isValidLenderQuestion(LenderSubmissionObj.emailSubjectQuestion) &&
      this.isValidLenderQuestion(LenderSubmissionObj.emailContentQuestion)
    );
  }

  isValidLenderQuestion(question) {
    return question && question.QuestionId && question.QuestionDisplayTypeId;
  }
}

export const loadModule = () =>
  angular
    .module('app')
    .factory(
      'loanSubmissionEmailContentService',
      function loanSubmissionEmailContentService(
        $q,
        loanScenarioService,
        contactService,
        corporateService,
        loanAppSharedData,
        loanSubmissionUIService,
        currentUserService,
      ) {
        return new LoanSubmissionEmailContentService(
          $q,
          loanScenarioService,
          contactService,
          corporateService,
          loanAppSharedData,
          loanSubmissionUIService,
          currentUserService,
        );
      },
    );
