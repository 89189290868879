import template from './addressModal.html';
import AddressModalCtrl from './addressModalCtrl';

export default {
  template,
  controller: AddressModalCtrl,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '=',
    isOrgAddress: '<',
    contactId: '<',
    tempAddress: '=',
    defaultManual: '<',
    addressTypeToShow: '<',
  },
};
