import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import { notesBuilderForUI } from 'Common/mappers/loanOpportunity';
import { SMS_AND_EMAIL_PATTERN } from 'Common/constants/pattern';
import { AGREEMENT_TYPE } from 'Common/constants/agreementType';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { initializeOpportunityLabel } from 'Common/utilities/addCalculationUtility';
import { updateArrayFieldValue } from 'Common/utilities/array';
import {
  initializeNotesDetails,
  reloadOnRemove,
} from './util/notesDetailsCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('NotesDetailsCtrl', function NotesDetailsCtrl(
      $scope,
      $uibModal,
      $state,
      $stateParams,
      contactService,
      $filter,
      SweetAlert,
      utilitiesService,
      corporateService,
      toaster,
      loanOpportunityService,
      currentUserService,
      rayWhiteService,
      configService,
      noteService,
      leadOpportunitiesService,
      contactsNotesService,
      loanScenarioModelService,
      uiService,
    ) {
      $scope.isAssistantView = $state.includes('app.contactsAssistant');
      $scope.notesWidget = false;
      $scope.notesWidgetMenu = false;
      $scope.notesWidgetPanel = false;
      $scope.filterReferrerNotes = 'all';
      $scope.searchNotes = '';
      $scope.brokerBrandingId = $stateParams.adviserOrgId || 0;
      $scope.isClient = true;
      $scope.displayNoteLauncher =
        configService.feature.addNotesAnywhereInContact;
      $scope.isShowLoadMore = false;
      $scope.isNotesLoading = false;
      $scope.familyId = $stateParams.familyId || 0;
      $scope.viewOfAfileTurnedOn = uiService.viewOfAfileTurnedOn;
      $scope.deal = {
        loanId: 0,
      };
      initializeOpportunityLabel({
        $scope,
        leadOpportunitiesService,
        isSingular: true,
      });
      initializeNotesDetails({
        $scope,
        $filter,
        configService,
        contactsNotesService,
      });
      const getClientInfo = (familyId) => {
        if (!familyId) {
          return;
        }
        contactService.clientInformGet(familyId).then((response) => {
          const { data } = response;
          if (!data || !data.length) {
            return;
          }
          $scope.clientName = data[0].FullName;
        });
      };
      const getUserInfo = () => {
        if (!currentUserService) {
          return;
        }
        $scope.userInfo = currentUserService;
        if ($scope.brokerBrandingId || $state.params.clientId !== undefined) {
          $scope.isClient = false;
        }
        if ($scope.isClient) {
          const { familyId } = $state.params;
          getClientInfo(familyId);
        }
      };
      // eslint-disable-next-line unicorn/consistent-function-scoping
      const parseNotesDetails = (notes) => {
        if (!notes) {
          return;
        }
        const notesDetails = notesBuilderForUI(notes);
        const {
          activityDetails,
          activityDetailsHtml,
          isSharedWithReferrer,
        } = notesDetails;
        if (!isSharedWithReferrer) {
          return { activityDetails };
        }
        const regexPattern = new RegExp(SMS_AND_EMAIL_PATTERN);
        const patternMatches =
          activityDetails && activityDetails.match(regexPattern);
        const detailsWithHtml =
          activityDetailsHtml && activityDetailsHtml.match(regexPattern);
        if (!patternMatches || !patternMatches.length) {
          return notes;
        }
        const notesHtmlMatch = (detailsWithHtml || []).map((match) => match);
        const notesMatch = patternMatches.map((match) => match);
        return {
          activityDetails: notesMatch[1] || '',
          activityDetailsHtml: _.get(notesHtmlMatch, '[1]', ''),
          smsBody: notesMatch[2] || '',
          emailBody: notesMatch[3] || '',
        };
      };
      const noteMapper = (note) => {
        const {
          activityDetails,
          activityDetailsHtml,
          smsBody,
          emailBody,
        } = parseNotesDetails(note);
        return {
          ...note,
          initial: utilitiesService.filterInitialOneString(note.ActivityOwner),
          ActivityDetails: activityDetails || note.ActivityDetails,
          smsBody,
          emailBody,
          ActivityDetailsHtml: activityDetailsHtml || note.ActivityDetailsHtml,
        };
      };
      $scope.onModalCloseHandler = (note = {}, isAddNote = true) => {
        if ($scope.displayTabledNotes) {
          $scope.getNotesList();
          return;
        }
        if (isAddNote) {
          $scope.noteListData = [note, ...$scope.noteListData];
        } else {
          $scope.noteListData = $scope.noteListData.map((noteMap) =>
            noteMap.CommunicationID === note.CommunicationID ? note : noteMap,
          );
        }

        $scope.noteListData = $scope.noteListData.map(noteMapper);
      };
      $scope.actionButtonVisible = (ownerId) => {
        if ($scope.isClient) {
          return true;
        }
        if (!$scope.userInfo && !$scope.userInfo.familyId) {
          return true;
        }
        return parseInt($scope.userInfo.familyId, 10) === parseInt(ownerId, 10);
      };

      $scope.showWidget = function (notesWidgetPanel) {
        $scope.notesWidgetPanel = !notesWidgetPanel;
        if ($scope.notesWidgetPanel === true) {
          $('#contacts-sidebar').addClass('overlap');
        } else {
          $('#contacts-sidebar').removeClass('overlap');
        }
      };
      $scope.filterNotes = () => {
        $scope.currentPage = 1;
        $scope.getNotesList();
      };
      // Notes [corporate] - moved from contactDetailsCtrl
      $scope.getNotesList = function () {
        // Set family ID default to 1 if Adviser Org Contact is opened
        const tempFamilyId =
          typeof $scope.familyId !== 'undefined' && $scope.familyId > 0
            ? $scope.familyId
            : 1;
        contactsNotesService.getDisplayCount().then(({ data }) => {
          $scope.defaultNotesCount = data || $scope.defaultNotesCount;
          const args = {
            familyID: tempFamilyId,
            count: $scope.defaultNotesCount,
            brokerbrandingId: $scope.brokerBrandingId,
            pageNumber: $scope.currentPage,
            isReferrer: $scope.filterReferrerNotes === 'referrer',
            loanId: $scope.deal.loanId || 0,
          };
          contactsNotesService.notesList(args).then((listResponse) => {
            if (!listResponse.data) {
              return;
            }
            $scope.noteListData = listResponse.data.map(noteMapper);
            const [noteItem] = $scope.noteListData;
            $scope.noteListCount = _.get(noteItem, 'TotalNumberOfNotes', 0);
            $scope.noteListCountRaw = $scope.noteListCount;
            $scope.notesRaw = [...$scope.noteListData];
            const isEqualNoteListAndCount =
              $scope.noteListData.length === $scope.noteListCount;
            $scope.isShowLoadMore =
              $scope.noteListData.length >= $scope.defaultNotesCount &&
              !isEqualNoteListAndCount;
            $scope.$watch($scope.noteListData, () => {
              $scope.notesContainer = $scope.noteListData;
            });
          });
        });
      };
      $scope.getNotesList();
      noteService.action.refreshNoteList = $scope.getNotesList;
      $scope.loadMoreHandler = () => {
        $scope.isNotesLoading = true;
        if (!$scope.displayTabledNotes) {
          $scope.currentPage += 1;
        }
        const loadMoreArgs = {
          familyID: $scope.familyId,
          count: $scope.defaultNotesCount,
          brokerbrandingId: $scope.brokerBrandingId,
          pageNumber: $scope.currentPage,
          isReferrer: $scope.filterReferrerNotes === 'referrer',
        };
        contactsNotesService.notesList(loadMoreArgs).then((response) => {
          $scope.isNotesLoading = false;
          if (!response.data || !response.data.length) {
            $scope.isShowLoadMore = false;
            return;
          }
          if (response.data.length < $scope.defaultNotesCount) {
            $scope.isShowLoadMore = false;
          }
          if ($scope.displayTabledNotes) {
            $scope.noteListData = response.data.map(noteMapper);
            $scope.notesRaw = [...$scope.noteListData];
            return;
          }
          $scope.noteListData = [
            ...$scope.noteListData,
            ...response.data.map(noteMapper),
          ];
        });
      };

      $scope.editNote = function (action, note) {
        let familyId = $scope.familyId;
        let noteObj = {};
        const isOpportunity =
          note.IsOpportunity ||
          (typeof note.IsOpportunity !== 'undefined' && !note.IsOpportunity);

        if ($stateParams.adviserOrgId) {
          familyId = 0;
        }
        contactsNotesService
          .noteGet(
            familyId,
            note.CommunicationID,
            $scope.brokerBrandingId,
            isOpportunity,
          )
          .then((response) => {
            const { data } = response;
            if (data && !data.length) {
              return;
            }

            noteObj = data[0];
            noteObj.IsOpportunity = isOpportunity;
            if (noteObj) {
              $scope.openCreateNoteModal(action, noteObj);
            } else {
              toaster.pop('error', 'Error retrieving note info.');
            }
          });
      };

      $scope.deleteNote = function (note) {
        if (!angular.isUndefined(note.CommunicationID)) {
          SweetAlert.swal(
            {
              title: 'Are you sure?',
              text: 'This record will be removed from your notes',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#F68F8F',
              confirmButtonText: 'Yes, remove it!',
              closeOnConfirm: false,
            },
            (confirm) => {
              if (confirm) {
                let service;
                let method;
                if (typeof note.IsOpportunity === 'undefined') {
                  service = contactsNotesService;
                  method = 'noteDelete';
                } else {
                  service = loanOpportunityService;
                  method = 'deleteLoanOpportunityNote';
                }
                service[method](note.CommunicationID).then(() => {
                  _.remove($scope.noteListData, (o) => {
                    return _.isEqual(o, note);
                  });

                  SweetAlert.swal({
                    title: 'Success',
                    text: 'Note has been been successfully deleted.',
                    type: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                  });
                  reloadOnRemove($scope);
                });
              }
            },
          );
        } else {
          _.remove($scope.noteListData, (o) => {
            return _.isEqual(o, note);
          });
          reloadOnRemove($scope);
        }
      };

      const getReferrerDetails = (referrerId, agreementTypeId) => {
        if (!referrerId) {
          return;
        }
        if (agreementTypeId === AGREEMENT_TYPE.RAY_WHITE) {
          return rayWhiteService.getMembers(referrerId);
        }
        return corporateService
          .corporateContactGet(0, referrerId)
          .then((response) => {
            if (!response.data) {
              return false;
            }
            return response.data;
          });
      };

      const getClientDetails = (familyID) => {
        return contactService
          .contactFamilyInfoGet(familyID)
          .then((response) => {
            if (!response.data) {
              return false;
            }
            const referrerId = response.data.ReferrerID || 0;
            const agreemetyTypeId = response.data.ReferrerAgreementTypeId;
            $scope.adviserFullName = response.data.AdviserFullName || '';
            return getReferrerDetails(referrerId, agreemetyTypeId);
          });
      };

      const openNoteModal = (props) => {
        const modalSize = configService.feature.noteType ? 'wide' : '';
        const modalInstance = $uibModal.open({
          template: `<opportunity-notes-modal
          modal-instance="vm.modalInstance"
          note-obj="vm.props.noteObj"
          is-add="vm.props.isAdd"
          referral-id="vm.props.referralId"
          is-sms-enabled="vm.props.isSmsEnabled"
          is-not-opportunity-module="true">
        </opportunity-notes-modal>`,
          controller: 'CommonModalPlaceholderCtrl',
          controllerAs: 'vm',
          backdrop: 'static',
          windowClass: `opportunity-notes-modal ${modalSize}`,
          size: modalSize,
          resolve: {
            props: () => props,
          },
        });
        modalInstance.result.then((note) => {
          $scope.onModalCloseHandler(note, false);
        });
      };

      const emailDefaultObject = (referrerInfo, props) => {
        const emailSubj = `New Note Created for ${referrerInfo.FirstName} ${referrerInfo.LastName}`;
        return {
          subject: emailSubj,
          body: props.emailBody || '',
          replyTo: {
            EmailAddress: $scope.userInfo.email,
          },
          sentDate: new Date(),
          recipients: [
            {
              EmailAddress: referrerInfo.Email,
            },
          ],
        };
      };

      // eslint-disable-next-line unicorn/consistent-function-scoping
      const emailData = (referrerInfo) => {
        const emailSubj = `New Note Created for ${referrerInfo.FirstName} ${referrerInfo.LastName}`;
        return {
          recipients: [
            {
              EmailAddress: referrerInfo.Email,
            },
          ],
          subject: emailSubj,
          referrerEmail: referrerInfo.Email,
        };
      };

      const setUpEmailAndSmsObj = (notesData, referrerInfo, props) => {
        const smsObj = {
          mobile: referrerInfo.Mobile,
          body: props.smsBody || '',
        };
        const emailObjDefault = emailDefaultObject(referrerInfo, props);
        const emailObj = {
          ...emailObjDefault,
          ...emailData(referrerInfo),
        };
        const isSmsEnabled = $scope.userInfo.sMSEnabled || false;
        const referralId = referrerInfo.FamilyId || 0;
        const referrerShareEmail = !!props.emailBody;
        const referrerShareSms = !!props.smsBody;
        const noteObj = {
          ...notesData,
          emailObj,
          smsObj,
          referrerShareEmail,
          referrerShareSms,
          clientName: $scope.clientName || '',
          brokerName: $scope.adviserFullName || '',
        };
        const propsTmp = {
          isSmsEnabled,
          referralId,
          noteObj,
        };
        return {
          ...props,
          ...propsTmp,
        };
      };

      $scope.onCloseNoteTypeModal = ({ updatedNoteTypes }) => {
        const args = {
          list: $scope.noteListData,
          lookup: updatedNoteTypes,
          listId: 'NoteTypeID',
          listField: 'NoteTypeDesc',
          lookupId: 'id',
          lookupField: 'type',
        };
        $scope.noteListData = updateArrayFieldValue(args);
      };

      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.openCreateNoteModal = function (action, noteObjData = {}) {
        const familyId = $state.params.familyId || 0;
        const brokerBrandingId = $state.params.adviserOrgId || 0;
        const notes = {
          ...noteObjData,
          FamilyID: familyId,
          BrokerBrandingID: brokerBrandingId,
        };
        const notesData = notesBuilderForUI(notes);
        const props = {
          isAdd: action === 'add',
          noteObj: notesData,
          referralId: 0,
          isSmsEnabled: 0,
          emailBody: noteObjData.emailBody || '',
          smsBody: noteObjData.smsBody || '',
        };
        if ($scope.isClient && familyId > 0) {
          getClientDetails(familyId).then((referrerInfo) => {
            if (referrerInfo) {
              const propsData = setUpEmailAndSmsObj(
                notesData,
                referrerInfo,
                props,
              );
              if ($scope.userInfo.accessType === ACCESS_TYPE.CORPORATE) {
                openNoteModal(propsData);
              } else {
                contactService
                  .getContactAssignedAdviser($scope.familyId)
                  .then((assignedAdviser) => {
                    if (!assignedAdviser.data) {
                      return;
                    }
                    corporateService
                      .familyBrokerSupportGet(assignedAdviser.data)
                      .then((adviser) => {
                        if (!adviser.data) {
                          return;
                        }
                        const prop = {
                          ...propsData,
                          isSmsEnabled: adviser.data.SMSEnabled,
                        };
                        openNoteModal(prop);
                      });
                  });
              }
            } else {
              openNoteModal(props);
            }
          });
        } else {
          openNoteModal(props);
        }
      };
      getUserInfo();

      $scope.$on('REFRESH_NOTES_LIST', () => {
        $scope.getNotesList();
      });
      const getDeal = () => {
        loanScenarioModelService
          .getSimplifiedListAllStatus($scope.familyId)
          .then((data = []) => {
            const emptyOption = {
              loanApplicationId: 0,
              loanId: 0,
              loanScenarioTitle: 'All deals',
            };
            $scope.deals = [emptyOption, ...data];
          });
      };
      getDeal();
    });
