import angular from 'angular';
import { INSURANCE_QUOTE_REPORT_SECTION } from 'Common/constants/insuranceOptions';
import { toastSuccess, displayWarning } from 'Common/utilities/alert';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .directive('pdfDownload', function pdfDownload(
      $window,
      $timeout,
      insuranceQuoteService,
      insuranceReportService,
    ) {
      return {
        restrict: 'A',
        scope: {
          insurerProviderId: '=',
          reportSettings: '<',
          quoteData: '<',
          onPdfDownloaded: '&',
        },
        // eslint-disable-next-line sonarjs/cognitive-complexity
        link: (scope, element) => {
          const generateReportBtn = angular.element('#quoteGenerateRerportBtn');
          const generateReportLoader = angular.element(
            '#quoteGenerateRerportLoader',
          );
          const generateReport = () => {
            insuranceQuoteService
              .insuranceInsurerReportGet(
                scope.reportSettings.quoteId,
                scope.reportSettings.ReportOptions,
              )
              .then((response) => {
                const data = response.data;
                data.Name = 'Insurer Report';

                const a = $window.document.createElement('a');
                a.setAttribute(
                  `href`,
                  `data:${data.ContentType};base64,${data.DocumentContent}`,
                );
                a.setAttribute(`download`, data.Name);

                a.style.display = `none`;
                $window.document.body.append(a);

                const clickDelay = $timeout(() => {
                  a.click();
                });
                scope.$on('destroy', () => {
                  $timeout.cancel(clickDelay);
                });

                a.remove();
                generateReportBtn.removeClass('hidden');
                generateReportLoader.addClass('hidden');

                if (scope.reportSettings.ReportOptions.SaveClientDocument) {
                  toastSuccess('Document successfully saved to client.');
                }

                if (typeof scope.onPdfDownloaded === 'function') {
                  scope.onPdfDownloaded({ pdf: data });
                }
              });
          };

          const clickListener = () => {
            const processTimeOut = $timeout(() => {
              const { ReportOptions: reportOptions } = scope.reportSettings;
              const isInsurerRequired = reportOptions.Sections.includes(
                INSURANCE_QUOTE_REPORT_SECTION.INSURER_RECOMMENDATION,
              );
              const isValidInsurer =
                !isInsurerRequired || !!reportOptions.ProviderID;

              if (!isValidInsurer) {
                displayWarning('Please select an Insurer.', 'Required!');
                return;
              }

              generateReportBtn.addClass('hidden');
              generateReportLoader.removeClass('hidden');

              const {
                FamilyID: familyId,
                SaveClientDocument: saveToDocs,
              } = reportOptions;
              if (!familyId && saveToDocs) {
                insuranceReportService
                  .confirmCreateClientFromQuote(scope.quoteData)
                  .result.then(
                    (response) => {
                      if (!response || !response.familyId) {
                        return;
                      }
                      scope.reportSettings.ReportOptions = {
                        ...reportOptions,
                        FamilyID: response.familyId,
                      };
                      generateReport();
                    },
                    () => {
                      generateReportBtn.removeClass('hidden');
                      generateReportLoader.addClass('hidden');
                    },
                  );
              } else {
                generateReport();
              }
            });
            scope.$on('destroy', () => {
              $timeout.cancel(processTimeOut);
            });
          };

          element.on('click', clickListener);
          scope.$on('destroy', () => {
            element.off('click', clickListener);
          });
        },
      };
    });
