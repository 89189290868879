import angular from 'angular';
import HttpClient from './httpClient';
import EmploymentService from './employmentService';
import EmploymentModalService from './employmentModalService';
import TemplateService from './templateService';
import EnquirySourceService from './enquirySourceService';
import ReferencedReferralService from './referencedReferralService';
import ContactStatusService from './contactStatusService';
import ClientInfoService from './clientInfoService';
import UsersProfileService from './usersProfileService';
import UsersSubscriptionService from './usersSubscriptionService';
import ReferralAgreementService from './referralAgreementService';
import PersonService from './personService';
import AccountService from './accountService';
import SearchPreferenceSetting from './searchPreferenceSetting';
import LoadAllocationModalService from './loadAllocationModalService';
import TaskDetailService from './taskDetailService';
import LoanProfilerDetailService from './loanProfilerDetailService';
import FundingCalculatorDetailService from './fundingCalculatorDetailService';
import ReferralDetailService from './referralDetailService';
import PipelineCardsService from './pipelineCardsService';
import DocumentService from './documentService';
import LoanService from './loanService';
import LoanAppPaymentAbilityService from './loanAppPaymentAbilityService';
import LoanApplicantAssetInfoService from './loanApplicantAssetInfoService';
import LoanAppDetailsService from './loanAppDetailsService';
import WatcherService from './watcherService';
import TimeoutService from './timeoutService';
import PartyTypeService from './partyTypeService';
import GenderService from './genderService';
import FormattingService from './formattingService';
import LenderRatesModalService from './lenderRatesModalService';
import contactSharedDataService from './contactSharedDataService';
import AddressHistoryService from './addressHistoryService';
import EventService from './eventService';
import CorporateWidgetDefaultService from './corporateWidgetDefaultService';
import PassportService from './passportService';
import IdentificationService from './identificationService';
import CreditCheckSubscriptionService from './creditCheckSubscriptionService';
import CustomerCareTabsService from './customerCareTabsService';
import companyTrustSharedDataService from './companyTrustSharedDataService';
import OverviewDashboardDetailService from './overviewDashboardDetailService';
import AddressModalService from './addressModalService';
import CreditCardService from './creditCardService';
import formErrorHandlerServices from './formErrorHandlerServices';
import FinanceService from './financeService';
import SubscriptionCheckIsShowPreLoader from './subscriptionCheckIsShowPreLoader';
import DashboardCategoryService from './dashboardCategoryService';
import CustomerCareTemplateService from './customerCareTemplateService';
import LimitClients from './insuranceService';
import CorporatePermissionService from './corporatePermissionService';
import LenderRatesService from './lenderRatesService';
import OutstandingRequirementService from './outstandingRequirementService';
import LoanCalculatorLinking from './loanCalculatorLinking';
import BouncingTooltipService from './bouncingTooltipService';
import CrmConfirmation from './crmConfirmation';
import OpportunityNewModalService from './opportunityNewModalService';
import InputService from './inputService';
import PipelineStatusService from './pipelineStatusService';
import InsuranceReportService from './insuranceReportService';
import CorporateTeamService from './corporateTeamService';
import OnlineFactFindModal from './onlineFactFindModal';
import CurrentUserService from './currentUserService';
import ProviderUploadDocumentsService from './providerUploadDocsService';
import GeneralSearchService from './generalSearchService';
import InsuranceModalApplicationService from './insuranceModalApplicationService';
import ObjectivesQuestionService from './objectivesQuestionService';
import GoogleMapService from './googleMapService';
import JasperReportService from './jasperReportService';
import StateService from './stateService';
import CardRedirections from './cardRedirectionsService';
import InsuranceSummaryOfCoverService from './insuranceSummaryOfCoverService';
import HelloBookPreviewService from './helloBookPreviewService';
import ActionValidatorService from './actionValidatorService';
import OnlineFactFindService from './onlineFactFindService';
import CommonWidgetService from './commonWidgetService';
import InsuranceFGModalService from './insuranceFGModalService';
import CreateNewLoanAppService from './createNewLoanAppService';
import OpportunityCalculationService from './opportunityCalculationService';
import SmsService from './smsService';
import LoanSubmissionUIService from './loanSubmissionUIService';
import BlueStoneSubmissionService from './blueStoneSubmissionService';
import GmailSyncModalService from './gmailSyncModalService';
import GamePlanGeneratorService from './gamePlanGeneratorService';
import GamePlanSharedDataService from './gamePlanSharedDataService';
import ModalGoogleAnalyticsService from './modalGoogleAnalyticsService';
import ElementService from './elementService';
import NoteService from './noteService';
import MycrmLookupService from './mycrmLookupService';
import CountryCodeService from './countryCodeService';
import AddressLookupService from './addressLookupService';
import LeadOpportunitiesService from './leadOpportunitiesService';
import LoanFinancialsService from './loanFinancialsService';
import CommissionHelperService from './commissionHelperService';
import LoanFileStatusService from './loanFileStatusService';
import LoanStatusConversionService from './loanStatusConversionService';
import ViewOfFileModalService from './viewOfFileModalService';
import HelloPackService from './helloPackService';
import CreditCheckService from './creditCheckService';
import HandoverPackService from './handoverPackService';
import HttpService from './httpService';
import OrgPipelineStatusService from './orgPipelineStatusService';
import AutomationRuleService from './automationRuleService';

export default angular
  .module('common.services', [])
  .service('httpClient', HttpClient)
  .service('employmentService', EmploymentService)
  .service('employmentModalService', EmploymentModalService)
  .service('templateService', TemplateService)
  .service('enquirySourceService', EnquirySourceService)
  .service('referencedReferralService', ReferencedReferralService)
  .service('contactStatusService', ContactStatusService)
  .service('clientInfoService', ClientInfoService)
  .service('usersProfileService', UsersProfileService)
  .service('usersSubscriptionService', UsersSubscriptionService)
  .service('referralAgreementService', ReferralAgreementService)
  .service('personService', PersonService)
  .service('accountService', AccountService)
  .service('searchPreferenceSetting', SearchPreferenceSetting)
  .service('loadAllocationModalService', LoadAllocationModalService)
  .service('taskDetailService', TaskDetailService)
  .service('loanProfilerDetailService', LoanProfilerDetailService)
  .service('fundingCalculatorDetailService', FundingCalculatorDetailService)
  .service('referralDetailService', ReferralDetailService)
  .service('pipelineCardsService', PipelineCardsService)
  .service('documentService', DocumentService)
  .service('loanService', LoanService)
  .service('loanAppPaymentAbilityService', LoanAppPaymentAbilityService)
  .service('loanApplicantAssetInfoService', LoanApplicantAssetInfoService)
  .service('loanAppDetailsService', LoanAppDetailsService)
  .service('watcherService', WatcherService)
  .service('timeoutService', TimeoutService)
  .service('partyTypeService', PartyTypeService)
  .service('genderService', GenderService)
  .service('formattingService', FormattingService)
  .service('lenderRatesModalService', LenderRatesModalService)
  .service('contactSharedDataService', contactSharedDataService)
  .service('addressHistoryService', AddressHistoryService)
  .service('eventService', EventService)
  .service('corporateWidgetDefaultService', CorporateWidgetDefaultService)
  .service('passportService', PassportService)
  .service('identificationService', IdentificationService)
  .service('creditCheckSubscriptionService', CreditCheckSubscriptionService)
  .service('customerCareTabsService', CustomerCareTabsService)
  .service('companyTrustSharedDataService', companyTrustSharedDataService)
  .service('overviewDashboardDetailService', OverviewDashboardDetailService)
  .service('addressModalService', AddressModalService)
  .service('CreditCardService', CreditCardService)
  .service('formErrorHandlerServices', formErrorHandlerServices)
  .service('financeService', FinanceService)
  .service('subscriptionCheckIsShowPreLoader', SubscriptionCheckIsShowPreLoader)
  .service('dashboardCategoryService', DashboardCategoryService)
  .service('customerCareTemplateService', CustomerCareTemplateService)
  .service('LimitClients', LimitClients)
  .service('corporatePermissionService', CorporatePermissionService)
  .service('lenderRatesService', LenderRatesService)
  .service('outstandingRequirementService', OutstandingRequirementService)
  .service('loanCalculatorLinking', LoanCalculatorLinking)
  .service('bouncingTooltipService', BouncingTooltipService)
  .service('crmConfirmation', CrmConfirmation)
  .service('opportunityNewModalService', OpportunityNewModalService)
  .service('inputService', InputService)
  .service('pipelineStatusService', PipelineStatusService)
  .service('insuranceReportService', InsuranceReportService)
  .service('corporateTeamService', CorporateTeamService)
  .service('onlineFactFindModal', OnlineFactFindModal)
  .service('currentUserService', CurrentUserService)
  .service('providerUploadDocsService', ProviderUploadDocumentsService)
  .service('generalSearchService', GeneralSearchService)
  .service('insuranceModalApplicationService', InsuranceModalApplicationService)
  .service('objectivesQuestionService', ObjectivesQuestionService)
  .service('googleMapService', GoogleMapService)
  .service('jasperReportService', JasperReportService)
  .service('stateService', StateService)
  .service('cardRedirectionsService', CardRedirections)
  .service('insuranceSummaryOfCoverService', InsuranceSummaryOfCoverService)
  .service('actionValidatorService', ActionValidatorService)
  .service('helloBookPreviewService', HelloBookPreviewService)
  .service('onlineFactFindService', OnlineFactFindService)
  .service('commonWidgetService', CommonWidgetService)
  .service('createNewLoanAppService', CreateNewLoanAppService)
  .service('opportunityCalculationService', OpportunityCalculationService)
  .service('insuranceFGModalService', InsuranceFGModalService)
  .service('smsService', SmsService)
  .service('loanSubmissionUIService', LoanSubmissionUIService)
  .service('blueStoneSubmissionService', BlueStoneSubmissionService)
  .service('gmailSyncModalService', GmailSyncModalService)
  .service('gamePlanGeneratorService', GamePlanGeneratorService)
  .service('gamePlanSharedDataService', GamePlanSharedDataService)
  .service('elementService', ElementService)
  .service('noteService', NoteService)
  .service('mycrmLookupService', MycrmLookupService)
  .service('countryCodeService', CountryCodeService)
  .service('addressLookupService', AddressLookupService)
  .service('leadOpportunitiesService', LeadOpportunitiesService)
  .service('loanFinancialsService', LoanFinancialsService)
  .service('commissionHelperService', CommissionHelperService)
  .service('loanFileStatusService', LoanFileStatusService)
  .service('loanStatusConversionService', LoanStatusConversionService)
  .service('modalGoogleAnalyticsService', ModalGoogleAnalyticsService)
  .service('viewOfFileModalService', ViewOfFileModalService)
  .service('creditCheckService', CreditCheckService)
  .service('handoverPackService', HandoverPackService)
  .service('httpService', HttpService)
  .service('helloPackService', HelloPackService)
  .service('automationRuleService', AutomationRuleService)
  .service('orgPipelineStatusService', OrgPipelineStatusService).name;
