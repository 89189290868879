export const downloadGmailAttachment = ({
  contactModelService,
  downloadDataService,
  gmailEmail,
  familyId,
}) => (attachmentId) => {
  const params = {
    gmailId: gmailEmail.GmailId || 0,
    attachmentId,
    familyId,
  };
  return contactModelService.downloadGmailAttachment(params).then((data) => {
    if (!data) {
      return;
    }
    const { contentType, documentContent, name } = data;
    downloadDataService.download(
      `data:${contentType};base64,${documentContent}`,
      name,
      contentType,
    );
  });
};
