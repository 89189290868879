class FormattingService {
  constructor(toaster) {
    'ngInject';

    this.toaster = toaster;
  }

  calculatorFormatCurrency(amount) {
    this.amountFormatted = '';
    if (amount && amount !== '-' && !amount.match(/[a-z]/i)) {
      const parsedAmount = parseFloat(amount.replace(/,/g, ''));
      this.amountFormatted = parsedAmount
        .toFixed(0)
        .replace(/./g, (c, i, a) => {
          return i && c !== '.' && (a.length - i) % 3 === 0 ? `,${c}` : c;
        });
    }
    return this.amountFormatted;
  }

  calculatorFormatInterestRate(amount) {
    this.validatedAmount = '';
    if (amount && amount !== '-' && !amount.match(/[a-z]/i)) {
      const parsedAmount = parseFloat(amount);
      if (parsedAmount === 1 || parsedAmount === 1.2) {
        this.validatedAmount = amount;
      } else if (parsedAmount < 1.25 || parsedAmount > 25) {
        this.toaster.pop('warning', 'Interest Rate Valid Range: 1.25% to 25%');
      } else {
        this.validatedAmount = amount;
      }
    }
    return this.validatedAmount;
  }
}

export default FormattingService;
