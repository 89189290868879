export function providerInfoBuilderForUI(providerInfo) {
  return {
    providerId: providerInfo.ProviderID,
    lenderId: providerInfo.LenderID,
    insurerId: providerInfo.InsurerID,
    providerName: providerInfo.ProviderName,
    countryCode: providerInfo.CountryCode,
    description: providerInfo.ProviderDescription,
    providerCode: providerInfo.ProviderCode,
    address: providerInfo.Address,
    website: providerInfo.Website,
    emailAddress: providerInfo.Email,
    faxNumber: providerInfo.Fax,
    phoneNumber: providerInfo.Phone,
  };
}

export function loanRateBuilderForUI(loanRate) {
  return {
    loanTerm: loanRate.LoanTerm,
    loanTermText: loanRate.LoanTermText,
    previousRate: loanRate.PreviousRate,
    currentRate: loanRate.CurrentRate,
    loanRatePrefix: loanRate.LoanRatePrefix,
  };
}

export function providerCategoryForUI(category) {
  return {
    id: category.CategoryID,
    name: category.CategoryName,
    color: category.Color,
    enabled: category.Enabled,
  };
}

export function contactPointForUI(contactPoint) {
  return {
    type: contactPoint.Type,
    value: contactPoint.Value,
  };
}

export function providerContactPointForUI(providerContactPoint) {
  return {
    title: providerContactPoint.Title,
    contacts:
      providerContactPoint.Contacts &&
      providerContactPoint.Contacts.map(contactPointForUI),
  };
}

export function providerContactPointsForUI(providerContactPoints) {
  return {
    lenderId: providerContactPoints.LenderID,
    lenderName: providerContactPoints.LenderName,
    contactPoints:
      providerContactPoints.ContactPoints &&
      providerContactPoints.ContactPoints.map(providerContactPointForUI),
  };
}

export function providerKeyContactsBuilderForUI(contacts) {
  return {
    keyRelationshipId: contacts.KeyRelationshipID,
    name: contacts.Name,
    position: contacts.Position,
    region: contacts.Region,
    phone: contacts.Phone,
    mobile: contacts.Mobile,
    email: contacts.Email,
    bUPhone: contacts.BUPhone,
    bUFax: contacts.BUFax,
  };
}

export function providerStatesBuilderForUI(state) {
  return {
    reportingStateId: state.ReportingStateId,
    reportingStateName: state.ReportingStateName,
  };
}

export function lendersFormBuilderForMycrm(infos) {
  return {
    keyRelationshipID: infos.keyRelationshipId || 0,
    name: infos.name,
    position: infos.position,
    mobile: infos.mobile,
    phone: infos.phone,
    BUPhone: infos.bUPhone,
    BUFax: infos.bUFax,
    email: infos.email,
    providerID: infos.providerId,
    region: infos.region,
  };
}

export function providerSaveDocumentsForAPI(documents) {
  return {
    Title: documents.title,
    FileName: documents.fileName,
    DocumentID: documents.documentId,
    IsMergedProviderToolsSection: documents.iSMergedProviderToolsSection,
    IsFavourite: documents.isFavourite,
    RubikCategoryName: documents.rubikCategoryName,
  };
}

export function providerCategoriesForUI(category) {
  return {
    categoryId: category.CategoryID,
    categoryName: category.CategoryName,
    color: category.Color,
    enabled: category.Enabled,
  };
}

export function providerDocumentCagory(category) {
  return {
    name: category.CategoryName,
  };
}

export function providerDocument(doc) {
  return {
    id: doc.ProviderDocumentID,
    title: doc.Title,
    fileName: doc.FileName,
    key: doc.DocumentKey,
    content: doc.Documents,
    isLoanInfo: doc.IsLoanInfo,
    isInsuranceInfo: doc.IsInsuranceInfo,
    isPrivateDocument: doc.IsPrivateDocument,
    isFavorite: doc.IsFavourite,
    isRubik: doc.IsRubik,
  };
}
