import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import cloneDeep from 'lodash/cloneDeep';
import { MERGE_FIELD_CATEGORY } from 'Common/constants/mergeFieldCategory';
import { QUESTION_ID, QUESTION_ID_NZ } from 'Common/constants/questionId';
import { DISPLAY_OPTIONS } from 'Common/constants/customerObjectives';
import { MERGE_FIELDS } from 'Common/constants/mergeFields';
import { arrayToObject } from 'Common/utilities/array';
import { toastSuccess, toastError } from 'Common/utilities/alert';
import { getQuestion } from 'Common/utilities/objectivesQuestion';
import { splitString } from 'Common/utilities/string';
import { LOAN_APP_SUBMISSION_METHODS } from 'Common/constants/loanAppSubmissionMethods';
import { checkInvalidRecipient } from './loanAppSubmission';

export const EMAIL_MODAL_KEYS = {
  LENDER: 'lender',
  CLIENT: 'client',
};

export const UI_MERGE_FIELDS = {
  APPLICANTS_LF_NAME: 'Applicants Last First Name',
  APPLICANTS_FL_NAME: 'Applicants First Last Name',
  LVR_SUMMARY: 'LVR Summary',
};

const getAnswerStr = (data, key) => get(data, `${key}.answerString[0]`, '');

const mapMergeFieldProps = ($scope, { subject, body }) => {
  return {
    familyId: $scope.familyId,
    loan: {
      LoanID: get(
        $scope.loanAppSharedData,
        'loanAppSummary.LoanDetails.LoanID',
      ),
    },
    templateSubject: subject,
    templateContent: body,
  };
};

const getMapSubjectBody = ({ templateSubject, templateContent }) => ({
  Subject: templateSubject,
  Body: templateContent,
});

export const mapLoanAppEmailTemplate = ({ significantLoanDataHolder }) => {
  const submissionObj = arrayToObject(significantLoanDataHolder, 'QuestionId');
  return {
    forClient: {
      subject: getAnswerStr(submissionObj, QUESTION_ID.APPLICANT_EMAIL_SUBJECT),
      body: getAnswerStr(submissionObj, QUESTION_ID.APPLICANT_EMAIL_CONTENT),
    },
    forLender: {
      subject: getAnswerStr(submissionObj, QUESTION_ID.LENDER_EMAIL_SUBJECT),
      body: getAnswerStr(submissionObj, QUESTION_ID.LENDER_EMAIL_CONTENT),
    },
  };
};

export const renderClientMappedEmail = ($scope, emailContent) => {
  const mergeFieldData = mapMergeFieldProps($scope, emailContent);
  $scope.mailTemplateService
    .translateMailTemplate(mergeFieldData, cloneDeep($scope.loanAppMergeFields))
    .then((data) => {
      $scope.loanSubmissionClientEmail = {
        ...$scope.loanSubmissionClientEmail,
        ...getMapSubjectBody(data),
      };
    });
};

export const renderLenderMappedEmail = ($scope, emailContent) => {
  const mergeFieldData = mapMergeFieldProps($scope, emailContent);
  $scope.mailTemplateService
    .translateMailTemplate(mergeFieldData, cloneDeep($scope.loanAppMergeFields))
    .then((data) => {
      $scope.loanSubmissionLenderEmail.email = {
        ...$scope.loanSubmissionLenderEmail.email,
        ...getMapSubjectBody(data),
      };
    });
};

export const getAdditionalMergeFields = () => {
  return {
    Category: MERGE_FIELD_CATEGORY.LOANS,
    MergeFields: [
      {
        Name: UI_MERGE_FIELDS.APPLICANTS_LF_NAME,
        Description: `Applicant(s) formatted name`,
      },
      {
        Name: UI_MERGE_FIELDS.APPLICANTS_FL_NAME,
        Description: `Applicant(s) formatted name`,
      },
      {
        Name: UI_MERGE_FIELDS.LVR_SUMMARY,
        Description: 'LVR Summary',
      },
    ],
  };
};

export const refreshMergeFieldsForTemplate = ($scope) =>
  $scope.isLenderSubmissionInitialized &&
  $scope.loanAppEmailTemplateNZ &&
  $scope.refreshFieldsAndContent();

export const updateTextUrgency = (originalText, isUrgent) => {
  const prependText = '[URGENT]';
  const removeText = /\[URGENT]/;
  const cleanText = originalText.replace(removeText, '');

  return isUrgent ? `${prependText} ${cleanText}` : cleanText;
};

export const mapAdditionalMergeFields = (
  fields,
  loanSubmissionEmailContentService,
  { loanAppApplicants },
) => {
  const applicants = loanAppApplicants.InvolvedPartyPerson;
  return fields.map((item) => {
    switch (item.Name) {
      case UI_MERGE_FIELDS.APPLICANTS_LF_NAME: {
        return {
          ...item,
          Value: loanSubmissionEmailContentService.getApplicantsLastFirstName(
            applicants,
          ),
        };
      }
      case UI_MERGE_FIELDS.APPLICANTS_FL_NAME: {
        return {
          ...item,
          Value: loanSubmissionEmailContentService.getApplicantsFirstLastName(
            applicants,
          ),
        };
      }
      case UI_MERGE_FIELDS.LVR_SUMMARY: {
        return {
          ...item,
          Value: loanSubmissionEmailContentService.getLVRText(),
        };
      }
      default:
        return item;
    }
  });
};

export const appendAdditionalMergeFields = (
  loanAppSharedData,
  loanSubmissionEmailContentService,
) => (data) => {
  const appendMergeFields = getAdditionalMergeFields();
  return data
    ? data.map((item) => {
        const matched = item.Category === appendMergeFields.Category;
        const fields = mapAdditionalMergeFields(
          appendMergeFields.MergeFields,
          loanSubmissionEmailContentService,
          loanAppSharedData,
        );
        const updatedItem = {
          ...item,
          MergeFields: [...item.MergeFields, ...fields],
        };
        return matched ? updatedItem : item;
      })
    : [];
};

export const mapClientContentRecipient = ($scope) => {
  const applicant = get($scope, 'mergeFieldEmail.applicant', []);
  const tickedClients = applicant.filter((item) => !!item.answer);
  return tickedClients.map((info) => info.Email);
};

export const mapLenderContentRecipient = ($scope) => {
  const {
    CopyToAdviser: copyToAdviser,
    AdviserEmailAddress: adviserEmail,
  } = $scope.LenderSubmissionSet;

  if (!$scope.loanAppEmailTemplateNZ) {
    const shouldInclude = copyToAdviser && adviserEmail;
    return shouldInclude ? [adviserEmail] : [];
  }

  const adviser = get($scope, 'mergeFieldEmail.adviser', []);
  const assistant = get($scope, 'mergeFieldEmail.assistant', []);

  const adviserAndAssistantList = [
    ...adviser.filter((item) => item && item.answer),
    ...assistant.filter((item) => item && item.answer),
  ];

  const adviserAndAssistantEmails = adviserAndAssistantList.map(
    (info) => info.Email,
  );
  return [...adviserAndAssistantEmails];
};

export const getAdditionalRecipients = ($scope) => {
  const emailList = mapLenderContentRecipient($scope);
  return emailList.map((email) => ({
    EmailAddress: email,
  }));
};

export const getOtherRecipients = ($scope) =>
  (get($scope, 'sendToOtherEmailCheckbox.answer') &&
    get($scope, 'otherEmailRecipient.answer')) ||
  null;

export const setupOtherRecipientQuestion = (questions) => (answers) => {
  const { SEND_LOAN_TO_OTHER, OTHER_LOAN_RECIPIENT } = QUESTION_ID_NZ;
  const checkboxQuestion = getQuestion(questions, SEND_LOAN_TO_OTHER);
  const emailQuestion = getQuestion(questions, OTHER_LOAN_RECIPIENT);

  const checkboxAnswer = getQuestion(answers, SEND_LOAN_TO_OTHER);
  const emailAnswer = getQuestion(answers, OTHER_LOAN_RECIPIENT);

  const checkboxObj = {
    ...checkboxQuestion,
    answer: get(checkboxAnswer, 'answerBool[0]'),
  };
  const emailObj = {
    ...emailQuestion,
    answer: get(emailAnswer, 'answerString[0]'),
  };

  return {
    checkboxObj,
    emailObj,
  };
};

export const getMergeFieldsByCategory = (data, category) => {
  const matched = data && data.find((obj) => obj.Category === category);
  return get(matched, 'MergeFields', []);
};

export const canSubmitViaEmail = ($scope) => {
  const isEmailSubmission =
    $scope.LenderSubmissionSet.SubmissionMethod ===
      LOAN_APP_SUBMISSION_METHODS.EMAIL && !$scope.currentUserService.isAU;

  if (isEmailSubmission) {
    const lenderValid =
      $scope.loanAppLenderDetailObject &&
      $scope.loanAppLenderDetailObject.LenderId;
    const validRecipients = !checkInvalidRecipient($scope);
    const lenderEmailValid =
      $scope.loanSubmissionLenderEmail.email.Subject &&
      $scope.loanSubmissionLenderEmail.email.Body;
    const clientEmailValid =
      !$scope.LenderSubmissionSet.CopyToClient ||
      ($scope.loanSubmissionClientEmail.Subject &&
        $scope.loanSubmissionClientEmail.Body);

    return (
      lenderValid && validRecipients && lenderEmailValid && clientEmailValid
    );
  }

  return false;
};

export const getClientTickedAnswer = (data) => {
  return data
    .map((item) => (item.answer ? item.ClientId : null))
    .filter((val) => !!val)
    .join(',');
};

export const getMergeFieldOptionalDetails = (mergeFields, name) => {
  const field = mergeFields.find((item) => item && item.Name === name);
  return field ? field.OptionalDetails : [];
};

export const isClientEmailTicked = (item, answerObj) => {
  const answerCSV = get(answerObj, 'answerString[0]');
  const answers = splitString(answerCSV);
  return answers.includes(`${item.ClientId}`);
};

export const getEmailFieldAnswer = (item, questionId, answerObj) => {
  switch (questionId) {
    case QUESTION_ID_NZ.COPY_TO_CLIENT:
      return { ...item, answer: isClientEmailTicked(item, answerObj) };
    case QUESTION_ID_NZ.COPY_TO_ADVISER:
    case QUESTION_ID_NZ.COPY_TO_ASSISTANT:
      return { ...item, answer: get(answerObj, 'answerBool[0]') };
  }
};

export const mapMergeFieldEmailAnswer = (answers, fieldList, questionId) => {
  const answerObj = getQuestion(answers, questionId);
  return fieldList.map((item) =>
    getEmailFieldAnswer(item, questionId, answerObj),
  );
};

export const setupLoanAppEmailTemplate = ({
  $scope,
  $uibModal,
  corporateModelService,
  loanLenderSubmissionService,
  loanSubmissionEmailContentService,
}) => {
  if (!$scope.loanAppEmailTemplateNZ) {
    return;
  }

  $scope.setMergeFields = () => {
    const params = {
      familyId: $scope.familyId,
      loanId: get(
        $scope.loanAppSharedData,
        'loanAppSummary.LoanDetails.LoanID',
      ),
      ExcludeInsurance: true,
    };
    return corporateModelService.mergeFieldsGet(params).then(({ data }) => {
      $scope.loanAppMergeFields = appendAdditionalMergeFields(
        $scope.loanAppSharedData,
        loanSubmissionEmailContentService,
      )(data);
      $scope.setMergeFieldEmails(data);
    });
  };

  $scope.setMergeFieldEmails = (data) => {
    const otherCategory = getMergeFieldsByCategory(
      data,
      MERGE_FIELD_CATEGORY.OTHER,
    );
    const contactCategory = getMergeFieldsByCategory(
      data,
      MERGE_FIELD_CATEGORY.CONTACT,
    );

    $scope.mergeFieldEmail = {
      adviser: getMergeFieldOptionalDetails(
        otherCategory,
        MERGE_FIELDS.ADVISER_EMAIL,
      ),
      applicant: getMergeFieldOptionalDetails(
        contactCategory,
        MERGE_FIELDS.APPLICANT_EMAIL,
      ),
      assistant: getMergeFieldOptionalDetails(
        otherCategory,
        MERGE_FIELDS.ASSISTANT_EMAIL,
      ),
    };
  };

  $scope.setupAnswerMergeFieldEmails = (answers) => {
    const {
      COPY_TO_CLIENT,
      COPY_TO_ADVISER,
      COPY_TO_ASSISTANT,
    } = QUESTION_ID_NZ;
    const { applicant, adviser, assistant } = $scope.mergeFieldEmail;
    $scope.mergeFieldEmail = {
      adviser: mapMergeFieldEmailAnswer(answers, adviser, COPY_TO_ADVISER),
      applicant: mapMergeFieldEmailAnswer(answers, applicant, COPY_TO_CLIENT),
      assistant: mapMergeFieldEmailAnswer(
        answers,
        assistant,
        COPY_TO_ASSISTANT,
      ),
    };
  };

  $scope.refreshFieldsAndContent = () => {
    $scope.setMergeFields().then(() => {
      $scope.lenderSubmissionGet();
    });
  };

  $scope.onSaveTemplate = ({ data, type }, setBusy) => {
    const commonParams = {
      LoanScenarioId: $scope.loanAppId,
      FamilyId: $scope.familyId,
      DisplayOptionId: DISPLAY_OPTIONS.TEXT_ONLY,
    };
    const isForClient = type === EMAIL_MODAL_KEYS.CLIENT;
    const params = [
      {
        ...commonParams,
        QuestionId: isForClient
          ? QUESTION_ID.APPLICANT_EMAIL_SUBJECT
          : QUESTION_ID.LENDER_EMAIL_SUBJECT,
        answerString: [data.subject],
      },
      {
        ...commonParams,
        QuestionId: isForClient
          ? QUESTION_ID.APPLICANT_EMAIL_CONTENT
          : QUESTION_ID.LENDER_EMAIL_CONTENT,
        answerString: [data.body],
      },
    ];

    loanLenderSubmissionService.setEmailContent(params).then(({ data }) => {
      isFunction(setBusy) && setBusy(false, data);
      $scope.refreshFieldsAndContent();
    });
  };

  $scope.handleUrgentEmail = (isUrgent) => {
    const lenderSubject = get($scope.loanTemplateObj, 'forLender.subject');
    const updatedSubject = updateTextUrgency(lenderSubject, isUrgent);
    const commonParams = {
      LoanScenarioId: $scope.loanAppId,
      FamilyId: $scope.familyId,
    };
    const params = [
      {
        ...commonParams,
        DisplayOptionId: DISPLAY_OPTIONS.TEXT_ONLY,
        QuestionId: QUESTION_ID.LENDER_EMAIL_SUBJECT,
        answerString: [updatedSubject],
      },
      {
        ...commonParams,
        DisplayOptionId: DISPLAY_OPTIONS.CHECK_BOX,
        QuestionId: QUESTION_ID.IS_URGENT,
        answerBool: [isUrgent],
      },
    ];

    loanLenderSubmissionService
      .setEmailContent(params)
      .then(() => {
        $scope.lenderSubmissionGet();
      })
      .catch(toastError);
  };

  $scope.openEmailTemplateModal = (type) => {
    const { forClient, forLender } = $scope.loanTemplateObj;
    const isEmailTypeClient = type === EMAIL_MODAL_KEYS.CLIENT;
    const appendMergeFields = getAdditionalMergeFields();
    const template = `<email-template-fields
      class="mail-management"
      modal-instance="vm.modalInstance"
      email="vm.props.email"
      modal-append-title="vm.props.modalAppendTitle"
      on-save-template="vm.props.onSaveTemplate(data, callback)"
      append-merge-fields="vm.props.appendMergeFields"
      ></email-template-fields>`;

    const modalInstance = $uibModal.open({
      controller: 'CommonModalPlaceholderCtrl',
      size: 'wide',
      controllerAs: 'vm',
      backdrop: 'static',
      windowClass: 'email-template-fields-modal',
      template,
      resolve: {
        props: () => ({
          appendMergeFields,
          email: isEmailTypeClient ? forClient : forLender,
          modalAppendTitle: isEmailTypeClient ? 'Client' : 'Lender',
          onSaveTemplate: (data, callback) => {
            $scope.onSaveTemplate({ data, type }, callback);
          },
        }),
      },
    });
    modalInstance.result.then(
      (data) => {
        data === 1
          ? toastSuccess('Changes to the Email Content were saved.')
          : toastError('Something went wrong on saving email content.');
      },
      () => {},
    );
  };
};
