import angular from 'angular';
import _ from 'lodash';
import { LOAN_APP_ENQUIRY_SOURCE } from 'Common/constants/referralType';

class EnquirySourceService {
  constructor(optionsService, currentUserService, $q) {
    'ngInject';

    this.optionsService = optionsService;
    this.currentUserService = currentUserService;
    this.$q = $q;
  }

  getEnquirySourceList(scope = {}, params) {
    const defer = this.$q.defer();
    this.optionsService.getEnquirySourceList(params).then(
      (response) => {
        const data = response.data;
        let sourceList = [];
        if (data && data.length) {
          data.forEach((enquiry) => {
            if (
              !(
                this.currentUserService.isNZ &&
                (enquiry.ReferralCategoryId ===
                  LOAN_APP_ENQUIRY_SOURCE.REFERRAL ||
                  enquiry.ReferralCategoryId ===
                    LOAN_APP_ENQUIRY_SOURCE.AFFILIATE)
              )
            ) {
              const items = enquiry.EnquirySourceItem;
              if (items && items.length) {
                items.forEach((item) => {
                  const temp = {
                    ReferralCategoryId: enquiry.ReferralCategoryId,
                    ReferralCategoryName: enquiry.ReferralCategoryName,
                    ReferralItemId:
                      item.ReferralItemId || `${enquiry.ReferralCategoryId}-0`,
                    ReferralItemName: item.ReferralItemName,
                  };
                  sourceList = [...sourceList, temp];
                });
              }
            }
          });
        }
        scope.EnquirySourceList = sourceList;
        scope.enquirySourceRaw = [...sourceList];
        defer.resolve(scope.EnquirySourceList);
      },
      () => {
        defer.reject([]);
      },
    );
    return defer.promise;
  }

  getEnquirySourceListData(data) {
    this.enquirySourceData = data;
    return this.enquirySourceData.reduce((accum, enquiry) => {
      let newAccum = accum;
      if (enquiry.EnquirySourceItem && enquiry.EnquirySourceItem.length) {
        _.each(enquiry.EnquirySourceItem, (item) =>
          angular.extend(item, {
            ReferralCategoryId: enquiry.ReferralCategoryId,
            ReferralCategoryName: enquiry.ReferralCategoryName,
          }),
        );
        newAccum = accum.concat(enquiry.EnquirySourceItem);
      }
      return newAccum;
    }, []);
  }

  selectEnquiry(pipelineSharedData, enquirySourceList) {
    this.pipelineSharedData = pipelineSharedData;
    if (!pipelineSharedData.leadFilterData) {
      pipelineSharedData.validateFilters();
      if (enquirySourceList.length > 0) {
        pipelineSharedData.leadFilterData.enquiryId = enquirySourceList[0];
      }
    } else {
      const resultData = _.find(
        enquirySourceList,
        (item) =>
          item.ReferralItemId ===
            pipelineSharedData.leadFilterData.ReferralItemID &&
          item.ReferralCategoryId ===
            pipelineSharedData.leadFilterData.ReferralCategoryID,
      );
      if (resultData) {
        pipelineSharedData.leadFilterData.enquiryId = resultData;
      }
    }
  }
}

export default EnquirySourceService;
