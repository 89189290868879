import { chunkArray } from 'Common/utilities/array';
import {
  ENQUIRY_SOURCE,
  ALL_VALUE,
  WIDGET_OPTION_VAL,
} from 'Common/constants/dashboardWidget';
import {
  getValueOption,
  parseMultiSelectTicks,
  mapOptionAll,
  mapSelectedItem,
  getCustomOptionValues,
  getAdvisersParams,
  getFormattedAdvisers,
} from 'Common/utilities/dashboardWidget';

export default class UpcomingSettlementDatesCtrl {
  constructor(
    $q,
    $location,
    optionsService,
    dashboardService,
    currentUserService,
    overviewDashboardService,
    commonWidgetService,
    mycrmLookupService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$location = $location;
    this.optionsService = optionsService;
    this.dashboardService = dashboardService;
    this.currentUserService = currentUserService;
    this.overviewDashboardService = overviewDashboardService;
    this.commonWidgetService = commonWidgetService;
    this.mycrmLookupService = mycrmLookupService;
  }

  $onInit() {
    this.widgetTitle = this.myParam.DisplayName;
    this.tablePage = [];
    this.dateFilter = [];
    this.advisersDropdown = [];
    this.selectedOption = {};
    this.dataLoaded = false;
    this.viewType = 'table';
    this.slickConfig = {
      event: {
        init: (event, slick) => {
          slick.setPosition();
        },
      },
      method: {},
    };
    this.getWidgetOptionDropdown();

    this.events = [
      {
        title: 'My event title',
        startsAt: new Date(`2019-09-24T16:00:00.000Z`),
        allDay: true,
        draggable: false,
        resizable: false,
      },
    ];
    this.calendarView = 'month';
    this.calendarDate = new Date();
  }

  viewChangeClicked() {
    this.widgetTitle = this.myParam.DisplayName;
    return false;
  }

  setWidgetOptions(params) {
    const { widgetOptionId, value, selectedUser, needUpdate } = params;
    const payload = {
      WidgetOptionId: widgetOptionId,
      Widget_UserID: this.myParam.Widget_UserID,
      Widget_OptionValue: value || 0,
      SelectedUser: selectedUser || 0,
    };
    this.overviewDashboardService.WidgetOptionSet(payload).then(() => {
      if (needUpdate) {
        this.getWidgetUserOptions(true);
        return;
      }
      this.getUpcomingDates();
    });
  }

  setView(viewType) {
    this.viewType = viewType;
  }

  onTitleClick() {
    this.$location.path('/app/report');
  }

  selectDateFilter(data) {
    this.dataLoaded = false;
    const param = {
      SelectedUser: null,
      Widget_UserID: this.myParam.Widget_UserID,
      Widget_OptionValue: data.valueId,
      WidgetOptionId: data.id,
    };
    this.dashboardService.setWidgetOption(param).then(() => {
      this.getUpcomingDates();
    });
  }

  getSelectedWidgetOption() {
    this.dashboardService
      .getWidgetOption(this.myParam.Widget_UserID)
      .then((res) => {
        if (!res.length) {
          return;
        }
        const matchedDate = this.dateFilter.filter(
          (item) => item.valueId === parseInt(res[0].widget_OptionValue, 10),
        );

        this.selectedOption = matchedDate.length ? matchedDate[0] : {};
      });
  }

  getWidgetUserOptions(isLoadSplits) {
    return this.commonWidgetService
      .getWidgetUserOptions(this.myParam.Widget_UserID)
      .then(({ data }) => {
        isLoadSplits && this.getUpcomingDates();
        if (!data || !data.length) {
          return;
        }

        const advisers = getValueOption(ENQUIRY_SOURCE.ADVISERS, data);

        this.advisersDropdown = parseMultiSelectTicks(
          advisers,
          this.advisersDropdown,
        );
      });
  }

  getWidgetOptionDropdown() {
    const { isAssistant, isAdminAssistant } = this.currentUserService;
    const isLoanWriterOnly = isAssistant || isAdminAssistant;

    const promises = [
      this.dashboardService.getWidgetDateOptions(),
      this.mycrmLookupService.getAdvisers(getAdvisersParams(isLoanWriterOnly)),
    ];

    this.$q.all(promises).then((responses) => {
      if (!responses || !responses.length) {
        return;
      }
      const [dateFilter, advisersDropdown] = responses;

      this.dateFilter = dateFilter;
      this.advisersDropdown = getFormattedAdvisers(advisersDropdown);
      this.getSelectedWidgetOption();
      this.getWidgetUserOptions(true);
    });
  }

  plotCalendarEvents(events) {
    this.events = events.map((item) => {
      return {
        title: item.familyName,
        startsAt: item.settlementDate
          ? new Date(item.settlementDate)
          : new Date(item.financeDate),
        allDay: true,
        draggable: false,
        resizable: false,
      };
    });
  }

  setCustomWidgetOption(variableName, isAll) {
    const optionValues = getCustomOptionValues(this[variableName]);

    const { ADVISER } = WIDGET_OPTION_VAL;
    const widgetOptionId = ENQUIRY_SOURCE.ADVISERS;

    const value = isAll ? ADVISER.ALL : ADVISER.CUSTOM;
    const needUpdate = false;

    this.setWidgetOptions({
      widgetOptionId,
      value,
      selectedUser: optionValues,
      needUpdate,
    });
  }

  selectAll(varName, inputData) {
    this[varName] = mapOptionAll(inputData);
    this.setCustomWidgetOption(varName, true);
  }

  onItemSelect(itemClicked, variableName, inputData, selection) {
    if (itemClicked.value === ALL_VALUE || !selection.length) {
      this.selectAll(variableName, inputData);
      return;
    }

    this[variableName] = mapSelectedItem(inputData);
    this.setCustomWidgetOption(variableName);
  }

  getUpcomingDates() {
    this.dataLoaded = false;

    const param = { userWidgetId: this.myParam.Widget_UserID };

    const tableData =
      this.type === 'settlement'
        ? this.dashboardService.getUpcomingSettlementDates(param)
        : this.dashboardService.getUpcomingFinanceDates(param);

    tableData.then((res) => {
      this.plotCalendarEvents(res);

      const chunkSize = 5;
      this.tablePage = chunkArray(res, chunkSize);
      this.dataLoaded = true;
    });
  }
}
