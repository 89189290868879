import angular from 'angular';
import ngAnimate from 'angular-animate';
import ngCookies from 'angular-cookies';
import ngTouch from 'angular-touch';
import ngSanitize from 'angular-sanitize';
import uiRouter from 'angular-ui-router';
import uiBootstrap from 'angular-ui-bootstrap';
import uiMask from 'angular-ui-mask';
import uiSelect from 'ui-select';
import 'ui-select/dist/select.css';
import 'ngstorage';
import 'angular-ui-switch';
import 'angular-ui-switch/angular-ui-switch.min.css';
import 'angular-moment';
import 'oclazyload';
import 'jquery-ui-dist/jquery-ui.js';
import 'jquery-ui-dist/jquery-ui.min.css';
import 'jquery-ui-dist/jquery-ui.structure.css';
import 'angular-swipe/dist/angular-swipe.min.js';
import 'angular-truncate/src/truncate.js';
import 'angularjs-toaster/toaster.js';
import 'angularjs-toaster/toaster.css';
import 'angular-aside/dist/js/angular-aside.min.js';
import 'angular-aside/dist/css/angular-aside.min.css';
import 'v-accordion/dist/v-accordion.min.js';
import 'v-accordion/dist/v-accordion.min.css';
import 'v-button/dist/v-button.min.js';
import 'v-button/dist/v-button.min.css';
import 'angular-sweetalert';
import 'angular-notification-icons';
import 'angular-notification-icons/dist/angular-notification-icons.min.css';
import 'angular-ladda';
import 'angular-awesome-slider';
import 'angular-awesome-slider/dist/css/angular-awesome-slider.min.css';
import 'angular-slick-carousel';
import 'angular-loading-bar';
import 'angular-loading-bar/build/loading-bar.min.css';
import 'angular-breadcrumb';
import 'angular-scroll';
import 'angular-fullscreen/src/angular-fullscreen.js';
import 'angular-drag-and-drop-lists/angular-drag-and-drop-lists.min.js';
import 'angular-jwt';
import 'ng-tags-input';
import 'ng-droplet';
import 'ng-idle';
import 'ng-knob/dist/ng-knob.min.js';
import 'isteven-angular-multiselect/isteven-multi-select.js';
import 'isteven-angular-multiselect/isteven-multi-select.css';
import 'angular-bootstrap-calendar';
import 'gm.datepicker-multi-select/dist/gm.datepickerMultiSelect.min.js';
import 'angular-ui-sortable/dist/sortable.js';
import 'angular-bootstrap-calendar/dist/js/angular-bootstrap-calendar-tpls.js';
import 'angular-bootstrap-calendar/dist/css/angular-bootstrap-calendar.min.css';
import 'ng-sortable';
import 'ng-sortable/dist/ng-sortable.css';
import 'angucomplete-alt';
import 'angular-elastic';
import 'angular-sticky-plugin';
import 'angulartics';
import 'angulartics-google-analytics';
import 'angular-vimeo-embed/dist/angular-vimeo-embed.min.js';
import 'ng-table';
import 'ng-table/dist/ng-table.min.css';
import 'angular-bootstrap-nav-tree';
import 'ngImgCrop/compile/minified/ng-img-crop.js';
import 'ngImgCrop/compile/minified/ng-img-crop.css';
import 'angular-file-upload';
import 'ngmap/build/scripts/ng-map.min.js';
import 'angular-chart.js';
import 'ng-flow/dist/ng-flow-standalone.min.js';
import 'angular-ckeditor/angular-ckeditor.min.js';
import 'ng-nestable/src/angular-nestable.js';
import 'ng-notify';
import 'ng-notify/dist/ng-notify.min.css';
import 'checklist-model/checklist-model.js';
import 'angular-spectrum-colorpicker';
import 'angu-fixed-header-table/angu-fixed-header-table.js';
import 'angucomplete/angucomplete.js';
import 'angucomplete/angucomplete.css';
import 'angular-ui-tab-scroll/angular-ui-tab-scroll.js';
import 'angular-ui-tab-scroll/angular-ui-tab-scroll.css';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'intl-tel-input/build/js/intlTelInput.min.js';
import 'intl-tel-input/lib/libphonenumber/build/utils.js';
import 'ng-intl-tel-input/dist/ng-intl-tel-input.min.js';
import './config/config.calendar.js';
import mycrmCountries from './services/countryProvider';

export default angular.module('packet', [
  ngAnimate,
  ngCookies,
  ngTouch,
  ngSanitize,
  uiRouter,
  uiBootstrap,
  uiMask,
  uiSelect,
  'ngStorage',
  'angularMoment',
  'oc.lazyLoad',
  'swipe',
  'truncate',
  'uiSwitch',
  'toaster',
  'ngAside',
  'vAccordion',
  'vButton',
  'oitozero.ngSweetAlert',
  'angular-notification-icons',
  'angular-ladda',
  'angularAwesomeSlider',
  'slickCarousel',
  'cfp.loadingBar',
  'ncy-angular-breadcrumb',
  'duScroll',
  'FBAngular',
  'dndLists',
  'angular-jwt',
  'ngTagsInput',
  'ngDroplet',
  'ngIdle',
  'ui.knob',
  'isteven-multi-select',
  'mwl.calendar',
  'as.sortable',
  'ui.sortable',
  'angucomplete-alt',
  'monospaced.elastic',
  'hl.sticky',
  'angulartics',
  'angulartics.google.analytics',
  'vimeoEmbed',
  'ngTable',
  'angularBootstrapNavTree',
  'ngImgCrop',
  'angularFileUpload',
  'ngMap',
  'chart.js',
  'flow',
  'ckeditor',
  'ng-nestable',
  'ngNotify',
  'checklist-model',
  'angularSpectrumColorpicker',
  'anguFixedHeaderTable',
  'angucomplete',
  'ui.tab.scroll',
  'gm.datepickerMultiSelect',
  'ngIntlTelInput',
  mycrmCountries,
  // 'datetimepicker',
  // 'ngAppear',
  // 'SignalR'
]).name;
