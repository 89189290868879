export const VALID_MOBILE_TYPES = ['MOBILE', 'FIXED_LINE_OR_MOBILE'];

export const phoneType = {
  home: 'Home',
  work: 'Work',
  mobile: 'Mobile',
};

export const PHONE_TYPE_INDEX = {
  HOME: 0,
  WORK: 1,
  MOBILE: 2,
};
