class QuickProductFinderDetailModalCtrl {
  constructor(loanProfilerService, uiService, currentUserService) {
    'ngInject';

    this.loanProfilerService = loanProfilerService;
    this.uiService = uiService;
    this.currentUserService = currentUserService;
  }

  $onInit() {
    this.checkProductLimit();
    this.loanProfilerService
      .getProductFullDetails(this.product.ProductID)
      .then((response) => {
        if (!response || !response.data) {
          return;
        }
        this.singleProductDetail = response.data;
      });
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }

  checkProductLimit() {
    this.isProductLimit =
      this.productCount >= this.uiService.loanAppProductFinderFavoriteLimit;
  }

  selectLoanStructureProduct(loanStructureData, productData) {
    this.selectLoanProduct(loanStructureData, productData);
    this.cancel();
  }
}

export default QuickProductFinderDetailModalCtrl;
