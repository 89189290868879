export const PROVIDERS = {
  FIDELITY: 'Fidelity Nib',
  SBS: 'Southland Building Society',
  NIB: 'NIB',
  PROSPA: 'PROSPA',
};

export const PROVIDER_LOGO = {
  [PROVIDERS.FIDELITY]: 'fidelity',
  [PROVIDERS.SBS]: 'SBS',
  [PROVIDERS.NIB]: 'nib',
  [PROVIDERS.PROSPA]: 'prospa',
};
