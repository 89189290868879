import { uniqBy } from 'lodash';
import {
  INSURANCE_PACK_TYPE,
  LAUNCH_LOCATION,
} from 'Common/constants/helloBook';
import {
  EXISTING_TAB,
  PREVIOUS_TAB,
  LEVEL,
} from 'Common/constants/fireAndGeneral';
import {
  showActionConfirmation,
  displayError,
  alertSucess,
} from 'Common/utilities/alert';
import {
  DEFAULT_EXISTING_INSURANCE,
  DEFAULT_INSURANCE_FILTER,
} from 'Common/default/insurance';
import { sendApplicationPack } from 'Common/utilities/insurance';
import { removeMultipleWhiteSpace } from 'Common/utilities/string';

const EXISTING_BENEFIT_TYPE = {
  LIFE_COVER: 1,
  HEALTH_COVER: 3,
  INCOME_PROTECTION: 10,
  MORTAGE_REPAYMENT: 7,
  WAIVER_OF_PREMIUM: 51,
  REDUNDANCY: 11,
  TPD: 9,
  TRAUMA_COVER: 2,
  FAMILY_PROTECTION: 20,
  FIRE_GENERAL: 26,
};

export function getBenefitLogo(benefitId) {
  switch (benefitId) {
    case EXISTING_BENEFIT_TYPE.LIFE_COVER:
      return '/assets/images/insuranceTab/life-cover-small.svg';
    case EXISTING_BENEFIT_TYPE.HEALTH_COVER:
      return '/assets/images/insuranceTab/health-cover-small.svg';
    case EXISTING_BENEFIT_TYPE.INCOME_PROTECTION:
      return '/assets/images/insuranceTab/income-protection-small.svg';
    case EXISTING_BENEFIT_TYPE.MORTAGE_REPAYMENT:
      return '/assets/images/insuranceTab/mortgage-repayment-small.svg';
    case EXISTING_BENEFIT_TYPE.WAIVER_OF_PREMIUM:
      return '/assets/images/insuranceTab/premium-waiver-small.svg';
    case EXISTING_BENEFIT_TYPE.REDUNDANCY:
      return '/assets/images/insuranceTab/redundancy-cover-small.svg';
    case EXISTING_BENEFIT_TYPE.TPD:
      return '/assets/images/insuranceTab/total-permanent-small.svg';
    case EXISTING_BENEFIT_TYPE.TRAUMA_COVER:
      return '/assets/images/insuranceTab/trauma-small.svg';
    case EXISTING_BENEFIT_TYPE.FAMILY_PROTECTION:
      return '/assets/images/insuranceTab/family-protection-small.svg';
    default:
      return null;
  }
}

export function splitPrefix(providerId = '') {
  return providerId.split('-')[1];
}

const INSURANCE_LIST_TYPE = {
  // eslint-disable-next-line sonarjs/no-duplicate-string
  IN_PROGRESS: 'In Progress',
};

export default class ExistingInsuranceCtrl {
  constructor(
    $state,
    $uibModal,
    contactInsuranceService,
    insuranceApplicationService,
    insurancePipelineService,
    insuranceModalApplicationService,
    insuranceSharedData,
    insuranceFGModalService,
    helloBookPreviewService,
    configService,
    downloadDataService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$uibModal = $uibModal;
    this.configService = configService;
    this.helloBookPreviewService = helloBookPreviewService;
    this.contactInsuranceService = contactInsuranceService;
    this.insuranceApplicationService = insuranceApplicationService;
    this.insurancePipelineService = insurancePipelineService;
    this.insuranceModalApplicationService = insuranceModalApplicationService;
    this.insuranceSharedData = insuranceSharedData;
    this.insuranceCreateApp = insuranceModalApplicationService;
    this.insuranceFGModalService = insuranceFGModalService;
    this.downloadDataService = downloadDataService;
  }

  $onInit() {
    this.LEVEL = LEVEL;
    this.tabName = this.type;
    this.isForExistingTab = this.type === EXISTING_TAB || PREVIOUS_TAB;
    this.defaultPolicyLabel = 'Show All Policies';
    this.fireAndGeneralBenefit = EXISTING_BENEFIT_TYPE.FIRE_GENERAL;
    this.insuranceProvider = [];
    this.insuranceFamilyPoliciesList = [];
    this.existing = { ...DEFAULT_EXISTING_INSURANCE };
    this.existingInsuranceList = [];
    this.totalPolicyFee = 0;
    this.totalPremium = 0;
    this.existingIsuranceModalData = [];
    this.insuranceProviderList = [];
    this.insurancePolicyList = [];
    this.filter = { ...DEFAULT_INSURANCE_FILTER };
    this.familyId = parseInt(this.$state.params.familyId, 10) || 0;

    this.loadProviders();
    this.loadInsurances();
    this.loadFiltersList();

    if (this.type === INSURANCE_LIST_TYPE.IN_PROGRESS) {
      this.insuranceSharedData.reloadInsuranceList = () =>
        this.loadInsurances();
    }
  }

  $onChanges(changes) {
    if (changes.changeCount && changes.changeCount.currentValue) {
      this.viewDataRefresh();
    }
  }

  loadProviders() {
    this.contactInsuranceService
      .insuranceQuoteProviderGet(0, 0)
      .then((response) => {
        if (!response.data || !response.data.length) {
          return;
        }
        this.insuranceProvider = [
          { ProviderName: 'Show All Insurers', ProviderId: 0 },
          ...response.data,
        ];
      });
  }

  loadFamilyPolicies() {
    this.contactInsuranceService
      .insuranceFamilyPoliciesGet(this.familyId, this.type)
      .then(({ data }) => {
        this.insuranceFamilyPoliciesList = [
          { PolicyNumber: this.defaultPolicyLabel, PolicyID: '' },
          ...(data || []),
        ];

        this.insurancePolicyList =
          data && data.length ? [...this.insuranceFamilyPoliciesList] : [];
      });
  }

  getInActivedInsurances({ benefitDetails = [] }) {
    const result = benefitDetails
      .filter(({ isDisabled }) => isDisabled)
      .reduce((benefit, accum) => {
        const propName = removeMultipleWhiteSpace(accum.statusName);
        benefit[propName] = benefit[propName] || [];
        benefit[propName] = [...benefit[propName], accum];
        return benefit;
      }, {});
    return Object.keys(result).map((type) => {
      const setData = Object.create(null);
      setData.benefitList = result[type];
      setData.title = result[type][0].statusName;
      setData.isDisabled = result[type][0].isDisabled;
      return setData;
    });
  }

  mapInsurances(data) {
    this.existingInsuranceList =
      (data &&
        data.map((item = {}) => {
          item.isToInvite = this.isToInviteClientCompleteApplication(item);
          item.isToView = this.isToViewClientCompleteApplication(item);
          if (item.isApplication) {
            item.inActiveBenefits = this.getInActivedInsurances(item);
          }
          item.benefitDetails = item.benefitDetails.filter(
            ({ isDisabled }) => !isDisabled,
          );
          return item;
        })) ||
      [];
  }

  loadInsurances(providerId = '0', policyNumber = '') {
    this.isLoading = true;

    this.insuranceApplicationService
      .getInsuranceApplication({
        familyId: this.familyId,
        status: this.type,
        providerId,
        policyNumber,
        isFireAndGeneral: !!splitPrefix(providerId),
      })
      .then((data) => {
        this.isLoading = false;
        this.mapInsurances(data);
      });
  }

  loadFiltersList() {
    this.contactInsuranceService
      .insuranceFamilyProviderGet(this.familyId, this.type)
      .then(({ data }) => {
        this.insuranceProviderList =
          data && data.length
            ? [
                { ProviderName: 'Show All Insurers', PrefixedID: '0' },
                ...uniqBy(data, 'PrefixedID'),
              ]
            : [];
      });
    this.filter.providerId = '0';
    this.filter.policyNumber = this.defaultPolicyLabel;
    this.loadFamilyPolicies();
  }

  onDeleteSuccess(message) {
    this.loadFiltersList();
    this.loadInsurances();
    this.insuranceSharedData.summaryInsuranceListGet(this.familyId);
    this.onDeleted && this.onDeleted({ familyId: this.familyId });
    alertSucess('Success', message, true);
  }

  deleleteAppConfirm(itemId) {
    return () => {
      this.insurancePipelineService
        .deletePipelineCard(itemId)
        .then(() => {
          this.onDeleteSuccess('Application removed successfully!');
        })
        .catch(displayError);
    };
  }

  deleteApplication(itemId) {
    showActionConfirmation(
      'Are you sure?',
      'This application will be removed from your list.',
      this.deleleteAppConfirm(itemId),
    );
  }

  deleteBenefitConfirm(insurance) {
    const [benefit] = insurance?.benefitDetails;
    return () => {
      const isMainApp = benefit.policyId && !insurance.isApplication;
      const deletePromise = isMainApp
        ? this.contactInsuranceService.insuranceDetailsDelete(
            this.familyId,
            benefit.policyId,
          )
        : this.insurancePipelineService.deleteInsuranceAppBenefitCover(
            benefit.id,
          );

      deletePromise
        .then((response) => {
          const isSuccessful = response.data === 1 || !benefit.policyId;
          if (isSuccessful) {
            this.onDeleteSuccess('Insurance successfully deleted.');
          } else {
            displayError({
              data: {
                Message: 'Insurance was not deleted successfully.',
              },
            });
          }
        })
        .catch(displayError);
    };
  }

  delete({ benefit, insurance, level = 1 }) {
    if (insurance.isApplication && level === 1) {
      this.deleteApplication(insurance.quoteId);
      return;
    }
    showActionConfirmation(
      'Are you sure?',
      'This insurance will be removed from your list',
      this.deleteBenefitConfirm(insurance),
    );
  }

  changeFilters() {
    const { filter } = this;
    const policyNumber =
      filter.policyNumber === this.defaultPolicyLabel
        ? ''
        : filter.policyNumber.toString();

    this.loadInsurances(filter.providerId, policyNumber);
  }

  resetFilters() {
    this.filter = DEFAULT_INSURANCE_FILTER;
    this.changeFilters();
  }

  triggerUpdate() {
    this.onUpdated && this.onUpdated({ familyId: this.familyId });
  }

  viewDataRefresh() {
    this.loadFiltersList();
    this.changeFilters();
    this.insuranceSharedData.summaryInsuranceListGet(this.familyId);
  }

  existingBenefitOptionChange() {
    this.loadInsurances();
  }

  addExistingInsuranceModal() {
    const modalInstance = this.$uibModal.open({
      templateUrl:
        '/assets/views/contacts/partials/insurance-modal/insurance_existing.html',
      controller: 'InsuranceExistingModalCtrl',
      windowClass: 'insurance-existing-modal',
      backdrop: false,
      keyboard: false,
      resolve: {
        items() {
          return {
            data: [],
          };
        },
      },
    });

    modalInstance.result.then(() => {
      this.triggerUpdate();
    });
  }

  addNew() {
    if (this.type === 'In Progress') {
      this.onNewApplication();
    } else {
      this.addExistingInsuranceModal();
    }
  }

  addFireAndGeneral() {
    this.insuranceFGModalService.openFireAndGeneralModal().result.then(() => {
      this.triggerUpdate();
    });
  }

  editFireAndGeneral(benefit, insurance) {
    const data = {
      benefitId: benefit.id,
      isFromFnGTable: !!insurance.isFromFnGTable,
    };
    this.insuranceFGModalService
      .openFireAndGeneralModal(data)
      .result.then(() => {
        this.triggerUpdate();
      });
  }

  edit({ value, insurance }) {
    if (value.benefitId === EXISTING_BENEFIT_TYPE.FIRE_GENERAL) {
      this.editFireAndGeneral(value, insurance);
      return;
    }
    if (insurance.quoteId) {
      this.editInsuranceAppBenefit(value);
    } else {
      this.editInsuranceBenefit(value);
    }
  }

  editInsuranceAppBenefit(benefit) {
    this.insurancePipelineService
      .getBenefitCover(benefit.id)
      .then((response) => {
        this.insuranceModalApplicationService
          .openAddBenefitModal({
            ...response,
            familyId: this.familyId,
            paymentFrequency: benefit.frequency,
          })
          .result.then(() => this.triggerUpdate());
      });
  }

  isToInviteClientCompleteApplication(insurance) {
    return (
      !!this.configService.feature.onlineInsuranceApplication &&
      this.type === 'In Progress' &&
      !insurance.isInviteClientToCompleteSent
    );
  }

  isToViewClientCompleteApplication(insurance) {
    return (
      insurance.isApplication &&
      !!this.configService.feature.onlineInsuranceApplication &&
      insurance.isInviteClientToCompleteSent
    );
  }

  editInsuranceBenefit(benefit) {
    this.contactInsuranceService
      .insuranceDetailsGet(this.familyId, benefit.id)
      .then((response) => {
        const modalInstance = this.$uibModal.open({
          templateUrl:
            '/assets/views/contacts/partials/insurance-modal/insurance_existing.html',
          controller: 'InsuranceExistingModalCtrl',
          windowClass: 'insurance-existing-modal',
          backdrop: false,
          keyboard: false,
          resolve: {
            items() {
              return {
                data: response,
              };
            },
          },
        });

        modalInstance.result.then(() => this.triggerUpdate());
      });
  }

  viewWorkbench(quoteId) {
    this.insuranceCreateApp
      .openWorkbenchModal(quoteId, this.familyId)
      .result.then((result = {}) => {
        const { isStatusChange, coversUpdated } = result;
        if (isStatusChange) {
          this.triggerUpdate();
        } else if (coversUpdated) {
          this.viewDataRefresh();
        }
      });
  }

  sendApplicationPack(insurance) {
    const data = {
      params: {
        familyId: this.familyId,
        jointContact: true,
        quoteId: insurance.quoteId,
        provider: {
          id: insurance.providerId,
          name: insurance.providerName,
        },
        packType: INSURANCE_PACK_TYPE.APPLICATION,
        options: {
          location: LAUNCH_LOCATION.INSURANCE_TAB,
        },
      },
      helloBookPreviewService: this.helloBookPreviewService,
    };
    sendApplicationPack(data, this);
  }

  previewPdfApplication(appId) {
    this.insuranceApplicationService.getApplicationPDF(appId).then((data) => {
      if (!data) {
        return;
      }
      const [fileName] = data.fileName;

      this.downloadDataService.download(
        `data:application/${data.fileExtension};base64,${data.content}`,
        fileName,
        data.fileExtension,
      );
    });
  }
}
