import { STATUS_BY_DATE_KEY } from 'Common/constants/tasks';
import { splitByCapitalLetters } from 'Common/utilities/string';
import { getUtcTimestamp } from 'Common/utilities/date';

export const getTaskStatus = (task) => {
  if (!task) {
    return '';
  }

  return STATUS_BY_DATE_KEY.reduce((acc, cur) => {
    return task[cur.key] ? cur.text : acc;
  }, '');
};

export const getTaskDataForGTM = ({
  getParams = {},
  user = {},
  event = 'get_tasks',
}) => {
  const params = Object.keys(getParams).reduce((acc, cur) => {
    const key = splitByCapitalLetters(cur, '_', true);
    return { ...acc, [key]: getParams[cur] };
  }, {});

  return {
    event,
    user_family_id: user.familyId,
    user_full_name: user.fullName,
    ...params,
  };
};

export const getDateFilterTimestamp = (dateObj) => {
  const validDateObject = dateObj && dateObj.start && dateObj.end;
  if (!validDateObject) {
    return { start: null, end: null };
  }

  return {
    start: getUtcTimestamp(dateObj.start, true),
    end: getUtcTimestamp(dateObj.end, true),
  };
};

export const getLenderLogoHtml = ({ countryCode, lenderId, lenderName }) => {
  return lenderId
    ? `<lender-logo
      country-code="'${countryCode}'"
      lender-id="${lenderId}"
      lender-name="'${lenderName}'"
    ></lender-logo>`
    : '';
};

export const getTaskStatusChip = (task) => {
  const status = getTaskStatus(task);

  if (task.IsDueToday && !task.IsCompleted) {
    return `<span class='status-chip due-today-task'>
      <due class='chip-icon'></due>
      ${status}
    </span>`;
  }

  if (task.IsOverDue && !task.IsCompleted) {
    return `<span class='status-chip overdue-task'>${status}</span>`;
  }

  return `<span>${status}</span>`;
};
