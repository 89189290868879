import moment from 'moment-timezone';
import { TO_MYCRM_DATE_FORMAT } from 'Common/constants/dateConfigs';

class AutomationRuleService {
  constructor(
    automationRuleModelService,
    loanScenarioModelService,
    currentUserService,
    clientInfoService,
    tasksService,
  ) {
    'ngInject';

    this.automationRuleModelService = automationRuleModelService;
    this.loanScenarioModelService = loanScenarioModelService;
    this.currentUserService = currentUserService;
    this.clientInfoService = clientInfoService;
    this.tasksService = tasksService;
  }

  getAutomationRule({ dealTypeId, statusId, purposeId }) {
    return this.automationRuleModelService
      .getAutomationRules({ dealTypeId, statusId, purposeId, isEnabled: true })
      .then((data = []) => {
        const [automationRule] = data;
        return automationRule;
      });
  }

  saveFamilyTask({ familyId, loanId, templateId, taskTemplate = [] }) {
    const tasks = taskTemplate.reduce((templates, template) => {
      const newTemplate = {
        FamilyID: familyId,
        TaskID: 0,
        Title: template.TaskName,
        assignedClientID: template.AssignedAdvisorID,
        CreatedByUser: this.currentUserService.familyId,
        Notes: '',
        TaskSettingsID: template.TaskSettingsID,
        LoanId: loanId,
        DueDate: moment(template.DueDate).format(TO_MYCRM_DATE_FORMAT),
      };
      return [...templates, newTemplate];
    }, []);
    return this.tasksService.taskV3MultipleSet({
      TaskList: tasks,
      TaskTemplateID: templateId,
    });
  }

  async getFormattedDueDateTaskTemplate(templateId) {
    const { data } = await this.tasksService.taskTemplateSettingsGet(
      templateId,
    );
    const templateModel = {
      TaskTemplateID: templateId,
      TaskTemplateName: '',
      TaskSettingsList: data,
    };
    return this.tasksService.dueDateSubsequentlyUpdate(templateModel);
  }

  async applyTaskTemplate({ loanId, templateId, familyIds }) {
    const { data: taskTemplate } = await this.getFormattedDueDateTaskTemplate(
      templateId,
    );
    const taskRequests = familyIds?.reduce((requests, familyId) => {
      return [
        ...requests,
        this.saveFamilyTask({ familyId, loanId, templateId, taskTemplate }),
      ];
    }, []);
    try {
      await Promise.all(taskRequests);
      return taskTemplate;
    } catch {
      return null;
    }
  }

  async applyAutomationRule({
    dealTypeId,
    statusId,
    purposeId,
    loanId,
    involvedParties,
  }) {
    const automationRule = await this.getAutomationRule({
      dealTypeId,
      statusId,
      purposeId,
    });
    const { automationRuleId } = automationRule ?? {};
    if (!automationRuleId) {
      return null;
    }

    const familyIds = [...new Set(involvedParties.map((p) => p.familyId))];

    try {
      const templates = await this.applyTaskTemplate({
        loanId,
        familyIds,
        templateId: automationRule.templateId,
      });

      await this.automationRuleModelService.applyAutomationRule(
        automationRuleId,
      );
      return { ...automationRule, templatesCount: templates?.length ?? 0 };
    } catch {
      return null;
    }
  }
}

export default AutomationRuleService;
