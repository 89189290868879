import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import {
  BROKER_NOTES_ID,
  BROKER_NOTES_MAX_LENGTH,
} from 'Common/constants/loanAppBrokerNotes';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';
import { REQUESTER } from 'Common/constants/addNewContactRequesters';
import { ROLE_TYPE } from 'Common/constants/roleType';
import { ageToDOBGet } from 'Common/utilities/dob';
import { showBetterEntity } from 'Common/utilities/showFeature';
import {
  COMPANY_TYPES,
  ENTITY_PARTY_TYPES,
  PERSON_PARTY_TYPES,
} from 'Common/constants/partyTypes';
import { SEARCH_TYPES } from 'Common/constants/searchType';
import {
  LENDING_CATEGORY,
  ASSET_PERSONAL_CATEGORY,
} from 'Common/constants/securityValues';
import { noteListInitUtil } from 'Common/utilities/loanApplication';
import { LOAN_APP_APPLICANTS } from 'Common/constants/performance';
import { performance } from 'Common/utilities/perfume';
import { loanAppSectionData } from 'Common/utilities/dataLayer';
import { parseToInt10 } from 'Common/utilities/parse';
import {
  getPartyType,
  updateClientDescription,
  getListDependents,
  getListSolicitors,
  getListAccountants,
  getListAssetSuppliers,
  getApplicantList,
  getGuarantorList,
  getClientFullName,
  getListApplicants,
  getListGuarantors,
  mapSoleTraderList,
  linkedSoleTrader,
  getListSoleTraders,
  refreshApplicantDepentSection,
  setSearchTypeToggles,
  getLendingCategoryId,
  getMappedEnityForGuarantors,
  assignApplicantBrokerNotesUtil,
  refreshApplicantSection,
  updateLoanAppSummary,
  hasFullApplicantsAddressHistory,
  hasFullApplicantsIdentification,
  setDependantParent,
  getLoanDependants,
} from './../util/application.js';
import { toggleDependantLoanAppInclusion } from 'Common/utilities/dependant';
import { setCompletionStatus } from './../util/loanAppCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('LoanAppApplicationCtrl', function LoanAppApplicationCtrl(
      $rootScope,
      $scope,
      $timeout,
      contactService,
      loanScenarioService,
      loanApplicationServices,
      optionsService,
      toaster,
      $state,
      $stateParams,
      utilitiesService,
      $uibModal,
      colorService,
      $document,
      SweetAlert,
      financialsSharedService,
      loanAppSharedData,
      applicationSharedService,
      uiService,
      $window,
      contactSharedDataService,
      loanService,
      identificationService,
      configService,
      currentUserService,
      blueStoneSubmissionService,
      contactModelService,
      opportunityLoanAppService,
      loanScenarioModelService,
      dependantModelService,
    ) {
      $scope.isApplicantLoaded = false;
      $scope.isListApplicantsLoaded = false;
      // eslint-disable-next-line sonarjs/cognitive-complexity
      const initializeApplicant = () => {
        let clickCounter = 0;
        /* listeners */
        $scope.$on('isLodged', (event, data) => {
          $scope.isLodged = data;
        });
        /* end of listeners */

        const {
          assetFinance,
          betterEntity,
          dependentUplift,
          dependentsWithOneClientAsParent,
        } = configService.feature;
        $scope.showBetterEntity = showBetterEntity({
          betterEntity,
          currentUserService,
        });
        $scope.dependentUplift = dependentUplift;
        $scope.dependentsWithOneClientAsParent = dependentsWithOneClientAsParent;
        $scope.isLoanAppOnOpportunities =
          uiService.viewOfAfileTurnedOn && $state.params.opportunityId;

        $scope.uiService = uiService;
        $scope.PERSON_PARTY_TYPES = PERSON_PARTY_TYPES;
        $scope.BROKER_NOTES_MAX_LENGTH = BROKER_NOTES_MAX_LENGTH;
        $scope.loanAppSharedData = loanAppSharedData;
        $scope.isAssetFinanceActive = assetFinance;
        $scope.familyIdentityStatusSet = {};
        $scope.familyIdentification = {};
        $scope.searchType = SEARCH_TYPES;
        blueStoneSubmissionService.extentFunction.applicantsSection = $scope;
        $scope.processApplicantSection = (sections = '') => {
          $scope.isApplicantLoaded = true;
          blueStoneSubmissionService.toggleBySections(sections);
          performance.start(LOAN_APP_APPLICANTS);
          if (clickCounter !== 0) {
            return;
          }
          loanAppSharedData.updateServiceabilityDropdown();
          clickCounter++;
          $scope.BROKER_NOTES_ID = BROKER_NOTES_ID;
          $scope.financialsSharedService = financialsSharedService;

          $scope.loanAppId = $state.params.loanAppId;
          $scope.familyId = $state.params.familyId;
          $scope.listEmploymentHistoryLength = 0;
          $scope.listAccountants = [];
          $scope.listGuarantors = [];
          $scope.listSolicitors = [];
          $scope.listAssetSuppliers = [];
          $scope.submitClicked = false; /* if ture submit button will be disable */
          $scope.loanAppLendingCategory =
            $scope.loanAppSharedData &&
            $scope.loanAppSharedData.loanAppSummary &&
            $scope.loanAppSharedData.loanAppSummary.LoanDetails &&
            $scope.loanAppSharedData.loanAppSummary.LoanDetails
              .LendingCategoryId;
          $scope.showAssetSupplierRelatedParty =
            $scope.isAssetFinanceActive &&
            $scope.loanAppLendingCategory === LENDING_CATEGORY.ASSET_FINANCE;

          $scope.RelatedPartySettings = {
            NoSolicitors: true,
            NoAccountants: true,
          };
          $scope.COMPANY_TYPES = COMPANY_TYPES;

          const redirectToCorrectFamily = (familyId) => {
            const newStateParams = {
              ...$state.params,
              familyId,
            };
            !$scope.isLoanAppOnOpportunities &&
              $state.go('app.loanApplicationDetailsV3', newStateParams);
          };

          const checkTrustCompany = (trustCompany) => {
            const { FamilyId: familyId } = trustCompany[0];
            redirectToCorrectFamily(familyId);
          };

          const checkMainApplicant = (applicants) => {
            const flatListApplicants = _.flattenDeep(applicants);
            const flatListTrustCompanies = $scope.listTrustCompanies;
            if (
              flatListApplicants.length <= 1 &&
              flatListTrustCompanies.length
            ) {
              checkTrustCompany(flatListTrustCompanies);
            }
            if (!flatListApplicants.length) {
              return;
            }
            const { FamilyId: familyId } = flatListApplicants[0];
            const isCurrentFamilyIdPresent = !!flatListApplicants.filter(
              (obj) =>
                parseInt(obj.FamilyId, 10) ===
                parseInt($state.params.familyId, 10),
            ).length;
            const validForRedirection = !isCurrentFamilyIdPresent && familyId;
            if (validForRedirection) {
              redirectToCorrectFamily(familyId);
            }
          };

          const assignBrokerNotes = (brokerNotesResponse) =>
            assignApplicantBrokerNotesUtil($scope, brokerNotesResponse);
          const noteListInit = () => {
            noteListInitUtil({
              scope: $scope,
              loanScenarioModelService,
              assignBrokerNotes,
            });
          };
          noteListInit();

          $scope.saveBrokerNote = (subsect, content) => {
            loanScenarioService
              .brokerNotesSet($scope.loanAppId, subsect, content)
              .then(() => {
                toaster.pop('info', 'Note in this section was updated.');
              });
          };

          $scope.IdentificationSet = {};
          $scope.AddressHistorySet = {};
          $scope.EmploymentSet = {};

          const onDocumentClick = () => {
            $scope.selectedPartyType = false;
            $scope.isShowGuarantorContats = false;
            $scope.isShowBorrowerContact = false;
            $scope.isShowComapniesContact = false;
            $scope.isShowSoleTradersContact = false;
          };
          $document.on('click', onDocumentClick);
          $scope.$on('$destroy', () => {
            $document.off('click', onDocumentClick);
          });

          $scope.getLoanAppFormWarningValue = () => {
            return $scope.isLoanAppOnOpportunities
              ? $rootScope.file.LoanAppFormWarning
              : $rootScope.LoanAppFormWarning;
          };

          $scope.isWarning = (list) => {
            return $scope.getLoanAppFormWarningValue() && list.length < 1;
          };

          $scope.isShowGuarantorContats = false;
          $scope.isShowBorrowerContact = false;
          $scope.isShowComapniesContact = false;
          $scope.isShowSoleTradersContact = false;

          // Remove function below
          $scope.resetSearch = (type, searchType, event) => {
            if (event) {
              event.stopPropagation();
            }
            const selectedPartyType = String(type);
            $scope.selectedClientName = '';

            $scope.selectedPartyType = selectedPartyType;

            setSearchTypeToggles($scope, searchType);
            $scope.selectedClient = null;
            $scope.getClientList = [];
            $scope.soleTraderList = [];

            const applicantSearchTimeout = $timeout(() => {
              const el = $window.document.querySelectorAll('.applicant-search');
              angular.element(el).focus();
            }, 0);
            $scope.$on('$destroy', () => {
              $timeout.cancel(applicantSearchTimeout);
            });
          };
          $scope.resetSearch(0);

          $scope.getScenarioClientList(false);

          $scope.updateLoanAppSummary = () => {
            updateLoanAppSummary({ $scope, loanApplicationServices });
          };

          // Delete client
          $scope.deleteClient = (
            client,
            dontRefresh,
            partyApplicants,
            valueIndex,
            event,
          ) => {
            if (event) {
              event.stopPropagation();
            }

            const applicants = {
              guarantors: $scope.listGuarantors,
              entities: $scope.listTrustCompanies,
              borrowers: [...$scope.listApplicants],
              solicitors: $scope.listSolicitors,
              accountants: $scope.listAccountants,
              dependants: $scope.listDependants,
              soleTraders: $scope.listSoleTraders,
            };
            loanService
              .removeBorrowerApplicant(applicants, valueIndex, partyApplicants)
              .then(() => {
                // check if the client is applicant, trap it so we cannot remove the last applicant
                if (client.IsApplicant && client.Role === ROLE_TYPE.APPLICANT) {
                  const isNotValidApplicant =
                    $scope.listApplicants &&
                    $scope.listApplicants.length === 1 &&
                    $scope.listApplicants[0].length === 1;
                  const hasApplicants =
                    partyApplicants && partyApplicants.length;
                  if (hasApplicants) {
                    const result = _.find(
                      partyApplicants,
                      (applicant) => applicant.isIncluded,
                    );
                    const isNotValidPartyApplicant =
                      result &&
                      result.length === 1 &&
                      parseInt(result[0].PersonId, 10) ===
                        parseInt(client.PersonId, 10);
                    if (isNotValidPartyApplicant) {
                      toaster.pop(
                        'error',
                        'Error',
                        'Must have atleast 1 applicant',
                      );
                      return false;
                    }
                  } else if (isNotValidApplicant) {
                    toaster.pop(
                      'error',
                      'Error',
                      'Must have atleast 1 applicant',
                    );
                    return false;
                  }
                }

                // else delete
                loanScenarioService
                  .scenarioClientDelete(
                    $scope.loanAppId,
                    client.PersonId ? client.PersonId : client.OrganisationId,
                    !!client.PersonId,
                  )
                  .then(() => {
                    if (!dontRefresh) {
                      refreshApplicantSection($scope, $state);
                    }
                    /* LoanAppOnOpportunities */
                    opportunityLoanAppService.executeCallback(
                      'refreshOpportunityDetails',
                    );
                  });

                checkMainApplicant($scope.listApplicants);
              })
              .catch(() => {
                toaster.pop(
                  'warning',
                  'Error',
                  'You need at least 1 applicant',
                );
              });
          };

          $scope.refreshOnDeleteDependant = () => {
            refreshApplicantSection($scope, $state);
            opportunityLoanAppService.executeCallback(
              'refreshOpportunityDetails',
            );
          };
          $scope.searchSetting = {
            searchInterval: 3,
            name: '',
            searchtype: '',
          };
          // initiate Search Countdown

          $scope.makeShowSoleTraderlist = () => {
            $scope.showSoleTraderList = true;
          };

          $scope.searchSoleTrader = (searchString) => {
            if (!searchString || !$scope.showBetterEntity) {
              return;
            }
            $scope.isSearchingSoleTrader = true;
            const executeMapSoleTraderList = mapSoleTraderList({
              $scope,
              utilitiesService,
              searchString,
            });
            executeMapSoleTraderList(contactModelService);
          };

          $scope.selectSoleTrader = (soleTrader) => {
            if (!soleTrader) {
              return;
            }
            const { ClientId, PersonId } = soleTrader;
            soleTrader.AssignedLoanScenarioId = $scope.loanAppId;
            soleTrader.ClientEntityId = ClientId || PersonId;
            $scope.selectedClient = soleTrader;
            $scope.saveSelectedSoleTrader();
          };

          $scope.saveSelectedSoleTrader = () => {
            if (!$scope.selectedClient) {
              return;
            }
            const executeLinkedSoleTrader = linkedSoleTrader({
              $scope,
            });
            executeLinkedSoleTrader(loanScenarioService);
          };

          $scope.searchClient = (selectedClientName, searchTypes) => {
            $scope.isSearchingClients = true;
            $scope.searchSetting.name = selectedClientName;
            $scope.searchSetting.searchtype = searchTypes;

            $scope.getSearchedClients($scope.searchSetting.name);
          };

          // initiate api call
          $scope.getSearchedClients = (searchString) => {
            $scope.clientNotFound = false;

            if (searchString) {
              // Override : Some part type are equivalent to the same party type
              const partyType = getPartyType(
                $scope.searchSetting.searchtype,
                $scope.selectedPartyType,
              );
              $scope.isSearchingClients = true;
              loanScenarioService
                .scenarioSearchClientGet(
                  searchString,
                  partyType,
                  $stateParams.loanAppId,
                )
                .then((response) => {
                  $scope.isSearchingClients = false;
                  const data = response.data;
                  if (!data || !data.length) {
                    $scope.getClientList = [];
                    return;
                  }
                  $scope.getClientList = data.map((o) => {
                    o.initials =
                      o.FirstName && o.LastName
                        ? utilitiesService.filterInitial(
                            o.FirstName,
                            o.LastName,
                          )
                        : '';
                    return o;
                  });
                });
            }
          };

          $scope.saveFromModal = (toRefresh = false) => {
            $scope.selectedClient.AssignedLoanScenarioId = $scope.loanAppId;
            const { ClientEntityId } = $scope.selectedClient;
            const selectedClient =
              $scope.modalModel &&
              $scope.modalModel.find(
                (client) => client.PersonId === ClientEntityId,
              );
            if ($scope.showBetterEntity && selectedClient) {
              const { SoleTraderInfo } = selectedClient;
              if (!SoleTraderInfo) {
                $scope.saveSelectClient(toRefresh);
                return;
              }
              const { ForDeletion } = SoleTraderInfo;
              ForDeletion && $scope.saveSelectClient(toRefresh);
              !ForDeletion && $scope.saveSelectedSoleTrader();
              return;
            }
            $scope.saveSelectClient(toRefresh);
          };

          // save selected client to loan app
          $scope.saveSelectClient = (toRefresh) => {
            $scope.selectedClient.AssignedLoanScenarioId = $scope.loanAppId;
            $scope.selectedClient = updateClientDescription(
              $scope.selectedClient,
              $scope.selectedPartyType,
            );

            loanScenarioService
              .scenarioApplicantSet($scope.selectedClient)
              .then(
                () => {
                  /**
                   * commented out to avoid flooding of notification
                   */
                  if (toRefresh) {
                    $scope.resetSearch(0);
                    $scope.getScenarioClientList();

                    // initialize again the sections that have dependencies on applicants
                    $scope.loanAppSharedData.REFRESH_APPLICANT_DEPENDENT_SECTIONS = refreshApplicantDepentSection();
                    /* LoanAppOnOpportunities */
                    opportunityLoanAppService.executeCallback(
                      'refreshOpportunityDetails',
                    );
                  }
                  $scope.updateLoanAppSummary();
                },
                () => {
                  toaster.pop(
                    'warning',
                    'Unable to Add',
                    'Parties were not added, please try again',
                  );
                },
              );
          };

          // Select a client or loop through client from family id you've created from the create contact
          $scope.selectClient = (
            client,
            isFromAddNewModal,
            createdFamilyId,
            PartyType,
            excldIds,
          ) => {
            let excludedIds = [];
            if (excldIds) {
              excludedIds = excldIds;
            }

            if (!isFromAddNewModal && !createdFamilyId) {
              $scope.selectedClient = client;
              $scope.selectedClientName = `${$scope.selectedClient.FirstName} ${$scope.selectedClient.LastName}`;
              $scope.saveFromModal(true);
              if (
                $scope.selectedClient.FamilyID ===
                parseToInt10($state.params.familyId)
              ) {
                refreshApplicantSection($scope, $state);
              }
            } else if (isFromAddNewModal && createdFamilyId) {
              // PartyType
              $scope.selectedPartyType = PartyType;

              // everything except 2 which is a company

              if (
                parseInt(PartyType, 10) !== ENTITY_PARTY_TYPES.COMPANY &&
                parseInt(PartyType, 10) !== ENTITY_PARTY_TYPES.PARTNERSHIP
              ) {
                // Get all the clients you've created from the create contact
                contactService
                  .clientInformGet(createdFamilyId)
                  .then((response) => {
                    _.remove(response.data, (o) => {
                      return o.Role === 'Child';
                    });

                    // Get the whole size of clients
                    _.forEach(response.data, (obj) => {
                      // if client is to be excluded, decrement the count
                      const isUserExcluded = _.find(excludedIds, (id) => {
                        return id === obj.PersonId;
                      });
                      if (!isUserExcluded) {
                        $scope.selectedClient = {
                          ClientEntityId: obj.PersonId,
                          ClientTypeDesc: '',
                          ClientTypeId: '1',
                          FirstName: obj.FirstName,
                          LastName: obj.LastName,
                          PreferedName: obj.PreferredName,
                          IsClient: true,
                          ClientID: 0,
                        };
                        $scope.saveFromModal(true);
                      }
                    });
                    $scope.updateLoanAppSummary();
                  });
              } else {
                contactService
                  .organisationInfoGet(createdFamilyId)
                  .then((response) => {
                    $scope.selectedClient = {
                      ClientEntityId: response.data.OrganisationId,
                      ClientTypeDesc: response.data.OrganisationType,
                      ClientTypeId:
                        response.data.OrganisationType === 'Company' ||
                        response.data.OrganisationType === 'Trust'
                          ? '2'
                          : '7',
                      PreferedName: response.data.Name,
                      IsClient: false,
                      ClientID: 0,
                    };
                    $scope.saveSelectClient(true);
                  });
              }
            } else {
              toaster.pop('error', 'Ooops', 'Something went wrong');
              return false;
            }
          };

          optionsService.MaritalStatusGet().then(
            (response) => {
              $scope.maritalStatus = response.data;
            },
            () => {
              $scope.maritalStatus = [];
            },
          );

          optionsService.ResidencyStatusGet().then(
            (response) => {
              $scope.residencyStatus = response.data;
            },
            () => {
              $scope.residencyStatus = [];
            },
          );

          optionsService.countryListGet().then(
            (response) => {
              $scope.countryList = response.data;
            },
            () => {
              $scope.countryList = [];
            },
          );

          optionsService.PreferredContactMethodGet().then(
            (response) => {
              $scope.preferredContactMethod = response.data;
            },
            () => {
              $scope.preferredContactMethod = [];
            },
          );

          $scope.applicantModal = (type, person) => {
            const { LoanApplicationDetails } = loanAppSharedData;
            const adviserId =
              LoanApplicationDetails && LoanApplicationDetails.AdviserId;

            const modalInstance = $uibModal.open({
              templateUrl:
                '/assets/views/loanApplication/clientAssess/modal/addApplicants_v2.html',
              controller: 'ApplicantModalCtrl',
              scope: $scope,
              size: 'lg',
              resolve: {
                selectedPartyType() {
                  return String(type);
                },
                person() {
                  return person;
                },
                type() {
                  return type;
                },
                requester() {
                  return null;
                },
                listApplicants() {
                  return null;
                },
                listSoleTraders() {
                  return null;
                },
                assignedAdviserIdFromRequester() {
                  return adviserId;
                },
              },
              backdrop: 'static',
              keyboard: false,
            });

            modalInstance.result.then(
              (response) => {
                $scope.selectClient(
                  {},
                  true,
                  response.familyId,
                  response.PartyType,
                  response.excludedIds,
                );
                const { partyApplicants } = response;
                $scope.modalModel = partyApplicants || [];
                $scope.validateChanges && $scope.validateChanges();
              },
              () => {
                $rootScope.$broadcast('REFRESH_RELATED_CLIENTS');
              },
            );
          };
          // Add Related Modal
          $scope.companyRelatedPartyModal = (type, person) => {
            const familyId = _.get(person, 'FamilyId');
            const guardianList = familyId
              ? $scope.InvolvedPartyPerson[familyId]
              : [];
            const modalInstance = $uibModal.open({
              templateUrl:
                '/assets/views/loanApplication/clientAssess/modal/personCompany.add.html',
              controller: 'AddCompanyRelatedModalCtrl',
              scope: $scope,
              size: 'md',
              resolve: {
                selectedPartyType() {
                  return String(type);
                },
                person() {
                  return person;
                },
                requester() {
                  return REQUESTER.STANDARD;
                },
                guardianList: () => guardianList,
              },
              backdrop: 'static',
              keyboard: false,
            });

            modalInstance.result.then(
              (response) => {
                opportunityLoanAppService.executeCallback(
                  'refreshOpportunityDetails',
                );
                if (!response.isEdit) {
                  $scope.selectClient(
                    {},
                    true,
                    response.FamilyId,
                    response.PartyType,
                  );
                } else {
                  $scope.getScenarioClientList();
                }
                $scope.validateChanges && $scope.validateChanges();
              },
              () => {
                $rootScope.$broadcast('REFRESH_RELATED_CLIENTS');
              },
            );
          };

          const getCompanyId = (type) => {
            switch (type) {
              case COMPANY_TYPES.COMPANY_STRING:
                return COMPANY_TYPES.COMPANY;
              case COMPANY_TYPES.TRUST_STRING:
                return COMPANY_TYPES.TRUST;
              case COMPANY_TYPES.PARTNERSHIP_STRING:
                return COMPANY_TYPES.PARTNERSHIP;
              case COMPANY_TYPES.SMSF_STRING:
                return COMPANY_TYPES.SMSF;
              default:
            }
          };

          $scope.EntityModal = (type, entity = null) => {
            const selectedCompanyType = getCompanyId(type);
            const modalInstance = $uibModal.open({
              templateUrl:
                '/assets/views/loanApplication/clientAssess/modal/addTrust.html',
              controller: 'AddTrustCtrl',
              controllerAs: 'vm',
              size: 'md',
              resolve: {
                selectedPartyType() {
                  return parseInt(selectedCompanyType, 10);
                },
                entity() {
                  return entity;
                },
              },
              backdrop: 'static',
              keyboard: false,
            });

            modalInstance.result.then(
              (response) => {
                if (!response.isEdit) {
                  $scope.selectedPartyType = ENTITY_PARTY_TYPES.COMPANY;
                  $scope.selectClient(
                    {},
                    true,
                    response.FamilyId,
                    response.PartyType,
                  );
                } else {
                  $scope.getScenarioClientList();
                  $scope.validateChanges && $scope.validateChanges();
                }
              },
              () => {
                $rootScope.$broadcast('REFRESH_RELATED_CLIENTS');
              },
            );
          };

          $scope.addCompany = () => {
            $scope.companyRelatedPartyModal(ENTITY_PARTY_TYPES.COMPANY);
          };

          $scope.calculateAge = (dob) => {
            // Check first if DOB is valid date
            if (moment(dob, 'DD/MM/YYYY', true).isValid()) {
              $scope.Dependants.Age = moment().diff(
                moment(dob, 'DD-MM-YYYY'),
                'years',
              );
            } else {
              $scope.Dependants.Age = 0;
            }
          };

          $scope.calculateDOB = () => {
            $scope.Dependants.DOBGet = ageToDOBGet($scope.Dependants.Age);
          };

          // Add Dependants button
          $scope.addDependants = ({ $invalid, $error }) => {
            if ($invalid) {
              $error.required.forEach((obj) => obj.$setDirty());
              return;
            }
            $scope.isAddingDependants = true;
            $scope.Dependants.PersonId = '0';
            if (!$scope.Dependants.DOBGet) {
              $scope.Dependants.DOBGet = ageToDOBGet($scope.Dependants.Age);
            }

            $scope.Dependants.DOB = $scope.Dependants.DOBGet.split('/')
              .reverse()
              .join('-');

            contactService
              .familyInfoSet([$scope.Dependants])
              .then((response) => {
                const PersonId = response.data;
                const { ParentGuardianId, FamilyId } = $scope.Dependants;
                $scope.selectedPartyType = PERSON_PARTY_TYPES.DEPENDANTS;
                $scope.Dependants.ClientEntityId = PersonId;

                $scope.selectedClient = {
                  FamilyID: $scope.familyId,
                  ClientEntityId: PersonId,
                  FirstName: $scope.Dependants.FirstName,
                  LastName: ' ',
                  PreferedName: '',
                  IsClient: true,
                  AssignedLoanScenarioId: $scope.loanAppId,
                  IsApplicant: false,
                  IsGuarantor: false,
                  IsSolicitor: false,
                  IsAccountant: false,
                  AddRelatedChildren: false,
                  Age: $scope.Dependants.Age,
                  DOB: $scope.Dependants.DOB,
                  DOBGet: $scope.Dependants.DOBGet,
                };

                $scope.Dependants = $scope.getNewContactModel('Child');

                $scope.selectedClientName = $scope.selectedClient.FirstName;

                if (dependentUplift) {
                  const payloadToggle = {
                    loanScenarioID: $scope.loanAppId,
                    familyID: FamilyId,
                    clientID: PersonId,
                    includedInLoanApp: true,
                  };
                  toggleDependantLoanAppInclusion({
                    dependantModelService,
                    payload: payloadToggle,
                  });
                  const payloadParent = {
                    dependantClientID: PersonId,
                    parentGuardianClientID: ParentGuardianId,
                  };
                  return setDependantParent({
                    $scope,
                    dependantModelService,
                    payload: payloadParent,
                    opportunityLoanAppService,
                    dependentsWithOneClientAsParent,
                  });
                }

                $scope.saveSelectClient(1);
              });
          };

          $scope.deleteDependant = (
            dependant,
            dontRefresh,
            partyApplicants,
            valueIndex,
          ) => {
            $scope.deleteClient(
              dependant,
              dontRefresh,
              partyApplicants,
              valueIndex,
            );
          };

          /**
           *   Section : Identification
           */

          // method
          angular.extend($scope, {
            GetFamilyIdentityStatus() {
              loanScenarioService
                .FamilyIdentityStatusGet(
                  $scope.loanAppId,
                  $scope.familyIdentification.FamilyId,
                )
                .then((response) => {
                  // zero out FamilyIdentityCheckId first
                  angular.extend($scope.familyIdentityStatusSet, {
                    FamilyIdentityCheckId: 0,
                  });

                  angular.extend($scope.familyIdentityStatusSet, response.data);
                });
            },
            SetFamilyIdentityStatus() {
              loanScenarioService
                .FamilyIdentityStatusSet($scope.familyIdentityStatusSet)
                .then(() => {
                  $scope.GetFamilyIdentityStatus();
                });
            },
            changeIdentificationFamily(familyId) {
              $scope.SetFamilyIdentification(false, familyId);
            },
            identificationModal(modalType, identification) {
              $scope.getFamilyForIdentification(true).then((response) => {
                const familyId = response;
                identificationService
                  .addEditIdentificationModal({
                    modalType,
                    identification,
                    familyId,
                    documentTypeList: $scope.documentTypeList,
                    loanAppId: $scope.loanAppId,
                  })
                  .then(() => {
                    $scope.GetIdentification(familyId);
                    runValidation();
                  });
              });
            },
            deleteIdentification(identificationId) {
              SweetAlert.swal(
                {
                  title: 'Are you sure?',
                  text:
                    'This record will be removed from your identification list',
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#F68F8F',
                  confirmButtonText: 'Yes, remove it!',
                  closeOnConfirm: false,
                },
                (confirm) => {
                  if (confirm) {
                    loanScenarioService
                      .IdentificationDelete(identificationId)
                      .then(() => {
                        $timeout(() => {
                          $scope.GetIdentification();
                          runValidation();
                          SweetAlert.swal({
                            title: 'Success',
                            text:
                              'Identification has been been successfully deleted.',
                            type: 'success',
                            timer: 2000,
                            showConfirmButton: false,
                          });
                        }, 100);
                        $scope.validateApplicants();
                      });
                  }
                },
              );
            },
            changeIdentificationClient(list) {
              if (
                !angular.isUndefined($scope.IdentificationSet.ClientEntityId)
              ) {
                const clientEntity = _.find(list, (o) => {
                  return o.PersonId === $scope.IdentificationSet.ClientEntityId;
                });

                $scope.IdentificationSet.NameOnDoc = getClientFullName(
                  clientEntity,
                );
              } else {
                $scope.IdentificationSet.NameOnDoc = '';
              }
            },
          });
          $scope.identificationInit();
          $scope.getDocType();

          $scope.$watch('familyIdentification.FamilyId', (nv) => {
            if (!$scope.familyIdentityStatusSet) {
              return;
            }
            angular.extend($scope.familyIdentityStatusSet, {
              FamilyId: String(nv),
            });

            $scope.GetFamilyIdentityStatus();
          });

          $scope.$watch(
            'loanAppSharedData.REFRESH_APPLICANT_DEPENDENT_SECTIONS',
            (nv) => {
              if (nv && _.size(nv) > 0 && nv.RefreshApplication) {
                $scope.identificationInit();
                nv.RefreshApplication = false;

                $scope.isAddingDependants = false;
              }
            },
          );
          const duration = performance.endPaint(LOAN_APP_APPLICANTS);
          loanAppSectionData({
            label: LOAN_APP_APPLICANTS,
            value: duration,
          });
        };
        $scope.SetFamilyIdentification = (getFirst, familyId) => {
          let family = {};
          if (getFirst) {
            family = _.head($scope.allIdentifications);
          } else {
            family = _.find($scope.allIdentifications, (o) => {
              return parseInt(o.FamilyId, 10) === parseInt(familyId, 10);
            });
          }

          // Assign background color to $scope.familyIdentification.FamilyId
          $scope.getBGcolor($scope.familyIdentification.FamilyId);
          angular.extend($scope.familyIdentification, family);
          $scope.familyIdentification.FamilyId = String(
            $scope.familyIdentification.FamilyId,
          );
          $scope.familyIdentification.initials = utilitiesService.filterInitialOneString(
            family.FamilyName,
          );
          $scope.familyIdentification.background = $scope.getBGcolor(
            $scope.familyIdentification.FamilyId,
          );

          _.map($scope.familyIdentification.Identification, (o) => {
            o.ClientInitials = utilitiesService.filterInitialOneString(
              `${o.ClientFirstName} ${o.ClientLastName}`,
            );

            // get the background assigned to FamilyId
            o.background = $scope.familyIdentification.background;
            return o;
          });
        };
        $scope.identificationInit = (forceValidate) => {
          $scope.getFamilyForIdentification(true).then((response) => {
            $scope.familyIdentityStatusSet = $scope.getFamilyIdentityStatusModel();
            $scope.allIdentificationFamilies =
              $scope.allIdentificationFamilies || [];
            $scope.familyIdentification = {};
            $scope.familyIdentification.FamilyId = response;
            $scope.familyIdentification.Identification = [];
            // call other apis
            $scope.GetIdentification(null, forceValidate);
            $scope.getDocType();
          });
        };
        $scope.getFamilyIdentityStatusModel = () => {
          return {
            LoanApplicationId: $scope.loanAppId,
            FamilyId: $scope.familyId,
            FamilyIdentityCheckId: 0,
            FaceToFaceIdentityCheck: false,
            IsExistingCustomer: false,
          };
        };
        $scope.GetIdentification = (selectedFamilyId, initialize) => {
          loanScenarioService
            .IdentificationGet($scope.loanAppId)
            .then((response) => {
              $scope.allIdentificationFamilies = [];
              $scope.allIdentifications = response.data;
              if (
                ($scope.listApplicants && $scope.listApplicants.length) ||
                ($scope.listGuarantors && $scope.listGuarantors.length)
              ) {
                $scope.populateIdentification(selectedFamilyId, initialize);
              } else {
                $scope.getFamilyForIdentification(true).then((res) => {
                  $scope.familyIdentification.FamilyId = res;
                  if (initialize) {
                    $scope.populateIdentification(selectedFamilyId, initialize);
                  }
                });
              }
            });
        };
        $scope.populateIdentification = (selectedFamilyId, validate) => {
          const tempList = [];
          $scope.listApplicants &&
            $scope.listApplicants.map((applicant) => {
              return applicant.map((o) => {
                tempList.push({
                  FamilyId: o.FamilyId,
                  FamilyName: o.FamilyFullName,
                  background: $scope.getBGcolor(o.FamilyId),
                  initials: utilitiesService.filterInitialOneString(
                    o.FamilyFullName,
                  ),
                  listPriority: o.IsApplicant ? 0 : 1,
                });
                return tempList;
              });
            });
          $scope.listGuarantors &&
            // eslint-disable-next-line sonarjs/no-identical-functions
            $scope.listGuarantors.map((applicant) => {
              // eslint-disable-next-line sonarjs/no-identical-functions
              return applicant.map((o) => {
                tempList.push({
                  FamilyId: o.FamilyId,
                  FamilyName: o.FamilyFullName,
                  background: $scope.getBGcolor(o.FamilyId),
                  initials: utilitiesService.filterInitialOneString(
                    o.FamilyFullName,
                  ),
                  listPriority: o.IsApplicant ? 0 : 1,
                });
                return tempList;
              });
            });

          if (tempList && tempList.length) {
            const uniqIdentificationFamilies = _.uniqBy(tempList, 'FamilyId');
            $scope.allIdentificationFamilies = _.orderBy(
              uniqIdentificationFamilies,
              ['listPriority'],
              ['asc'],
            );
          }

          if (selectedFamilyId) {
            $scope.SetFamilyIdentification(false, selectedFamilyId);
          } else {
            $scope.SetFamilyIdentification(true);
          }
          if (validate) {
            $scope.validateApplicants();
          }
        };
        $scope.getFamilyForIdentification = (idOnly = false) => {
          return $scope.getScenarioClientList().then(() => {
            const guarantorsLen =
              $scope.listGuarantors && $scope.listGuarantors.length;
            const firstGuarantor = guarantorsLen ? $scope.listGuarantors[0] : 0;
            const family = Object.keys($scope.familyIdentification).length
              ? $scope.familyIdentification
              : firstGuarantor;
            return idOnly ? family.FamilyId : family;
          });
        };
        $scope.getScenarioClientList = (isRefresh = true) => {
          return $scope.loanAppSharedData
            .getLoanAppApplicants($scope.loanAppId, isRefresh)
            .then((respond) => {
              if (!respond || !respond.data) {
                return;
              }
              let listAllParties = [];

              $scope.InvolvedPartyPerson = respond.data.InvolvedPartyPerson;
              $scope.Dependent = respond.data.Dependent;

              $scope.DependantsFamilyDropdown = [];

              if (_.size($scope.InvolvedPartyPerson) > 0) {
                _.forEach($scope.InvolvedPartyPerson, (family) => {
                  // get the first person in the family
                  const first = _.first(family);
                  $scope.DependantsFamilyDropdown.push({
                    FamilyId: first.FamilyId,
                    FamilyFullName: first.FamilyFullName,
                  });
                });

                // Assign the first object's family id to dependants
                $scope.Dependants.FamilyId = _.first(
                  $scope.DependantsFamilyDropdown,
                ).FamilyId;
              }

              Object.keys($scope.InvolvedPartyPerson).forEach((key) => {
                $scope.InvolvedPartyPerson[key].forEach((people) => {
                  people.initials = utilitiesService.filterInitial(
                    people.FirstName,
                    people.LastName,
                  );
                  people.background =
                    $scope.loanAppSharedData.backgroundList[
                      people.FamilyId.toString()
                    ] || $scope.getBGcolor(people.FamilyId);

                  listAllParties.push(people);
                });
              });

              $scope.listApplicants = getListApplicants(
                $scope.InvolvedPartyPerson,
              );
              $scope.listSoleTraders = getListSoleTraders(
                $scope.InvolvedPartyPerson,
              );
              if ($scope.listApplicants.length === 1) {
                $scope.originalApplicants = {
                  ...$scope.listApplicants[0],
                };
                $scope.originalApplicantsId =
                  $scope.originalApplicants[0] &&
                  $scope.originalApplicants[0].FamilyId;
              }
              loanAppSharedData.listApplicants = $scope.listApplicants;
              // Filter then put background on each COMPANIES
              const companies = _.map(
                ($scope.listTrustCompanies = _.filter(
                  respond.data.InvolvedPartyEntity,
                  (obj) => {
                    return obj.IsApplicant;
                  },
                )),
                (o) => {
                  o.initials = utilitiesService.filterInitialOneString(
                    o.FamilyFullName,
                  );
                  o.background =
                    $scope.loanAppSharedData.backgroundList[
                      o.FamilyId.toString()
                    ] ||
                    $scope.getBGcolor(o.FamilyId) ||
                    colorService.getRandomColor();

                  return o;
                },
              );
              if (companies && companies.length) {
                listAllParties = [...listAllParties, ...companies];
              }
              const mappedEntityGuarantor = getMappedEnityForGuarantors({
                $scope,
                utilitiesService,
                colorService,
              })(respond.data.InvolvedPartyEntity);
              $scope.listGuarantors = getListGuarantors(
                $scope.InvolvedPartyPerson,
                mappedEntityGuarantor,
              );

              loanAppSharedData.listGuarantors = $scope.listGuarantors;
              loanAppSharedData.updateServiceabilityDropdown();
              $scope.listDependants = getListDependents($scope.Dependent);
              blueStoneSubmissionService.mapListOfDependents();
              const dependents = $scope.listDependants.map((o) => {
                if (o.FamilyId) {
                  o.initials = utilitiesService.filterInitialOneString(
                    o.PreferredName,
                  );
                  o.background =
                    $scope.loanAppSharedData.backgroundList[
                      o.FamilyId.toString()
                    ] || $scope.getBGcolor(o.FamilyId);
                }

                return o;
              });
              if (dependents && dependents.length) {
                listAllParties = [...listAllParties, ...dependents];
              }

              if (_.size($scope.listDependants) > 0) {
                $scope.Dependants.NoDependants = false;
              }

              const solicitorsAndAccountantAddBackgroundInitials = (object) => {
                object.initials = utilitiesService.filterInitial(
                  object.FirstName,
                  object.LastName,
                );
                object.background =
                  $scope.loanAppSharedData.backgroundList[
                    object.FamilyId.toString()
                  ] || $scope.getBGcolor(object.FamilyId);

                return object;
              };
              // Filter then put background on each SOLICITOR
              $scope.listSolicitors = getListSolicitors(
                respond.data.RelatedParty,
              );
              const solicitors = $scope.listSolicitors.map((o) => {
                return solicitorsAndAccountantAddBackgroundInitials(o);
              });
              if (solicitors && solicitors.length) {
                listAllParties = [...listAllParties, ...solicitors];
              }

              if (_.size($scope.listSolicitors) > 0) {
                $scope.RelatedPartySettings.NoSolicitors = false;
              }

              // Filter then put background on each ACCOUNTANTS
              $scope.listAccountants = getListAccountants(
                respond.data.RelatedParty,
              );
              const accountants = $scope.listAccountants.map((o) => {
                return solicitorsAndAccountantAddBackgroundInitials(o);
              });
              if (accountants && accountants.length) {
                listAllParties = [...listAllParties, ...accountants];
              }

              if (_.size($scope.listAccountants) > 0) {
                $scope.RelatedPartySettings.NoAccountants = false;
              }

              $scope.listAssetSuppliers = getListAssetSuppliers(
                respond.data.RelatedParty,
              );
              const assetSuppliers = $scope.listAssetSuppliers.map((obj) =>
                solicitorsAndAccountantAddBackgroundInitials(obj),
              );
              if ($scope.assetSuppliers && $scope.assetSuppliers.length) {
                listAllParties = [...listAllParties, ...assetSuppliers];
              }

              $scope.loanAppSharedData.entityOnlyApplicant =
                !_.size($scope.listApplicants) &&
                !_.size($scope.listGuarantors) &&
                _.size($scope.listTrustCompanies) > 0;
              const involvedParties = {
                listApplicants: $scope.listApplicants,
                listGuarantors: $scope.listGuarantors,
                listTrustCompanies: $scope.listTrustCompanies,
                listAccountants: $scope.listAccountants,
                listSolicitors: $scope.listSolicitors,
                listDependants: $scope.listDependants,
              };
              if (dependentUplift) {
                return getLoanDependants({
                  $scope,
                  dependantModelService,
                  involvedParties,
                  utilitiesService,
                });
              }
              return involvedParties;
            });
        };
        $scope.getNewContactModel = (role) => {
          const countryCode = applicationSharedService.countryCodeAndBirthday(
            uiService.getCrmCountry(),
          );
          const countryOfBirth = applicationSharedService.countryCodeAndBirthday(
            uiService.getCrmCountry(),
          );
          const familyId = '0';
          return contactSharedDataService.getContactDataModel(
            role,
            countryCode,
            countryOfBirth,
            familyId,
          );
        };
        $scope.countApplicants = () => {
          // @return completed/started/false
          const listAllParties = _.size($scope.listApplicants);
          const listDependants = _.size($scope.listDependants);

          const listTrustCompanies = _.size($scope.listTrustCompanies);
          const listGuarantors = _.size($scope.listGuarantors);

          const listSolicitors = _.size($scope.listSolicitors);
          const listAccountants = _.size($scope.listAccountants);

          return (
            listAllParties +
            listDependants +
            listTrustCompanies +
            listGuarantors +
            listSolicitors +
            listAccountants
          );
        };

        /**
         * Validation for Section
         */
        const runValidation = () => {
          const { entityOnlyApplicant } = $scope.loanAppSharedData;
          const involvedPartiesValidation =
            _.size($scope.listApplicants) + _.size($scope.listTrustCompanies) >
            0;
          const startedInvolvedPartiesValidation = $scope.countApplicants() > 0;
          const employmentValidation =
            ($scope.hasEmployment && $scope.isEmploymentYearsValid) ||
            entityOnlyApplicant;
          const addressHistoryValidation = $scope.allHaveAddressHistory;
          const identificationValidation = hasFullApplicantsIdentification({
            applicantsToCheck: $scope.allIdentificationFamilies,
            allIdentifications: $scope.allIdentifications,
            entityOnlyApplicant,
            country: uiService.getCrmCountry(),
          });
          $scope.identificationValidation = identificationValidation;
          if (uiService.isCountry('New Zealand')) {
            $scope.isValidApplicants =
              (involvedPartiesValidation &&
                employmentValidation &&
                addressHistoryValidation) ||
              ($scope.loanAppSharedData.entityOnlyApplicant &&
                addressHistoryValidation);
            $scope.isStartedApplicants =
              startedInvolvedPartiesValidation ||
              employmentValidation ||
              addressHistoryValidation;
          } else {
            $scope.isValidApplicants =
              (involvedPartiesValidation &&
                employmentValidation &&
                addressHistoryValidation &&
                identificationValidation) ||
              ($scope.loanAppSharedData.entityOnlyApplicant &&
                addressHistoryValidation);
            $scope.isStartedApplicants =
              startedInvolvedPartiesValidation ||
              employmentValidation ||
              addressHistoryValidation ||
              identificationValidation;
          }

          $scope.countValids($scope.isValidApplicants, 'applicants');

          setCompletionStatus({
            $scope,
            $timeout,
            sectionId: SECTION_ID.APPLICANTS,
            loanAppSharedData: $scope.loanAppSharedData,
            isValidSection: $scope.isValidApplicants,
          });
        };
        $scope.validateApplicants = () => {
          const isApplicantsSectionReady =
            ($scope.employmentsLoaded &&
              $scope.addressHistoryLoaded &&
              $scope.identificationsLoaded) ||
            $scope.loanAppSharedData.entityOnlyApplicant;
          if (!isApplicantsSectionReady) {
            return;
          }
          if ($scope.clientListLoaded) {
            runValidation();
          } else {
            $scope.getScenarioClientList().then(() => {
              runValidation();
              $scope.clientListLoaded = true;
            });
          }
        };
        $scope.getDocType = () => {
          optionsService.identificationDocumentsTypeGet().then(({ data }) => {
            $scope.documentTypeList = data || [];
          });
        };

        // eslint-disable-next-line unicorn/consistent-function-scoping
        const returnListApplicantsGuarantors = (listApplicants) => {
          return {
            applicants: getApplicantList(listApplicants),
            guarantors: getGuarantorList(listApplicants),
          };
        };

        const sectionObject = $scope.loanAppSharedData.getSectionObjectById(
          $scope.loanAppSharedData.completionListObject,
          SECTION_ID.APPLICANTS,
        );
        $scope.countValids(sectionObject.IsCompleted, 'applicants');

        const applicantGuarantor = returnListApplicantsGuarantors(
          $scope.loanAppSharedData.loanAppSummary.ApplicantGuarantorList
            .InvolvedPartyPerson,
        );
        const applicants = applicantGuarantor.applicants;
        const guarantors = applicantGuarantor.guarantors;
        $scope.isValidApplicants = sectionObject.IsCompleted;
        $scope.listApplicants = [];
        $scope.listGuarantors = [];
        $scope.listApplicants[0] = $scope.loanAppSharedData.populateBackgroundForApplicantSection(
          applicants,
          $scope.getBGcolor,
        );
        $scope.listGuarantors[0] = $scope.loanAppSharedData.populateBackgroundForApplicantSection(
          guarantors,
          $scope.getBGcolor,
        );
        $scope.listTrustCompanies = $scope.loanAppSharedData.populateBackgroundForApplicantSection(
          $scope.loanAppSharedData.loanAppSummary.ApplicantGuarantorList
            .InvolvedPartyEntity,
          $scope.getBGcolor,
        );
        $scope.isStartedApplicants = true;

        const lendingCategoryId = getLendingCategoryId(
          $scope.loanAppSharedData,
        );
        const isAssetOrPersonal = ASSET_PERSONAL_CATEGORY.includes(
          parseInt(lendingCategoryId, 10),
        );
        $scope.showTradeReference =
          $scope.isAssetFinanceActive &&
          isAssetOrPersonal &&
          currentUserService.isAU;

        /**
         *  Validates every update on object
         */
        const listApplicantsWatcher = $scope.$watch(
          'listApplicants',
          (nv, ov) => {
            $scope.validateApplicants();
            $scope.loanAppSharedData.listApplicantsUpdated =
              ov && nv && nv.length;
            $scope.loanAppSharedData.listApplicants = nv;
          },
        );

        const loanAppApplicantsWatcher = $scope.$watch(
          'loanAppSharedData.loanAppApplicants',
          (nv) => {
            $scope.validateApplicants();
            const {
              InvolvedPartyEntity: entities,
              InvolvedPartyPerson: persons,
              RelatedParty: relatedParty,
            } = nv || {};
            $scope.isListApplicantsLoaded =
              !!(entities && entities.length) ||
              !!(persons && Object.keys(persons).length) ||
              !!(relatedParty && relatedParty.length);
          },
        );

        const updateEmploymentWatcher = $scope.$watch(
          'loanAppSharedData.UPDATE_EMPLOYMENT',
          (nv) => {
            if (!nv) {
              return;
            }
            $scope.employmentsLoaded = true;
            if (_.size(nv) > 0) {
              $scope.isEmploymentYearsValid = nv.invalidCount < 1;
              $scope.hasEmployment = nv.hasEmployment;
              $scope.validateApplicants();
            } else {
              $scope.validateApplicants();
            }
          },
          true,
        );

        const updateAddressWatcher = $scope.$watch(
          'loanAppSharedData.UPDATE_ADDRESS',
          (nv) => {
            if (typeof nv === 'undefined') {
              return;
            }
            $scope.addressHistoryLoaded = true;
            $scope.allHaveAddressHistory = hasFullApplicantsAddressHistory(
              loanAppSharedData,
            );
            $scope.validateApplicants();
          },
          true,
        );

        const allIdentificationsWatcher = $scope.$watch(
          'allIdentifications',
          (nv) => {
            if (typeof nv === 'undefined') {
              return;
            }
            $scope.identificationsLoaded = true;
            $scope.validateApplicants();
          },
          true,
        );

        $scope.$on('$destroy', () => {
          if (listApplicantsWatcher) {
            listApplicantsWatcher();
          }
          if (updateEmploymentWatcher) {
            updateEmploymentWatcher();
          }
          if (updateAddressWatcher) {
            updateAddressWatcher();
          }
          if (allIdentificationsWatcher) {
            allIdentificationsWatcher();
          }
          if (loanAppApplicantsWatcher) {
            loanAppApplicantsWatcher();
          }
        });

        $scope.isApplicantsSectionReady = false;
        $scope.Dependants = $scope.getNewContactModel('Child');
        $scope.Dependants.NoDependants = true;
        $scope.identificationInit(true);
      };

      $scope.$watch('isLoanAppLoaded', () => {
        if (!$scope.isLoanAppLoaded) {
          return;
        }
        initializeApplicant();
      });
    });
