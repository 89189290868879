import moment from 'moment';
import {
  TO_MYCRM_DATE_FORMAT,
  DATE_TEXT_FORMAT,
} from 'Common/constants/dateConfigs';

export function resourceMyDocumentsBuilderForUI(data) {
  return {
    documentFileID: data.DocumentFileID,
    documentID: data.DocumentID,
    title: data.Title,
    writtenBy: data.WrittenBy,
    dateUploaded: moment(data.DateUploaded, `${TO_MYCRM_DATE_FORMAT} `).format(
      DATE_TEXT_FORMAT,
    ),
    description: data.Description,
    version: data.Version,
  };
}

export function resourceMyDocumentsBuilderForMyCRM(data) {
  return {
    description: data.description,
    documentFileId: data.documentFileID,
    documentId: data.documentID,
    title: data.title,
    version: data.version,
  };
}
export function maximumLVRBuilderForUI(maximumLVR) {
  return {
    bridging: maximumLVR.Bridging,
    commercial: maximumLVR.Commercial,
    companyShare: maximumLVR.CompanyShare,
    construction: maximumLVR.Construction,
    countryCode: maximumLVR.CountryCode,
    equity: maximumLVR.Equity,
    equityFinance: maximumLVR.EquityFinance,
    holidayHome: maximumLVR.HolidayHome,
    investment: maximumLVR.Investment,
    loc: maximumLVR.LOC,
    leasehold: maximumLVR.Leasehold,
    lenderID: maximumLVR.LenderID,
    lenderName: maximumLVR.LenderName,
    lifestyleBlockL10ha: maximumLVR.LifestyleBlockL10ha,
    lifestyleBlockL15haVNS: maximumLVR.LifestyleBlockL15haVNS,
    lifestyleBlockL15haVS: maximumLVR.LifestyleBlockL15haVS,
    luxury: maximumLVR.Luxury,
    nonStandardApartment: maximumLVR.NonStandardApartment,
    ownerOccupied: maximumLVR.OwnerOccupied,
    partialBuildContract: maximumLVR.PartialBuildContract,
    pensionerflatsretirementvillage: maximumLVR.Pensionerflatsretirementvillage,
    propertyDevelopment: maximumLVR.PropertyDevelopment,
    residentialLeaseholdProperty: maximumLVR.ResidentialLeaseholdProperty,
    reverse: maximumLVR.Reverse,
    ruralLifestyleBlocks10ha: maximumLVR.RuralLifestyleBlocks10ha,
    ruralLifestyleG10: maximumLVR.RuralLifestyleG10,
    standardApartment: maximumLVR.StandardApartment,
    standardApartmentsL50: maximumLVR.StandardApartmentsL50,
    standardResidential: maximumLVR.StandardResidential,
    standardResidentialNonResident: maximumLVR.StandardResidentialNonResident,
    terracedHousing: maximumLVR.TerracedHousing,
    vacantLand: maximumLVR.VacantLand,
    vacantLandServiced: maximumLVR.VacantLandServiced,
    vacantLandUnserviced: maximumLVR.VacantLandUnserviced,
    smsf: maximumLVR.SMSF,
  };
}

export function offsetPaymentChartBuilderForUI(data) {
  return {
    principalToPaywithOffset: data.PrincipalToPaywithOffset,
    principalToPaywithoutOffset: data.PrincipalToPaywithoutOffset,
  };
}

export function repaymentHistoryBuilderForUI(data) {
  return {
    period: data.Period,
    repayment: data.Repayment,
    principalPaid: data.PrincipalPaid,
    interestPaid: data.InterestPaid,
    accumulativePrincipalPaid: data.AccumulativePrincipalPaid,
    accumulativeInterestPaid: data.AccumulativeInterestPaid,
    principalToPay: data.PrincipalToPay,
  };
}

export function offsetCalculatorBuilderForUI(data) {
  return {
    offsetPaymentChart: data.OffsetPaymentChart.map(
      offsetPaymentChartBuilderForUI,
    ),
    repaymentHistory: data.RepaymentHistory.map(repaymentHistoryBuilderForUI),
    interestDifference: data.InterestDifference,
    periodDifference: data.PeriodDifference,
  };
}

export function discountTimePeriodBuilderForUI(timePeriod) {
  return {
    name: timePeriod.Name,
    value: +timePeriod.Value,
  };
}

export function educationBuilderForUI(data) {
  return {
    id: data.EducationId,
    title: data.CourseTitle,
    structured: data.StructuredHours,
    unstructured: data.UnStructuredHours,
    provider: data.Provider,
    date: data.DateTaken
      ? moment(data.DateTaken, `${TO_MYCRM_DATE_FORMAT} `).format(
          DATE_TEXT_FORMAT,
        )
      : '',
    notes: data.Notes,
    documentId: data.DocumentId,
    documentName: data.DocumentFileName,
  };
}

export function educationBuilderForMyCRM(data) {
  return {
    EducationId: data.id,
    CourseTitle: data.title,
    StructuredHours: data.structured,
    UnStructuredHours: data.unstructured,
    Provider: data.provider,
    DateTaken: moment(data.date).format(TO_MYCRM_DATE_FORMAT),
    Notes: data.notes,
    DocumentId: data.documentId,
  };
}

export function qualificationBuilderForUI(data) {
  return {
    id: data.QualificationId,
    qualificationPresetId: data.QualificationPresetId,
    qualification: data.Qualification,
    provider: data.Provider,
    comments: data.Comments,
    date: data.Date
      ? moment(data.Date, `${TO_MYCRM_DATE_FORMAT} `).format(DATE_TEXT_FORMAT)
      : '',
    documentId: data.DocumentId,
    documentName: data.DocumentFileName,
  };
}

export function qualificationBuilderForMyCRM(data) {
  return {
    QualificationId: data.id,
    QualificationPresetId: data.qualificationPresetId,
    Provider: data.provider,
    Comments: data.comments,
    Date: moment(data.date).format(TO_MYCRM_DATE_FORMAT),
    DocumentId: data.documentId,
  };
}

export function qualificationPresetBuilderForUI(data) {
  return data.reduce((accum, curr) => {
    const {
      Heading: heading,
      QualificationHeadingId: headingId,
      QualificationPreset: presets = [],
    } = curr;

    return accum.concat(
      ...presets.map((preset) => ({
        heading,
        headingId,
        id: preset.PresetId,
        label: preset.Qualifications,
      })),
    );
  }, []);
}

export function corporateComplaintsBuilderForUI(data) {
  return {
    complaintId: data.ComplaintID,
    loggedBy: data.LoggedBy,
    adviserOrg: data.AdviserOrg,
    natureOfComplaint: data.NatureOfComplaint,
    complaint: data.ComplaintDetails,
    complainant: data.Complainant,
    complaintAgainst: data.ComplaintAgainst,
    status: data.StatusID === 1 ? 'OPEN' : 'CLOSED',
    corpComments: data.CorpComments,
    isInlineEdit: !data.CorpComments,
  };
}
