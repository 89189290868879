import angular from 'angular';
import { isValidNumberForRegion } from 'libphonenumber-js';

const MOBILE_NUMBER_CHAR = 6;
const AU_COUNTRY_CODE = '+61';
const NZ_COUNTRY_CODE = '+64';

const isTollFreeNumber = (phoneNumber) => {
  const firstDigit = phoneNumber[0];
  return Number.parseInt(firstDigit, 10) === 1;
};

const isValidAUMobileNumber = (phoneNumber) => {
  const formattedPhone = phoneNumber.replace(AU_COUNTRY_CODE, '');
  return formattedPhone.length === MOBILE_NUMBER_CHAR;
};

const formatAuMobilePhone = (phoneNumber) => {
  const phone = phoneNumber.replace(AU_COUNTRY_CODE, '');
  return `${phone.slice(0, 2)} ${phone.slice(2, 4)} ${phone.slice(4, 6)}`;
};

export const loadModule = () =>
  angular
    .module('app')
    .filter('formatPhoneNumber', function formatPhoneNumber($window) {
      return (phone) => {
        const validAUNumber =
          isValidNumberForRegion(phone, 'AU') ||
          phone.includes(AU_COUNTRY_CODE);
        const validNZNumber =
          isValidNumberForRegion(phone, 'NZ') ||
          phone.includes(NZ_COUNTRY_CODE);

        const isAuMobilePhone = isValidAUMobileNumber(phone);

        if (isAuMobilePhone) {
          return formatAuMobilePhone(phone);
        }

        if (!validAUNumber && !validNZNumber) {
          return phone;
        }
        const formatted = $window.intlTelInputUtils.formatNumber(
          phone,
          null,
          $window.intlTelInputUtils.numberFormat.INTERNATIONAL,
        );
        const withCountryCode = formatted
          .replace('-', ' ')
          .split(' ')
          .slice(1)
          .join(' ');
        const formattedNumber = isTollFreeNumber(withCountryCode)
          ? withCountryCode
          : `0${withCountryCode}`;
        return withCountryCode ? formattedNumber : phone;
      };
    });
