import { LOAN_APP_ENQUIRY_SOURCE } from 'Common/constants/referralType';
import { getLogger } from '@loanmarket/logger-core';
import {
  toastError,
  toastSaveSuccess,
  displayError,
  toastInfo,
  displayWarning,
  showActionConfirmation,
} from 'Common/utilities/alert';
import { INPUT_FIELDS_DEBOUNCE_TIMEOUT } from 'Common/constants/formSettings';
import { concatenateValidStrings } from 'Common/utilities/string';
import { parseToInt10 } from 'Common/utilities/parse';
import {
  isBoolean,
  isValidObjectValue,
} from 'Common/utilities/objectValidation';
import { ASSET_FINANCE_FEES } from 'Common/constants/cqpFees';
import { feesSet } from 'Assets/js/controllers/loanApp/util/clientAccess/loanFundingDetails';
import {
  updateBrokerageValues,
  copyBrokerageDocumentValues,
} from './util/generateCqpOptions';

const REFERRAL_INPUT_CHANGE_TYPE = {
  PERCENTAGE: 1,
  FEE: 2,
};

export default class GenerateCqpOptionsCtrl {
  constructor(
    uiService,
    loanScenarioService,
    loanScenarioModelService,
    currentUserService,
    fundingCalculatorService,
    loanProfilerService,
    loanApplicationServices,
    configService,
    referralService,
  ) {
    'ngInject';

    this.uiService = uiService;
    this.loanScenarioService = loanScenarioService;
    this.loanScenarioModelService = loanScenarioModelService;
    this.currentUserService = currentUserService;
    this.fundingCalculatorService = fundingCalculatorService;
    this.loanProfilerService = loanProfilerService;
    this.loanApplicationServices = loanApplicationServices;
    this.configService = configService;
    this.referralService = referralService;
  }

  $onInit() {
    this.inputFieldDebounceTimeout = INPUT_FIELDS_DEBOUNCE_TIMEOUT;
    this.referralChangeType = REFERRAL_INPUT_CHANGE_TYPE;
    this.cpaCqpSet.CQP.IsFees = false;
    this.newFee = {};
    this.isAddFee = false;
    this.allFees = [];
    this.productFeesList = [];
    this.facility = [];
    this.isSaving = false;
    this.setupReferralCommissionModel();
    this.gamePlanCQPInit();
    this.displayWarning = displayWarning;
    this.productFinderEditEnhancements = !!this.configService.feature
      .productFinderEditEnhancements;
    this.isLoadedReferralInfo = false;
    this.disabledReferralInputFields = true;
    this.logger = getLogger('CreditQuoteModal');
    this.skipSuccessAlertOnInitialLoad = true;
    this.getCQPFees(true).then(() => {
      this.referralFeePercentage = '';
      this.setPercentage();
    });
  }

  setupReferralCommissionModel() {
    this.referralCommissionInfo = {
      loanApplicationId: this.loanAppId,
      adviserId: this.loanAdviserId,
      upfrontReferral: 0,
      initialReferralFee: 0,
      trailReferral: 0,
      ongoingReferralFee: 0,
    };
  }

  getCQPFees(skipUpdateCpaCqp = false) {
    this.isFeesLoading = true;
    return this.loanScenarioModelService
      .getCqpDynamicFee(this.loanAppId)
      .then((response) => {
        this.allFees = response || [];
        this.isFeesLoading = false;
        this.updateIsFees(this.allFees.length, skipUpdateCpaCqp);
        this.getLoanAppFacilityFees().then(() => {
          this.getLoanScenarioEnquiry().then(() => {
            this.disabledReferralInputFields = !(
              this.lenderId && this.isLoanAppReferralType
            );
          });

          this.updateUpfrontFeesPayableWhen();
        });
      });
  }

  updateUpfrontFeesPayableWhen() {
    const upfrontFeesWithNoPayableWhen = this.allFees.filter(
      (fee) => fee.description === 'Upfront Fees' && !fee.payableWhen,
    );

    if (!upfrontFeesWithNoPayableWhen) {
      return;
    }

    const atSettlementText = 'At Settlement';
    upfrontFeesWithNoPayableWhen.forEach((fee) => {
      const params = {
        ...fee,
        payableWhen: atSettlementText,
      };
      this.loanScenarioModelService
        .updateCqpDynamicFee(this.loanAppId, fee.feeId, params)
        .then(() => {
          fee.payableWhen = atSettlementText;
        });
    });
  }

  processAssetFinanceCommission(loanFacility) {
    const cpaCqpSetCopy = { ...this.cpaCqpSet };
    const facility = isValidObjectValue(() => loanFacility.length)
      ? loanFacility[0]
      : {};
    copyBrokerageDocumentValues({ cpaCqpSetCopy, facility });
    updateBrokerageValues({ cqp: cpaCqpSetCopy.CQP });
    this.loanScenarioService.RecommendationReportStatus(cpaCqpSetCopy);
  }

  getLoanScenarioEnquiry() {
    return this.loanScenarioService
      .getLoanScenarioEnquiry(this.loanAppId)
      .then(({ data }) => {
        this.isLoanAppReferralType = !!(
          data && data.ReferralCategoryId === LOAN_APP_ENQUIRY_SOURCE.REFERRAL
        );
        this.cpaCqpSet.CQP.IsReferralFee = this.isLoanAppReferralType;
        this.hasReferralData = !!(data.ReferrerName || data.ReferrerOrgName);
        this.updateCPACQP();
        if (!this.isLoanAppReferralType) {
          return;
        }

        if (this.cpaCqpSet.CQP.ReferralFee === 0) {
          this.cpaCqpSet.CQP.ReferralFee = '0';
        }

        const referralData = [data.ReferrerName, data.ReferrerOrgName];
        this.cpaCqpSet.CQP.ReferralFeePaidTo = concatenateValidStrings(
          referralData,
        );

        if (this.cpaCqpSet.CQP.ReferralFeePaidTo === 0) {
          this.cpaCqpSet.CQP.ReferralFeePaidTo = '';
        }
      });
  }

  getLoanAppFacilityFees() {
    this.getProductFeesList();
    return this.loanScenarioService
      .loanDetailsGet(this.loanAppId)
      .then(({ data }) => {
        this.lenderId = data.LenderId;
        if (!data || !data.LoanFacility) {
          return;
        }

        this.facility = data.LoanFacility;
        if (data.LoanFacility.length > 1) {
          const msg = `Multiple products were selected for this Loan App. Please verify the fees listed.`;
          toastInfo(msg);
        }

        const shouldRefill =
          this.isAssetFinance || !this.productFinderEditEnhancements;
        if (!shouldRefill) {
          return;
        }
        this.isAssetFinance &&
          this.processAssetFinanceCommission(this.facility);

        const hasSavedCqpFees = this.allFees && this.allFees.length;
        if (hasSavedCqpFees) {
          this.checkManualCqpState();
        } else {
          this.resetCQPManuallyUpdated();
          this.getAllFacilitiesFeesList(this.facility);
        }
      });
  }

  checkManualCqpState() {
    this.loanScenarioModelService
      .getManualCqpState(this.loanAppId)
      .then(({ data }) => {
        const isCqpFeesUpdated = data && isBoolean(data);
        if (!isCqpFeesUpdated) {
          return;
        }

        this.resetCQPManuallyUpdated();
        showActionConfirmation(
          `Notice`,
          `It appears the product fees have been changed \non the loan application.
            \nDo you wish to reload the CQP fees section? \nPlease be aware that this will remove any additional fees you many have added manually.`,
          (confirm) => {
            if (!confirm) {
              return;
            }
            this.deleteAllCurrentFees();
            this.getAllFacilitiesFeesList(this.facility);
          },
        );
      });
  }

  resetCQPManuallyUpdated() {
    this.loanScenarioModelService.setManualCqpState(this.loanAppId, false);
  }

  deleteAllCurrentFees() {
    if (!this.allFees || !this.allFees.length) {
      return;
    }
    this.allFees.forEach((fee, idx, arr) => {
      this.deleteFee(fee, idx === arr.length - 1);
    });
  }

  getProductFeesList() {
    this.loanApplicationServices.getProductFeesList().then(({ data }) => {
      this.productFeesList = data || [];
    });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  getAllFacilitiesFeesList(facilities) {
    if (!facilities || !facilities.length) {
      this.updateIsFees();
      return;
    }

    if (this.isAssetFinance) {
      this.prefillAssetFinanceFees(facilities);
      return;
    }

    this.isFeesLoading = true;
    let tempFeesCount = 0;
    facilities.forEach((facility, index) => {
      if (!facility.BrokerEventId) {
        return;
      }

      this.loanProfilerService
        .getSelectedProductDetailsGetByLoanStructure(facility.BrokerEventId)
        .then(({ data }) => {
          const details = data && data.length && data[0];
          if (!details || !details.SelectedProduct.length) {
            this.updateIsFees();
            return;
          }
          const selectedProduct = details.SelectedProduct.find((product) => {
            return product.IsRecommended;
          });

          if (!selectedProduct) {
            return;
          }
          return this.loanScenarioService.LenderFeesGet(
            selectedProduct.ProductID,
            facility.BrokerEventId,
            details.LoanStructureID,
            this.loanAppId,
          );
        })
        .then((result) => {
          const data = result && result.data;
          const productFees = (data && data.productFee) || [];
          const checkedProducts =
            isValidObjectValue(() => productFees.length) &&
            productFees.filter((fee) => fee.IsChecked);
          tempFeesCount += (checkedProducts && checkedProducts.length) || 0;
          this.formatProductFees(productFees);

          if (index === facilities.length - 1) {
            this.updateIsFees(tempFeesCount);
            if (!tempFeesCount) {
              this.isFeesLoading = false;
            }
          }
        })
        .catch(displayError);
    });
  }

  formatProductFees(productFees) {
    if (!productFees || !productFees.length) {
      this.isFeesLoading = false;
      return;
    }

    const getPayableWhen = (fee) => {
      const prodFee = this.productFeesList.find(
        (item) => item.description === fee.FeesName,
      );
      return prodFee && prodFee.payableWhen;
    };

    const formattedProductFees = productFees
      .filter((fee) => fee.IsChecked)
      .map((fee) => {
        return {
          description: fee.FeesName,
          amount: fee.Amount,
          payableWhen: getPayableWhen(fee),
        };
      });

    this.insertFee(...formattedProductFees);
  }

  refreshFeesList() {
    this.loanScenarioModelService
      .getCqpDynamicFee(this.loanAppId)
      .then((response) => {
        this.allFees = response;
        this.isFeesLoading = false;
        if (!this.allFees || !this.allFees.length) {
          const isFeesModelValid = this.cpaCqpSet && this.cpaCqpSet.CQP;
          if (!isFeesModelValid) {
            return;
          }
          this.cpaCqpSet.CQP.IsFees = false;
        }
      });
  }

  insertFee(...data) {
    data.map((item, index) => {
      this.isSaving = true;
      this.loanScenarioModelService
        .addCqpDynamicFee(this.loanAppId, data[index])
        .then((response) => {
          if (data.length - 1 === index) {
            if (data.length === 1) {
              this.allFees = response;
              this.isFeesLoading = false;
            } else {
              this.refreshFeesList();
            }

            this.isSaving = false;
            this.newFee = {};
            this.toggleAddFee(false);
            this.cpaCqpSet.CQP.IsFees = true;
          }
        });
      return item;
    });
  }

  addFee() {
    const { description, amount } = this.newFee;
    if (!description || !amount) {
      toastError('Description and amount is required.');
      return;
    }
    this.insertFee(this.newFee);
  }

  toggleAddFee(isAddFee) {
    this.isAddFee = isAddFee;
  }

  editFee(fee) {
    this.toggleAddFee(false);
    fee.edit = true;
  }

  saveFee(fee) {
    const { description, amount } = fee;
    if (!description || !amount) {
      toastError('Description and amount is required.');
      return;
    }
    fee.isEditing = true;
    this.loanScenarioModelService
      .updateCqpDynamicFee(this.loanAppId, fee.feeId, fee)
      .then(() => {
        fee.edit = false;
        fee.isEditing = false;
      });
  }

  deleteFee(fee, refreshFees = false) {
    fee.isDeleting = true;
    this.loanScenarioModelService
      .deleteCqpDynamicFee(this.loanAppId, fee.feeId, true)
      .then((response) => {
        this.allFees = response;
        if (refreshFees) {
          this.refreshFeesList();
        }
      });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  updateCPACQP(reCalculate = true, isAmountChanged = false) {
    const { CQP: cqp, CPA: cpa } = this.cpaCqpSet;
    const { totalProposedLending } = this.calculatorFundsSummary;

    const checkTypeValid = this.cpaCqpSet && this.calculatorFundsSummary;
    if (!checkTypeValid) {
      return;
    }

    if (reCalculate) {
      const isAutoCompute = this.isAssetFinance;

      if (isAutoCompute) {
        const commissions = ['DocumentFee', 'Brokerage'];
        if (!isAmountChanged) {
          commissions.map((item) => {
            const amount = (parseFloat(cqp[item]) / 100) * totalProposedLending;
            cqp[`${item}_amt`] = Number.isInteger(Math.floor(amount))
              ? Math.floor(amount)
              : 0;
            return item;
          });
        } else {
          commissions.map((item) => {
            const percentage =
              (cqp[`${item}_amt`] / totalProposedLending) * 100;
            cqp[item] = Math.round(percentage * 100) / 100;
            return item;
          });
        }
        updateBrokerageValues({ cqp });
      } else {
        cqp.TrailCommission_amt = totalProposedLending
          ? cqp.TrailCommission_amt
          : 0;
        cqp.UpfrontCommission_amt = totalProposedLending
          ? cqp.UpfrontCommission_amt
          : 0;
      }
    }

    cpa.IsTemplated = this.isSaveTemplateChecked;
    const cpaCqpSetCopy = { ...this.cpaCqpSet };
    if (cpaCqpSetCopy && cpaCqpSetCopy.CPA && cpaCqpSetCopy.CPA.Content) {
      cpaCqpSetCopy.CPA.Content = cpaCqpSetCopy.CPA.Content.replace(
        /\r\n|\r|\n/g,
        '<br>',
      );
    }
    if (this.isInvalidReferralFee()) {
      const { ReferralFee, ...noReferallFeeCqp } = cpaCqpSetCopy.CQP;
      cpaCqpSetCopy.CQP = noReferallFeeCqp;
    }
    this.loanScenarioService
      .RecommendationReportStatus(cpaCqpSetCopy)
      .then(() => this.setReferralCommissionOnChange());
  }

  updateIsFees(feesCount, skipUpdateCpaCqp = false) {
    const isCQPModelValid = this.cpaCqpSet && this.cpaCqpSet.CQP;
    if (!isCQPModelValid) {
      return;
    }
    this.cpaCqpSet.CQP.IsFees = !!feesCount;
    !skipUpdateCpaCqp && this.updateCPACQP();
  }

  prefillAssetFinanceFees(loanFacility) {
    if (!loanFacility.length) {
      return;
    }
    const { AssetFinance: assetFinanceFacility } = loanFacility[0];
    const assetFinanceFees = ASSET_FINANCE_FEES.map((fee) => ({
      ...fee,
      amount: assetFinanceFacility[fee.objKey] || 0,
    }));

    feesSet(
      assetFinanceFees,
      this.loanScenarioModelService,
      this.loanAppId,
    ).then(() => {
      this.refreshFeesList();
    });
  }

  isInvalidReferralFee() {
    const referralFee = this.cpaCqpSet.CQP && this.cpaCqpSet.CQP.ReferralFee;
    const isValidReferralFee = referralFee === 0 || !!referralFee;
    return this.isLoanAppReferralType && !isValidReferralFee;
  }

  validateReferral() {
    this.showReferralFeeWarning = this.isInvalidReferralFee();
  }

  /** * FOR GENERATE GAME PLAN CQP CHANGES  */
  gamePlanCQPInit() {
    this.btnIcon = '<icon-back class="icon-back"></icon-back>';
  }

  onModelUpdate(propertyName, newValue, ...params) {
    this.cpaCqpSet.CQP[propertyName] = newValue;

    const isRecalculate = (params && params[0]) || true;
    const isAmountChanged = (params && params[1]) || false;
    this.updateCPACQP(isRecalculate, isAmountChanged);
  }

  onUpdateNewFee(value, fee) {
    if (fee) {
      fee.amount = value;
      return;
    }

    this.newFee.amount = value;
  }

  clickNext() {
    !this.uiService.showGamePlanInterestAndCommission &&
      this.validateReferral();
    if (
      !this.showReferralFeeWarning ||
      this.uiService.showGamePlanInterestAndCommission
    ) {
      this.onNext();
    }
  }

  notifyMissingCQPPDFLink() {
    this.displayWarning(
      'PDF Commission Guideline is not available for this lender.',
      false,
      'Oops!',
    );
  }

  setReferralFeePercentage() {
    if (!this.cpaCqpSet.CQP.UpfrontCommission_amt) {
      return;
    }

    const unroundedReferralFeePercentage =
      (this.referralFeePercentage * this.cpaCqpSet.CQP.UpfrontCommission_amt) /
      100;
    const referalFeePercentage = +unroundedReferralFeePercentage.toFixed(2);

    this.cpaCqpSet.CQP.ReferralFee =
      referalFeePercentage || unroundedReferralFeePercentage;

    this.updateCPACQP();
  }

  setPercentage() {
    if (
      !parseToInt10(this.cpaCqpSet.CQP.ReferralFee) ||
      !this.cpaCqpSet.CQP.UpfrontCommission_amt
    ) {
      return;
    }

    this.referralFeePercentage = +(
      (this.cpaCqpSet.CQP.ReferralFee /
        this.cpaCqpSet.CQP.UpfrontCommission_amt) *
      100
    ).toFixed(2);
  }

  setPercentageByReferralFee({
    isCreditQuoteDetailsModal,
    propertyName = '',
    value,
  }) {
    if (isCreditQuoteDetailsModal) {
      this.onModelUpdate(propertyName, value);
      this.setPercentage();
    } else {
      this.updateCPACQP();
      this.setPercentage();
    }
  }

  setReferralFeeByUpfrontCommision({
    isCreditQuoteDetailsModal,
    updateCPACQPCondition = false,
    upfrontCommisionName = '',
    value = '',
    onModelUpdateCondition = false,
  }) {
    if (
      !this.cpaCqpSet.CQP.UpfrontCommission_amt &&
      this.referralFeePercentage
    ) {
      return;
    }

    if (!isCreditQuoteDetailsModal) {
      this.updateCPACQP(updateCPACQPCondition);
    } else {
      this.onModelUpdate(upfrontCommisionName, value, onModelUpdateCondition);
    }

    !this.uiService.showGamePlanInterestAndCommission &&
      this.setReferralFeePercentage();
  }

  setReferralCommissionOnChange() {
    this.uiService.showGamePlanInterestAndCommission &&
      this.lenderId &&
      this.hasReferralData &&
      this.getAdviserReferralCommission().then(() => {
        this.saveReferralCommission();
      });
  }

  getAdviserReferralCommission() {
    return this.referralService
      .getLoanReferralCommission(this.loanAdviserId, this.loanAppId)
      .then((response) => {
        if (!response || !response.loanApplicationId) {
          return this.initializeReferralCommission();
        }
        this.isLoadedReferralInfo = true;
        this.referralCommissionInfo = response;
      });
  }

  initializeReferralCommission() {
    return this.referralService
      .initializeLoanReferralCommission(this.loanAdviserId, this.loanAppId)
      .then((response) => {
        if (!response) {
          return;
        }
        this.isLoadedReferralInfo = true;
        this.referralCommissionInfo = {
          ...response,
          loanApplicationId: this.loanAppId,
          adviserId: this.loanAdviserId,
        };
      });
  }

  saveReferralCommission(
    changeTypeId = REFERRAL_INPUT_CHANGE_TYPE.PERCENTAGE,
    fieldName,
    feeValue,
  ) {
    if (
      !this.referralCommissionInfo ||
      !this.referralCommissionInfo.loanApplicationId ||
      !this.lenderId
    ) {
      return;
    }

    if (
      changeTypeId === REFERRAL_INPUT_CHANGE_TYPE.FEE &&
      feeValue !== undefined
    ) {
      this.referralCommissionInfo[fieldName] = feeValue;
    }

    this.referralCommissionInfo.upfrontCommission = this.cpaCqpSet.CQP.UpfrontCommission_amt;
    this.referralCommissionInfo.trailCommission = this.cpaCqpSet.CQP.TrailCommission_amt;

    this.referralService
      .saveLoanReferralCommission(changeTypeId, this.referralCommissionInfo)
      .then((response) => {
        if (!response) {
          toastError('Save referral failed.');
          this.logger.error(
            `Unable to save referral commission field 
            payload : ${this.referralCommissionInfo} /n
            response : ${response} /n
            changeTypeId : ${changeTypeId}`,
          );
          return;
        }

        this.referralCommissionInfo = {
          ...response,
          loanApplicationId: this.loanAppId,
          adviserId: this.loanAdviserId,
        };
        !this.skipSuccessAlertOnInitialLoad && toastSaveSuccess();
        this.skipSuccessAlertOnInitialLoad = false;
      });
  }
}
