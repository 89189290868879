import _ from 'lodash';
import { toastSaveSuccess } from 'Common/utilities/alert';
import { FIRE_GENERAL_ID } from 'Common/constants/customerCareModules';
import { setCompletionUtil } from '../util/tabTableCommon';

export default class CurrentClaimsCtrl {
  constructor(
    NgTableParams,
    customerCareInsuranceService,
    customerCareTabsService,
    uiService,
    $uibModal,
    contactInsuranceService,
  ) {
    'ngInject';

    this.NgTableParams = NgTableParams;
    this.$uibModal = $uibModal;
    this.toastSaveSuccess = toastSaveSuccess;
    this.customerCareInsuranceService = customerCareInsuranceService;
    this.customerCareTabsService = customerCareTabsService;
    this.contactInsuranceService = contactInsuranceService;
    this.uiService = uiService;
    this.FIRE_GENERAL_ID = FIRE_GENERAL_ID;
  }

  $onInit() {
    this.isBulkEmailActive = this.customerCareTabsService.isBulkEmailActive();
    this.isLoading = false;
    this.isFromCompleted = false;
    this.totalRecords = 0;
    this.currentPage = 1;
    this.tableData = [];

    this.defaultTableSettings = {
      page: 1,
      count: 10,
      sorting: {
        CommencementDate: 'asc',
      },
    };

    this.loadTableData();
  }

  $onChanges(changes) {
    if (changes.filterUpdateCount) {
      this.currentPage = 1;
      if (this.defaultTableSettings) {
        this.defaultTableSettings.page = 1;
      }
      this.isFromCompleted = false;
      if (this.tableParams) {
        this.tableParams.reload();
      }
    }

    if (changes.refreshCount && this.tableParams) {
      this.tableParams.reload();
    }
  }

  onActionSelected($event) {
    this.onSendModal({ $event });
  }

  markCompleted(item) {
    this.setCompletion(item);
  }

  onItemCheckboxToggled(item) {
    if (this.isBulkEmailActive) {
      this.onTableDataLoaded({ $event: { tableData: this.tableData } });
    } else {
      item.isComplete = item.isChecked;
      this.setCompletion(item);
    }
  }

  gotoContact(familyId) {
    this.viewContact({ $event: { familyId } });
  }

  onPageChange(event) {
    this.defaultTableSettings.page = event.$e.pageNumber;
    this.currentPage = event.$e.pageNumber;
    this.isFromCompleted = false;
    this.tableParams.reload();
  }

  loadTableData() {
    const tableCtrl = this;
    this.tableParams = new this.NgTableParams(tableCtrl.defaultTableSettings, {
      counts: [],
      total: 0,
      getData(params) {
        const sortBy = params.orderBy()[0].slice(1);
        const sortOrder = _.values(params.sorting())[0].toUpperCase();
        if (!tableCtrl.isFromCompleted) {
          tableCtrl.isLoading = true;
        }

        const {
          reviewDateStart,
          reviewDateEnd,
          ...restFilterData
        } = tableCtrl.filterData;

        const newFilter = {
          ...restFilterData,
          sortBy,
          sortOrder,
          pageNumber: tableCtrl.currentPage,
        };

        return tableCtrl.customerCareInsuranceService
          .getCurrentClaimList(newFilter)
          .then((response) => {
            tableCtrl.isLoading = false;
            tableCtrl.tableData = response;
            tableCtrl.tableData.map((item) => {
              item.isComplete = newFilter.isCompleted;
              return item;
            });
            tableCtrl.totalRecords =
              tableCtrl.tableData.length && tableCtrl.tableData[0].totalCount;
            tableCtrl.onTableDataLoaded({
              $event: { tableData: tableCtrl.tableData },
            });

            if (tableCtrl.totalRecords) {
              params.total(tableCtrl.totalRecords);
            } else {
              params.total(0);
            }
            return tableCtrl.tableData;
          });
      },
    });
  }

  openInsuranceDetailsModal(item) {
    this.contactInsuranceService
      .insuranceDetailsGet(
        item.familyID,
        item.tableRecordID /* Actually holds BenefitId */,
      )
      .then((response) => {
        const modalInstance = this.$uibModal.open({
          templateUrl:
            '/assets/views/contacts/partials/insurance-modal/insurance_existing.html',
          controller: 'InsuranceExistingModalCtrl',
          windowClass: 'insurance-existing-modal',
          backdrop: false,
          keyboard: false,
          resolve: {
            items() {
              return {
                data: {
                  ...response,
                  isCurrentClaim: false,
                },
                defaultTab: 'insurance-details',
              };
            },
          },
        });

        modalInstance.result.then(() => this.loadTableData());
      });
  }

  setCompletion(item) {
    if (this.uiService.newCustomerCareEvents) {
      this.openInsuranceDetailsModal(item);
    } else {
      setCompletionUtil(this)(item);
    }
  }
}
