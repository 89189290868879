export const filterContactCategory = ({ $scope, data }) => {
  if (!data) {
    return;
  }

  $scope.existingCategoryNameList = [];
  const listOfCategory = [];
  data.forEach((value) => {
    const { CategoryName: categoryName } = value;
    if (categoryName && !listOfCategory.includes(categoryName)) {
      listOfCategory.push(categoryName);
      value.IsChecked = $scope.isCategoryPresent(categoryName);
      $scope.existingCategoryNameList.push(value);
    }
  });
};
