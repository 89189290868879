import { collectionCamelizeBuilderForUI } from 'Common/mappers/genericMapper';

class AutomationRuleModelService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'automation-rule';
  }

  getAutomationRules(criteria = {}) {
    return this.httpClient
      .get(`${this.apiBaseUrl}`, criteria)
      .then(({ data }) => data.map(collectionCamelizeBuilderForUI));
  }

  applyAutomationRule(automationRuleId) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/${automationRuleId}/apply`,
      {},
    );
  }
}

export default AutomationRuleModelService;
