import moment from 'moment';
import {
  ENTITY_PARTY_TYPES,
  PROFESSIONAL_PARTY_TYPES,
} from 'Common/constants/partyTypes';
import { CONTACT_STATUS } from 'Common/constants/contactStatus';
import { parseToInt10 } from 'Common/utilities/parse';
import { toastError } from 'Common/utilities/alert';
import { DD_MM_YYYY_MOMENT_FORMAT } from 'Common/constants/dateConfigs';

export const filterBasedOnCountry = (list, isNZ) => {
  if (!list || !list.length) {
    return [];
  }
  if (isNZ) {
    return list;
  }
  return list.filter((type) => {
    return parseInt(type.value, 10) !== ENTITY_PARTY_TYPES.PARTNERSHIP;
  });
};

export const getPartyTypeList = ({ list, partyListToShow, isNZ }) => {
  const filteredList = filterBasedOnCountry(list, isNZ);

  const validPartyToShow = partyListToShow && partyListToShow.length;
  if (!validPartyToShow) {
    return filteredList;
  }

  return filteredList.filter((type) => {
    return partyListToShow.includes(parseInt(type.value, 10));
  });
};

export const isProfessionalPartyType = (partyType) => {
  return PROFESSIONAL_PARTY_TYPES.includes(parseToInt10(partyType));
};

export const setFamilyDetails = ({ $scope, contactService }) => (
  familyId,
  isEdit,
) => {
  const isProfContact = isProfessionalPartyType($scope.ContactSet.PartyType);
  const saveFamilyDetails = !isEdit && isProfContact;
  if (!saveFamilyDetails) {
    $scope.completeSave(familyId, isEdit);
    return;
  }

  contactService
    .contactFamilyInfoGet(familyId)
    .then(({ data }) => {
      const contactDetails = {
        ...data,
        FamilyId: familyId,
        ContactStatusId: CONTACT_STATUS.PROFESSIONAL_PARTNER,
      };
      return contactService.contactFamilyInfoSet(contactDetails);
    })
    .then(() => {
      $scope.completeSave(familyId, isEdit);
    });
};

export const completeSave = ({ $scope, $uibModalInstance }) => (
  familyId,
  isEdit,
) => {
  $uibModalInstance.close({
    FamilyId: familyId,
    PartyType: $scope.ContactSet.PartyType,
    isEdit,
  });
  if (typeof $scope.getClientByFamilyId === 'function') {
    $scope.getClientByFamilyId();
  }
};

export const getDependentAge = (birthday) => {
  return moment().diff(moment(birthday, DD_MM_YYYY_MOMENT_FORMAT), 'years');
};

export const validateBirthdayDependent = (birthday) => {
  const isBirthdayValid =
    !birthday ||
    !!(birthday && moment(birthday, DD_MM_YYYY_MOMENT_FORMAT, true).isValid());
  if (!isBirthdayValid) {
    toastError('Invalid date of birth', 'Validation Error');
  }
  return isBirthdayValid;
};

export const processBirthdayForDependent = ($scope) => {
  if (!$scope.displayBirthdayField) {
    $scope.ContactSet.Age = 0;
    $scope.ContactSet.DOB = '';
    return true;
  }
  const isValidBirthday = validateBirthdayDependent($scope.ContactSet.DOB);
  if (!isValidBirthday) {
    $scope.invalidBirthday = true;
    $scope.ContactSet.Age = 0;
    return false;
  }
  $scope.ContactSet.Age = getDependentAge($scope.ContactSet.DOB);
  return true;
};

export const setDependantParent = ({
  $scope,
  contactService,
  setFamilyDetailsPayData,
  isEdit,
  dependantModelService,
  payload,
}) => {
  if (!payload) {
    return;
  }
  try {
    dependantModelService
      .setDependantParent(payload)
      .then(() => {
        setFamilyDetails({ $scope, contactService })(
          setFamilyDetailsPayData,
          isEdit,
        );
      })
      .catch(toastError);
  } catch (error) {
    toastError(error);
  }
};
