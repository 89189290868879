import get from 'lodash/get';
import { PHONE_TYPE } from 'Common/constants/phoneMobileFormat';
import { EMAIL_CTYPES } from 'Common/constants/emailTypes';
import { CONTACT_TYPE } from 'Common/constants/contactType';
import { ONBOARDING_ROLE_ID } from 'Common/constants/onboarding';
import { PARTY_TYPES_LIST } from 'Common/constants/partyTypes';
import { SERVICE_OPTION } from 'Common/constants/helloBook';
import {
  DD_MM_YYYY_HH_MM_A_FORMAT,
  DATE_TIME_TZ_FORMAT,
} from 'Common/constants/dateConfigs';
import {
  formatDateWithTimeZone,
  formatDateTimezone,
} from 'Common/utilities/date';

export function getPhoneNumberByType(phoneArray, type) {
  if (!phoneArray || !phoneArray.length) {
    return;
  }
  const phone = phoneArray.filter((obj) => obj.Type === type);
  if (!phone || !phone.length) {
    return;
  }
  return phone[0].Number;
}

export function getEmailByType(emailArray, type) {
  if (!emailArray || !emailArray.length) {
    return;
  }
  const email = emailArray.filter((obj) => obj.Type === type);
  if (!email || !email.length) {
    return;
  }
  return email[0].EmailAddress;
}

export function getFirstAddressId(addressArray) {
  return addressArray && addressArray.length && addressArray[0].AddressID;
}

export function getFullName(firstname, lastname, middlename = '') {
  const nameArr = [firstname, middlename, lastname];
  return nameArr.reduce((acc, cur) => {
    const name = cur || '';
    return `${acc} ${name.trim()}`.trim();
  }, '');
}

export function hasFirstnameOrLastname(contact) {
  return !!(contact && (contact.FirstName || contact.LastName));
}

export function filterInitialOneString(str) {
  if (!str) {
    return '';
  }

  let initials = '';
  const stringArray = str.split(/(\s+)/);

  let i = 0; // first word
  initials = stringArray[i].charAt(0).toUpperCase();

  i = stringArray.length - 1; // last word
  if (i > 0) {
    initials += stringArray[i].charAt(0).toUpperCase();
  }
  return initials;
}

export const getRoleIdByContactType = (contactType) => {
  switch (contactType) {
    case CONTACT_TYPE.DIRECTOR:
      return ONBOARDING_ROLE_ID.DIRECTOR.toString();
    case CONTACT_TYPE.SHARE_HOLDER:
      return ONBOARDING_ROLE_ID.GUARANTOR.toString();
    case CONTACT_TYPE.LOAN_WRITER_STR:
      return ONBOARDING_ROLE_ID.LOAN_WRITER.toString();
    case CONTACT_TYPE.PARTNER_STR:
      return ONBOARDING_ROLE_ID.PARTNER.toString();
    default:
      return '';
  }
};

export function splitFullName(fullname, separator) {
  if (!fullname || !separator) {
    return;
  }
  const split = fullname.split(separator);
  const isInvalidFullName = !split || !split.length || split.length !== 3;
  if (isInvalidFullName) {
    return;
  }
  const [firstName, middleName, lastName] = split;
  return { firstName, middleName, lastName };
}

export function concatenateName(nameObj, joinChar) {
  if (!nameObj) {
    return '';
  }
  const { firstName, middleName, lastName } = nameObj;
  const emptyNames = !firstName && !middleName && !lastName;
  if (emptyNames) {
    return '';
  }
  const nameArr = [firstName, middleName, lastName];
  return nameArr.join(joinChar);
}

export function getNamePartsObject(fullname, separator = '|') {
  if (!fullname) {
    return {};
  }
  const name = splitFullName(fullname, separator);
  if (!name) {
    return {};
  }
  const { firstName, middleName, lastName } = name;
  return {
    fullName: getFullName(firstName, lastName, middleName),
    firstName,
    middleName,
    lastName,
  };
}

export function autoFormatUsername(nameArr) {
  if (!nameArr || !nameArr.length) {
    return '';
  }
  return nameArr.reduce((acc, cur) => {
    const name = (cur || '') && cur.replace(/\s/g, '');
    const names = [acc, name].filter((str) => str);
    return names.join('.').toLowerCase();
  }, '');
}

export function roleIdsHasRole(roleId, roleIdArr) {
  if (!roleIdArr || !roleIdArr.length) {
    return;
  }
  const roleIds = roleIdArr.split(',');
  const roleIdStr = roleId.toString();
  return roleIds.includes(roleIdStr);
}

export function appendPreferredName(nameObj, appendMiddleName) {
  if (!nameObj) {
    return '';
  }
  const { FirstName, MiddleName, LastName, PreferredName } = nameObj;

  const preferred = (PreferredName || '').trim();
  const appendPreferred =
    preferred && preferred.toLowerCase() !== FirstName.toLowerCase().trim();
  const preferredFormat = appendPreferred ? `(${preferred})` : '';
  const middleName = appendMiddleName ? MiddleName : '';
  const nameArray = [FirstName, preferredFormat, middleName, LastName];
  return nameArray
    .filter((current) => (current || '').toString().trim())
    .join(' ');
}

export const sendEmail = ({ $state, $stateParams, $scope, $window }) => (
  contactType,
) => {
  $window.sessionStorage.setItem('lastTabOpened', $stateParams.activeTab);
  $state.go('app.sendEmail', { familyId: $scope.familyId, contactType });
};

export const getContactFullname = (contact) => {
  if (!contact) {
    return '';
  }
  return getFullName(contact.FirstName, contact.LastName);
};

export const getSpecificPartyField = (contact) => {
  if (!contact) {
    return;
  }
  const fields = PARTY_TYPES_LIST.reduce((accum, value) => {
    return value.field ? [...accum, value.field] : accum;
  }, []);
  const contactPartyField = fields.find((field) => contact[field]);
  return PARTY_TYPES_LIST.find(
    (type) => type.field && type.field === contactPartyField,
  );
};

export const getDisplayFirstname = (contact, usePreferred) => {
  if (!contact) {
    return '';
  }
  const { FirstName, PreferredName } = contact;
  const preferredName = PreferredName || FirstName;
  return usePreferred ? preferredName : FirstName;
};

export const setContactEmailByType = ({ contact, newEmail, emailType }) => {
  const email = contact.Email.find(
    (emailField) => emailField.Type === emailType,
  );
  if (email) {
    email.EmailAddress = newEmail;
  } else {
    contact.Email = [
      ...contact.Email,
      {
        EmailAddress: newEmail,
        Type: emailType,
      },
    ];
  }
};

export const setMappedContactEmailByType = ({ contact, email, emailType }) => {
  let emailArray = contact && contact.email;
  let hasMatchingEmailType = false;

  const newEmail = {
    email,
    type: emailType,
  };
  if (emailArray && emailArray.length) {
    emailArray = emailArray.map((item) => {
      if (item.type === emailType) {
        item.email = email;
        hasMatchingEmailType = true;
      }
      return item;
    });
    if (!hasMatchingEmailType) {
      emailArray = [newEmail, ...emailArray];
    }
  } else {
    emailArray = [newEmail];
  }

  contact.email = emailArray;
  return contact;
};

export const setMappedContactPhoneByType = ({
  contact,
  phone,
  countryCode,
  phoneType,
}) => {
  let phoneArray = contact && contact.phone;
  let hasMatchingEmailType = false;

  const newPhone = {
    number: phone,
    type: phoneType,
    countryCode,
  };
  if (phoneArray && phoneArray.length) {
    phoneArray = phoneArray.map((item) => {
      if (item.type === phoneType) {
        item.number = phone;
        item.countryCode = countryCode;
        hasMatchingEmailType = true;
      }
      return item;
    });
    if (!hasMatchingEmailType) {
      phoneArray = [newPhone, ...phoneArray];
    }
  } else {
    phoneArray = [newPhone];
  }
  contact.phone = phoneArray;
  return contact;
};

export const setContactPhoneByType = ({
  contact,
  phone,
  countryCode,
  phoneType,
}) => {
  let phoneArray = contact && contact.phone;
  let hasMatchingEmailType = false;

  const newPhone = {
    Number: phone,
    Type: phoneType,
    CountryCode: countryCode,
  };
  if (phoneArray && phoneArray.length) {
    phoneArray = phoneArray.map((item) => {
      if (item.Type === phoneType) {
        item.Number = phone;
        item.CountryCode = countryCode;
        hasMatchingEmailType = true;
      }
      return item;
    });
    if (!hasMatchingEmailType) {
      phoneArray = [newPhone, ...phoneArray];
    }
  } else {
    phoneArray = [newPhone];
  }
  contact.Phone = phoneArray;
  return contact;
};

export const setContactEmailAddressByType = ({ contact, email, emailType }) => {
  let emailArray = contact && contact.Email;
  let hasMatchingEmailType = false;

  const newEmail = {
    EmailAddress: email,
    Type: emailType,
  };
  if (emailArray && emailArray.length) {
    emailArray = emailArray.map((item) => {
      if (item.Type === emailType) {
        item.EmailAddress = email;
        hasMatchingEmailType = true;
      }
      return item;
    });
    if (!hasMatchingEmailType) {
      emailArray = [newEmail, ...emailArray];
    }
  } else {
    emailArray = [newEmail];
  }

  contact.Email = emailArray;
  return contact;
};

export const addColorAndInitials = ({ utilitiesService, colorService }) => (
  list,
  nameKey = 'name',
) => {
  if (!list) {
    return [];
  }
  return list.reduce((accum, elem) => {
    return accum.concat({
      ...elem,
      initials: utilitiesService.filterInitialOneString(elem[nameKey]),
      backgroundColor: colorService.getRandomColor(),
    });
  }, []);
};

export const sendHelloBook = ({
  vm,
  helloBookPreviewService,
  isNewModal = false,
  loanApplicationId = 0,
}) => {
  const { isLoanWriter, isRiskWriter, isNZ, isAU } = vm.designatedBrokerInfo;
  const isToShowServiceOptions = isLoanWriter && isRiskWriter && isNZ;
  if (isToShowServiceOptions) {
    helloBookPreviewService
      .openServiceOptionModal({ action: 'send' })
      .result.then((result) => {
        vm.openSendHelloBook({
          isForLoanWriters: result.selectedService === SERVICE_OPTION.LENDING,
          selectedService: result.selectedService,
          toShowFactFind: result.selectedService === SERVICE_OPTION.LENDING,
          isNewModal,
          loanApplicationId,
        });
      });
    return Promise.resolve(null);
  }

  let selectedService = ``;
  if (isLoanWriter && !isRiskWriter && isNZ) {
    selectedService = SERVICE_OPTION.LENDING;
  }
  if (isRiskWriter && !isLoanWriter && isNZ) {
    selectedService = SERVICE_OPTION.INSURANCE;
  }
  const isForLending = selectedService === SERVICE_OPTION.LENDING || isAU;
  return vm.openSendHelloBook({
    isForLoanWriters: isForLending,
    selectedService,
    toShowFactFind: isForLending,
    isNewModal,
    loanApplicationId,
  });
};

export const getLastLogin = (contactDetails) => {
  if (!contactDetails) {
    return;
  }

  const { OktaLastLoginDate, LastLoginDate, TimeZone } = contactDetails;

  const DEFAULT_TIMEZONE = 'Pacific/Auckland';
  const timezone = TimeZone || DEFAULT_TIMEZONE;

  if (OktaLastLoginDate) {
    return formatDateTimezone(OktaLastLoginDate, DATE_TIME_TZ_FORMAT, timezone);
  }

  return formatDateWithTimeZone(
    LastLoginDate,
    DD_MM_YYYY_HH_MM_A_FORMAT,
    timezone,
    '(z Z)',
  );
};

export const getDefaultMobileFromDetail = (detail) => {
  const phones = get(detail, 'Phone', []);
  const mobile =
    phones.find((phone) => phone.Type === PHONE_TYPE.MOBILE) ||
    get(phones, '[0]', {});
  return {
    defaultMobile: mobile.Number || '',
    defaultCountryCode: get(mobile, 'CountryCode', '').toLowerCase(),
  };
};

export const getClientEmailMobilePayload = (applicant) => {
  if (!applicant) {
    return;
  }
  const mobile = getDefaultMobileFromDetail(applicant);
  const email = applicant.Email.find(
    (emailType) => emailType.Type === EMAIL_CTYPES.CRM_EMAIL,
  );

  return {
    personId: applicant.PersonId,
    email: email
      ? [{ email: email.EmailAddress, type: EMAIL_CTYPES.CRM_EMAIL }]
      : [],
    phone: mobile
      ? [
          {
            countryCode: mobile.defaultCountryCode,
            number: mobile.defaultMobile,
            type: PHONE_TYPE.MOBILE,
          },
        ]
      : [],
  };
};
