import get from 'lodash/get';
import set from 'lodash/set';
import {
  helloBookClientBuilderForUI,
  helloBookClientBuilderForMyCRM,
} from 'Common/mappers/helloBook';
import { toastError, toastSaveSuccess } from 'Common/utilities/alert';
import { setupNewPostURL } from 'Common/utilities/handleObsoleteURL';
import { saveStandAloneEntityContactNumber } from 'Common/utilities/contactNumber';
import { EMAIL_CTYPES } from 'Common/constants/emailTypes';
import { PHONE_TYPE } from 'Common/constants/phoneMobileFormat';

export default class HelloPackInvolvedPartiesCtrl {
  constructor(
    $timeout,
    contactService,
    helloPackService,
    helloBookService,
    countryCodeService,
    contactModelService,
    uiService,
    timeoutService,
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.contactService = contactService;
    this.helloPackService = helloPackService;
    this.helloBookService = helloBookService;
    this.countryCodeService = countryCodeService;
    this.contactModelService = contactModelService;
    this.uiService = uiService;
    this.timeoutService = timeoutService;
  }

  $onInit() {
    this.loanApplicationId = get(this, 'model.loanApplicationId');
    this.validationError = '';
    this.getInvolvedParties();
    this.helloPackService.handler.onCheckInvolvedPartiesValidation = (
      defaultValidation,
    ) => this.checkInvolvedPartiesValidation(defaultValidation);
    if (this.handler) {
      this.handler.setClientsByLoanId = (privacyConsents) => {
        this.updateClientsPolicy(privacyConsents);
      };
    }
  }

  updateClientsPolicy(privacyConsents) {
    if (!get(privacyConsents, 'length', 0)) {
      return;
    }
    this.clients = this.clients.map((client) => {
      const clientId = +get(client, 'personId', 0);
      const clientPolicy = privacyConsents.find(
        (policy) => policy.clientId === clientId,
      );
      return {
        ...client,
        privacyConsent: get(clientPolicy, 'privacyConsent', ''),
      };
    });
    this.onClientChange();
  }

  onDisableClientToggle(clients = []) {
    this.clients.map((client) => {
      client.disabled = clients.length <= 1 && client.value;
      return client;
    });
  }

  checkInvolvedPartiesValidation(defaultValidation = true) {
    const clients = this.clients.filter((client) => client.value);
    this.model.isInvolvedPartiesValid = clients.reduce((accum, client) => {
      if (!accum) {
        return accum;
      }
      return get(
        this,
        `helloPackService.form.involvedPartyForm[${client.personId}].$valid`,
        true,
      );
    }, defaultValidation);
  }

  checkMobileValidation(clients = []) {
    this.hasMobileError = clients.reduce((hasValidationError, client) => {
      if (hasValidationError) {
        return hasValidationError;
      }
      return !!get(
        this,
        `helloPackService.form.involvedPartyForm[${client.personId}].$error.ngIntlTelInput`,
        hasValidationError,
      );
    }, false);
    this.helloPackService.formError.mobileError = this.hasMobileError
      ? 'Mobile number is invalid.'
      : '';
  }

  onClientChange() {
    const clients = this.clients.filter((client) => client.value);
    this.checkMobileValidation(clients);
    this.onDisableClientToggle(clients);
    this.$timeout(() => {
      this.checkInvolvedPartiesValidation();
    }, 100);
    this.onClientUpdate && this.onClientUpdate({ clients });
  }

  getInvolvedParties() {
    return this.helloPackService
      .getHelloPackInvolvedParties({
        loanApplicationId: this.loanApplicationId,
        familyId: this.familyId,
        filters: this.filters,
      })
      .then((data) => {
        if (!data) {
          return;
        }
        this.clients = data.map((party) => {
          const client = helloBookClientBuilderForUI(party);
          client.defaultMobile = this.countryCodeService.formatExistingMobileAuNzNumber(
            {
              isInterNationalPhoneNumbers: this
                .isInternationalPhoneNumberEnabled,
              phoneNumber: client.defaultMobile,
            },
          );
          client.disabled = data.length <= 1;
          return client;
        });
        this.onClientChange();
      });
  }

  saveEntityDetails(client) {
    return this.contactService
      .organisationInfoGet(client.familyId)
      .then(({ data }) => {
        const { Phone, ...details } = data;
        const email = get(client, 'email[0].email', '');
        const contact = {
          ...details,
          FamilyId: client.familyId,
          Email: [{ Type: 'Email', EmailAddress: email }],
        };
        return this.contactService.organisationInfoSet(
          setupNewPostURL(contact, 'organisation'),
        );
      })
      .then(() => {
        const contact = { ...client };
        contact.PersonId = client.personId;
        contact.Phone = get(client, 'phone', []).map((phone) => ({
          Type: phone.type,
          Number: phone.number,
          CountryCode: phone.countryCode,
        }));
        return saveStandAloneEntityContactNumber({
          contact,
          familyId: contact.familyId,
          contactModelService: this.contactModelService,
        });
      });
  }

  saveDetails(client) {
    if (this.uiService.helloPackNewClientUpdateEnabled) {
      this.helloBookService.setClient(client).then((res) => res);
    }
    if (client.isEntity) {
      return this.saveEntityDetails(client);
    } else {
      return this.contactService.addContact([
        helloBookClientBuilderForMyCRM(client),
      ]);
    }
  }

  setClientUpdatedContact(updatedClient) {
    this.clients = this.clients.reduce((accum, client) => {
      if (client.personId !== updatedClient.personId) {
        return accum.concat(client);
      }
      const { email } =
        get(client, 'email', []).find(
          (clientEmail) => clientEmail.type === EMAIL_CTYPES.CRM_EMAIL,
        ) || get(client, 'email[0]', {});
      const { countryCode, number } =
        get(client, 'phone', []).find(
          (phone) => phone.Type === PHONE_TYPE.MOBILE,
        ) || get(client, 'phone[0]', {});
      return accum.concat({
        ...client,
        defaultCountryCode: countryCode,
        defaultMobile: number,
        defaultEmail: email,
      });
    }, []);
  }

  setEmailSaveValidity({ client, type, isValid }) {
    const emailFieldName = `${type}-${client.personId}`;
    const form = get(
      this,
      `helloPackService.form.involvedPartyForm[${client.personId}]`,
      {
        $setValidity: () => {},
      },
    );
    form.$setValidity(emailFieldName, isValid);
    const errorMessage = isValid ? '' : 'Email address already taken.';
    set(this, `helloPackService.formError[${emailFieldName}]`, errorMessage);
    this.checkInvolvedPartiesValidation(isValid);
  }

  onSaveClientDetail({ client, type }) {
    this.saveDetails(client)
      .then(() => {
        this.setClientUpdatedContact(client);
        this.onClientChange();
        const clients = this.clients.filter((client) => client.value);
        this.helloPackService.onCheckClientDuplicateEmail(clients);
        toastSaveSuccess();
        if (type === 'email') {
          this.setEmailSaveValidity({ client, type, isValid: true });
        }
      })
      .catch(() => {
        if (type !== 'email') {
          return toastError();
        }
        this.setEmailSaveValidity({ client, type, isValid: false });
      });
  }

  onToggleClient(value, client) {
    client.value = value;
    this.onClientChange();
    const clients = this.clients.filter((client) => client.value);
    this.helloPackService.onCheckClientDuplicateEmail(clients);
  }

  $onDestroy() {
    this.timeoutService.cancelTimeouts('HelloPackInvolvedPartiesCtrl');
  }
}
