import { reject } from 'lodash';
import moment from 'moment-timezone';
import { toastSuccess, toastError } from 'Common/utilities/alert';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import {
  DD_MMM_YYYY_FORMAT,
  DD_MM_YYYY_FORMAT,
  TO_MYCRM_DATE_FORMAT,
} from 'Common/constants/dateConfigs';

class TaskTemplatedCtrl {
  constructor(
    toaster,
    contactService,
    contactModelService,
    dashboardService,
    tasksService,
    generalSearchService,
    currentUserService,
    clientInfoService,
    taskDetailService,
    uiService,
  ) {
    'ngInject';

    this.toaster = toaster;
    this.contactService = contactService;
    this.contactModelService = contactModelService;
    this.dashboardService = dashboardService;
    this.tasksService = tasksService;
    this.generalSearchService = generalSearchService;
    this.currentUserService = currentUserService;
    this.clientInfoService = clientInfoService;
    this.taskDetailService = taskDetailService;
    this.toastError = toastError;
    this.uiService = uiService;
  }

  $onInit() {
    this.isDealTemplateTaskEnabled = !!(
      this.uiService.viewOfAfileTurnedOn && this.loanId
    );
    this.taskSet = {};
    this.mainObject = {};
    this.mainObject.isSearchEnable = false;
    this.isLoading = false;
    this.format = DD_MMM_YYYY_FORMAT;
    this.dueDateFormat = DD_MM_YYYY_FORMAT;
    this.dateOptions = {
      formatYear: 'yy',
      showWeeks: false,
    };
    this.template_task_id = 0;
    this.copiedTemplatedTaskList = [];
    this.selectedTemplateId = 0;
    this.taskInput = {
      familyValue: '',
    };
    this.templateTask = {};
    this.getAllData();
    this.taskTemplateAssignForm = {};
    this.taskDeal = {
      createdByUserFamilyId: this.currentUserService.familyId?.toString(),
    };
    this.involvePartiesOptionInstance = {};
    this.isSelectionAvailable = false;
  }

  openDate(templateTask) {
    this.temp = {};
    templateTask.datePopUp = !templateTask.datePopUp;
  }

  dateChanged(templateTask) {
    this.temp = {};
    templateTask.DueDate = templateTask.DueDateFormat;
  }

  closeFamilyList() {
    this.familyDetailsList = this.taskDetailService.closeFamilyList(
      this.taskInput,
      this.mainObject,
    );
  }

  selectFamily(id, contactName) {
    this.familyDetailsList = this.taskDetailService.selectFamily(
      id,
      contactName,
      this.taskInput,
      this.taskSet,
      this.mainObject,
    );
  }

  editTemplateTask(task) {
    task.isEditMode = true;
    task.datePopUp = true;
    task.DueDateFormat = new Date(task.DueDate);
    if (!this.mainObject.editInfoShown) {
      this.toaster.pop(
        'info',
        'Info',
        'Changing the date will update subsequent tasks.',
      );
      this.mainObject.editInfoShown = true;
    }
  }

  cancelTemplateTaskEditMode(task) {
    this.copiedTemplatedTaskList.map((item) => {
      if (item.TaskSettingsID === task.TaskSettingsID) {
        task.TaskName = item.TaskName;
        task.AssignedAdvisorID = item.AssignedAdvisorID;
        task.DueDate = item.DueDate;
        const adviserData = this.taskAdviserList.find((adviser) => {
          return adviser.BrokerID === task.AssignedAdvisorID;
        });
        if (adviserData) {
          item.FirstName = adviserData.LastName;
          item.LastName = adviserData.LastName;
          task.FirstName = adviserData.FirstName;
          task.LastName = adviserData.LastName;
        }
      }
      return item;
    });
    task.isEditMode = false;
  }

  saveTemplateTask(task) {
    this.copiedTemplatedTaskList.map((item) => {
      if (item.TaskSettingsID === task.TaskSettingsID) {
        item.TaskName = task.TaskName;
        item.AssignedAdvisorID = task.AssignedAdvisorID;
        item.DueDate = task.DueDate;
        const adviserData = this.taskAdviserList.find((adviser) => {
          return adviser.BrokerID === task.AssignedAdvisorID;
        });
        if (adviserData) {
          item.FirstName = adviserData.FirstName;
          item.LastName = adviserData.LastName;
          task.FirstName = adviserData.FirstName;
          task.LastName = adviserData.LastName;
        }
      }
      return item;
    });
    task.isEditMode = false;
    this.formatTaskSettingsDueDate(task.TaskTemplateID, task.TaskSettingsID);
  }

  getTemplateTask(templateId) {
    if (!templateId) {
      return;
    }
    this.selectedTemplateId = templateId;
    this.isSelectionAvailable = false;
    this.tasksService.taskTemplateSettingsGet(templateId).then(({ data }) => {
      this.templatedTaskList = data || [];
      this.isSelectAll = this.isDealTemplateTaskEnabled;
      this.enableAssignTask = false;
      this.formatTaskSettingsDueDate(templateId);
    });
  }

  formatTaskSettingsDueDate(templateId, taskId = 0) {
    const templateModel = {
      TaskTemplateID: templateId,
      TaskTemplateName: '',
      TaskSettingsList: this.templatedTaskList,
    };

    this.tasksService
      .dueDateSubsequentlyUpdate(templateModel, taskId)
      .then(({ data }) => {
        this.templatedTaskList =
          data &&
          data.map((task) => {
            task.isSelected = this.isSelectAll;
            return task;
          });
        this.isSelectionAvailable = this.isSelectAll;
        this.getBrokerBasicInfo();
      });
  }

  assignTask() {
    if (!this.templatedTaskList || !this.templatedTaskList.length) {
      return;
    }
    this.templatedTaskList.map((task) => {
      if (!task.isSelected) {
        return task;
      }
      if (this.assignToAdviser) {
        task.FirstName = this.assignToAdviser.FirstName;
        task.LastName = this.assignToAdviser.LastName;
        task.AssignedAdvisorID = this.assignToAdviser.BrokerID;
      }
      if (this.assignedTemplateDueDate) {
        task.DueDate = this.assignedTemplateDueDate;
      }
      return task;
    });
    this.toaster.pop('success', 'Successfully', 'Assigned Tasks');
  }

  selectAllTask() {
    if (!this.templatedTaskList || !this.templatedTaskList.length) {
      return;
    }
    this.templatedTaskList.map((task) => {
      task.isSelected = this.isSelectAll;
      return task;
    });
    this.enableAssignTask = this.isSelectAll;
  }

  watchCheckboxChanges() {
    if (!this.templatedTaskList || !this.templatedTaskList.length) {
      return;
    }
    const selectedItem = this.templatedTaskList.filter(
      (task) => task.isSelected,
    );
    this.isSelectAll =
      (selectedItem &&
        selectedItem.length &&
        selectedItem.length === this.templatedTaskList.length) ||
      false;
    this.enableAssignTask = !!(selectedItem && selectedItem.length);
  }

  disableAssignTask() {
    return (
      !this.enableAssignTask ||
      !(this.assignedTemplateDueDate || this.assignToAdviser)
    );
  }

  getBrokerBasicInfo() {
    this.dashboardService.getBrokerBasicInfo().then((response) => {
      const { data } = response;
      if (!data) {
        return;
      }

      this.mainObject.CreatedByUser = data.BrokerId;

      this.templatedTaskList.forEach((item) => {
        item.AssignedAdvisorID = item.AssignedAdvisorID.toString();
        if (item.AssignedAdvisorID === '0') {
          item.AssignedAdvisorID = data.BrokerId;
          item.FirstName = data.FirstName;
          item.AssignedAdvisorID = data.BrokerId;
        }
      });
      this.copiedTemplatedTaskList = [...this.templatedTaskList];
    });
  }

  deleteTemplateTask(task) {
    const index = this.templatedTaskList.indexOf(task);
    if (index > -1) {
      this.templatedTaskList.splice(index, 1);
      this.onCheckSelection();
    }
  }

  filterFamily(query) {
    const result = this.familyList.filter((el) => {
      return el.FamilyFullName.toLowerCase().includes(query.toLowerCase());
    });

    if (!this.quoteId) {
      const involvedParties = [
        {
          FamilyId: 0,
          FamilyFullName: 'All Involved Parties',
          IsClient: true,
        },
      ];
      return [...result, ...involvedParties];
    }
    return [...result];
  }

  searchFamilyInput(searchString) {
    if (this.familyList) {
      this.mainObject.isSearchEnable = true;
      this.familyDetailsList = this.filterFamily(searchString);
      return;
    }

    if (searchString) {
      this.mainObject.isSearchEnable = true;
      this.dashboardService
        .getGenralSearchResult(searchString)
        .then((response) => {
          if (!response) {
            return;
          }
          this.getClientResult(response, searchString);
        });
    }
  }

  getClientResult(searchResult, searchString) {
    this.generalSearchService
      .getClientResult(searchResult, searchString)
      .then((res) => {
        if (!res || !res.allResults) {
          return;
        }
        this.familyDetailsList = this.generalSearchService.processClientSearch(
          res.allResults,
        );
      });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  getAllData() {
    if (this.template_task_id) {
      this.tasksService
        .taskDetailsGet(this.template_task_id)
        .then((respond) => {
          this.taskSet = respond.data;
          this.taskSet.DueDate = new Date(this.taskSet.DueDate);
        });
    } else {
      this.taskSet.DueDate = new Date();
    }

    this.tasksService.taskTemplateListGet().then((respond) => {
      this.taskTemplateList = respond.data;
    });

    if (this.familyId && !this.loanId && !this.quoteId) {
      this.contactService.clientInformGet(this.familyId).then((response) => {
        if (!response || !response.data) {
          return;
        }
        const data = response.data;
        this.taskInput.familyValue = this.clientInfoService.getFullName(data);
        this.taskSet.FamilyId = this.familyId;
      });
    }

    this.isCorporateUser =
      this.currentUserService.accessType === ACCESS_TYPE.CORPORATE;
    const isLoanWriter =
      this.isCorporateUser && this.currentUserService.isNZ ? 0 : null;

    this.contactModelService
      .taskAdviserListGet(isLoanWriter, null, this.isFromTaskList)
      .then((respond) => {
        this.taskAdviserList = respond.data;
        this.taskAdviserList = reject(respond.data, ['BrokerID', 0]);
        respond.data.forEach((item) => {
          item.BrokerID = item.BrokerID.toString();
        });
        this.assignToAdviser = this.isDealTemplateTaskEnabled
          ? null
          : this.taskAdviserList.find(
              (adviser) =>
                adviser.BrokerID === `${this.currentUserService.familyId}`,
            );
      });

    this.contactService.getAllByFilter('*').then((respond) => {
      respond.data.FamilyList.forEach((value) => {
        if (value.FamilyID === this.familyId) {
          this.taskInput.familyValue = value.FamilyFullName;
          this.taskSet.FamilyId = !this.template_task_id
            ? value.FamilyID
            : this.taskSet.FamilyId;
        }
      });
    });

    if (this.quoteId) {
      const family = this.familyList && this.familyList[0];
      family && this.selectFamily(family.FamilyId, family.FamilyFullName);
    }
  }

  saveTemplatedTask() {
    const postDataList = [];

    this.templatedTaskList
      .filter((task) => task.isSelected)
      .map((item) => {
        const taskData = {
          FamilyID: this.taskSet.FamilyId,
          TaskID: 0,
          Title: item.TaskName,
          assignedClientID: item.AssignedAdvisorID,
          CreatedByUser: this.mainObject.CreatedByUser,
          DueDate: moment(item.DueDate).format('YYYY-MM-DD'),
          Notes: '',
          TaskSettingsID: item.TaskSettingsID,
          LoanId: this.loanId,
          QuoteID: this.quoteId,
        };
        postDataList.push(taskData);
        return item;
      });

    const postData = {
      TaskList: postDataList,
      TaskTemplateID: this.selectedTemplateId,
    };

    this.isLoading = true;
    const successHandler = () => {
      this.isLoading = false;
      if (this.template_task_id) {
        this.toaster.pop(
          'success',
          'Updated',
          'Templated task successfully updated',
        );
      } else {
        this.toaster.pop(
          'success',
          'Added',
          'Templated task successfully added',
        );
      }
      this.modalInstance.close('saved');
    };

    this.tasksService
      .taskV3MultipleSet(postData)
      .then(successHandler, toastError);
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }

  onSaveTemplate(updatedTemplate = {}) {
    this.templatedTaskList = this.templatedTaskList.map((template) => {
      if (template.TaskSettingsID !== updatedTemplate.TaskSettingsID) {
        return template;
      }
      const {
        TaskName,
        AssignedAdvisorID,
        DueDate,
        FirstName,
        LastName,
      } = updatedTemplate;
      return {
        ...template,
        TaskName,
        AssignedAdvisorID,
        DueDate,
        FirstName,
        LastName,
      };
    });
  }

  onApplyTask() {
    this.isLoading = true;
    return this.initializeSaveTask()
      .then(() => {
        this.modalInstance.close('saved');
        toastSuccess('Templated task successfully added');
      })
      .catch(() => {
        toastError();
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  initializeSaveTask() {
    if (!this.uiService.newTaskDealEnabled) {
      return this.saveTask().then(({ data }) =>
        this.linkTaskToInvolvedParties(data),
      );
    }
    const clients = this.involvePartiesOptionInstance.getSelectedValues();
    const taskRequests = clients?.reduce((requests, familyId) => {
      return [...requests, this.saveTask(familyId)];
    }, []);
    return Promise.all(taskRequests);
  }

  saveTask(familyId = 0) {
    const tasks = this.templatedTaskList.reduce((templates, template) => {
      if (!template.isSelected) {
        return templates;
      }
      const dueDate = template.DueDate ?? this.assignedTemplateDueDate;
      const assignedAdviserId =
        this.assignToAdviser?.BrokerID || template.AssignedAdvisorID;
      const newTemplate = {
        FamilyID: familyId,
        TaskID: 0,
        Title: template.TaskName,
        assignedClientID: assignedAdviserId,
        CreatedByUser: this.taskDeal.createdByUserFamilyId,
        DueDate: moment(dueDate).format(TO_MYCRM_DATE_FORMAT),
        Notes: '',
        TaskSettingsID: template.TaskSettingsID,
        LoanId: this.loanId,
        QuoteID: this.quoteId,
      };
      return [...templates, newTemplate];
    }, []);

    return this.tasksService.taskV3MultipleSet({
      TaskList: tasks,
      TaskTemplateID: this.selectedTemplateId,
    });
  }

  linkTaskToInvolvedParties(tasks = []) {
    const sharedToContactFamilyIdCsv = this.involvePartiesOptionInstance
      .getSelectedValues()
      .join();

    if (!sharedToContactFamilyIdCsv) {
      return Promise.resolve({});
    }

    const requests = tasks.reduce((taskRequests, task) => {
      const invalidLinkToLoan = !task.TaskID || !task.LoanId;
      if (invalidLinkToLoan) {
        return taskRequests;
      }
      const params = {
        taskLoanLinkId: 0,
        loanId: task.LoanId || 0,
        taskId: task.TaskID || 0,
        sharedToContactFamilyIdCsv,
        isActive: true,
      };
      return [...taskRequests, this.tasksService.setTaskLinkToLoan(params)];
    }, []);

    return Promise.all(requests);
  }

  onCheckSelection() {
    this.isSelectionAvailable = !!this.templatedTaskList?.find(
      (template) => template.isSelected,
    );
  }

  checkInvolvedPartiesValidity() {
    return (
      !this.selectedOpportunityId ||
      (this.selectedOpportunityId && this.involvePartiesOptionInstance?.valid)
    );
  }
}

export default TaskTemplatedCtrl;
