import angular from 'angular';

export const loadModule = () =>
  angular
    .module('app')
    .directive('providerUpdates', function providerUpdates(
      $timeout,
      overviewDashboardService,
      $q,
      optionsService,
      $rootScope,
      queryService,
      overviewDashboardSharedService,
    ) {
      return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: { myParam: '=' },
        templateUrl:
          'assets/views/overviewDashboard/directiveTemplates/providerUpdates.html',

        link(scope) {
          scope.interval = 'allDates';
          scope.slickConfig = { method: {} };
          scope.providerUpdateTableData = [];
          // Model
          angular.extend(scope, {
            lenderUpdatesObject: {
              selectedDay: '30',
              list: [],
            },
            countryList: [],
            dataLoaded: false,
            showSlick: false,
          });

          // Methods
          angular.extend(scope, {
            initValues() {
              scope.finalizeLenderUpdates(
                scope.lenderUpdatesObject.selectedDay,
              );
              overviewDashboardSharedService
                .getCountryList()
                .then((response) => {
                  scope.countryList = response;
                });
            },
            getLenderUpdates(days) {
              const defer = $q.defer();
              overviewDashboardService
                .getLenderUpdates(days)
                .then((response) => {
                  return defer.resolve(response.data);
                });
              return defer.promise;
            },

            finalizeLenderUpdates(days) {
              scope.lenderUpdatesObject.list = [];
              scope.getLenderUpdates(days).then((response) => {
                scope.lenderUpdatesObject.list = queryService.queryPerPage(
                  3,
                  response,
                );

                if (scope.lenderUpdatesObject.list.length > 0) {
                  $timeout(() => {
                    scope.dataLoaded = true;
                    scope.showSlick = true;
                  }, 20);

                  $timeout(() => {
                    try {
                      scope.slickConfig.method.slickGoTo(0);
                    } catch (error) {
                      // Continue regardless of error
                    }
                  }, 20);
                }
              });
            },

            daysChanged(selectedDay) {
              scope.finalizeLenderUpdates(selectedDay);
            },
          });

          function init() {
            $timeout(() => {
              scope.crmCountryID = $rootScope.crmCountryID;
            }, 1000);
            scope.initValues();
          }
          scope.dynamicPopover = {
            content: ' ',
            templateUrl: 'providerUpdatesPopover.html',
            title: 'Title',
          };

          scope.doAction = function (msg) {
            if (msg === 'Customise') {
              scope.customizeMode = true;
            } else {
              scope.customizeMode = false;
            }
          };

          init();
        },
      };
    });
