import {
  isBluesToneFeatureOn,
  isApplicationToOnlineProduct,
  isFeatureBlueStoneOnAndOff,
  loanParams,
  sectionErrorCallback,
  excludeErrors,
  mapClientErrors,
  validateOccurringErrors,
  appendErrorsToClientParty,
  errorSegregatorSections,
  preventModalToOpenTwice,
  mapDependentErrors,
  appendErrorToLoanFacility,
  appendAttributesNotificationError,
  appendErrorsToGuarantors,
  getFatalErrorCodes,
  lockedValidation,
  appendErrorToSecurityAddress,
  addErrorIconToClientIncome,
} from 'Common/utilities/bluestone';
import {
  LOAN_SECTION,
  BLUESTONE_SECTION_ERROR_PROPS,
} from 'Common/constants/blueStoneStatusCodes';
import {
  mapdAddresses,
  addressHistory,
  mapFinancialAddress,
} from 'Common/utilities/bluestoneAddress';
import { internalCrmErrorBluestoneSubmission } from 'Common/utilities/internalBluestone';

export default class BlueStoneSubmissionService {
  constructor(
    $state,
    $timeout,
    $uibModal,
    crmConfirmation,
    configService,
    currentUserService,
    bluestoneService,
    financialsSharedService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$timeout = $timeout;
    this.$uibModal = $uibModal;
    this.crmConfirmation = crmConfirmation;
    this.bluestoneService = bluestoneService;
    this.configService = configService;
    this.currentUserService = currentUserService;
    this.financialsSharedService = financialsSharedService;
    this.isApplicationToOnlineProduct = isApplicationToOnlineProduct;
    this.extentFunction = {};
    this.loanScope = {};
    this.isOpened = false;
  }

  initializeScoping(apiData, loanScope) {
    const {
      applicantsSection,
      fundingSection,
      fundingDetailsSection = {},
      errorCounter,
    } = this.extentFunction;
    this.handlerApplicants = applicantsSection;
    this.handlerFundings = fundingSection;
    this.handlerFundingDetails = fundingDetailsSection;
    this.errorCounter = errorCounter;
    this.apiData = apiData;
    this.loanScope = loanScope;
    this.appendExtentFunctions();

    this.applicantsCtrl = applicantsSection.applicantErrorProps;
    this.fundingCtrl = fundingSection.fundingErrorProps;
    this.fundingDetailsCtrl = fundingDetailsSection.fundingDetailsErrorProps;
  }

  appendExtentFunctions() {
    this.errorCounter.errorCount = 0;
    this.errorCounter.errorToggle = () => {
      this.toggleErrorsSections();
    };

    this.handlerFundings.fundingErrorProps = {
      toggle: this.isFeatureOnAndOff(),
      bannerColor: false,
      errorTypes: {},
      reloading: false,
    };

    this.handlerFundingDetails.fundingDetailsErrorProps = {
      toggle: this.isFeatureOnAndOff(),
      bannerColor: false,
      errorTypes: {},
      reloading: false,
    };

    this.handlerApplicants.applicantErrorProps = {
      toggle: this.isFeatureOnAndOff(),
      bannerColor: false,
      errorTypes: {},
      reloading: false,
    };
    this.loanScope.toggle = false;
    this.assetsSection.toggle = false;
    this.assetsSection.reloading = false;
    this.assetsSection.validCountries = [];
    this.loanScope.errorTypes = {};
  }

  getLoanAppLixiValidation(loanScope, dontCheckEmployment) {
    const loanData = loanParams(loanScope);
    const selectedMethod = loanScope.LenderSubmissionSet.SubmissionMethod;
    if (!this.isValidToBluestone(loanData) || selectedMethod !== 'bluestone') {
      loanScope.formSubmissionLender(dontCheckEmployment);
      return;
    }

    this.onCallBluesStoneAPI(loanScope, loanData);
  }

  getLoanSubmissionDateTested(loanScope, { loanAppId }) {
    this.bluestoneService
      .getLoanSubmissionDateTested(loanAppId)
      .then(({ dateTested }) => {
        loanScope.dateTested = dateTested;
      });
  }

  onCallBluesStoneAPI(
    loanScope,
    loanData,
    closeModal,
    isNotResubmitted = true,
  ) {
    const resetAPIData = (apiData) => {
      this.apiData = apiData;
    };
    this.bluestoneService
      .getLoanLoanAppValidation(loanData)
      .then(({ isSucceeded, apiData }) => {
        if (!isSucceeded) {
          const crmConfirmation = this.crmConfirmation;
          internalCrmErrorBluestoneSubmission({ crmConfirmation, closeModal });
          return;
        }
        isNotResubmitted && this.initializeScoping(apiData, loanScope);
        !isNotResubmitted && resetAPIData(apiData);
        this.afterCallBluesStoneAPI(loanScope, apiData, isNotResubmitted);
        isNotResubmitted &&
          this.getLoanSubmissionDateTested(loanScope, loanData);
        isNotResubmitted && closeModal && closeModal.close();
        loanScope.isLoadingLoanSubmission = false;
        this.handlerApplicants.applicantErrorProps.reloading = false;
        this.handlerFundings.fundingErrorProps.reloading = false;
        this.handlerFundingDetails.fundingDetailsErrorProps.reloading = false;
        this.loanScope.reloading = false;
        this.assetsSection.reloading = false;
      });
  }

  resubmitToBlueStone() {
    const loanData = loanParams(this.loanScope);
    if (!this.isValidToBluestone(loanData)) {
      return;
    }

    this.handlerApplicants.validateChanges = () => {
      this.handlerApplicants.applicantErrorProps.reloading = true;
      this.refreshBlueStoneAPI(loanData);
    };

    this.handlerFundings.validateChanges = () => {
      this.handlerFundings.fundingErrorProps.reloading = true;
      this.handlerFundingDetails.fundingDetailsErrorProps.reloading = true;
      const isWhiteLabeled = isApplicationToOnlineProduct(
        this.handlerFundings.loanDetailsSet.LenderId,
      );
      this.resetLenderSubmissionSecionts(isWhiteLabeled);
      isWhiteLabeled && this.refreshBlueStoneAPI(loanData);
    };

    this.loanScope.validateChanges = () => {
      this.loanScope.reloading = true;
      this.refreshBlueStoneAPI(loanData);
    };
  }

  refreshBlueStoneAPI(loanData) {
    const refreshBlueStoneAPI = this.$timeout(() => {
      this.onCallBluesStoneAPI(this.loanScope, loanData, {}, false);
      this.$onDestroy = () => this.$timeout.cancel(refreshBlueStoneAPI);
    }, 1500);
  }

  afterCallBluesStoneAPI(loanScope, { errorCount }, isNotResubmitted = true) {
    if (errorCount > 0) {
      isNotResubmitted && this.warningConfirmation(errorCount, loanScope);
      this.resetDependentStatus();
      this.onToggleExtentFunction().errorCounts(errorCount);
      this.onBindEachErrors();
      this.mapListOfApplicants();
      this.appendErrorNotifications();
      this.segregateApplicantErrors();
      this.segregateFundingSectionErrors();
      this.mapListOfGuarantors();
      this.mapListOfDependents();
      this.mapApplicantAddressHistory();
      this.mapErrorClientIncomes();
      this.mapEmploymentAddresses();
      this.addErrorBannerToLoanFacility();
      this.mapAssetsFinancialAddresses();
      this.resubmitToBlueStone();
      return;
    }
    this.resetDependentStatus();
    this.resetErroLoanFacility();
    this.successConfirmation(isNotResubmitted, (confirm) => {
      confirm && this.onSubmitDocuments(loanScope);
    });
    this.onToggleExtentFunction().resetErrors();
  }

  onBindEachErrors() {
    sectionErrorCallback(this.apiData, 'applicantErrorProps', (errors) => {
      this.applicantsCtrl.errorTypes = errors;
      this.applicantsCtrl.occurringErrors = validateOccurringErrors(
        excludeErrors(errors),
      );
    });

    sectionErrorCallback(this.apiData, 'fundingErrorProps', (errors) => {
      this.fundingCtrl.errorTypes = errors;
      this.fundingCtrl.occurringErrors = validateOccurringErrors(
        excludeErrors(errors),
      );
    });

    sectionErrorCallback(
      this.apiData,
      BLUESTONE_SECTION_ERROR_PROPS.FUNDING_DETAILS,
      (errors) => {
        this.fundingDetailsCtrl.errorTypes = errors;
        this.fundingDetailsCtrl.occurringErrors = validateOccurringErrors(
          excludeErrors(errors),
        );
      },
    );

    sectionErrorCallback(this.apiData, 'declarationErrorPops', (errors) => {
      this.loanScope.errorTypes = errors;
      this.loanScope.occurringErrors = validateOccurringErrors(
        excludeErrors(errors),
      );
    });
  }

  addErrorBannerToLoanFacility() {
    const hasLoanFacilities = !!this.handlerFundings.loanDetailsSet;
    const onAppendErrorToLoanFacility = () => {
      appendErrorToLoanFacility(
        this.handlerFundings.loanDetailsSet,
        this.fundingCtrl.occurringErrors,
      );
    };
    hasLoanFacilities && onAppendErrorToLoanFacility();
    this.addErrorToSecurityAddress();
    this.addErrorToFundingDetailsSecurityAddress();
  }

  resetErroLoanFacility() {
    !!this.handlerFundings &&
      (() => {
        const LoanFacility = (this.handlerFundings.loanDetailsSet || {})
          .LoanFacility;
        LoanFacility &&
          LoanFacility.forEach((facility) => {
            facility.hasError = false;
          });
      })();
  }

  addErrorToSecurityAddress() {
    const { securityList } = this.handlerFundings.loanAppSharedData;
    const errorStructure = this.fundingCtrl.occurringErrors;
    appendErrorToSecurityAddress(errorStructure, securityList);
  }

  addErrorToFundingDetailsSecurityAddress() {
    const { securityList } = this.handlerFundings.loanAppSharedData;
    const errorStructure = this.fundingDetailsCtrl.occurringErrors;
    appendErrorToSecurityAddress(errorStructure, securityList);
  }

  segregateFundingSectionErrors() {
    this.fundingCtrl.fundingRequired = errorSegregatorSections(
      this.fundingCtrl.occurringErrors,
      'FUNDING_REQUIRED',
    );
    this.fundingDetailsCtrl.fundingRequired = errorSegregatorSections(
      this.fundingDetailsCtrl.occurringErrors,
      'FUNDING_REQUIRED',
    );
    this.fundingCtrl.proposedLending = errorSegregatorSections(
      this.fundingCtrl.occurringErrors,
      'PROPOSED_LENDING',
    );
  }

  segregateApplicantErrors() {
    this.applicantsCtrl.employments = errorSegregatorSections(
      this.applicantsCtrl.occurringErrors,
      'EMPLOYMENT',
    );
    this.applicantsCtrl.borrowers = errorSegregatorSections(
      this.applicantsCtrl.occurringErrors,
      'BORROWERS',
    );
    this.applicantsCtrl.guarantors = errorSegregatorSections(
      this.applicantsCtrl.occurringErrors,
      'GUARANTORS',
    );
    this.applicantsCtrl.dependents = errorSegregatorSections(
      this.applicantsCtrl.occurringErrors,
      'DEPENDENTS',
    );

    this.applicantsCtrl.address = errorSegregatorSections(
      this.applicantsCtrl.occurringErrors,
      'ADDRESS',
    );

    this.applicantsCtrl.relatedParties = errorSegregatorSections(
      this.applicantsCtrl.occurringErrors,
      'RELATED_PARTIES',
    );

    this.assetsSection.validCountries = errorSegregatorSections(
      this.applicantsCtrl.occurringErrors,
      'EXISTING_REAL_ESTATE_ASSETS',
    );
  }

  validateCountries() {
    const loanData = loanParams(this.loanScope);
    if (!this.isValidToBluestone(loanData) && !!this.assetsSection) {
      return;
    }
    this.assetsSection.reloading = true;
    this.refreshBlueStoneAPI(loanData);
  }

  toggleErrorsSections() {
    LOAN_SECTION.forEach((sections) => {
      sectionErrorCallback(this.apiData, sections, ({ hasErrors }) => {
        hasErrors && this.onToggleExtentFunction().toggleBySection(sections);
      });
    });
    this.onToggleExtentFunction().toggleBySection('financialAssetsProps');
  }

  resetAllErrorToggle(validLender) {
    this.hasInitialize(!validLender) &&
      this.onToggleExtentFunction().resetErrors();
  }

  toggleBySections(section) {
    this.hasInitialize(section) &&
      (() => {
        this.onToggleExtentFunction().toggleBySection(section);
        this.updateListOfApplicantsError();
      })();
  }

  updateListOfApplicantsError() {
    const checkErrors = this.$timeout(() => {
      this.mapListOfApplicants();
      this.appendErrorNotifications();
      this.mapListOfGuarantors();
      this.mapEmploymentAddresses();
      this.mapApplicantAddressHistory();
      this.$onDestroy = () => this.$timeout.cancel(checkErrors);
    }, 800);
  }

  isValidToBluestone({ lenderId }) {
    return this.isProviderBlueStone(lenderId) && this.isBlueStoneFeatureOn();
  }

  isFeatureOnAndOff() {
    return isFeatureBlueStoneOnAndOff(this.isBlueStoneFeatureOn());
  }

  hasInitialize(actions) {
    const hasInitialize = this.applicantsCtrl && this.fundingCtrl;
    return actions && hasInitialize;
  }

  appendErrorNotifications() {
    const { occurringErrors } = this.applicantsCtrl;
    const { listApplicants } = this.handlerApplicants;
    appendErrorsToClientParty({ occurringErrors, listApplicants });
  }

  mapListOfApplicants() {
    const { listApplicants } = this.handlerApplicants;
    appendAttributesNotificationError(
      'BORROWERS',
      this.applicantsCtrl.errorTypes,
      listApplicants,
    );
  }

  mapListOfDependents() {
    const listDependants = (this.handlerApplicants || {}).listDependants;
    const dependents = (this.applicantsCtrl || {}).dependents;

    listDependants &&
      dependents &&
      dependents.forEach((dependent) => {
        mapDependentErrors(
          dependent.attributes,
          'hasErrors',
          listDependants,
          dependent,
        );
      });
  }

  mapErrorClientIncomes() {
    addErrorIconToClientIncome(this.applicantsCtrl, this.handlerApplicants);
  }

  resetDependentStatus() {
    const listDependants = (this.handlerApplicants || {}).listDependants;
    listDependants &&
      listDependants.map((dependent) => {
        dependent.hasErrors = false;
        dependent.dependentMaximumAge = false;
        dependent.dependentDateOfBirth = false;
        return dependent;
      });
  }

  mapAssetsFinancialAddresses() {
    mapFinancialAddress(this.applicantsCtrl, this.financialsSharedService);
  }

  mapEmploymentAddresses() {
    mapdAddresses(this.applicantsCtrl, this.handlerApplicants);
  }

  mapApplicantAddressHistory() {
    addressHistory(this.applicantsCtrl, this.handlerApplicants);
  }

  mapListOfGuarantors() {
    const { guarantors = [] } = this.applicantsCtrl;
    const { listGuarantors } = this.handlerApplicants;
    const { occurringErrors } = this.applicantsCtrl;

    guarantors.forEach((guarantor) => {
      mapClientErrors(guarantor.attributes, 'hasErrors', listGuarantors);
    });

    appendAttributesNotificationError(
      'GUARANTORS',
      this.applicantsCtrl.errorTypes,
      listGuarantors,
    );

    appendErrorsToGuarantors({ occurringErrors, listGuarantors });
  }

  onToggleExtentFunction() {
    return {
      errorCounts: (errors) => {
        this.errorCounter.errorCount = errors;
        this.errorCounter.LoanAppFormWarning = !!errors;
      },
      toggleBySection: (sections) => {
        const hasErrors = !!this.assetsSection.validCountries.length;
        switch (sections) {
          case 'applicantErrorProps':
            this.applicantsCtrl.toggle = !this.applicantsCtrl.toggle;
            this.mapListOfDependents();
            break;
          case 'declarationErrorPops':
            this.loanScope.toggle = !this.loanScope.toggle;
            break;
          case 'financialAssetsProps':
            if (hasErrors) {
              this.assetsSection.toggle = !this.assetsSection.toggle;
            }
            break;
          case 'fundingDetailsErrorProps':
            this.fundingDetailsCtrl.toggle = !this.fundingDetailsCtrl.toggle;
            break;
          default:
            this.fundingCtrl.toggle = !this.fundingCtrl.toggle;
            break;
        }
      },
      resetErrors: () => {
        this.errorCounter.errorCount = 0;
        this.errorCounter.LoanAppFormWarning = false;
        this.applicantsCtrl.errorTypes.bannerColor = false;
        this.applicantsCtrl.errorTypes.hasErrors = false;
        this.fundingCtrl.errorTypes.bannerColor = false;
        this.fundingCtrl.errorTypes.hasErrors = false;
        this.fundingDetailsCtrl.errorTypes.bannerColor = false;
        this.fundingDetailsCtrl.errorTypes.hasErrors = false;
        this.loanScope.errorTypes.hasErrors = false;
        this.loanScope.errorTypes.bannerColor = false;
        this.assetsSection.validCountries = [];
      },
    };
  }

  resetLenderSubmissionSecionts(isWhiteLabeled) {
    this.loanScope.isProviderBlueStone = isWhiteLabeled;
  }

  isBlueStoneFeatureOn() {
    const myCRMconfigs = {
      ...this.currentUserService,
      ...this.configService,
    };
    return isBluesToneFeatureOn(myCRMconfigs);
  }

  isProviderBlueStone(lenderId) {
    const isWhiteLabeledProduct = this.isApplicationToOnlineProduct(lenderId);
    this.resetLenderSubmissionSecionts(isWhiteLabeledProduct);
    this.resetAllErrorToggle(isWhiteLabeledProduct);
    return isWhiteLabeledProduct;
  }

  warningConfirmation(errors, { showErrors }) {
    this.crmConfirmation.open({
      type: 'warning',
      title: 'Oops, we need to fix some things',
      description: `We found <span class="error-label-to-fix">${errors}</span> things that need updating`,
      buttonText: `Show me`,
      onConfirm: showErrors,
      showCloseButton: true,
      showCancelButton: false,
      modalSize: 'md',
      cancelButtonClass: 'colored',
    });
  }

  successConfirmation(isNotResubmitted, confirm) {
    const confirming = () => {
      confirm(true);
    };

    if (!this.isOpened && isNotResubmitted) {
      const modalInstance = this.crmConfirmation.open({
        type: 'success',
        title: 'Looks good!',
        description: ``,
        buttonText: `Submit Application`,
        onConfirm: confirming,
        showCloseButton: true,
        showCustomButton: true,
        customButtonText: 'Back to application',
        customButtonClass: 'colored',
        confirmationIcon: `<send-icon></send-icon>`,
        modalSize: 'md',
        cancelButtonClass: 'colored',
      });
      preventModalToOpenTwice(this, { modalInstance });
    }
  }

  fatalErrors(section, advisersServiceEmail) {
    this.crmConfirmation.open({
      type: 'warning',
      title: 'Oops!',
      description: `<fatal-errors fatal-errors-data="vm.cCommonObject" ></fatal-errors>`,
      buttonText: `Got it`,
      onConfirm: this.loanScope.showErrors,
      showCloseButton: true,
      showCancelButton: false,
      modalSize: 'md',
      cancelButtonClass: 'colored',
      renderAsComponent: true,
      commonObject: getFatalErrorCodes(section),
      isButtonMaxWidth: true,
    });
  }

  documentUploadSuccess() {
    this.crmConfirmation.open({
      type: 'success',
      title: 'Application Sent Succesfully!',
      description: `We've sent off the application but you can still make updates <br> via email if you need to.`,
      buttonText: `Okay, got it`,
      showCloseButton: true,
      showCustomButton: false,
      customButtonClass: 'colored',
      modalSize: 'md',
      cancelButtonClass: 'colored',
      onConfirm: () => {
        this.$state.go('app.contactsSingle', {
          familyId: this.loanScope.familyId,
          activeTab: 'lending',
          loanId: this.loanScope.loanAppSharedData.LoanApplicationDetails
            .LoanId,
          inprogress: 1,
        });
      },
    });
  }

  onSubmitDocuments(loanScope) {
    const { lenderId, loanAppId } = loanParams(loanScope);
    const params = {
      id: loanAppId,
      lenderId,
    };

    const submitDocumentToLender = (modal) => {
      this.bluestoneService
        .setSubmitDocumentsToBluestone(params)
        .then(({ errorCount, sections, isLocked, advisersServiceEmail }) => {
          modal.close();
          if (errorCount) {
            this.fatalErrors(sections);
            return;
          }
          this.documentUploadSuccess();
          lockedValidation(this.loanScope.loanAppSharedData, isLocked);
        })
        .catch(() => {
          modal.close();
          const sections = [
            {
              fatalErrors: [
                {
                  name: 'Server error',
                },
              ],
            },
          ];
          return this.fatalErrors(sections);
        });
    };

    this.openLoadingProviderModal(loanParams(loanScope), (modal) => {
      submitDocumentToLender(modal);
    });
  }

  openLoadingProviderModal(loanScope, modal) {
    const modalInstance = this.$uibModal.open({
      animation: true,
      template: `
        <modal-providers
          modal-instance="vm.modalInstance"
          lender-id="vm.props.lenderId">
        </modal-providers>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'providers-modal',
      size: 'md',
      resolve: {
        props: () => loanScope,
      },
    });
    modal(modalInstance);
  }
}
