export const ERROR_EMAIL_MESSAGE = {
  EMPTY_EMAIL: 'Please provide a valid email address.',
  INVALID_EMAIL: 'Invalid email.',
};

export const ERROR_PHONE_MESSAGE = {
  INVALID_MOBILE_PHONE: 'Please provide a valid mobile number.',
};

export const ERROR_OKTA = 'Okta error';
export const ERROR_CLIENT_FORM = {
  INVALID_CLIENT_FORM: 'Invalid Form - Please fill up the form correctly',
  INVALID_EMAIL: 'Invalid Form - Please provide a valid email.',
  INVALID_ADDRESS: 'Invalid Form - Please provide a valid address.',
  INVALID_MOBILE: 'Invalid Form - Please provide a valid mobile number.',
  INVALID_NAME: 'Invalid Form - Please provide a valid name.',
};
